import { AffineMatrix, Anchor, MINIMUM_TOLERANCE, Path, Vec } from "../../geom";
import { clamp } from "../../geom/math/scalar-math";
import { AnchorHandleConstraint } from "../../model/builtin-primitives";
import { Node } from "../../model/node";
import { SelectableParameter, SelectableSegment } from "../../model/selectable";
import { contextMatrixForNode } from "../../model/transform-utils";
import { ParameterTransformer, TransformerOptions } from "../../model/transformer";
import { CanvasDrag, globalState } from "../../global-state";
import { minimumPrecisionWorldPositionsFromCanvasDrag, worldPositionFromEvent } from "../util";
import { startCanvasDrag } from "./canvas-drag";

export interface StartAnchorHandleDragOptions {
  flip?: boolean;
  allowBreaking?: boolean;
  disableSnappingCache?: boolean;
  overrideAnchorHandleConstraint?: AnchorHandleConstraint;
  assignAnchorHandleConstraint?: AnchorHandleConstraint;
}

export const startAnchorHandleDrag = (
  downEvent: PointerEvent,
  node: Node,
  parameterName: "handleIn" | "handleOut",
  options?: StartAnchorHandleDragOptions
) => {
  let transformer: ParameterTransformer;
  startCanvasDrag(downEvent, {
    disableDisplay: true,
    disableGhost: true,
    disableSnappingCache: options?.disableSnappingCache,
    isValid() {
      return globalState.project.nodeExists(node);
    },
    onConsummate(moveEvent, canvasDrag) {
      const selectable = SelectableParameter.fromName(node, node.source.base, parameterName);
      transformer = new ParameterTransformer(selectable);
    },
    onMove(moveEvent, canvasDrag) {
      const trace = globalState.traceForNode(node);
      if (!trace?.base.isSuccess()) return;

      // The position arg should be a Vec since the base trace was successful.
      const anchorPosition = trace.base.args.position!.evaluationResult as Vec;

      const { currentPosition } = minimumPrecisionWorldPositionsFromCanvasDrag(canvasDrag);

      const inverseContextMatrix = contextMatrixForNode(node).clone().invert();
      const localPosition = currentPosition
        .clone()
        .affineTransform(inverseContextMatrix)
        .sub(anchorPosition);

      if (options?.flip) {
        localPosition.negate();
      }

      const overrideAnchorHandleConstraint =
        options?.allowBreaking && moveEvent.altKey
          ? "free"
          : options?.overrideAnchorHandleConstraint;
      const assignAnchorHandleConstraint =
        overrideAnchorHandleConstraint === "free" ? "free" : options?.assignAnchorHandleConstraint;
      transformer.assignValue(localPosition, {
        overrideAnchorHandleConstraint,
        assignAnchorHandleConstraint,
      });
    },
  });
};

export const startMirroredHandleDragFromAnchorCenter = (downEvent: PointerEvent, node: Node) => {
  startAnchorHandleDrag(downEvent, node, "handleOut", {
    overrideAnchorHandleConstraint: "mirror",
    assignAnchorHandleConstraint: "tangent",
  });
};

interface StartSegmentBendDragOptions {
  onCancel?: () => void;
}
export const startSegmentBendDrag = (
  downEvent: PointerEvent,
  segment: SelectableSegment,
  options?: StartSegmentBendDragOptions
) => {
  let handleOutTransformer: ParameterTransformer;
  let handleInTransformer: ParameterTransformer;

  let path: Path;
  let time: number;
  let inverseContextMatrix: AffineMatrix;

  startCanvasDrag(downEvent, {
    enableGridSnapping: false,
    isValid() {
      return globalState.project.selectableExistsAndIsValid(segment);
    },
    onConsummate(event: PointerEvent, canvasDrag: CanvasDrag) {
      const anchorNode1 = segment.node;
      const anchorNode2 = segment.nextNode;
      handleOutTransformer = new ParameterTransformer(
        SelectableParameter.fromName(anchorNode1, anchorNode1.source.base, "handleOut")
      );
      handleInTransformer = new ParameterTransformer(
        SelectableParameter.fromName(anchorNode2, anchorNode2.source.base, "handleIn")
      );
      const trace1 = globalState.traceForNode(anchorNode1);
      const trace2 = globalState.traceForNode(anchorNode2);
      if (
        trace1?.isSuccess() &&
        trace1.result instanceof Anchor &&
        trace2?.isSuccess() &&
        trace2.result instanceof Anchor
      ) {
        path = new Path([trace1.result, trace2.result]);
        inverseContextMatrix = contextMatrixForNode(anchorNode1).clone().invert();

        let localPosition: Vec;
        if (canvasDrag.startPoint.isPrecise()) {
          localPosition = canvasDrag.startPoint.worldPosition
            .clone()
            .affineTransform(inverseContextMatrix);
        } else {
          // Use position from the event for picking. The canvasDrag startPoint
          // may be quantized at this point which would cause the drag to start
          // from an unexpected position.
          localPosition = worldPositionFromEvent(event).affineTransform(inverseContextMatrix);
        }

        const closestTime = path.closestPoint(localPosition)?.time;
        if (closestTime !== undefined) {
          // Prevent degenerate cases where the picked position is the same as
          // one of the Anchor positions.
          time = clamp(closestTime, MINIMUM_TOLERANCE, 1 - MINIMUM_TOLERANCE);
        }
      }
    },
    onMove(event: PointerEvent, canvasDrag: CanvasDrag) {
      const [a1, a2] = path.anchors;

      const h1 = a1.handleOut.clone();
      const h2 = a2.handleIn.clone();
      if (h1.isZero() && h2.isZero()) {
        // Pop out zero handles such that the resulting bezier is uniform
        h1.copy(a1.position).mix(a2.position, 1 / 3);
        h2.copy(a2.position).mix(a1.position, 1 / 3);
      } else {
        h1.add(a1.position);
        h2.add(a2.position);
      }

      const B = canvasDrag.currentPoint.worldPosition.clone().affineTransform(inverseContextMatrix);
      const b = path.positionAtTime(time);
      const c = h1.clone().mix(h2, time);

      const oneMinusTime = 1 - time;
      const timeCubed = time * time * time;
      const oneMinusTimeCubed = oneMinusTime * oneMinusTime * oneMinusTime;
      const u = timeCubed / (timeCubed + oneMinusTimeCubed);

      const a = a1.position.clone().mix(a2.position, u);

      let abcRatio: number;
      if (b._almostEquals(a)) {
        // Prevent divide by zero when dragging a straight segment from its midpoint
        abcRatio = 1 / 3;
      } else {
        abcRatio = c.distance(b) / b.distance(a);
      }

      const C = a.clone().mix(B, abcRatio + 1);
      const v = C.clone().sub(c);

      const H1 = v
        .clone()
        .mulScalar(-2 * (time * time) + time + 1)
        .add(h1);
      const H2 = v
        .clone()
        .mulScalar(-2 * (oneMinusTime * oneMinusTime) + oneMinusTime + 1)
        .add(h2);

      const handleIn = H2.clone().sub(a2.position);
      const handleOut = H1.clone().sub(a1.position);

      if (!handleIn.isFinite() || !handleOut.isFinite()) debugger;

      const handleConstraint = event.altKey ? "free" : undefined;
      const options: TransformerOptions = {
        overrideAnchorHandleConstraint: handleConstraint,
        assignAnchorHandleConstraint: handleConstraint,
      };
      handleOutTransformer.assignValue(handleOut, options);
      handleInTransformer.assignValue(handleIn, options);
    },
    onCancel() {
      options?.onCancel?.();
    },
  });
};
