import { AffineMatrix } from "../../geom/index";
import { globalState } from "../../global-state";
import { ComponentFocus } from "../../model/focus";
import { isPointerEventDoubleClick } from "../../shared/util";
import { CanvasBaseUI, CanvasDragUI, FocusedGeometryUI } from "../canvas-ui/canvas-base-interface";
import { CanvasInterfaceElement } from "../canvas-ui/canvas-interface-element";
import {
  GhostGeometryUI,
  HoveredGeometryUI,
  InteractableNodeGeometryUI,
  SelectedModifiersGeometryUI,
  SelectionOriginsUI,
} from "../canvas-ui/geometry-interface";
import { GuidesUI } from "../canvas-ui/guides-interface";
import { SelectionOrComponentHandlesUI } from "../canvas-ui/handles-interface";
import {
  FocusedPathSegmentsUI,
  InteractablePathSegmentUI,
} from "../canvas-ui/path-segment-interface";
import { TransformBoxUI } from "../canvas-ui/transform-box-interface";
import { TransformCenterUI } from "../canvas-ui/transform-center-interface";
import { worldPositionFromEvent } from "../util";
import { startSegmentBendDrag } from "./anchor-handle-drag";
import { startCanvasBoxSelectionDrag } from "./canvas-drag";
import { Tool, handleNudgeEvent } from "./shared";

export class SelectTool implements Tool {
  onPointerMove(event: PointerEvent) {
    if (
      globalState.deviceStorage.geometrySnappingEnabled &&
      !globalState.canvasPointerIsDown &&
      !globalState.definitionDrag
    ) {
      globalState.snapping.cacheSnappingDataForHoveredInterface();
      globalState.snapping.updateSnappingPointNearWorldPosition(worldPositionFromEvent(event), {
        isSource: true,
      });
    }
  }

  onPointerLeave(event: PointerEvent) {
    globalState.snapping.clearCurrentPoint();
  }

  onKeyDown(event: KeyboardEvent) {
    const { project } = globalState;
    if (event.key === "Escape") {
      if (!project.selection.isEmpty()) {
        project.clearSelection();
      } else if (project.focus instanceof ComponentFocus && project.focus.node.parent) {
        const prevFocusedNode = project.focus.node;
        project.focusNode(project.focus.node.parent);
        project.selectNode(prevFocusedNode);
      }
    } else {
      handleNudgeEvent(event);
    }
  }

  interface(viewMatrix: AffineMatrix) {
    const { project } = globalState;

    const interactableNodes = project.interactableNodesToShow();

    const interfaceElems: CanvasInterfaceElement[] = [
      new SelectCanvasBaseUI(),
      new FocusedGeometryUI(),
      new FocusedPathSegmentsUI(SelectPathSegmentUI),
      ...interactableNodes.items.map((item) => new InteractableNodeGeometryUI(item.node, true)),
      new GhostGeometryUI(),
    ];

    const selection = project.selection.allNodesAndInstancesToShow();
    const selectionNodes = selection.directlySelectedNodes();
    const selectionAllNodes = selection.allNodes();

    const isSelectionVisible = !globalState.isPickerOpen;
    if (isSelectionVisible) {
      for (let item of selectionNodes.items) {
        interfaceElems.push(new InteractableNodeGeometryUI(item.node));
      }
      interfaceElems.push(new SelectedModifiersGeometryUI());
    }

    interfaceElems.push(
      new GuidesUI(interactableNodes),
      new HoveredGeometryUI(),
      new CanvasDragUI()
    );

    if (isSelectionVisible) {
      interfaceElems.push(
        new SelectionOrComponentHandlesUI(),
        new SelectionOriginsUI(selectionAllNodes)
      );
    }

    const isTransformBoxVisible =
      isSelectionVisible &&
      project.isTransformBoxEnabled &&
      !selectionNodes.isAllAnchors() &&
      !selectionNodes.isPassThrough();
    if (isTransformBoxVisible) {
      interfaceElems.push(new TransformBoxUI(selectionNodes, viewMatrix));
    }

    if (
      globalState.isControlDown ||
      globalState.isMetaDown ||
      project.hasOverrideTransformCenter()
    ) {
      if (
        isTransformBoxVisible ||
        (selectionAllNodes.isSingle() && !selectionAllNodes.isAllAnchors())
      ) {
        interfaceElems.push(new TransformCenterUI());
      }
    }

    return interfaceElems;
  }
}

class SelectCanvasBaseUI extends CanvasBaseUI {
  onPointerDown(event: PointerEvent) {
    const { project } = globalState;
    const isDoubleClick = isPointerEventDoubleClick(event);
    if (isDoubleClick && project.focus instanceof ComponentFocus && project.focus.node.parent) {
      project.clearSelection();
      project.focusNode(project.focus.node.parent);
    } else {
      startCanvasBoxSelectionDrag(event);
    }
  }
}

class SelectPathSegmentUI extends InteractablePathSegmentUI {
  onPointerDown(event: PointerEvent) {
    if (isPointerEventDoubleClick(event)) {
      this.insertAnchorWithPointerEvent(event);
    } else if (this.shouldBend()) {
      if (!this.selectable.isBendLocked()) {
        startSegmentBendDrag(event, this.selectable);
      }
      event.preventDefault(); // Signal that we've handled this event (don't start a selection drag)
    }
  }

  shouldBend() {
    let shouldBend = globalState.project.selection.items.length <= 1;
    if (globalState.isMetaDown) {
      shouldBend = !shouldBend;
    }
    return shouldBend;
  }

  cursor() {
    if (this.shouldBend()) {
      if (this.selectable.isBendLocked()) {
        return "select-locked";
      }
      if (globalState.isAltDown) {
        return "select-bend-split";
      }
      return "select-bend";
    }
    return "select";
  }
}
