import { AffineMatrix, Unit, Vec } from "../../geom";
import { Viewport } from "../../model/viewport";
import styleConstants from "../style-constants";

export const paintGrid = (
  viewMatrix: AffineMatrix,
  viewport: Viewport,
  viewportSize: Vec,
  divisions: "decimal" | "fractional",
  ctx: CanvasRenderingContext2D
) => {
  const inverseViewMatrix = viewMatrix.clone().invert();

  const worldMin = new Vec(0, 0).affineTransform(inverseViewMatrix);
  const worldMax = viewportSize.clone().affineTransform(inverseViewMatrix);

  const { interval, subdivisions } = gridIntervalAndSubdivisions(viewport, divisions);

  const worldMinGrid = worldMin.clone().divScalar(interval).floor().mulScalar(interval);
  const worldMaxGrid = worldMax.clone().divScalar(interval).ceil().mulScalar(interval);

  const drawLine = (worldA: Vec, worldB: Vec) => {
    const pixelA = worldA.clone().affineTransform(viewMatrix);
    const pixelB = worldB.clone().affineTransform(viewMatrix);
    ctx.moveTo(pixelA.x, pixelA.y);
    ctx.lineTo(pixelB.x, pixelB.y);
  };

  ctx.lineCap = "butt";
  ctx.lineWidth = 1.5;

  Minor: {
    ctx.beginPath();
    for (let x = worldMinGrid.x; x < worldMaxGrid.x; x += interval) {
      for (let i = 1; i < subdivisions; i++) {
        const x2 = x + (i * interval) / subdivisions;
        const worldA = new Vec(x2, worldMinGrid.y);
        const worldB = new Vec(x2, worldMaxGrid.y);
        drawLine(worldA, worldB);
      }
    }
    for (let y = worldMinGrid.y; y < worldMaxGrid.y; y += interval) {
      for (let i = 1; i < subdivisions; i++) {
        const y2 = y + (i * interval) / subdivisions;
        const worldA = new Vec(worldMinGrid.x, y2);
        const worldB = new Vec(worldMaxGrid.x, y2);
        drawLine(worldA, worldB);
      }
    }
    ctx.strokeStyle = styleConstants.gray96;
    ctx.stroke();
  }

  Major: {
    ctx.beginPath();
    for (let x = worldMinGrid.x; x < worldMaxGrid.x; x += interval) {
      if (x !== 0) {
        const worldA = new Vec(x, worldMinGrid.y);
        const worldB = new Vec(x, worldMaxGrid.y);
        drawLine(worldA, worldB);
      }
    }
    for (let y = worldMinGrid.y; y < worldMaxGrid.y; y += interval) {
      if (y !== 0) {
        const worldA = new Vec(worldMinGrid.x, y);
        const worldB = new Vec(worldMaxGrid.x, y);
        drawLine(worldA, worldB);
      }
    }
    ctx.strokeStyle = styleConstants.gray91;
    ctx.stroke();
  }

  // X and Y Axes
  ctx.beginPath();
  drawLine(new Vec(worldMinGrid.x, 0), new Vec(worldMaxGrid.x, 0));
  drawLine(new Vec(0, worldMinGrid.y), new Vec(0, worldMaxGrid.y));
  ctx.strokeStyle = styleConstants.gray70;
  ctx.stroke();
};

export const gridIntervalAndSubdivisions = (
  viewport: Viewport,
  divisions: "decimal" | "fractional"
) => {
  const minSpacingPixels = 90;
  const minSpacingWorld = minSpacingPixels / viewport.pixelsPerUnit;
  if (divisions === "fractional" && minSpacingWorld < 1) {
    return gridIntervalAndSubdivisionsFractional(minSpacingWorld);
  }
  return gridIntervalAndSubdivisionsDecimal(minSpacingWorld);
};

const gridIntervalAndSubdivisionsDecimal = (minSpacingWorld: number) => {
  let subdivisions = 10;
  let interval = Math.pow(10, Math.ceil(Math.log(minSpacingWorld) / Math.log(10)));
  // multiply by 2.5 so the 1/2 subdivision waits a bit longer before popping.
  if (interval / 2 > minSpacingWorld * 1.75) {
    interval = interval / 2;
  }
  return { interval, subdivisions };
};

const gridIntervalAndSubdivisionsFractional = (minSpacingWorld: number) => {
  let subdivisions = 8;
  let interval = Math.pow(4, Math.ceil(Math.log(minSpacingWorld) / Math.log(4)));
  return { interval, subdivisions };
};
