import {
  componentWise1,
  componentWise2,
  componentWise3,
  componentWiseN,
} from "./vec-component-wise";
import {
  clamp as clampComponent,
  fract as fractComponent,
  mix as mixComponent,
  modulo as moduloComponent,
  moduloDistance as moduloDistanceComponent,
  saturate as saturateComponent,
  smoothstep as smoothstepComponent,
} from "..";

/**
 * @returns the absolute value of `a`.
 */
export const abs = componentWise1(Math.abs);

/**
 * @returns 1 if `a` is greater than 0, -1 if `x` is less than 0, or 0 if `a` is
 * exactly 0.
 */
export const sign = componentWise1(Math.sign);

/**
 * @returns the largest integer less than or equal to `a`.
 */
export const floor = componentWise1(Math.floor);

/**
 * @returns the smallest integer greater than or equal to `a`.
 */
export const ceil = componentWise1(Math.ceil);

/**
 * @returns the closest integer to `a`.
 */
export const round = componentWise1(Math.round);

/**
 * @returns the integer part of `a`.
 */
export const trunc = componentWise1(Math.trunc);

/**
 * @returns the lowest value among the supplied arguments.
 */
export const max = componentWiseN(Math.max);

/**
 * @returns the highest value among the supplied arguments.
 */
export const min = componentWiseN(Math.min);

/**
 * Linearly interpolates between numbers `a` and `b` by the mixing factor `c`.
 *
 * @param a The value when `c` is 0
 * @param b The value when `c` is 1
 * @param c The mixing factor
 *
 * @returns a value between `a` and `b`
 */
export const mix = componentWise3(mixComponent);

/**
 * Constrains a number to be between a minimum and maximum value.
 *
 * @param a The value to constrain
 * @param b The minimum value to return
 * @param c The maximum value to return
 *
 * @returns the closest value to `a` between `b` and `c`.
 */
export const clamp = componentWise3(clampComponent);

/**
 * A special case of `clamp` for constraining values to be between 0 and
 * 1.
 *
 * @param a The value to constrain
 *
 * @returns the closest value to `a` between 0 and 1
 */
export const saturate = componentWise1(saturateComponent);

/**
 * Smoothly interpolates `c` between the values `a` and `b`. Values of `c`
 * outside this range will be clamped.
 *
 * See https://en.wikipedia.org/wiki/Smoothstep
 *
 * @param a The lower bound of the interpolated region
 * @param b The upper bound of the interpolated region
 * @param c The input value to smooth
 *
 * @returns a smoothly interpolated value from `a` to `b`
 */
export const smoothstep = componentWise3(smoothstepComponent);

/**
 * @returns the fractional part of `a`
 *
 * ```
 * let x = 1.456;
 * fract(x); // 0.456
 * ```
 */
export const fract = componentWise1(fractComponent);

/**
 * This is like `a % b` except if `a` is negative it will return a positive
 * result. For example `modulo(-2, 5) === 3`.
 */
export const modulo = componentWise2(moduloComponent);

/**
 * @returns the (smallest) absolute difference between `a` and `b` in modulo `c`
 * space. The result will be constrained to `0 <= difference <= c / 2`. This is
 * used, for example, in `angularDistance()` to wrap around 360.
 */
export const moduloDistance = componentWise3(moduloDistanceComponent);
