import m from "mithril";

import { BoundingBox, roundToFixed } from "../geom";
import { globalState } from "../global-state";
import { CodeComponent, Component } from "../model/component";
import { Icon20 } from "../shared/icon";

interface ComponentDimensionsAttrs {
  component: Component | CodeComponent;
}
export const ComponentDimensions: m.Component<ComponentDimensionsAttrs> = {
  view({ attrs: { component } }) {
    const trace = globalState.traceForComponent(component);
    const boundingBox = trace?.result?.boundingBox() ?? new BoundingBox();

    const size = boundingBox.size();
    const { units } = globalState.project.settings;

    return m(".component-dimensions", [
      m("span.dimension", [
        m(Icon20, { icon: "width" }),
        m("span.number", m("span.number-value", size.x.toFixed(2)), m("span.number-unit", units)),
      ]),
      m("span.spacing"),
      m("span.dimension", [
        m(Icon20, { icon: "height" }),
        m("span.number", m("span.number-value", size.y.toFixed(2)), m("span.number-unit", units)),
      ]),
    ]);
  },
};
