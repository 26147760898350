import { logEvent } from "./shared/log-event";

/**
 * This singleton class manages state related to analytics.
 */
class AnalyticsState {
  lastCopyTimeMillis = 0;

  reportCopy() {
    this.lastCopyTimeMillis = Date.now();
  }
  reportPaste() {
    this.lastCopyTimeMillis = 0;
  }

  reportLeave() {
    // If the user copied something and then immediately left (defocused) the
    // app, we assume they pasted to an application outside Cuttle.
    const externalCopyDelayMillis = 10000;
    if (
      this.lastCopyTimeMillis > 0 &&
      Date.now() - this.lastCopyTimeMillis < externalCopyDelayMillis
    ) {
      logEvent("external paste");
      // Prevent this event from being logged twice for the same copy.
      this.lastCopyTimeMillis = 0;
    }
  }
}

export const analyticsState = new AnalyticsState();

document.addEventListener("visibilitychange", () => {
  if (document.visibilityState === "hidden") {
    analyticsState.reportLeave();
  }
});
window.addEventListener("blur", () => {
  analyticsState.reportLeave();
});
