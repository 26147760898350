import {
  AffineMatrix,
  Anchor,
  CompoundPath,
  Fill,
  Graphic,
  Group,
  ImageData,
  ImageFill,
  Path,
  Vec,
  saturate,
} from "..";

export interface RasterizeOptions {
  pixelsPerUnit?: number;
  ignoreStroke?: boolean;
  hairlineStrokeWidth?: number;
}

// Canvas limits from: https://stackoverflow.com/a/11585939
const CANVAS_MAX_DIM = 2 << 14;
const CANVAS_MAX_AREA = (2 << 13) * (2 << 13);

// Dummy HTML Canvas context for point-in-path and rasterization.
const dummyCanvas = document.createElement("canvas");
const dummyCanvasCtx = dummyCanvas.getContext("2d");

export type ImageRetriever = (url: string) => ImageData | undefined;

// Start with a context that always returns undefined.
let getImageFromURL: ImageRetriever = () => undefined;
export const setImageRetriever = (retriever: ImageRetriever) => {
  getImageFromURL = retriever;
};

export const rasterizeGraphic = (graphic: Graphic, options?: RasterizeOptions) => {
  const viewGraphic = graphic.clone();
  const box = viewGraphic.boundingBox();
  if (!box) return;

  if (!dummyCanvasCtx) {
    throw new RasterizeError("No canvas context.");
  }

  const scale = options?.pixelsPerUnit ?? 1;
  const res = box.size().mulScalar(scale).round();

  if (res.x > CANVAS_MAX_DIM || res.y > CANVAS_MAX_DIM || res.x * res.y > CANVAS_MAX_AREA) {
    throw new RasterizeCanvasTooLargeError();
  }

  dummyCanvas.width = res.x;
  dummyCanvas.height = res.y;

  const viewMatrix = AffineMatrix.fromScale(scale).translate(box.min.clone().negate());
  viewGraphic.affineTransform(viewMatrix);
  paintGraphicToCanvas(viewGraphic, dummyCanvasCtx, res, options);

  const dataURL = dummyCanvas.toDataURL();
  if (dataURL === "data:,") {
    // This is also probably meant that the canvas size was too large.
    throw new RasterizeCanvasTooLargeError();
  }
  return {
    fill: new ImageFill(dataURL, 1, viewMatrix.clone().invert()),
    boundingBox: box,
  };
};

export class RasterizeError extends Error {
  constructor(message: string) {
    super("Rasterize: " + message);
  }
}
export class RasterizeCanvasTooLargeError extends Error {
  constructor() {
    super("Rasterize: A path with an image fill was larger than the maximum canvas size.");
  }
}

export const paintGraphicToCanvas = (
  graphic: Graphic,
  ctx: CanvasRenderingContext2D,

  // Canvas size is needed to compute the inverted clip path for "outer" aligned
  // strokes.
  canvasSize: Vec,

  options?: RasterizeOptions
) => {
  if (graphic instanceof Path || graphic instanceof CompoundPath) {
    const { stroke, fill } = graphic;

    // Skip rendering graphics that have no style.
    if (!stroke && !fill) return;

    const path = new Path2D();
    pathToCanvasPath(graphic, path);

    if (fill) {
      if (fill instanceof Fill) {
        ctx.fillStyle = fill.color.toCSSString();
        ctx.fill(path, "evenodd");
      } else {
        // fill instanceof ImageFill
        const img = getImageFromURL(fill.image);
        if (img) {
          const pattern = ctx.createPattern(img._image, "no-repeat");
          if (pattern) {
            const m = fill.transform;
            ctx.fillStyle = pattern;
            ctx.save();
            ctx.globalAlpha = saturate(fill.opacity);
            ctx.clip(path, "evenodd");
            ctx.transform(m.a, m.b, m.c, m.d, m.tx, m.ty);
            ctx.fillRect(0, 0, img.width, img.height);
            ctx.restore();
          }
        }
      }
    }

    if (stroke && !options?.ignoreStroke) {
      ctx.strokeStyle = stroke.color.toCSSString();
      ctx.lineCap = stroke.cap as CanvasLineCap;
      ctx.lineJoin = stroke.join as CanvasLineJoin;
      ctx.miterLimit = stroke.miterLimit;

      const hasNonStandardAlignment = graphic.hasStrokeWithNonStandardAlignment();

      if (stroke.hairline) {
        ctx.lineWidth = options?.hairlineStrokeWidth ?? 1;
        // Round join and cap improves rendering of hairline strokes in Safari.
        ctx.lineJoin = "round";
        ctx.lineCap = "round";
      } else if (hasNonStandardAlignment) {
        ctx.lineWidth = stroke.width * 2;
      } else {
        ctx.lineWidth = stroke.width;
      }

      if (hasNonStandardAlignment) {
        ctx.save();
        if (stroke.alignment === "outer") {
          // Make a copy of the path and invert it by added an (effectively)
          // infinitely large rectangle.
          const invertClipPath = new Path2D(path);
          invertClipPath.rect(0, 0, canvasSize.x, canvasSize.y);
          ctx.clip(invertClipPath, "evenodd");
        } else if (stroke.alignment === "inner") {
          ctx.clip(path, "evenodd");
        }
        ctx.stroke(path);
        ctx.restore();
      } else {
        ctx.stroke(path);
      }
    }
  } else if (graphic instanceof Group) {
    for (let item of graphic.items) {
      paintGraphicToCanvas(item, ctx, canvasSize, options);
    }
  }
};

export const pathContainsPoint = (path: Path | CompoundPath, point: Vec) => {
  if (!dummyCanvasCtx) return false;

  dummyCanvasCtx.beginPath();
  pathToCanvasPath(path, dummyCanvasCtx);
  return dummyCanvasCtx.isPointInPath(point.x, point.y, "evenodd");
};

export const pathStyleContainsPoint = (geom: Path | CompoundPath, point: Vec) => {
  if (!dummyCanvasCtx) return false;

  const { stroke, fill } = geom;

  const hasVisibleFill =
    (fill instanceof Fill && fill.color.a > 0) || (fill instanceof ImageFill && fill.opacity > 0);
  const hasVisibleStroke = stroke && !stroke.hairline && stroke.color.a > 0;

  // Optimization: exit early if there's no fill or stroke.
  if (!hasVisibleFill && !hasVisibleStroke) return false;

  dummyCanvasCtx.beginPath();
  pathToCanvasPath(geom, dummyCanvasCtx);
  const isInPath = dummyCanvasCtx.isPointInPath(point.x, point.y, "evenodd");

  if (hasVisibleFill && isInPath) return true;

  if (hasVisibleStroke && stroke) {
    // Check stroke again here to appease the type system
    dummyCanvasCtx.lineJoin = stroke.join as CanvasLineJoin;
    dummyCanvasCtx.lineCap = stroke.cap as CanvasLineCap;
    dummyCanvasCtx.miterLimit = stroke.miterLimit;
    if (stroke.alignment === "centered") {
      dummyCanvasCtx.lineWidth = stroke.width;
      return dummyCanvasCtx.isPointInStroke(point.x, point.y);
    } else if (stroke.alignment === "outer") {
      dummyCanvasCtx.lineWidth = stroke.width * 2;
      return !isInPath && dummyCanvasCtx.isPointInStroke(point.x, point.y);
    } else if (stroke.alignment === "inner") {
      dummyCanvasCtx.lineWidth = stroke.width * 2;
      return isInPath && dummyCanvasCtx.isPointInStroke(point.x, point.y);
    }
  }
  return false;
};

/**
 * Creates a path on an HTML Canvas 2D context but does not fill or stroke it.
 * Note: this does not call ctx.beginPath().
 *
 * @internal
 */
export const pathToCanvasPath = (
  path: Path | CompoundPath,
  ctx: CanvasRenderingContext2D | Path2D
) => {
  if (path instanceof Path) {
    if (path.anchors.length > 1) {
      let a1 = path.anchors[0];
      ctx.moveTo(a1.position.x, a1.position.y);
      for (let i = 1, n = path.anchors.length; i < n; ++i) {
        let a2 = path.anchors[i];
        segmentToCanvasPath(ctx, a1, a2);
        a1 = a2;
      }
      if (path.closed) {
        segmentToCanvasPath(ctx, a1, path.anchors[0]);
        ctx.closePath();
      }
    }
  } else {
    for (let subPath of path.paths) {
      pathToCanvasPath(subPath, ctx);
    }
  }
};

const segmentToCanvasPath = (ctx: CanvasRenderingContext2D | Path2D, a1: Anchor, a2: Anchor) => {
  if (a1.handleOut.x != 0 || a1.handleOut.y != 0 || a2.handleIn.x != 0 || a2.handleIn.y != 0) {
    ctx.bezierCurveTo(
      a1.position.x + a1.handleOut.x,
      a1.position.y + a1.handleOut.y,
      a2.position.x + a2.handleIn.x,
      a2.position.y + a2.handleIn.y,
      a2.position.x,
      a2.position.y
    );
  } else {
    ctx.lineTo(a2.position.x, a2.position.y);
  }
};
