import { AffineMatrix, Anchor, Vec } from "../../geom/index";
import { globalState } from "../../global-state";
import { AnchorHandleConstraint } from "../../model/builtin-primitives";
import { Expression } from "../../model/expression";
import { expressionCodeForVec } from "../../model/expression-code";
import { Node } from "../../model/node";
import { SelectionTransformer } from "../../model/transformer";
import { CanvasInterfaceElement } from "../canvas-ui/canvas-interface-element";

export type CanvasEvent = PointerEvent | WheelEvent | KeyboardEvent;

export type ToolName = "Select" | "Pen" | "Scale" | "Scale 1D" | "Rotate";

export interface Tool {
  activate?(): void;
  deactivate?(): void;

  isDisabled?(): boolean;

  onPointerEnter?(event: PointerEvent): void;
  onPointerLeave?(event: PointerEvent): void;
  onPointerMove?(event: PointerEvent): void;

  onKeyDown?(event: KeyboardEvent): void;

  onAfterEvaluation?(): void;

  interface(viewMatrix: AffineMatrix): CanvasInterfaceElement[];
}

export const handleNudgeEvent = (event: KeyboardEvent) => {
  if (event.key === "ArrowLeft") {
    nudgeSelection(new Vec(-1, 0), event.shiftKey);
    return true;
  }
  if (event.key === "ArrowRight") {
    nudgeSelection(new Vec(1, 0), event.shiftKey);
    return true;
  }
  if (event.key === "ArrowUp") {
    nudgeSelection(new Vec(0, -1), event.shiftKey);
    return true;
  }
  if (event.key === "ArrowDown") {
    nudgeSelection(new Vec(0, 1), event.shiftKey);
    return true;
  }
  return false;
};

export const toggleAnchorHandleConstraint = (node: Node) => {
  const trace = globalState.traceForNode(node);
  if (!(trace?.isSuccess() && trace.result instanceof Anchor)) return;

  let constraint: AnchorHandleConstraint = trace.result.hasTangentHandles() ? "free" : "tangent";
  let handleIn: Vec;
  let handleOut: Vec;

  if (constraint === "free") {
    handleIn = new Vec(0, 0);
    handleOut = new Vec(0, 0);
  } else {
    handleIn = trace.result.handleIn.clone();
    handleOut = trace.result.handleOut.clone();

    const handleInZero = handleIn.isZero();
    const handleOutZero = handleOut.isZero();

    if (handleInZero && !handleOutZero) {
      handleIn.copy(handleOut).negate();
    } else if (!handleInZero && handleOutZero) {
      handleOut.copy(handleIn).negate();
    } else {
      if (!node.parent) return;
      const index = node.indexInParent();
      if (index === -1) return;

      const childCount = node.parent.childCount();
      if (childCount > 1) {
        const parentTrace = globalState.traceForNode(node.parent);
        const isClosed = parentTrace?.isSuccess()
          ? Boolean(parentTrace.base.args.closed?.evaluationResult)
          : false;

        let prevIndex = index - 1;
        let nextIndex = index + 1;
        if (isClosed) {
          prevIndex = (prevIndex + childCount) % childCount;
          nextIndex %= childCount;
        }

        const prevNode = prevIndex >= 0 ? node.parent.childNodeAtIndex(prevIndex) : undefined;
        const nextNode =
          nextIndex < childCount ? node.parent.childNodeAtIndex(nextIndex) : undefined;

        let anchor1: Anchor | undefined;
        let anchor2 = trace.result;
        let anchor3: Anchor | undefined;

        if (prevNode !== undefined) {
          const trace = globalState.traceForNode(prevNode);
          if (trace?.result instanceof Anchor) {
            anchor1 = trace.result;
          }
        }
        if (nextNode !== undefined) {
          const trace = globalState.traceForNode(nextNode);
          if (trace?.result instanceof Anchor) {
            anchor3 = trace.result;
          }
        }

        if (anchor1 && anchor3) {
          handleIn.copy(anchor2.position).sub(anchor1.position);
          handleOut.copy(anchor3.position).sub(anchor2.position);
          const lengthIn = handleIn.length() / 3;
          const lengthOut = handleOut.length() / 3;
          handleIn.normalize();
          handleOut.normalize();
          handleOut.add(handleIn).normalize();
          handleIn.copy(handleOut).mulScalar(-lengthIn);
          handleOut.mulScalar(lengthOut);
        } else if (anchor1 && !anchor3) {
          // Last anchor in open path
          handleIn
            .copy(anchor1.position)
            .sub(anchor2.position)
            .mulScalar(1 / 3);
          handleOut.copy(handleIn).negate();
        } else if (anchor3 && !anchor1) {
          // First anchor in open path
          handleOut
            .copy(anchor3.position)
            .sub(anchor2.position)
            .mulScalar(1 / 3);
          handleIn.copy(handleOut).negate();
        }
      }
    }
  }

  const { args } = node.source.base;

  args.handleIn = new Expression(expressionCodeForVec(handleIn, 2));
  args.handleOut = new Expression(expressionCodeForVec(handleOut, 2));
  args.handleConstraint = new Expression(`"${constraint}"`);
};

export const nudgeSelection = (direction: Vec, faster: boolean) => {
  const { project } = globalState;

  if (project.selection.isImmutable()) return;

  const focusedComponent = project.focusedComponent();
  if (!focusedComponent) return;

  let increment: number;
  if (globalState.deviceStorage.gridSnappingEnabled) {
    increment = globalState.gridIncrement();
  } else {
    const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
    ({ increment } = viewport.precisionInfo());
  }
  if (faster) increment *= 10;

  const worldNudgeMatrix = AffineMatrix.fromTranslation(direction.clone().mulScalar(increment));

  const transformer = new SelectionTransformer(project.selection);
  transformer.transformWorld(worldNudgeMatrix);
};
