import * as modifiers from "./builtin-modifiers";
import * as primitives from "./builtin-primitives";
import * as shapes from "./builtin-shapes";
import { Expression } from "./expression";

// We want to ensure the interface never changes built-ins as that results in
// errors or weird behavior. So we'll deep freeze all of them to catch these
// errors early. Can be removed in production.
function deepFreeze<T extends object>(o: T): T {
  if (o instanceof Expression) {
    // Need to allow it to add _compiled but want to prevent jsCode from
    // changing.
    Object.defineProperty(o, "jsCode", { value: o.jsCode });
    return o;
  }
  Object.freeze(o);
  for (let name of Object.getOwnPropertyNames(o)) {
    const prop: unknown = o[name as keyof T];
    if (
      (typeof prop === "object" || typeof prop === "function") &&
      prop !== null &&
      !Object.isFrozen(prop)
    ) {
      deepFreeze(prop);
    }
  }
  return o;
}

export const allBuiltinPrimitives = Object.values(primitives);
export const allBuiltinShapes = Object.values(shapes);
export const allBuiltinModifiers = Object.values(modifiers);

export const allBuiltins = [...allBuiltinPrimitives, ...allBuiltinShapes, ...allBuiltinModifiers];
allBuiltins.forEach((definition) => {
  deepFreeze(definition);
});
