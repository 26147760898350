// Legacy Modifiers

import { DEFAULT_TOLERANCE } from "../geom";
import { Expression } from "./expression";
import { Modifier } from "./modifier";
import {
  makeBooleanParameter,
  makeDistanceParameter,
  makeScalarParameter,
  makeSelectParameter,
} from "./parameter";
import { registerBuiltin } from "./registry";

export const OldExpandDefinition = new Modifier();
OldExpandDefinition.isImmutable = true;
OldExpandDefinition.name = "Expand (old)";
OldExpandDefinition.parameters = [
  makeDistanceParameter("amount", 0.1),
  makeScalarParameter("miterLimit", 4),
  makeSelectParameter("join", "miter", ["miter", "round", "bevel"]),
];
OldExpandDefinition.code = new Expression(
  `if (amount === 0) return input;
if (amount < 0) return Contractold({ amount: -amount, miterLimit, join }, input);

return CompoundPath.booleanUnion([
  input,
  CompoundPath.stroke(input, { width: amount, miterLimit, join }),
]).copyStyle(input);`
);
OldExpandDefinition.icon = "expand";
registerBuiltin("ExpandDefinition", OldExpandDefinition);

export const OldContractDefinition = new Modifier();
OldContractDefinition.isImmutable = true;
OldContractDefinition.name = "Contract (old)";
OldContractDefinition.parameters = [
  makeDistanceParameter("amount", 0.1),
  makeScalarParameter("miterLimit", 4),
  makeSelectParameter("join", "miter", ["miter", "round", "bevel"]),
];
OldContractDefinition.code = new Expression(
  `if (amount === 0) return input;
if (amount < 0) return Expandold({ amount: -amount, miterLimit, join }, input);

return CompoundPath.booleanDifference([
  input,
  CompoundPath.stroke(input, { width: amount, miterLimit, join }),
]).copyStyle(input);`
);
OldContractDefinition.icon = "contract";
registerBuiltin("ContractDefinition", OldContractDefinition);

const OldJoinPathsDefinition = new Modifier();
OldJoinPathsDefinition.isImmutable = true;
OldJoinPathsDefinition.name = "Join Paths (old)";
OldJoinPathsDefinition.parameters = [makeBooleanParameter("closed", false)];
OldJoinPathsDefinition.code = new Expression("Path(input.allAnchors(), closed)");
registerBuiltin("JoinPathsDefinition", OldJoinPathsDefinition);

const OldMergePathsDefinition = new Modifier();
OldMergePathsDefinition.isImmutable = true;
OldMergePathsDefinition.name = "Merge Paths (old)";
OldMergePathsDefinition.parameters = [makeDistanceParameter("tolerance", DEFAULT_TOLERANCE)];
OldMergePathsDefinition.code = new Expression(
  `const groupByJoiningPaths = (paths, tolerance) => {
  if (paths.length <= 1) return paths;

  const toleranceSq = tolerance * tolerance;

  // Clone because we're mutating the input paths (e.g. reversing anchors).
  let inPaths = paths.map((path) => path.clone());
  let outPaths;
  while (true) {
    outPaths = [];
    for (let inPath of inPaths) {
      if (inPath.closed) {
        outPaths.push(inPath);
        continue;
      }

      const inStart = inPath.anchors[0];
      const inEnd = inPath.anchors[inPath.anchors.length - 1];

      let i = 0;
      for (let n = outPaths.length; i < n; ++i) {
        const outPath = outPaths[i];
        if (outPath.closed) continue;
        const outStart = outPath.anchors[0];
        const outEnd = outPath.anchors[outPath.anchors.length - 1];
        if (inStart.position.distanceSquared(outEnd.position) <= toleranceSq) {
          outEnd.handleOut.copy(inStart.handleOut);
          outPath.anchors.push(...inPath.anchors.slice(1));
          break;
        } else if (inStart.position.distanceSquared(outStart.position) <= toleranceSq) {
          outStart.handleIn.copy(inStart.handleOut);
          outPath.anchors.splice(0, 0, ...inPath.reverse().anchors.slice(0, -1));
          break;
        } else if (inEnd.position.distanceSquared(outStart.position) <= toleranceSq) {
          outStart.handleIn.copy(inEnd.handleIn);
          outPath.anchors.splice(0, 0, ...inPath.anchors.slice(0, -1));
          break;
        } else if (inEnd.position.distanceSquared(outEnd.position) <= toleranceSq) {
          outEnd.handleOut.copy(inEnd.handleIn);
          outPath.anchors.push(...inPath.reverse().anchors.slice(1));
          break;
        }
      }

      if (i === outPaths.length) {
        // The path was not merged. Append it to outPaths for the next interation.
        outPaths.push(inPath);
      }
    }

    if (outPaths.length === inPaths.length) break;
    inPaths = outPaths;
  }

  // Close any remaining paths that have matching endpoints
  for (let path of outPaths) {
    if (path.anchors.length > 1) {
      const start = path.anchors[0];
      const end = path.anchors[path.anchors.length - 1];
      if (start.position.distanceSquared(end.position) <= toleranceSq) {
        start.handleIn.copy(end.handleIn);
        path.anchors.splice(-1, 1);
        path.closed = true;
      }
    }
  }

  return new Group(outPaths);
}
return groupByJoiningPaths(input.allPaths(), tolerance);`
);
registerBuiltin("MergePathsDefinition", OldMergePathsDefinition);
