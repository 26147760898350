import m from "mithril";

import { Color } from "../../geom";
import { saturate } from "../../geom/math/scalar-math";
import { globalState } from "../../global-state";
import { Instance } from "../../model/instance";
import { Node } from "../../model/node";
import { SelectableInstance } from "../../model/selectable";
import { InstanceTrace } from "../../model/trace";
import { Icon20, IconName } from "../../shared/icon";
import { classNames, isNumber, isString } from "../../shared/util";
import { escapeStringLiteral } from "../../util";
import { POINTER_EVENT_BUTTONS_NONE } from "../util";
import { ModifierBadge } from "./outline-modifier-badge";

const selectableAttributes = (node: Node, instance: Instance, isError: boolean) => {
  const selectable = new SelectableInstance(node, instance);
  return {
    onpointerover: (event: PointerEvent) => {
      if (event.buttons === POINTER_EVENT_BUTTONS_NONE) {
        event.stopPropagation();
        if (globalState.project.selectableExistsAndIsValid(selectable)) {
          globalState.project.hoveredItem = selectable;
        }
      }
    },
    onpointerdown: (event: PointerEvent) => {
      event.stopPropagation();
      if (event.shiftKey) {
        globalState.project.toggleSelectItem(selectable);
      } else {
        globalState.project.selectItem(selectable);
      }
    },
    className: classNames({
      selected: globalState.project.selection.contains(selectable),
      "selected-indirect": globalState.project.selection.isInstanceIndirectlySelected(
        selectable.instance
      ),
      hovered: globalState.project.isItemHovered(selectable),
      disabled: !selectable.instance.isEnabled,
      error: isError,
    }),
  };
};

interface OutlineBadgesAttrs {
  node: Node;
}
export const OutlineBadges: m.Component<OutlineBadgesAttrs> = {
  view({ attrs: { node } }) {
    const trace = globalState.traceForNode(node);
    if (!trace) return;

    const { transform, fill, stroke } = trace;

    let mTransform: m.Child;
    let mFill: m.Child;
    let mStroke: m.Child;

    // Only show the transform badge if one or more args are not default.
    if (node.source.transform?.isEnabled) {
      mTransform = m(
        ".badge.transform",
        {
          ...selectableAttributes(node, node.source.transform, !transform?.isSuccess()),
          title: "Transform",
        },
        m(Icon20, { icon: "outline_transform" })
      );
    }

    if (node.source.fill?.isEnabled) {
      mFill = badgeForFill(node, node.source.fill, fill);
    }

    if (node.source.stroke?.isEnabled) {
      mStroke = badgeForStroke(node, node.source.stroke, stroke);
    }

    // To avoid the container margin, only return the container if there are
    // badges to show.
    if (!mTransform && !mFill && !mStroke && node.source.modifiers.length < 1) return;

    return [
      mTransform,
      mFill,
      mStroke,
      node.source.modifiers.map((modifier, index) => {
        return m(ModifierBadge, { node, modifier, index });
      }),
    ];
  },
};

const badgeForFill = (node: Node, instance: Instance, trace: InstanceTrace | undefined) => {
  const fillIsError = !trace || !trace.isSuccess();
  const attrs = selectableAttributes(node, instance, fillIsError);

  if (trace) {
    const fillType = trace.args.type?.evaluationResult;

    if (fillType === "color") {
      const fillColor = trace.args.color?.evaluationResult;
      if (fillColor === "none") {
        return m(
          ".badge",
          { ...attrs, title: "Fill None" },
          m(Icon20, { icon: "outline_fill_none" })
        );
      }
      if (fillColor instanceof Color) {
        let icon: IconName = "outline_fill";
        if (fillColor.r === 1 && fillColor.g === 1 && fillColor.b === 1 && fillColor.a === 1) {
          icon = "outline_fill_white";
        }
        return m(
          ".badge",
          {
            ...attrs,
            title: "Fill",
            style: { color: fillColor.toCSSString() },
          },
          m(Icon20, { icon })
        );
      }
    }

    if (fillType === "image") {
      const fillImage = trace.args.image?.evaluationResult;
      if (isString(fillImage)) {
        let opacity = 1;
        const fillOpacity = trace.args.opacity?.evaluationResult;
        if (isNumber(fillOpacity)) opacity = saturate(fillOpacity);
        return m(
          ".badge",
          {
            ...attrs,
            title: "Fill",
            style: { color: "transparent" },
          },
          [
            m(Icon20, { icon: "outline_fill" }),
            m(".image-fill", {
              style: { backgroundImage: `url("${escapeStringLiteral(fillImage)}")`, opacity },
            }),
          ]
        );
      }
    }
  }

  return m(".badge", { ...attrs, title: "Fill" }, m(Icon20, { icon: "outline_fill" }));
};

const badgeForStroke = (node: Node, instance: Instance, trace: InstanceTrace | undefined) => {
  const strokeIsError = !trace || !trace.isSuccess();
  const attrs = selectableAttributes(node, instance, strokeIsError);

  if (trace) {
    const strokeColor = trace.args.color?.evaluationResult;
    if (strokeColor === "none") {
      return m(
        ".badge",
        {
          ...attrs,
          title: "Stroke None",
        },
        m(Icon20, { icon: "outline_stroke_none" })
      );
    }

    if (strokeColor instanceof Color) {
      let icon: IconName = "outline_stroke";
      if (
        strokeColor.r === 1 &&
        strokeColor.g === 1 &&
        strokeColor.b === 1 &&
        strokeColor.a === 1
      ) {
        icon = "outline_stroke_white";
      }
      return m(
        ".badge",
        {
          ...attrs,
          title: "Stroke",
          style: { color: strokeColor.toCSSString() },
        },
        m(Icon20, { icon })
      );
    }
  }

  return m(".badge", { ...attrs, title: "Stroke" }, m(Icon20, { icon: "outline_stroke" }));
};
