import { assert } from "./geom";
import { Project } from "./model/project";
import { ProjectSnapshot } from "./model/snapshot";
import { isDevelopmentServer } from "./util";

const maxUndoStackSize = Infinity;

export class EditHistory {
  // Edit history state is stored in time order, so ordering all the states in
  // time would be: [...undoStack, currentSnapshot, ...redoStack]
  undoStack: EditHistoryState[];
  currentState: EditHistoryState;
  redoStack: EditHistoryState[];

  // TODO: Undos want to undo to the most newest project state before the edit,
  // but redos want to redo to the oldest state right after the edit. We
  // probably want to store both states on the stack.

  constructor(project: Project) {
    const snapshot = ProjectSnapshot.fromProject(project);
    this.currentState = new EditHistoryState(snapshot);
    this.undoStack = [];
    this.redoStack = [];
  }

  /**
   * Returns true if there's been a difference (and the snapshot was committed
   * or amended).
   */
  checkpoint(project: Project) {
    const snapshot = ProjectSnapshot.fromProject(project);

    // A common use case is to go back in time (undo a few times), copy
    // something (thus changing your selection), then redo back to where you
    // were and paste in the thing from the past. To accomodate this, we only
    // want to commit (and kill our redoStack) if there's been a material
    // change.
    const difference = this.currentState.end.difference(snapshot);

    // Sanity check to make sure our cache is being invalidated correctly. If
    // the cached and non-cached snapshots don't agree, then it might mean some
    // ModelObject cache is not being reset on change.
    if (isDevelopmentServer()) {
      const cachelessSnapshot = ProjectSnapshot.fromProject(project, { bypassReducerCache: true });
      assert(snapshot.encodedMaterialProject !== cachelessSnapshot.encodedMaterialProject);
      const cachelessDifference = this.currentState.end.difference(cachelessSnapshot);
      assert(difference === cachelessDifference, "Cached and non-cached snapshots are different!");
    }

    if (difference === "material") {
      this.commit(snapshot);
    } else if (difference === "immaterial") {
      // Amend so that current immaterial changes are preserved when this
      // snapshot is restored.
      this.amend(snapshot);
    }

    return difference;
  }

  private amend(snapshot: ProjectSnapshot) {
    this.currentState.amend(snapshot);
  }

  private commit(snapshot: ProjectSnapshot) {
    if (this.redoStack.length > 0) {
      console.log("Squashing redo stack.");
    }
    this.redoStack = [];
    this.undoStack.push(this.currentState);
    this.currentState = new EditHistoryState(snapshot);
    // Keep undoStack under the max stack size.
    if (this.undoStack.length > maxUndoStackSize) {
      // console.log("Undo stack at its size limit, removing a snapshot from the beginning.");
      this.undoStack.shift();
    }
  }

  hasUndo() {
    return this.undoStack.length > 0;
  }
  hasRedo() {
    return this.redoStack.length > 0;
  }

  undo() {
    if (!this.hasUndo()) throw "Nothing to undo";
    this.redoStack.unshift(this.currentState);
    this.currentState = this.undoStack.pop()!; // Pop cannot return undefined
    return this.currentState.end.toProject();
  }

  redo() {
    if (!this.hasRedo()) throw "Nothing to redo";
    this.undoStack.push(this.currentState);
    this.currentState = this.redoStack.shift()!; // Shift cannot return undefined
    return this.currentState.begin.toProject();
  }

  currentSnapshot() {
    return this.currentState.end;
  }
}

class EditHistoryState {
  begin: ProjectSnapshot;
  end: ProjectSnapshot;

  constructor(snapshot: ProjectSnapshot) {
    this.begin = snapshot;
    this.end = snapshot;
  }

  amend(snapshot: ProjectSnapshot) {
    this.end = snapshot;
  }
}
