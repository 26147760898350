import m from "mithril";

import { globalState } from "../../global-state";
import {
  FillDefinition,
  StrokeDefinition,
  TransformDefinition,
} from "../../model/builtin-primitives";
import { CodeComponent, Component } from "../../model/component";
import { Element } from "../../model/element";
import { DocumentationFocus } from "../../model/focus";
import { Instance } from "../../model/instance";
import { Node } from "../../model/node";
import { SelectableInstance } from "../../model/selectable";
import { InstanceTrace } from "../../model/trace";
import { classNames } from "../../shared/util";
import { ScopeRepeatModifiersInspector } from "../repeat-modifier-settings";
import { InstanceInspector } from "./instance-inspector";
import { InstanceInspectorMulti } from "./instance-inspector-multi";

interface InspectorAttrs {
  component: Component | CodeComponent;
  heightFactor?: number;
}
export const Inspector: m.Component<InspectorAttrs> = {
  view({ attrs: { component, heightFactor } }) {
    const style = heightFactor ? `flex-basis: ${(heightFactor * 100).toFixed(3)}vh` : "";

    const nodesSelection = globalState.project.selection.allNodes();
    const instancesSelection = globalState.project.selection.allInstances();

    const mContents: m.Children = [];

    const isEmbed = globalState.project.focus instanceof DocumentationFocus;
    const hideEmptyCategories = isEmbed;

    const hideProjectParams =
      hideEmptyCategories &&
      !globalState.project.definitionHasVisibleParameter(globalState.project);
    if (!hideProjectParams) {
      const projectInspector = m(InstanceInspector, {
        selectable: new SelectableProjectInstance(),
        rootComponent: component,
        isContext: true,
      });
      mContents.push(projectInspector);
    }

    const hideComponentParams =
      hideEmptyCategories && !globalState.project.definitionHasVisibleParameter(component);
    if (!hideComponentParams) {
      const focusedComponentInspector = m(InstanceInspector, {
        selectable: new SelectableComponentInstance(component),
        rootComponent: component,
        isContext: true,
      });
      mContents.push(focusedComponentInspector);
    }

    if (!nodesSelection.isEmpty()) {
      const parentContextsInspector = m(ParentContextsInspector, {
        node: nodesSelection.items[0].node,
        rootComponent: component,
      });
      mContents.push(parentContextsInspector);
    }

    if (instancesSelection.isSingle() && nodesSelection.isSingle()) {
      const selectable = instancesSelection.items[0];
      mContents.push(
        m(ScopeRepeatModifiersInspector, { selectable }),
        m(InstanceInspector, {
          selectable,
          rootComponent: component,
          isContext: false,
          isModifier: !selectable.isBase(),
        })
      );
    } else if (nodesSelection.isSingle()) {
      const { node } = nodesSelection.items[0];
      mContents.push(
        m(ScopeRepeatModifiersInspector, {
          selectable: new SelectableInstance(node, node.source.base),
        }),
        m(NodeInspector, { node, rootComponent: component })
      );
    } else if (nodesSelection.isMultiple() && !nodesSelection.hasAnchor()) {
      // Multi-selection fill and stroke
      const nodesInDocumentOrder = nodesSelection
        .sortInDocumentOrder()
        .items.map((selectableNode) => selectableNode.node);

      mContents.push(
        m(InstanceInspectorMulti, {
          nodesInDocumentOrder,
          slot: "fill",
          definition: FillDefinition,
          rootComponent: component,
        })
      );
      mContents.push(
        m(InstanceInspectorMulti, {
          nodesInDocumentOrder,
          slot: "stroke",
          definition: StrokeDefinition,
          rootComponent: component,
        })
      );
    }

    if (isEmbed && mContents.length === 0) return;

    return m(
      ".inspector",
      {
        className: classNames({ scrollable: !isEmbed }),
        style,
      },
      mContents
    );
  },
};

interface NodeInspectorAttrs {
  node: Node;
  rootComponent: Component | CodeComponent;
}
const NodeInspector: m.Component<NodeInspectorAttrs> = {
  view({ attrs: { node, rootComponent } }) {
    const selectable = new SelectableInstance(node, node.source.base);
    return [
      // Base
      m(InstanceInspector, { selectable, rootComponent }),

      // Transform
      !node.source.isPassThrough() &&
        m(InstanceInspectorMulti, {
          nodesInDocumentOrder: [node],
          slot: "transform",
          definition: TransformDefinition,
          rootComponent,
        }),

      // Fill and Stroke
      !node.isAnchor() && [
        m(InstanceInspectorMulti, {
          nodesInDocumentOrder: [node],
          slot: "fill",
          definition: FillDefinition,
          rootComponent,
        }),
        m(InstanceInspectorMulti, {
          nodesInDocumentOrder: [node],
          slot: "stroke",
          definition: StrokeDefinition,
          rootComponent,
        }),
      ],

      // Modifiers
      node.source.modifiers.map((instance) => {
        const selectable = new SelectableInstance(node, instance);
        return m(InstanceInspector, { selectable, rootComponent, isModifier: true });
      }),
    ];
  },
};

class SelectableProjectInstance extends SelectableInstance {
  // This is a one-off selectable class that shoehorns Project into a
  // SelectableInstance. It only serves to keep InstanceInspector's attributes
  // as simple as possible. It's kept local to avoid polluting the selection
  // model with one-off use cases.

  // NOTE: This class is not registered and should not be saved to a snapshot.

  constructor() {
    const instance = new Instance(globalState.project);
    // This dummy node should not actually be used.
    const dummyNode = new Node(null, new Element(instance));
    super(dummyNode, instance);
  }

  instanceTrace(): InstanceTrace | undefined {
    return globalState.projectTrace?.base;
  }
}

class SelectableComponentInstance extends SelectableInstance {
  // Another one-off selectable class, this time to shoehorn a Component into a
  // SelectableInstance. Components don't have a node unless they're focused,
  // and CodeComponents don't have a node even when focused. Component instances
  // are re-created on every evaluation, so we also make a dummy instance here.

  // NOTE: This class is not registered and should not be saved to a snapshot.

  component: Component | CodeComponent;

  constructor(component: Component | CodeComponent) {
    // This dummy node should not actually be used.
    const dummyInstance = new Instance(component);
    const dummyNode = new Node(null, new Element(dummyInstance));
    super(dummyNode, dummyInstance);
    this.component = component;
  }

  instanceTrace(): InstanceTrace | undefined {
    return globalState.traceForComponent(this.component);
  }
}

interface ParentContextsInspectorAttrs {
  node: Node;
  rootComponent: Component | CodeComponent;
}
export const ParentContextsInspector: m.Component<ParentContextsInspectorAttrs> = {
  view({ attrs: { node, rootComponent } }) {
    let mContents: m.Children = [];

    // Nested Component Contexts
    const contextNodes: Node[] = [];
    let nodeParent = node.parent;
    while (nodeParent) {
      if (nodeParent.createsNewContext()) {
        contextNodes.unshift(nodeParent);
      }
      nodeParent = nodeParent.parent;
    }
    for (let node of contextNodes) {
      const selectable = new SelectableInstance(node, node.source.base);
      mContents.push(m(InstanceInspector, { selectable, rootComponent }));
    }

    return mContents;
  },
};
