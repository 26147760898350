import {
  AffineMatrix,
  Anchor,
  Axis,
  BoundingBox,
  CompoundPath,
  CubicSegment,
  Geometry,
  Graphic,
  Group,
  LineSegment,
  Path,
  Ray,
  Stroke,
  TAU,
  Vec,
} from "../geom";
import { paintGraphicToCanvas, pathToCanvasPath } from "../geom/io/canvas";
import { globalState } from "../global-state";
import { Instance } from "../model/instance";
import { Node } from "../model/node";
import { contextMatrixForInstance, contextMatrixForNode } from "../model/transform-utils";
import styleConstants from "./style-constants";

export const ANCHOR_GRAPHIC_RADIUS = 3;
export const ANCHOR_GRAPHIC_RADIUS_SELECTED = 4;

export const paintNodeBaseToCanvas = (
  node: Node,
  viewMatrix: AffineMatrix,
  ctx: CanvasRenderingContext2D
) => {
  const trace = globalState.traceForNode(node);
  if (!trace) return;

  const graphic = (trace.stroke ?? trace.fill ?? trace.transform ?? trace.base).result;
  if (!graphic) return;

  const isSelected = globalState.project.selection.isNodeDirectlySelected(node);
  const isHovered = globalState.project.isNodeHovered(node);
  const isPreviewFill = isSelected && !node.source.hasEnabledModifier();

  let strokeColor = styleConstants.black;
  let strokeWidth = 1;

  if (isSelected) {
    strokeColor = styleConstants.blue63;
    strokeWidth = 2;
  } else if (isHovered) {
    strokeColor = styleConstants.blue53;
    strokeWidth = 2;
  }

  const contextMatrix = contextMatrixForNode(node);
  const contextViewMatrix = viewMatrix.clone().mul(contextMatrix);
  paintGeometryToCanvas(
    graphic,
    strokeColor,
    strokeWidth,
    isSelected,
    isPreviewFill,
    contextViewMatrix,
    ctx
  );
};

export const paintSelectedInstanceToCanvas = (
  node: Node,
  instance: Instance,
  viewMatrix: AffineMatrix,
  ctx: CanvasRenderingContext2D
) => {
  const trace = globalState.traceForNode(node);
  const instanceTrace = trace?.traceForInstance(instance);
  if (instanceTrace?.isSuccess()) {
    const contextMatrix = contextMatrixForInstance(node, instance);
    const contextViewMatrix = viewMatrix.clone().mul(contextMatrix);
    paintGeometryToCanvas(
      instanceTrace.result,
      styleConstants.green60,
      2,
      false,
      true,
      contextViewMatrix,
      ctx
    );
  }
};

export const paintStyledGraphicToCanvas = (
  graphic: Graphic,
  viewMatrix: AffineMatrix,
  ctx: CanvasRenderingContext2D,
  canvasSize: Vec,
  assignDefaultStrokeToUnstyled?: boolean
) => {
  const scaleFactor = viewMatrix.a; // We assume the matrix scale and translation only.
  const viewGraphic = graphic.clone().affineTransform(viewMatrix).scaleStroke(scaleFactor);
  if (assignDefaultStrokeToUnstyled && !viewGraphic.hasStyle()) {
    viewGraphic.assignStroke(new Stroke());
  }
  paintGraphicToCanvas(viewGraphic, ctx, canvasSize);
};

export const paintGeometryToCanvas = (
  geometry: Geometry | Vec,
  color: string,
  strokeWidth: number,
  isSelected: boolean,
  isPreviewFill: boolean,
  viewMatrix: AffineMatrix,
  ctx: CanvasRenderingContext2D
) => {
  // Group
  if (geometry instanceof Group) {
    for (let item of geometry.items) {
      paintGeometryToCanvas(item, color, strokeWidth, isSelected, isPreviewFill, viewMatrix, ctx);
    }
  }

  // Anchor
  else if (geometry instanceof Anchor) {
    const anchor = geometry.clone().affineTransform(viewMatrix);
    paintAnchorToCanvas(anchor, color, isSelected, ctx);
  }

  // Single Anchor Path
  else if (geometry instanceof Path && geometry.anchors.length === 1) {
    const anchor = geometry.anchors[0].clone().affineTransform(viewMatrix);
    paintAnchorToCanvas(anchor, color, isSelected, ctx);
  }

  // Paths
  else if (geometry instanceof Path || geometry instanceof CompoundPath) {
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.lineWidth = strokeWidth;
    ctx.strokeStyle = color;
    ctx.beginPath();

    const viewGraphic = geometry.clone().affineTransform(viewMatrix);
    pathToCanvasPath(viewGraphic, ctx);

    // Preview the filled area of closed path that do not already have a fill.
    // This is intended to give users a better sense of what's inside a path and
    // disambiguate between holes and contained paths.
    if (isPreviewFill) {
      isPreviewFill &&= geometry.fill === undefined;
      if (geometry instanceof CompoundPath) {
        // Don't preview the fill if the compound path contains open paths (such
        // as in single line font text).
        isPreviewFill &&= geometry.paths.every((path) => path.closed);
      } else {
        isPreviewFill &&= geometry.closed;
      }
      if (isPreviewFill) {
        ctx.fillStyle = color;
        ctx.globalAlpha = 0.06;
        ctx.fill("evenodd");
        ctx.globalAlpha = 1.0;
      }
    }

    ctx.stroke();
  }

  // Point
  else if (geometry instanceof Vec) {
    const center = geometry.clone().affineTransform(viewMatrix);
    paintDotToCanvas(center, 2.15, color, ctx);
  }

  // Axis
  else if (geometry instanceof Axis) {
    const axis = geometry.clone().affineTransform(viewMatrix);

    if (axis.direction.isZero()) return;

    const screenBox = new BoundingBox(new Vec(), new Vec(ctx.canvas.width, ctx.canvas.height));
    const points = axisBoundingBoxIntersectionPoints(axis, screenBox);
    if (points.length === 2) {
      const [p1, p2] = points;
      ctx.strokeStyle = color;
      ctx.lineCap = "square";
      ctx.lineWidth = strokeWidth;
      ctx.beginPath();
      ctx.moveTo(p1.x, p1.y);
      ctx.lineTo(p2.x, p2.y);
      ctx.stroke();
    }
  }

  // Ray
  else if (geometry instanceof Ray) {
    const axis = geometry.clone().affineTransform(viewMatrix);

    const { origin, direction } = axis;
    if (direction.isZero()) return;

    // TODO: Render rays in screen space by intersecting with the canvas
    // bounding rect.
    const scale = 100000 / direction.length();
    const dx = direction.x * scale;
    const dy = direction.y * scale;
    ctx.strokeStyle = color;
    ctx.lineCap = "butt";
    ctx.lineWidth = strokeWidth;
    ctx.beginPath();
    ctx.moveTo(origin.x, origin.y);
    ctx.lineTo(origin.x + dx, origin.y + dy);
    ctx.stroke();
  }

  // Line Segment
  else if (geometry instanceof LineSegment) {
    const segment = geometry.clone().affineTransform(viewMatrix);
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.lineWidth = strokeWidth;
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.moveTo(segment.s.x, segment.s.y);
    ctx.lineTo(segment.e.x, segment.e.y);
    ctx.stroke();
  }

  // Cubic Segment
  else if (geometry instanceof CubicSegment) {
    const segment = geometry.clone().affineTransform(viewMatrix);
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.lineWidth = strokeWidth;
    ctx.strokeStyle = color;
    ctx.beginPath();
    const { s, cs, ce, e } = segment;
    ctx.moveTo(s.x, s.y);
    ctx.bezierCurveTo(cs.x, cs.y, ce.x, ce.y, e.x, e.y);
    ctx.stroke();
  }
};

const paintAnchorToCanvas = (
  anchor: Anchor,
  color: string,
  isSelected: boolean,
  ctx: CanvasRenderingContext2D
) => {
  const cx = Math.floor(anchor.position.x) + 0.5;
  const cy = Math.floor(anchor.position.y) + 0.5;
  const radius = isSelected ? ANCHOR_GRAPHIC_RADIUS_SELECTED : ANCHOR_GRAPHIC_RADIUS;
  ctx.fillStyle = color;
  ctx.strokeStyle = styleConstants.white;
  ctx.lineWidth = 1;
  ctx.beginPath();
  if (anchor.hasTangentHandles()) {
    ctx.ellipse(cx, cy, radius, radius, 0, 0, TAU);
  } else {
    ctx.rect(cx - radius, cy - radius, radius * 2, radius * 2);
  }
  ctx.fill();
  ctx.stroke();
};

export const paintDotToCanvas = (
  center: Vec,
  radius: number,
  color: string,
  ctx: CanvasRenderingContext2D
) => {
  const cx = Math.floor(center.x) + 0.5;
  const cy = Math.floor(center.y) + 0.5;
  ctx.fillStyle = color;
  ctx.strokeStyle = styleConstants.white;
  ctx.lineWidth = 1;
  ctx.beginPath();
  ctx.ellipse(cx, cy, radius, radius, 0, 0, TAU);
  ctx.fill();
  ctx.stroke();
};

// TODO: Compute this more efficiently.
const axisBoundingBoxIntersectionPoints = (axis: Axis, box: BoundingBox) => {
  return Path.fromBoundingBox(box)
    .intersectionsWith([axis])
    .map((ix) => ix.position);
};
