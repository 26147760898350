import { AffineMatrix, fract, Unit, Vec } from "../../geom";
import { globalState } from "../../global-state";
import { Viewport } from "../../model/viewport";
import styleConstants from "../style-constants";
import { gridIntervalAndSubdivisions } from "./canvas-grid";

export const rulerWidthPixels = 20;
const rulerMajorTickPixels = 5;
const rulerMinorTickPixels = 4;
const rulerTextOffsetPixels = 2;

export const paintRulers = (
  viewMatrix: AffineMatrix,
  viewport: Viewport,
  viewportSize: Vec,
  units: Unit,
  divisions: "decimal" | "fractional",
  ctx: CanvasRenderingContext2D
) => {
  const inverseViewMatrix = viewMatrix.clone().invert();

  const worldMin = new Vec(0, 0).affineTransform(inverseViewMatrix);
  const worldMax = viewportSize.clone().affineTransform(inverseViewMatrix);

  const { interval, subdivisions } = gridIntervalAndSubdivisions(viewport, divisions);

  const worldMinGrid = worldMin.clone().divScalar(interval).floor().mulScalar(interval);
  const worldMaxGrid = worldMax.clone().divScalar(interval).ceil().mulScalar(interval);

  const majorStart = rulerWidthPixels - rulerMajorTickPixels;
  const majorEnd = rulerWidthPixels;
  const minorStart = rulerWidthPixels - rulerMinorTickPixels;
  const minorEnd = rulerWidthPixels;

  const pointerPos = globalState.canvasPointerPositionPixels;

  ctx.fillStyle = styleConstants.gray96;
  ctx.fillRect(0, 0, viewportSize.x, rulerWidthPixels);
  ctx.fillRect(0, 0, rulerWidthPixels, viewportSize.y);

  ctx.lineCap = "butt";
  ctx.lineWidth = 1.5;

  ctx.font = styleConstants.defaultFont12;
  ctx.textAlign = "center";
  ctx.textBaseline = "hanging";

  Horizontal: {
    Minor: {
      ctx.beginPath();
      for (let x = worldMinGrid.x; x < worldMaxGrid.x; x += interval) {
        for (let i = 1; i < subdivisions; i++) {
          const x2 = x + (i * interval) / subdivisions;
          const x2Pixels = new Vec(x2, 0).affineTransform(viewMatrix).x;
          if (x2Pixels > rulerWidthPixels) {
            ctx.moveTo(x2Pixels, minorStart);
            ctx.lineTo(x2Pixels, minorEnd);
          }
        }
      }
      ctx.strokeStyle = styleConstants.gray80;
      ctx.stroke();
    }
    Major: {
      ctx.beginPath();
      for (let x = worldMinGrid.x; x < worldMaxGrid.x; x += interval) {
        const xPixels = new Vec(x, 0).affineTransform(viewMatrix).x;
        if (xPixels > rulerWidthPixels) {
          // Skip the vertical ruler
          ctx.moveTo(xPixels, majorStart);
          ctx.lineTo(xPixels, majorEnd);
        }
      }
      ctx.strokeStyle = styleConstants.gray70;
      ctx.stroke();
    }
    Labels: {
      ctx.fillStyle = styleConstants.gray70;
      for (let x = worldMinGrid.x; x < worldMaxGrid.x; x += interval) {
        const xPixels = new Vec(x, 0).affineTransform(viewMatrix).x;
        if (xPixels > rulerWidthPixels) {
          const label = stringForRulerIncrement(x, units, divisions);
          ctx.fillText(label, xPixels, rulerTextOffsetPixels);
        }
      }
    }
  }

  Vertical: {
    Minor: {
      ctx.beginPath();
      for (let y = worldMinGrid.y; y < worldMaxGrid.y; y += interval) {
        for (let i = 1; i < subdivisions; i++) {
          const y2 = y + (i * interval) / subdivisions;
          const y2Pixels = new Vec(0, y2).affineTransform(viewMatrix).y;
          if (y2Pixels > rulerWidthPixels) {
            ctx.moveTo(minorStart, y2Pixels);
            ctx.lineTo(minorEnd, y2Pixels);
          }
        }
      }
      ctx.strokeStyle = styleConstants.gray80;
      ctx.stroke();
    }
    Major: {
      ctx.beginPath();
      for (let y = worldMinGrid.y; y < worldMaxGrid.y; y += interval) {
        const yPixels = new Vec(0, y).affineTransform(viewMatrix).y;
        if (yPixels > rulerWidthPixels) {
          // Skip the horizontal ruler
          ctx.moveTo(majorStart, yPixels);
          ctx.lineTo(majorEnd, yPixels);
        }
      }
      ctx.strokeStyle = styleConstants.gray70;
      ctx.stroke();
    }
    Labels: {
      ctx.fillStyle = styleConstants.gray70;
      for (let y = worldMinGrid.y; y < worldMaxGrid.y; y += interval) {
        const yPixels = new Vec(0, y).affineTransform(viewMatrix).y;
        if (yPixels > rulerWidthPixels) {
          const label = stringForRulerIncrement(y, units, divisions);
          ctx.save();
          ctx.translate(rulerTextOffsetPixels, yPixels);
          ctx.rotate(Math.PI * -0.5);
          ctx.fillText(label, 0, 0);
          ctx.restore();
        }
      }
    }
  }

  Pointer: {
    if (pointerPos && pointerPos.x > rulerWidthPixels && pointerPos.y > rulerWidthPixels) {
      ctx.beginPath();
      ctx.moveTo(pointerPos.x, 0);
      ctx.lineTo(pointerPos.x, rulerWidthPixels);
      ctx.moveTo(0, pointerPos.y);
      ctx.lineTo(rulerWidthPixels, pointerPos.y);
      ctx.strokeStyle = styleConstants.gray70;
      ctx.stroke();
    }
  }
};

export const stringForRulerIncrement = (
  increment: number,
  units: Unit,
  divisions: "decimal" | "fractional"
): string => {
  if (divisions === "fractional") {
    const wholePart = Math.trunc(increment);
    const fractPart = increment - wholePart;
    if (fractPart === 0) {
      return `${wholePart}${units}`;
    }
    for (let denominator = 2; denominator <= 4096; denominator *= 2) {
      const numerator = fractPart * denominator;
      if (Number.isInteger(numerator)) {
        if (wholePart === 0) {
          return `${numerator}/${denominator}${units}`;
        }
        return `${wholePart} ${Math.abs(numerator)}/${denominator}${units}`;
      }
    }
  }
  return parseFloat(increment.toFixed(12)).toString() + units;
};
