import m from "mithril";

import { unitForUnitName, unitNames, units } from "../geom";
import { globalState } from "../global-state";
import { ProjectSettings } from "../model/project";
import { canonicalOrigin } from "../shared/config";
import { canonicalUrlForForked } from "../shared/util";
import { Select } from "./basic/select";
import { TextInput } from "./basic/text-input";
import { stringForRulerIncrement } from "./canvas-ui/canvas-ruler";
import { DocEditorTitle } from "./doc-editor/doc-editor-title";

const rasterizeOptions = ["PDF Only", "PDF and SVG"];

interface SettingsAttrs {
  settings: ProjectSettings;
}
export const SettingsInspector: m.Component<SettingsAttrs> = {
  view({ attrs: { settings } }) {
    // TODO: The "forked from" info might want to be displayed somewhere more
    // prominent than in the project settings. Also not sure about language:
    // "forked from", "remixed from", etc.
    let mForkedFrom: m.Child = null;
    const forkedFrom = globalState.storage.getProjectForkedFrom();
    if (forkedFrom) {
      mForkedFrom = m(".project-settings-comment", [
        "Remixed from ",
        m(
          "a",
          { href: canonicalOrigin + canonicalUrlForForked(forkedFrom), target: "_blank" },
          forkedFrom.name
        ),
        " by ",
        // TODO: This will be a link to the user's profile page once we have
        // profile pages.
        m("u", forkedFrom.owner),
      ]);
    }

    const decimalGridUnitsOption = `Decimal (${stringForRulerIncrement(
      0.1,
      settings.units,
      "decimal"
    )})`;
    const fractionalGridUnitsOption = `Fractional (${stringForRulerIncrement(
      0.125,
      settings.units,
      "fractional"
    )})`;

    return m(".project-settings", [
      m(DocEditorTitle, {
        value: globalState.storage.getProjectName(),
        editable: globalState.storage.hasWritePermission(),
        onchange: (newValue) => globalState.storage.setProjectName(newValue),
      }),
      m(".editor-form", [
        mForkedFrom,
        m("h2", "Project Settings"),
        m(".editor-form-key-values", [
          m(".editor-form-key", "Units"),
          m(".editor-form-value", [
            m(Select, {
              options: units.map((u) => unitNames[u]),
              selected: unitNames[globalState.project.settings.units],
              onchange: (newValue) => {
                const units = unitForUnitName(newValue);
                globalState.deviceStorage.projectUnits = units;
                globalState.project.settings.units = units;
              },
            }),
          ]),
          m(".editor-form-key", "Grid Divisions"),
          m(".editor-form-value", [
            m(Select, {
              options: [decimalGridUnitsOption, fractionalGridUnitsOption],
              selected:
                settings.gridDivisions === "fractional"
                  ? fractionalGridUnitsOption
                  : decimalGridUnitsOption,
              onchange: (newValue) => {
                if (newValue === fractionalGridUnitsOption) {
                  settings.gridDivisions = "fractional";
                } else {
                  settings.gridDivisions = "decimal";
                }
              },
            }),
          ]),
        ]),
        m("h2", "Export Settings"),
        m(".editor-form-key-values", [
          m(
            ".editor-form-key",
            `Hairline Stroke Width (${unitNames[globalState.project.settings.units]})`
          ),
          m(".editor-form-value", [
            m(TextInput, {
              value: String(globalState.project.settings.hairlineStrokeWidth),
              onchange: (newValue) => {
                globalState.project.settings.hairlineStrokeWidth = parseFloat(newValue);
              },
            }),
          ]),
          m(".editor-form-comment", [
            "Hairline strokes will always appear thin in the Cuttle interface. This setting controls the ",
            m("em", "exported"),
            " stroke width of hairlines. Some laser cutters are strict about stroke widths that they recognize as cut paths.",
          ]),

          m(".editor-form-key", "Rasterize Images"),
          m(".editor-form-value", [
            m(Select, {
              options: rasterizeOptions,
              selected:
                rasterizeOptions[globalState.project.settings.rasterizeExportedImages ? 1 : 0],
              onchange: (newValue) => {
                globalState.project.settings.rasterizeExportedImages =
                  newValue === rasterizeOptions[1];
              },
            }),
          ]),
          m(".editor-form-comment", [
            "Removes transforms from all images and re-renders them at a fixed resolution. This setting is suggested when exporting images for laser engraving.",
          ]),

          m(".editor-form-key", "Rasterizing Resolution (pixels per inch)"),
          m(".editor-form-value", [
            m(TextInput, {
              value: String(globalState.project.settings.rasterizeExportedImagesPixelsPerInch),
              onchange: (newValue) =>
                (globalState.project.settings.rasterizeExportedImagesPixelsPerInch =
                  parseFloat(newValue)),
            }),
          ]),
          m(".editor-form-comment", [
            "When rasterizing, images will be resampled at this resolution.",
          ]),
        ]),
      ]),
    ]);
  },
};
