import type { FontFamily } from "./font-list";

let builtinFontFamilies: FontFamily[];

/**
 * Dynamically loaded via FontPicker's oninit.
 */
export async function getBuiltinFontsInfo() {
  if (builtinFontFamilies) return builtinFontFamilies;

  const [{ allGoogleFonts }, { allStrokeFonts }, { allProFonts }] = await Promise.all([
    import("../dynamic-import/builtin-fonts-google"),
    import("../dynamic-import/builtin-fonts-stroke"),
    import("../dynamic-import/builtin-fonts-pro"),
  ]);

  builtinFontFamilies = [...allGoogleFonts, ...allStrokeFonts, ...allProFonts];

  return builtinFontFamilies;
}
