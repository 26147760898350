import {
  CompoundPath,
  FontGlyph,
  Graphic,
  Group,
  RichText,
  RichTextGlyph,
  RichTextSymbol,
  Vec,
  isArray,
  isString,
} from "..";
import { logManager } from "../../log-manager";

/**
 * A range of characters that can be replaced with a different glyph.
 *
 * Used when used Opentype GSUB feature "aalt" ([Access All
 * Alternates](https://learn.microsoft.com/en-us/typography/opentype/otspec183/features_ae#tag-aalt))
 * is available.
 *
 * [Cuttle project for inspecting this metadata](https://cuttle.xyz/@forresto/Font-test-GSUB-aalt-EdtlJpCGUteG) of any font.
 *
 * @internal
 */
export interface GlyphRangeAlternateIndex {
  type: "alternateIndex";
  /** Code point of the first character in the range, most often `97` for `"a"`. */
  rangeStart: number;
  /** Code point of the first character in the range, most often `122` for `"z"`. */
  rangeEnd: number;
  /** Opentype feature's "alternateSets" index */
  alternateIndex: number;
}

/**
 * A range of characters that can be replaced with a different glyph.
 *
 * Simple enumeration of glyph indices, for when "aalt" metadata is missing.
 *
 * @internal
 */
export interface GlyphRangeIndices {
  type: "glyphIndices";
  /** Code point of the first character in the range, most often `97` for `"a"`. */
  rangeStart: number;
  /** Code point of the first character in the range, most often `122` for `"z"`. */
  rangeEnd: number;
  /** Array of glyph indices, most often in order from a-z. */
  glyphIndices: number[];
}

/**
 * A range of characters that can be replaced with a different glyph. The type
 * depends on the metadata that is embedded in the font.
 *
 * @internal
 */
export type GlyphAlternateRange = GlyphRangeAlternateIndex | GlyphRangeIndices;

/**
 * Named glyph alternate info that is embedded in pro fonts.
 *
 * @internal
 */
export interface GlyphAlternateInfo {
  leftTail?: Array<GlyphAlternateRange>;
  rightTail?: Array<GlyphAlternateRange>;
  rightHeart?: Array<GlyphAlternateRange>;
  skip?: Array<GlyphAlternateRange>;
}

/**
 * Glyph alternate names, that are the values the AlternateGlyphReplacements
 * pattern. See `Font.replacePatternsWithGlyphAlternates`.
 */
export type GlyphAlternateName = "leftTail" | "rightTail" | "rightHeart" | "skip"; // keyof GlyphAlternateInfo;

/**
 * Patterns for replacing characters with alternate glyphs. See
 * `Font.replacePatternsWithGlyphAlternates`.
 *
 * @internal
 */
export interface AlternateGlyphReplacements {
  wordStart?: GlyphAlternateName | Array<GlyphAlternateRange>;
  wordConnect?: GlyphAlternateName | Array<GlyphAlternateRange>;
  wordEnd?: GlyphAlternateName | Array<GlyphAlternateRange>;
}

/**
 * Glyph replacement pattern names, that are the keys of the AlternateGlyphReplacements pattern.
 */
export type ReplacementPattern = "wordStart" | "wordConnect" | "wordEnd"; // keyof AlternateGlyphReplacements;

/**
 * The result type for `Font.renderLine()`.
 */
export interface RenderLineResult {
  /**
   * The combined `advanceX` from all glyphs in this line of text. This roughly
   * amounts to a "width" of the line and is used when horizontally aligning
   * text.
   */
  advanceX: number;

  /**
   * Contains the same value as `advanceX`. This property is present for
   * compatibility with older modifiers.
   *
   * @deprecated
   */
  advanceWidth: number;

  /**
   * The geometry associated with this line of text.
   */
  geometry: Group;
}

interface SymbolPlacement {
  height: number;
  y: number;
  startPadding: number;
  endPadding: number;
}

export type StringGlyphRun = Array<string | RichTextGlyph>;

const blankGlyph: FontGlyph = new FontGlyph("", 0, 0, 0, new CompoundPath());

/**
 * A function that returns a graphic for a symbol. Symbols come from outside of
 * the font, so this function is needed to tell Cuttle where to find them.
 */
export type FontSymbolRetriever = (symbol: RichTextSymbol) => Graphic | undefined;

/**
 * Options for rendering text with a font.
 */
export interface FontRenderOptions {
  /**
   * Additional spacing to add in-between letters. If provided, this value will
   * be added to the advance width of every glyph.
   */
  letterSpacing?: number;
}

/**
 * The base class for all font faces. Internally, fonts can be either SVG or
 * OpenType. To instantiate a font, use
 *
 * ```
 * const myFont = getFontFromURL(font);
 * ```
 *
 * where `font` is the url parameter chosen by the font picker.
 */
export abstract class Font {
  /**
   * The family of the font (e.g. "Noto Sans")
   *
   * TODO: Remove `_family` and `_variant`. These are only populated in
   * OpenTypeFont. FontList should be responsible for font name association.
   *
   * @private
   */
  abstract _family: string;

  /**
   * The family of the font (e.g. "Regular")
   *
   * @private
   */
  abstract _variant: string;

  /**
   * The maximum height above the baseline of any glyph in the font. This value
   * is a scale relative to the font size.
   */
  abstract ascenderHeight: number;

  /**
   * The maximum height below the baseline of any glyph in the font. This value
   * is a scale relative to the font size.
   */
  abstract descenderHeight: number;

  /**
   * Most fonts are designed as filled shapes, but some are designed to be used
   * as open paths or "single line" fonts.
   */
  abstract isSingleLine: boolean;

  protected _symbolRetriever: FontSymbolRetriever;

  constructor(symbolRetriever: FontSymbolRetriever) {
    this._symbolRetriever = symbolRetriever;
  }

  /**
   * @internal
   */
  private _measuredSymbolPlacement?: SymbolPlacement;

  /**
   * Placement measurements come from the font's "O" glyph. Fonts without an "O"
   * glyph probably return a "tofu" block, which we'll measure. If we get blank
   * geometry, keep default placement values.
   *
   * @internal
   */
  protected _symbolPlacement() {
    if (this._measuredSymbolPlacement) {
      return this._measuredSymbolPlacement;
    }

    const oGlyph = this._glyphsFromTextString("O")[0];
    if (!oGlyph) return defaultSymbolPlacement;

    const bbox = oGlyph.geometry.boundingBox();
    if (!bbox) return defaultSymbolPlacement;

    return (this._measuredSymbolPlacement = {
      height: bbox.height(),
      y: bbox.max.y,
      startPadding: Math.max(minSymbolPadding, bbox.min.x),
      endPadding: Math.max(minSymbolPadding, oGlyph.advanceWidth - bbox.max.x),
    });
  }

  /**
   * Implementation is different for SVG and OpenType fonts.
   *
   * @internal
   */
  abstract _glyphsFromTextString(textString: string | StringGlyphRun): FontGlyph[];

  /**
   * Use when modifier will layout each glyph. Should not include newlines.
   * Accepts string or RichText.
   */
  glyphsFromString(text: string | RichText | unknown, options: FontRenderOptions = {}) {
    const richText = text instanceof RichText ? text : new RichText(text);

    // Group text into symbols and string/glyph runs
    const symbolsAndStringGlyphRuns: Array<RichTextSymbol | StringGlyphRun> = [];
    for (const item of richText.items) {
      if (item instanceof RichTextSymbol) {
        symbolsAndStringGlyphRuns.push(item);
      }
      if (isString(item) || item instanceof RichTextGlyph) {
        const previousItem = symbolsAndStringGlyphRuns[symbolsAndStringGlyphRuns.length - 1];
        if (previousItem && isArray(previousItem)) {
          previousItem.push(item);
        } else {
          symbolsAndStringGlyphRuns.push([item]);
        }
      }
    }

    const glyphs: FontGlyph[] = [];
    for (const item of symbolsAndStringGlyphRuns) {
      if (item instanceof RichTextSymbol) {
        glyphs.push(this._glyphForSymbol(item));
      } else {
        glyphs.push(...this._glyphsFromTextString(item));
      }
    }

    // Add letter spacing as a final step. In future this might end up as some
    // kind of font extension.
    const { letterSpacing = 0 } = options;
    if (letterSpacing !== 0) {
      for (let i = 0, n = glyphs.length - 1; i < n; ++i) {
        glyphs[i].advanceX += letterSpacing;
      }
    }

    return glyphs;
  }

  /**
   * Symbols come from outside the font, but need font-specific information for
   * placement.
   *
   * @returns a glyph for a RichTextSymbol, if one is found.
   * @private
   */
  private _glyphForSymbol(symbol: RichTextSymbol | unknown): FontGlyph {
    if (symbol instanceof RichTextSymbol) {
      const shape = this._symbolRetriever(symbol);
      const bbox = shape?.boundingBox();
      if (shape && bbox) {
        const { height, y, startPadding, endPadding } = this._symbolPlacement();
        const origin = new Vec(bbox.min.x, bbox.max.y);
        const scale = height / bbox.height();
        const translation = new Vec(startPadding, y);
        shape.transform({ origin, scale, position: translation });
        const advanceWidth = startPadding + bbox.width() * scale + endPadding;
        return new FontGlyph(
          "",
          0,
          advanceWidth,
          advanceWidth,
          CompoundPath.booleanUnion([shape], "evenodd")
        );
      } else {
        logManager.consoleWarn("Loading in progress, or no geometry found.");
        return blankGlyph.clone();
      }
    }
    logManager.consoleWarn("Unknown symbol type.");
    return blankGlyph.clone();
  }

  renderLine(textString: string | RichText, options: FontRenderOptions): RenderLineResult {
    const cuttleGlyphs = this.glyphsFromString(textString, options);
    const paths: Graphic[] = [];
    let x = 0;
    for (let glyph of cuttleGlyphs) {
      const { geometry, advanceX } = glyph;
      paths.push(geometry.transform({ position: new Vec(x, 0) }));
      x += advanceX;
    }
    return {
      geometry: new Group(paths),
      advanceX: x,
      advanceWidth: x,
    };
  }

  /**
   * Use when modifier will layout each line. Can include newlines. Casts input
   * to string or RichText.
   */
  render(text: string | RichText | unknown, options: FontRenderOptions) {
    const richText = isString(text) || text instanceof RichText ? text : new RichText(text);
    const splitText = richText.split("\n");
    const lines = splitText.map((line) => this.renderLine(line, options));
    return lines;
  }

  /**
   * This is a convenience method that will perform all the replacements on a
   * string, eg tails and hearts. Only available for select "pro" OpenType
   * fonts.
   *
   * Options for wordStart:
   *   - `"leftTail"`: Replace the first character of a word with tail+char
   *     (`~a`)
   *
   * Options for wordConnect:
   *   - `"rightHeart"`: Replace the last character of a word, and the space,
   *     with char+heart (`~a♥b~`)
   *   - `"auto"`: Follow the wordStart and wordEnd replacements (`~a~ ~b~`)
   *   - `"skip"`: Do not replace characters between words (`~a b~`)
   *
   * Options for wordEnd:
   *   - `"rightTail"`: Replace the last character of a word with char+tail
   *     (`b~`)
   *
   * A typical use of replacement patterns would be:
   *
   * ```
   * const swashed = myFont.replacePatternsWithGlyphAlternates(
   *   text,
   *   {
   *     wordStart: "leftTail",
   *     wordConnect: "rightHeart",
   *     wordEnd: "rightTail",
   *   }
   * );
   * ```
   *
   * @internal
   */
  abstract replacePatternsWithGlyphAlternates(
    text: string | RichText,
    replacements: AlternateGlyphReplacements
  ): RichText;
}

const minSymbolPadding = 0.05;

const defaultSymbolPlacement: SymbolPlacement = {
  height: 0.8,
  y: 0,
  startPadding: minSymbolPadding,
  endPadding: minSymbolPadding,
};
