import { Vec } from "..";

/**
 * ComponentWise generates a function that accepts either number and Vec
 * arguments.
 *
 * @returns A number if all arguments are numbers. If any argument is a Vec then
 * `fn` is applied component-wise to `x` and `y` and a Vec is returned.
 *
 * @internal
 */
export const componentWise1 = (fn: (a: number) => number) => {
  return (a: Vec | number) => {
    if (a instanceof Vec) {
      return new Vec(fn(a.x), fn(a.y));
    }
    return fn(a);
  };
};
export const componentWise2 = (fn: (a: number, b: number) => number) => {
  return (a: Vec | number, b: Vec | number) => {
    const isVec1 = a instanceof Vec;
    const isVec2 = b instanceof Vec;

    if (!isVec1 && !isVec2) {
      return fn(a as number, b as number);
    }

    const v1x = isVec1 ? (a as Vec).x : (a as number);
    const v1y = isVec1 ? (a as Vec).y : (a as number);
    const v2x = isVec2 ? (b as Vec).x : (b as number);
    const v2y = isVec2 ? (b as Vec).y : (b as number);

    return new Vec(fn(v1x, v2x), fn(v1y, v2y));
  };
};
export const componentWise3 = (fn: (a: number, b: number, c: number) => number) => {
  return (a: Vec | number, b: Vec | number, c: Vec | number) => {
    const isVec1 = a instanceof Vec;
    const isVec2 = b instanceof Vec;
    const isVec3 = c instanceof Vec;

    if (!isVec1 && !isVec2 && !isVec3) {
      return fn(a as number, b as number, c as number);
    }

    const v1x = isVec1 ? (a as Vec).x : (a as number);
    const v1y = isVec1 ? (a as Vec).y : (a as number);
    const v2x = isVec2 ? (b as Vec).x : (b as number);
    const v2y = isVec2 ? (b as Vec).y : (b as number);
    const v3x = isVec3 ? (c as Vec).x : (c as number);
    const v3y = isVec3 ? (c as Vec).y : (c as number);

    return new Vec(fn(v1x, v2x, v3x), fn(v1y, v2y, v3y));
  };
};
export const componentWiseN = (fn: (...args: number[]) => number) => {
  return (...args: (Vec | number)[]) => {
    const hasVec = args.some((arg) => arg instanceof Vec);
    if (!hasVec) {
      return fn.apply(undefined, args as number[]);
    }
    const xs: number[] = [];
    const ys: number[] = [];
    for (let arg of args) {
      const isVec = arg instanceof Vec;
      xs.push(isVec ? (arg as Vec).x : (arg as number));
      ys.push(isVec ? (arg as Vec).y : (arg as number));
    }
    return new Vec(fn.apply(undefined, xs), fn.apply(undefined, ys));
  };
};
