import { ModelObject } from "./model-object";
import { Parameter, ParameterFolder } from "./parameter";
import { ParameterInterface } from "./parameter-interface";

export abstract class InstanceDefinition extends ModelObject {
  name = "";

  parameters: Parameter[] = [];
  parameterFolders: ParameterFolder[] = [];

  // Pass-through indicates that this Component's parameters will be transformed
  // instead of its Transform. Elements with a Modifiers or Components
  // definition with `isPassTrough` cannot have Transforms.
  isPassThrough = false;
  isShowGuides = false;

  isImmutable = false;
  isDefaultUniformScale = true;

  comment?: string;

  materialKeys(): string[] {
    return [
      "name",
      "parameters",
      "parameterFolders",

      "isPassThrough",
      "isShowGuides",
      "isImmutable",
      "isDefaultUniformScale",

      "comment",
    ];
  }

  parameterWithName(name: string): Parameter | undefined {
    return this.allParameters().find((parameter) => parameter.name === name);
  }
  hasParameterWithName(name: string): boolean {
    return this.allParameters().some((parameter) => parameter.name === name);
  }

  firstParameterWithInterface(parameterInterfaceClass: typeof ParameterInterface) {
    return this.allParameters().find((p) => p.interface instanceof parameterInterfaceClass);
  }

  // Parameters can be nested inside folders, but frequently we want to iterate
  // over all of them.
  allParameters() {
    const parameters = this.parameters.slice();
    for (let folder of this.parameterFolders) {
      parameters.push(...folder.parameters);
    }
    return parameters;
  }
  hasParameters() {
    return (
      this.parameters.length > 0 ||
      this.parameterFolders.some((folder) => folder.parameters.length > 0)
    );
  }

  removeParameter(parameter: Parameter) {
    const index = this.parameters.indexOf(parameter);
    if (index >= 0) {
      this.parameters.splice(index, 1);
    } else {
      // Look in parameter folders.
      for (let folder of this.parameterFolders) {
        const index = folder.parameters.indexOf(parameter);
        if (index >= 0) {
          folder.parameters.splice(index, 1);
          break;
        }
      }
    }
  }
}
