import m from "mithril";
import {
  isKeyboardEventForCodeMirror,
  isKeyboardEventForPlainTextField,
  isMacPlatform,
} from "./util";

const keyboardSymbols: { [keyName: string]: string } = {
  backspace: "⌫",
};

interface AcceleratorOptions {
  command?: boolean | "optional";
  shift?: boolean;
  option?: boolean;
  displayOnly?: boolean;
}

const specialKeysByKeyCode: { [code: number]: string } = {
  106: "*",
  107: "+",
  109: "-",
  110: ".",
  111: "/",
  186: ";",
  187: "=",
  188: ",",
  189: "-",
  190: ".",
  191: "/",
  192: "`",
  219: "[",
  220: "\\",
  221: "]",
  222: "'",
};

const keyFromEvent = (event: KeyboardEvent) => {
  const specialKey = specialKeysByKeyCode[event.which];
  if (specialKey !== undefined) {
    return specialKey;
  }
  return event.key.toLowerCase();
};

export class Accelerator {
  key: string;

  command: boolean | "optional";
  shift: boolean;
  option: boolean;
  displayOnly: boolean;

  constructor(key: string, modifiers?: AcceleratorOptions) {
    this.key = key;
    this.command = modifiers?.command ?? false;
    this.shift = !!modifiers?.shift;
    this.option = !!modifiers?.option;
    this.displayOnly = !!modifiers?.displayOnly;
  }

  toString() {
    let result = "";
    const isMac = isMacPlatform();
    if (this.command === true) result += isMac ? "⌘" : "Ctrl+";
    if (this.shift) result += isMac ? "⇧" : "Shift+";
    if (this.option) result += isMac ? "⌥" : "Alt+";
    if (keyboardSymbols[this.key]) {
      result += keyboardSymbols[this.key];
    } else {
      result += this.key.toUpperCase();
    }
    return result;
  }

  matchesEvent(event: KeyboardEvent) {
    if (this.displayOnly) return false;
    const isMac = isMacPlatform();
    const matchesCommand =
      this.command === "optional" || (isMac ? event.metaKey : event.ctrlKey) === this.command;
    const matchesShift = event.shiftKey === this.shift;
    const matchesOption = event.altKey === this.option;
    const matchesKey = keyFromEvent(event) === this.key;
    return matchesCommand && matchesShift && matchesOption && matchesKey;
  }
}

interface Shortcut {
  accelerator: Accelerator;
  action: () => void;
}

const shortcutRegistry: Shortcut[] = [];

export const registerKeyboardShortcut = (accelerator: Accelerator, action: () => void) => {
  if (accelerator.displayOnly) return;
  shortcutRegistry.push({ accelerator, action });
};

/**
 * Executes the keyboard handler for a keydown event if appropriate. Returns
 * true if the keyboard event is handled.
 */
export const handleAcceleratorKeyDownEvent = (event: KeyboardEvent) => {
  // If in a plain text field we want the text field to handle all keyboard
  // events, so we never do.
  if (isKeyboardEventForPlainTextField(event)) {
    return false;
  }

  // Sometimes a CodeMirror is focused when it's not the center of your
  // attention (e.g. after you just scrubbed a number), so we still want to
  // handle e.g. Command+G to group. We also want to handle the undo shortcuts
  // in a CodeMirror (we're not using CodeMirror's undo stack). So, if in a
  // CodeMirror, we want to handle command/ctrl events that CodeMirror does not
  // specifically handle. We made CodeMirror do stopPropagation on its
  // "keyHandled" events, so we just need to check if command/ctrl is held.
  if (isKeyboardEventForCodeMirror(event)) {
    const isMac = isMacPlatform();
    const isCommand = isMac ? event.metaKey : event.ctrlKey;
    if (!isCommand) {
      return false;
    }
  }

  // Try to find a registered keyboard shortcut to handle the event.
  for (let { accelerator, action } of shortcutRegistry) {
    if (accelerator.matchesEvent(event)) {
      event.preventDefault();
      console.log("[Keyboard Shortcut]", accelerator.toString());
      action();
      m.redraw();
      return true;
    }
  }

  return false;
};
