import m from "mithril";

import { ShareStatus, TagInfo, UploadedFont } from "../shared/api-types";
import { uuid } from "../shared/uuid-v4";
import { ProjectSnapshot } from "../model/snapshot";
import type { SnapshotPayload, Storage } from "./storage-types";

const localTestFontUploads: UploadedFont[] = [
  {
    family: "Custom Font",
    variant: "unavailable",
    hash: "0000",
  },
];
const localTestFontURLs: Map<string, string> = new Map();

export class StorageLocal implements Storage {
  readonly type = "local";
  private _projectName = "Local Project";

  async init() {
    const storageValue = window.localStorage.getItem("cuttle");
    if (storageValue) {
      const projectSnapshot = ProjectSnapshot.fromString(storageValue, {
        defaultProjectId: "LOCAL-" + uuid(),
      });
      if (projectSnapshot) {
        return projectSnapshot;
      }
    }
    return undefined;
  }
  async checkpoint(snapshotPayload: SnapshotPayload) {
    const { projectSnapshot } = snapshotPayload;
    window.localStorage.setItem("cuttle", projectSnapshot.toString());
  }
  async saveCopy(snapshotPayload: SnapshotPayload) {
    console.warn("saveCopy, running locally");
    return true;
  }
  isSynchronized() {
    return true;
  }
  async forceSave() {
    console.warn("forceSave, running locally");
    return;
  }
  async saveVersion(snapshotPayload: SnapshotPayload) {
    console.warn("Could not complete action, running locally");
    return 0;
  }
  async uploadFile(file: Blob) {
    console.warn("Locally simulating an upload and resolving a data url of the file.");
    const reader = new FileReader();
    return new Promise<string>((accept, fail) => {
      reader.onload = () => accept(reader.result as string);
      reader.onerror = () => fail(reader.error);
      // Some extra delay to make the asynchronicity visible
      setTimeout(() => reader.readAsDataURL(file), 5000);
    });
  }
  async addFontForLoggedInUser(options: {
    file: File;
    hash: string;
    family: string;
    variant: string;
    fileExtension: string;
  }) {
    console.warn("Locally simulating a font upload.");

    const { file, hash, family, variant } = options;

    if (localTestFontURLs.has(hash)) {
      return;
    }

    const dataURL = await this.uploadFile(file);
    localTestFontUploads.push({
      family: family,
      variant: variant,
      hash: hash,
    });
    localTestFontURLs.set(hash, dataURL);
    return;
  }
  async refreshCustomFonts() {
    console.warn("Locally simulating refreshing fonts.");
    return;
  }
  getCustomFonts() {
    return localTestFontUploads;
  }
  async getFontByHash(
    hash: string
  ): Promise<{ url: string | null; family: string; variant: string } | null> {
    const url = localTestFontURLs.get(hash);
    const font = localTestFontUploads.find((font) => font.hash === hash);
    return url
      ? {
          family: font?.family || "",
          variant: font?.variant || "",
          url,
        }
      : null;
  }
  async removeFontForLoggedInUser(hash: string) {
    console.warn("Locally simulating a font removal.");
    localTestFontUploads.splice(
      localTestFontUploads.findIndex((font) => font.hash === hash),
      1
    );
    localTestFontURLs.delete(hash);
    return;
  }
  getSnapshots() {
    return undefined;
  }
  getCurrentSnapshotId() {
    return undefined;
  }
  getPublishedSnapshotId() {
    return undefined;
  }
  async refreshSnapshots() {
    return [];
  }
  async restoreSnapshot() {
    console.warn("Could not complete action, running locally");
    return undefined;
  }
  async deleteSnapshot() {
    console.warn("Could not complete action, running locally");
  }
  async setPublishedSnapshot() {
    console.warn("Could not complete action, running locally");
  }
  async updateSnapshotDescription() {
    console.warn("Could not complete action, running locally");
  }
  getProjectUrl() {
    return "https://localhost.cuttle.xyz:8000/@dev/name-id";
  }
  getProjectId() {
    return "LOCAL";
  }
  getProjectName() {
    return this._projectName;
  }
  setProjectName(newName: string) {
    this._projectName = newName;
  }
  hasWritePermission() {
    return true;
  }
  getShareStatus(): ShareStatus {
    return "private";
  }
  setShareStatus(newStatus: ShareStatus) {
    console.warn("Could not complete action, running locally");
  }
  getProjectOwner() {
    return "Local";
  }
  getProjectOwnerDisplayName() {
    return undefined;
  }
  getProjectOwnerAvatar() {
    return "";
  }
  getProjectFirstMadePublicDate() {
    return undefined;
  }
  getProjectPublishedDate() {
    return undefined;
  }
  getProjectForkedFrom() {
    return undefined;
    // Testing
    // return {
    //   name: "Forked From Test Project",
    //   owner: "original_author",
    //   projectId: "0000",
    //   projectUrl: "https://cuttle.xyz/",
    // };
  }
  _projectTags: TagInfo[] = [{ tagId: 1, tagName: "Tag 1", slug: "tag-1" }];
  getProjectTags() {
    return this._projectTags;
  }
  async setProjectTags(tags: TagInfo[]) {
    this._projectTags = tags;
    return;
  }
  sequenceToLoad() {
    return undefined;
  }
  getAccessError() {
    return undefined;
  }
  isOfficialCuttleTemplate() {
    return true;
  }
  isProTemplate() {
    return false;
  }

  getAllTags() {
    return [
      { tagId: 1, tagName: "Tag 1", slug: "tag-1" },
      { tagId: 2, tagName: "Tag 2", slug: "tag-2" },
      { tagId: 3, tagName: "Tag 3", slug: "tag-3" },
    ];
  }
}
