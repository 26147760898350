import m from "mithril";

import { ProFeatureButton } from "../shared/feature-check";
import { fontMimeTypesByFileExtension } from "../shared/file-types";
import { Tooltipped } from "../shared/popup";
import { importFontFilesToProject } from "./import-to-project";
import { pickFile } from "./util";

/** Custom font uploader. Pro feature! 👑 */
export const FontUploader: m.ClosureComponent<{
  onSuccess: (eagerFontURL: string) => void;
  onFail: (err: any) => void;
}> = () => {
  let uploadInProgress = false;
  return {
    view({ attrs: { onSuccess, onFail } }) {
      async function clickUpload() {
        // NOTE: OSes are less consistant with font file types, so we use file
        // extensions for the picker's `accept` attribute.
        const fontFileList = await pickFile({
          accept: Object.keys(fontMimeTypesByFileExtension)
            .map((ext) => "." + ext)
            .join(","),
          multiple: true,
        });
        if (fontFileList && fontFileList.length > 0) {
          uploadInProgress = true;
          m.redraw();

          try {
            const fonts = await importFontFilesToProject(Array.from(fontFileList));
            onSuccess(fonts[0].hash);
          } catch (err) {
            onFail(err);
          }

          uploadInProgress = false;
          m.redraw();
        }
      }

      return m(
        ".font-uploader",
        m(
          Tooltipped,
          {
            message: () => [
              "Choose a font to add to your account.",
              m("br"),
              "Supported font types: ttc, ttf, otf, woff.",
            ],
            placement: "bottom",
          },
          m(
            ProFeatureButton,
            {
              className: "secondary",
              feature: "fonts",
              onClick: clickUpload,
              disabled: uploadInProgress,
            },
            uploadInProgress ? "Uploading…" : "Add Font"
          )
        )
      );
    },
  };
};
