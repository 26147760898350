import { AffineMatrix, TAU, Vec } from "../../geom";
import { globalState } from "../../global-state";
import { CanvasBaseUI, FocusedGeometryUI } from "../canvas-ui/canvas-base-interface";
import { CanvasInterfaceElement } from "../canvas-ui/canvas-interface-element";
import {
  GhostGeometryUI,
  HoveredGeometryUI,
  SnappingGeometryUI,
  StyledNodeGeometryUI,
  UnstyledNodeGeometryUI,
} from "../canvas-ui/geometry-interface";
import { GuidesUI } from "../canvas-ui/guides-interface";
import { SelectionOrComponentHandlesUI } from "../canvas-ui/handles-interface";
import { FocusedPathSegmentsUI, PathSegmentUI } from "../canvas-ui/path-segment-interface";
import { TransformCenterUI } from "../canvas-ui/transform-center-interface";
import styleConstants from "../style-constants";
import { alignToPixelCenter, worldPositionFromEvent } from "../util";
import { startSelectionTransformDrag } from "./selection-drag";
import { Tool } from "./shared";

export class TransformTool implements Tool {
  allowRotate: boolean;
  allowScale: boolean;
  uniformScale: boolean;

  constructor(allowRotate: boolean, allowScale: boolean, uniformScale: boolean) {
    this.allowRotate = allowRotate;
    this.allowScale = allowScale;
    this.uniformScale = uniformScale;
  }

  isDisabled() {
    const { selection } = globalState.project;

    if (selection.isEmpty()) return true;

    const isPositionLocked = selection.isPositionLocked();
    const allowNonUniformScale = this.allowScale && !this.uniformScale;

    // Non-uniform scale can introduce a matrix transform, which would delete
    // the position argument.
    if (allowNonUniformScale && isPositionLocked) {
      return true;
    }

    // Non-uniform scale is incompatible with nodes that have a locked aspect
    // ratio.
    if (allowNonUniformScale && selection.isUniformScale()) {
      return true;
    }

    // An off-center rotation or scale would modify the position. This is common
    // when multiple nodes are selected.
    if ((this.allowRotate || this.allowScale) && isPositionLocked && !selection.isSingle()) {
      return true;
    }
    if ((this.allowRotate || allowNonUniformScale) && selection.isRotationLocked()) {
      return true;
    }
    if (this.allowScale && selection.isScaleLocked()) {
      return true;
    }

    return false;
  }

  interface() {
    const { project } = globalState;

    const interactableNodesSelection = project.interactableNodesToShow();

    const interfaceElems: CanvasInterfaceElement[] = [
      new TransformCanvasBaseUI(this),
      new FocusedGeometryUI(),
      new FocusedPathSegmentsUI(PathSegmentUI),
      ...interactableNodesSelection.items.map((item) => new StyledNodeGeometryUI(item.node)),
      ...interactableNodesSelection.items.map((item) => new SnappingGeometryUI(item.node)),
      new GhostGeometryUI(),
    ];

    if (!globalState.isPickerOpen) {
      const selectionNodes = globalState.project.selection
        .allNodesAndInstancesToShow()
        .directlySelectedNodes();
      for (let item of selectionNodes.items) {
        interfaceElems.push(new UnstyledNodeGeometryUI(item.node));
      }
    }

    interfaceElems.push(
      new GuidesUI(),
      new HoveredGeometryUI(),
      new TransformCenterUI(),
      new SelectionOrComponentHandlesUI(false),
      new TransformDragUI()
    );

    return interfaceElems;
  }

  onCanvasPointerDown(event: PointerEvent) {
    startSelectionTransformDrag(
      event,
      globalState.project.selection,
      this.allowRotate,
      this.allowScale,
      this.uniformScale
    );
  }

  onPointerMove(event: PointerEvent) {
    if (!globalState.canvasPointerIsDown && globalState.deviceStorage.geometrySnappingEnabled) {
      globalState.snapping.cacheSnappingDataForInterface(globalState.interfaceElements, {
        isSource: true,
      });
      globalState.snapping.updateSnappingPointNearWorldPosition(worldPositionFromEvent(event), {
        isSource: true,
      });
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      globalState.activateTool("Select");
    }
  }
}

class TransformCanvasBaseUI extends CanvasBaseUI {
  private transformTool: TransformTool;

  constructor(transformTool: TransformTool) {
    super();
    this.transformTool = transformTool;
  }

  onPointerDown(event: PointerEvent) {
    this.transformTool.onCanvasPointerDown(event);
  }
}

class TransformDragUI implements CanvasInterfaceElement {
  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    const transformCenter = globalState.project.transformCenter();
    if (!transformCenter) return;

    const center = transformCenter.worldPosition.clone().affineTransform(viewMatrix);
    alignToPixelCenter(center);
    ctx.beginPath();
    ctx.ellipse(center.x, center.y, 2.5, 2.5, 0, 0, TAU);
    ctx.fillStyle = styleConstants.blue53;
    ctx.fill();

    let startPos: Vec | undefined;
    let currentPos: Vec | undefined;

    const { canvasDrag } = globalState;
    if (canvasDrag) {
      startPos = canvasDrag.startPoint.worldPosition.clone().affineTransform(viewMatrix);
      alignToPixelCenter(startPos);
      currentPos = canvasDrag.currentPoint.worldPosition.clone().affineTransform(viewMatrix);
      alignToPixelCenter(currentPos);
    } else if (globalState.canvasPointerPositionPixels) {
      startPos = alignToPixelCenter(globalState.canvasPointerPositionPixels.clone());
    }

    let snappingPos: Vec | undefined;
    if (globalState.snapping.currentPoint) {
      snappingPos = globalState.snapping.currentPoint.worldPosition
        .clone()
        .affineTransform(viewMatrix);
      alignToPixelCenter(snappingPos);
    }

    ctx.lineWidth = 1;

    if (startPos) {
      ctx.beginPath();
      ctx.moveTo(center.x, center.y);
      ctx.lineTo(startPos.x, startPos.y);
      const color = currentPos ? styleConstants.gray50 : styleConstants.blue53;
      ctx.strokeStyle = color;
      ctx.stroke();
      if (!snappingPos || !snappingPos.equals(startPos)) {
        ctx.beginPath();
        ctx.ellipse(startPos.x, startPos.y, 2.5, 2.5, 0, 0, TAU);
        ctx.fillStyle = color;
        ctx.fill();
      }
    }

    if (currentPos) {
      ctx.beginPath();
      ctx.moveTo(center.x, center.y);
      ctx.lineTo(currentPos.x, currentPos.y);
      ctx.strokeStyle = styleConstants.blue53;
      ctx.stroke();
      if (!snappingPos || !snappingPos.equals(currentPos)) {
        ctx.beginPath();
        ctx.ellipse(currentPos.x, currentPos.y, 2.5, 2.5, 0, 0, TAU);
        ctx.fillStyle = styleConstants.blue53;
        ctx.fill();
      }
    }
  }

  snappingReferencePoints(isSource: boolean) {
    const transformCenter = globalState.project.transformCenter();
    return transformCenter?.worldPosition;
  }
}
