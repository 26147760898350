import { isValidUnit, Unit } from "../geom/io/units";
import { isValidPostprocessSVG, PostprocessSVG } from "../model/export-format";
import { isBoolean, isNumber, isObject } from "../shared/util";

const LOCAL_STORAGE_KEY = "cuttleDeviceStorage";

type ScrollBehavior = "pan" | "zoom";
type RealSizeCalibrations = { [key: string]: number };

function isValidRealSizeCalibrations(calibrations: unknown): calibrations is RealSizeCalibrations {
  return (
    typeof calibrations === "object" &&
    calibrations !== null &&
    Object.entries(calibrations).every(
      ([key, value]) => typeof key === "string" && typeof value === "number"
    )
  );
}

function isValidScrollBehavior(value: unknown): value is ScrollBehavior {
  return typeof value === "string" && (value === "pan" || value === "zoom");
}

/**
 * DeviceStorage is read once on load, and written with document
 * visibilitychange. So, the last Cuttle window closed will win.
 */
class DeviceStorage {
  static version = 1;

  // Calibration Defaults
  realSizeCalibrations: RealSizeCalibrations = {};
  scrollBehavior: ScrollBehavior = "pan";
  pointerInitShown = false;
  showAdminFeatures = false;

  // Preference Defaults
  rightSidebarWidth = 500;
  rightSidebarSplit = 0.333;
  projectUnits: Unit = "in";
  gridSnappingEnabled = false;
  geometrySnappingEnabled = true;

  // Export options
  svgPostprocess: PostprocessSVG = "none";

  constructor() {
    try {
      const fromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (fromLocalStorage === null) {
        throw new Error("First load or local storage item not found.");
      }
      const parsedLocalStorage: unknown = JSON.parse(fromLocalStorage);
      if (!isObject(parsedLocalStorage)) {
        throw new Error("Device storage parse failed.");
      }
      let { version } = parsedLocalStorage;
      if (typeof version !== "number") {
        throw new Error("Device storage is missing version.");
      }
      // Upgrade versions: do these migrations before verifying local storage.
      if (version === 0) {
        version = 1;
      }

      // Type check local storage vals and write them to this.
      const {
        rightSidebarWidth,
        rightSidebarSplit,
        realSizeCalibrations,
        scrollBehavior,
        projectUnits,
        hairlineStrokeWidth,
        pointerInitShown,
        showAdminFeatures,
        gridSnappingEnabled,
        geometrySnappingEnabled,
        svgPostprocess,
      } = parsedLocalStorage;
      if (isNumber(rightSidebarWidth)) {
        this.rightSidebarWidth = rightSidebarWidth;
      }
      if (isNumber(rightSidebarSplit)) {
        this.rightSidebarSplit = rightSidebarSplit;
      }
      if (isValidRealSizeCalibrations(realSizeCalibrations)) {
        this.realSizeCalibrations = realSizeCalibrations;
      }
      if (isValidScrollBehavior(scrollBehavior)) {
        this.scrollBehavior = scrollBehavior;
      }
      if (isValidUnit(projectUnits)) {
        this.projectUnits = projectUnits;
      }
      if (isBoolean(pointerInitShown)) {
        this.pointerInitShown = pointerInitShown;
      }
      if (isBoolean(showAdminFeatures)) {
        this.showAdminFeatures = showAdminFeatures;
      }
      if (isBoolean(gridSnappingEnabled)) {
        this.gridSnappingEnabled = gridSnappingEnabled;
      }
      if (isBoolean(geometrySnappingEnabled)) {
        this.geometrySnappingEnabled = geometrySnappingEnabled;
      }
      if (isValidPostprocessSVG(svgPostprocess)) {
        this.svgPostprocess = svgPostprocess;
      }
    } catch (error) {
      console.warn(error, "Continue with defaults.");
    }

    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        // Persist device storage when the page becomes invisible (the user
        // navigates away, reloads, or closes the tab)
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.toJSON()));
      }
    });
  }
  toJSON() {
    return { version: DeviceStorage.version, ...this };
  }
  private currentScreenKey() {
    // `window.screen` dimensions are "css pixels." Added `devicePixelRatio` to
    // the key for the case of a 1080p laptop with 4k external monitor.
    const { width, height } = window.screen;
    const devicePixelRatio = window.devicePixelRatio || 1;
    return `${width}x${height}@${devicePixelRatio}`;
  }
  currentScreenPPI(): number | undefined {
    return this.realSizeCalibrations[this.currentScreenKey()];
  }
  setCurrentScreenPPI(value: number) {
    this.realSizeCalibrations[this.currentScreenKey()] = value;
  }
}

export const deviceStorage = new DeviceStorage();
