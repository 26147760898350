import m from "mithril";
import { Parameter } from "../model/parameter";
import { modalState } from "../shared/modal";
import { globalState } from "../global-state";
import { PIImage } from "../model/parameter-interface";
import { classNames } from "../shared/util";

interface SaveConfirmModalAttrs {
  changedParameters: Parameter[];
  /** Called after changes are reset or saved. Not called if modal is dismissed. */
  onClose?: () => void;
}

export const SaveConfirmModal: m.Component<SaveConfirmModalAttrs> = {
  view({ attrs: { changedParameters, onClose } }) {
    const closeModal = () => {
      modalState.close();
    };
    const onClickSave = () => {
      // We don't want local images in edit mode.
      revertImages(changedParameters);
      globalState.project.setParameterDefaults();
      globalState.enterEditingMode();
      closeModal();
      onClose?.();
    };
    const onClickReset = () => {
      globalState.enterEditingMode();
      closeModal();
      onClose?.();
    };
    const changedImageParameters: Array<Parameter> = [];
    const changedOtherParameters: Array<Parameter> = [];
    changedParameters.forEach((parameter) => {
      if (parameter.interface instanceof PIImage) {
        changedImageParameters.push(parameter);
      } else {
        changedOtherParameters.push(parameter);
      }
    });
    const hasImageChange = changedImageParameters.length > 0;
    const hasOtherChange = changedOtherParameters.length > 0;
    return m(".modal-box.save-confirm-modal", [
      hasOtherChange && [
        m("p", "You’ve changed parameters while in packaged view."),
        parameterNameList(changedOtherParameters),
      ],
      hasImageChange && [
        m("p", "Note: changes to image parameters will be automatically cleared."),
        parameterNameList(changedImageParameters),
      ],
      m(".save-confirm-modal-actions", [
        m("button.secondary", { onclick: closeModal }, "Cancel"),
        m(
          "button.secondary",
          { className: classNames({ "full-width": !hasOtherChange }), onclick: onClickReset },
          "Clear changes"
        ),
        // We only save non-image changes.
        hasOtherChange && m("button", { onclick: onClickSave }, "Keep changes"),
      ]),
    ]);
  },
};

export const enterEditingModeWithSaveConfirm = (onEnter?: () => void) => {
  const changedParameters = allParameters().filter((p) => p.isOverridden());
  if (globalState.storage.hasWritePermission()) {
    if (changedParameters.length > 0) {
      // Prompt the user to save or discard parameter changes.
      modalState.open({
        modalView: () => m(SaveConfirmModal, { changedParameters, onClose: onEnter }),
      });
      return;
    }
  } else {
    // We don't want local images in edit mode.
    revertImages(changedParameters);
    // Always save parameter defaults when editing another user's project.
    globalState.project.setParameterDefaults();
  }

  globalState.enterEditingMode();
  onEnter?.();
};

const allParameters = () => {
  return [
    ...globalState.project.allParameters(),
    ...globalState.project.components.flatMap((component) => component.allParameters()),
  ];
};

const revertImages = (parameters: Array<Parameter>) => {
  parameters.forEach((parameter) => {
    if (parameter.interface instanceof PIImage) {
      parameter.revertToDefault();
      parameter.clearDefault();
    }
  });
};

const parameterNameList = (parameters: Parameter[]) => {
  const maxNames = 8;
  return m("ul", [
    parameters.slice(0, maxNames).map((parameter) => {
      return m("li", parameter.name);
    }),
    parameters.length > maxNames && `and ${parameters.length - maxNames} more`,
  ]);
};
