import m from "mithril";

import { expressionCodeForNumber } from "../model/expression-code";
import { classNames } from "../shared/util";
import { trimEnd } from "../util";
import { startNumberScrubDrag } from "./start-drag";

interface NumberScrubberAttrs {
  stringValue: string;
  fractionDigits: number;

  prefix?: string;
  suffix?: string;
  readOnly?: boolean;

  tabIndex?: 0;

  onClick: (pointerUpEvent: PointerEvent) => void;
  onChange: (value: number, fractionDigits: number) => void;
  onFocus?: () => void;
}
export const NumberScrubber: m.Component<NumberScrubberAttrs> = {
  view({
    attrs: {
      stringValue,
      fractionDigits,
      prefix,
      suffix,
      readOnly,
      tabIndex,
      onClick,
      onChange,
      onFocus,
    },
  }) {
    const startValue = +stringValue;

    const onpointerdown = (downEvent: PointerEvent) => {
      // Prevent default so that we don't get focus, which would redirect key
      // presses from the main UI.
      downEvent.preventDefault();

      downEvent.stopPropagation();
      if (readOnly) return;

      startNumberScrubDrag(downEvent, {
        startValue,
        fractionDigits,
        onChange,
        onCancel: onClick,
      });
    };

    const className = classNames({ disabled: readOnly });

    return m("span.number-scrubber", { className, onpointerdown, tabIndex, onfocus: onFocus }, [
      m("span.prefix", prefix),
      m(PreciseNumber, { stringValue, fractionDigits }),
      m("span.suffix", suffix),
    ]);
  },
};

interface PreciseNumberAttrs {
  // Pass either the numeric value, or the string value. Not both.
  value?: number;
  stringValue?: string;

  fractionDigits: number;
}
export const PreciseNumber: m.Component<PreciseNumberAttrs> = {
  view({ attrs: { value, stringValue, fractionDigits } }) {
    if (value === undefined) {
      value = +stringValue!;
    }
    if (stringValue === undefined) {
      stringValue = expressionCodeForNumber(value, fractionDigits);
    }
    stringValue = trimEnd(trimEnd(stringValue, "0"), ".");
    const displayValue = value.toFixed(fractionDigits);
    const isDisplayValueAccurate = displayValue.includes(stringValue);
    return [
      m("span.number-value", displayValue),
      !isDisplayValueAccurate && m("span.number-ellipsis", "…"),
    ];
  },
};
