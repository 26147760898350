import { DEGREES_PER_RADIAN, RADIANS_PER_DEGREE } from "..";

/**
 * @returns the trigonometric sine of an `angle` specified in degrees
 * @param angle An angle in degrees
 */
export const sin = (angle: number) => Math.sin(angle * RADIANS_PER_DEGREE);

/**
 * @returns the trigonometric cosine of an `angle` specified in degrees
 * @param angle An angle in degrees
 */
export const cos = (angle: number) => Math.cos(angle * RADIANS_PER_DEGREE);

/**
 * @returns the trigonometric tangent of an `angle` specified in degrees
 * @param angle An angle in degrees
 */
export const tan = (angle: number) => Math.tan(angle * RADIANS_PER_DEGREE);

/**
 * @returns an angle in degrees representing the arcsine of the number `x`.
 * @param x
 */
export const asin = (x: number) => Math.asin(x) * DEGREES_PER_RADIAN;

/**
 * @returns an angle in degrees representing the arccosine of the number `x`.
 * @param x
 */
export const acos = (x: number) => Math.acos(x) * DEGREES_PER_RADIAN;

/**
 * @returns an angle in degrees representing the arctangent of the number `x`.
 * @param x
 */
export const atan = (x: number) => Math.atan(x) * DEGREES_PER_RADIAN;

/**
 * @returns an angle in degrees representing the angle of a vector with the
 * components `x` and `y`.
 *
 * @param y
 * @param x
 */
export const atan2 = (y: number, x: number) => Math.atan2(y, x) * DEGREES_PER_RADIAN;

/**
 * Converts an angle from degrees to radians.
 *
 * @param degrees An angle in degrees
 */
export const radiansFromDegrees = (degrees: number) => {
  return degrees * RADIANS_PER_DEGREE;
};

/**
 * Converts an angle in radians to degrees.
 *
 * @param radians An angle in radians
 */
export const degreesFromRadians = (radians: number) => {
  return radians * DEGREES_PER_RADIAN;
};
