export interface NameHash {
  [name: string]: true;
}

abstract class NameGenerator {
  existingNames: NameHash;

  constructor(existingNames: NameHash) {
    this.existingNames = existingNames;
  }

  abstract compose(baseName: string, number: number): string;
  abstract decompose(name: string): [string, number];

  /**
   * Returns the desired name back if it doesn't exist. Otherwise returns a
   * unique name based on the desired name.
   */
  preview(desiredName: string) {
    if (!this.existingNames[desiredName]) {
      return desiredName;
    }
    let [baseName, number] = this.decompose(desiredName);
    while (true) {
      number += 1;
      const name = this.compose(baseName, number);
      if (!this.existingNames[name]) {
        return name;
      }
    }
  }

  /**
   * Returns the desired name back if it doesn't exist. Otherwise returns a
   * unique name based on the desired name. Either way, adds the generated name
   * to existing names so it can't be used again.
   */
  generate(desiredName: string) {
    const name = this.preview(desiredName);
    this.existingNames[name] = true;
    return name;
  }
}

/**
 * Elements are named by their definition + space + number, e.g. "Circle 3",
 * "Anchor 12".
 */
export class ElementNameGenerator extends NameGenerator {
  compose(baseName: string, number: number): string {
    return baseName + " " + number;
  }

  decompose(name: string): [string, number] {
    const baseName = name.replace(/ *\d+$/, "");
    let number = 0;
    if (baseName !== name) {
      number = +name.substring(baseName.length + 1);
    }
    return [baseName, number];
  }
}

/**
 * Definitions are named by their base + space + letter, e.g. "Component B",
 * "Modifier D".
 */
export class DefinitionNameGenerator extends NameGenerator {
  /**
   * We want 1 -> A, 2 -> B, ..., 26 -> Z, 27 -> AA, 28 -> AB, etc.
   */
  numberToAlpha(x: number) {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const n = alphabet.length;
    let result = "";
    while (true) {
      let remainder = x % n;
      if (remainder === 0) remainder = n;
      result = alphabet[remainder - 1] + result;
      x = (x - remainder) / n;
      if (x <= 0) return result;
    }
  }

  alphaToNumber(a: string) {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const n = alphabet.length;
    let result = 0;
    while (a.length > 0) {
      const letter = a[0];
      a = a.substring(1);
      result *= n;
      result += alphabet.indexOf(letter) + 1;
    }
    return result;
  }

  compose(baseName: string, number: number): string {
    return baseName + " " + this.numberToAlpha(number);
  }

  decompose(name: string): [string, number] {
    const baseName = name.replace(/ [A-Z]+$/, "");
    let number = 0;
    if (baseName !== name) {
      const a = name.substring(baseName.length + 1);
      number = this.alphaToNumber(a);
    }
    return [baseName, number];
  }
}

/**
 * Parameters are named e.g. "p1", "p2", etc. To generate a name we add a number
 * at the end, with no space in between.
 */
export class ParameterNameGenerator extends NameGenerator {
  compose(baseName: string, number: number): string {
    return baseName + number;
  }

  decompose(name: string): [string, number] {
    const baseName = name.replace(/\d+$/, "");
    let number = 0;
    if (baseName !== name) {
      number = +name.substring(baseName.length);
    }
    return [baseName, number];
  }
}
