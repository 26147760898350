import m from "mithril";

/**
 * This component should only be included once in the app. The patterns are
 * referenced by #id.
 */
export const GlobalSVGPatterns: m.Component<void> = {
  view() {
    return m(
      "svg#global-svg-patterns",
      { style: { width: 0, height: 0, position: "absolute", top: "-1000px" } },
      [
        m("defs", [
          m(
            "pattern",
            {
              id: "alpha-background-checkerboard",
              x: 0,
              y: 0,
              width: 8,
              height: 8,
              patternUnits: "userSpaceOnUse",
            },
            [
              m("rect", { width: 8, height: 8, fill: "white" }),
              m("rect", { width: 4, height: 4, fill: "#DDDDDD" }),
              m("rect", { x: 4, y: 4, width: 4, height: 4, fill: "#DDDDDD" }),
            ]
          ),
        ]),
      ]
    );
  },
};
