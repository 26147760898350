import {
  AffineMatrix,
  CompoundPath,
  Fill,
  Graphic,
  Group,
  ImageFill,
  Path,
  Stroke,
  rasterizeGraphic,
  stringForNumber,
  ImageData,
} from "..";

type ImageRetriever = (url: string) => ImageData | undefined;

export interface SVGBuilderOptions {
  hairlineStrokeWidth?: number;

  rasterizeImages?: boolean;
  rasterizeImagesPixelsPerUnit?: number;

  maximumFractionDigits?: number;
}

export class SVGBuilder {
  patternDefs: string[];

  imageDefs: string[];
  imagesById: Record<string, ImageData>;

  result: string[];

  private _imageRetriever: ImageRetriever;

  constructor(imageRetriever: ImageRetriever) {
    this.patternDefs = [];
    this.imageDefs = [];
    this.imagesById = {};
    this.result = [];
    this._imageRetriever = imageRetriever;
  }

  appendGraphic(graphic: Graphic, options?: SVGBuilderOptions) {
    if (graphic instanceof Group) {
      this.result.push("<g>");
      for (let item of graphic.items) {
        this.appendGraphic(item, options);
      }
      this.result.push("</g>");
    } else if (graphic instanceof Path || graphic instanceof CompoundPath) {
      this.appendPath(graphic, options);
    }
  }

  appendPath(path: Path | CompoundPath, options?: SVGBuilderOptions) {
    const { stroke, fill } = path;
    const maximumFractionDigits = options?.maximumFractionDigits;

    let attrs = "";
    if (fill instanceof Fill) {
      attrs += `fill="${fill.color.toCSSHexString()}" fill-rule="evenodd" `;
      if (fill.color.a < 1) {
        attrs += `fill-opacity="${stringForNumber(fill.color.a, maximumFractionDigits)}" `;
      }
    } else if (fill instanceof ImageFill) {
      const pixelsPerUnit = options?.rasterizeImagesPixelsPerUnit ?? 0;
      if (options?.rasterizeImages && pixelsPerUnit > 0) {
        // Glowforge doesn't recognize pattern fills. When exporting image fills
        // for engraving we must re-rasterize image fills into <image> tags.
        const res = rasterizeGraphic(path, { pixelsPerUnit, ignoreStroke: true });
        if (res) {
          const size = res.boundingBox.size();
          const transform = `transform="translate(${res.boundingBox.min.x}, ${res.boundingBox.min.y})"`;
          const image = `<image width="${size.x}" height="${size.y}" ${transform} xlink:href="${res.fill.image}"/>`;
          this.result.push(image);
        }
        attrs += `fill="none" `;
      } else {
        const patternId = this.appendPatternDefsForImageFill(fill);
        attrs += `fill="url(#${patternId})" `;
      }
    } else {
      attrs += `fill="none" `;
    }

    if (stroke instanceof Stroke) {
      attrs += `stroke="${stroke.color.toCSSHexString()}" `;
      if (stroke.color.a < 1) {
        attrs += `stroke-opacity="${stringForNumber(stroke.color.a, maximumFractionDigits)}" `;
      }
      if (stroke.hairline) {
        const hairlineStrokeWidth = options?.hairlineStrokeWidth ?? 1;
        attrs += `stroke-width="${stringForNumber(hairlineStrokeWidth, maximumFractionDigits)}" `;
      } else {
        attrs += `stroke-width="${stringForNumber(stroke.width, maximumFractionDigits)}" `;
      }

      // Let's accumulate all the weird stroke attributes. We'll only add them
      // if they're not the default value.
      let strokeAttrs = "";
      if (stroke) {
        if (stroke.cap !== "butt") {
          strokeAttrs += `stroke-linecap="${stroke.cap}" `;
        }
        if (stroke.join !== "miter") {
          strokeAttrs += `stroke-linejoin="${stroke.join}" `;
        }
        if (stroke.miterLimit !== 4) {
          strokeAttrs += `stroke-miterlimit="${stroke.miterLimit}" `;
        }
      }

      // If we're using inner or outer stroke alignment, we'll need to do fancy
      // stuff to make our SVG work.
      const hasNonStandardAlignment = path.hasStrokeWithNonStandardAlignment();

      if (hasNonStandardAlignment) {
        // We'll modify the original geometry by expanding (outer) or contracting
        // (inner) it by half the stroke width. This is how e.g. Figma exports
        // non-centered strokes.
        const stroked = CompoundPath.stroke(path, {
          width: stroke.width,
          join: stroke.join,
          miterLimit: stroke.miterLimit,
        });
        if (stroke.alignment === "outer") {
          path = CompoundPath.booleanUnion([path, stroked]);
        } else if (stroke.alignment === "inner") {
          path = CompoundPath.booleanDifference([path, stroked]);
        }
      }

      // Add other stroke attributes if they're not the default value.
      attrs += strokeAttrs;
    } else {
      attrs += `stroke="none" `;
    }

    const d = path.toSVGPathString(options?.maximumFractionDigits);
    // If `d` is empty we return empty string. This fixes an issue where Ponoko
    // breaks if you have an SVG `path` with empty `d`.
    if (/^\s*$/.test(d)) return;

    this.result.push(`<path d="${d}" ${attrs}/>`);
  }

  appendPatternDefsForImageFill(fill: ImageFill) {
    const imageId = "image" + quickHashForString(fill.image);
    let image: ImageData | undefined = this.imagesById[imageId];
    if (!image) {
      image = this._imageRetriever(fill.image);
      if (!image) return;

      const imageDef = `<image id="${imageId}" width="${image.width}" height="${image.height}" xlink:href="${image.url}"/>`;

      this.imageDefs.push(imageDef);
      this.imagesById[imageId] = image;
    }

    const patternId = "pattern" + this.patternDefs.length;
    const patternDef = `<pattern id="${patternId}" width="100%" height="100%" patternUnits="userSpaceOnUse">
<use xlink:href="#${imageId}" transform="${svgTransformStringFromMatrix(fill.transform)}"/>
</pattern>`;
    this.patternDefs.push(patternDef);
    return patternId;
  }

  concatenatedResult() {
    const lines = [...this.result];
    const defs = [...this.patternDefs, ...this.imageDefs];
    if (defs.length > 0) {
      lines.push("<defs>", ...defs, "</defs>");
    }
    return lines.join("\n");
  }
}

// via https://gist.github.com/victor-homyakov/bcb7d7911e4a388b1c810f8c3ce17bcf
const quickHashForString = (str: string) => {
  let hash = 5381;
  const len = str.length;
  for (let i = 0; i < len; i++) {
    hash = (hash * 33) ^ str.charCodeAt(i);
  }
  return hash >>> 0;
};

const svgTransformStringFromMatrix = (m: AffineMatrix) => {
  const a = String(m.a);
  const b = String(m.b);
  const c = String(m.c);
  const d = String(m.d);
  const tx = String(m.tx);
  const ty = String(m.ty);
  return `matrix(${a}, ${b}, ${c}, ${d}, ${tx}, ${ty})`;
};
