import { AffineMatrix, BoundingBox, TAU, Vec } from "../../geom";
import { globalState } from "../../global-state";
import { AnchorDefinition } from "../../model/builtin-primitives";
import { ComponentFocus } from "../../model/focus";
import { Node } from "../../model/node";
import { Parameter } from "../../model/parameter";
import { PIVector } from "../../model/parameter-interface";
import { Project } from "../../model/project";
import { SelectableComponentParameter, SelectableParameter } from "../../model/selectable";
import { Selection } from "../../model/selection";
import { InstanceTrace } from "../../model/trace";
import { HandlePositionInfo, handlePositionInfoForParameter } from "../../model/transform-utils";
import { SnappingPoint } from "../snapping";
import styleConstants from "../style-constants";
import { alignToPixelCenter } from "../util";
import { CanvasInterfaceElement } from "./canvas-interface-element";

const PARAMETER_HANDLE_RADIUS_PIXELS = 4;

class PointHandleUI implements CanvasInterfaceElement {
  selectable: SelectableParameter | SelectableComponentParameter;

  instanceTrace: InstanceTrace;

  isInteractable: boolean;
  isSelected: boolean;
  isLocked: boolean;

  worldHandlePosition?: Vec;

  constructor(
    selectable: SelectableParameter | SelectableComponentParameter,
    instanceTrace: InstanceTrace,
    info: HandlePositionInfo,
    isInteractable: boolean
  ) {
    this.selectable = selectable;
    this.instanceTrace = instanceTrace;
    this.isInteractable = isInteractable;
    this.isSelected = globalState.project.selection.contains(selectable);
    this.isLocked = selectable.isImmutable() || selectable.expression().isComputed();
    this.worldHandlePosition = info.handlePosition;
  }

  isValid() {
    return globalState.project.selectableExistsAndIsValid(this.selectable);
  }

  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    if (!this.worldHandlePosition) return;

    const center = alignToPixelCenter(this.worldHandlePosition.clone().affineTransform(viewMatrix));
    const radius = PARAMETER_HANDLE_RADIUS_PIXELS;

    ctx.beginPath();
    ctx.ellipse(center.x, center.y, radius, radius, 0, 0, Math.PI * 2);
    ctx.fillStyle = this.isSelected ? styleConstants.blue63 : styleConstants.white;
    ctx.strokeStyle = styleConstants.blue63;
    ctx.lineWidth = 1;
    ctx.fill();
    ctx.stroke();
  }

  hitTest(worldPosition: Vec, pixelsPerUnit: number) {
    if (!this.isInteractable || !this.worldHandlePosition) return;
    const worldRadius = PARAMETER_HANDLE_RADIUS_PIXELS / pixelsPerUnit;
    const distance = this.worldHandlePosition.distance(worldPosition) - worldRadius;
    const isHit = distance <= 0;
    return { distance, isHit };
  }

  isContainedByBoundingBox(box: BoundingBox) {
    if (!this.isInteractable || !this.worldHandlePosition) return false;
    return box.containsPoint(this.worldHandlePosition);
  }
  isOverlappedByBoundingBox(box: BoundingBox) {
    if (!this.isInteractable || !this.worldHandlePosition) return false;
    return box.containsPoint(this.worldHandlePosition);
  }

  selectables() {
    return [this.selectable];
  }

  cursor() {
    if (this.isLocked) return "select-locked";
    if (globalState.isAltDown && this.instanceTrace.source.definition === AnchorDefinition) {
      return "select-split-handles";
    }
    return "select";
  }

  snappingPoints(isSource: boolean) {
    if (!this.isLocked && this.worldHandlePosition) {
      let name: string;
      if (this.selectable instanceof SelectableParameter) {
        name = `${this.selectable.parameter.name} (${this.selectable.instance.definition.name})`;
      } else {
        name = this.selectable.parameter.name;
      }
      return new SnappingPoint(this.worldHandlePosition, name, "Handle");
    }
    return undefined;
  }
  snappingReferencePoints(isSource: boolean): Vec | Vec[] | undefined {
    if (!isSource) {
      return this.worldHandlePosition;
    }
    return undefined;
  }
}

class VectorHandleUI extends PointHandleUI {
  worldOriginPosition?: Vec;

  constructor(
    selectable: SelectableParameter | SelectableComponentParameter,
    instanceTrace: InstanceTrace,
    info: HandlePositionInfo,
    isInteractable: boolean
  ) {
    super(selectable, instanceTrace, info, isInteractable);
    this.worldOriginPosition = info.originPosition;
  }

  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    if (!this.worldHandlePosition || !this.worldOriginPosition) return;

    const origin = alignToPixelCenter(this.worldOriginPosition.clone().affineTransform(viewMatrix));
    const center = alignToPixelCenter(this.worldHandlePosition.clone().affineTransform(viewMatrix));
    const radius = PARAMETER_HANDLE_RADIUS_PIXELS;

    ctx.strokeStyle = styleConstants.blue63;
    ctx.lineWidth = 1;

    ctx.beginPath();
    ctx.moveTo(origin.x, origin.y);
    ctx.lineTo(center.x, center.y);
    ctx.stroke();

    ctx.beginPath();
    ctx.ellipse(center.x, center.y, radius, radius, 0, 0, TAU);
    ctx.fillStyle = this.isSelected ? styleConstants.blue63 : styleConstants.white;
    ctx.fill();
    ctx.stroke();
  }

  snappingReferencePoints(isSource: boolean) {
    if (!isSource && this.isSelected) {
      if (this.worldOriginPosition) {
        if (
          this.selectable instanceof SelectableComponentParameter ||
          globalState.project.selection.isNodeInderectlySelected(this.selectable.node)
        ) {
          return this.worldOriginPosition;
        }
      }
    }
    return undefined;
  }
}

export class SelectionHandlesUI implements CanvasInterfaceElement {
  children: CanvasInterfaceElement[];

  constructor(selection: Selection, isIteractable = true) {
    const children: PointHandleUI[] = [];

    for (let item of selection.items) {
      let parameter: Parameter | undefined;
      let instanceTrace: InstanceTrace | undefined;

      if (item instanceof SelectableParameter) {
        parameter = item.parameter;

        // Omit hidden parameters from instances.
        if (parameter.hidden) continue;

        instanceTrace = item.instanceTrace();
      } else if (item instanceof SelectableComponentParameter) {
        const { focus } = globalState.project;
        // Only render handles when the root node is focused.
        if (focus instanceof ComponentFocus && focus.node.parent !== null) continue;

        parameter = item.parameter;
        instanceTrace = item.instanceTrace();
      } else {
        continue;
      }

      // Skip component parameters with computed expressions.
      if (parameter.expression.isComputed()) continue;

      if (instanceTrace) {
        const info = handlePositionInfoForParameter(item);
        if (info) {
          if (parameter.interface instanceof PIVector) {
            children.push(new VectorHandleUI(item, instanceTrace, info, isIteractable));
          } else {
            children.push(new PointHandleUI(item, instanceTrace, info, isIteractable));
          }
        }
      }
    }

    // Sort selected handles in front.
    this.children = children.sort((a, b) => {
      if (a.isSelected === b.isSelected) return 0;
      if (a.isSelected) return 1;
      return -1;
    });
  }
}

export class SelectionOrComponentHandlesUI extends SelectionHandlesUI {
  constructor(isIteractable = true) {
    let selection = globalState.selectionBoxParameters;
    if (!selection) {
      if (globalState.project.selection.isEmpty()) {
        selection = globalState.project.focusedComponentParametersToShow();
      } else {
        selection = globalState.project.selectionParametersToShow();
      }
    }
    super(selection, isIteractable);
  }
}

export class SelectionOnlyHandlesUI extends SelectionHandlesUI {
  constructor(isIteractable = true) {
    let selection = globalState.selectionBoxParameters;
    if (!selection) {
      selection = globalState.project.selectionParametersToShow();
    }
    super(selection, isIteractable);
  }
}
