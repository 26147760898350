/*

Serializing based on "JSOG" with some extensions.

https://github.com/jsog/jsog

Extensions:

To serialize an object with a class, you need to first call registerClass. This
will set the `@class` property on the class's prototype, so instances of that
class will have a `@class` property. When deserializing, these objects will be
constructed using `new klass()` with no arguments.

To serialize an object that is built-in, you need to first call registerBuiltin.
This will set the `@builtin` property on the object.

ModelObject:

If a ModelObject has a `materialKeys()` function (returns a list of key names),
then we'll use this list to serialize it. (This can be useful if you want to
ensure a specific ordering of keys, or want to exclude certain keys.) Otherwise,
we'll use `Object.keys(object)`.

ModelObject IDs are automatically encoded as "@id", so there's no need to
include "id" in materialKeys.

*/

import { Vec, assert } from "../geom";
import { isArray, isObject } from "../shared/util";

const classNameRegistry: WeakMap<Function, string> = new WeakMap();
export const classRegistry: { [name: string]: new () => any } = {};
export const registerClass = (name: string, klass: any) => {
  assert(!classNameRegistry.has(klass));
  classNameRegistry.set(klass, name);
  classRegistry[name] = klass;
};

// Register geometry classes that are used in model
registerClass("Vec", Vec);

const builtinNameRegistry: WeakMap<object, string> = new WeakMap();
export const builtinRegistry: { [name: string]: object } = {};
export const registerBuiltin = (name: string, object: object) => {
  const registerSingleBuiltin = (builtin: object, builtinName: string) => {
    builtinNameRegistry.set(builtin, builtinName);
    builtinRegistry[builtinName] = builtin;
  };
  assert(!builtinNameRegistry.has(object));
  registerSingleBuiltin(object, name);
  let nextId = 0;
  const recurse = (o: unknown) => {
    if (isArray(o)) {
      o.forEach(recurse);
    } else if (isObject(o) && !builtinNameRegistry.has(o)) {
      const id = nextId++;
      registerSingleBuiltin(o, name + "/" + id);
      Object.values(o).forEach(recurse);
    }
  };
  Object.values(object).forEach(recurse);
};

export const isBuiltin = (obj: object) => {
  return builtinNameRegistry.has(obj);
};
export const builtinNameForEncoding = (obj: object) => {
  return builtinNameRegistry.get(obj);
};
export const classNameForEncoding = (obj: object) => {
  return classNameRegistry.get(obj.constructor);
};
