import {
  BoundingBox,
  CubicSegment,
  GEOMETRIC_TOLERANCE,
  Geometry,
  IntersectionResult,
  LineSegment,
  PATH_TIME_EPSILON,
  PrimitiveIntersectionResult,
  Vec,
  almostEquals,
  almostZero,
  approximatelyZeroOrMore,
  approximatelyZeroToOne,
  areaOfInterestForPartitionedGeometries,
  primitivesFromGeometry,
  saturate,
} from "..";
import { pointAtTimeOnCubic, timeAtClosestPointOnAxis } from "../geometry/primitive-utils";
import { solveCubic } from "../math/numeric";

export const lineLineIntersection = (
  s1: Vec,
  e1: Vec,
  s2: Vec,
  e2: Vec
): PrimitiveIntersectionResult | undefined => {
  const d1x = e1.x - s1.x;
  const d1y = e1.y - s1.y;
  const d2x = e2.x - s2.x;
  const d2y = e2.y - s2.y;

  // http://www-cs.ccny.cuny.edu/~wolberg/capstone/intersection/Intersection%20point%20of%20two%20lines.html
  const denom = d2y * d1x - d2x * d1y;

  // If denom is 0, lines are parallel.
  // NOTE: An approximate check can fail here for very small lines.
  if (denom === 0) return undefined;

  let time1 = (d2x * (s1.y - s2.y) - d2y * (s1.x - s2.x)) / denom;
  if (!approximatelyZeroToOne(time1)) return undefined;

  let time2 = (d1x * (s1.y - s2.y) - d1y * (s1.x - s2.x)) / denom;
  if (!approximatelyZeroToOne(time2)) return undefined;

  // Make sure that we always return a 0-1 value.
  time1 = saturate(time1);
  time2 = saturate(time2);

  return { time1, time2 };
};

const toleranceForLineSegmentTime = (s: Vec, e: Vec) => {
  const length = s.distance(e);
  return GEOMETRIC_TOLERANCE / length;
};

export const lineAxisIntersection = (
  s: Vec,
  e: Vec,
  o: Vec,
  d: Vec
): PrimitiveIntersectionResult | undefined => {
  const d1x = e.x - s.x;
  const d1y = e.y - s.y;
  const denom = d.y * d1x - d.x * d1y;
  // If denom === 0, lines are parallel.
  if (denom === 0) return undefined;
  // TODO: Need to watch out for intersections at or near the endpoints.
  const time1 = (d.x * (s.y - o.y) - d.y * (s.x - o.x)) / denom;
  if (!approximatelyZeroToOne(time1)) return undefined;
  const time2 = (d1x * (s.y - o.y) - d1y * (s.x - o.x)) / denom;
  return { time1, time2 };
};

export const lineRayIntersection = (
  s: Vec,
  e: Vec,
  o: Vec,
  d: Vec
): PrimitiveIntersectionResult | undefined => {
  const d1x = e.x - s.x;
  const d1y = e.y - s.y;
  const denom = d.y * d1x - d.x * d1y;
  // If denom === 0, lines are parallel.
  if (denom === 0) return undefined;
  // TODO: Need to watch out for intersections at or near the endpoints.
  const time1 = (d.x * (s.y - o.y) - d.y * (s.x - o.x)) / denom;
  if (!approximatelyZeroToOne(time1)) return undefined;
  const time2 = (d1x * (s.y - o.y) - d1y * (s.x - o.x)) / denom;
  if (!approximatelyZeroOrMore(time2)) return undefined;
  return { time1, time2 };
};

export const axisAxisIntersection = (
  o1: Vec,
  d1: Vec,
  o2: Vec,
  d2: Vec
): PrimitiveIntersectionResult | undefined => {
  const denom = d2.y * d1.x - d2.x * d1.y;
  // If denom === 0, lines are parallel.
  if (denom === 0) return undefined;
  const time1 = (d2.x * (o1.y - o2.y) - d2.y * (o1.x - o2.x)) / denom;
  const time2 = (d1.x * (o1.y - o2.y) - d1.y * (o1.x - o2.x)) / denom;
  return { time1, time2 };
};

export const axisRayIntersection = (
  o1: Vec,
  d1: Vec,
  o2: Vec,
  d2: Vec
): PrimitiveIntersectionResult | undefined => {
  const denom = d2.y * d1.x - d2.x * d1.y;
  // If denom === 0, lines are parallel.
  if (denom === 0) return undefined;
  const time1 = (d2.x * (o1.y - o2.y) - d2.y * (o1.x - o2.x)) / denom;
  const time2 = (d1.x * (o1.y - o2.y) - d1.y * (o1.x - o2.x)) / denom;
  if (!approximatelyZeroOrMore(time2)) return undefined;
  return { time1, time2 };
};

export const rayRayIntersection = (
  o1: Vec,
  d1: Vec,
  o2: Vec,
  d2: Vec
): PrimitiveIntersectionResult | undefined => {
  const denom = d2.y * d1.x - d2.x * d1.y;
  // If denom === 0, lines are parallel.
  if (denom === 0) return undefined;
  const time1 = (d2.x * (o1.y - o2.y) - d2.y * (o1.x - o2.x)) / denom;
  if (!approximatelyZeroOrMore(time1)) return undefined;
  const time2 = (d1.x * (o1.y - o2.y) - d1.y * (o1.x - o2.x)) / denom;
  if (!approximatelyZeroOrMore(time2)) return undefined;
  return { time1, time2 };
};

export const axisCubicIntersections = (
  o1: Vec,
  d1: Vec,
  s2: Vec,
  cs2: Vec,
  ce2: Vec,
  e2: Vec
): PrimitiveIntersectionResult[] => {
  // Line direction
  const dx = d1.x;
  const dy = d1.y;

  if (almostZero(dx) && almostZero(dy)) {
    // If the line direction is zero then we can't perform an intersection.
    return [];
  }

  // Line origin
  const ox = o1.x;
  const oy = o1.y;

  // Rotate the cubic so the intersection line lands on the X axis.
  const angle = Math.atan2(-dy, dx);
  const sa = Math.sin(angle);
  const ca = Math.cos(angle);
  const sy = (s2.x - ox) * sa + (s2.y - oy) * ca;
  const csy = (cs2.x - ox) * sa + (cs2.y - oy) * ca;
  const cey = (ce2.x - ox) * sa + (ce2.y - oy) * ca;
  const ey = (e2.x - ox) * sa + (e2.y - oy) * ca;

  const roots: number[] = [];

  // Solve the cubic for the zero-crossings.
  const count = solveCubic(sy, csy, cey, ey, 0, roots, 0, 1);
  // Count will be -1 when the cubic is linear, meaning that the cubic is always
  // a fixed distance away from the line. In this case typically all of the
  // coefficients are 0, which means the intersection is a line segment. For the
  // purpose of finding point intersections, we can skip these.
  if (count === -1) return [];

  const results: PrimitiveIntersectionResult[] = [];
  const point = new Vec();
  for (let i = 0; i < count; ++i) {
    const time2 = roots[i];
    pointAtTimeOnCubic(s2, cs2, ce2, e2, time2, point);
    const time1 = timeAtClosestPointOnAxis(o1.x, o1.y, d1.x, d1.y, point.x, point.y);
    results.push({ time1, time2 });
  }

  return results;
};

export const lineCubicIntersections = (
  s1: Vec,
  e1: Vec,
  s2: Vec,
  cs2: Vec,
  ce2: Vec,
  e2: Vec
): PrimitiveIntersectionResult[] => {
  const d1 = e1.clone().sub(s1);
  return axisCubicIntersections(s1, d1, s2, cs2, ce2, e2).filter((i) => {
    return approximatelyZeroToOne(i.time1);
  });
};

export const rayCubicIntersections = (
  o1: Vec,
  d1: Vec,
  s2: Vec,
  cs2: Vec,
  ce2: Vec,
  e2: Vec
): PrimitiveIntersectionResult[] => {
  return axisCubicIntersections(o1, d1, s2, cs2, ce2, e2).filter((i) => {
    return approximatelyZeroOrMore(i.time1);
  });
};

export const swapIntersectionTimes = (intersection: PrimitiveIntersectionResult) => {
  const { time1, time2 } = intersection;
  intersection.time1 = time2;
  intersection.time2 = time1;
  return intersection;
};

export const swapAllIntersectionTimes = (intersections: PrimitiveIntersectionResult[]) => {
  for (let intersection of intersections) {
    swapIntersectionTimes(intersection);
  }
  return intersections;
};

const containsPosition = (results: IntersectionResult[], position: Vec) => {
  for (let result of results) {
    if (position._almostEquals(result.position)) return true;
  }
  return false;
};

export const partitionedGeometryIntersections = (
  geometries1: Geometry[],
  geometries2: Geometry[],
  areaOfInterest?: BoundingBox
) => {
  areaOfInterest = areaOfInterestForPartitionedGeometries(geometries1, geometries2, areaOfInterest);
  const primitives1 = primitivesFromGeometry(geometries1, areaOfInterest);
  const primitives2 = primitivesFromGeometry(geometries2, areaOfInterest);
  const intersectionResults: IntersectionResult[] = [];
  for (const p1 of primitives1) {
    for (const p2 of primitives2) {
      if (p1 === p2) continue;
      const results = p1._intersectionsWithPrimitive(p2);
      for (let result of results) {
        const position = p1.positionAtTime(result.time1);
        if (areaOfInterest && !areaOfInterest.containsPoint(position)) continue;
        if (containsPosition(intersectionResults, position)) continue;
        const res = result as IntersectionResult;
        res.primitive1 = p1;
        res.primitive2 = p2;
        res.position = position;
        intersectionResults.push(res);
      }
    }
  }
  return intersectionResults;
};

export const geometryIntersections = (geometries: Geometry[], areaOfInterest?: BoundingBox) => {
  const primitives = primitivesFromGeometry(geometries, areaOfInterest);
  const intersectionResults: IntersectionResult[] = [];
  const n = primitives.length;
  for (let i1 = 0; i1 < n; ++i1) {
    const p1 = primitives[i1];
    for (let i2 = i1 + 1; i2 < n; ++i2) {
      const p2 = primitives[i2];
      const results = p1._intersectionsWithPrimitive(p2);

      for (let result of results) {
        const position = p1.positionAtTime(result.time1);
        if (areaOfInterest && !areaOfInterest.containsPoint(position)) continue;
        if (containsPosition(intersectionResults, position)) continue;
        const res = result as IntersectionResult;
        res.primitive1 = p1;
        res.primitive2 = p2;
        res.position = position;
        intersectionResults.push(res);
      }
    }
  }
  return intersectionResults;
};

export const isInternalIntersection = (
  segment1: LineSegment | CubicSegment,
  segment2: LineSegment | CubicSegment,
  intersection: PrimitiveIntersectionResult
) => {
  const source1 = segment1.source;
  const source2 = segment2.source;
  if (source1 && source2 && source1.path === source2.path) {
    const index1 = source1.time;
    const index2 = source2.time;
    const { time1, time2 } = intersection;
    const index1Next = source1.path.normalizedTime(index1 + 1);
    if (index1Next === index2) {
      if (almostEquals(time1, 1, PATH_TIME_EPSILON) && almostEquals(time2, 0, PATH_TIME_EPSILON)) {
        return true;
      }
    }
    const index2Next = source2.path.normalizedTime(index2 + 1);
    if (index2Next === index1) {
      if (almostEquals(time1, 0, PATH_TIME_EPSILON) && almostEquals(time2, 1, PATH_TIME_EPSILON)) {
        return true;
      }
    }
  }
  return false;
};
