import m from "mithril";

import { analyticsState } from "../analytics-state";
import { nonNull } from "../geom";
import { RasterizeCanvasTooLargeError } from "../geom/io/canvas";
import { globalState } from "../global-state";
import { CodeComponent, Component } from "../model/component";
import { ComponentFocus } from "../model/focus";
import { Selection } from "../model/selection";
import { isSupportedUploadableImageFile } from "../shared/file-types";
import { copyToClipboard, hasActiveElementFocus } from "../shared/util";
import { PortableProjectDataSnapshot } from "../model/snapshot";
import { svgStringFromPortableProjectData } from "./export-utils";
import { importImageFilesToProject, importSVGStringToProject } from "./import-to-project";
import { pastePortableProjectData } from "./paste-portable-project-data";
import { toastState } from "./toast-message";
import { showClipboardInfoToast, showRasterizeCanvasTooLargeToast } from "./toast-messages";

const CLIPBOARD_LOCAL_STORAGE_KEY = "cuttleClipboard";

export const menuCutSelection = () => {
  if (globalState.project.selection.isEmpty()) {
    toastState.showBasic({
      type: "warning",
      message: "Nothing to cut.",
      nextStep: "Try selecting something before cutting.",
    });
    return;
  }
  const selection = copySelection();
  if (selection) {
    // Need to clone the copied Selection b/c delete will mutate it
    const selectionToCut = selection.clone();
    globalState.deleteSelection();
    showClipboardInfoToast("Cut", selectionToCut);
    m.redraw();
  }
};

export const menuCopySelection = () => {
  const selection = copySelection();
  if (selection) {
    showClipboardInfoToast("Copied", selection);
  }
};

export const menuPasteSelection = () => {
  // Calling execCommand is preferrable over our localStorage clipboard when
  // supported, because it enables pasting from non-Cuttle sources. We use
  // queryCommandSupported over queryCommandEnabled here, because a text input
  // must be focused for queryCommandEnabled to return true.
  if (document.queryCommandSupported?.("paste")) {
    document.execCommand("paste");
    return;
  }

  // We only need to report a paste if execCommand is not supported, since
  // otherwise the paste would be reported in `onDocumentPaste`.
  analyticsState.reportPaste();

  const data = localStorage.getItem(CLIPBOARD_LOCAL_STORAGE_KEY);
  if (data) {
    const success = pasteCuttleSvg(data);
    if (success) m.redraw();
  }
};

const copySelection = () => {
  if (globalState.project.selection.isEmpty()) {
    toastState.showBasic({
      type: "warning",
      message: "Nothing to copy.",
      nextStep: "Try selecting something before copying.",
    });
    return;
  }
  const portableProjectData = globalState.project.selectionPortableProjectData();
  try {
    const svgString = svgStringFromPortableProjectData(portableProjectData);
    setLocalStorageClipboard(svgString);
    copyToClipboard(svgString);
  } catch (error) {
    if (error instanceof RasterizeCanvasTooLargeError) {
      showRasterizeCanvasTooLargeToast();
    }
  }
  return globalState.project.selection;
};

export const copyComponent = (component: Component | CodeComponent) => {
  const portableProjectData = globalState.project.componentPortableProjectData(component);
  try {
    const svgString = svgStringFromPortableProjectData(portableProjectData);
    setLocalStorageClipboard(svgString);
    copyToClipboard(svgString);
    showClipboardInfoToast("Copied", component);
  } catch (error) {
    if (error instanceof RasterizeCanvasTooLargeError) {
      showRasterizeCanvasTooLargeToast();
    }
  }
  return;
};

const setLocalStorageClipboard = (data: string) => {
  if (localStorage) {
    try {
      localStorage.setItem(CLIPBOARD_LOCAL_STORAGE_KEY, data);
    } catch (error) {
      console.error("Couldn't set Local Storage clipboard. Storage full.");
    }
  }
};

const windowHasSelectedText = () => {
  const windowSelection = window.getSelection();
  if (windowSelection) {
    // Safari workaround, because Safari updates the windowSelection.isCollapsed
    // property based on user interactions, and we select text programmatically
    // in copyToClipboard. toString() is a reliable check in supported browsers.
    const isCollapsed = windowSelection.toString() === "";
    if (windowSelection.type === "Range" && !isCollapsed) {
      return true;
    }
  }
  return false;
};

const pasteCuttleSvg = (data: string): boolean => {
  const snapshot = PortableProjectDataSnapshot.fromSVGString(data);
  if (!snapshot) return false;

  const portableProjectData = snapshot.toPortableProjectData();
  if (!portableProjectData) return false;

  const result = pastePortableProjectData(globalState.project, portableProjectData);
  if (!result) return true;

  if (result instanceof Selection) {
    // Paste had elements or instances.
    showClipboardInfoToast("Pasted", result);
  } else {
    // If the paste only contained components, focus the first one (which should
    // be the one that was copied, the others are dependencies).
    globalState.project.focusItem(result);
    showClipboardInfoToast("Pasted", result);
  }

  // Even if the paste fails, for example when pasting an instance into self, we
  // want to return true to signal that we recognized the Cuttle snapshot paste.
  return true;
};

export const onDocumentCut = (event: ClipboardEvent) => {
  if (!globalState.project.focusedComponent()) return;
  if (hasActiveElementFocus()) return;
  if (windowHasSelectedText()) return;

  if (globalState.project.selection.isEmpty()) {
    toastState.showBasic({
      type: "warning",
      message: "Nothing to cut.",
      nextStep: "Try selecting something before cutting.",
    });
    return;
  }

  // Need to clone the copied Selection b/c delete will mutate it
  const portableProjectData = globalState.project.selectionPortableProjectData();
  try {
    const svgString = svgStringFromPortableProjectData(portableProjectData);

    event.clipboardData?.setData("text/plain", svgString);
    setLocalStorageClipboard(svgString);

    showClipboardInfoToast("Cut", globalState.project.selection);

    analyticsState.reportCopy();

    // Must be called after we're done using the project selection.
    globalState.deleteSelection();
  } catch (error) {
    if (error instanceof RasterizeCanvasTooLargeError) {
      showRasterizeCanvasTooLargeToast();
    }
  }

  event.preventDefault();
  m.redraw();
};

export const onDocumentCopy = (event: ClipboardEvent) => {
  if (!globalState.project.focusedComponent()) return;
  if (hasActiveElementFocus()) return;
  if (windowHasSelectedText()) return;

  if (
    globalState.project.selection.directlySelectedNodes().isEmpty() &&
    globalState.project.selection.directlySelectedInstances().isEmpty()
  ) {
    toastState.showBasic({
      type: "warning",
      message: "Nothing to copy.",
      nextStep: "Try selecting something before copying.",
    });
    return;
  }

  const portableProjectData = globalState.project.selectionPortableProjectData();
  try {
    const svgString = svgStringFromPortableProjectData(portableProjectData);

    event.clipboardData?.setData("text/plain", svgString);
    setLocalStorageClipboard(svgString);

    showClipboardInfoToast("Copied", globalState.project.selection);

    analyticsState.reportCopy();
  } catch (error) {
    if (error instanceof RasterizeCanvasTooLargeError) {
      showRasterizeCanvasTooLargeToast();
    }
  }

  event.preventDefault();
  m.redraw();
};

export const onDocumentPaste = (event: ClipboardEvent) => {
  // We can paste a solo component while not focused in a component.

  const { clipboardData } = event;
  if (!clipboardData) return;

  // Don't check isKeyboardEventForTextField here, because we want to
  // prevent pasting SVG and Cuttle data into text fields. We want those to
  // get imported to the canvas.

  // Eagerly report pastes since the content doesn't matter for analytics.
  analyticsState.reportPaste();

  const textData = clipboardData.getData("text/plain");
  if (textData) {
    // Try to parse and import Cuttle data.
    const success = pasteCuttleSvg(textData);
    if (success) {
      event.preventDefault();
      m.redraw();
      return;
    }

    // Some versions of Adobe Illustrator export SVGs with a leading <?xml> tag.
    if (textData.startsWith("<svg") || textData.startsWith("<?xml")) {
      // Try to parse and import non-Cuttle SVG.
      if (importSVGStringToProject(textData, "Pasted Graphic")) {
        toastState.showBasic({ type: "info", message: "Pasted SVG" });
      }
      event.preventDefault();
      m.redraw();
      return;
    }
  }

  // Handle pasting images when a component is focused. Pasting into the Read Me
  // is handled by DocEditor.
  if (globalState.project.focus instanceof ComponentFocus) {
    if (clipboardData.items.length > 0) {
      // Try to paste images.
      const allFiles = nonNull(Array.from(clipboardData.items).map((item) => item.getAsFile()));
      const imageFiles = allFiles.filter((file) => isSupportedUploadableImageFile(file));
      if (imageFiles.length > 0) {
        importImageFilesToProject(imageFiles, "Pasted Image");
      }
    }
  }

  // Allow text paste by not preventing default.
  return;
};
