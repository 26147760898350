import { RichText, Vec } from "../geom";
import { componentWise2 } from "../geom/env/vec-component-wise";
import { toString } from "../geom/io/to-string";
import { isNumber } from "../shared/util";

export const operatorOverloads = {
  sub: componentWise2((a, b) => a - b),
  mul: componentWise2((a, b) => a * b),
  div: componentWise2((a, b) => a / b),
  mod: componentWise2((a, b) => a % b),

  add: (v1: unknown, v2: unknown) => {
    if (v1 instanceof Vec && v2 instanceof Vec) {
      return new Vec(v1.x + v2.x, v1.y + v2.y);
    }
    if (v1 instanceof Vec && isNumber(v2)) {
      return new Vec(v1.x + v2, v1.y + v2);
    }
    if (isNumber(v1) && v2 instanceof Vec) {
      return new Vec(v1 + v2.x, v1 + v2.y);
    }
    if (isNumber(v1) && isNumber(v2)) {
      return v1 + v2;
    }
    if (v1 instanceof RichText || v2 instanceof RichText) {
      return new RichText(v1, v2);
    }
    return toString(v1) + toString(v2);
  },

  negate: (v: Vec | number) => {
    if (v instanceof Vec) {
      return v.clone().negate();
    }
    return -v;
  },

  doubleEquals: (v1: unknown, v2: unknown) => {
    if (v1 instanceof Vec && v2 instanceof Vec) {
      return v1.equals(v2);
    }
    if (v1 instanceof RichText && v2 instanceof RichText) {
      return v1.equals(v2);
    }
    return v1 == v2;
  },
  tripleEquals: (v1: unknown, v2: unknown) => {
    if (v1 instanceof Vec && v2 instanceof Vec) {
      return v1.equals(v2);
    }
    if (v1 instanceof RichText && v2 instanceof RichText) {
      return v1.equals(v2);
    }
    return v1 === v2;
  },
  doubleNotEquals: (v1: unknown, v2: unknown) => {
    if (v1 instanceof Vec && v2 instanceof Vec) {
      return !v1.equals(v2);
    }
    if (v1 instanceof RichText && v2 instanceof RichText) {
      return !v1.equals(v2);
    }
    return v1 != v2;
  },
  tripleNotEquals: (v1: unknown, v2: unknown) => {
    if (v1 instanceof Vec && v2 instanceof Vec) {
      return !v1.equals(v2);
    }
    if (v1 instanceof RichText && v2 instanceof RichText) {
      return !v1.equals(v2);
    }
    return v1 !== v2;
  },
};
