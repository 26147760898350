import m from "mithril";

import { createPopup } from "../shared/popup";
import { domForVnode } from "../shared/util";
import { CodeEditor } from "./code-editor";

interface EditableCodeAttrs {
  value: string;
  onChange: (value: string) => void;
  onOpen?: () => void;
  onClose?: () => void;
}
export const EditableCode: m.ClosureComponent<EditableCodeAttrs> = (initialVnode) => {
  let latestVnode = initialVnode;
  let isOpen = false;

  const openEditor = () => {
    if (isOpen) return;
    const containerEl = domForVnode(latestVnode);
    const rect = containerEl.getBoundingClientRect();

    const onchange = (newValue: string) => {
      createdPopup.close();
      latestVnode.attrs.onChange(newValue);
    };

    isOpen = true;
    const createdPopup = createPopup({
      view: () => m(EditableCodePopup, { value: latestVnode.attrs.value, onchange }),
      spawnFrom: { x: (rect.left + rect.right) * 0.5, y: (rect.top + rect.bottom) * 0.5 - 17 },
      placement: "bottom",
      className: "editable-value-popup",
      onclose: () => {
        isOpen = false;
        latestVnode.attrs.onClose?.();
      },
      overlay: "closeOnOutsidePointerDown",
    });
    latestVnode.attrs.onOpen?.();
  };

  const onpointerdown = (pointerDownEvent: PointerEvent) => {
    pointerDownEvent.stopPropagation();
    openEditor();
  };

  return {
    view(vnode) {
      latestVnode = vnode;
      return m(".editable-value", { onpointerdown }, vnode.children);
    },
  };
};

interface EditableCodePopupAttrs {
  value: string;
  onchange: (value: string) => void;
}
const EditableCodePopup: m.ClosureComponent<EditableCodePopupAttrs> = (initialVnode) => {
  const { value, onchange } = initialVnode.attrs;
  let latestValue = value;
  const codeEditorOnChange = (value: string) => {
    latestValue = value;
  };
  const codeEditorOnBlur = () => {
    onchange(latestValue);
  };
  return {
    view() {
      return m(CodeEditor, {
        value: latestValue,
        confirmOnEnter: true,
        initialSelections: "all",
        onChange: codeEditorOnChange,
        onBlur: codeEditorOnBlur,
      });
    },
  };
};
