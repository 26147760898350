export interface GeneratedBuiltinExampleData {
  type: "builtin";
  keywords: string[];
}

export interface GeneratedSnapshotExampleData {
  type: "snapshot";
  keywords: string[];
  snapshot: string;
  thumbnail: string;
}

export interface GeneratedCuttleSymbolExampleData {
  type: "cuttle-symbol";
  keywords: string[];
}

export type GeneratedExampleData =
  | GeneratedBuiltinExampleData
  | GeneratedSnapshotExampleData
  | GeneratedCuttleSymbolExampleData;

export class ExamplesData {
  private examplesDataByName: Record<string, GeneratedExampleData>;

  constructor(examplesDataByName: Record<string, GeneratedExampleData>) {
    this.examplesDataByName = examplesDataByName;
  }

  exampleDataWithName(name: string): GeneratedExampleData | undefined {
    return this.examplesDataByName[name];
  }
}
