/**
 * Disable iOS input zoom-on-focus, while still allowing pinch-to-zoom.
 *
 * The existing width=device-width prevents zoom-on-focus on Mobile Chrome
 * (unless one chooses "Desktop Site" option!)
 *
 * Thanks https://stackoverflow.com/a/57527009/592125
 */
export const disableIOSInputTapZoom = () => {
  const isIOS =
    /Macintosh|iPad|iPhone|iPod/.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1 &&
    !(window as any).MSStream;

  const disableInputTapZoom = () => {
    let el = document.querySelector("meta[name=viewport]");

    if (!el) {
      el = document.createElement("meta");
      el.setAttribute("name", "viewport");
      el.setAttribute("content", "maximum-scale=1.0");
      (document.querySelector("head") as HTMLHeadElement).appendChild(el);
    } else {
      let content = el.getAttribute("content");
      let re = /maximum\-scale=[0-9\.]+/g;

      if (content && re.test(content)) {
        content = content.replace(re, "maximum-scale=1.0");
      } else {
        content = [content, "maximum-scale=1.0"].join(", ");
      }

      el.setAttribute("content", content);
    }
  };
  if (isIOS) {
    disableInputTapZoom();
  }
};
