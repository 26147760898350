import { scaleFactorForUnitConversion } from "./geom";
import { globalState } from "./global-state";
import { CodeComponent, Component } from "./model/component";
import { CodeComponentFocus, ComponentFocus } from "./model/focus";
import { SelectableNode } from "./model/selectable";
import { Selection } from "./model/selection";
import { Viewport } from "./model/viewport";

export class ViewportManager {
  viewportsByComponentId: Record<string, Viewport> = {};

  viewportForComponent(component: Component | CodeComponent) {
    let viewport = this.viewportsByComponentId[component.id];
    if (!viewport) {
      viewport = this.initializeViewportForComponent(component);
    }
    return viewport;
  }

  initializeViewportForComponent(component: Component | CodeComponent) {
    const viewport = new Viewport();
    this.viewportsByComponentId[component.id] = viewport;

    this.zoomViewportToFitAll(viewport, component);

    // When component is marked auto-scale, it is usually authored relative to
    // one unit. Don't zoom to real-size.
    if (!component.isAutoScale) {
      const currentScreenPPI = globalState.deviceStorage.currentScreenPPI() ?? 110;
      this.zoomViewportToPixelsPerInch(viewport, currentScreenPPI);
      // Zoom to fit all only if needed to see it
      this.zoomViewportOutToFitAll(viewport, component);
    }

    return viewport;
  }

  zoomViewportToPixelsPerInch(viewport: Viewport, pixelsPerInch: number) {
    const scaleFactor = scaleFactorForUnitConversion(globalState.project.settings.units, "in");
    viewport.pixelsPerUnit = pixelsPerInch * scaleFactor;
  }

  zoomViewportOutToFitAll(viewport: Viewport, component: Component | CodeComponent) {
    const trace = globalState.traceForComponent(component);
    const boundingBox = trace?.result?.boundingBox();
    if (boundingBox) {
      const viewportWorldBoundingBox = viewport.worldBoundingBox(globalState.canvasDimensions);
      if (!viewportWorldBoundingBox.containsBoundingBox(boundingBox)) {
        viewport.scaleToFitBoundingBox(boundingBox, globalState.canvasDimensions);
      }
    }
  }

  zoomViewportToFitAll(viewport: Viewport, component: Component | CodeComponent) {
    const trace = globalState.traceForComponent(component);
    const boundingBox = trace?.result?.boundingBox();
    if (boundingBox) {
      viewport.scaleToFitBoundingBox(boundingBox, globalState.canvasDimensions);
    }
  }

  zoomViewport(viewport: Viewport, selection: Selection, scale: number, quantize?: boolean) {
    if (!selection.isEmpty()) {
      const selectionBounds = selection.worldBoundingBox();
      if (selectionBounds) {
        const viewportBounds = viewport.worldBoundingBox(globalState.canvasDimensions);
        if (!selectionBounds.overlapsBoundingBox(viewportBounds)) {
          viewport.center = selectionBounds.center();
        }
      }
    }
    viewport.scale(scale, quantize);
  }
  zoomViewportToFitSelection(
    viewport: Viewport,
    component: Component | CodeComponent,
    selection: Selection
  ) {
    if (selection.isEmpty()) {
      this.zoomViewportToFitAll(viewport, component);
      return;
    }
    const selectionBox = selection.worldBoundingBox();
    if (selectionBox) {
      viewport.scaleToFitBoundingBox(selectionBox, globalState.canvasDimensions);
    }
  }
  zoomViewportToFitFocus(viewport: Viewport, focus: ComponentFocus | CodeComponentFocus) {
    if (focus instanceof ComponentFocus) {
      const selection = new Selection([new SelectableNode(focus.node)]);
      this.zoomViewportToFitSelection(viewport, focus.component, selection);
    } else {
      this.zoomViewportToFitAll(viewport, focus.component);
    }
  }
}
