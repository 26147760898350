import m from "mithril";

import { Color } from "../geom";
import { saturate } from "../geom/math/scalar-math";
import { globalState } from "../global-state";
import { CreatedPopup, createPopup } from "../shared/popup";
import { classNames, domForVnode } from "../shared/util";
import { startDrag } from "./start-drag";

const checkerboardSVG = `
<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="8" height="8" fill="white"/>
<rect width="4" height="4" fill="#DDDDDD"/>
<rect x="4" y="4" width="4" height="4" fill="#DDDDDD"/>
</svg>
`;
const checkerboardDataURL = `url('data:image/svg+xml;base64,${btoa(checkerboardSVG)}')`;

const defaultColors: (Color | "none")[] = [
  "none",
  new Color(0, 0, 0, 1),
  new Color(0, 0, 1, 1),
  new Color(0, 1, 0, 1),
  new Color(0, 1, 1, 1),
  new Color(0.5, 0.5, 0.5, 1),
  new Color(1, 0, 0, 1),
  new Color(1, 0, 1, 1),
  new Color(1, 1, 0, 1),
  new Color(1, 1, 1, 1),
];

const makeBackgroundCSS = (color: Color) => {
  const colorString = color.toCSSString();
  return `linear-gradient(${colorString}, ${colorString}), ${checkerboardDataURL}`;
};

interface ColorPickerAttrs {
  color: Color | "none";
  onchange: (value: Color | "none") => void;
}
export const ColorPicker: m.ClosureComponent<ColorPickerAttrs> = (initialVnode) => {
  let latestVnode = initialVnode;
  let [h, s, v, a] =
    latestVnode.attrs.color === "none" ? [0, 0, 0, 1] : latestVnode.attrs.color.toHSVA();
  let popup: CreatedPopup;

  const sendChange = () => {
    const color = Color.fromHSVA(h, s, v, a);
    latestVnode.attrs.onchange(color);
  };

  const makeAreaPointerDownHandler = (updater: (x: number, y: number) => void) => {
    return (downEvent: PointerEvent) => {
      const target = downEvent.target as HTMLElement;
      const rect = target.getBoundingClientRect();
      const update = (pointerEvent: PointerEvent) => {
        const x = saturate((pointerEvent.clientX - rect.left) / rect.width);
        const y = saturate((pointerEvent.clientY - rect.top) / rect.height);
        updater(x, y);
        sendChange();
      };
      update(downEvent);
      startDrag(downEvent, {
        onMove: update,
      });
    };
  };

  const handleValueSaturationPointerDown = makeAreaPointerDownHandler((x, y) => {
    s = x;
    v = 1 - y;
  });

  const handleHuePointerDown = makeAreaPointerDownHandler((x, y) => {
    h = 1 - y;
    if (s === 0 && (v === 0 || v === 0.5)) {
      // Automatically turn up the saturation and value when starting from black
      // or the default gray fill.
      s = 0.618;
      v = 1;
    }
  });

  const handleAlphaPointerDown = makeAreaPointerDownHandler((x, y) => {
    a = 1 - y;
  });

  const pickerView = () => {
    const latestColor =
      latestVnode.attrs.color === "none" ? new Color(0, 0, 0, 1) : latestVnode.attrs.color;

    let [h2, s2, v2, a2] = latestColor.toHSVA();
    v = v2;
    a = a2;
    if (v > 0) {
      s = s2;
    }
    if (s > 0 && v > 0) {
      const isRed = h === 0 || h === 1;
      const isRed2 = h2 === 0 || h2 === 1;
      if (isRed && isRed2) {
        // To prevent cycling, don't change h.
      } else {
        h = h2;
      }
    }

    const fullHue = Color.fromHSVA(h, 1, 1, 1);
    const fullOpacity = latestColor.clone();
    fullOpacity.a = 1;
    const valueSaturationGradient = `linear-gradient(to top, rgb(0,0,0), transparent), linear-gradient(to left, ${fullHue.toCSSString()}, white)`;
    const alphaGradient = `linear-gradient(to top, transparent, ${fullOpacity.toCSSString()}), ${checkerboardDataURL}`;

    const isColorChosen = (color: Color | "none") => {
      return (
        (color instanceof Color && color.equals(latestColor)) ||
        (color === "none" && latestVnode.attrs.color === null)
      );
    };
    const chooseColorSwatch = (color: Color | "none") => {
      latestVnode.attrs.onchange(color);
      popup.close();
    };

    const projectColors = globalState.project.allColors().filter((color) => {
      // Filter out colors already present in the default color swatches.
      return !defaultColors.some(
        (defaultColor) => defaultColor instanceof Color && color.equals(defaultColor)
      );
    });

    return m(".color-picker-popup", [
      m(".color-picker-palette", [
        m(
          ".color-picker-value-saturation",
          {
            onpointerdown: handleValueSaturationPointerDown,
            style: { background: valueSaturationGradient },
          },
          [
            m(".color-picker-value-saturation-indicator", {
              style: {
                top: `${(1 - v) * 100}%`,
                left: `${s * 100}%`,
                "background-color": fullOpacity.toCSSString(),
              },
            }),
          ]
        ),
        m(
          ".color-picker-hue",
          {
            onpointerdown: handleHuePointerDown,
          },
          [
            m(".color-picker-hue-indicator", {
              style: {
                top: `${(1 - h) * 100}%`,
              },
            }),
          ]
        ),
        m(
          ".color-picker-alpha",
          {
            onpointerdown: handleAlphaPointerDown,
            style: { background: alphaGradient },
          },
          [
            m(".color-picker-alpha-indicator", {
              style: {
                top: `${(1 - a) * 100}%`,
              },
            }),
          ]
        ),
      ]),
      m(
        ".color-picker-swatches",
        defaultColors.map((color) => {
          return m(ColorSwatch, {
            color,
            onpointerdown: () => chooseColorSwatch(color),
            isChosen: isColorChosen(color),
          });
        })
      ),
      projectColors.length > 0 && [
        m(".color-picker-swatches-category-name", "Project"),
        m(
          ".color-picker-swatches",
          projectColors.map((color) => {
            return m(ColorSwatch, {
              color,
              onpointerdown: () => chooseColorSwatch(color),
              isChosen: isColorChosen(color),
            });
          })
        ),
      ],
    ]);
  };

  return {
    view(vnode) {
      latestVnode = vnode;
      const { color } = latestVnode.attrs;

      const onpointerdown = (event: PointerEvent) => {
        event.stopPropagation();
        const spawnEl = domForVnode(vnode);
        globalState.isPickerOpen = true;
        const gestureId = globalState.startGesture("Color Picker");
        popup = createPopup({
          spawnFrom: spawnEl,
          view: pickerView,
          placement: "top-start",
          offset: 8,
          onclose: () => {
            globalState.isPickerOpen = false;
            globalState.stopGesture(gestureId);
          },
        });
      };

      return m(".color-picker", [m(ColorSwatch, { color, onpointerdown })]);
    },
  };
};

interface ColorSwatchAttrs {
  color: Color | "none";
  onpointerdown?: (event: PointerEvent) => void;
  isChosen?: boolean;
}
const ColorSwatch: m.Component<ColorSwatchAttrs> = {
  view({ attrs: { color, onpointerdown, isChosen } }) {
    return m(".color-picker-swatch", {
      style: color === "none" ? undefined : { background: makeBackgroundCSS(color) },
      onpointerdown,
      className: classNames({
        chosen: isChosen,
        none: color === "none",
      }),
    });
  },
};
