import type { NodeViewRendererProps } from "@tiptap/core";
import m from "mithril";

import { Icon20 } from "../../shared/icon";

export const initDragHandle = (dom: HTMLElement, nodeViewRendererProps: NodeViewRendererProps) => {
  const { editor, getPos } = nodeViewRendererProps;

  const handleEl = document.createElement("div");

  handleEl.setAttribute("data-drag-handle", "true");
  dom.setAttribute("data-has-drag-handle", "true");

  /**
   * HACK: This is a workaround to prevent ProseMirror from stealing focus with
   * mouse down on internal controls.
   */
  dom.setAttribute("tabindex", "0");
  dom.addEventListener("focus", () => {
    if (typeof getPos === "function") {
      editor.commands.setNodeSelection(getPos());
    }
  });

  // Prepend handle to dom element
  dom.prepend(handleEl);

  let isDraggingHandle = false;

  // Select node on mouse down, which makes the node draggable
  const onDragBegin = (event: MouseEvent) => {
    if (event.button === 0 && typeof getPos === "function") {
      editor.commands.setNodeSelection(getPos());
      isDraggingHandle = true;
      window.addEventListener("mouseup", onDragEnd);
    }
  };
  const onDragEnd = () => {
    isDraggingHandle = false;
    window.removeEventListener("mouseup", onDragEnd);
  };

  const handleComponent = {
    view() {
      return m(
        ".drag-handle.icon-button",
        {
          onmousedown: onDragBegin,
        },
        m(Icon20, { icon: "drag_handle" })
      );
    },
  };
  m.mount(handleEl, handleComponent);

  // HACK: remove the initial draggable attribute from the dom elemeent, as it
  // conflicts with internal text selection. ProseMirror will add the attribute
  // while the drag is in progress.
  setTimeout(() => {
    dom.removeAttribute("draggable");
  }, 100);

  return {
    // Mark this node as being the selected node, without adding draggable.
    selectNode() {
      dom.classList.add("ProseMirror-selectednode");
    },
    // Remove selected node marking from this node.
    deselectNode() {
      dom.classList.remove("ProseMirror-selectednode");
    },
    // Ignore events other than drag handle
    stopEvent(event: Event) {
      if (isDraggingHandle) {
        // Let ProseMirror handle this event.
        return false;
      }
      const target = event?.target as HTMLElement;
      if (target === handleEl) {
        // Let ProseMirror handle this event, select the node, make it
        // draggable, etc.
        return false;
      }
      if (event.type === "drop" && editor.view.dragging) {
        // Dropping another node onto this node, let ProseMirror handle it.
        return false;
      }
      // Stop other drag events
      if (event.type.startsWith("drag")) {
        event.preventDefault();
      }
      // Ignore other mouse events
      return true;
    },
    destroy() {
      m.mount(handleEl, null);
    },
  };
};
