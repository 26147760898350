import m from "mithril";

import { globalState } from "../../global-state";
import { FillDefinition, StrokeDefinition } from "../../model/builtin-primitives";
import { CodeComponent, Component } from "../../model/component";
import { DocumentationFocus } from "../../model/focus";
import { Parameter, ParameterFolder } from "../../model/parameter";
import { Project } from "../../model/project";
import {
  SelectableComponentParameter,
  SelectableInstance,
  SelectableParameter,
} from "../../model/selectable";
import { ParameterInspector } from "./parameter-inspector";

interface InstanceParameterInspectorAttrs {
  selectables: SelectableInstance[];
  parameter: Parameter;
  isEditingDefinition: boolean;
  folder?: ParameterFolder;
}
export const InstanceParameterInspector: m.Component<InstanceParameterInspectorAttrs> = {
  view({ attrs: { selectables, parameter, isEditingDefinition, folder } }) {
    const isEmbed = globalState.project.focus instanceof DocumentationFocus;

    const firstSelectable = selectables[0];
    const selectableParameters = selectables.map((s) => {
      if (isEditingDefinition) {
        const d = s.instance.definition;
        const ignoreInstance =
          d instanceof Component ||
          d instanceof Project ||
          (d instanceof CodeComponent &&
            !globalState.project.isEditingCode(firstSelectable.instance));
        if (ignoreInstance) {
          return new SelectableComponentParameter(d, parameter);
        }
      }
      return new SelectableParameter(s.node, s.instance, parameter);
    });

    if (!isEditingDefinition || isEmbed) {
      // Don't hide instance argument, even if it is overriding a hidden or derived
      // parameter. We try to expose the state of the model, even if incorrect.
      const isVisible = globalState.project.isParameterVisible(
        firstSelectable.instance.definition,
        parameter
      );
      if (!isVisible && !firstSelectable.instance.hasArgumentWithName(parameter.name)) {
        return;
      }

      const firstSelectableParameter = selectableParameters[0];
      if (shouldOmitStokeAndFillParameter(firstSelectable, firstSelectableParameter)) {
        return;
      }
    }

    return m(ParameterInspector, {
      selectables: selectableParameters,
      isEditingDefinition,
      folder,
    });
  },
};

const shouldOmitStokeAndFillParameter = (
  instance: SelectableInstance,
  selectable: SelectableParameter | SelectableComponentParameter
) => {
  // HACK: Hide ineffective parameters in Stroke and Fill.. Remove this when
  // we have a generic way of determining which parameters are relevant given
  // some previous parameter result.
  const { name } = selectable.parameter;
  const definition = selectable.definition();
  if (definition === StrokeDefinition) {
    let isHairlineStroke = false;
    const trace = instance.instanceTrace();
    if (trace?.args.hairline?.evaluationResult) {
      isHairlineStroke = true;
    }
    if (isHairlineStroke === true) {
      if (
        name === "width" ||
        name === "alignment" ||
        name === "cap" ||
        name === "join" ||
        name === "miterLimit"
      ) {
        return true;
      }
    }
  }
  if (definition === FillDefinition) {
    const trace = instance.instanceTrace();
    const fillType = trace?.args.type?.evaluationResult;
    if (fillType === "color") {
      if (name === "image" || name === "opacity" || name === "transform") return true;
    }
    if (fillType === "image") {
      if (name === "color") return true;
    }
  }
  return false;
};
