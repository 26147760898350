import { AffineMatrix, Axis, TAU, Vec, assert } from "../../geom";
import { globalState } from "../../global-state";
import { CodeComponentFocus, ComponentFocus } from "../../model/focus";
import { Node } from "../../model/node";
import { transformedGraphicForNode } from "../../model/transform-utils";
import { paintDotToCanvas } from "../canvas-geometry";
import { SnappingGeometry, SnappingPoint } from "../snapping";
import styleConstants from "../style-constants";
import { alignToPixelCenter } from "../util";
import { CanvasInterfaceElement } from "./canvas-interface-element";
import {
  SnappingGeometryUI,
  StyledComponentGeometryUI,
  StyledNodeGeometryUI,
} from "./geometry-interface";

export class CanvasBaseUI implements CanvasInterfaceElement {
  readonly id: string = "canvas-base";

  cursor(): string {
    if (globalState.isAltDown) return "select-contains";
    return "select";
  }

  hitTest(worldPosition: Vec) {
    return { isHit: true };
  }

  snappingPoints(isSource: boolean) {
    if (!isSource) {
      return [new SnappingPoint(new Vec(), "Canvas Origin", "Origin")];
    }
    return undefined;
  }

  snappingGeometry(isSource: boolean) {
    if (isSource) return undefined;
    return [
      new SnappingGeometry("X Axis", new Axis(new Vec(), new Vec(1, 0))),
      new SnappingGeometry("Y Axis", new Axis(new Vec(), new Vec(0, 1))),
    ];
  }

  onPointerEnter(event: PointerEvent) {
    globalState.project.hoveredItem = null;
  }
}

export class CanvasDragUI implements CanvasInterfaceElement {
  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    const { canvasDrag, snapping } = globalState;
    if (!canvasDrag) return;

    const startPos = canvasDrag.startPoint.worldPosition.clone().affineTransform(viewMatrix);
    alignToPixelCenter(startPos);
    const currentPos = canvasDrag.currentPoint.worldPosition.clone().affineTransform(viewMatrix);
    alignToPixelCenter(currentPos);

    ctx.strokeStyle = canvasDrag.isPrecise() ? styleConstants.red47 : styleConstants.gray50;
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(startPos.x, startPos.y);
    ctx.lineTo(currentPos.x, currentPos.y);
    ctx.stroke();

    if (
      !snapping.currentPoint ||
      !snapping.currentPoint.worldPosition.equals(canvasDrag.startPoint.worldPosition)
    ) {
      const color = canvasDrag.startPoint.isPrecise()
        ? styleConstants.red47
        : styleConstants.gray50;
      paintDotToCanvas(startPos, 3, color, ctx);
    }
    if (
      !snapping.currentPoint ||
      !snapping.currentPoint.worldPosition.equals(canvasDrag.currentPoint.worldPosition)
    ) {
      const color = canvasDrag.currentPoint.isPrecise()
        ? styleConstants.red47
        : styleConstants.gray50;
      paintDotToCanvas(currentPos, 3, color, ctx);
    }
  }

  snappingReferencePoints() {
    const { canvasDrag } = globalState;
    if (canvasDrag) {
      return canvasDrag.startPoint.worldPosition;
    }
  }
}

export class FocusedGeometryUI implements CanvasInterfaceElement {
  children: CanvasInterfaceElement[] = [];

  constructor() {
    if (globalState.project.focus instanceof ComponentFocus) {
      let node: Node | null = globalState.project.focus.node;

      if (node.isPath()) {
        this.children.push(new StyledNodeGeometryUI(node));
        node = node.parent;
      }

      // Only draw the spotlight if focused node is not the base.
      if (globalState.project.focus.node.parent) {
        this.children.push(new FocusedNodeSpotlightUI());
      }

      let opacity = 1;
      while (node !== null) {
        opacity *= 0.5;
        this.children.push(new SnappingGeometryUI(node), new StyledNodeGeometryUI(node, opacity));
        node = node.parent;
      }

      this.children.reverse();
    } else if (globalState.project.focus instanceof CodeComponentFocus) {
      this.children.push(new StyledComponentGeometryUI(globalState.project.focus.component));
    }
  }
}

class FocusedNodeSpotlightUI implements CanvasInterfaceElement {
  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    const { focus } = globalState.project;
    assert(focus instanceof ComponentFocus);

    // Calculate a circular spotlight around the focused geometry.
    const boundingBox = transformedGraphicForNode(focus.node)?.boundingBox();
    if (!boundingBox) return;

    const viewport = globalState.viewportManager.viewportForComponent(focus.component);
    const expansionWorld = 20 / viewport.pixelsPerUnit;
    const center = boundingBox.center().affineTransform(viewMatrix);
    const radius = viewMatrix.a * (boundingBox.size().length() / 2 + expansionWorld);

    // Create a "negative" of the circular spotlight.
    const { width, height } = globalState.canvasRectPixels;

    ctx.fillStyle = styleConstants.blackAlpha03;
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.ellipse(center.x, center.y, radius, radius, 0, 0, TAU);
    ctx.fill("evenodd");

    ctx.font = styleConstants.defaultFont14;
    ctx.fillStyle = styleConstants.blackAlpha50;
    ctx.textAlign = "center";
    ctx.fillText(focus.node.source.name, center.x, center.y - radius - 12);
  }
}
