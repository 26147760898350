export class ImageData {
  width: number;
  height: number;

  url: string;

  _image: ImageBitmap | HTMLImageElement;

  _imageData?: globalThis.ImageData;

  constructor(bitmap: ImageBitmap | HTMLImageElement, url: string) {
    this._image = bitmap;
    this.url = url;

    this.width = bitmap.width;
    this.height = bitmap.height;
  }

  /**
   * @returns a `Uint8ClampedArray` containing the pixel color data for this
   * image. Data is stored in a 1-dimensional array of RGBA color component
   * values as integers from 0-255 (UInt8).
   */
  pixelData() {
    if (this._imageData === undefined) {
      const img = this._image;

      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const context = canvas.getContext("2d");
      if (!context) return;

      context.drawImage(img, 0, 0);

      const imageData = context.getImageData(0, 0, img.width, img.height);
      if (imageData) {
        this._imageData = imageData;
      }
    }
    return this._imageData?.data;
  }
}
