import opentype from "opentype.js";

import { ttc2ttf } from "../3rd-party/ttc2ttf";
import { extensionFromPath, hashSHA256 } from "../shared/util";

export interface LocalFontInfo {
  file: File;
  family: string;
  label: string;
  variant: string;
  hash: string;
  fileExtension: string;
}

/** Returns an array, because some font files (TTC) include a collection of
 * variants, which we extract into multiple TTF files. Other formats (TTF, OTF,
 * WOFF, WOFF2) return the array with one item, and the file intact.
 *
 * If opentype.load doesn't succeed, we throw an error here, so the font won't
 * be uploaded.
 */
export async function getInfoFromLocalFont(file: File): Promise<LocalFontInfo[]> {
  let fileExtension = extensionFromPath(file.name);
  let files = [file];

  if (fileExtension === "ttc") {
    files = await ttc2ttf(file);
    fileExtension = "ttf";
  }

  const info: LocalFontInfo[] = [];

  for (let file of files) {
    const url = URL.createObjectURL(file);
    const font = await opentype.load(url);
    URL.revokeObjectURL(url);

    if (!font) {
      throw new Error("Could not parse font.");
    }

    const arrayBuffer = await file.arrayBuffer();
    const hash = await hashSHA256(arrayBuffer);

    const family =
      font.getEnglishName("preferredFamily") || font.getEnglishName("fontFamily") || file.name;
    const variant =
      font.getEnglishName("preferredSubfamily") ||
      font.getEnglishName("fontSubfamily") ||
      "default";
    const label = `${family} (${variant})`;

    info.push({
      file,
      family,
      variant,
      label,
      hash,
      fileExtension,
    });
  }

  return info;
}
