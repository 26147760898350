import { AffineMatrix, Color, RichText, Vec } from "../geom";
import { isArray, isBoolean, isNumber, isString } from "../shared/util";
import { registerClass } from "./registry";

type ParameterPrecisionDigits = number | "nudge" | "auto";

/**
 * Abstract base class for all parameter interfaces.
 *
 * NOTE: Properties of any subclass must be readonly. Parameter interfaces are
 * not designed to be modified after instantiation since changes won't propagate
 * up and invalidate the Parameter's reducer cache.
 */
export abstract class ParameterInterface {
  isPositionTransformable = false;
  isRotationTransformable = false;
  isScaleTransformable = false;
  isSkewTransformable = false;

  isVisible = true;

  precisionDigits: ParameterPrecisionDigits = 2;

  abstract clone(): ParameterInterface;
  abstract isValidValue(value: unknown): boolean;
  abstract invalidValueMessage(): string;
}

export class PIBoolean extends ParameterInterface {
  clone() {
    return new PIBoolean();
  }

  isValidValue(value: unknown): boolean {
    return isBoolean(value) || isArray(value);
  }
  invalidValueMessage(): string {
    return "Expected a boolean (true or false)";
  }
}
registerClass("PIBoolean", PIBoolean);

export class PIScalar extends ParameterInterface {
  constructor(precisionDigits: ParameterPrecisionDigits = "auto") {
    super();
    this.precisionDigits = precisionDigits;
  }

  clone() {
    return new PIScalar(this.precisionDigits);
  }

  isValidValue(value: unknown): boolean {
    return isNumber(value) || isArray(value);
  }
  invalidValueMessage(): string {
    return "Expected a number";
  }
}
registerClass("PIScalar", PIScalar);

export class PIPercentage extends ParameterInterface {
  readonly precisionDigits: ParameterPrecisionDigits;

  constructor(precisionDigits: ParameterPrecisionDigits = "auto") {
    super();
    this.precisionDigits = precisionDigits;
  }

  clone() {
    return new PIPercentage();
  }

  isValidValue(value: unknown): boolean {
    return isNumber(value) || isArray(value);
  }
  invalidValueMessage(): string {
    return "Expected a number";
  }
}
registerClass("PIPercentage", PIPercentage);

export class PIDistance extends ParameterInterface {
  readonly precisionDigits: ParameterPrecisionDigits = "nudge";

  clone() {
    return new PIDistance();
  }

  isValidValue(value: unknown): boolean {
    return isNumber(value) || isArray(value);
  }
  invalidValueMessage(): string {
    return "Expected a number";
  }
}
registerClass("PIDistance", PIDistance);

export class PIAngle extends ParameterInterface {
  readonly precisionDigits: ParameterPrecisionDigits = 0;

  clone() {
    return new PIAngle();
  }

  isValidValue(value: unknown): boolean {
    return isNumber(value) || isArray(value);
  }
  invalidValueMessage(): string {
    return "Expected a number";
  }
}
registerClass("PIAngle", PIAngle);

export class PICount extends ParameterInterface {
  readonly precisionDigits: ParameterPrecisionDigits = 0;

  clone() {
    return new PICount();
  }

  isValidValue(value: unknown): boolean {
    return isNumber(value) || isArray(value);
  }
  invalidValueMessage(): string {
    return "Expected a number";
  }
}
registerClass("PICount", PICount);

export class PISize extends ParameterInterface {
  readonly precisionDigits: ParameterPrecisionDigits = "nudge";

  clone() {
    return new PISize();
  }

  isValidValue(value: unknown): boolean {
    return isNumber(value) || Vec.isValid(value) || isArray(value);
  }
  invalidValueMessage(): string {
    return "Expected a number or a Vec";
  }
}
registerClass("PISize", PISize);

export class PIVector extends ParameterInterface {
  readonly isRotationTransformable = true;
  readonly isScaleTransformable = true;
  readonly isSkewTransformable = true;

  readonly precisionDigits: ParameterPrecisionDigits = "nudge";

  readonly originParameterName?: string;

  constructor(originParameterName?: string) {
    super();
    this.originParameterName = originParameterName;
  }

  clone() {
    return new PIVector(this.originParameterName);
  }

  isValidValue(value: unknown): boolean {
    return Vec.isValid(value) || isArray(value);
  }
  invalidValueMessage(): string {
    return "Expected a Vec";
  }
}
registerClass("PIVector", PIVector);

export class PIPoint extends ParameterInterface {
  readonly isPositionTransformable = true;
  readonly isRotationTransformable = true;
  readonly isScaleTransformable = true;
  readonly isSkewTransformable = true;

  readonly precisionDigits: ParameterPrecisionDigits = "nudge";

  clone() {
    return new PIPoint();
  }

  isValidValue(value: unknown): boolean {
    return Vec.isValid(value) || isArray(value);
  }
  invalidValueMessage(): string {
    return "Expected a Vec";
  }
}
registerClass("PIPoint", PIPoint);

export class PIColor extends ParameterInterface {
  clone() {
    return new PIColor();
  }

  isValidValue(value: unknown): boolean {
    return Color.isValid(value) || value === "none" || isArray(value);
  }
  invalidValueMessage(): string {
    return 'Expected a Color or "none"';
  }
}
registerClass("PIColor", PIColor);

export interface PISelectOption {
  readonly label: string;
  readonly value: string;
}
export class PISelect extends ParameterInterface {
  readonly options: ReadonlyArray<PISelectOption>;

  constructor(options: PISelectOption[]) {
    super();
    this.options = options;
  }

  clone() {
    return new PISelect(
      this.options.map((option) => {
        return { label: option.label, value: option.value };
      })
    );
  }

  isValidValue(value: unknown): boolean {
    return isString(value) && this.options.some((option) => option.value === value);
  }
  invalidValueMessage(): string {
    return `Expected one of the options ${this.options
      .map((option) => `"${option.value}"`)
      .join(", ")}`;
  }
}
registerClass("PISelect", PISelect);

export class PIImageTransform extends PISelect {
  constructor() {
    super([
      { label: "fill", value: "fill" },
      { label: "fit", value: "fit" },
    ]);
  }

  clone() {
    return new PIImageTransform();
  }

  isValidValue(value: unknown): boolean {
    return super.isValidValue(value) || AffineMatrix.isValid(value);
  }
  invalidValueMessage(): string {
    return `Expected one of the options "fill", "fit" or an AffineMatrix`;
  }
}
registerClass("PIImageTransform", PIImageTransform);

export class PIFontSelect extends ParameterInterface {
  readonly recommendedValues: ReadonlyArray<string>;

  constructor(recommendedValues: string[] = []) {
    super();
    this.recommendedValues = recommendedValues;
  }

  clone() {
    return new PIFontSelect(this.recommendedValues.slice());
  }

  isValidValue(value: unknown): boolean {
    return isString(value);
  }
  invalidValueMessage(): string {
    return "Expected a string";
  }
}
registerClass("PIFontSelect", PIFontSelect);

export class PIText extends ParameterInterface {
  clone() {
    return new PIText();
  }

  isValidValue(value: unknown): boolean {
    return isString(value) || isNumber(value) || isArray(value) || value instanceof RichText;
  }
  invalidValueMessage(): string {
    return "Expected a string, number, or RichText";
  }
}
registerClass("PIText", PIText);

export class PIEmoji extends ParameterInterface {
  clone() {
    return new PIEmoji();
  }

  isValidValue(value: unknown): boolean {
    return isString(value);
  }
  invalidValueMessage(): string {
    return "Expected a string";
  }
}
registerClass("PIEmoji", PIEmoji);

export class PIImage extends ParameterInterface {
  clone() {
    return new PIImage();
  }

  isValidValue(value: unknown): boolean {
    return isString(value);
  }
  invalidValueMessage(): string {
    return "Expected a string";
  }
}
registerClass("PIImage", PIImage);
