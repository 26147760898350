import {
  AffineMatrix,
  Anchor,
  BoundingBox,
  ClosestPointResultWithTime,
  CompoundPath,
  CubicSegment,
  DEFAULT_TOLERANCE,
  Fill,
  Graphic,
  Group,
  ImageFill,
  LineSegment,
  PATH_TIME_EPSILON,
  RADIANS_PER_DEGREE,
  RasterizeOptions,
  Stroke,
  Vec,
  almostEquals,
  assert,
  fract,
  modulo,
  pairs,
  pathContainsPoint,
  pathStyleContainsPoint,
  rasterizeGraphic,
  rotateArray,
  stringForNumber,
} from "..";
import { boundingBoxForCubic, isSegment } from "../geometry/primitive-utils";
import { pathFromSmoothFunction } from "../misc/path-from-function";
import { filletPath } from "../op/fillet";

/**
 * A representation of a two-dimensional path made up of straight and curved segments.
 */
export class Path extends Graphic {
  static readonly displayName = "Path";

  /** An array of anchors that makes up the path. */
  anchors: Anchor[];

  /**
   * Specifies if the path is closed. If closed, the first and last anchors will
   * be connected by an additional segment.
   */
  closed: boolean;

  /** The stroke style */
  stroke?: Stroke;

  /** The fill style */
  fill?: Fill | ImageFill;

  /**
   * Constructs a path from an array of anchors.
   *
   * @param anchors An array of anchors
   * @param closed If `true`, the path will form a loop
   * @param stroke A stroke style to assign
   * @param fill A fill style to assign
   */
  constructor(anchors: Anchor[] = [], closed = false, stroke?: Stroke, fill?: Fill | ImageFill) {
    super();
    this.anchors = anchors;
    this.closed = closed;
    this.stroke = stroke;
    this.fill = fill;
  }

  clone() {
    return new Path(
      this.anchors.map((anchor) => anchor.clone()),
      this.closed,
      this.stroke?.clone(),
      this.fill?.clone()
    );
  }

  isValid() {
    return (
      Array.isArray(this.anchors) &&
      this.anchors.every(Anchor.isValid) &&
      (this.stroke === undefined || Stroke.isValid(this.stroke)) &&
      (this.fill === undefined || Fill.isValid(this.fill) || ImageFill.isValid(this.fill))
    );
  }

  affineTransform(affineMatrix: AffineMatrix) {
    for (let anchor of this.anchors) anchor.affineTransform(affineMatrix);
    if (this.fill instanceof ImageFill) {
      this.fill.affineTransform(affineMatrix);
    }
    return this;
  }

  affineTransformWithoutTranslation(affineMatrix: AffineMatrix) {
    for (let anchor of this.anchors) anchor.affineTransformWithoutTranslation(affineMatrix);
    if (this.fill instanceof ImageFill) {
      this.fill.affineTransformWithoutTranslation(affineMatrix);
    }
    return this;
  }

  allPaths() {
    return [this];
  }

  allAnchors() {
    return [...this.anchors];
  }

  allPathsAndCompoundPaths() {
    return [this];
  }

  hasStyle() {
    return Boolean(this.stroke) || Boolean(this.fill);
  }

  hasStrokeWithNonStandardAlignment() {
    return (
      this.closed && this.stroke && !this.stroke.hairline && this.stroke.alignment !== "centered"
    );
  }

  assignFill(fill: Fill | ImageFill) {
    this.fill = fill.clone();
    return this;
  }
  removeFill() {
    this.fill = undefined;
    return this;
  }

  assignStroke(stroke: Stroke) {
    this.stroke = stroke.clone();
    return this;
  }
  removeStroke() {
    this.stroke = undefined;
    return this;
  }

  assignStyle(fill: Fill, stroke: Stroke) {
    this.stroke = stroke?.clone();
    this.fill = fill?.clone();
    return this;
  }

  copyStyle(graphic: Graphic) {
    if (graphic instanceof Group && graphic.items.length > 0) {
      this.copyStyle(graphic.items[0]);
    } else if (graphic instanceof Path || graphic instanceof CompoundPath) {
      this.stroke = graphic.stroke?.clone();
      this.fill = graphic.fill?.clone();
    }
    return this;
  }

  scaleStroke(scaleFactor: number) {
    if (this.stroke) {
      this.stroke.width *= scaleFactor;
    }
    return this;
  }

  firstStyled() {
    if (this.fill || this.stroke) return this;
    return undefined;
  }

  /**
   * @returns an array of segments for the path.
   */
  segments() {
    return pairs(this.anchors, this.closed).map(([a1, a2]) => {
      if (a1.handleOut.isZero() && a2.handleIn.isZero()) {
        return LineSegment.fromAnchors(a1, a2);
      }
      return CubicSegment.fromAnchors(a1, a2);
    });
  }

  /**
   * @returns an array of paths corresponding to each segment in the path.
   */
  segmentPaths() {
    return pairs(this.anchors, this.closed).map((anchors) => new Path(anchors));
  }

  /**
   * A path can be broken into edges by splitting it at every corner. A corner
   * is an anchor with non-tangent handles. This means each edge will be a path
   * that is "smooth", i.e. all of its anchors have tangent handles.
   * @returns an array of edges for the path.
   */
  edgePaths() {
    const { anchors } = this;
    const anchorCount = anchors.length;
    if (anchorCount < 2) return [];

    let startIndex = 0;

    // Find the first sharp corner if this is a closed path
    if (this.closed) {
      while (startIndex < anchorCount && anchors[startIndex].hasTangentHandles()) {
        ++startIndex;
      }

      // If we havent found any sharp corners then return the entire path as a
      // single edge
      if (startIndex === anchorCount) {
        return [new Path(anchors, true)];
      }
    }

    const edgePaths: Path[] = [];
    let edgeAnchors: Anchor[] = [];

    // Accumulate anchors from the first corner
    for (let i = startIndex; i < anchorCount; ++i) {
      const anchor = anchors[i];
      edgeAnchors.push(anchor);
      if (edgeAnchors.length >= 2 && !anchor.hasTangentHandles()) {
        edgePaths.push(new Path(edgeAnchors));
        edgeAnchors = [anchor];
      }
    }

    // Accumulate any remaining anchors
    if (startIndex > 0) {
      for (let i = 0, n = startIndex + 1; i < n; ++i) {
        const anchor = anchors[i];
        edgeAnchors.push(anchor);
        if (edgeAnchors.length >= 2 && !anchor.hasTangentHandles()) {
          edgePaths.push(new Path(edgeAnchors));
          edgeAnchors = [anchor];
        }
      }
    } else if (this.closed) {
      edgeAnchors.push(anchors[0]);
    }

    // Append the final edge path
    if (edgeAnchors.length > 1) {
      edgePaths.push(new Path(edgeAnchors));
    }

    return edgePaths;
  }

  toSVGPathString(maxPrecision?: number) {
    const SVGStringCommandForSegment = (a1: Anchor, a2: Anchor): string => {
      if (a1.handleOut.x != 0 || a1.handleOut.y != 0 || a2.handleIn.x != 0 || a2.handleIn.y != 0) {
        const x1 = stringForNumber(a1.position.x + a1.handleOut.x, maxPrecision);
        const y1 = stringForNumber(a1.position.y + a1.handleOut.y, maxPrecision);
        const x2 = stringForNumber(a2.position.x + a2.handleIn.x, maxPrecision);
        const y2 = stringForNumber(a2.position.y + a2.handleIn.y, maxPrecision);
        const x3 = stringForNumber(a2.position.x, maxPrecision);
        const y3 = stringForNumber(a2.position.y, maxPrecision);
        return `C${x1} ${y1} ${x2} ${y2} ${x3} ${y3} `;
      } else {
        const x = stringForNumber(a2.position.x, maxPrecision);
        const y = stringForNumber(a2.position.y, maxPrecision);
        return `L${x} ${y} `;
      }
    };

    const { anchors } = this;
    if (anchors.length > 1) {
      const cmds: string[] = [];
      let a1 = anchors[0];
      const x = stringForNumber(a1.position.x, maxPrecision);
      const y = stringForNumber(a1.position.y, maxPrecision);
      cmds.push(`M${x} ${y} `);
      for (let i = 1, n = anchors.length; i < n; ++i) {
        let a2 = anchors[i];
        cmds.push(SVGStringCommandForSegment(a1, a2));
        a1 = a2;
      }
      if (this.closed) {
        cmds.push(SVGStringCommandForSegment(a1, anchors[0]));
        cmds.push("Z ");
      }
      return cmds.join("");
    }

    return " ";
  }

  looseBoundingBox() {
    const { anchors, closed } = this;

    if (anchors.length === 0) return;
    if (anchors.length === 1) return anchors[0].looseBoundingBox();

    const handle = new Vec();

    let anchor = anchors[0];
    const box = new BoundingBox(anchor.position.clone(), anchor.position.clone());
    box.expandToIncludePoint(handle.copy(anchor.position).add(anchor.handleOut));
    if (closed) {
      box.expandToIncludePoint(handle.copy(anchor.position).add(anchor.handleIn));
    }

    const n1 = anchors.length - 1;
    for (let i = 1; i < n1; ++i) {
      anchor = anchors[i];
      box.expandToIncludePoint(anchor.position);
      box.expandToIncludePoint(handle.copy(anchor.position).add(anchor.handleIn));
      box.expandToIncludePoint(handle.copy(anchor.position).add(anchor.handleOut));
    }

    anchor = anchors[n1];
    box.expandToIncludePoint(anchor.position);
    box.expandToIncludePoint(handle.copy(anchor.position).add(anchor.handleIn));
    if (closed) {
      box.expandToIncludePoint(handle.copy(anchor.position).add(anchor.handleOut));
    }

    return box;
  }

  boundingBox(): BoundingBox | undefined {
    const { anchors, closed } = this;

    const anchorCount = anchors.length;
    if (anchorCount === 0) return;
    if (anchorCount === 1) return anchors[0].boundingBox();

    const box = new BoundingBox(new Vec(Infinity), new Vec(-Infinity));
    const p1 = new Vec();
    const p2 = new Vec();
    const outMin = new Vec();
    const outMax = new Vec();
    let a0: Anchor;
    let a3: Anchor;
    for (let i = 1; i < anchorCount; ++i) {
      a0 = anchors[i - 1];
      a3 = anchors[i];
      p1.copy(a0.position).add(a0.handleOut);
      p2.copy(a3.position).add(a3.handleIn);
      boundingBoxForCubic(a0.position, p1, p2, a3.position, outMin, outMax);
      box.min.min(outMin);
      box.max.max(outMax);
    }

    if (closed) {
      a0 = anchors[anchors.length - 1];
      a3 = anchors[0];
      p1.copy(a0.position).add(a0.handleOut);
      p2.copy(a3.position).add(a3.handleIn);
      boundingBoxForCubic(a0.position, p1, p2, a3.position, outMin, outMax);
      box.min.min(outMin);
      box.max.max(outMax);
    }

    return box;
  }

  isContainedByBoundingBox(box: BoundingBox) {
    const bounds = this.boundingBox();
    return Boolean(bounds && box.containsBoundingBox(bounds));
  }

  isIntersectedByBoundingBox(box: BoundingBox) {
    const looseBounds = this.looseBoundingBox();
    if (looseBounds?.overlapsBoundingBox(box)) {
      const { min, max } = box;
      const p1 = new Vec(min.x, min.y);
      const p2 = new Vec(max.x, min.y);
      const p3 = new Vec(max.x, max.y);
      const p4 = new Vec(min.x, max.y);
      const intersections = this.intersectionsWith([
        new LineSegment(p1, p2),
        new LineSegment(p2, p3),
        new LineSegment(p3, p4),
        new LineSegment(p4, p1),
      ]);
      return intersections.length > 0;
    }
    return false;
  }

  isOverlappedByBoundingBox(box: BoundingBox) {
    return this.isContainedByBoundingBox(box) || this.isIntersectedByBoundingBox(box);
  }

  containsPoint(point: Vec) {
    if (!this.closed) return false;
    return pathContainsPoint(this, point);
  }

  styleContainsPoint(point: Vec) {
    return pathStyleContainsPoint(this, point);
  }

  containsPath(path: Path) {
    // Any anchor of an inner path will be inside the containing path.
    if (
      this.containsPoint(path.firstAnchor().position) &&
      this.containsPoint(path.lastAnchor().position)
    ) {
      const xs = path.intersectionsWith([this]);
      if (xs.length >= 2) {
        // Check the midpoints (in time) between every pair of intersections. If
        // they're all inside the containing path then we consider the whole
        // path to be contained even though it intersects.
        for (let [x1, x2] of pairs(xs, path.closed)) {
          // Appease TypeScript. We know these are segments since intersecting
          // two paths can only produce segment intersections.
          assert(isSegment(x1.primitive1) && isSegment(x2.primitive1));
          const t1 = x1.primitive1.source!.time + x1.time1;
          const t2 = x2.primitive1.source!.time + x2.time1;
          const time = path.mixedTime(t1, t2, 0.5);
          const mid = path.positionAtTime(time);
          if (!this.containsPoint(mid)) {
            return false;
          }
        }
      }
      return true;
    }
  }

  reverse() {
    for (let anchor of this.anchors) anchor.reverse();
    this.anchors.reverse();
    return this;
  }

  /**
   * @returns the approximate arc length of the path.
   */
  length() {
    let length = 0;
    for (let segment of this.segments()) {
      length += segment.length();
    }
    return length;
  }

  /**
   * @returns the signed area of the path. Clockwise paths will have a positive
   * area and counter-clockwise paths negative. Paths are assumed to be
   * non-intersecting. Paths such as infinity (∞) that cross over themselves
   * will have both positive and negative areas which will cancel out and give
   * an incorrect result.
   */
  signedArea() {
    let area = 0;

    const segments = this.segments();
    for (let segment of segments) {
      area += segment._signedArea();
    }

    if (!this.closed) {
      // Add an effective line segment between the endpoints to mimic what the
      // area would be if filled.
      const closingSegment = new LineSegment(segments[segments.length - 1].e, segments[0].s);
      area += closingSegment._signedArea();
    }

    return area;
  }

  /**
   * @returns the area of the path. Paths such as infinity (∞) that cross over
   * themselves will have both positive and negative areas which will cancel out
   * and give an incorrect result.
   */
  area() {
    return Math.abs(this.signedArea());
  }

  /**
   * @returns the approximate time at `distance` along the path.
   *
   * If `distance` is greater than the length of the path, this will return the
   * time of the last anchor.
   *
   * @param distance A positive distance
   */
  timeAtDistance(distance: number) {
    assert(distance >= 0, "Distance must be a positive number");

    if (distance === 0) return 0;

    const endTime = this.endTime();

    let length = 0;
    for (let t = 0; t < endTime; ++t) {
      const segment = this.segmentAtTime(t);
      const segLength = segment.length();
      if (length + segLength > distance) {
        return Math.min(endTime, t + segment.timeAtDistance(distance - length));
      }
      length += segLength;
    }

    return endTime;
  }

  /**
   * @returns the approximate distance along the path at `time`.
   * @param time A valid path time
   */
  distanceAtTime(time: number) {
    if (time <= 0) return 0;

    const { anchors, closed } = this;
    if (time >= (closed ? anchors.length : anchors.length - 1)) return this.length();

    const segmentIndex = Math.floor(time);
    let distance = 0;
    for (let i = 0; i < segmentIndex; ++i) {
      distance += this.segmentAtTime(i).length();
    }
    const segmentTime = time - segmentIndex;
    if (segmentTime > 0) {
      distance += this.segmentAtTime(segmentIndex).distanceAtTime(segmentTime);
    }

    return distance;
  }

  /**
   * @returns the canonical time for `time`.
   *
   * If the path is closed, time will loop.
   *
   * @param time A valid path time
   */
  normalizedTime(time: number) {
    if (this.closed) {
      // Loop time in closed paths
      return modulo(time, this.anchors.length);
    }
    return time;
  }

  /**
   * @returns `true` if `time1` and `time2` correspond to the same normalized
   * path time.
   */
  isSameTime(time1: number, time2: number) {
    return this.normalizedTime(time1) === this.normalizedTime(time2);
  }

  /**
   * @returns the first anchor at the start of the path.
   */
  firstAnchor() {
    const { anchors } = this;
    assert(anchors.length > 0, "Path contains no anchors");
    return anchors[0];
  }
  /**
   * @returns the last anchor at the end of the path.
   */
  lastAnchor() {
    const { anchors } = this;
    assert(anchors.length > 0, "Path contains no anchors");
    return anchors[anchors.length - 1];
  }

  /**
   * @returns the time that corrensponds to the end of the path. This is
   * different for closed paths to account for the closing segment.
   */
  endTime() {
    return this.closed ? this.anchors.length : this.anchors.length - 1;
  }

  /**
   * @param time1 The time when the mixing factor is 0
   * @param time2 The time when the mixing factor is 1
   * @param t The mixing factor
   *
   * @returns a time in-between `time1` and `time2`. If the path is closed,
   * return a time in the shorter of the two possible spans.
   */
  mixedTime(time1: number, time2: number, t: number) {
    let dt = time2 - time1;
    if (this.closed) {
      const n = this.anchors.length;
      if (Math.abs(dt) * 2 > n) dt -= n;
    }
    return time1 + dt * t;
  }

  /**
   * @returns `true` if the point at `time` is a corner, meaning that there is
   * an anchor at `time` that does not have tangent handles or is an endpoint.
   *
   * Note: this currently counts any anchor with zeroed handles as a corner,
   * even if it's a 180 degree anchor.
   *
   * @param time A valid path time
   */
  isCorner(time: number) {
    if (this.isEndpoint(time)) return false;
    const anchor = this.anchorAtTime(time);
    return anchor && !anchor.hasTangentHandles();
  }

  /**
   * @returns `true` if the point at `time` is an endpoint of the path.
   *
   * Note that this will always return `false` for closed paths since they have
   * no endpoints.
   *
   * @param time A valid path time
   */
  isEndpoint(time: number) {
    return !this.closed && (time === 0 || time === this.anchors.length - 1);
  }

  /**
   * @returns the anchor at or before `time`.
   */
  anchorAtTime(time: number) {
    time = this.normalizedTime(Math.floor(time));
    const anchor = this.anchors[time];
    assert(anchor, `Path does not contain an anchor at time ${time}`);
    return anchor;
  }

  /**
   * @returns the segment that contains the point at `time`.
   *
   * When called on open paths with `time` at its maximum, the last segment will
   * be returned.
   *
   * @param time A valid path time
   */
  segmentAtTime(time: number) {
    const { anchors, closed } = this;
    assert(anchors.length >= 2, "A path needs at least 2 anchors to have a segment");

    // We must convert to an integer index in order to prevent float precision
    // from rounding `time + 1` to the next value due to float imprecision.
    let index = Math.floor(time);

    // Return the last segment when `time` at its maximum value.
    if (!closed && index === anchors.length - 1) {
      --index;
    }

    const a1 = this.anchorAtTime(index);
    const a2 = this.anchorAtTime(index + 1);

    assert(a1 !== a2);

    if (a1.handleOut.isZero() && a2.handleIn.isZero()) {
      return LineSegment.fromAnchors(a1, a2);
    }
    return CubicSegment.fromAnchors(a1, a2);
  }

  /**
   * @returns the position on the path at `time`.
   * @param time A valid path time
   */
  positionAtTime(time: number) {
    const { anchors, closed } = this;

    assert(anchors.length > 0, "A path needs at least 1 anchor to calculate a position");

    if (anchors.length === 1) {
      return anchors[0].position.clone();
    }
    if (!closed && almostEquals(time, anchors.length - 1, PATH_TIME_EPSILON)) {
      // NOTE: In an open path this.segmentAtTime(anchors.length - 1) would
      // return undefined, so we add a special case and just return the position
      // of the last anchor.
      return anchors[anchors.length - 1].position.clone();
    }

    return this.segmentAtTime(time).positionAtTime(fract(time));
  }

  /**
   * @returns the first derivative of the path at `time`.
   * @param time A valid path time
   */
  derivativeAtTime(time: number) {
    const { anchors } = this;

    assert(anchors.length > 0, "A path needs at least 1 anchor to calculate a derivative");

    if (anchors.length === 1) {
      return anchors[0].handleOut.clone();
    }
    if (!closed && almostEquals(time, anchors.length - 1, PATH_TIME_EPSILON)) {
      // NOTE: In an open path this.segmentAtTime(anchors.length - 1) would
      // return undefined, so we add a special case and just return the
      // derivative of the last anchor.
      return anchors[anchors.length - 1].handleIn.clone().negate();
    }

    return this.segmentAtTime(time).derivativeAtTime(fract(time));
  }

  /**
   * @returns the second derivative of the path at `time`.
   *
   * The second derivative can also be thought of as the "acceleration", or
   * change in velocity of a point moving along the curve.
   *
   * @param time A valid path time
   */
  secondDerivativeAtTime(time: number) {
    const { anchors } = this;

    assert(anchors.length > 0, "A path needs at least 1 anchor to calculate a second derivative");

    if (anchors.length === 1) {
      return new Vec(0, 0);
    }
    if (!closed && almostEquals(time, anchors.length - 1, PATH_TIME_EPSILON)) {
      // NOTE: In an open path this.segmentAtTime(anchors.length - 1) would
      // return undefined, so we add a special case and just return the second
      // derivative at time 1 of the last segment.
      return this.segmentAtTime(anchors.length - 2).secondDerivativeAtTime(1);
    }

    return this.segmentAtTime(time).secondDerivativeAtTime(fract(time));
  }

  /**
   * @returns the curvature of the path at `time`.
   *
   * Curvature describes how "bent" a path is at a given point. It can be
   * thought of as the reciprocal of the radius of a circle with the same
   * curvature. To find the radius, one can use the formula `1 / curvature`.
   *
   * @param time A valid path time
   */
  curvatureAtTime(time: number) {
    const { anchors } = this;

    assert(anchors.length > 0, "A path needs at least 1 anchor to calculate curvature");

    if (anchors.length === 1) {
      return 0;
    }
    if (!closed && almostEquals(time, anchors.length - 1, PATH_TIME_EPSILON)) {
      // NOTE: In an open path this.segmentAtTime(anchors.length - 1) would
      // return undefined, so we add a special case and just return the
      // curvature at time 1 of the last segment.
      return this.segmentAtTime(anchors.length - 2).curvatureAtTime(1);
    }

    return this.segmentAtTime(time).curvatureAtTime(fract(time));
  }

  /**
   * @returns a unit-length vector tangent to the path at `time`.
   * @param time A valid path time
   */
  tangentAtTime(time: number) {
    if (!closed) {
      const endTime = this.anchors.length - 1;
      if (almostEquals(time, endTime, PATH_TIME_EPSILON)) {
        return this.segmentAtTime(endTime - 1).tangentAtTime(1);
      }
    }
    return this.segmentAtTime(time).tangentAtTime(fract(time));
  }

  /**
   * @returns a unit-length vector perpendicular to the path at `time`.
   * @param time A valid path time
   */
  normalAtTime(time: number) {
    return this.tangentAtTime(time).rotate90();
  }

  /**
   * @returns the angle of of the tangent to the path at `time`.
   * @param time A valid path time
   */
  angleAtTime(time: number) {
    return this.derivativeAtTime(time).angle();
  }

  /**
   * Attempts to insert an anchor into the path at `time`.
   *
   * @returns the inserted anchor, or `undefined` if there already was an anchor
   * at `time`
   *
   * @param time A valid path time
   */
  insertAnchorAtTime(time: number) {
    const { anchors } = this;

    assert(anchors.length >= 2, "A path needs at least 2 anchors to insert an anchor at a time");

    time = this.normalizedTime(time);
    const segmentIndex = Math.trunc(time);
    if (segmentIndex === time) {
      // We're inserting exactly at an anchor position. Return `undefined` to
      // indicate that an anchor was not inserted.
      return undefined;
    }

    const segment = this.segmentAtTime(time);
    if (!segment) return undefined;

    const [s1, s2] = segment.splitAtTime(fract(time));

    const position = s1.e;
    const handleIn = new Vec();
    const handleOut = new Vec();
    if (s1 instanceof CubicSegment) {
      handleIn.copy(s1.ce).sub(position);
      anchors[segmentIndex].handleOut.copy(s1.cs).sub(s1.s);
    }
    if (s2 instanceof CubicSegment) {
      handleOut.copy(s2.cs).sub(position);
      const nextSegmentIndex = this.normalizedTime(segmentIndex + 1);
      anchors[nextSegmentIndex].handleIn.copy(s2.ce).sub(s2.e);
    }

    const anchor = new Anchor(position, handleIn, handleOut);
    anchors.splice(segmentIndex + 1, 0, anchor);

    return anchor;
  }

  /**
   * Splits the path at a specific `anchor`.
   *
   * @param anchor An anchor contained within the path
   *
   * @returns Two open paths if the path is already open, or one open path if
   * the path is closed
   *
   * Note: Paths returned from this method will share references to anchors in
   * the original path.
   */
  splitAtAnchor(anchor: Anchor) {
    const anchorIndex = this.anchors.indexOf(anchor);
    assert(
      anchorIndex >= 0,
      "An anchor must be contained within a path to be used as a split point"
    );
    return this.splitAtTime(anchorIndex);
  }

  /**
   * Splits the path at a specific `time`.
   *
   * @returns two open paths if the path is already open, or one open path if
   * the path is closed.
   *
   * Note: Paths returned from this method will share references to anchors in
   * the original path.
   *
   * @param time A valid path time
   */
  splitAtTime(time: number): [Path] | [Path, Path] {
    time = this.normalizedTime(time);

    const path = this.clone();
    const { anchors, closed } = path;

    // Don't allow splitting at the endpoints.
    if (time <= 0 || time >= anchors.length - 1) {
      return [path];
    }

    let index = Math.trunc(time);
    const insertedAnchor = path.insertAnchorAtTime(time);
    if (insertedAnchor) {
      // Assuming we inserted an anchor, we bump to its index.
      ++index;
    }

    if (closed) {
      if (index > 0) rotateArray(anchors, index);
      anchors.push(anchors[0].clone());
      path.closed = false;
      return [path];
    }

    const path1 = new Path(anchors.slice(0, index));
    const path2 = new Path(anchors.slice(index));

    if (this.stroke) {
      path1.stroke = this.stroke.clone();
      path2.stroke = this.stroke.clone();
    }
    if (this.fill) {
      path1.fill = this.fill.clone();
      path2.fill = this.fill.clone();
    }

    path1.anchors.push(path2.anchors[0].clone()); // Append a copy of the shared anchor.
    return [path1, path2];
  }

  /**
   * Splits the path in one or more places.
   *
   * @param times An array of path times
   * @returns One or more paths, depending on the number of split times.
   */
  splitAtTimes(times: number[]): Path[] {
    if (times.length === 1) {
      return this.splitAtTime(times[0]);
    }
    const orderedTimes = times.slice().sort((a, b) => a - b);
    const paths: Path[] = [];
    const n = orderedTimes.length;
    let prevTime = this.closed ? orderedTimes[n - 1] : 0;
    for (let i = 0; i < n; ++i) {
      const time = orderedTimes[i];
      const slice = this.slice(prevTime, time);
      paths.push(slice);
      prevTime = time;
    }
    if (!this.closed) {
      const lastSlice = this.slice(prevTime, this.anchors.length - 1);
      paths.push(lastSlice);
    }
    return paths;
  }

  /**
   * Replaces sharp corners in the path with circular arcs of `radius`.
   *
   * @radius A positive number
   * @chainable
   */
  roundCorners(radius: number | number[]) {
    filletPath(this, radius);
    return this;
  }

  /**
   * Replaces a sharp corner with a circular arc of `radius` at a specific
   * anchor.
   *
   * @param anchor The anchor to replace
   * @param radius A positive number
   * @chainable
   */
  roundCornerAtAnchor(anchor: Anchor, radius: number) {
    const radii = this.anchors.map((a) => (a === anchor ? radius : 0));
    return this.roundCorners(radii);
  }

  /**
   * @returns A new path that is the subset of the path between `startTime` and
   * `endTime`.
   *
   * @param startTime A valid path time
   * @param endTime A valid path time greater than `startTime`
   */
  slice(startTime: number, endTime: number) {
    if (this.closed && endTime < startTime) {
      // Allow slices that straddle the closing anchor.
      endTime += this.anchors.length;
    }

    const startIndex = Math.floor(startTime);
    const endIndex = Math.ceil(endTime);

    // Copy anchors from startIndex to endIndex
    const anchors: Anchor[] = new Array(endIndex - startIndex + 1);
    for (let i = startIndex; i < endIndex + 1; ++i) {
      const normalizedIndex = this.normalizedTime(i);
      anchors[i - startIndex] = this.anchors[normalizedIndex].clone();
    }

    const closed = this.closed && endTime - startTime === this.endTime();
    const path = new Path(anchors, closed, this.stroke?.clone(), this.fill?.clone());
    if (endTime < endIndex) {
      if (path.insertAnchorAtTime(endTime - startIndex)) {
        // If an anchor was inserted, remove the first one.
        path.anchors.pop();
      }
    }
    if (startTime > startIndex) {
      let time = startTime - startIndex;
      if (startIndex === endIndex - 1) {
        // We've already inserted an anchor into the first segment, so our end
        // anchor insertion time needs to be adjusted.
        const endTimeFract = fract(endTime);
        if (endTimeFract > 0) {
          time /= endTimeFract;
        }
      }
      if (path.insertAnchorAtTime(time)) {
        // If an anchor was inserted, remove the last one.
        path.anchors.shift();
      }
    }
    // If we sliced the whole path, remove the last anchor and move its handleIn
    // to the first anchor.
    if (closed && path.anchors.length >= 2) {
      path.firstAnchor().handleIn.copy(path.lastAnchor().handleIn);
      path.anchors.pop();
    }
    return path;
  }

  /**
   * Cuts the path and inserts each splice `path` between `time1` and `time2`.
   *
   * Splices are assumed to be non-overlapping and ordered such that `time2` is
   * greater than `time1` and time values increase monotonically when
   * forward-iterating through the array. Splice paths are assumed to connect at
   * their first and last anchors sequentially.
   *
   * @chainable
   */
  splice(orderedSplices: { path: Path; time1: number; time2: number }[]) {
    if (orderedSplices.length <= 0) return this;

    const { anchors, closed } = this;

    let prevTime2 = this.closed ? orderedSplices[orderedSplices.length - 1].time2 : 0;
    const slices: Path[] = [];
    for (let { path, time1, time2 } of orderedSplices) {
      if (time1 > prevTime2) {
        // Create an intermediate slice if there is a positively sized gap.
        const slice = this.slice(prevTime2, time1);
        slices.push(slice);
      }
      slices.push(path);
      prevTime2 = time2;
    }
    const endTime = closed ? anchors.length + orderedSplices[0].time1 : anchors.length - 1;
    if (endTime > prevTime2) {
      const slice = this.slice(prevTime2, endTime);
      slices.push(slice);
    }

    const splicedAnchors: Anchor[] = [];
    let prevSliceLastAnchor: Anchor | undefined;
    for (let { anchors } of slices) {
      let i = 0;
      let anchor = anchors[i];
      if (prevSliceLastAnchor) {
        prevSliceLastAnchor.handleOut.copy(anchor.handleOut);
        ++i;
      }
      for (; i < anchors.length; ++i) {
        anchor = anchors[i];
        splicedAnchors.push(anchor);
      }
      prevSliceLastAnchor = anchor;
    }

    if (closed) {
      // Combine the first and last anchor.
      const firstAnchor = splicedAnchors[0];
      if (prevSliceLastAnchor) {
        firstAnchor.handleIn.copy(prevSliceLastAnchor.handleIn);
        splicedAnchors.pop();
      }
    }

    this.anchors = splicedAnchors;
    return this;
  }

  warpCoordinates(mappingFunc: (p: Vec) => Vec, tolerance: number) {
    let warpedAnchors: Anchor[] | undefined;
    for (let edge of this.edgePaths()) {
      const warpedEdge = Path.fromSmoothFunction(
        (t) => {
          return mappingFunc(edge.positionAtTime(edge.timeAtDistance(t)));
        },
        0,
        edge.length(),
        tolerance
      );
      if (warpedAnchors) {
        warpedAnchors[warpedAnchors.length - 1].handleOut = warpedEdge.anchors[0].handleOut;
        warpedEdge.anchors.shift();
        warpedAnchors.push(...warpedEdge.anchors);
      } else {
        warpedAnchors = warpedEdge.anchors;
      }
    }
    if (warpedAnchors) {
      if (this.closed) {
        const closingAnchor = warpedAnchors.pop();
        if (closingAnchor) {
          warpedAnchors[0].handleIn = closingAnchor.handleIn;
        }
      }
      this.anchors = warpedAnchors;
    }
    return this;
  }

  /**
   * Merges contiguous anchors of this path that are less than some distance
   * apart from each other.
   *
   * @param mergeDistance A small threshold distance. Anchors closer than this
   * will be merged together.
   *
   * @chainable
   */
  mergeAnchors(mergeDistance = DEFAULT_TOLERANCE) {
    const { anchors, closed } = this;

    let len = anchors.length;
    if (len < 2) return this;

    const mergedAnchors = [];

    let firstIndex = 0;
    let lastIndex = firstIndex;
    let currentIndex: number;
    let a1;
    let a2;
    const iEnd = closed ? len + 1 : len;
    for (let i = 1; i < iEnd; ++i) {
      currentIndex = i % len;
      a1 = anchors[firstIndex];
      a2 = anchors[currentIndex];

      const d = a1.position.distance(a2.position);
      if (d < mergeDistance) {
        // Move the index marker and keep going til we find an anchor that's far
        // enough away.
        lastIndex = currentIndex;
        continue;
      }

      // Merge anchors into the first anchor.
      const merged = mergeRangeOfAnchors(anchors, firstIndex, lastIndex);

      // Append the anchor.
      mergedAnchors.push(merged);

      // Reset.
      firstIndex = lastIndex = currentIndex;
    }

    // Complete paths that end with a merge.
    if (firstIndex !== lastIndex) {
      const merged = mergeRangeOfAnchors(anchors, firstIndex, lastIndex);
      if (closed) {
        mergedAnchors[0] = merged;
      } else {
        mergedAnchors.push(merged);
      }
    }

    // Complete open paths that don't end with a merge.
    if (!closed && firstIndex === lastIndex && firstIndex === len - 1) {
      mergedAnchors.push(anchors[firstIndex]);
    }

    this.anchors = mergedAnchors;

    return this;
  }

  closestPoint(point: Vec, areaOfInterest?: BoundingBox): ClosestPointResultWithTime | undefined {
    const { anchors, closed } = this;
    const len = anchors.length;

    if (len === 0) return undefined;
    if (len === 1) return { position: anchors[0].position, time: 0 };

    const dummy = new Vec(); // Placeholder for segment construction. These will be replaced later.
    const lineSegment = new LineSegment(dummy, dummy);
    const cubicSegment = new CubicSegment(dummy, new Vec(), new Vec(), dummy);

    let closestResult: ClosestPointResultWithTime | undefined;
    let closestDistanceSq = Infinity;

    for (let i = 0, n = closed ? len : len - 1; i < n; ++i) {
      const a1 = anchors[i];
      const a2 = anchors[(i + 1) % len];

      let result: ClosestPointResultWithTime | undefined;
      if (a1.handleOut.isZero() && a2.handleIn.isZero()) {
        result = lineSegment.setFromAnchors(a1, a2).closestPoint(point, areaOfInterest);
      } else {
        result = cubicSegment.setFromAnchors(a1, a2).closestPoint(point, areaOfInterest);
      }

      if (!result) continue;

      const distanceSq = point.distanceSquared(result.position);
      if (distanceSq < closestDistanceSq) {
        result.time += i; // Turn segment time into path time by adding the current index.
        result.geometry = this;
        closestResult = result;
        closestDistanceSq = distanceSq;
      }
    }

    return closestResult;
  }

  _primitives() {
    const segments = this.segments();
    for (let i = 0; i < segments.length; ++i) {
      segments[i].source = { path: this, time: i };
    }
    return segments;
  }

  rasterize(options?: RasterizeOptions): Path | undefined {
    const res = rasterizeGraphic(this, options);
    if (!res) return;
    const imagePath = Path.fromBoundingBox(res.boundingBox);
    imagePath.fill = res.fill;
    return imagePath;
  }

  static isValid(a: unknown): a is Path {
    return a instanceof Path && a.isValid();
  }

  /**
   * Connects a series of points with lines to form a path.
   */
  static fromPoints(points: Vec[], closed = false) {
    return new Path(
      points.map((point) => new Anchor(point)),
      closed
    );
  }

  /**
   * Constructs a path from an array of points interpreted as cubic bezier
   * positions.
   *
   * In canvas-style drawing terms, the first point is interpreted as a "move
   * to" command. Subsequent groups of 3 points are interpreted as "curve to"
   * commands.
   */
  static fromCubicBezierPoints(points: Vec[], closed = false) {
    let prevAnchor = new Anchor(points[0]);
    const path = new Path([prevAnchor], closed);
    for (let i = 1, n = points.length; i < n; ) {
      prevAnchor.handleOut.copy(points[i]).sub(prevAnchor.position);
      if (++i === n) break;
      const nextHandleIn = points[i].clone();
      if (++i === n) {
        if (closed) {
          path.anchors[0].handleIn.copy(nextHandleIn).sub(path.anchors[0].position);
        } else {
          path.anchors.push(new Anchor(nextHandleIn));
        }
        break;
      }
      const nextAnchor = new Anchor(points[i], nextHandleIn);
      nextAnchor.handleIn.sub(nextAnchor.position);
      path.anchors.push(nextAnchor);
      prevAnchor = nextAnchor;
      ++i;
    }
    return path;
  }

  /**
   * Constructs a path from a segment.
   */
  static fromSegment(segment: LineSegment | CubicSegment) {
    if (segment instanceof LineSegment) {
      return new Path([new Anchor(segment.s.clone()), new Anchor(segment.e.clone())]);
    }
    return new Path([
      new Anchor(segment.s.clone(), undefined, segment.cs.clone().sub(segment.s)),
      new Anchor(segment.e.clone(), segment.ce.clone().sub(segment.e)),
    ]);
  }

  /**
   * Constructs a path from a sequence of path fragments.
   *
   * If sequential fragments are connected -- the second fragment starts from
   * the position where the previous fragment ended -- they will be merged
   * together. Otherwise, a line will be drawn in-between.
   *
   * If the start and end points of the entire sequence are equal, the resulting
   * path will be closed.
   */
  static fromFragments(fragments: (LineSegment | CubicSegment | Path | Anchor)[]) {
    if (fragments.length <= 0) return new Path();

    // Create the path from the first part.
    const firstFragment = fragments[0];
    let path: Path;
    if (firstFragment instanceof Path) {
      path = firstFragment;
    } else if (firstFragment instanceof Anchor) {
      path = new Path([firstFragment]);
    } else {
      path = Path.fromSegment(firstFragment);
    }

    // Append the rest of the parts in order.
    const anchors = path.anchors;
    const appendFragment = (fragment: LineSegment | CubicSegment | Path | Anchor) => {
      const prevAnchor = anchors[anchors.length - 1];
      if (fragment instanceof LineSegment) {
        if (!fragment.s._almostEquals(prevAnchor.position)) {
          anchors.push(new Anchor(fragment.s.clone()));
        }
        anchors.push(new Anchor(fragment.e.clone()));
      } else if (fragment instanceof CubicSegment) {
        if (fragment.s._almostEquals(prevAnchor.position)) {
          prevAnchor.handleOut.copy(fragment.cs).sub(fragment.s);
        } else {
          anchors.push(new Anchor(fragment.s, new Vec(), fragment.cs.clone().sub(fragment.s)));
        }
        anchors.push(new Anchor(fragment.e.clone(), fragment.ce.clone().sub(fragment.e)));
      } else if (fragment instanceof Path) {
        for (let seg of fragment.segments()) {
          appendFragment(seg);
        }
      } else if (fragment instanceof Anchor) {
        if (fragment.position._almostEquals(prevAnchor.position)) {
          prevAnchor.handleOut.copy(fragment.handleOut);
        } else {
          anchors.push(fragment.clone());
        }
      }
    };
    for (let i = 1; i < fragments.length; ++i) {
      appendFragment(fragments[i]);
    }

    // Close the path if it meets at its endpoints.
    const firstAnchor = anchors[0];
    const lastAnchor = anchors[anchors.length - 1];
    if (firstAnchor.position._almostEquals(lastAnchor.position)) {
      firstAnchor.handleIn = lastAnchor.handleIn;
      anchors.pop();
      path.closed = true;
    }

    return path;
  }

  /**
   * Constructs a path from a bounding box.
   *
   * @returns A clockwise closed path
   */
  static fromBoundingBox(box: BoundingBox) {
    const { min, max } = box;
    return new Path(
      [
        new Anchor(new Vec(min.x, min.y)),
        new Anchor(new Vec(max.x, min.y)),
        new Anchor(new Vec(max.x, max.y)),
        new Anchor(new Vec(min.x, max.y)),
      ],
      true
    );
  }

  /**
   * Constructs an arc (circle segment) path.
   *
   * @param startAngle The angle at the start of the arc, specified in degrees
   * @param endAngle The angle at the end of the arc, specified in degrees
   *
   * To construct a clockwise arc, `endAngle` must be greater than `startAngle`.
   * If `endAngle` is less than `startAngle`, add 360° to draw a clockwise arc.
   * For counter-clockwise, do the opposite.
   * ```
   * if (endAngle < startAngle) {
   *   endAngle += 360;
   * }
   * ```
   */
  static fromArc(center: Vec, radius: number, startAngle: number, endAngle: number) {
    const absAngle = Math.abs(startAngle - endAngle);
    const numSegments = Math.ceil(absAngle / 90);
    const segmentAngle = (endAngle - startAngle) / numSegments;

    // Built up path from segments.
    let lastAnchor = new Anchor(new Vec(1, 0));
    const path = new Path([lastAnchor]);
    for (let i = 0; i < numSegments; i++) {
      const segment = arcSegment(segmentAngle);
      segment.transform({ rotation: i * segmentAngle });
      lastAnchor.handleOut = segment.anchors[0].handleOut;
      lastAnchor = segment.anchors[1];
      path.anchors.push(lastAnchor);
    }

    path.transform({
      position: center,
      rotation: startAngle,
      scale: radius,
    });

    return path;
  }

  /**
   * Constructs a path that plots a smooth function over a given time domain.
   *
   * It's important that `func` be a smooth function for this to produce a path.
   * A smooth function must be continuous and should have C1 differentiability
   * (continuous derivative).
   *
   * See: https://en.wikipedia.org/wiki/Smoothness
   *
   * @param func The function to plot, taking a single argument "time"
   * @param domainStart Start of the time domain to evaluate the function over
   * @param domainEnd End of the time domain to evaluate the function over
   * @param tolerance The resulting path will be considered "close enough" if it
   * is within this distance from the true plot.
   */
  static fromSmoothFunction(
    func: (t: number) => Vec,
    domainStart: number,
    domainEnd: number,
    tolerance: number
  ) {
    return pathFromSmoothFunction(func, domainStart, domainEnd, tolerance);
  }
}

const arcSegment = (angle: number) => {
  // based on https://pomax.github.io/bezierinfo/#circles_cubic
  const f = (4 / 3) * Math.tan((angle / 4) * RADIANS_PER_DEGREE);
  return new Path([
    new Anchor(new Vec(1, 0), new Vec(0, 0), new Vec(0, f)),
    new Anchor(new Vec(1, 0).rotate(angle), new Vec(0, -f).rotate(angle), new Vec(0, 0)),
  ]);
};

const mergeRangeOfAnchors = (anchors: Anchor[], firstIndex: number, lastIndex: number) => {
  // Multiple strategies are possible here. We average anchor positions, and use
  // the handleIn from the first anchor and the handleOut from the last anchor.
  const len = anchors.length;
  const firstAnchor = anchors[firstIndex];
  if (firstIndex === lastIndex) return firstAnchor;
  if (lastIndex < firstIndex) {
    lastIndex += len;
  }
  const lastAnchor = anchors[lastIndex % len];
  const endIndex = lastIndex + 1;
  for (let i = firstIndex + 1; i < endIndex; ++i) {
    const a = anchors[i % len];
    firstAnchor.position.add(a.position);
  }
  const count = endIndex - firstIndex;
  firstAnchor.position.mulScalar(1 / count);
  firstAnchor.handleOut.copy(lastAnchor.handleOut);

  return firstAnchor;
};
