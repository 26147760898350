import m from "mithril";

import { assert } from "../../geom";
import { AutoSelectComment, globalState } from "../../global-state";
import { CodeComponent, Component } from "../../model/component";
import { expressionCodeForString } from "../../model/expression-code";
import { DocumentationFocus } from "../../model/focus";
import {
  filterFontFileNameFromFontURLArray,
  isFontFileNameInFontURLArray,
} from "../../model/font-list";
import { Parameter, ParameterFolder } from "../../model/parameter";
import {
  PIAngle,
  PIBoolean,
  PIColor,
  PIDistance,
  PIEmoji,
  PIFontSelect,
  PIImage,
  PIPercentage,
  PIPoint,
  PIScalar,
  PISelect,
  PISelectOption,
  PIText,
  PIVector,
} from "../../model/parameter-interface";
import { parseLiteral } from "../../model/parse-literal";
import { SelectableComponentParameter, SelectableParameter } from "../../model/selectable";
import { canonicalAssetsOrigin } from "../../shared/config";
import { EditableText } from "../../shared/editable-text";
import { Icon20, IconButton } from "../../shared/icon";
import { modalState } from "../../shared/modal";
import {
  MenuItem,
  Tooltipped,
  createPopupMenu,
  createRightClickPopupMenu,
} from "../../shared/popup";
import { classNames, domForVnode, isPointerEventRightClick, isString } from "../../shared/util";
import { DocEditorText } from "../doc-editor/doc-editor-text";
import { EvaluationResult } from "../evaluation-result";
import { SelectOptionsModal } from "../select-options-modal";
import { startDrag } from "../start-drag";
import styleConstants from "../style-constants";
import { POINTER_EVENT_BUTTONS_NONE, TextMeasurer, showAdminFeatures } from "../util";
import { ParameterInput } from "./parameter-input";
import { ParameterStack } from "./parameter-stack";

const parameterNameTextMeasurer = new TextMeasurer(styleConstants.defaultFont14);

export interface ParameterInspectorAttrs {
  selectables: (SelectableParameter | SelectableComponentParameter)[];
  isEditingDefinition: boolean;
  folder?: ParameterFolder;
}
export const ParameterInspector: m.ClosureComponent<ParameterInspectorAttrs> = () => {
  let isEditingExpression = false;
  return {
    view(vnode) {
      const {
        attrs: { selectables, folder, isEditingDefinition },
      } = vnode;

      const stack = new ParameterStack(selectables, isEditingDefinition);
      const { parameter } = stack.heroSelectable;

      const isLongName =
        parameterNameTextMeasurer.getWidth(parameter.name) > styleConstants.parameterNameWidth;
      const definition = stack.heroSelectable.definition();

      const siblingParameters = folder?.parameters ?? definition.parameters;

      const isEmbed = globalState.project.focus instanceof DocumentationFocus;
      const isDerived = globalState.project.isParameterDerived(definition, parameter);
      const isDefinitionImmutable = definition.isImmutable;
      const isOverridden = stack.heroSelectable.isOverridden();
      const isHovered = globalState.project.isItemHovered(stack.heroSelectable);
      const isSelected = globalState.project.selection.contains(stack.heroSelectable);

      const expression = stack.heroSelectable.expression();
      const isLiteral = expression.isLiteral();

      const onChangeCode = (jsCode: string) => {
        stack.setEditingExpressionCode(jsCode);
        if (!stack.isMultiline) {
          stack.tryCompileAndCommitEditingExpression();
        }
      };
      const onCommitCode = () => stack.commitEditingExpression();
      const onExpressionEditorBlur = () => {
        isEditingExpression = false;
        if (canRevertToDefault && stack.heroSelectable.expression().isEmpty()) {
          revertToDefault();
        } else {
          stack.commitEditingExpression();
        }
      };

      const isInFocusedComponentContext = (selectable: SelectableParameter) => {
        const focusedComponent = globalState.project.focusedComponent();
        return focusedComponent === globalState.project.contextComponentForNode(selectable.node);
      };
      const canExtractAsParameter =
        isLiteral &&
        !isEmbed &&
        !stack.isImmutable &&
        !stack.isMixed &&
        // Should only extract a parameter while an instance of the modifier or code component is selected.
        !isEditingDefinition &&
        // Not SelectableComponentParameter
        stack.heroSelectable instanceof SelectableParameter &&
        isInFocusedComponentContext(stack.heroSelectable);
      /** Extract current expression to a new component parameter */
      const extractAsParameter = () => {
        const focusedComponent = globalState.project.focusedComponent();
        if (!focusedComponent) return;

        const currentExpression = stack.heroSelectable.expression();
        const newParameter = globalState.project.createParameter(
          focusedComponent,
          currentExpression.jsCode,
          parameter.name
        );
        if (parameter.interface) {
          newParameter.interface = parameter.interface.clone();
        }

        globalState.isComponentParameterInspectorOpen = true;
        globalState.autoSelectForRename = newParameter;

        // Replace the current expression with a reference to the new parameter.
        onChangeCode(newParameter.name);
      };

      const canMoveParameterToProject =
        isLiteral &&
        !isEmbed &&
        !stack.isImmutable &&
        !stack.isMixed &&
        isEditingDefinition &&
        stack.heroSelectable instanceof SelectableComponentParameter &&
        // Not a project parameter
        (stack.heroSelectable.component instanceof Component ||
          stack.heroSelectable.component instanceof CodeComponent);
      const moveParameterToProject = () => {
        assert(stack.heroSelectable instanceof SelectableComponentParameter);
        assert(
          stack.heroSelectable.component instanceof Component ||
            stack.heroSelectable.component instanceof CodeComponent
        );
        globalState.project.moveComponentParameterToProject(stack.heroSelectable);
      };

      // We want to have "Edit Expression" in menu, if the editing interface is
      // anything other than the plain code expression editor.
      const canEditExpression = isLiteral || parameter.interface instanceof PISelect;
      const editExpression = () => {
        isEditingExpression = true;
      };

      const canRevertToDefault = isOverridden && !stack.isImmutable;
      const revertToDefault = () => stack.revertToDefault();

      const canSetAsDefault =
        !stack.isMixed && isOverridden && isEditingDefinition && !isDefinitionImmutable && !isEmbed;
      const setAsDefault = () => {
        assert(stack.heroSelectable instanceof SelectableParameter);
        stack.heroSelectable.setAsDefault();
        stack.heroSelectable.revertToDefault();
      };

      const canHideParameter =
        !stack.isMixed && isEditingDefinition && !isDefinitionImmutable && !isEmbed;
      const setParameterHidden = (hidden: boolean) => {
        parameter.hidden = hidden;
      };

      const canRemoveParameter =
        !stack.isMixed && isEditingDefinition && !isDefinitionImmutable && !isEmbed;
      const removeParameter = () => {
        globalState.project.removeDefinitionParameter(definition, parameter);
      };

      const canRenameParameter =
        !stack.isMixed && isEditingDefinition && !isDefinitionImmutable && !isEmbed;
      const renameParameter = (newName: string) => {
        globalState.project.renameDefinitionParameter(definition, parameter, newName);
      };

      const canStartReorder =
        !stack.isMixed && isEditingDefinition && !isDefinitionImmutable && !isEmbed;
      const startReorder = (event: PointerEvent) => {
        startDrag(event, {
          cursor() {
            return "grabbing";
          },
          onConsummate() {
            globalState.parameterReorder = { definition, parameter };
          },
          onUp() {
            const { parameterReorder } = globalState;
            if (parameterReorder) {
              let newIndex = parameterReorder.hoveredInsertionIndex;
              if (newIndex !== undefined) {
                const oldIndex = siblingParameters.indexOf(parameter);
                siblingParameters.splice(oldIndex, 1);

                const newParent = parameterReorder.hoveredInsertionParent ?? definition;

                // If we're removing the parameter from a previous position in
                // the same array, we need to decrement the insertion index by 1
                // to account for the removed parameter.
                if (siblingParameters === newParent.parameters && oldIndex < newIndex)
                  newIndex -= 1;

                newParent.parameters.splice(newIndex, 0, parameter);
              }
            }
            globalState.parameterReorder = null;
          },
          onCancel() {
            selectParameter(event);
          },
        });
      };

      const canSelectParameter = !isEmbed;
      const selectParameter = (event: PointerEvent) => {
        if (event.shiftKey) {
          globalState.project.toggleSelectItems(selectables);
        } else {
          globalState.project.selectItems(selectables);
        }
      };

      const canEditComment = !isDefinitionImmutable && isEditingDefinition && !isEmbed;
      const canAddComment = canEditComment && parameter.comment === undefined;
      const addComment = () => {
        parameter.comment = "";
        globalState.autoSelectForRename = new AutoSelectComment(parameter);
      };
      const canRemoveComment = canEditComment && parameter.comment !== undefined;
      const removeComment = () => {
        parameter.comment = undefined;
      };

      const canChangeInterface = !isDefinitionImmutable && isEditingDefinition && !isEmbed;

      const className = classNames({
        overridden: isOverridden,
        hidden: parameter.hidden || isDerived,
        multiline: stack.isMultiline,
        longname: isLongName,
        selectable: true,
        selected: isSelected,
        hovered: isHovered,
      });

      const menuItems: MenuItem[] = [];
      let parameterNameAttrs: m.Attributes = {};

      if (!isEmbed) {
        const onParameterPointerDown = (event: PointerEvent) => {
          if (isPointerEventRightClick(event)) {
            if (menuItems.length > 0) {
              createRightClickPopupMenu(event, menuItems);
            }
          } else {
            if (canStartReorder) {
              startReorder(event);
            } else if (canSelectParameter) {
              selectParameter(event);
            }
          }
        };
        const onParameterPointerEnter = (event: PointerEvent) => {
          if (event.buttons !== POINTER_EVENT_BUTTONS_NONE) return;
          if (globalState.project.selectableExistsAndIsValid(stack.heroSelectable)) {
            globalState.project.hoveredItem = stack.heroSelectable;
          }
        };
        const onParameterPointerLeave = (event: PointerEvent) => {
          globalState.project.hoveredItem = null;
        };
        parameterNameAttrs = {
          onpointerdown: onParameterPointerDown,
          onpointerenter: onParameterPointerEnter,
          onpointerleave: onParameterPointerLeave,
        };
      }

      let mName: m.Child;
      {
        let mInnerName: m.Children = parameter.name;
        if (canRenameParameter) {
          const autoSelect = globalState.autoSelectForRename === parameter;
          if (autoSelect) {
            globalState.autoSelectForRename = undefined;
          }
          mInnerName = m(EditableText, {
            value: parameter.name,
            onchange: renameParameter,
            autoSelect,
          });
        }
        mName = m(".parameter-name", parameterNameAttrs, mInnerName);
      }

      let mValue: m.Child;
      {
        let mMixedButton: m.Children;
        if (stack.isMixed) {
          const onMixedClick = (e: MouseEvent) => {
            e.stopPropagation(); // Don't click into expression editor
            stack.setEditingExpressionCode(stack.heroSelectable.expression().jsCode);
            stack.commitEditingExpression();
          };
          mMixedButton = m(
            Tooltipped,
            {
              message: () => [
                `Mixed ${name} value.`,
                m("br"),
                `Click to set the first selected ${name} value on all selected items.`,
              ],
            },
            m("button.mixed-hint", { onclick: onMixedClick }, "Mixed ×")
          );
        }

        const expressionTrace = stack.heroSelectable.expressionTrace();

        const unit = expression.literalUnit();
        const projectUnit = globalState.project.settings.units;
        const showResultUnits = unit && unit !== projectUnit;
        const showResult = !isLiteral || showResultUnits;

        let mResult: m.Child;
        if (showResult) {
          mResult = m(EvaluationResult, {
            expressionTrace,
            units: showResultUnits ? projectUnit : undefined,
            selectable: stack.heroSelectable,
          });
        }

        mValue = m(".parameter-value", [
          m(ParameterInput, {
            expression,
            expressionTrace,
            parameterInterface: parameter.interface,
            isImmutable: stack.isImmutable,
            isEditingExpression,
            onChangeCode,
            onCommitCode,
            onExpressionEditorBlur,
            mixedHint: mMixedButton,
          }),
          mResult,
        ]);
      }

      const editPISelectOptions = () => {
        assert(parameter.interface instanceof PISelect);
        modalState.open({
          modalView: () =>
            m(SelectOptionsModal, {
              parameter,
              onChange: (options) => {
                // If the current option is not in the new options, set it to the first option.
                if (!isLiteral) return;
                const currentOption = expression.literalValue();
                if (!isString(currentOption)) return;
                const currentOptionExists = options.some((o) => o.value === currentOption);
                if (!currentOptionExists) {
                  onChangeCode(expressionCodeForString(options[0].value));
                }
              },
            }),
        });
      };

      let mExtraChildren: m.Children = [];
      if (isEmbed) {
        if (isOverridden) {
          mExtraChildren.push(
            m(
              Tooltipped,
              { message: () => "Revert parameter to default." },
              m(IconButton, {
                icon: "revert",
                onpointerdown: revertToDefault,
              })
            )
          );
        }
      } else {
        if (canEditExpression) {
          menuItems.push({ label: "Edit Expression", action: editExpression });
        }
        if (canRevertToDefault || canSetAsDefault) {
          if (menuItems.length) menuItems.push({ type: "separator" });
          if (canRevertToDefault) {
            menuItems.push({ label: "Revert to Default Value", action: revertToDefault });
          }
          if (canSetAsDefault) {
            menuItems.push({ label: "Set as Default Value", action: setAsDefault });
          }
        }
        if (canAddComment || canRemoveComment) {
          if (menuItems.length) menuItems.push({ type: "separator" });
          if (canAddComment) {
            menuItems.push({ label: "Add Comment", action: addComment });
          }
          if (canRemoveComment) {
            menuItems.push({ label: "Delete Comment", action: removeComment });
          }
        }
        if (canChangeInterface) {
          if (menuItems.length) menuItems.push({ type: "separator" });
          menuItems.push(parameterInterfaceMenuItem(parameter, editPISelectOptions));
          if (parameter.interface instanceof PISelect) {
            menuItems.push({
              label: "Edit Select Options…",
              action: editPISelectOptions,
            });
          }
          if (canChangeInterface && parameter.interface instanceof PIFontSelect) {
            const isRecommendedEnabled = () => {
              if (!(parameter.interface instanceof PIFontSelect)) return false;
              if (!parameter.expression.isLiteral()) return false;
              const value = parameter.expression.literalValue();
              if (value && isString(value)) return true;
              return false;
            };
            const isSelectedFontRecommended = () => {
              if (!(parameter.interface instanceof PIFontSelect)) return false;
              if (!parameter.expression.isLiteral()) return false;
              const value = parameter.expression.literalValue();
              if (!value || !isString(value)) return false;
              return isFontFileNameInFontURLArray(value, parameter.interface.recommendedValues);
            };
            const toggleFontRecommended = () => {
              if (!(parameter.interface instanceof PIFontSelect)) return;

              const value = parameter.expression.literalValue();
              if (!value || !isString(value)) return;

              const alreadyExists = isFontFileNameInFontURLArray(
                value,
                parameter.interface.recommendedValues
              );
              const newRecommended = alreadyExists
                ? filterFontFileNameFromFontURLArray(value, parameter.interface.recommendedValues)
                : [...parameter.interface.recommendedValues, value];
              parameter.interface = new PIFontSelect(newRecommended);
            };
            menuItems.push({
              label: isSelectedFontRecommended()
                ? "Remove Font From Recommended"
                : "Add Font to Recommended",
              action: toggleFontRecommended,
              enabled: isRecommendedEnabled,
            });
          }
        }
        if (canHideParameter) {
          if (menuItems.length) menuItems.push({ type: "separator" });
          menuItems.push({
            label: "Hide Parameter",
            icon: () => (parameter.hidden || isDerived ? m(Icon20, { icon: "check" }) : undefined),
            action: () => setParameterHidden(!parameter.hidden),
            enabled: () => !isDerived,
            tooltip: isDerived
              ? () => "This parameter is hidden because it references another parameter"
              : undefined,
          });
        }
        if (canExtractAsParameter || canMoveParameterToProject || canRemoveParameter) {
          if (menuItems.length) menuItems.push({ type: "separator" });
          if (canExtractAsParameter) {
            menuItems.push({ label: "Extract as Parameter", action: extractAsParameter });
          }
          if (canMoveParameterToProject) {
            menuItems.push({ label: "Move Parameter to Project", action: moveParameterToProject });
          }
          if (canRemoveParameter) {
            menuItems.push({ label: "Delete Parameter", action: removeParameter });
          }
        }
      }

      if (menuItems.length > 0) {
        const onpointerdown = () => {
          createPopupMenu({
            menuItems,
            spawnFrom: domForVnode(vnode),
            placement: "top-end",
          });
        };
        mExtraChildren.push(
          m(IconButton, {
            icon: "dotdotdot",
            className: "extra-hidden",
            onpointerdown,
          })
        );
      }

      const mExtra = m(".extra", mExtraChildren);

      let mMetadata: m.Children;
      if (parameter.comment !== undefined) {
        let mCommentValue: m.Children;
        if (canEditComment) {
          mCommentValue = m(DocEditorText, {
            value: parameter.comment ?? "",
            placeholder: "Write a note about your parameter…",
            editable: canEditComment,
            onchange: (newValue) => (parameter.comment = newValue),
            autofocus:
              globalState.autoSelectForRename instanceof AutoSelectComment &&
              globalState.autoSelectForRename.subject === parameter,
          });
        } else if (parameter.comment !== "") {
          mCommentValue = parameter.comment.split("\n").map((line) => m("p", line));
        }
        if (mCommentValue) {
          mMetadata = m(".inspector-row.comment", mCommentValue);
        }
      }

      return [m(".inspector-row.parameter", { className }, [mName, mValue, mExtra]), mMetadata];
    },
  };
};

const parameterInterfaceMenuItem = (
  parameter: Parameter,
  editPISelectOptions: () => void
): MenuItem => {
  const checkIcon = (condition: boolean) => {
    return () => {
      if (condition) return m(Icon20, { icon: "check" });
    };
  };

  // Note: when we select a parameter type (Boolean), we check if the
  // parameter's current value is a valid value of that type (`true` or
  // `false`). If not we set it to be a default value (`false`).
  //
  // This logic would perhaps be better to live on the ParameterInterface class
  // rather than in the view code here.

  const submenu = (): MenuItem[] => {
    return [
      {
        label: "Auto",
        icon: checkIcon(parameter.interface === undefined),
        action: () => (parameter.interface = undefined),
      },
      { type: "separator" },
      {
        label: "Boolean",
        icon: checkIcon(parameter.interface instanceof PIBoolean),
        action: () => {
          parameter.interface = new PIBoolean();
          const literal = parseLiteral(parameter.expression.jsCode);
          if (!literal || literal.type !== "Boolean") {
            parameter.expression.jsCode = `false`;
          }
        },
      },
      {
        label: "Number",
        icon: checkIcon(parameter.interface instanceof PIScalar),
        action: () => {
          parameter.interface = new PIScalar();
          const literal = parseLiteral(parameter.expression.jsCode);
          if (!literal || literal.type !== "Number") {
            parameter.expression.jsCode = `0.00`;
          }
        },
      },
      {
        label: "Percent",
        icon: checkIcon(parameter.interface instanceof PIPercentage),
        action: () => {
          parameter.interface = new PIPercentage();
          const literal = parseLiteral(parameter.expression.jsCode);
          if (literal && literal.type === "Number") {
            parameter.expression.jsCode = literal.string; // Strip any unit in the original expression.
          } else {
            parameter.expression.jsCode = `0.00`;
          }
        },
      },
      {
        label: "Angle",
        icon: checkIcon(parameter.interface instanceof PIAngle),
        action: () => {
          parameter.interface = new PIAngle();
          const literal = parseLiteral(parameter.expression.jsCode);
          if (!literal || literal.type !== "Number") {
            parameter.expression.jsCode = `0`;
          }
        },
      },
      {
        label: ["Distance", m(".feature-tag", "ADMIN")],
        icon: checkIcon(parameter.interface instanceof PIDistance),
        action: () => {
          parameter.interface = new PIDistance();
          const literal = parseLiteral(parameter.expression.jsCode);
          if (!literal || literal.type !== "Number") {
            parameter.expression.jsCode = `0.00`;
          }
        },
        visible: showAdminFeatures,
      },
      {
        label: "Point",
        icon: checkIcon(parameter.interface instanceof PIPoint),
        action: () => {
          parameter.interface = new PIPoint();
          const literal = parseLiteral(parameter.expression.jsCode);
          if (!literal || literal.type !== "Vec") {
            parameter.expression.jsCode = `Vec(0.00, 0.00)`;
          }
        },
      },
      {
        label: ["Vector", m(".feature-tag", "ADMIN")],
        icon: checkIcon(parameter.interface instanceof PIVector),
        action: () => {
          const originParameterName = window.prompt(
            "Vector parameters move relative to a Point parameter. What is the origin Point parameter name? For example, p1.",
            parameter.interface instanceof PIVector ? parameter.interface.originParameterName : ""
          );
          if (!originParameterName || originParameterName === "") {
            return;
          }
          parameter.interface = new PIVector(originParameterName);
          const literal = parseLiteral(parameter.expression.jsCode);
          if (!literal || literal.type !== "Vec") {
            parameter.expression.jsCode = `Vec(0.00, 0.00)`;
          }
        },
        visible: showAdminFeatures,
      },
      {
        label: "Color",
        icon: checkIcon(parameter.interface instanceof PIColor),
        action: () => {
          parameter.interface = new PIColor();
          const literal = parseLiteral(parameter.expression.jsCode);
          if (literal) {
            if (literal.type === "Color") return;
            if (literal.type === "String" && literal.value === "none") return;
          }
          parameter.expression.jsCode = `Color(0.000, 0.000, 0.000, 1.000)`;
        },
      },
      {
        label: "Text",
        icon: checkIcon(parameter.interface instanceof PIText),
        action: () => {
          parameter.interface = new PIText();
          const literal = parseLiteral(parameter.expression.jsCode);
          if (literal && literal.type === "RichText") {
            return;
          } else if (literal && literal.type === "String") {
            parameter.expression.jsCode = `RichText(${JSON.stringify(literal.value)})`;
          } else {
            parameter.expression.jsCode = `RichText("Aa")`;
          }
        },
      },
      {
        label: "Font",
        icon: checkIcon(parameter.interface instanceof PIFontSelect),
        action: () => {
          parameter.interface = new PIFontSelect();
          if (!parameter.expression.jsCode.startsWith(`"http`)) {
            parameter.expression.jsCode = `"https://fonts.gstatic.com/s/notosans/v26/o-0IIpQlx3QUlC5A4PNb4j5Ba_2c7A.ttf"`;
          }
        },
      },
      {
        label: "Emoji",
        icon: checkIcon(parameter.interface instanceof PIEmoji),
        action: () => {
          parameter.interface = new PIEmoji();
          if (!parameter.expression.jsCode.startsWith(`"https`)) {
            parameter.expression.jsCode = `"${canonicalAssetsOrigin}/noto-emoji-600/1f642.svg"`;
          }
        },
      },
      {
        label: "Image",
        icon: checkIcon(parameter.interface instanceof PIImage),
        action: () => {
          parameter.interface = new PIImage();
          if (!parameter.expression.jsCode.startsWith(`"https`)) {
            parameter.expression.jsCode = `""`;
          }
        },
      },
      {
        label: "Select",
        icon: checkIcon(parameter.interface instanceof PISelect),
        action: () => {
          if (parameter.interface instanceof PISelect) {
            editPISelectOptions();
            return;
          }
          const options: PISelectOption[] = [];
          const literal = parseLiteral(parameter.expression.jsCode);
          if (!literal || literal.type !== "String") {
            options.push({ label: "first", value: "first" }, { label: "second", value: "second" });
            parameter.expression.jsCode = `"first"`;
          } else {
            options.push({ label: literal.value, value: literal.value });
          }
          parameter.interface = new PISelect(options);
          editPISelectOptions();
        },
      },
    ];
  };

  return {
    label: "Parameter Type",
    submenu,
  };
};
