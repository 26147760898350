import m from "mithril";

import { globalState } from "../../global-state";
import { InstanceDefinition } from "../../model/instance-definition";
import { ParameterFolder } from "../../model/parameter";
import { classNames } from "../../shared/util";

interface ParameterReorderHitTargetAttrs {
  definition: InstanceDefinition;
  insertionIndex: number;
  insertionParent?: ParameterFolder;
}
export const ParameterReorderHitTarget: m.Component<ParameterReorderHitTargetAttrs> = {
  view({ attrs: { definition, insertionIndex, insertionParent } }) {
    if (!globalState.parameterReorder) return null;
    if (definition !== globalState.parameterReorder.definition) return null;

    const onpointerenter = () => {
      if (!globalState.parameterReorder) return;
      globalState.parameterReorder.hoveredInsertionIndex = insertionIndex;
      globalState.parameterReorder.hoveredInsertionParent = insertionParent;
    };
    const onpointerleave = () => {
      if (!globalState.parameterReorder) return;
      globalState.parameterReorder.hoveredInsertionIndex = undefined;
      globalState.parameterReorder.hoveredInsertionParent = undefined;
    };
    const { parameterReorder } = globalState;
    const className = classNames({
      hovered:
        parameterReorder &&
        parameterReorder.hoveredInsertionParent === insertionParent &&
        parameterReorder.hoveredInsertionIndex === insertionIndex,
    });
    return m(".parameter-reorder-hit-target", { className, onpointerenter, onpointerleave });
  },
};

interface ParameterFolderReorderHitTargetAttrs {
  definition: InstanceDefinition;
  insertionIndex: number;
}
export const ParameterFolderReorderHitTarget: m.Component<ParameterFolderReorderHitTargetAttrs> = {
  view({ attrs: { definition, insertionIndex } }) {
    if (!globalState.parameterFolderReorder) return null;
    if (definition !== globalState.parameterFolderReorder.definition) return null;

    const onpointerenter = () => {
      if (!globalState.parameterFolderReorder) return;
      globalState.parameterFolderReorder.hoveredInsertionIndex = insertionIndex;
    };
    const onpointerleave = () => {
      if (!globalState.parameterFolderReorder) return;
      globalState.parameterFolderReorder.hoveredInsertionIndex = undefined;
    };
    const { parameterFolderReorder } = globalState;
    const className = classNames({
      hovered:
        parameterFolderReorder && parameterFolderReorder.hoveredInsertionIndex === insertionIndex,
    });
    return m(".parameter-reorder-hit-target", { className, onpointerenter, onpointerleave });
  },
};
