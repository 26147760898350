import { Graphic, Group, Stroke, Vec } from "../geom";
import { RasterizeCanvasTooLargeError } from "../geom/io/canvas";
import { globalState } from "../global-state";
import { downloadFileWithExportOptions } from "../io/export";
import { ExportOptions, exportSVGOptionsForPrint } from "../io/export-options";
import { PDFBlobFromSVGString, printPDFBlobUsingIframe } from "../io/export-pdf";
import { PNGBlobFromFramedGraphic } from "../io/export-png";
import { SVGStringFromFramedGraphic, bareSVGStringFromFramedGraphic } from "../io/export-svg";
import {
  framedGraphicFitToSize,
  normalizedFramedGraphicForExport,
  tightFramedGraphic,
  usLetterFramedGraphic,
} from "../io/framed-graphic";
import { CodeComponent, Component } from "../model/component";
import { logEvent } from "../shared/log-event";
import { PortableProjectData, PortableProjectDataSnapshot } from "../model/snapshot";
import { checkUserCanExport } from "./export-check";
import { showRasterizeCanvasTooLargeToast } from "./toast-messages";

export const downloadFileWithExportOptionsAndErrorToast = async (
  exportOptions: ExportOptions[]
) => {
  try {
    await downloadFileWithExportOptions(exportOptions);
  } catch (error) {
    if (error instanceof RasterizeCanvasTooLargeError) {
      showRasterizeCanvasTooLargeToast();
    }
  }
};

export const printFocusedComponent = () => {
  const component = globalState.project.focusedComponent();
  if (!component) return;
  printComponent(component);
};

export const printComponent = async (component: Component | CodeComponent) => {
  if (!(await checkUserCanExport())) return;

  const trace = globalState.traceForComponent(component);
  if (!trace?.isSuccess()) return;

  const { settings } = globalState.project;
  const framedGraphic = usLetterFramedGraphic(trace.result, settings.units);
  if (!framedGraphic) return;

  const framedGraphicPoints = normalizedFramedGraphicForExport(framedGraphic, "pt");

  try {
    // NOTE: We need the bare SVG string without the wrapping `<svg>...</svg>`.
    const options = exportSVGOptionsForPrint(settings);
    const svgString = bareSVGStringFromFramedGraphic(framedGraphicPoints, options);
    const pdfBlob = await PDFBlobFromSVGString(svgString, framedGraphicPoints.artboard.size());
    printPDFBlobUsingIframe(pdfBlob);

    logEvent("project printed", { component: component.name });
  } catch (error) {
    if (error instanceof RasterizeCanvasTooLargeError) {
      showRasterizeCanvasTooLargeToast();
    }
  }
};

export const previewPNGForMainComponent = async () => {
  const component = globalState.project.mainComponent();

  const trace = globalState.traceForComponent(component);
  if (!trace?.result) return undefined;

  const artboardSize = new Vec(800, 450);

  const framedGraphic = framedGraphicFitToSize(trace.result, artboardSize, "px");
  if (component instanceof CodeComponent && !framedGraphic.graphic.hasStyle()) {
    framedGraphic.graphic.assignStroke(new Stroke());
  }

  return PNGBlobFromFramedGraphic(framedGraphic, {
    backgroundColor: "#ffffff",
    hairlineStrokeWidthPixels: 2,
  });
};

export const svgStringFromPortableProjectData = (portableProjectData: PortableProjectData) => {
  const items: Graphic[] = [];
  const focusedComponent = globalState.project.focusedComponent();
  if (focusedComponent) {
    // Path elements for any selected segments will not be added to the focused
    // component, and thus won't be evaluated. We need to perform an ad-hoc
    // evalutation of the selection to get geometry for these elements.
    const elementTraces = globalState.evaluator.evaluateElementsWithinFocusedComponent(
      globalState.project,
      globalState.project
        .selectionElements()
        .filter((element) => element.isVisible && element.guidesDisplay !== "show-all-as-guides")
    );
    for (let trace of elementTraces) {
      if (trace.isSuccess()) {
        items.push(trace.result);
      }
    }
  }
  const group = new Group(items);

  const { settings } = globalState.project;
  const framedGraphic = tightFramedGraphic(group, settings.units);

  const options = exportSVGOptionsForPrint(settings);
  options.comment =
    PortableProjectDataSnapshot.fromPortableProjectData(portableProjectData).toString();

  const framedGraphicPoints = normalizedFramedGraphicForExport(framedGraphic, "pt");
  return SVGStringFromFramedGraphic(framedGraphicPoints, options);
};
