import m from "mithril";

import { Vec } from "../geom/math/vec";
import { isMetricUnit, scaleFactorForUnitConversion, Unit } from "../geom/io/units";
import { globalState } from "../global-state";
import { RectWatcher } from "./rect-watcher";
import { EditorModal } from "./modal";

const CREDIT_CARD_INCHES = new Vec(3.37, 2.125);
const INPUT_ATTRS = { min: 64, max: 210, step: 1 };
const HIGH_DEFAULT = 140;
const STANDARD_DEFAULT = 110;

interface CalibrateRealSizeAttrs {
  onClose: () => void;
}
export const CalibrateRealSize: m.ClosureComponent<CalibrateRealSizeAttrs> = () => {
  const devicePixelRatio = window.devicePixelRatio || 1;

  const currentProjectUnits = globalState.project.settings.units;

  let previewPPI =
    globalState.deviceStorage.currentScreenPPI() ||
    (devicePixelRatio > 1 ? HIGH_DEFAULT : STANDARD_DEFAULT);
  let previewPPIString = previewPPI.toString();

  const onChangeInput = (e: InputEvent) => {
    // Need to keep track of the string so that folks can type "298.0" without losing the ".0"
    if (e.currentTarget instanceof HTMLInputElement) {
      previewPPIString = e.currentTarget.value;
      previewPPI = parseFloat(previewPPIString);
    }
  };

  const apply = () => {
    globalState.deviceStorage.setCurrentScreenPPI(previewPPI);
    globalState.zoomViewportToPixelsPerInch(previewPPI);
  };

  return {
    view({ attrs: { onClose } }) {
      return m(
        EditorModal,
        {
          title: "Calibrate This Screen",
          actions: [
            {
              label: "Calibrate Screen",
              action: apply,
            },
          ],
          onClose,
        },
        [
          m(".calibrate-real-size", [
            m("p", ["Adjust the slider until the image matches a standard credit card."]),
            m(".calibrate-real-size-controls", [
              m("input.input-range", {
                type: "range",
                ...INPUT_ATTRS,
                value: previewPPIString,
                oninput: onChangeInput,
              }),
              m("br"),
              m("input.input-number", {
                type: "number",
                ...INPUT_ATTRS,
                value: previewPPIString,
                oninput: onChangeInput,
              }),
              " Pixels Per Inch",
            ]),
          ]),
          m(CalibrationPreview, { ppi: previewPPI, currentProjectUnits }),
        ]
      );
    },
  };
};

interface CalibrationPreviewAttrs {
  ppi: number;
  currentProjectUnits: Unit;
}
const CalibrationPreview: m.Component<CalibrationPreviewAttrs> = {
  view({ attrs: { ppi, currentProjectUnits } }) {
    return m(RectWatcher, {
      className: "calibrate-real-size-preview",
      view(rect: DOMRect) {
        const origin = new Vec(rect.width, rect.height).mulScalar(1 / 2);
        const size = CREDIT_CARD_INCHES.clone().mulScalar(ppi);
        const position = origin.clone().sub(size.clone().mulScalar(1 / 2));

        const isMetric = isMetricUnit(currentProjectUnits);
        const rulerUnit = isMetric ? "cm" : "in";
        const scaleFactor = scaleFactorForUnitConversion(rulerUnit, "in");

        // 1 cm or 1/2 inch
        const rulerWidth = isMetric ? 1 * ppi * scaleFactor : (1 / 2) * ppi * scaleFactor;
        const rulerSpacing = rulerWidth / 2;

        const tickMarkPx = rulerWidth + rulerSpacing;

        return [
          m(CreditCard, { size, position }),
          m("svg", { width: rect.width, height: rect.height }, [
            m(RulersXY, {
              rect,
              size,
              position,
              ppi,
              rulerWidth,
              rulerSpacing,
              isMetric,
            }),
            m(TickMarks, { size, position, tickMarkPx }),
          ]),
        ];
      },
    });
  },
};

interface CreditCardAttrs {
  size: Vec;
  position: Vec;
}
const CreditCard: m.Component<CreditCardAttrs> = {
  view({ attrs: { size, position } }) {
    // Cuttle source: https://cuttle.xyz/@forresto/cuttle-credit-card-YtNuPZk0sOyV
    return m("img", {
      src: "/editor/images/screen-calibration-credit-card.svg",
      width: size.x,
      height: size.y,
      style: { position: "absolute", top: position.y + "px", left: position.x + "px" },
    });
  },
};

interface TickMarksAttrs {
  size: Vec;
  position: Vec;
  tickMarkPx: number;
}
const TickMarks: m.Component<TickMarksAttrs> = {
  view({ attrs: { size, position, tickMarkPx } }) {
    const otherCorner = position.clone().add(size);
    // Cuttle source: https://cuttle.xyz/@forresto/cuttle-credit-card-YtNuPZk0sOyV
    return m("g.tick-marks", [
      m("line", {
        x1: position.x - tickMarkPx,
        y1: position.y,
        x2: otherCorner.x + tickMarkPx,
        y2: position.y,
      }),
      m("line", {
        x1: position.x - tickMarkPx,
        y1: otherCorner.y,
        x2: otherCorner.x + tickMarkPx,
        y2: otherCorner.y,
      }),
      m("line", {
        x1: position.x,
        y1: position.y - tickMarkPx,
        x2: position.x,
        y2: otherCorner.y + tickMarkPx,
      }),
      m("line", {
        x1: otherCorner.x,
        y1: position.y - tickMarkPx,
        x2: otherCorner.x,
        y2: otherCorner.y + tickMarkPx,
      }),
    ]);
  },
};

interface RulersXYAttrs {
  rect: DOMRect;
  size: Vec;
  position: Vec;
  ppi: number;
  isMetric: boolean;
  rulerWidth: number;
  rulerSpacing: number;
}
const RulersXY: m.Component<RulersXYAttrs> = {
  view({ attrs: { rect, size, position, ppi, isMetric, rulerWidth, rulerSpacing } }) {
    return m("g.rulers-xy", [
      m(Ruler, {
        key: "y",
        size: new Vec(rect.height, rulerWidth),
        originX: position.y,
        ppi,
        isMetric,
        isVertical: true,
        transform: `translate(${position.x - rulerWidth - rulerSpacing} ${
          rect.height
        }) rotate(-90)`,
      }),
      m(Ruler, {
        key: "x",
        size: new Vec(rect.width, rulerWidth),
        originX: position.x,
        ppi,
        isMetric,
        isVertical: false,
        transform: `translate(${0} ${position.y + size.y + rulerSpacing})`,
      }),
    ]);
  },
};

const labelMax = (isVertical: boolean, isMetric: boolean): number => {
  if (isVertical) {
    return isMetric ? 5 : 2;
  }
  return isMetric ? 8 : 3;
};

interface RulerAttrs {
  key: string;
  size: Vec;
  originX: number;
  ppi: number;
  isMetric: boolean;
  isVertical: boolean;
  transform?: string;
}
const Ruler: m.Component<RulerAttrs> = {
  view({ attrs: { key, size, originX, ppi, isMetric, isVertical, transform } }) {
    return m("g.ruler", { transform }, [
      m("rect.ruler-bg", {
        key: "bg",
        width: size.x,
        height: size.y,
        x: 0,
        y: 0,
      }),
      m(RulerMarks, {
        key: `${key}-${isMetric ? "cm" : "in"}`,
        size,
        originX,
        ppi,
        isMetric,
        isSecondary: false,
        labelMax: labelMax(isVertical, isMetric),
      }),
      m(RulerMarks, {
        key: `${key}-${isMetric ? "in" : "cm"}`,
        size,
        originX,
        ppi,
        isMetric: !isMetric,
        isSecondary: true,
        labelMax: labelMax(isVertical, !isMetric),
      }),
    ]);
  },
};

interface RulerMarksAttrs {
  key: string;
  size: Vec;
  originX: number;
  ppi: number;
  isMetric: boolean;
  isSecondary: boolean;
  labelMax: number;
}
const RulerMarks: m.Component<RulerMarksAttrs> = {
  view({ attrs: { key, size, originX, ppi, isMetric, isSecondary, labelMax } }) {
    const rulerUnit = isMetric ? "cm" : "in";
    const majorLinePx = ppi * scaleFactorForUnitConversion(rulerUnit, "in");
    // 1 mm or 1/8 inch
    const minorLinePx = isMetric ? majorLinePx / 10 : majorLinePx / 8;
    const fontSizePx = size.y / 4;

    const labelsHorizontal: m.Children = [];
    for (let i = 0; i <= labelMax; i++) {
      labelsHorizontal.push(
        m(
          "text",
          { x: majorLinePx * i, y: 0, "font-size": fontSizePx + "px" },
          i === 0 ? rulerUnit : i
        )
      );
    }

    return m("g.ruler-marks", [
      m("defs", [
        m(
          "pattern",
          {
            id: "calibrate-ruler-pattern-minor-" + key,
            x: 0,
            y: 0,
            width: minorLinePx,
            height: size.y,
            patternUnits: "userSpaceOnUse",
          },
          [
            m(
              "line.minor",
              isSecondary
                ? { x1: 0, y1: size.y * (7 / 8), x2: 0, y2: size.y }
                : { x1: 0, y1: 0, x2: 0, y2: size.y / 8 }
            ),
          ]
        ),
        m(
          "pattern",
          {
            id: "calibrate-ruler-pattern-" + key,
            x: originX,
            y: 0,
            width: majorLinePx,
            height: size.y,
            patternUnits: "userSpaceOnUse",
          },
          [
            m("rect", {
              x: 0,
              y: 0,
              width: majorLinePx,
              height: size.y,
              fill: `url(#calibrate-ruler-pattern-minor-${key})`,
            }),
            m(
              "line.major",
              isSecondary
                ? { x1: 0, y1: size.y * (3 / 4), x2: 0, y2: size.y }
                : { x1: 0, y1: 0, x2: 0, y2: size.y / 4 }
            ),
          ]
        ),
      ]),
      m("rect.ruler.pattern", {
        width: size.x,
        height: size.y,
        x: 0,
        y: 0,
        fill: `url(#calibrate-ruler-pattern-${key})`,
      }),
      m(
        "g.labels",
        {
          transform: `translate(${originX + 3} ${
            isSecondary ? size.y * (4 / 5) : size.y * (2 / 5)
          })`,
        },
        labelsHorizontal
      ),
    ]);
  },
};
