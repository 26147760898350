import { Color, Vec } from "../geom";
import { sanitizeName } from "../util";
import { Expression } from "./expression";
import {
  expressionCodeForNumber,
  expressionCodeForString,
  expressionCodeForValue,
  expressionCodeForVec,
} from "./expression-code";
import { ModelObject } from "./model-object";
import {
  PIAngle,
  PIBoolean,
  PIColor,
  PICount,
  PIDistance,
  PIEmoji,
  PIFontSelect,
  PIImage,
  PIImageTransform,
  PIPercentage,
  PIPoint,
  PIScalar,
  PISelect,
  PISelectOption,
  PISize,
  PIText,
  PIVector,
  ParameterInterface,
} from "./parameter-interface";
import { registerClass } from "./registry";

export class Parameter extends ModelObject {
  name: string;
  expression: Expression;

  // The default expression is set when a project is switched to viewing mode.
  // The viewer can make changes, but these changes will be reverted when
  // switching back to editing mode.
  defaultExpression?: Expression;

  // Describes the parameter in the inspector.
  comment?: string;
  hidden?: boolean; // NAMING: This should be "isHidden"

  interface?: ParameterInterface;

  materialKeys() {
    return ["name", "expression", "comment", "hidden", "interface"];
  }

  constructor(
    name = "",
    jsCode = "",
    parameterInterface?: ParameterInterface,
    comment?: string,
    hidden?: boolean
  ) {
    super();
    this.name = name;
    this.expression = new Expression(jsCode);
    this.interface = parameterInterface;
    this.comment = comment;
    this.hidden = hidden;
  }

  sanitizedName() {
    return sanitizeName(this.name);
  }

  clone() {
    return new Parameter(
      this.name,
      this.expression.jsCode,
      this.interface?.clone(),
      this.comment,
      this.hidden
    );
  }

  isPoint() {
    if (this.interface instanceof PIPoint) return true;
    return (
      this.interface === undefined &&
      this.expression.isLiteral() &&
      this.expression.literalValue() instanceof Vec
    );
  }

  /**
   * Convenience method for builtin definitions, to add a comment to a parameter
   * in a chainable way.
   */
  setComment(comment: string) {
    this.comment = comment;
    return this;
  }

  setDefault() {
    this.defaultExpression = this.expression.clone();
  }
  clearDefault() {
    delete this.defaultExpression;
  }
  revertToDefault() {
    if (this.defaultExpression) {
      this.expression.jsCode = this.defaultExpression.jsCode;
    }
  }
  isOverridden() {
    return Boolean(
      this.defaultExpression && !this.defaultExpression.runsSameCodeAs(this.expression)
    );
  }
}
registerClass("Parameter", Parameter);

export class ParameterFolder extends ModelObject {
  name: string;
  parameters: Parameter[];

  constructor(name: string, parameters: Parameter[] = []) {
    super();
    this.name = name;
    this.parameters = parameters;
  }

  materialKeys(): string[] {
    return ["name", "parameters"];
  }

  clone() {
    return new ParameterFolder(
      this.name,
      this.parameters.map((p) => p.clone())
    );
  }
}
registerClass("ParameterFolder", ParameterFolder);

export const makeSelectParameter = (name: string, defaultValue: string, values: string[]) => {
  return makeSelectParameterWithOptions(
    name,
    defaultValue,
    values.map((s) => {
      return { label: s, value: s };
    })
  );
};
export const makeSelectParameterWithOptions = (
  name: string,
  defaultValue: string,
  options: PISelectOption[]
) => {
  return new Parameter(name, expressionCodeForString(defaultValue), new PISelect(options));
};
export const makeImageTransformParameter = (name: string, defaultValue: string) => {
  return new Parameter(name, expressionCodeForString(defaultValue), new PIImageTransform());
};
export const makeFontSelectParameter = (
  name: string,
  defaultValue: string,
  recommendedValues?: string[]
) => {
  return new Parameter(
    name,
    expressionCodeForString(defaultValue),
    new PIFontSelect(recommendedValues)
  );
};
export const makeEmojiParameter = (name: string, defaultValue: string) => {
  return new Parameter(name, expressionCodeForString(defaultValue), new PIEmoji());
};
export const makeBooleanParameter = (name: string, defaultValue: boolean) => {
  return new Parameter(name, defaultValue.toString(), new PIBoolean());
};
export const makeScalarParameter = (
  name: string,
  defaultValue: number,
  precisionDigits?: number
) => {
  return new Parameter(
    name,
    precisionDigits ? defaultValue.toFixed(precisionDigits) : expressionCodeForNumber(defaultValue),
    new PIScalar(precisionDigits)
  );
};
export const makePercentageParameter = (
  name: string,
  defaultValue: number,
  precisionDigits: number
) => {
  return new Parameter(
    name,
    expressionCodeForNumber(defaultValue, precisionDigits),
    new PIPercentage()
  );
};
export const makeDistanceParameter = (name: string, defaultValue: number) => {
  return new Parameter(name, expressionCodeForNumber(defaultValue), new PIDistance());
};
export const makeAngleParameter = (name: string, defaultValue: number) => {
  return new Parameter(name, expressionCodeForNumber(defaultValue), new PIAngle());
};
export const makeCountParameter = (name: string, defaultValue: number) => {
  return new Parameter(name, expressionCodeForNumber(defaultValue), new PICount());
};
export const makeSizeParameter = (name: string, defaultValue: Vec | number) => {
  const expressionCode =
    defaultValue instanceof Vec
      ? expressionCodeForVec(defaultValue)
      : expressionCodeForNumber(defaultValue);
  return new Parameter(name, expressionCode, new PISize());
};
export const makeVectorParameter = (
  name: string,
  defaultValue: Vec,
  originParameterName?: string
) => {
  return new Parameter(name, expressionCodeForVec(defaultValue), new PIVector(originParameterName));
};
export const makePointParameter = (name: string, defaultValue: Vec) => {
  return new Parameter(name, expressionCodeForVec(defaultValue), new PIPoint());
};
export const makeColorParameter = (name: string, defaultValue: Color | "none") => {
  return new Parameter(name, expressionCodeForValue(defaultValue), new PIColor());
};
export const makeTextParameter = (name: string, defaultValue: string) => {
  return new Parameter(name, `"${defaultValue}"`, new PIText());
};
export const makeImageParameter = (name: string, defaultValue: string) => {
  return new Parameter(name, `"${defaultValue}"`, new PIImage());
};
