import m from "mithril";

import { modalState } from "../shared/modal";
import { classNames } from "../shared/util";

export interface ModalAction {
  label: string;
  action: () => void;
}

interface EditorModalAttrs {
  title?: string;
  actions: ModalAction[];
  onClose?: () => void;
  widthAuto?: boolean;
}
export const EditorModal: m.Component<EditorModalAttrs> = {
  view({ attrs: { title, actions, onClose, widthAuto }, children }) {
    const closeModal = () => {
      onClose?.();
      modalState.close();
    };

    const mActions = actions.map(({ label, action }) => {
      const onclick = () => {
        action();
        closeModal();
      };
      return m("button", { onclick }, label);
    });

    // Modals always have a cancel button
    mActions.unshift(m("button.secondary", { onclick: closeModal }, "Cancel"));

    return [
      m(".editor-modal-box", { className: classNames({ ["width-auto"]: widthAuto }) }, [
        title && m(".editor-modal-title", m("h1", title)),
        m(".editor-modal-contents", children),
        m(".editor-modal-actions", mActions),
      ]),
    ];
  },
};

interface SimpleModalAttrs {
  primaryAction: ModalAction;
  onConfirm?: () => void;
}
export const SimpleModal: m.ClosureComponent<SimpleModalAttrs> = (initialVnode) => {
  const performPrimaryAction = () => {
    const { primaryAction, onConfirm } = initialVnode.attrs;
    primaryAction.action();
    onConfirm?.();
    modalState.close();
  };
  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.stopPropagation();
      performPrimaryAction();
    }
  };
  return {
    oncreate() {
      document.addEventListener("keydown", onKeyDown);
    },
    onremove() {
      document.removeEventListener("keydown", onKeyDown);
    },
    view({ attrs: { primaryAction }, children }) {
      const mActions = [
        m("button", { onclick: performPrimaryAction }, primaryAction.label),
        m("button.secondary", { onclick: () => modalState.dismiss() }, "Cancel"),
      ];

      return [
        m(".simple-modal-box", [
          m(".simple-modal-contents", children),
          m(".simple-modal-actions", mActions),
        ]),
      ];
    },
  };
};
