import m from "mithril";

import { globalState } from "./global-state";
import { downloadFileWithExportOptions } from "./io/export";
import { exportOptionsForComponent } from "./io/export-options";
import { ExportFormat } from "./model/export-format";
import { checkUserCanExport } from "./view/export-check";

/** Console API for Cuttle, for scripting multiple exports. */
export const Cuttle = {
  setComponentParameters: function (params: { [key: string]: string | number }) {
    const currentComponent = globalState.project.focusedComponent();
    if (!currentComponent) {
      console.warn("Must be focused on a component.");
      return;
    }
    for (const key in params) {
      const param = currentComponent.parameterWithName(key);
      if (!param) {
        console.warn("Component parameter not found: " + key);
        continue;
      }
      param.expression.jsCode = params[key] + "";
    }
    // UI updates immediately. Needed before export for now, so that everything
    // reevaluates. We might be able to split that out in the future if we want
    // to export a bunch without redrawing the UI.
    m.redraw.sync();
  },
  setProjectParameters: function (params: { [key: string]: string | number }) {
    for (const key in params) {
      const param = globalState.project.parameterWithName(key);
      if (!param) {
        console.warn("Project parameter not found: " + key);
        continue;
      }
      param.expression.jsCode = params[key] + "";
    }
    m.redraw.sync();
  },
  /** Calling exportX functions in a tight loop only exports the last call. By
   * making these async, we can await them in dev tools scripts.
   * TODO: see if exportX can be properly async and resolve when ready. */
  exportPNG: async function (filename?: string) {
    await exportFocusedComponentNoOptions("png", filename);
    await pause();
  },
  exportSVG: async function (filename?: string) {
    await exportFocusedComponentNoOptions("svg", filename);
    await pause();
  },
  exportPDF: async function (filename?: string) {
    await exportFocusedComponentNoOptions("pdf", filename);
    await pause();
  },
  exportDXF: async function (filename?: string) {
    await exportFocusedComponentNoOptions("dxf", filename);
    await pause();
  },
  help: function () {
    console.log(
      `%cWelcome to Cuttle's _experimental_ console API. 🤫

With this, you can set parameters of the current component or project, and export PNG, SVG, or PDF.

Note that calling setComponentParameters or setProjectParameters will save the parameters to your project.

Calling exportX functions in a tight loop only downloads a file for the last call. By using await, we can make a loop that exports each file. Your browser might ask permission to save multiple files.
Example:`,
      "font-family: sans-serif; font-size: 14px;"
    );
    console.log(`// Chrome seems to do best with saving multiple files
for (let i = 0, frames = 12; i < frames; i++) {
  Cuttle.setComponentParameters({
    time: i/frames
  });
  // exportPNG, exportSVG, and exportPDF take a string to use as the filename
  await Cuttle.exportPNG( "cuttle-" + i.toString().padStart(3, "0") );
}`);
  },
};

function pause() {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, 250);
  });
}

const exportFocusedComponentNoOptions = async (format: ExportFormat, filename?: string) => {
  if (!(await checkUserCanExport())) return;

  const component = globalState.project.focusedComponent();
  if (!component) return;

  const options = exportOptionsForComponent(component, format, filename);
  if (options) {
    downloadFileWithExportOptions([options]);
  }
};
