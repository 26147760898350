import SuperExpressive from "super-expressive";

import { AffineMatrix, Color, RichText, RichTextGlyph, RichTextSymbol, Unit, Vec } from "../geom";
import { isString } from "../shared/util";
import { parse } from "acorn";
import { ACORN_PARSE_OPTIONS } from "./compile-options";
import type * as ESTree from "estree";

// ----------------------------------------------------------------------------
// SuperExpressive expressions for each literal
// ----------------------------------------------------------------------------

// prettier-ignore
export const seBoolean = SuperExpressive()
  .namedCapture("Boolean")
    .anyOf
      .string("true")
      .string("false")
    .end()
  .end();

// prettier-ignore
export const seNumber = SuperExpressive()
  .namedCapture("Number")
    .optional.anyOfChars("-+")
    .anyOf
      .group
        .oneOrMore.digit
        .optional.string(".")
        .zeroOrMore.digit
      .end()
      .group
        .string(".")
        .oneOrMore.digit
      .end()
    .end()
    .optional.group
      .anyOfChars("eE")
      .optional.anyOfChars("-+")
      .oneOrMore.digit
    .end()
  .end();

// prettier-ignore
export const seString = SuperExpressive()
  .namedCapture("String")
    .string(`"`)
      .zeroOrMore.anyOf
        .anythingButChars(`"`)
        .string(`\"`)
      .end()
    .string(`"`)
  .end();

// prettier-ignore
export const seVec = SuperExpressive()
  .namedCapture("Vec")
    .string("Vec(")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "VecX"})
    .zeroOrMore.whitespaceChar
    .string(",")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "VecY"})
    .zeroOrMore.whitespaceChar
    .string(")")
  .end();

// prettier-ignore
export const seAffineMatrix = SuperExpressive()
  .namedCapture("AffineMatrix")
    .string("AffineMatrix(")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "AffineMatrixA"})
    .zeroOrMore.whitespaceChar
    .string(",")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "AffineMatrixB"})
    .zeroOrMore.whitespaceChar
    .string(",")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "AffineMatrixC"})
    .zeroOrMore.whitespaceChar
    .string(",")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "AffineMatrixD"})
    .zeroOrMore.whitespaceChar
    .string(",")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "AffineMatrixTx"})
    .zeroOrMore.whitespaceChar
    .string(",")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "AffineMatrixTy"})
    .zeroOrMore.whitespaceChar
    .string(")")
  .end();

// prettier-ignore
export const seColor = SuperExpressive()
  .namedCapture("Color")
    .string("Color(")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "ColorR"})
    .zeroOrMore.whitespaceChar
    .string(",")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "ColorG"})
    .zeroOrMore.whitespaceChar
    .string(",")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "ColorB"})
    .zeroOrMore.whitespaceChar
    .string(",")
    .zeroOrMore.whitespaceChar
    .subexpression(seNumber, {namespace: "ColorA"})
    .zeroOrMore.whitespaceChar
    .string(")")
  .end();

// prettier-ignore
export const seRichText = SuperExpressive()
.namedCapture("RichText")
  .string(`RichText(`)
    .zeroOrMore.anyChar
  .string(")")
.end();

// prettier-ignore
export const seUnit = SuperExpressive()
  .namedCapture("Unit")
    .anyOf
      .string("in")
      .string("ft")
      .string("mm")
      .string("cm")
      .string("m")
      .string("px")
      .string("pc")
      .string("pt")
    .end()
  .end()

// prettier-ignore
export const seLiteral = SuperExpressive()
  .startOfInput
  .zeroOrMore.whitespaceChar
  .anyOf
    .subexpression(seBoolean)
    .group
      // Number and Vec literals can optionally have units.
      .anyOf
        .subexpression(seNumber)
        .subexpression(seVec)
      .end()
      .zeroOrMore.whitespaceChar
      .optional.subexpression(seUnit)
    .end()
    .subexpression(seString)
    .subexpression(seAffineMatrix)
    .subexpression(seColor)
    .subexpression(seRichText)
  .end()
  .zeroOrMore.whitespaceChar
  .endOfInput;

// ----------------------------------------------------------------------------
// Data structure for returned literals
// ----------------------------------------------------------------------------

export type LiteralBoolean = {
  type: "Boolean";
  value: boolean;
  string: string;
};
export type LiteralNumber = {
  type: "Number";
  value: number;
  string: string;
  unit?: Unit;
};
export type LiteralString = {
  type: "String";
  value: string;
  string: string;
};
export type LiteralRichText = {
  type: "RichText";
  value: RichText;
  string: string;
};
type LiteralRichTextGlyph = {
  type: "RichTextGlyph";
  value: RichTextGlyph;
  string: string;
};
type LiteralRichTextSymbol = {
  type: "RichTextSymbol";
  value: RichTextSymbol;
  string: string;
};
export type LiteralVec = {
  type: "Vec";
  string: string;
  value: Vec;
  x: LiteralNumber;
  y: LiteralNumber;
  unit?: Unit;
};
export type LiteralAffineMatrix = {
  type: "AffineMatrix";
  string: string;
  value: AffineMatrix;
  a: LiteralNumber;
  b: LiteralNumber;
  c: LiteralNumber;
  d: LiteralNumber;
  tx: LiteralNumber;
  ty: LiteralNumber;
};
export type LiteralColor = {
  type: "Color";
  string: string;
  value: Color;
  r: LiteralNumber;
  g: LiteralNumber;
  b: LiteralNumber;
  a: LiteralNumber;
};

export type Literal =
  | LiteralBoolean
  | LiteralNumber
  | LiteralString
  | LiteralRichText
  | LiteralRichTextGlyph
  | LiteralRichTextSymbol
  | LiteralVec
  | LiteralAffineMatrix
  | LiteralColor;

// ----------------------------------------------------------------------------
// Implementation
// ----------------------------------------------------------------------------

const reLiteral = seLiteral.toRegex();

export const parseLiteral = (s: string): Literal | undefined => {
  const result = reLiteral.exec(s);
  if (!result || !result.groups) return undefined;

  let string: string;
  if ((string = result.groups.Number)) {
    const value = +string;
    const unit = result.groups.Unit as Unit | undefined;
    return { type: "Number", value, string, unit };
  } else if ((string = result.groups.Boolean)) {
    const value = string === "true";
    return { type: "Boolean", value, string };
  } else if ((string = result.groups.String)) {
    try {
      // The string should always parse, but let's be extra defensive.
      const value = JSON.parse(string);
      if (isString(value)) {
        return { type: "String", value, string };
      }
    } catch (err) {}
    console.warn("Couldn't parse string literal", string);
  } else if ((string = result.groups.Vec)) {
    const xString = result.groups.VecXNumber;
    const xValue = +xString;
    const yString = result.groups.VecYNumber;
    const yValue = +yString;
    const value = new Vec(xValue, yValue);
    const unit = result.groups.Unit as Unit | undefined;
    return {
      type: "Vec",
      value,
      string,
      x: { type: "Number", value: xValue, string: xString, unit },
      y: { type: "Number", value: yValue, string: yString, unit },
      unit,
    };
  } else if ((string = result.groups.AffineMatrix)) {
    const aString = result.groups.AffineMatrixANumber;
    const aValue = +aString;
    const bString = result.groups.AffineMatrixBNumber;
    const bValue = +bString;
    const cString = result.groups.AffineMatrixCNumber;
    const cValue = +cString;
    const dString = result.groups.AffineMatrixDNumber;
    const dValue = +dString;
    const txString = result.groups.AffineMatrixTxNumber;
    const txValue = +txString;
    const tyString = result.groups.AffineMatrixTyNumber;
    const tyValue = +tyString;
    const value = new AffineMatrix(aValue, bValue, cValue, dValue, txValue, tyValue);
    return {
      type: "AffineMatrix",
      value,
      string,
      a: { type: "Number", value: aValue, string: aString },
      b: { type: "Number", value: bValue, string: bString },
      c: { type: "Number", value: cValue, string: cString },
      d: { type: "Number", value: dValue, string: dString },
      tx: { type: "Number", value: txValue, string: txString },
      ty: { type: "Number", value: tyValue, string: tyString },
    };
  } else if ((string = result.groups.Color)) {
    const rString = result.groups.ColorRNumber;
    const rValue = +rString;
    const gString = result.groups.ColorGNumber;
    const gValue = +gString;
    const bString = result.groups.ColorBNumber;
    const bValue = +bString;
    const aString = result.groups.ColorANumber;
    const aValue = +aString;
    const value = new Color(rValue, gValue, bValue, aValue);
    return {
      type: "Color",
      value,
      string,
      r: { type: "Number", value: rValue, string: rString },
      g: { type: "Number", value: gValue, string: gString },
      b: { type: "Number", value: bValue, string: bString },
      a: { type: "Number", value: aValue, string: aString },
    };
  } else if ((string = result.groups.RichText)) {
    const value = parseLiteralRichText(string);
    if (value) {
      return { type: "RichText", value, string };
    }
  }
};

const isCallExpression = (node: ESTree.Node): node is ESTree.CallExpression => {
  return node.type === "CallExpression";
};

const isCallExpressionWithName = (
  node: ESTree.Node,
  name: string
): node is ESTree.CallExpression => {
  if (node.type !== "CallExpression") return false;
  const callee = node.callee;
  if (callee.type !== "Identifier") return false;
  return callee.name === name;
};

const parseLiteralRichText = (jsCode: string): RichText | undefined => {
  let ast: ESTree.Node;
  try {
    ast = parse(jsCode, ACORN_PARSE_OPTIONS) as ESTree.Node;
  } catch (error) {
    return undefined;
  }

  if (ast.type !== "Program") return undefined;
  if (ast.body.length !== 1) return undefined;
  const expressionStatement = ast.body[0];
  if (expressionStatement.type !== "ExpressionStatement") return undefined;
  const expression = expressionStatement.expression;
  if (!isCallExpressionWithName(expression, "RichText")) return undefined;

  const args = expression.arguments;
  const parsedArgs: (string | RichTextGlyph | RichTextSymbol)[] = [];
  for (let arg of args) {
    if (arg.type === "Literal" && typeof arg.value === "string") {
      parsedArgs.push(arg.value);
    } else if (isCallExpression(arg)) {
      const symbolArgs = arg.arguments;
      if (symbolArgs.length !== 1) return undefined;
      const symbolArg = symbolArgs[0];
      if (isCallExpressionWithName(arg, "RichTextGlyph")) {
        // Until we have editing UI for RichTextGlyph, it should not parse as a literal.
        return undefined;
        // if (symbolArg.type !== "Literal" || typeof symbolArg.value !== "number") return undefined;
        // parsedArgs.push(new RichTextGlyph(symbolArg.value));
      } else if (isCallExpressionWithName(arg, "RichTextSymbol")) {
        if (symbolArg.type !== "Literal" || typeof symbolArg.value !== "string") return undefined;
        parsedArgs.push(new RichTextSymbol(symbolArg.value));
      }
    } else {
      return undefined;
    }
  }
  return new RichText(...parsedArgs);
};

// DEBUG

// console.log(seNumber.toRegexString());
// console.log(seBoolean.toRegexString());
// console.log(seVec.toRegexString());
// console.log(seAffineMatrix.toRegexString());
// console.log(seLiteral.toRegexString());
// console.log(seRichText.toRegexString());
// const w = window as any;

// w.seAffineMatrix = seAffineMatrix;
// w.seVec = seVec;
// w.seNumber = seNumber;
// w.seBoolean = seBoolean;
// w.seLiteral = seLiteral;
// w.parseLiteral = parseLiteral;
