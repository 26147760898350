import { Vec } from "..";

export type Unit = "in" | "ft" | "mm" | "cm" | "m" | "px" | "pc" | "pt";
export type UnitName =
  | "Inches"
  | "Feet"
  | "Millimeters"
  | "Centimeters"
  | "Meters"
  | "Pixels"
  | "Picas"
  | "Points";

export const units: Unit[] = ["in", "ft", "mm", "cm", "m", "px", "pc", "pt"];

export const unitNames: { [U in Unit]: UnitName } = {
  in: "Inches",
  ft: "Feet",
  mm: "Millimeters",
  cm: "Centimeters",
  m: "Meters",
  px: "Pixels",
  pc: "Picas",
  pt: "Points",
};

// Scale factor to convert from given unit to inches.
export const unitScaleFactors: { [U in Unit]: number } = {
  in: 1,
  ft: 12,
  mm: 1 / 25.4,
  cm: 1 / 2.54,
  m: 1000 / 25.4,
  px: 1 / 96,
  pc: 1 / 6,
  pt: 1 / 72,
};

export const isValidUnit = (unit: unknown): unit is Unit => {
  return typeof unit === "string" && unitNames.hasOwnProperty(unit);
};

const METRIC_UNITS = ["mm", "cm", "m"];
export const isMetricUnit = (unit: Unit): boolean => {
  return METRIC_UNITS.includes(unit);
};

/**
 * Returns a scale factor to transform a value in `sourceUnit` to a value in
 * `targetUnit`.
 *
 * @internal
 */
export const scaleFactorForUnitConversion = (sourceUnit: Unit, targetUnit: Unit) => {
  return unitScaleFactors[sourceUnit] / unitScaleFactors[targetUnit];
};

/**
 * Converts a value from one unit to another.
 *
 * @internal
 */
export function valueByUnitConversion<T extends number | Vec>(
  value: T,
  sourceUnit: Unit,
  targetUnit: Unit
): T;
export function valueByUnitConversion(value: number | Vec, sourceUnit: Unit, targetUnit: Unit) {
  if (targetUnit === sourceUnit) {
    return value;
  }
  const scaleFactor = scaleFactorForUnitConversion(sourceUnit, targetUnit);
  if (value instanceof Vec) {
    return new Vec(value.x * scaleFactor, value.y * scaleFactor);
  }
  return value * scaleFactor;
}

export const unitForUnitName = (unitName: string) => {
  unitName = unitName.toLowerCase();
  for (let unit of units) {
    if (unitNames[unit].toLowerCase() === unitName) return unit;
  }
  throw `Invalid unit name: ${unitName}`;
};
