import m from "mithril";
import { modalState } from "../shared/modal";
import { createPopup } from "../shared/popup";
import { globalState } from "../global-state";
import { EditorModal } from "./modal";

export const PointerInitModal: m.Component = {
  view: () => {
    const choosePointer = (style: "pan" | "zoom") => () => {
      globalState.deviceStorage.scrollBehavior = style;
      onClose();
      modalState.close();
    };

    const onClose = () => {
      // TODO: Show mini-tutorial on zoom and pan before this popup
      const viewMenuEl = document.querySelector('[data-automation-id="top-menu-item-view"]');
      if (!viewMenuEl) return;

      createPopup({
        spawnFrom: viewMenuEl as HTMLElement,
        placement: "top-start",
        offset: 4,
        className: "tooltip arrow-top-start",
        overlay: "closeOnOutsidePointerDown",
        view: () =>
          m("div", [
            "You can change trackpad or mouse scroll",
            m("br"),
            "behavior here in the ",
            m("strong", "View"),
            " menu.",
          ]),
      });
    };

    return m(
      EditorModal,
      {
        title: "What are you using now?",
        actions: [],
        onClose,
        widthAuto: true,
      },
      m(".pointer-init-modal-contents", [
        m(".buttons", [
          m("button.secondary", { onclick: choosePointer("pan") }, [
            m("img", {
              alt: "",
              src: "/editor/images/trackpad.svg",
              width: 100,
              height: 100,
            }),
            "Trackpad",
          ]),
          m("button.secondary", { onclick: choosePointer("zoom") }, [
            m("img", {
              alt: "Mouse",
              src: "/editor/images/mouse.svg",
              width: 100,
              height: 100,
            }),
            "Mouse",
          ]),
        ]),
        "We'll set up Cuttle's zoom and pan controls to match your choice.",
      ])
    );
  },
};
