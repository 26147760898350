import { AffineMatrix, BoundingBox, TransformArgs, valueByUnitConversion, Vec } from "../geom";
import { globalState } from "../global-state";
import { Instance } from "./instance";
import { Node } from "./node";
import { PIVector } from "./parameter-interface";
import { SelectableComponentParameter, SelectableNode, SelectableParameter } from "./selectable";
import { Selection } from "./selection";
import { NodeTransformer, SelectionTransformer } from "./transformer";

const zeroVec = Object.freeze(new Vec());

export const transformMatrixForNode = (node: Node) => {
  // If this node has been evaluated, use the result.
  const trace = globalState.traceForNode(node);
  if (trace) {
    return trace.transformMatrix();
  }

  // If the transform arguments are all literals we can still create a matrix.
  const { transform } = node.source;
  if (transform) {
    const matrix = transformMatrixForTransformLiteral(transform);
    if (matrix) return matrix;
  }

  return AffineMatrix.indentity;
};

const transformMatrixForTransformLiteral = (transform: Instance) => {
  const { args } = transform;
  const transformArgs: TransformArgs = {};
  const projectUnit = globalState.project.settings.units;

  if (args.position) {
    const positionLiteral = args.position.compiled().literal;
    if (!positionLiteral) return;
    if (positionLiteral.type !== "Vec") return;
    let position = positionLiteral.value;
    if (positionLiteral.unit) {
      position = valueByUnitConversion(position, positionLiteral.unit, projectUnit);
    }
    transformArgs.position = position;
  }
  if (args.rotation) {
    const rotationLiteral = args.rotation.compiled().literal;
    if (!rotationLiteral) return;
    if (rotationLiteral.type !== "Number") return;
    transformArgs.rotation = rotationLiteral.value;
  }
  if (args.scale) {
    const scaleLiteral = args.scale.compiled().literal;
    if (!scaleLiteral) return;
    if (scaleLiteral.type !== "Vec" && scaleLiteral.type !== "Number") return;
    let scale = scaleLiteral.value;
    if (scaleLiteral.unit) {
      scale = valueByUnitConversion(scale, scaleLiteral.unit, projectUnit);
    }
    transformArgs.scale = scale;
  }
  if (args.skew) {
    const skewLiteral = args.skew.compiled().literal;
    if (!skewLiteral) return;
    if (skewLiteral.type !== "Number") return;
    transformArgs.skew = skewLiteral.value;
  }
  if (args.origin) {
    const originLiteral = args.origin.compiled().literal;
    if (!originLiteral) return;
    if (originLiteral.type !== "Vec") return;
    let origin = originLiteral.value;
    if (originLiteral.unit) {
      origin = valueByUnitConversion(origin, originLiteral.unit, projectUnit);
    }
    transformArgs.origin = origin;
  }

  return AffineMatrix.fromTransform(transformArgs);
};

export const contextTransformMatrixForNode = (node: Node): AffineMatrix => {
  const contextTransformMatrix = transformMatrixForNode(node).clone();
  let { parent } = node;
  while (parent) {
    const parentTransformMatrix = transformMatrixForNode(parent);
    contextTransformMatrix.preMul(parentTransformMatrix);
    parent = parent.parent;
  }
  return contextTransformMatrix;
};

export const contextMatrixForNode = (node: Node): AffineMatrix => {
  if (node.parent) {
    return contextTransformMatrixForNode(node.parent);
  }
  return AffineMatrix.indentity;
};

export const contextMatrixForInstance = (node: Node, instance: Instance) => {
  if (instance === node.source.base) {
    return contextTransformMatrixForNode(node);
  }
  return contextMatrixForNode(node);
};

export const transformedGraphicForNode = (node: Node) => {
  const trace = globalState.traceForNode(node);
  if (trace?.isSuccess()) {
    const contextMatrix = contextMatrixForNode(node);
    return trace.result.clone().affineTransform(contextMatrix);
  }
  return undefined;
};

export const transformOriginForNode = (node: Node): Readonly<Vec> => {
  // If we have an enabled transform return its origin.
  const { transform } = node.source;
  if (transform?.isEnabled) {
    const literal = transform.expressionForParameterWithName("origin")?.literal();
    if (literal && literal.type === "Vec") {
      return literal.value;
    } else {
      const trace = globalState.traceForNode(node);
      const origin = trace?.transform?.resultForArgName("origin");
      if (Vec.isValid(origin)) return origin;
    }
  }

  // If there's no transform, use the center of the bounding box. Make sure to
  // use the base instance here since we need the bounding box pre-transform.
  const trace = globalState.traceForNode(node);
  if (trace?.base.isSuccess()) {
    const box = trace.base.result.boundingBox();
    if (box) return box.center();
  }

  // If nothing was found, return the default origin.
  return zeroVec;
};

export interface TransformBox {
  boundingBox?: BoundingBox;
  boundingBoxTransform?: AffineMatrix;
}

export const transformBoxForSelection = (selection: Selection) => {
  const transformableSelection = selection.allTransformable();

  if (
    transformableSelection.items.length === 1 &&
    transformableSelection.items[0] instanceof SelectableNode
  ) {
    const { node } = transformableSelection.items[0];
    return {
      boundingBox: boundingBoxForNode(node, false),
      boundingBoxTransform: contextTransformMatrixForNode(node),
    };
  }

  return {
    boundingBox: selection.worldBoundingBox(),
    boundingBoxTransform: AffineMatrix.indentity,
  };
};

const boundingBoxForNode = (node: Node, transformToWorld: boolean) => {
  const trace = globalState.traceForNode(node);
  if (!trace?.base.isSuccess()) return;

  // Try the base instance geometry.
  let baseGraphic = trace.base.result;
  if (transformToWorld) {
    const contextTransformMatrix = contextTransformMatrixForNode(node);
    baseGraphic = baseGraphic.clone().affineTransform(contextTransformMatrix);
  }
  return baseGraphic.boundingBox();
};

export interface HandlePositionInfo {
  handlePosition: Vec;
  originPosition?: Vec;
}

export const handlePositionInfoForParameter = (
  selectable: SelectableParameter | SelectableComponentParameter
): HandlePositionInfo | undefined => {
  const { parameter } = selectable;

  const parameterInterface = parameter.interface;
  if (parameterInterface && !parameterInterface.isVisible) return;

  const trace = selectable.instanceTrace();
  if (!trace) return;

  const result = trace.resultForArgName(parameter.name);

  if (!Vec.isValid(result)) return;
  if (parameterInterface instanceof PIVector && result.isZero()) return;

  let handlePosition = result;
  let originPosition: Vec | undefined;

  if (selectable instanceof SelectableParameter) {
    const { node, instance } = selectable;
    let contextMatrix = contextMatrixForInstance(node, instance);
    if (parameterInterface instanceof PIVector) {
      const origin = transformOriginForNode(node);
      const originOffset = origin.clone().affineTransform(transformMatrixForNode(node));

      const { originParameterName } = parameterInterface;
      if (originParameterName) {
        const op = trace.resultForArgName(originParameterName);
        if (Vec.isValid(op)) {
          originPosition = op.clone().affineTransform(contextMatrix);
        }
      } else {
        contextMatrix = contextMatrix.clone().translate(originOffset);
        originPosition = new Vec().affineTransform(contextMatrix);
      }

      handlePosition = handlePosition.clone().affineTransformWithoutTranslation(contextMatrix);
      if (originPosition) {
        handlePosition.add(originPosition);
      }
    } else {
      handlePosition = handlePosition.clone().affineTransform(contextMatrix);
    }
  }

  return { handlePosition, originPosition };
};

export const flipNodes = (nodes: Node[], direction: Vec) => {
  const transformCenter = globalState.project.transformCenter();
  if (!transformCenter) return;
  const center = transformCenter.worldPosition;
  const ref1 = center.clone().sub(direction);
  const ref2 = center.clone().add(direction);
  const worldFlipMatrix = AffineMatrix.fromCenterAndReferencePoints(center, ref1, ref2, {
    allowScale: true,
  });
  for (let node of nodes) {
    const transformer = new NodeTransformer(node);
    transformer.transformWorld(worldFlipMatrix);
  }
};

export type Alignment = "left" | "center" | "right" | "top" | "middle" | "bottom";
const alignmentPointFromBoundingBox = (box: BoundingBox, alignment: Alignment) => {
  if (alignment === "left") return new Vec(box.min.x, 0);
  if (alignment === "center") return new Vec((box.min.x + box.max.x) * 0.5, 0);
  if (alignment === "right") return new Vec(box.max.x, 0);
  if (alignment === "top") return new Vec(0, box.min.y);
  if (alignment === "middle") return new Vec(0, (box.min.y + box.max.y) * 0.5);
  return new Vec(0, box.max.y); // if (alignment === "bottom")
};

export const alignNodes = (nodes: Node[], alignment: Alignment) => {
  const alignmentData: { node: Node; point: Vec }[] = [];
  const combinedBoundingBox = new BoundingBox(new Vec(Infinity), new Vec(-Infinity));
  for (let node of nodes) {
    const trace = globalState.traceForNode(node);
    if (trace?.base.isSuccess()) {
      const contextTransformMatrix = contextTransformMatrixForNode(node);
      const transformedBaseGeom = trace.base.result.clone().affineTransform(contextTransformMatrix);
      const box = transformedBaseGeom.boundingBox();
      if (box) {
        combinedBoundingBox.expandToIncludeBoundingBox(box);
        const point = alignmentPointFromBoundingBox(box, alignment);
        alignmentData.push({ node, point });
      }
    }
  }

  if (alignmentData.length === 0) return;

  const alignmentPoint = alignmentPointFromBoundingBox(combinedBoundingBox, alignment);

  for (let { node, point } of alignmentData) {
    const transformMatrix = AffineMatrix.fromTranslation(alignmentPoint.clone().sub(point));
    const transformer = new NodeTransformer(node);
    transformer.transformWorld(transformMatrix);
  }
};

export const scaleSelectionTransformBox = (
  selection: Selection,
  boundingBox: BoundingBox,
  boundingBoxTransform: AffineMatrix,
  scale: Vec
) => {
  const transformCenter = globalState.project.transformCenter();
  const inverseBoundingBoxTransform = boundingBoxTransform.clone().invert();

  let center: Vec;
  if (transformCenter) {
    center = transformCenter.worldPosition.clone().affineTransform(inverseBoundingBoxTransform);
  } else {
    center = boundingBox.center();
  }

  const transformMatrix = AffineMatrix.fromCenterScale(center, scale);

  transformMatrix.changeBasis(inverseBoundingBoxTransform, boundingBoxTransform); // Change basis from transform box to world coordinates
  transformMatrix.ensureMinimumBasisLength(globalState.project.settings.tolerance);

  const preserveRotation = selection.isSingle();
  const transformer = new SelectionTransformer(selection);
  transformer.transformWorld(transformMatrix, { preserveRotation });
};

export const handleOriginForParameter = (
  selectable: SelectableParameter | SelectableComponentParameter
) => {
  const parameterInterface = selectable.parameter.interface;
  if (parameterInterface instanceof PIVector) {
    const { originParameterName } = parameterInterface;
    if (selectable instanceof SelectableParameter) {
      const { node, instance } = selectable;
      const trace = globalState.traceForNode(node);
      if (trace) {
        if (originParameterName !== undefined) {
          const instanceTrace = trace?.traceForInstance(instance);
          if (instanceTrace) {
            const origin = instanceTrace.resultForArgName(originParameterName);
            if (Vec.isValid(origin)) return origin;
          }
        }
        // If no origin parameter is specified, use the transformed origin
        const origin = transformOriginForNode(node);
        const transformMatrix = transformMatrixForNode(node);
        return origin.clone().affineTransform(transformMatrix);
      }
    } else {
      if (originParameterName !== undefined) {
        const expressionTrace = selectable.expressionTrace();
        const origin = expressionTrace?.evaluationResult;
        if (Vec.isValid(origin)) return origin;
      } else {
        return new Vec();
      }
    }
  }
  return undefined;
};
