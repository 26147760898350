import m from "mithril";

import { globalState } from "../global-state";
import { parseExternalURL } from "../io/external-svg";
import { createPopup } from "../shared/popup";
import { domForVnode } from "../shared/util";
import { normalizeAssetsUrl } from "../util";
import { ShapePickerPopup } from "./shape-picker";

function unifiedToEmojiString(unified: string): string {
  const hexStrings = unified.split("-");
  const codePoints = hexStrings.map((s) => parseInt(s, 16));
  return String.fromCodePoint(...codePoints);
}

interface EmojiPickerAttrs {
  value: string;
  onchange: (value: string) => void;
}

export const EmojiPicker: m.Component<EmojiPickerAttrs> = {
  view: (vnode) => {
    const { value, onchange } = vnode.attrs;
    const normalizedUrl = normalizeAssetsUrl(value);

    let label: m.Child = "Select an emoji";
    try {
      // Only make an img tag if the format matches what we're expecting
      const { directory, name } = parseExternalURL(normalizedUrl);
      let alt = name;
      if (directory.includes("emoji")) {
        alt = unifiedToEmojiString(name);
      }
      label = m("img", {
        width: 32,
        height: 32,
        src: normalizedUrl,
        alt,
      });
    } catch (error) {
      console.warn("Unexpected emoji value.", value);
    }

    const openEmojiPickerPopup = () => {
      const spawnFrom = domForVnode(vnode);
      globalState.isPickerOpen = true;
      const gestureId = globalState.startGesture("Emoji Picker");
      createPopup({
        spawnFrom,
        view: () =>
          m(ShapePickerPopup, {
            value,
            onchange,
          }),
        placement: "top-start",
        offset: 8,
        onclose: () => {
          globalState.isPickerOpen = false;
          globalState.stopGesture(gestureId);
        },
        closeOnEnter: true,
      });
    };

    return m("button.secondary.emoji-picker", { onclick: openEmojiPickerPopup }, [label]);
  },
};
