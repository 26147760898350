import m from "mithril";

import { assert } from "../../geom";
import { domForVnode } from "../../shared/util";

interface TextInputAttrs {
  value: string;
  onchange: (value: string) => void;
  initialSelectionRange?: { start: number; end: number };
}
export const TextInput: m.Component<TextInputAttrs> = {
  view() {
    return m("input[type=text]", { spellcheck: false });
  },
  oncreate(vnode) {
    const elem = domForVnode(vnode);
    assert(elem instanceof HTMLInputElement);
    elem.value = vnode.attrs.value;
    elem.addEventListener("blur", () => {
      vnode.attrs.onchange(elem.value);
      m.redraw();
    });
    elem.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.code == "Enter") {
        event.preventDefault();
        elem.blur();
      }
    });
    const selectionRange = vnode.attrs.initialSelectionRange;
    if (selectionRange) {
      elem.focus();
      elem.setSelectionRange(selectionRange.start, selectionRange.end);
    }
  },
  onupdate(vnode) {
    const elem = domForVnode(vnode);
    assert(elem instanceof HTMLInputElement);
    // Don't update the input's value while the user is changing it.
    if (elem !== document.activeElement) {
      elem.value = vnode.attrs.value;
    }
  },
};
