import { CodeComponent, Component } from "./component";
import { Documentation } from "./documentation";
import { Expression } from "./expression";
import { Node } from "./node";
import { ProjectSettings } from "./project";
import { registerClass } from "./registry";

export interface CodeEditorRange {
  line: number;
  ch: number;
}
export interface CodeEditorSelection {
  anchor: CodeEditorRange;
  head: CodeEditorRange;
}

type ExpressionSelections = CodeEditorSelection[] | "all";

export class ExpressionFocus {
  expression: Expression;
  selections: ExpressionSelections;

  constructor(expression: Expression, selections: ExpressionSelections = []) {
    this.expression = expression;
    this.selections = selections;
  }
}
registerClass("ExpressionFocus", ExpressionFocus);

export class ComponentFocus {
  component: Component;
  node: Node;

  constructor(component: Component, node: Node) {
    this.component = component;
    this.node = node;
  }
}
registerClass("ComponentFocus", ComponentFocus);

export class CodeComponentFocus {
  component: CodeComponent;

  constructor(component: CodeComponent) {
    this.component = component;
  }
}
registerClass("CodeComponentFocus", CodeComponentFocus);

export class DocumentationFocus {
  documentation: Documentation;

  constructor(documentation: Documentation) {
    this.documentation = documentation;
  }
}
registerClass("DocumentationFocus", DocumentationFocus);

export class SettingsFocus {
  settings: ProjectSettings;

  constructor(settings: ProjectSettings) {
    this.settings = settings;
  }
}
registerClass("SettingsFocus", SettingsFocus);
