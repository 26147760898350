import m from "mithril";

import { AffineMatrix, Anchor } from "../../geom";
import { globalState } from "../../global-state";
import { isPopupOpen } from "../../shared/popup";
import { paintDotToCanvas, paintGeometryToCanvas } from "../canvas-geometry";
import styleConstants from "../style-constants";
import { CanvasInterfaceElement } from "./canvas-interface-element";

export class SnappingUI implements CanvasInterfaceElement {
  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    if (!globalState.deviceStorage.geometrySnappingEnabled) return;

    const { snapping, canvasDrag } = globalState;

    if (snapping.currentPoint) {
      if (snapping.currentPoint.sources) {
        for (let source of snapping.currentPoint.sources) {
          if (source.geometry instanceof Anchor) continue;
          paintGeometryToCanvas(
            source.geometry,
            styleConstants.red47Alpha60,
            2,
            false,
            false,
            viewMatrix,
            ctx
          );
        }
      }
      const pos = snapping.currentPoint.worldPosition.clone().affineTransform(viewMatrix);
      paintDotToCanvas(pos, 3, styleConstants.red47, ctx);

      if (!canvasDrag || canvasDrag.startPoint.isPrecise()) {
        for (let point of snapping.referencePoints) {
          const pos = point.clone().affineTransform(viewMatrix);
          paintDotToCanvas(pos, 3, styleConstants.red47, ctx);
        }
      }
    }
  }
}

export const SnappingPointTooltip: m.Component = {
  view() {
    const snappingPoint = globalState.snapping.currentPoint;
    if (!snappingPoint || isPopupOpen() || !globalState.deviceStorage.geometrySnappingEnabled) {
      return;
    }

    const focusedComponent = globalState.project.focusedComponent();
    if (!focusedComponent) return;

    const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
    const viewMatrix = viewport.viewMatrixWithCanvasDimensions(globalState.canvasDimensions);

    const position = snappingPoint.worldPosition.clone().affineTransform(viewMatrix);
    position.x += globalState.canvasRectPixels.x;
    position.y += globalState.canvasRectPixels.y;
    position.floor();

    const style = {
      left: position.x + "px",
      top: position.y + "px",
    };
    return m(".snapping-point-tooltip", { style }, snappingPoint.message());
  },
};
