// Note: any data you preload will stick around forever. In the future we could
// add functionality for discarding preloaded URLs if we know we don't need them
// anymore. We'd need to use `URL.revokeObjectURL`.

import { normalizeAssetsUrl } from "./util";

class PreloadManager {
  /** Keys are URLs, Values are URLs created with `createObjectURL` or `null` if
   * still loading. */
  preloaded: Record<string, string | null> = {};

  async preload(url: string) {
    url = normalizeAssetsUrl(url);

    // Don't fetch any url more than once
    if (this.preloaded[url] || this.preloaded[url] === null) return;

    // While loading
    this.preloaded[url] = null;

    // Async fully load to memory and make blob URL
    try {
      const res = await fetch(url);
      const blob = await res.blob();
      this.preloaded[url] = URL.createObjectURL(blob);
    } catch (e) {
      console.warn(e, "Failed to preload URL.", url);
    }
  }

  get(src: string) {
    const preloadedUrl = this.preloaded[src];
    if (preloadedUrl) return preloadedUrl;
    return src;
  }
}

export const preloadManager = new PreloadManager();
