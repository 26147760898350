import m from "mithril";

import CodeMirror, { EditorConfiguration } from "codemirror";
import "codemirror/addon/comment/comment";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/javascript/javascript";

// Find
import "codemirror/addon/dialog/dialog";
import "codemirror/addon/search/jump-to-line";
import "codemirror/addon/search/search";
import "codemirror/addon/search/searchcursor";

import { isMacPlatform } from "../shared/util";

// ----------------------------------------------------------------------------
// CodeMirror Optimization
// ----------------------------------------------------------------------------

const amendChangeThresholdMs = 500;

// Creating CodeMirrors seems to be somewhat expensive (~20ms) and we're
// frequently creating and disposing of them as the inspector changes. As an
// optimization, we'll keep unused CodeMirror instances here and reuse them when
// we "create" new codemirrors.
export class CodeMirrorPool {
  options: EditorConfiguration;

  private _unusedPool: CodeMirror.Editor[];

  constructor(options: EditorConfiguration) {
    this.options = options;
    this._unusedPool = [];
  }

  create(containerEl: HTMLElement) {
    let cm = this._unusedPool.pop();
    if (cm) {
      containerEl.appendChild(cm.getWrapperElement());
      return cm;
    }

    cm = CodeMirror(containerEl, this.options);
    cm.on("keyHandled", (instance, name, event) => {
      // CodeMirror's keyHandled gets triggered when CodeMirror is handling a
      // command (e.g. cmd+A). For these we want to stopPropagation so the
      // main app doesn't also try to handle the event.
      event.stopPropagation();
    });

    return cm;
  }

  dispose(cm: CodeMirror.Editor) {
    this._unusedPool.push(cm);
  }
}

// CodeMirror has this "operation" system for managing when it does DOM methods
// that trigger expensive reflows. The idea is to tell CodeMirror when you're
// doing operations (like changing the value) and when you're done -- and
// CodeMirror will only do the expensive operations when you're done. See
// https://codemirror.net/doc/manual.html#api_misc
//
// So we want to wrap all our CodeMirror operations in `startOperation` and
// `endOperation`. To do this, we make dummy components and insist that these
// components "wrap" all CodeEditor components. Since Mithril runs its lifecycle
// callbacks in document order, that ensures that `startOperation` and
// `endOperation` wrap all our CodeEditor lifecycle callbacks.

const dummyEl = document.createElement("div");
const dummyCm = CodeMirror(dummyEl);

export const CodeEditorStartOptimizer: m.Component = {
  view() {
    return null;
  },
  onupdate() {
    dummyCm.startOperation();
  },
};

export const CodeEditorEndOptimizer: m.Component = {
  view() {
    return null;
  },
  onupdate() {
    dummyCm.endOperation();
  },
};

// ----------------------------------------------------------------------------
// CodeMirror Shared Behavior
// ----------------------------------------------------------------------------

const cmdKey = isMacPlatform() ? "Cmd" : "Ctrl";

export const codeMirrorExtraKeys: CodeMirror.KeyMap = CodeMirror.normalizeKeyMap({
  // Handle (Shift-)Tab in code-editor, or fall through to this which falls
  // through to the browser's focus management
  Tab: false,
  ["Shift-Tab"]: false,

  [`${cmdKey}-/`]: "toggleComment",
  "Alt-Enter": "newlineAndIndent",

  // Autocomplete shortcuts are set up in CodeEditor oncreate, to be able to
  // pass in options, was // "Ctrl-Space": "autocomplete",

  // TODO: Soft tab behavior for del?
  // TODO: Make sure Ctrl is supported for PC.

  // The following override parts CodeMirror's default keymap. The default
  // keymap can be found at https://codemirror.net/doc/manual.html#commands or
  // https://github.com/codemirror/CodeMirror/blob/master/src/input/keymap.js

  // We're not using CodeMirror's undo, we want these handled by the editor.
  [`${cmdKey}-Z`]: false,
  [`${cmdKey}-Shift-Z`]: false,

  // We prefer our Cmd+D (duplicate) over CodeMirror's deleteLine behavior.
  [`${cmdKey}-D`]: false,
});

// ----------------------------------------------------------------------------
// Shared string utils and types
// ----------------------------------------------------------------------------

export interface CodeHint {
  text: string;
  className: string;
}

// Initial version from https://codemirror.net/demo/complete.html
// Addon code: https://github.com/codemirror/CodeMirror/blob/master/addon/hint/show-hint.js
// Docs: https://codemirror.net/doc/manual.html#addon_show-hint
export const codeMirrorHintOptions = (hints: CodeHint[]) => ({
  hint: (cm: CodeMirror.Editor) => {
    const cursor = cm.getCursor(),
      line = cm.getLine(cursor.line),
      token = cm.getTokenAt(cursor);
    // Don't show hints in strings and comments
    if (token.type && /\b(?:string|comment)\b/.test(token.type)) return;
    let start = cursor.ch,
      end = cursor.ch;
    while (start && /[\w\.]/.test(line.charAt(start - 1))) --start;
    while (end < line.length && /\w/.test(line.charAt(end))) ++end;
    const word = line.slice(start, end);
    const wordLower = word.toLowerCase();
    // Show case-mismatched words after case match
    const listCaseMatch: CodeHint[] = [];
    const listCaseMismatch: CodeHint[] = [];
    hints.forEach((hint) => {
      if (hint.text.startsWith(word)) {
        listCaseMatch.push(hint);
      } else if (hint.text.toLowerCase().startsWith(wordLower)) {
        // Do show hints with case mismatch
        listCaseMismatch.push(hint);
      }
    });
    const list = [...listCaseMatch, ...listCaseMismatch];
    if (list.length > 0) {
      return {
        list,
        from: CodeMirror.Pos(cursor.line, start),
        to: CodeMirror.Pos(cursor.line, end),
      };
    }
    return;
  },
  // We don't want to autocomplete with one result, b/c we're showing the list
  // while typing
  completeSingle: false,
  // Useful for style debugging
  // closeOnUnfocus: false,
});
