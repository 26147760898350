import m from "mithril";
import { CodeComponent, Component } from "../model/component";
import { Selection } from "../model/selection";
import { ToastMessageClipboard, toastState } from "./toast-message";

export const showRasterizeCanvasTooLargeToast = () => {
  toastState.showBasic({
    type: "error",
    message: "A path with an image fill was too large to be rasterized.",
    nextStep:
      "Try disabling Rasterize Images in settings, or set Rasterizing Resolution to a lower number.",
  });
};

export const showClipboardInfoToast = (
  actionPastTense: string,
  copiedOrPasted: Selection | Component | CodeComponent
) => {
  if (copiedOrPasted instanceof Selection) {
    // Need to clone so that the toast message doesn't change with the
    // selection.
    copiedOrPasted = copiedOrPasted.clone();
  }
  toastState.show({
    view: () =>
      m(ToastMessageClipboard, {
        message: actionPastTense,
        copiedOrPasted,
      }),
  });
};
