import m from "mithril";

import type { Extensions, JSONContent } from "@tiptap/core";
import { Editor, isTextSelection } from "@tiptap/core";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import FloatingMenu from "@tiptap/extension-floating-menu";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";

import { CuttleComponentNode } from "./doc-editor-extension-component";
import { CuttleImage } from "./doc-editor-extension-image";
import { CuttleProjectCardNode } from "./doc-editor-extension-project-card";
import { SelectionChanging } from "./doc-editor-extension-selection-changing";
import { VideoPlayerNode } from "./doc-editor-extension-video";
import { ImageUploadExtension } from "./doc-editor-plugin-image-upload";

import { globalState } from "../../global-state";
import { canonicalOrigin } from "../../shared/config";
import { createPopupMenu, MenuItem } from "../../shared/popup";
import { createPopupPrompt } from "../../shared/popup-prompt";
import { classNames, domForVnode } from "../../shared/util";
import { ProjectMeta } from "../project-meta";
import { Thumbnail } from "../thumbnail";
import { toastState } from "../toast-message";
import { transformPastedHTML } from "./doc-editor-shared";

// We have an app-wide block on the default context menu, so that we can show
// our custom one in some places. We want people to be able to use the browser
// default in the DocEditor view.
const allowBrowserContextMenu = (e: MouseEvent) => {
  e.stopPropagation();
};

interface DocEditorAttrs {
  doc: object;
  editable: boolean;
  onchange: (value: JSONContent) => void;
}
export const DocEditor: m.ClosureComponent<DocEditorAttrs> = (initialVnode) => {
  let latestVnode = initialVnode;
  let editor: Editor | undefined;

  return {
    view(vnode) {
      latestVnode = vnode;
      const { editable } = vnode.attrs;
      const className = classNames({ "is-empty": editor?.isEmpty });
      // Note that our editor extensions depend on this structure and naming
      return m(".doc-editor", { oncontextmenu: allowBrowserContextMenu, className }, [
        m(ProjectMeta, { editable }),
        m(".doc-editor-wrap"),
        editable && [
          m(".doc-editor-menu-selection-wrap", m(DocEditorSelectionMenu, { editor })),
          m(".doc-editor-menu-line-wrap", m(DocEditorLineMenu, { editor })),
        ],
      ]);
    },
    oncreate(vnode) {
      const el = domForVnode(vnode);
      const editorEl = el.querySelector(".doc-editor-wrap");
      const selectionMenuEl = el.querySelector(".doc-editor-menu-selection-wrap");
      const lineMenuEl = el.querySelector(".doc-editor-menu-line-wrap");
      const { doc, editable } = vnode.attrs;

      // Suppress spellcheck if not editing
      const setSpellcheck = () => {
        if (!editor?.view) return;
        editor.view.dom.setAttribute("spellcheck", String(editor.view.hasFocus()));
      };

      const extensions: Extensions = [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          },
        }),
        Link.configure({ openOnClick: true }),
      ];

      // Add our embed extensions
      const embedExtensions = [
        CuttleComponentNode,
        CuttleImage, // Doesn't use drag handle
        VideoPlayerNode,
        CuttleProjectCardNode,
      ];
      for (const embedExtension of embedExtensions) {
        // Make our embed extensions selectable and draggable only when the doc
        // is editable. Our prosemirror-drag-handle mix-in takes care of the
        // handle UI, and some of the browser peculiarities.
        extensions.push(embedExtension.extend({ selectable: editable, draggable: editable }));
      }

      // Add editable-only extensions
      let editableDocHandlers = {};
      if (editable) {
        extensions.push(
          BubbleMenu.configure({
            element: selectionMenuEl as HTMLElement,
            tippyOptions: {
              maxWidth: "80ch",
              placement: "top-start",
              zIndex: 99,
            },
            shouldShow: ({ editor, view, state, from, to }) => {
              // Reworked from the default, because we only want the selection
              // menu for text selections where a mark change will be visible.
              // https://github.com/ueberdosis/tiptap/blob/063ced27ca55f331960b01ee6aea5623eee0ba49/packages/extension-bubble-menu/src/bubble-menu-plugin.ts#L43
              if (!view.hasFocus()) return false;
              const { doc, selection } = state;
              const isText = isTextSelection(selection);
              if (!isText) return false;
              const isEmpty = selection.empty || (isText && doc.textBetween(from, to).length === 0);
              if (isEmpty) return false;
              if (editor.isActive("codeBlock")) return false;
              return true;
            },
          }),
          FloatingMenu.configure({
            element: lineMenuEl as HTMLElement,
            tippyOptions: {
              // Give some space between cursor and menu
              offset: [0, 36],
              maxWidth: "100%",
              zIndex: 99,
            },
          }),
          Placeholder.configure({
            placeholder: "Click here to write notes about your project or upload images...",
          }),
          ImageUploadExtension,
          SelectionChanging
        );

        // Add edit-only event handlers
        editableDocHandlers = {
          onUpdate({ editor }: { editor: Editor }) {
            latestVnode.attrs.onchange(editor.getJSON() as JSONContent);
          },
          onSelectionUpdate() {
            // Needed to trigger menus to redraw with new selection
            m.redraw();
          },
          onCreate({ editor }: { editor: Editor }) {
            globalState.activeDocEditor = editor;
            setSpellcheck();
          },
          onDestroy() {
            globalState.activeDocEditor = undefined;
          },
          onFocus() {
            setSpellcheck();
          },
          onBlur() {
            setSpellcheck();
          },
        };
      }

      editor = new Editor({
        content: doc as JSONContent,
        editable,
        element: editorEl as HTMLElement,
        extensions,
        ...editableDocHandlers,
        editorProps: {
          transformPastedHTML,
        },
      });

      // Need to redraw so that menus get the editor reference
      m.redraw();
    },
    onremove() {
      if (editor) {
        editor.destroy();
      }
    },
  };
};

const DocEditorSelectionMenu: m.Component<{ editor: Editor | undefined }> = {
  view: ({ attrs: { editor } }) => {
    if (!editor) return;

    const boldClassName = classNames({ active: editor.isActive("bold") });
    const italicClassName = classNames({ active: editor.isActive("italic") });
    const codeClassName = classNames({ active: editor.isActive("code") });
    const linkIsActive = editor.isActive("link");
    const linkClassName = classNames({ active: linkIsActive });

    return m(".doc-editor-menu.doc-editor-menu-selection", [
      m(
        "button.secondary",
        {
          onclick: () => {
            editor.chain().focus().toggleBold().run();
          },
          className: boldClassName,
        },
        "Bold"
      ),
      m(
        "button.secondary",
        {
          onclick: () => {
            editor.chain().focus().toggleItalic().run();
          },
          className: italicClassName,
        },
        "Italic"
      ),
      m(
        "button.secondary",
        {
          onclick: () => {
            editor.chain().focus().toggleCode().run();
          },
          className: codeClassName,
        },
        "Code"
      ),
      m(".doc-editor-menu-seperator"),
      !linkIsActive &&
        m(
          "button.secondary",
          {
            onclick: (e: MouseEvent) => {
              createPopupPrompt({
                spawnFrom: e.target as HTMLElement,
                label: "URL",
                placeholder: "https://",
                autofocus: true,
                onsubmit: (href) => {
                  if (href) {
                    editor.chain().focus().setLink({ href }).run();
                  } else {
                    editor.view.focus();
                  }
                },
                oncancel: () => {
                  editor.view.focus();
                },
              });
            },
            className: linkClassName,
          },
          "Link"
        ),
      linkIsActive && [
        m(
          "button.secondary",
          {
            onclick: () => {
              editor
                .chain()
                .focus()
                // Unsets the whole link with partial selected
                .extendMarkRange("link")
                .unsetLink()
                // Works around extendMarkRange suppressing the selection menu
                .cuttleAllowSelectionMenu()
                .run();
            },
            className: linkClassName,
          },
          "Unlink"
        ),
        m(
          "a",
          {
            href: editor.getAttributes("link").href,
            target: "_blank",
            rel: "noreferrer noopener nofollow",
          },
          "Visit Link ↗"
        ),
      ],
      m(".doc-editor-menu-seperator"),
      m(DocEditorLineMenu, { editor, inSelectionMenu: true }),
    ]);
  },
};

const DocEditorLineMenu: m.Component<{ editor: Editor | undefined; inSelectionMenu?: boolean }> = {
  view: ({ attrs: { editor, inSelectionMenu } }) => {
    if (!editor) return;

    const h1ClassName = classNames({ active: editor.isActive("heading", { level: 1 }) });
    const h2ClassName = classNames({ active: editor.isActive("heading", { level: 2 }) });
    const ulClassName = classNames({ active: editor.isActive("bulletList") });
    const olClassName = classNames({ active: editor.isActive("orderedList") });

    const componentMenuItems: MenuItem[] = [];
    globalState.project.components.forEach((component) => {
      if (component.isImmutable) return;
      const graphic = globalState.tracesByDefinition.get(component)?.result;
      componentMenuItems.push({
        label: component.name,
        icon: () =>
          m(
            ".doc-editor-line-menu-components-thumbnail",
            m(Thumbnail, { graphic, width: 20, height: 20, padding: 2 })
          ),
        action: () => {
          editor.chain().focus().insertCuttleComponent({ component }).run();
        },
      });
    });

    const buttons = [
      !inSelectionMenu && [
        m(
          "button.secondary",
          {
            onclick: () => {
              editor.chain().focus().cuttleImagePick().run();
            },
          },
          "Image"
        ),
        m(
          "button.secondary",
          {
            onclick: (e: MouseEvent) => {
              createPopupPrompt({
                spawnFrom: e.target as HTMLElement,
                label: "YouTube Link",
                placeholder: "https://youtu.be/…",
                autofocus: true,
                onsubmit: (url) => {
                  const success = editor.chain().focus().insertVideoPlayer({ url }).run();
                  if (!success) {
                    toastState.showBasic({
                      type: "warning",
                      message: "YouTube link not found.",
                    });
                  }
                },
                oncancel: () => {
                  editor.view.focus();
                },
              });
            },
          },
          "Video"
        ),
        m(
          "button.secondary",
          {
            onclick: (e: MouseEvent) => {
              createPopupMenu({
                className: "doc-editor-line-menu-components",
                menuItems: componentMenuItems,
                spawnFrom: e.target as HTMLElement,
                placement: "bottom-start",
              });
            },
          },
          "Component"
        ),
        m(
          "button.secondary",
          {
            onclick: (e: MouseEvent) => {
              createPopupPrompt({
                spawnFrom: e.target as HTMLElement,
                label: "Cuttle Project Link",
                placeholder: canonicalOrigin + "/…",
                autofocus: true,
                onsubmit: (url) => {
                  const success = editor.chain().focus().insertCuttleProjectCard({ url }).run();
                  if (!success) {
                    toastState.showBasic({
                      type: "warning",
                      message: "Cuttle project link not found.",
                    });
                  }
                },
                oncancel: () => {
                  editor.view.focus();
                },
              });
            },
          },
          "Project"
        ),
      ],
      m(
        "button.secondary",
        {
          onclick: () => {
            editor.chain().focus().toggleHeading({ level: 1 }).run();
          },
          className: h1ClassName,
        },
        inSelectionMenu ? "H1" : "# Heading 1"
      ),
      m(
        "button.secondary",
        {
          onclick: () => {
            editor.chain().focus().toggleHeading({ level: 2 }).run();
          },
          className: h2ClassName,
        },
        inSelectionMenu ? "H2" : "## Heading 2"
      ),
      m(
        "button.secondary",
        {
          onclick: () => {
            editor.chain().focus().toggleBulletList().run();
          },
          className: ulClassName,
        },
        inSelectionMenu ? "* List" : "* Bulleted List"
      ),
      m(
        "button.secondary",
        {
          onclick: () => {
            editor.chain().focus().toggleOrderedList().run();
          },
          className: olClassName,
        },
        inSelectionMenu ? "1. List" : "1. Numbered List"
      ),
    ];

    return inSelectionMenu ? buttons : m(".doc-editor-menu.doc-editor-menu-line", buttons);
  },
};
