import { BoundingBox, Graphic, Group, scaleFactorForUnitConversion, Unit, Vec } from "../geom";
import { simplifyHierarchy } from "../geom-internal";

/**
 * A graphic in with an artboard in a specific real world unit. This type is
 * templated on `units` so that we can ensure we have a framed graphic in a
 * specific unit. For example before exporting an SVG of PDF the graphic must
 * always be converted to points.
 */
export class FramedGraphic<U extends Unit = Unit> {
  graphic: Graphic;
  artboard: BoundingBox;
  units: U;

  constructor(graphic: Graphic, artboard: BoundingBox, units: U) {
    this.graphic = graphic;
    this.artboard = artboard;
    this.units = units;
  }

  clone() {
    return new FramedGraphic<U>(this.graphic.clone(), this.artboard.clone(), this.units);
  }
}

export const tightFramedGraphic = <U extends Unit = Unit>(
  graphic: Graphic,
  units: U
): FramedGraphic<U> => {
  const graphicBounds = graphic.boundingBox();
  const graphicCenter = graphicBounds?.center() ?? new Vec();
  const graphicSize = graphicBounds?.size() ?? new Vec();
  const halfSize = graphicSize.clone().mulScalar(0.5);
  const artboard = boundingBoxFromCenterHalfSize(graphicCenter, halfSize);

  return new FramedGraphic(graphic, artboard, units);
};

export const usLetterFramedGraphic = <U extends Unit = Unit>(
  graphic: Graphic,
  units: U
): FramedGraphic<U> => {
  const graphicBounds = graphic.boundingBox();

  const inchesToUnitScale = scaleFactorForUnitConversion("in", units);

  let artboardSize = new Vec(8.5, 11).mulScalar(inchesToUnitScale);
  if (graphicBounds) {
    const graphicSize = graphicBounds.size();
    if (graphicSize.x > artboardSize.x || graphicSize.y > artboardSize.y) {
      artboardSize = graphicSize;
    }
  }

  const graphicCenter = graphicBounds?.center() ?? new Vec();
  const halfSize = artboardSize.clone().mulScalar(0.5);
  const artboard = boundingBoxFromCenterHalfSize(graphicCenter, halfSize);

  return new FramedGraphic(graphic, artboard, units);
};

export const framedGraphicFitToSize = <U extends Unit = Unit>(
  graphic: Graphic,
  frameSize: Vec,
  frameUnits: U
): FramedGraphic<U> => {
  const graphicBounds = graphic.boundingBox();
  if (graphicBounds) {
    const artboardCenter = frameSize.clone().mulScalar(0.5);
    const graphicCenter = graphicBounds.center();
    const graphicSize = graphicBounds.size();

    let scaleFactor = Math.min(frameSize.x / graphicSize.x, frameSize.y / graphicSize.y);
    if (!Number.isFinite(scaleFactor)) {
      scaleFactor = 1;
    }

    graphic = graphic
      .clone()
      .transform({
        origin: graphicCenter,
        position: artboardCenter,
        scale: scaleFactor,
      })
      .scaleStroke(scaleFactor);
  }

  return new FramedGraphic(graphic, new BoundingBox(new Vec(0, 0), frameSize), frameUnits);
};

const boundingBoxFromCenterHalfSize = (center: Vec, halfSize: Vec) => {
  return new BoundingBox(center.clone().sub(halfSize), center.clone().add(halfSize));
};

/**
 * Returns a framed graphic with its upper left corner at 0,0 scaled to the
 * target export units.
 */
export const normalizedFramedGraphicForExport = <U extends Unit = Unit>(
  framedGraphic: FramedGraphic,
  exportUnits: U
): FramedGraphic<U> => {
  const scaleFactor = scaleFactorForUnitConversion(framedGraphic.units, exportUnits);
  const simplifiedGraphic = simplifyHierarchy(framedGraphic.graphic.clone()) ?? new Group();
  const exportGraphic = simplifiedGraphic
    .transform({
      origin: framedGraphic.artboard.min,
      scale: scaleFactor,
    })
    .scaleStroke(scaleFactor);
  const exportArtboard = new BoundingBox(
    new Vec(0, 0),
    framedGraphic.artboard.size().mulScalar(scaleFactor)
  );
  return new FramedGraphic(exportGraphic, exportArtboard, exportUnits);
};
