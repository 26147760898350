import m from "mithril";

import { BoundingBox, Graphic, Vec } from "../geom";
import { globalState } from "../global-state";
import { CodeComponent, Component } from "../model/component";
import { CanvasDimensions, Viewport } from "../model/viewport";
import { paintStyledGraphicToCanvas } from "./canvas-geometry";
import { paintGrid } from "./canvas-ui/canvas-grid";
import { paintRulers, rulerWidthPixels } from "./canvas-ui/canvas-ruler";
import { CustomCanvas } from "./custom-canvas";
import { RectWatcher } from "./rect-watcher";
import styleConstants from "./style-constants";

interface PreviewCanvasAttrs {
  component: Component | CodeComponent;
  canvasDimensions: CanvasDimensions;
  zoomViewport: boolean;
  hideRulers?: boolean;
}
export const PreviewCanvas: m.ClosureComponent<PreviewCanvasAttrs> = () => {
  // Always zooms to fit geometry
  const viewport = new Viewport();

  return {
    view({ attrs: { component, canvasDimensions, zoomViewport, hideRulers } }) {
      let graphic: Graphic | undefined;
      const assignDefaultStrokeToUnstyled = component instanceof CodeComponent;

      const trace = globalState.traceForComponent(component);
      if (trace?.isSuccess()) {
        graphic = trace.result;

        if (zoomViewport) {
          const box = graphic.boundingBox();
          if (box) {
            viewport.scaleToFitBoundingBox(box, canvasDimensions);
          }
        }
      }

      const viewportSize = canvasDimensions.size;
      const viewMatrix = viewport.viewMatrixWithBounds(canvasDimensions.usableArea);

      return m(
        ".viewport-main",
        m(CustomCanvas, {
          viewportSize,
          render: (viewportSize: Vec, ctx: CanvasRenderingContext2D) => {
            ctx.fillStyle = styleConstants.white;
            ctx.fillRect(0, 0, viewportSize.x, viewportSize.y);

            const { units, gridDivisions } = globalState.project.settings;

            if (globalState.project.isGridEnabled) {
              paintGrid(viewMatrix, viewport, viewportSize, gridDivisions, ctx);
            }

            if (graphic) {
              paintStyledGraphicToCanvas(
                graphic,
                viewMatrix,
                ctx,
                viewportSize,
                assignDefaultStrokeToUnstyled
              );
            }

            if (!hideRulers) {
              paintRulers(viewMatrix, viewport, viewportSize, units, gridDivisions, ctx);
            }
          },
        })
      );
    },
  };
};

interface PreviewCanvasAutosizeAttrs {
  component: Component | CodeComponent;
  marginBottom: number;
  zoomViewport: boolean;
  hideRulers?: boolean;
}
export const PreviewCanvasAutosize: m.Component<PreviewCanvasAutosizeAttrs> = {
  view({ attrs: { component, marginBottom, zoomViewport, hideRulers } }) {
    return m(RectWatcher, {
      className: "svg-preview-canvas-autosize",
      view: (canvasRectPixels) => {
        const canvasSize = new Vec(canvasRectPixels.width, canvasRectPixels.height);
        const usableMin = hideRulers ? new Vec(0, 0) : new Vec(rulerWidthPixels, rulerWidthPixels);
        const usableMax = new Vec(canvasSize.x, canvasSize.y - marginBottom);
        const usableArea = new BoundingBox(usableMin, usableMax);
        const canvasDimensions = new CanvasDimensions(canvasSize, usableArea);
        return m(PreviewCanvas, {
          component,
          canvasDimensions,
          zoomViewport,
          hideRulers,
        });
      },
    });
  },
};
