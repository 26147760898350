import {
  AffineMatrix,
  Anchor,
  BoundingBox,
  Color,
  CubicSegment,
  LineSegment,
  pairs,
  Path,
  Vec,
} from "../../geom";
import { globalState } from "../../global-state";
import { AnchorDefinition } from "../../model/builtin-primitives";
import { CanvasPoint } from "../../model/canvas-point";
import { Expression } from "../../model/expression";
import { expressionCodeForVec } from "../../model/expression-code";
import { ComponentFocus } from "../../model/focus";
import { Node } from "../../model/node";
import { SelectableSegment } from "../../model/selectable";
import { transformedGraphicForNode } from "../../model/transform-utils";
import { paintGeometryToCanvas } from "../canvas-geometry";
import styleConstants from "../style-constants";
import { startSelectionDrag } from "../tool/selection-drag";
import { constrainCanvasPoint, worldPositionFromEvent } from "../util";
import { CanvasInterfaceElement } from "./canvas-interface-element";

export class PathSegmentUI implements CanvasInterfaceElement {
  id: string;
  selectable: SelectableSegment;

  isSelected: boolean;
  isInverted: boolean;

  segmentWorld?: LineSegment | CubicSegment;

  constructor(selectable: SelectableSegment, invert: boolean) {
    const { node, nextNode } = selectable;

    this.id = `segment-${node.hash()}-${nextNode.hash()}`;
    this.selectable = selectable;
    this.isSelected = globalState.project.selection.contains(this.selectable);
    this.isInverted = invert;

    const a1 = transformedGraphicForNode(node);
    const a2 = transformedGraphicForNode(nextNode);
    if (a1 instanceof Anchor && a2 instanceof Anchor) {
      if (a1.handleOut.isZero() && a2.handleIn.isZero()) {
        this.segmentWorld = LineSegment.fromAnchors(a1, a2);
      } else {
        this.segmentWorld = CubicSegment.fromAnchors(a1, a2);
      }
    }
  }

  isValid() {
    return globalState.project.selectableExistsAndIsValid(this.selectable);
  }

  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    if (!this.segmentWorld) return;
    const color = this.isSelected
      ? styleConstants.blue63
      : this.isInverted
      ? styleConstants.gray50
      : styleConstants.black;
    const strokeWidth = this.isSelected ? 2 : this.isInverted ? 1.5 : 1;
    paintGeometryToCanvas(
      this.segmentWorld,
      color,
      strokeWidth,
      this.isSelected,
      false,
      viewMatrix,
      ctx
    );
  }
}

export class InteractablePathSegmentUI extends PathSegmentUI implements CanvasInterfaceElement {
  isContainedByBoundingBox(box: BoundingBox) {
    return Boolean(this.segmentWorld?.isContainedByBoundingBox(box));
  }
  isOverlappedByBoundingBox(box: BoundingBox) {
    return Boolean(this.segmentWorld?.isOverlappedByBoundingBox(box));
  }
  selectables() {
    return [this.selectable];
  }

  hitTest(worldPosition: Vec, pixelsPerUnit: number) {
    const position = this.segmentWorld?.closestPoint(worldPosition)?.position;
    if (!position) return undefined;
    return { distance: worldPosition.distance(position) };
  }

  cursor(): string {
    if (this.selectable.isMoveLocked()) {
      return "select-locked";
    }
    return "select";
  }

  insertAnchorWithPointerEvent(event: PointerEvent) {
    if (!this.segmentWorld) return;

    const canvasPoint = new CanvasPoint(worldPositionFromEvent(event));
    constrainCanvasPoint(canvasPoint);

    const closestResult = this.segmentWorld.closestPoint(canvasPoint.worldPosition);
    if (closestResult?.time !== undefined) {
      const n1 = this.selectable.node;
      const n2 = this.selectable.nextNode;
      const anchors = [n1, n2].map((node) => globalState.traceForNode(node)?.result);
      if (!anchors.every((anchor) => anchor instanceof Anchor)) return;

      const segmentPath = new Path(anchors as Anchor[]);
      const newAnchor = segmentPath.insertAnchorAtTime(closestResult.time);
      if (newAnchor) {
        // An anchor was inserted.
        const pathNode = n1.parent;
        if (pathNode) {
          const { project } = globalState;
          const [a1, a2, a3] = segmentPath.anchors;

          n1.source.base.args.handleOut = new Expression(expressionCodeForVec(a1.handleOut));
          n2.source.base.args.handleIn = new Expression(expressionCodeForVec(a3.handleIn));

          const anchorElem = project.createElementWithDefinition(AnchorDefinition);
          anchorElem.base.args.position = new Expression(expressionCodeForVec(a2.position));
          anchorElem.base.args.handleIn = new Expression(expressionCodeForVec(a2.handleIn));
          anchorElem.base.args.handleOut = new Expression(expressionCodeForVec(a2.handleOut));
          anchorElem.base.args.handleConstraint = new Expression(
            a2.hasZeroHandles() ? `"free"` : `"tangent"`
          );

          const index = n1.indexInParent() + 1;
          const nodes = project.spliceNodes([], [anchorElem], pathNode, index);

          globalState.project.selectNodes(nodes);

          startSelectionDrag(event, globalState.project.selection);
        }
      }
    }
  }
}

const blackColor = new Color(0, 0, 0, 1);

export class FocusedPathSegmentsUI<CustomPathSegmentUIClass extends PathSegmentUI>
  implements CanvasInterfaceElement
{
  children: CanvasInterfaceElement[] = [];

  constructor(
    CustomPathSegmentUI: {
      new (selectable: SelectableSegment, invert: boolean): CustomPathSegmentUIClass;
    },
    previewAnchorNode?: Node,
    isClosed?: boolean
  ) {
    if (!(globalState.project.focus instanceof ComponentFocus)) return;
    const { node } = globalState.project.focus;
    if (node.isPath()) {
      const trace = globalState.traceForNode(node);
      if (isClosed === undefined) {
        isClosed = Boolean(trace?.base.args.closed?.evaluationResult);
      }
      const invert = Boolean(
        trace?.result instanceof Path &&
          trace.result.stroke &&
          !trace.result.stroke.hairline &&
          trace.result.stroke.color.equals(blackColor)
      );
      const childNodes = node.childNodes();
      for (let [node1, node2] of pairs(childNodes, isClosed)) {
        if (
          previewAnchorNode &&
          (previewAnchorNode.equalsNode(node1) || previewAnchorNode.equalsNode(node2))
        ) {
          continue;
        }
        if (node1.isAnchor() && node2.isAnchor()) {
          const selectable = new SelectableSegment(node1, node2);
          this.children.push(new CustomPathSegmentUI(selectable, invert));
        }
      }
    }
  }
}
