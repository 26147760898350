import { Anchor, CubicSegment, LineSegment, Path, Vec, approximatelyZero, mix, pairs } from "..";
import { degreesFromRadians } from "../env/trig";
import { axisAxisIntersection } from "./intersection";

const CUBIC_SEGMENT_DIVISIONS = 32;
const FILLET_INFO_MAX_STEPS = 500;

interface FilletInfo {
  time1: number;
  time2: number;
  radius: number;
}

interface FilletSplice {
  path: Path;
  time1: number;
  time2: number;
}

const filletInfosForSegments = (
  s1: LineSegment | CubicSegment,
  s2: LineSegment | CubicSegment,
  radius: number
): FilletInfo[] | undefined => {
  if (s1.isTangentToNext(s2)) return undefined;

  // Reverse the first segment to make the algorithm a bit simpler
  s1 = s1.clone().reverse();

  const infos: FilletInfo[] = [
    // Always start with a zero-radius fillet info
    {
      time1: 0,
      time2: 0,
      radius: 0,
    },
  ];

  const d1 = s1.tangentAtTime(0);
  const d2 = s2.tangentAtTime(0);

  if (s1 instanceof LineSegment && s2 instanceof LineSegment) {
    // If both adjacent segments are linear we can compute the fillet
    // analytically.
    const angle = 0.5 * Math.acos(d1.dot(d2));
    const tanAngle = Math.abs(Math.tan(angle));
    let d = radius / tanAngle; // Distance from the corner

    const len1 = s1.length();
    const len2 = s2.length();
    d = Math.min(d, len1, len2);

    infos.push({
      time1: d / len1,
      time2: d / len2,
      radius: tanAngle * d,
    });
    return infos;
  }

  const isClockwise = d2.isClockwiseFrom(d1);

  let cursor1 = 1;
  let cursor2 = 1;
  let prevCursor1 = 0;
  let prevCursor2 = 0;
  let prevLength1 = 0;
  let prevLength2 = 0;
  let prevRadius = 0;

  for (let i = 0; i < FILLET_INFO_MAX_STEPS; i++) {
    let time1 = cursor1 / CUBIC_SEGMENT_DIVISIONS;
    let time2 = cursor2 / CUBIC_SEGMENT_DIVISIONS;
    let p1 = s1.positionAtTime(time1);
    let p2 = s2.positionAtTime(time2);
    let n1 = s1.normalAtTime(time1);
    let n2 = s2.normalAtTime(time2).negate();
    if (!isClockwise) {
      n1.negate();
      n2.negate();
    }

    const intersection = axisAxisIntersection(p1, n1, p2, n2);
    if (!intersection) break;

    const length1 = intersection.time1;
    const length2 = intersection.time2;

    if (
      (prevLength1 !== 0 || prevLength2 !== 0) &&
      length1 > length2 !== prevLength1 > prevLength2
    ) {
      const a = prevLength1 - prevLength2;
      const b = length1 - length2;
      const bias = -a / (-a + b);

      time1 = mix(prevCursor1, cursor1, bias) / CUBIC_SEGMENT_DIVISIONS;
      time2 = mix(prevCursor2, cursor2, bias) / CUBIC_SEGMENT_DIVISIONS;
      p1 = s1.positionAtTime(time1);
      p2 = s2.positionAtTime(time2);
      n1 = s1.normalAtTime(time1);
      n2 = s2.normalAtTime(time2).negate();
      if (!isClockwise) {
        n1.negate();
        n2.negate();
      }

      const r = mix(prevLength1 + prevLength2, length1 + length2, bias) / 2;
      if (r < prevRadius) {
        // Don't try to handle corners that start to converge again
        break;
      }

      const e1 = n1.clone().mulScalar(r).add(p1);
      const e2 = n2.clone().mulScalar(r).add(p2);

      if (e1.distanceSquared(e2) < 0.001) {
        // We found a good (t1, t2, radius) triple!
        infos.push({ time1, time2, radius: r });

        // Early out if we've passed our desired radius
        if (r >= radius) break;

        prevRadius = r;
      }
    }

    prevLength1 = length1;
    prevLength2 = length2;
    prevCursor1 = cursor1;
    prevCursor2 = cursor2;

    // Advance the length that's longer
    if (length1 > length2) {
      cursor1++;
      if (cursor1 > CUBIC_SEGMENT_DIVISIONS) break;
    } else {
      cursor2++;
      if (cursor2 > CUBIC_SEGMENT_DIVISIONS) break;
    }
  }

  return infos;
};

const intersectionBetweenFilletInfos = (infos1: FilletInfo[], infos2: FilletInfo[]) => {
  // Intersect fillet info graphs using Path intersection code. This could be
  // made more efficient by stepping monotonically through the two graphs (along
  // radius). In the meantime this does the job and is at least concise.
  const path1 = new Path(infos1.map((info) => new Anchor(new Vec(info.time2, info.radius))));
  path1.anchors.push(new Anchor(new Vec(infos1[infos1.length - 1].time2, 9999)));
  const path2 = new Path(infos2.map((info) => new Anchor(new Vec(1 - info.time1, info.radius))));
  path2.anchors.push(new Anchor(new Vec(1 - infos2[infos2.length - 1].time1, 9999)));
  const intersections = path1.intersectionsWith([path2]);
  if (intersections.length > 0) {
    return {
      time: intersections[0].position.x,
      radius: intersections[0].position.y,
    };
  }
  return undefined;
};

// NOTE: Tangents (`tan1` and `tan2`) are expected to be normalized.
const arcPathFromTangents = (point1: Vec, point2: Vec, tan1: Vec, tan2: Vec) => {
  const intersection = axisAxisIntersection(point1, tan1, point2, tan2);
  if (!intersection || intersection.time1 < 0 || intersection.time2 < 0) return undefined;

  let { time1, time2 } = intersection;
  let startTime1 = 0;
  let pos1 = point1;
  if (time1 > time2) {
    startTime1 = intersection.time1 - intersection.time2;
    pos1 = tan1.clone().mulScalar(startTime1).add(point1);
  }

  const isClockwise = tan2.isClockwiseFrom(tan1);

  const angle = Math.acos(tan1.dot(tan2));
  const radius = Math.tan(angle * 0.5) * (time1 - startTime1);
  const norm1 = tan1.clone();
  if (isClockwise) {
    norm1.rotate90();
  } else {
    norm1.rotateNeg90();
  }
  const center = norm1.clone().negate().mulScalar(radius).add(pos1);

  let angle1 = pos1.clone().sub(center).angleRadians();
  let angle2: number;
  if (isClockwise) {
    angle2 = angle1 + angle - Math.PI;
  } else {
    angle2 = angle1 - angle + Math.PI;
  }

  const path = Path.fromArc(center, radius, degreesFromRadians(angle1), degreesFromRadians(angle2));

  // Add a small segment connecting the arc to the other point.
  if (time1 > time2) {
    // Prevent adding very small segments when `time1` and `time2` are very
    // close.
    if (!approximatelyZero(time1 - time2)) {
      path.anchors.unshift(new Anchor(point1));
    }
  } else if (time2 > time1) {
    if (!approximatelyZero(time2 - time1)) {
      path.anchors.push(new Anchor(point2));
    }
  }

  return path;
};

export const filletPath = (path: Path, radius: number | number[]) => {
  const { anchors, closed } = path;

  // A path needs at least 2 anchors to have a corner if closed, 3 if open.
  if (closed && anchors.length < 2) return;
  if (!closed && anchors.length < 3) return;

  let radii: number[];
  if (typeof radius === "number") {
    if (radius <= 0) return;
    radii = [radius];
  } else {
    radii = radius;
  }

  const segmentPairs = pairs(path.segments(), closed);
  const filletInfos = segmentPairs.map(([s1, s2], i) => {
    const targetRadius = radii[i % radii.length];
    if (targetRadius <= 0) return undefined;
    return filletInfosForSegments(s1, s2, targetRadius);
  });
  const filletInfosPairs = pairs(filletInfos, closed);
  const intersections = filletInfosPairs.map(([infos1, infos2], i) => {
    if (infos1 === undefined || infos2 === undefined) return undefined;
    return intersectionBetweenFilletInfos(infos1, infos2);
  });

  const splices: FilletSplice[] = [];
  for (let i = 0; i < filletInfos.length; ++i) {
    const infos = filletInfos[i];
    if (infos === undefined) continue;

    // We need at least 2 fillet infos (first should be radius = 0)
    if (infos.length < 2) continue;

    const lastInfo = infos[infos.length - 1];
    let prevFilletRadius = lastInfo.radius;
    let nextFilletRadius = lastInfo.radius;
    let prevFilletTime = lastInfo.time1;
    let nextFilletTime = lastInfo.time2;

    // Check intersection with the previous corner
    let prevIntersection = intersections[path.normalizedTime(i - 1)];
    if (prevIntersection && prevIntersection.radius < prevFilletRadius) {
      prevFilletRadius = prevIntersection.radius;
      prevFilletTime = 1 - prevIntersection.time;
    }

    // Check intersection with the previous corner
    const nextIntersection = intersections[i];
    if (nextIntersection && nextIntersection.radius < nextFilletRadius) {
      nextFilletRadius = nextIntersection.radius;
      nextFilletTime = nextIntersection.time;
    }

    // Clamp both sides to the smallest radius
    let targetRadius = radii[i % radii.length];
    let filletRadius = Math.min(targetRadius, prevFilletRadius, nextFilletRadius);
    if (filletRadius < prevFilletRadius || filletRadius < nextFilletRadius) {
      let j = infos.length - 1;
      let info1: FilletInfo;
      let info2 = infos[j];
      while (--j >= 0) {
        info1 = infos[j];
        if (info1.radius < filletRadius) {
          const t = (filletRadius - info1.radius) / (info2.radius - info1.radius);
          const prevTime = mix(info1.time1, info2.time1, t);
          const nextTime = mix(info1.time2, info2.time2, t);
          prevFilletTime = Math.min(prevFilletTime, prevTime);
          nextFilletTime = Math.min(nextFilletTime, nextTime);
          break;
        }
        info2 = info1;
      }
    }

    // Finally un-reverse the previous segment time (it was previously reversed when computing fillet info)
    prevFilletTime = 1 - prevFilletTime;

    const [s1, s2] = segmentPairs[i];
    const prevPosition = s1.positionAtTime(prevFilletTime);
    const prevTangent = s1.tangentAtTime(prevFilletTime);
    const nextPosition = s2.positionAtTime(nextFilletTime);
    const nextTangent = s2.tangentAtTime(nextFilletTime).negate();

    const filletPath = arcPathFromTangents(prevPosition, nextPosition, prevTangent, nextTangent);
    if (!filletPath) continue;

    splices.push({
      path: filletPath,
      time1: prevFilletTime + i,
      time2: nextFilletTime + i + 1,
    });
  }

  path.splice(splices);
};
