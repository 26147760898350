import { assert, scaleFactorForUnitConversion, Vec } from "../geom";
import { globalState } from "../global-state";
import { FillDefinition, StrokeDefinition } from "../model/builtin-primitives";
import { ImageFrameDefinition } from "../model/builtin-shapes";
import { CodeComponent, Component } from "../model/component";
import { CodeComponentFocus, DocumentationFocus, SettingsFocus } from "../model/focus";
import { Instance, InstanceArgs } from "../model/instance";
import { Node } from "../model/node";
import { isBuiltin } from "../model/registry";
import { Selection } from "../model/selection";
import { PortableProjectDataSnapshot } from "../model/snapshot";
import { NodeTransformer, SelectionTransformer, Transformer } from "../model/transformer";
import { pastePortableProjectData } from "./paste-portable-project-data";
import { startDrag } from "./start-drag";
import { toastState } from "./toast-message";

export interface StartDefinitionDragOptions {
  definitionDrag: DefinitionDrag | GutsDrag;
  onConsummate?: (event: PointerEvent) => void;
  onUp?: (event: PointerEvent) => void;
  onCancel?: (event: PointerEvent) => void;
}

export const startDefinitionDrag = (
  downEvent: PointerEvent,
  { definitionDrag, onConsummate, onUp, onCancel }: StartDefinitionDragOptions
) => {
  // Set `definitionDrag` before the drag is consummated. This prevents a race
  // condition that results in `definitionDrag` not being set when the canvas UI
  // event handlers are called, since they are created when the app starts and
  // come first.
  globalState.definitionDrag = definitionDrag;
  startDrag(downEvent, {
    cursor() {
      return "grabbing";
    },
    onConsummate: (event: PointerEvent) => {
      if (globalState.definitionDrag) {
        globalState.definitionDrag.isConsummated = true;
      }
      onConsummate?.(event);
    },
    onUp: (upEvent: PointerEvent) => {
      onUp?.(upEvent);
      globalState.definitionDrag = undefined;
    },
    onCancel: (event: PointerEvent) => {
      onCancel?.(event);
      globalState.definitionDrag = undefined;
    },
  });
};

export class DefinitionDrag {
  definition: Component | CodeComponent;
  args?: InstanceArgs;

  hoveredInsertionIndex?: number;

  /** The created instance that we're dragging in the canvas. */
  node?: Node;
  nodeTransformer?: NodeTransformer;
  message?: string;

  autoScaleVec?: Vec;

  isConsummated?: boolean;

  constructor(definition: Component | CodeComponent, args?: InstanceArgs) {
    this.definition = definition;
    this.args = args;
  }

  transformer(): Transformer | undefined {
    return this.nodeTransformer;
  }

  parentNode(): Node | undefined {
    return this.node?.parent ?? undefined;
  }

  initialScale(): number | "auto" {
    let scale: number | "auto" = 1;
    if (this.definition === ImageFrameDefinition) {
      scale = scaleFactorForUnitConversion("px", globalState.project.settings.units);
    } else if (this.definition.isAutoScale) {
      scale = "auto";
    }
    return scale;
  }

  tryInsert(insertionParent: Node, insertionIndex?: number) {
    if (!this.isConsummated) return false;

    const { project } = globalState;
    const { definition, args } = this;

    if (project.focus instanceof DocumentationFocus) {
      this.message = "Can't insert into the Read Me";
      return false;
    }
    if (project.focus instanceof SettingsFocus) {
      this.message = "Can't insert into settings";
      return false;
    }
    if (project.focus instanceof CodeComponentFocus) {
      this.message = "Can't insert into a code component";
      return false;
    }

    if (this.definition === project.focus.component) {
      // Note: this won't prevent mutual recursion which will break!
      this.message = "Can't insert Component into itself.";
      return false;
    }
    if (project.focus.node.isImmutable() || project.focus.node.isImmutableChildren()) {
      this.message = "Can't insert into a built-in Component";
      return false;
    }

    const element = project.createElementWithDefinition(definition);

    if (this.args) {
      for (let name in args) {
        element.base.args[name] = this.args[name];
      }
    }

    // Add a default fill or stroke to builtin shapes
    if (isBuiltin(definition)) {
      if (definition instanceof Component || definition instanceof CodeComponent) {
        // Most built-ins get the default stroke
        if (definition.defaultStyle === "stroke") {
          element.stroke = new Instance(StrokeDefinition);
        } else if (definition.defaultStyle === "fill") {
          element.fill = new Instance(FillDefinition);
        }
      }
    } else if (definition instanceof CodeComponent) {
      // Add a default stroke to Code Components with no internal style
      const trace = globalState.traceForComponent(definition);
      if (trace?.result && !trace.result.hasStyle()) {
        element.stroke = new Instance(StrokeDefinition);
      }
    }

    const [node] = project.spliceNodes([], [element], insertionParent, insertionIndex);

    this.node = node;
    this.nodeTransformer = new NodeTransformer(node);

    project.selectNode(node);

    return true;
  }

  remove() {
    if (this.node) {
      globalState.project.spliceNodes([this.node]);
    }
    this.node = undefined;
    this.nodeTransformer = undefined;
    this.message = undefined;
  }
}

export class GutsDrag {
  snapshotString: string;
  isAutoScale: boolean;

  /** The first created instance that we're dragging in the canvas. */
  node?: Node;

  // What we get back from `pastePortableProjectData`
  insertionParent?: Node;
  selectionTransformer?: SelectionTransformer;

  message?: string;

  isConsummated?: boolean;

  constructor(snapshotString: string, isAutoScale: boolean) {
    this.snapshotString = snapshotString;
    this.isAutoScale = isAutoScale;
  }

  parentNode() {
    return this.insertionParent;
  }

  initialScale() {
    if (this.isAutoScale) {
      return "auto";
    }
    return undefined;
  }

  transformer() {
    return this.selectionTransformer;
  }

  tryInsert(insertionParent: Node, insertionIndex?: number) {
    const snap = PortableProjectDataSnapshot.fromString(this.snapshotString);
    assert(snap);

    const { project } = globalState;
    // Focus the insertion parent and select the element just before the
    // insertion index so that the paste happens at the right location.
    project.focusNode(insertionParent);
    if (insertionIndex !== undefined && insertionIndex > 0) {
      assert(insertionParent.childCount() >= insertionIndex);
      project.selectNode(insertionParent.childNodeAtIndex(insertionIndex - 1));
    }

    const portableProjectData = snap.toPortableProjectData();
    assert(portableProjectData);

    // Rename existing mutable components, if there is a name conflict.
    for (const pastingComponent of portableProjectData.components) {
      for (const projectComponent of project.components) {
        const projectComponentName = projectComponent.name;
        if (projectComponentName === pastingComponent.name && !projectComponent.isImmutable) {
          const definitionNameGenerator = project.makeDefinitionNameGenerator();
          // projectComponentName will be in the
          // definitionNameGenerator.existingNames hash already, so this should
          // return the next available version of the name.
          const newName = definitionNameGenerator.generate(projectComponentName);
          project.renameDefinition(projectComponent, newName);
          toastState.showBasic({
            type: "info",
            message: `Renamed your component “${projectComponentName}” to “${newName}”.`,
          });
        }
      }
    }

    // NOTE: Maybe `pastePortableProjectData` is the wrong function to use here
    // since it applied to pasting more than just elements, and it alse doesn't
    // allow the insertion parent and index to be specified.

    const selection = pastePortableProjectData(project, portableProjectData);
    // It's possible to paste components and code components, but this doesn't
    // apply here since guts are not supposed to contain those. We should always
    // get a selection back.
    assert(selection instanceof Selection);

    const nodes = selection.toNodes();
    if (nodes.length > 0) {
      this.node = nodes[0];
    }

    this.insertionParent = insertionParent;
    this.selectionTransformer = new SelectionTransformer(selection);
  }

  remove() {
    // NOTE: We assume that the global selection will be the contents of this
    // guts drag.
    globalState.deleteSelection();
  }
}
