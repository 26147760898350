import { Vec } from "../geom";
import { AnchorHandleConstraint } from "./builtin-primitives";
import { Element } from "./element";
import { Expression } from "./expression";
import { expressionCodeForVec } from "./expression-code";

const expressionForHandle = (
  handle: Vec,
  oppositeHandle: Vec,
  handleConstraint: AnchorHandleConstraint,
  minimumFractionDigits?: number
) => {
  if (handleConstraint === "mirror") {
    return new Expression(
      expressionCodeForVec(oppositeHandle.clone().negate(), minimumFractionDigits)
    );
  } else if (handleConstraint === "tangent") {
    const length = handle.length();
    return new Expression(
      expressionCodeForVec(
        oppositeHandle.clone().negate().normalize().mulScalar(length),
        minimumFractionDigits
      )
    );
  } else {
    return new Expression(expressionCodeForVec(handle, minimumFractionDigits));
  }
};

export const constrainAnchorHandles = (
  element: Element,
  handleConstraint: AnchorHandleConstraint,
  handleIn: Vec,
  constrainIn: boolean,
  handleOut: Vec,
  constrainOut: boolean,
  minimumFractionDigits?: number
) => {
  if (handleConstraint !== "free") {
    const { args } = element.base;
    if (constrainOut && !handleIn.isZero()) {
      args.handleOut = expressionForHandle(
        handleOut,
        handleIn,
        handleConstraint,
        minimumFractionDigits
      );
    } else if (constrainIn && !handleOut.isZero()) {
      args.handleIn = expressionForHandle(
        handleIn,
        handleOut,
        handleConstraint,
        minimumFractionDigits
      );
    }
  }
};
