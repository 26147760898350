import {
  AffineMatrix,
  BoundingBox,
  DistanceToIntersectOptions,
  GeometryPrimitive,
  TransformArgs,
  Vec,
} from "..";
import { distanceToIntersect } from "../op/distance-to-intersect";
import { geometryIntersections, partitionedGeometryIntersections } from "../op/intersection";
import { geometryOverlaps, partitionedGeometryOverlaps } from "../op/overlap";

/**
 * The base class for anything that is representable on the canvas. Geometry
 * types must support any affine transformation.
 */
export abstract class Geometry {
  /**
   * @returns `true` if this geometry is valid, or `false` otherwise.
   */
  abstract isValid(): boolean;

  /**
   * Clone is useful when you need to make a change to geometry without changing
   * the original.
   *
   * ```
   * const transformedPath = path.clone().transform({ rotation: 45 });
   * ```
   *
   * @returns A deep copy of this geometry
   */
  abstract clone(): Geometry;

  /**
   * @returns The closest point to `point` that lies on this geometry, or
   * `undefined` if no point is found.
   *
   * @param point The target point
   * @param areaOfInterest If supplied, only results inside this bounding box
   * will be returned. This can save a lot of computation if you only need to
   * find closest points within a small area. Typically `areaOfInterest` is
   * centered on `point`, but this isn't required.
   */
  abstract closestPoint(
    point: Vec,
    areaOfInterest?: BoundingBox
  ): ClosestPointResult | ClosestPointResultWithTime | undefined;

  /**
   * Spatially transforms this geometry by an affine transformation matrix.
   *
   * Affine matrices can represent any 2-dimensional transformation that keeps
   * lines parallel.
   *
   * @chainable
   */
  abstract affineTransform(affineMatrix: AffineMatrix): this;

  /**
   * Spatially transforms this geometry by an affine transformation matrix,
   * excluding translation. Only rotation, scale, and skew transformations will
   * be applied.
   *
   * @chainable
   */
  abstract affineTransformWithoutTranslation(affineMatrix: AffineMatrix): this;

  /**
   * Transforms this geometry.
   *
   * A transform can optionally specify any of `position`, `rotation`, `scale`,
   * `skew` and `origin`.
   *
   * `origin` defines the center (in pre-transform coordinates) of the
   * transformation for `rotation`, `scale` and `skew`.
   *
   * ```
   * // Simple translation
   * geometry.translate({
   *   position: Vec(1, 0),
   * });
   *
   * // Rotation and scale
   * geometry.transform({
   *   rotation: 45,
   *   scale: 2,
   * });
   *
   * // Complicated transform
   * geometry.transform({
   *   position: Vec(1, 1),
   *   rotation: 180,
   *   scale: Vec(2, 1),
   *   skew: 45,
   *   origin: Vec(-0.5, 0.5),
   * };
   * ```
   *
   * @chainable
   */
  transform(transform: TransformArgs): this {
    return this.affineTransform(AffineMatrix.fromTransform(transform));
  }

  /**
   * Reverses this geometry.
   *
   * @chainable
   */
  reverse(): Geometry {
    return this;
  }

  /**
   * @returns the smallest axis-aligned bounding box that contains this geometry.
   */
  boundingBox(): BoundingBox | undefined {
    return undefined;
  }

  /**
   * The loose bounding box may not be the smallest possible, but it's usually
   * cheaper to compute. Use this when the exact bounding box isn't required.
   *
   * @returns an axis-aligned bounding box that contains this geometry.
   */
  looseBoundingBox(): BoundingBox | undefined {
    return undefined;
  }

  /**
   * Geometry is contained by a bounding box if no part of it lies beyond it's
   * minimum and maximum.
   */
  isContainedByBoundingBox(box: BoundingBox): boolean {
    return false;
  }
  /**
   * Geometry intersects a bounding box if part of the geometry crosses the
   * boundary between the inside and outside of the box.
   */
  isIntersectedByBoundingBox(box: BoundingBox): boolean {
    return false;
  }
  /**
   * Geometry is overlapped by a bounding box if a point can be chosen that is
   * indside both the geometry and the box.
   *
   * Geometry is overlapped by a bounding box if it's contained inside, or
   * intersected by it.
   */
  isOverlappedByBoundingBox(box: BoundingBox): boolean {
    return false;
  }

  _primitives(): GeometryPrimitive[] {
    return [];
  }

  /**
   * @param geometries An array of geometries to intersect with.
   * @param areaOfInterest If supplied, only intersection results inside this
   * bounding box will be returned. This can save a lot of computation if you
   * only need to find intersections within a small area.
   * @returns An array of intersections between this geometry and `geometries`
   */
  intersectionsWith(geometries: Geometry[], areaOfInterest?: BoundingBox) {
    return partitionedGeometryIntersections([this], geometries, areaOfInterest);
  }

  /**
   * @param geometries An array of geometries to find overlaps with.
   * @param areaOfInterest If supplied, input geometry will be filtered so that
   * only parts that intersect this bounding box will be tested. This can save a
   * lot of time if only need to find overlaps within a small area.
   * @param tolerance The maximum distance apart two segments can be to be
   * considered overlapping.
   * @returns An array of overlaps between this geometry and `geometries`
   */
  overlapsWith(geometries: Geometry[], areaOfInterest?: BoundingBox, tolerance?: number) {
    return partitionedGeometryOverlaps([this], geometries, areaOfInterest, tolerance);
  }

  /**
   * @param targetGeometry The geometry to move toward.
   * @param direction The direction to move in. This should point towards the
   * target geometry, otherwise no intersection may be found.
   *
   * @returns approximately the smallest distance to move until this geometry
   * intersects the target geometry.
   *
   * If `minOverlap` is specified, the distance to move until the geometry
   * overlaps by some minimum width will be returned instead.
   *
   * Returns `undefined` if no intersection is found.
   */
  distanceToIntersect(
    targetGeometry: Geometry,
    direction: Vec,
    options?: DistanceToIntersectOptions
  ) {
    return distanceToIntersect(this, targetGeometry, direction, options);
  }

  /**
   * Finds intersections between all geometries in the array, including
   * self-intersections.
   *
   * @param geometries An array of geometries to intersect
   * @param areaOfInterest If supplied, only intersection results inside this
   * bounding box will be returned. This can save a lot of computation if you
   * only need to find intersections within a small area.
   * @returns An array of intersections
   */
  static intersectionsBetweenAll(geometries: Geometry[], areaOfInterest?: BoundingBox) {
    return geometryIntersections(geometries, areaOfInterest);
  }

  /**
   * Finds intersections between two sets of geometries. This won't find
   * self-intersections or intersections within the two sets.
   *
   * @param geometries1 The first geometry array to intersect
   * @param geometries2 The second geometry array to intersect
   * @param areaOfInterest If supplied, only intersection results inside this
   * bounding box will be returned. This can save a lot of computation if you
   * only need to find intersections within a small area.
   * @returns An array of intersections
   */
  static intersectionsBetweenSets(
    geometries1: Geometry[],
    geometries2: Geometry[],
    areaOfInterest?: BoundingBox
  ) {
    return partitionedGeometryIntersections(geometries1, geometries2, areaOfInterest);
  }

  /**
   * Finds overlaps between all geometries in the array.
   *
   * @param geometries An array of geometries to find overlaps between.
   * @param areaOfInterest If supplied, input geometry will be filtered so that
   * only parts that intersect this bounding box will be tested. This can save a
   * lot of time if only need to find overlaps within a small area.
   * @param tolerance The maximum distance apart two segments can be to be
   * considered overlapping.
   * @returns An array of overlaps
   */
  static overlapsBetweenAll(
    geometries: Geometry[],
    areaOfInterest?: BoundingBox,
    tolerance?: number
  ) {
    return geometryOverlaps(geometries, areaOfInterest, tolerance);
  }

  /**
   * Finds overlaps between two sets of geometry. This won't find overlaps
   * within the two sets.
   *
   * @param geometries1 The first set of geometries
   * @param geometries2 The second set of geometries
   * @param areaOfInterest If supplied, input geometry will be filtered so that
   * only parts that intersect this bounding box will be tested. This can save a
   * lot of time if only need to find overlaps within a small area.
   * @param tolerance The maximum distance apart two segments can be to be
   * considered overlapping.
   * @returns As array of overlaps between the two sets of geometry
   */
  static overlapsBetweenSets(
    geometries1: Geometry[],
    geometries2: Geometry[],
    areaOfInterest?: BoundingBox,
    tolerance?: number
  ) {
    return partitionedGeometryOverlaps(geometries1, geometries2, areaOfInterest, tolerance);
  }

  /**
   * @returns `true` if `geom` is valid geometry
   */
  static isValid(geom: unknown): geom is Geometry {
    if (geom instanceof Geometry) return geom.isValid();
    return false;
  }
}

/**
 * The return type for geometry `closestPoint` queries.
 */
export interface ClosestPointResult {
  /**
   * The position of the closest point.
   */
  position: Vec;
}

/**
 * Return type for geometry `closestPoint` queries on types addressable by time,
 * such as paths, segments, and axes.
 */
export interface ClosestPointResultWithTime extends ClosestPointResult {
  /**
   * Contains a reference to the geometry that `time` applies to. This is
   * necessary when finding a closest point on a group or compound path, since
   * `time` will apply to one of the sub-paths.
   */
  geometry?: Geometry;

  /**
   * The position of the closest point, expressed as `time` along the path,
   * segment or axis.
   */
  time: number;
}
