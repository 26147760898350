import { Vec, isNumber } from "..";
import { isValidUnit, units, valueByUnitConversion } from "../io/units";

export const convertUnits = (value: unknown, sourceUnit: unknown, targetUnit: unknown) => {
  if (!(isNumber(value) || Vec.isValid(value))) {
    throw new Error("Value must be a number or Vec.");
  }
  if (!isValidUnit(sourceUnit)) {
    throw new Error(`Source unit must be one of ${units.map((u) => `"${u}"`).join(", ")}`);
  }
  if (!isValidUnit(targetUnit)) {
    throw new Error(`Target unit must be one of ${units.map((u) => `"${u}"`).join(", ")}`);
  }
  return valueByUnitConversion(value, sourceUnit, targetUnit);
};
