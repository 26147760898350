import seedrandom from "seedrandom";

import { TAU, Vec } from "..";

/**
 * A random generator with a specific seed.
 */
export class RandomGenerator {
  private _rng: seedrandom.prng;

  /**
   * Constructs a random generator with a specific `seed`. If no seed is
   * specified, a random seed will be used.
   */
  constructor(seed?: string) {
    this._rng = seedrandom(seed);
  }

  /**
   * Sets the seed of the random generator. This resets the random number
   * sequence.
   */
  seed(seed?: string) {
    this._rng = seedrandom(seed);
  }

  /**
   * See `random()`.
   */
  random(min?: number, max?: number) {
    if (max === undefined) {
      max = min === undefined ? 1 : min;
      min = 0;
    } else if (min === undefined) {
      min = 0;
    }

    return min + this._rng() * (max - min);
  }

  /**
   * See `randomInt()`.
   */
  randomInt(min?: number, max?: number) {
    if (max === undefined) {
      max = min === undefined ? 0 : min;
      min = 0;
    } else if (min === undefined) {
      min = 0;
    }

    min = min | 0;
    max = max | 0;

    // Return early to prevent returning a raw rng int32 later.
    if (min === max) return min;

    const integer = Math.abs(this._rng.int32());
    if (max > 0) {
      return min + (integer % (max - min + 1));
    }
    return integer;
  }

  /**
   * See `randomDirection()`.
   */
  randomDirection(length = 1) {
    return new Vec(length, 0).rotateRadians(this.random(TAU));
  }

  /**
   * See `randomPointInDisk()`.
   */
  randomPointInDisk(radius = 1) {
    return this.randomDirection(radius * Math.sqrt(this._rng()));
  }
}

const globalRandomGenerator = new RandomGenerator();

export const _seedGlobalRandom = (seed: string) => globalRandomGenerator.seed(seed);

/**
 * @returns a random number between `min` and `max`.
 *
 * The range of possible values is inclusive, so it's possible to get `min` or
 * `max` back! Random numbers are distributed uniformally, meaning it's just as
 * likely you'll get any number in the range.
 *
 * There are several ways to call `random()` that have different effects:
 *
 * ```
 * random();
 * // A random number between 0 and 1
 * // e.g. 0.2999…
 *
 * random(5);
 * // A random number between 0 and 5
 * // e.g. 1.4993…
 *
 * random(3, 5);
 * // A random number between 3 and 5
 * // e.g. 3.5997…
 * ```
 *
 * @param min The minimum possible number
 * @param max The maximum possible number
 */
export const random = (min?: number, max?: number) => globalRandomGenerator.random(min, max);

/**
 * @returns a random integer between `min` and `max`.
 *
 * This function is similar to `random()`, and can be called in the same way.
 * The only difference is that it will only return integer values.
 *
 * ```
 * randomInt(10);
 * // A random integer between 0 and 10 (inclusive)
 * // e.g. 4
 *
 * randomInt(3, 5);
 * // A random integer between 3 and 5 (inclusive)
 * // e.g. 4
 * ```
 *
 * @param min The minimum possible integer
 * @param max The maximum possible integer
 */
export const randomInt = (min?: number, max?: number) => globalRandomGenerator.randomInt(min, max);

/**
 * @returns a random vector of `length`.
 *
 * ```
 * randomDirection();
 * // A random vector of unit length
 * // e.g. Vec(-0.3082…, 0.9513…)
 *
 * randomDirection(10);
 * // A random vector of length 10
 * // e.g. Vec(-3.0822…, 9.5132…)
 * ```
 *
 * @param length The length of the random vector. If unspecified, the vector
 * will be of unit length.
 */
export const randomDirection = (length?: number) => globalRandomGenerator.randomDirection(length);

/**
 * @returns a random vector contained within a circle of `radius`.
 *
 * ```
 * randomPointInDisk();
 * // A random vector contained within a circle of radius 1
 * // e.g. Vec(-0.5466…, 0.0334…)
 *
 * randomPointInDisk(10);
 * // A random vector contained within a circle of radius 10
 * // e.g. Vec(-5.4658…, 0.3345…)
 * ```
 *
 * @param radius The maximum possible length of the random vector. If
 * unspecified, the maximum length will be 1.
 */
export const randomPointInDisk = (radius?: number) => {
  return globalRandomGenerator.randomPointInDisk(radius);
};
