import m from "mithril";

import { assert } from "../geom";
import { globalState } from "../global-state";
import type { ExportOptions } from "../io/export-options";
import { isValidPostprocessSVG } from "../model/export-format";
import { modalState } from "../shared/modal";
import { FilenameInput } from "./basic/filename-input";
import { Select, SelectOption } from "./basic/select";
import { checkUserCanExport } from "./export-check";
import { SimpleModal } from "./modal";
import { downloadFileWithExportOptionsAndErrorToast } from "./export-utils";

const formatOptions: SelectOption[] = [
  { value: "svg", label: "SVG" },
  { value: "png", label: "PNG" },
  { value: "pdf", label: "PDF" },
  { value: "dxf", label: "DXF" },
];

const postprocessOptions: SelectOption[] = [
  { value: "none", label: "Standard SVG (default)" },
  { value: "glowforge", label: "Glowforge" },
  { value: "cricut", label: "Cricut" },
];

/**
 * Check the user can export and then if so, open a modal that allows the user
 * to customize export options.
 */
export const openExportOptionsModal = async (
  exportOptions: ExportOptions[] /** Will be mutated by this flow. */
) => {
  // Ask the user to log or upgrade to export.
  if (!(await checkUserCanExport())) return;

  // Open the modal.
  modalState.open({
    modalView: () => m(ExportOptionsModal, { exportOptions }),
  });
};

interface ExportOptionsModalAttrs {
  exportOptions: ExportOptions[];
}
const ExportOptionsModal: m.Component<ExportOptionsModalAttrs> = {
  view({ attrs: { exportOptions } }) {
    const hasSVG = exportOptions.some((options) => options.format === "svg");
    return m(
      SimpleModal,
      {
        primaryAction: {
          label: "Download",
          action: () => downloadFileWithExportOptionsAndErrorToast(exportOptions),
        },
      },
      m(".editor-form", [
        exportOptions.map((options, index) => {
          return m(FilenameInput, {
            filename: options.filename,
            format: options.format,
            formatOptions,
            autoSelect: index === 0,
            onchange: (newFilename, newFormat) => {
              options.filename = newFilename;
              options.format = newFormat;
            },
          });
        }),
        hasSVG && m(SVGPostprocessSelect),
      ])
    );
  },
};

const SVGPostprocessSelect: m.Component<{}> = {
  view(vnode) {
    const value = globalState.deviceStorage.svgPostprocess;

    return m(".editor-form-key-values", [
      m(".editor-form-key", "Optimize for Device"),
      m(
        ".editor-form-value",
        m(Select, {
          options: postprocessOptions,
          selected: value,
          onchange: (newPostprocess) => {
            assert(isValidPostprocessSVG(newPostprocess));
            globalState.deviceStorage.svgPostprocess = newPostprocess;
          },
        })
      ),
      value === "glowforge" &&
        m(".editor-form-comment", [
          "Exports an SVG optimized for Glowforge.",
          m(
            "ul",
            m(
              "li",
              "Recolors paths so that inner paths will list before outer paths in the Glowforge interface. This lets you schedule inner cuts earlier in the cutting process."
            )
          ),
        ]),
      value === "cricut" &&
        m(".editor-form-comment", [
          "Exports an SVG optimized for Cricut Design Space.",
          m("ul", [
            m("li", `Groups will be ungrouped.`),
            m(
              "li",
              'All shapes of the same color will be "pre-attached" (combined into a Compound Path).'
            ),
            m("li", `Black will be replaced with gray (it shows up better in Design Space).`),
          ]),
        ]),
    ]);
  },
};
