import { Expression } from "./expression";
import { InstanceDefinition } from "./instance-definition";
import { ModelObject } from "./model-object";
import { Modifier } from "./modifier";
import { Parameter } from "./parameter";
import { registerClass } from "./registry";

export type InstanceArgs = { [name: string]: Expression };

export class Instance extends ModelObject {
  definition: InstanceDefinition;
  args: InstanceArgs = {};

  isEnabled = true;

  // This configures the name used for the index variable in repeat modifier
  // instances. If not specified here, `i` will be used. This value must be
  // sanitized when set from user input.
  repeatIndexVariableName: string | null = null;

  materialKeys() {
    return ["definition", "args", "isEnabled", "repeatIndexVariableName"];
  }
  indirectKeys(): string[] {
    return ["definition"];
  }

  constructor(definition: InstanceDefinition) {
    super();
    this.definition = definition;
  }

  clone() {
    const instance = new Instance(this.definition);
    instance.isEnabled = this.isEnabled;
    instance.repeatIndexVariableName = this.repeatIndexVariableName;
    for (let name in this.args) {
      instance.args[name] = this.args[name].clone();
    }
    return instance;
  }

  hasArgumentWithName(name: string) {
    return this.args.hasOwnProperty(name) && !this.args[name].isEmpty();
  }

  expressionForParameterWithName(name: string) {
    if (this.hasArgumentWithName(name)) return this.args[name];
    return this.definition.parameterWithName(name)?.expression;
  }

  allExpressions() {
    return Object.values(this.args);
  }

  isRepeatModifier() {
    return this.definition instanceof Modifier && Boolean(this.definition.isRepeat);
  }

  parametersInEvaluationOrder() {
    const parameters: Parameter[] = [];
    const path: string[] = [];
    const circularPaths: string[][] = [];
    const visitedNames: Set<string> = new Set();

    const addParameter = (parameter: Parameter) => {
      visitedNames.add(parameter.name);

      // Argument expressions can't reference other parameters, so we can ignore
      // references in them.
      if (!this.hasArgumentWithName(parameter.name)) {
        path.push(parameter.name);

        for (const { name } of parameter.expression.compiled().references) {
          // Self-references usually refer to a parameter with the same name
          // from an outer scope. Don't add those.
          if (name === parameter.name) continue;

          // Don't add references we've already visited.
          if (visitedNames.has(name)) {
            if (path.includes(name)) {
              // Found a circular reference
              circularPaths.push(path.slice());
            }
            continue;
          }

          // Add the referenced parameter.
          const refParameter = this.definition.parameterWithName(name);
          if (refParameter) {
            addParameter(refParameter);
          }
        }

        path.pop();
      }

      parameters.push(parameter);
    };

    for (const parameter of this.definition.allParameters()) {
      if (!visitedNames.has(parameter.name)) {
        addParameter(parameter);
      }
    }

    return { parameters, circularPaths };
  }
}
registerClass("Instance", Instance);
