import m from "mithril";

import { groupBy } from "../geom";
import { globalState } from "../global-state";
import type { Expression } from "../model/expression";
import { CodeEditorSelection } from "../model/focus";
import { ContextSelectable } from "../model/selectable";
import { ExpressionTrace } from "../model/trace";
import { Icon20 } from "../shared/icon";
import { Accelerator } from "../shared/keyboard";
import { Tooltipped } from "../shared/popup";
import { classNames } from "../shared/util";
import { CodeEditor, LineAnnotation } from "./code-editor";
import { ObjectInspector } from "./object-inspector";

interface ExpressionEditorAttrs {
  // Internally handles change events to be able to control when they are
  // committed. Other components can also edit the expression jsCode, for
  // example scrubbing a number pill.
  expression: Readonly<Expression>;
  expressionTrace?: ExpressionTrace;

  // Flags
  readOnly?: boolean;

  // Forward Force Selection and Focus
  forceSelections?: CodeEditorSelection[] | "all";
  forceFocused?: boolean;
  forceMultiline?: boolean;

  initialSelections?: CodeEditorSelection[] | "all";

  // Context for Logging
  selectable?: ContextSelectable;

  // Callbacks
  onBlur?: () => void;
  onFocus?: () => void;
  onChangeCode?: (jsCode: string) => void;
  onCommitCode?: () => void;
  onChangeSelection?: (selections: CodeEditorSelection[]) => void;
}

const hintWithType = (className: string) => (text: string) => ({ text, className });

const extraHints = [
  // TODO maybe: include closing paren, but place cursor inside
  "console.log(",
  "console.warn(",
  "console.error(",
  "console.geometry(",
  "console.guide(",
  "cuttle.project.units",
].map(hintWithType("code-hint-builtin code-hint-builtin-env"));

export const ExpressionEditor: m.ClosureComponent<ExpressionEditorAttrs> = () => {
  let latestCodeEditorSelections: CodeEditorSelection[] | undefined;
  return {
    view: ({
      attrs: {
        expression,
        expressionTrace,
        readOnly,
        forceSelections,
        forceFocused,
        forceMultiline,
        initialSelections,
        selectable,
        onBlur,
        onFocus,
        onChangeCode,
        onCommitCode,
        onChangeSelection,
      },
    }) => {
      const latestCode = expression.latestCode();
      const isMultiline = forceMultiline || latestCode.includes("\n");
      const confirmOnEnter =
        !forceMultiline && !shouldInsertNewLine(latestCode, latestCodeEditorSelections);
      const isUncommitted = !expression.isCommitted();

      // If the compiled expression is literal, but we type eg `if (p1 > 22) {` we
      // probably want to ignore confirmOnEnter because we want to type more.
      // const confirmOnEnter = !isMultiline && !expression.editingJsCode && expression.isLiteral();

      const codeHints = () => {
        const categorizedNames = globalState.project.categorizedNamesInExpressionScope(expression);
        return [
          // My things
          ...categorizedNames.parameters.map(hintWithType("code-hint-my code-hint-parameter")),
          ...categorizedNames.repeats.map(hintWithType("code-hint-my code-hint-parameter")),
          ...categorizedNames.components.map(hintWithType("code-hint-my code-hint-component")),
          ...categorizedNames.modifiers.map(hintWithType("code-hint-my code-hint-modifier")),
          // Built-in things
          ...categorizedNames.builtins.map(hintWithType("code-hint-builtin code-hint-builtin-def")),
          ...categorizedNames.units.map(hintWithType("code-hint-builtin code-hint-builtin-units")),
          ...categorizedNames.globals.map(hintWithType("code-hint-builtin code-hint-builtin-env")),
          ...extraHints,
        ];
      };

      const codeEditorOnChange = (jsCode: string) => {
        onChangeCode?.(jsCode);
      };
      const codeEditorOnFocus = () => {
        onFocus?.();
      };
      const codeEditorOnBlur = () => {
        onBlur?.();
      };
      const codeEditorOnSelection = (selections: CodeEditorSelection[]) => {
        latestCodeEditorSelections = selections;
        onChangeSelection?.(selections);
      };
      const codeEditorCommitCode = () => {
        onCommitCode?.();
      };

      // We don't need a click listener for this button, because focusing on it
      // will blur the code editor, which commits in-progress code
      const mCommitHint =
        isUncommitted && isMultiline
          ? m(
              ".expression-editor-commit",
              { tabIndex: 0 },
              m(
                Tooltipped,
                {
                  message: () => [
                    "Run Code",
                    m(
                      "span.tooltip-accelerator",
                      new Accelerator("Enter", { command: true }).toString()
                    ),
                  ],
                },
                m(Icon20, { icon: "play" })
              )
            )
          : undefined;

      return m(
        ".expression-editor",
        {
          className: classNames({ changed: isUncommitted }),
        },
        [
          m(CodeEditor, {
            value: latestCode,

            lineAnnotations: lineAnnotationsFromExpressionTrace(expressionTrace, selectable),
            readOnly,
            confirmOnEnter,

            forceSelections,
            forceFocused,

            initialSelections,

            onChange: codeEditorOnChange,
            onFocus: codeEditorOnFocus,
            onBlur: codeEditorOnBlur,
            onSelection: codeEditorOnSelection,
            onConfirm: codeEditorCommitCode,
            onCommandEnter: codeEditorCommitCode,
            onNumberScrub: codeEditorCommitCode,

            codeHints,
          }),
          mCommitHint,
        ]
      );
    },
  };
};

const lineAnnotationsFromExpressionTrace = (
  expressionTrace: ExpressionTrace | undefined,
  selectable?: ContextSelectable
): LineAnnotation[] => {
  if (!expressionTrace) return [];

  const messagesGroupedByLine = groupBy(expressionTrace.messages, (message) => message.line);
  if (messagesGroupedByLine.size < 1) return [];

  return Array.from(messagesGroupedByLine.values()).map((messages) => {
    return {
      line: messages[0].line ?? -1,
      view: () => {
        return m(
          ".expression-message-group",
          messages.map((message) => {
            const className = "expression-message " + message.logLevel;
            return m("div", { className }, [
              m(ObjectInspector, { object: message.message, selectable }),
            ]);
          })
        );
      },
    };
  });
};

const shouldInsertNewLine = (code: string, selections?: CodeEditorSelection[]) => {
  // Looks like you're writing some code.
  const re = /\n|;|\/\/|\/\*+|\b(?:var|let|const|return)\b/;
  if (re.test(code)) {
    return true;
  }

  if (selections) {
    // Always insert a newline when there are multiple selections.
    if (selections.length > 1) return true;

    if (selections.length === 1) {
      // If the cursor is preceded by certain characters like "[", "{" or "," it
      // looks like you're trying to type a multiline Vec, array or object.
      const selectionStart = Math.min(selections[0].anchor.ch, selections[0].head.ch);
      const codeBeforeSelection = code.slice(0, selectionStart);
      const re = /(?:\[|\(|{|,)\s*$/;
      if (re.test(codeBeforeSelection)) {
        return true;
      }
    }
  }

  return false;
};
