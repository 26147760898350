import { assert, stringForNumber } from "../geom";
import { SVGBuilder, SVGBuilderOptions } from "../geom/io/svg-builder";
import { imageManager } from "../image-manager";
import { escapeForXML, escapeHyphen } from "../util";
import { FramedGraphic } from "./framed-graphic";

export interface ExportSVGOptions extends SVGBuilderOptions {
  // Will go in `<title>...</title>` after the opening `<svg>`. It will be
  // escaped using `escapeForXML`.
  title?: string;

  // Will be a comment (`<!-- ... -->`) after the closing `</svg>`. It will be
  // escaped using `escapeHypen`.
  comment?: string;
}

/**
 * Prepares the inner part of an SVG string.
 */
export const bareSVGStringFromFramedGraphic = (
  framedGraphic: FramedGraphic<"pt" | "px">,
  options: ExportSVGOptions
) => {
  assert(
    framedGraphic.units === "pt" || framedGraphic.units === "px",
    "Framed graphics must be converted to points"
  );

  const builder = new SVGBuilder((url) => imageManager.getImageFromURL(url));
  builder.appendGraphic(framedGraphic.graphic, options);
  return builder.concatenatedResult();
};

/**
 * Prepares an export-ready SVG string.
 */
export const SVGStringFromFramedGraphic = (
  framedGraphic: FramedGraphic<"pt" | "px">,
  options: ExportSVGOptions
) => {
  const { maximumFractionDigits } = options;
  const svgString = bareSVGStringFromFramedGraphic(framedGraphic, options);

  const size = framedGraphic.artboard.size();
  const sizeXString = stringForNumber(size.x, maximumFractionDigits);
  const sizeYString = stringForNumber(size.y, maximumFractionDigits);
  const widthString = sizeXString + framedGraphic.units;
  const heightString = sizeYString + framedGraphic.units;
  const viewBoxString = `0 0 ${sizeXString} ${sizeYString}`;
  return [
    `<?xml version="1.0" encoding="UTF-8" standalone="no"?>`,
    `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="${widthString}" height="${heightString}" viewBox="${viewBoxString}">`,
    options.title && `<title>${escapeForXML(options.title)}</title>`,
    svgString,
    `</svg>`,
    options.comment && [`<!--`, escapeHyphen(options.comment), `-->`],
  ]
    .flat(Infinity)
    .filter((e) => e !== undefined)
    .join("\n")
    .trim();
};
