import pako from "pako";

import { UpgradeSnapshotOptions } from "../model/project-snapshot-upgrade";
import { APIRequestOptions, apiRequest, apiRequestSuccess } from "../shared/api-request";
import { GetProjectOptions } from "../shared/api-types";
import { isObject } from "../shared/util";
import { ProjectSnapshot } from "../model/snapshot";

export const savePreviewPNG = async (projectId: string, snapshotId: number, previewPNG: Blob) => {
  const { url } = await apiRequestSuccess(
    "generatePreviewPutUrl",
    { projectId, snapshotId },
    { skipRedraw: true }
  );
  await fetch(url, { method: "PUT", body: previewPNG });
};

export const saveProjectJSON = async (
  projectId: string,
  snapshotId: number,
  projectJSON: object
) => {
  const { url } = await apiRequestSuccess(
    "generateProjectJSONPutUrl",
    { projectId, snapshotId },
    { skipRedraw: true }
  );

  // Compress the JSON to increase download speed later. Project data will be
  // transparently decompressed by the browser on download.
  const gzippedProjectJSON = pako.gzip(JSON.stringify(projectJSON));

  await fetch(url, {
    method: "PUT",
    headers: {
      // Notify S3 that the file is gzipped. This header will also be present
      // when the project is downloaded.
      "Content-Encoding": "gzip",
    },
    body: new Blob([gzippedProjectJSON], { type: "application/json" }),
  });
};

const getProjectJSON = async (projectJSONUrl: string) => {
  try {
    const response = await fetch(projectJSONUrl);
    const projectJSON = await response.json();
    if (isObject(projectJSON)) {
      return projectJSON;
    }
  } catch (error) {}
  throw new Error("Could not load project");
};

export class ProjectIsPrivateError extends Error {
  constructor() {
    super("Project is private");
  }
}
export class ProjectDoesNotExistError extends Error {
  constructor() {
    super("Project does not exist");
  }
}

export const snapshotFromProjectId = async (options: {
  getProjectOptions: GetProjectOptions;
  apiRequestOptions?: APIRequestOptions;
  upgradeSnapshotOptions?: UpgradeSnapshotOptions;
}) => {
  const { getProjectOptions, apiRequestOptions, upgradeSnapshotOptions } = options;
  const response = await apiRequest("getProject", getProjectOptions, apiRequestOptions);
  console.log("Got the project response", response);

  if (!response.success) {
    if (response.message === "This project is private") {
      throw new ProjectIsPrivateError();
    } else {
      throw new ProjectDoesNotExistError();
    }
  }

  const projectJSONUrl = response.projectJSONUrl;
  let projectJSON;
  let snapshot: ProjectSnapshot | undefined;
  if (projectJSONUrl) {
    projectJSON = await getProjectJSON(projectJSONUrl);
    if (projectJSON) {
      snapshot = ProjectSnapshot.fromJSON(projectJSON, upgradeSnapshotOptions);
    }
  } else {
    // It's a new project
    console.log("New project, no existing projectJSON yet");
  }

  return { response, snapshot };
};
