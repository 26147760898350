import m from "mithril";

import { registerCheckpointEventHandlers } from "../checkpoint-events";
import { BoundingBox, Vec } from "../geom";
import { clamp } from "../geom/math/scalar-math";
import { globalState } from "../global-state";
import { imageManager } from "../image-manager";
import { externalSVGManager } from "../io/external-svg";
import { fontManager } from "../io/font-manager";
import { registerKeyboardEventHandlers } from "../keyboard-events";
import { CodeComponent, Component } from "../model/component";
import { CodeComponentFocus, ComponentFocus, SettingsFocus } from "../model/focus";
import { fontList } from "../model/font-list";
import { CanvasDimensions } from "../model/viewport";
import { accountState } from "../shared/account";
import { IconButton } from "../shared/icon";
import { SiteContainer } from "../shared/site-layout/site-layout";
import type { AccessError } from "../storage/storage-types";
import { isDevelopmentServer } from "../util";
import { Canvas } from "./canvas";
import { rulerWidthPixels } from "./canvas-ui/canvas-ruler";
import { SnappingPointTooltip } from "./canvas-ui/snapping-interface";
import { CodeEditorEndOptimizer, CodeEditorStartOptimizer } from "./code-mirror";
import { Components } from "./components";
import {
  CANVAS_WIDTH_MIN,
  LEFT_PANEL_WIDTH,
  RIGHT_SIDEBAR_WIDTH_MIN,
  WINDOW_WIDTH_MIN,
} from "./constants";
import { DocEditor } from "./doc-editor/doc-editor";
import { ImportFileDrop } from "./file-drop";
import { GettingStartedModal } from "./getting-started-modal";
import { GlobalSVGPatterns } from "./global-svg-patterns";
import { disableIOSInputTapZoom } from "./html-meta";
import { Inspector } from "./inspector/inspector";
import { Outline } from "./outline/outline";
import { PreviewCanvas } from "./preview-canvas";
import { ReadOnly } from "./read-only";
import { RectWatcher } from "./rect-watcher";
import { SettingsInspector } from "./settings-inspector";
import { startDrag } from "./start-drag";
import { ToastContainer } from "./toast-message";
import { Toolbar } from "./toolbar";
import { TopMenu } from "./top-menu";
import { TopMenuSmall } from "./top-menu-small";
import { VersionHistory } from "./version-history";
import { ZoomHint } from "./zoom-hint";

registerKeyboardEventHandlers();
registerCheckpointEventHandlers();

disableIOSInputTapZoom();

// Whenever a font is loaded, redraw Mithril.
fontManager.subscribe(() => m.redraw());
imageManager.subscribe(() => m.redraw());
externalSVGManager.subscribe(() => m.redraw());
fontList.subscribe(() => m.redraw());

// Whenever the window is resized, redraw Mithril.
window.addEventListener("resize", () => m.redraw());

export const App: m.ClosureComponent = () => {
  const contextMenuPreventDefault = (event: Event) => event.preventDefault();

  const onVisibilityChange = async () => {
    if (document.visibilityState === "visible") {
      // Immediate actions
      globalState.isFirstRedrawAfterBecameVisible = true;
      m.redraw();

      // Async actions
      await accountState.refreshLoggedInUser();
      m.redraw();
    }
  };

  return {
    oncreate() {
      document.addEventListener("visibilitychange", onVisibilityChange);
    },
    onupdate() {
      globalState.isFirstRedrawAfterBecameVisible = false;
    },
    view() {
      if (globalState.project.hasFocusedComponent()) {
        globalState.ensureEnabledActiveTool();
      }

      globalState.updateEvaluation();

      // Private hint
      const accessError = globalState.storage.getAccessError();
      if (accessError) {
        return m(PrivateMessage, { accessError });
      }

      // Mututally exclusive types of things that can be focused
      const focusedComponent = globalState.project.focusedComponent();
      const focusedDocumentation = globalState.project.focusedDocumentation();

      const windowRect = new DOMRect(0, 0, window.innerWidth, window.innerHeight);
      const isSmallWindow = windowRect.width < WINDOW_WIDTH_MIN;

      const topBarHeight = isSmallWindow ? 32 : 28;
      const leftPanelWidth = isSmallWindow ? 0 : LEFT_PANEL_WIDTH;
      const rightPanelWidth = isSmallWindow
        ? 0
        : Math.min(
            globalState.deviceStorage.rightSidebarWidth,
            windowRect.width - CANVAS_WIDTH_MIN - LEFT_PANEL_WIDTH
          );
      const viewportSize = new Vec(
        Math.max(0, windowRect.width - leftPanelWidth - rightPanelWidth),
        windowRect.height - topBarHeight
      );
      globalState.canvasRectPixels = new DOMRect(
        leftPanelWidth,
        topBarHeight,
        viewportSize.x,
        viewportSize.y
      );
      const toolbarHeightPixels = isSmallWindow ? 0 : 44;
      const canvasUsableArea = new BoundingBox(
        new Vec(rulerWidthPixels, rulerWidthPixels + toolbarHeightPixels),
        viewportSize
      );
      const canvasDimensions = new CanvasDimensions(viewportSize, canvasUsableArea);
      globalState.canvasDimensions = canvasDimensions;

      let mViewport: m.Children;
      if (focusedComponent) {
        if (isSmallWindow) {
          mViewport = m(PreviewCanvas, {
            component: focusedComponent,
            canvasDimensions,
            zoomViewport: true,
          });
        } else {
          const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
          mViewport = [
            m(Canvas, { viewport, canvasDimensions }),
            focusedComponent && m(Toolbar),
            m(ZoomHint),
          ];
        }
      } else if (focusedDocumentation) {
        const editable = globalState.storage.hasWritePermission() && globalState.isEditingMode();
        mViewport = m(DocEditor, {
          doc: focusedDocumentation.doc,
          editable,
          onchange: (doc) => {
            if (!focusedDocumentation || !editable) return;
            focusedDocumentation.doc = doc;
            globalState.checkpointAfterNextEvaluation();
          },
        });
      } else if (globalState.project.focus instanceof SettingsFocus) {
        mViewport = m(SettingsInspector, { settings: globalState.project.focus.settings });
      }
      mViewport = m(".viewport", mViewport);

      const isReadOnlyDocs = !globalState.isEditingMode();
      if (isReadOnlyDocs) {
        return [m(ReadOnly, {}, mViewport), m(ToastContainer)];
      }

      return m(
        ".app",
        { oncontextmenu: contextMenuPreventDefault },
        m(SiteContainer, [
          // Must be before all CodeEditor components
          m(CodeEditorStartOptimizer),

          m(GlobalSVGPatterns),

          isSmallWindow
            ? [m(".panel-top-small", [m(TopMenuSmall)]), m(".main", mViewport)]
            : [
                m(".panel-top", [m(TopMenu, { rightPanelWidth })]),
                m(".main", [
                  m(".panel-left", m(Components)),
                  mViewport,
                  (focusedComponent || globalState.isVersionHistoryOpen) &&
                    m(".panel-right", { style: { width: rightPanelWidth + "px" } }, [
                      m(PanelRightResizer, { rightPanelWidth }),
                      m(PanelRight, { component: focusedComponent }),
                    ]),
                ]),
              ],

          m(SnappingPointTooltip),
          m(ToastContainer),

          // Getting Started views are under ActiveModal, b/c we want to be
          // able to keep it open while seeing other modals
          globalState.gettingStartedState &&
            m(GettingStartedModal, {
              gettingStartedState: globalState.gettingStartedState,
              isSmallWindow,
            }),

          // m(ModalContainer),
          m(ImportFileDrop),
          // m(PopupContainer),

          // Must be after all CodeEditor components
          m(CodeEditorEndOptimizer),
          isDevelopmentServer() && !globalState.debug_hideInfoInspector && m(EditHistoryDebug),
        ])
      );
    },
  };
};

interface PanelRightAttrs {
  component: Component | CodeComponent | undefined;
}
const PanelRight: m.Component<PanelRightAttrs> = {
  view({ attrs: { component } }) {
    if (globalState.isVersionHistoryOpen) {
      return m(VersionHistory);
    }
    if (component) {
      return m(RectWatcher, {
        className: "outline-inspector",
        view(rect: DOMRect) {
          if (component instanceof CodeComponent) {
            return m(Inspector, { component });
          }
          return [
            m(Outline, { heightFactor: globalState.deviceStorage.rightSidebarSplit }),
            m(".outline-inspector-divider", m(OutlineInspectorResizer, { rect })),
            m(Inspector, {
              component,
              heightFactor: 1 - globalState.deviceStorage.rightSidebarSplit,
            }),
          ];
        },
      });
    }
    return;
  },
};

const PanelRightResizer: m.Component<{ rightPanelWidth: number }> = {
  view({ attrs: { rightPanelWidth } }) {
    // Hide the resizer if you're dragging for an outline reorder to maximize
    // outline drop areas.
    if (globalState.outlineReorder) {
      return null;
    }

    const onpointerdown = (downEvent: PointerEvent) => {
      const startClientX = downEvent.clientX;
      const startWidth = rightPanelWidth;
      startDrag(downEvent, {
        cursor() {
          return "ew-resize";
        },
        onMove(moveEvent) {
          const currentClientX = moveEvent.clientX;
          const delta = currentClientX - startClientX;
          globalState.deviceStorage.rightSidebarWidth = Math.max(
            RIGHT_SIDEBAR_WIDTH_MIN,
            Math.round(startWidth - delta)
          );
        },
      });
    };
    return m(".panel-right-resizer", { onpointerdown });
  },
};

interface OutlineInspectorResizerAttrs {
  rect: DOMRect;
}
const OutlineInspectorResizer: m.Component<OutlineInspectorResizerAttrs> = {
  view({ attrs: { rect } }) {
    // Hide the resizer if you're dragging for an outline reorder to maximize
    // outline drop areas.
    if (globalState.outlineReorder) {
      return null;
    }

    const onpointerdown = (downEvent: PointerEvent) => {
      const startClientY = downEvent.clientY;
      const startSplitY = rect.height * globalState.deviceStorage.rightSidebarSplit;
      startDrag(downEvent, {
        onMove(moveEvent) {
          const delta = moveEvent.clientY - startClientY;
          globalState.deviceStorage.rightSidebarSplit = clamp(
            (startSplitY + delta) / rect.height,
            0.05,
            0.95
          );
        },
        cursor() {
          return "ns-resize";
        },
      });
    };
    return m(".outline-inspector-resizer", { onpointerdown });
  },
};

const EditHistoryDebug: m.Component = {
  view() {
    return m(".debug-inspector", [
      m(".debug-inspector-name", "DEBUG"),
      m(
        ".debug-inspector-value",
        m(IconButton, { icon: "x", onclick: () => (globalState.debug_hideInfoInspector = true) })
      ),
      m(".debug-inspector-name", "Undo stack size"),
      m(".debug-inspector-value", globalState.editHistory.undoStack.length),
      m(".debug-inspector-name", "Redo stack size"),
      m(".debug-inspector-value", globalState.editHistory.redoStack.length),
      m(".debug-inspector-name", "Last difference"),
      m(".debug-inspector-value", globalState.debug_lastCheckpointDifference ?? "none"),
      m(".debug-inspector-name", "Saved"),
      m(".debug-inspector-value", Boolean(globalState.debug_lastCheckpointSaved).toString()),
      m(".debug-inspector-name", "Mid gesture"),
      m(
        ".debug-inspector-value",
        globalState.isMidGesture() &&
          Array.from(globalState.gestureIds).map((id) => id.description + " ")
      ),
    ]);
  },
};

const PrivateMessage: m.Component<{ accessError: AccessError }> = {
  view({ attrs: { accessError } }) {
    if (accessError === "refresh-to-update") {
      return m(".project-error-message-private", [
        m("p", "You caught us during an update. 🙈"),
        m("p", "Please refresh this page to load the newest version."),
      ]);
    }
    return m(".project-error-message-private", [
      accessError === "private"
        ? [
            m("p", "This project is private."),
            m(
              "p",
              'The owner of this project needs to change its share status to "Unlisted" or "Public" in order for you to view it.'
            ),
          ]
        : [
            m("p", "Page not found."),
            m("p", [
              "If this seems like a bug, email ",
              m("a", { href: "mailto:toby@cuttle.xyz" }, "toby@cuttle.xyz"),
              " and we'll try to fix it!",
            ]),
          ],
      m("p", [m("a", { href: "/explore", target: "_top" }, "Explore public projects.")]),
    ]);
  },
};
