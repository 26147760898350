import m from "mithril";

import { assert } from "../geom";
import { fontList } from "../model/font-list";
import { accountState } from "../shared/account";
import { apiRequest, apiRequestSuccess } from "../shared/api-request";
import {
  ForkedFromInfo,
  ShareStatus,
  SnapshotRow,
  TagInfo,
  UploadedFont,
} from "../shared/api-types";
import { canonicalOrigin } from "../shared/config";
import { isSupportedUploadableFontFile, mimeTypeForFile } from "../shared/file-types";
import { logEvent } from "../shared/log-event";
import { canonicalUrlForProject, hashSHA256 } from "../shared/util";
import { SaveManager } from "./save-manager";
import {
  ProjectIsPrivateError,
  savePreviewPNG,
  saveProjectJSON,
  snapshotFromProjectId,
} from "./save-utils";
import type { AccessError, SnapshotPayload, Storage } from "./storage-types";

export class StorageBackend implements Storage {
  static version = 6;
  readonly type = "backend";

  private _projectId = "";
  private _projectName = "Untitled Project";
  private _projectOwner = "Unknown";
  private _projectOwnerDisplayName: string | null = null;
  private _projectOwnerAvatar = "";
  private _projectFirstMadePublicDate: string | null = null;
  private _projectPublishedDate: string | null = null;
  private _hasWritePermission = false;
  private _shareStatus: ShareStatus = "private";
  private _sequenceToLoad: "intro" | undefined;
  private _isProTemplate: boolean = false;

  private _projectForkedFrom: ForkedFromInfo | undefined;
  private _projectTags: TagInfo[] | undefined;
  private _snapshots: SnapshotRow[] | undefined;
  private _currentSnapshotId: number | null | undefined;
  private _publishedSnapshotId: number | null | undefined;

  private _saveManager: SaveManager | undefined;

  private _accessError: AccessError | undefined;

  private _uploadedFonts: UploadedFont[] = [];

  private _allTags: TagInfo[] | undefined;

  async init(projectId?: string) {
    await accountState.refreshLoggedInUser();
    if (projectId) {
      this._saveManager = new SaveManager(projectId);
    }
    const snapshot = await this.initProject(projectId);

    // Needs to run after refreshLoggedInUser, but we don't have to await it here.
    fontList.refreshCustomFonts();

    // Only admins can see views that use all tags.
    if (accountState.featureFlags.hasAdminFeatures) {
      // Don't have to await this, because it is not used in any initial views.
      this.initAllTags();
    }

    return snapshot;
  }

  async initProject(projectId: string | undefined) {
    if (projectId === undefined || window.location.host === "editor.cuttle.xyz") {
      console.error("This version should not be loaded in the iframe.");
      this._accessError = "refresh-to-update";
      return undefined;
    }

    this._projectId = projectId;

    this._saveManager = new SaveManager(projectId);

    if (projectId === "_intro") {
      return this.initIntroSequence();
    }

    try {
      const { response, snapshot } = await snapshotFromProjectId({
        getProjectOptions: { projectId },
        upgradeSnapshotOptions: { defaultProjectId: projectId },
      });

      this._projectName = response.name;
      this._hasWritePermission = response.hasWritePermission;
      this._shareStatus = response.shareStatus;
      this._sequenceToLoad = undefined;
      this._projectOwner = response.owner;
      this._projectOwnerDisplayName = response.ownerDisplayName;
      this._projectOwnerAvatar = response.ownerAvatar;
      this._projectFirstMadePublicDate = response.firstMadePublicDate;
      this._projectPublishedDate = response.publishedDate;
      this._projectForkedFrom = response.forkedFrom;
      this._projectTags = response.tags;
      this._isProTemplate = response.isPro;
      this.refreshProjectUrl();

      document.title = response.name;
      this.refreshSnapshots();

      // If projectJSON is null, then we're starting a fresh (blank) project. If
      // projectJSON exists, load the existing project.
      if (snapshot) {
        return snapshot;
      }
      return undefined;
    } catch (error) {
      if (error instanceof ProjectIsPrivateError) {
        console.log("Don't have access");
        document.title = "Project is private.";
        this._accessError = "private";
      } else {
        console.log("Non-existent project");
        document.title = "Page not found.";
        this._accessError = "not-found";
      }
      return undefined;
    }
  }

  initIntroSequence() {
    this._projectName = "Learn the Basics";
    this._projectOwner = "@cuttle";
    this._hasWritePermission = false;
    this._shareStatus = "unlisted";
    this._sequenceToLoad = "intro";
    return undefined;
  }

  refreshProjectUrl() {
    history.replaceState({}, "", this.getLocalProjectUrl());
  }

  async checkpoint(snapshotPayload: SnapshotPayload) {
    if (!this._saveManager || !this.hasWritePermission()) return;

    const { projectSnapshot, previewPNG, coverPhoto } = snapshotPayload;
    const project = projectSnapshot.toJSON();
    // Update local state
    this._currentSnapshotId = await this._saveManager.queueSave({
      project,
      previewPNG,
      coverPhoto,
    });
    const snapshot = this._snapshots?.find(
      (snapshot) => snapshot.snapshotId === this._currentSnapshotId
    );
    if (snapshot) {
      snapshot.lastModified = new Date().toJSON();
      snapshot.previewImage = refreshAwsUrl(snapshot.previewImage);
      m.redraw();
    } else {
      this.refreshSnapshots();
    }
  }
  /**
   * ⚠️ This does a few things to be aware of:
   * 1. If person is not logged in, triggers the signup modal.
   * 2. Makes a copy of the project.
   * 3. Calls initProject which loads the project and replaces the url.
   */
  async saveCopy(snapshotPayload: SnapshotPayload) {
    // Finish saving, in case a change is in flight.
    await this.forceSave();

    // Ensure logged in
    if (!accountState.loggedInUser) {
      const success = await accountState.openAccountModalPromise("signup");
      if (!success) return false;
    }

    assert(accountState.loggedInUser, "Should be logged in to save a copy.");

    const { projectSnapshot, previewPNG, coverPhoto } = snapshotPayload;
    const project = projectSnapshot.toJSON();

    let originalProjectId: string | undefined = this._projectId;
    if (originalProjectId === "_intro") originalProjectId = undefined;
    const { projectId: newProjectId } = await apiRequestSuccess(
      "createProject",
      { forkedFrom: originalProjectId },
      { skipRedraw: true }
    );
    await apiRequestSuccess(
      "renameProject",
      { projectId: newProjectId, name: this._projectName },
      { skipRedraw: true }
    );
    const { snapshotId } = await apiRequestSuccess(
      "createAutosaveSnapshot",
      { projectId: newProjectId, coverPhoto },
      { skipRedraw: true }
    );
    await saveProjectJSON(newProjectId, snapshotId, project);
    await apiRequestSuccess(
      "updateProjectCurrentSnapshot",
      { projectId: newProjectId, currentSnapshotId: snapshotId },
      { skipRedraw: true }
    );
    if (previewPNG) {
      await savePreviewPNG(newProjectId, snapshotId, previewPNG);
    }

    // Will load project and call refreshProjectUrl
    const newlyLoadedSnapshot = await this.initProject(newProjectId);
    if (newlyLoadedSnapshot) {
      return true;
    }
    return false;
  }
  isSynchronized() {
    if (this._saveManager) {
      return this._saveManager.isSynchronized();
    }
    return true;
  }
  async forceSave() {
    if (this._saveManager) {
      await this._saveManager.forceSave();
    }
    return;
  }
  async saveVersion(snapshotPayload: SnapshotPayload) {
    const { projectSnapshot, previewPNG, coverPhoto } = snapshotPayload;
    const project = projectSnapshot.toJSON();
    const { snapshotId } = await apiRequestSuccess(
      "createVersionSnapshot",
      { projectId: this._projectId, coverPhoto },
      { skipRedraw: true }
    );
    await saveProjectJSON(this._projectId, snapshotId, project);
    if (previewPNG) {
      await savePreviewPNG(this._projectId, snapshotId, previewPNG);
    }
    await apiRequestSuccess(
      "updateProjectCurrentSnapshot",
      { projectId: this._projectId, currentSnapshotId: snapshotId },
      { skipRedraw: true }
    );
    await this.refreshSnapshots();
    return snapshotId;
  }
  async uploadFile(fileBlob: Blob) {
    const buffer = await fileBlob.arrayBuffer();
    const hash = await hashSHA256(buffer);
    const mimeType = fileBlob.type;
    const { filePath, url } = await apiRequestSuccess(
      "generateUploadPutUrl",
      { mimeType, hash },
      { skipRedraw: true }
    );
    if (url) {
      // Only upload to S3 if a URL was returned.
      await fetch(url, { method: "PUT", body: fileBlob });
    }
    return filePath;
  }
  async addFontForLoggedInUser(options: {
    file: File;
    hash: string;
    family: string;
    variant: string;
    fileExtension: string;
  }) {
    const { file, hash, family, variant, fileExtension } = options;

    const mimeType = mimeTypeForFile(file);
    if (!mimeType || !isSupportedUploadableFontFile(file)) {
      throw new Error("Unknown font type");
    }

    // Check for an existing font upload and get an S3 PUT URL.
    const apiBody = { hash, family, variant, fileExtension, mimeType };
    const response = await apiRequestSuccess("addFontForLoggedInUser", apiBody, {
      skipRedraw: true,
    });

    const body = new Blob([file], { type: mimeType });

    // If the font hash doesn't exist in the DB, upload it to S3. This step
    // requires faith in the user's connection, that the font metadata is
    // correct and it will get successfully uploaded. In the future we might
    // want a server-side check that we have the files that we expect.
    if (response.url) {
      // upload to S3
      await fetch(response.url, { method: "PUT", body });
      logEvent("upload font", { family, variant });
    }
  }
  async refreshCustomFonts() {
    if (!accountState.loggedInUser) {
      this._uploadedFonts = [];
      return;
    }

    const responseData = await apiRequest("getFontsForLoggedInUser", {});
    if (responseData.success) {
      this._uploadedFonts = responseData.fonts;
    } else {
      this._uploadedFonts = [];
    }
    return;
  }
  getCustomFonts() {
    if (!accountState.loggedInUser) return;
    return this._uploadedFonts;
  }
  async getFontByHash(
    hash: string
  ): Promise<{ url: string | null; family: string; variant: string } | null> {
    const response = await apiRequestSuccess("getFontByHash", { hash });
    if (response.success) {
      const { url, family, variant } = response;
      return { url, family, variant };
    }
    return null;
  }
  async removeFontForLoggedInUser(hash: string) {
    await apiRequestSuccess("removeFontForLoggedInUser", { hash });
    return;
  }
  getSnapshots() {
    return this._snapshots;
  }
  getCurrentSnapshotId() {
    return this._currentSnapshotId;
  }
  getPublishedSnapshotId() {
    return this._publishedSnapshotId;
  }
  async refreshSnapshots() {
    if (this.hasWritePermission()) {
      const response = await apiRequestSuccess("getSnapshots", { projectId: this._projectId });
      this._snapshots = response.snapshots;
      this._currentSnapshotId = response.currentSnapshotId;
      this._publishedSnapshotId = response.publishedSnapshotId;
    } else {
      this._snapshots = [];
    }
    return this._snapshots;
  }
  async restoreSnapshot(snapshotId: number) {
    const { snapshot } = await snapshotFromProjectId({
      getProjectOptions: { projectId: this._projectId, snapshotId },
      apiRequestOptions: { skipRedraw: true },
      upgradeSnapshotOptions: { defaultProjectId: this._projectId },
    });

    await apiRequestSuccess("updateProjectCurrentSnapshot", {
      projectId: this._projectId,
      currentSnapshotId: snapshotId,
    });

    // Update local state
    this._currentSnapshotId = snapshotId;

    return snapshot;
  }
  async deleteSnapshot(snapshotId: number) {
    await apiRequestSuccess(
      "deleteSnapshot",
      { projectId: this._projectId, snapshotId },
      { skipRedraw: true }
    );
    this.refreshSnapshots();
  }
  async setPublishedSnapshot(snapshotId?: number, publishedDescription?: string) {
    await apiRequestSuccess("updateProjectPublishedSnapshot", {
      projectId: this._projectId,
      publishedSnapshotId: snapshotId,
      publishedDescription,
    });
    this._publishedSnapshotId = snapshotId ?? null;
  }
  async updateSnapshotDescription(snapshotId: number, newDescription: string) {
    // Update local snapshot
    const localSnapshot = this._snapshots?.find((snapshot) => snapshot.snapshotId === snapshotId);
    if (localSnapshot) {
      localSnapshot.description = newDescription;
    }
    // Update backend snapshot
    await apiRequestSuccess("updateSnapshotDescription", {
      projectId: this._projectId,
      snapshotId,
      description: newDescription,
    });
  }
  private getLocalProjectUrl() {
    return canonicalUrlForProject(this._projectOwner, this._projectName, this._projectId);
  }
  getProjectUrl() {
    return canonicalOrigin + this.getLocalProjectUrl();
  }
  getProjectId() {
    return this._projectId;
  }
  getProjectName() {
    return this._projectName;
  }
  async setProjectName(newName: string) {
    if (!accountState.loggedInUser) return;

    await apiRequestSuccess("renameProject", { projectId: this._projectId, name: newName });

    document.title = newName;
    this._projectName = newName;
    this.refreshProjectUrl();
  }
  hasWritePermission() {
    return Boolean(accountState.loggedInUser) && this._hasWritePermission;
  }
  getShareStatus() {
    return this._shareStatus;
  }
  async setShareStatus(newStatus: ShareStatus) {
    this._shareStatus = newStatus;
    await apiRequestSuccess("updateShareStatus", {
      projectId: this._projectId,
      shareStatus: newStatus,
    });
  }
  getProjectOwner() {
    return this._projectOwner;
  }
  getProjectOwnerDisplayName() {
    return this._projectOwnerDisplayName ?? undefined;
  }
  getProjectOwnerAvatar() {
    return this._projectOwnerAvatar;
  }
  getProjectFirstMadePublicDate() {
    return this._projectFirstMadePublicDate ?? undefined;
  }
  getProjectPublishedDate() {
    return this._projectPublishedDate ?? undefined;
  }
  getProjectForkedFrom() {
    return this._projectForkedFrom;
  }
  getProjectTags() {
    return this._projectTags;
  }
  async setProjectTags(tags: TagInfo[]) {
    this._projectTags = tags;
    const tagIds = tags.map((tag) => tag.tagId);
    await apiRequestSuccess("updateProjectTags", { projectId: this._projectId, tagIds });
    return;
  }
  sequenceToLoad() {
    return this._sequenceToLoad;
  }
  getAccessError() {
    return this._accessError;
  }
  isOfficialCuttleTemplate() {
    // For now at least, we say a project is an official cuttle template if it
    // was made by user @cuttle.
    return this._projectOwner === "cuttle";
  }
  isProTemplate() {
    return this._isProTemplate;
  }

  // Tag list that comes from API, is shared by all projects.
  private async initAllTags() {
    const result = await apiRequestSuccess("getTags", {});
    if (result.success) {
      this._allTags = result.tags;
    }
  }
  getAllTags() {
    return this._allTags;
  }
}

/**
 * Modifies an AWS URL so that it's unique, to force a refresh. Kinda hacky!
 */
const refreshAwsUrl = (url: string) => {
  const regex = /&refresh=\d+$/;
  const replacement = `&refresh=${Date.now()}`;
  if (regex.test(url)) {
    return url.replace(regex, replacement);
  }
  return url + replacement;
};
