import * as opentype from "opentype.js";

import { base64StringToBlob } from "blob-util";
import { Font, FontSymbolRetriever } from "../geom";
import type { GlyphAlternateInfo } from "../geom";
import { OpenTypeFont } from "../geom/io/opentype-font";
import { SVGFont } from "../geom/io/svg-font";
import { globalState } from "../global-state";
import { extensionFromPath } from "../shared/util";
import { normalizeAssetsUrl } from "../util";
import { externalSVGManager } from "./external-svg";
import { decryptFile } from "./web-crypto";

const customFontPrefix = "cuttle-font://";
export const hashForCustomFontURL = (url: string) => {
  if (url.startsWith(customFontPrefix)) {
    return url.substring(customFontPrefix.length);
  }
  return;
};

export type FontChangeSubscriber = () => void;

export interface CuttleFontInfo {
  app: "Cuttle";
  type: "Font";
  version: 1;
  payload: {
    name: string;
    type: string;
    isSingleLine: boolean;
    glyphAlternates?: GlyphAlternateInfo;
    encryptedData: string;
  };
}

class FontError {
  /** Bubbles up to UI. */
  errorMessage: string;

  constructor(errorMessage: string) {
    this.errorMessage = errorMessage;
  }
}

class FontManager {
  private loadedFonts: Record<string, Font | FontError | undefined> = {};
  private subscribers: Set<FontChangeSubscriber> = new Set();

  // Note this method is declared using => to bind `this`.
  getFontFromURL = (url: string) => {
    url = normalizeAssetsUrl(url);

    if (this.loadedFonts.hasOwnProperty(url)) {
      const loadedFont = this.loadedFonts[url];
      if (loadedFont instanceof FontError) {
        throw new Error(loadedFont.errorMessage);
      }
      return loadedFont;
    }

    // So we don't call loadFont more than once per url
    this.loadedFonts[url] = undefined;

    loadFont(url)
      .then((font) => {
        this.loadedFonts[url] = font;
        this.notifySubscribers();
      })
      .catch((error) => {
        console.warn("Font loading error:", error);
        let errorMessage = String(error);
        if (errorMessage.includes("OpenType")) {
          errorMessage = "Font reading error.";
        }
        this.loadedFonts[url] = new FontError(errorMessage);
        this.notifySubscribers();
      });

    return;
  };

  subscribe(subscriber: FontChangeSubscriber) {
    this.subscribers.add(subscriber);
  }
  unsubscribe(subscriber: FontChangeSubscriber) {
    this.subscribers.delete(subscriber);
  }
  notifySubscribers() {
    for (let subscriber of this.subscribers) {
      subscriber();
    }
  }
  getNames(url: string) {
    const loadedFont = this.loadedFonts[url];
    if (loadedFont && !(loadedFont instanceof FontError)) {
      return { family: loadedFont._family, variant: loadedFont._variant };
    }
    return;
  }
  getLabel(url: string) {
    const loadedFont = this.loadedFonts[url];
    if (loadedFont && !(loadedFont instanceof FontError)) {
      const { _family, _variant } = loadedFont;
      if (_family && _variant) {
        return _family + (_variant ? ` (${_variant})` : "");
      }
    }
    return;
  }
  getError(url: string) {
    const loadedFont = this.loadedFonts[url];
    if (loadedFont && loadedFont instanceof FontError) {
      return loadedFont.errorMessage;
    }
    return;
  }
  /** Call this after adding fonts to account. */
  resetErrors() {
    Object.keys(this.loadedFonts).forEach((url) => {
      const font = this.loadedFonts[url];
      if (font && font instanceof FontError) {
        delete this.loadedFonts[url];
      }
    });
  }
}
export const fontManager = new FontManager();

const loadFont = async (url: string) => {
  // Translate cuttle-font://hash to signed S3 URL
  const customFontHash = hashForCustomFontURL(url);
  if (customFontHash) {
    const customFont = await globalState.storage.getFontByHash(customFontHash);
    if (!customFont) {
      return Promise.reject("Font not found.");
    }
    if (!customFont.url) {
      return Promise.reject(
        (customFont.family || "Uploaded Font") +
          (customFont.variant ? ` (${customFont.variant})` : "") +
          ": you do not have access to this font."
      );
    }
    url = customFont.url;
  }
  const extension = extensionFromPath(url);
  if (extension === "svg") {
    return loadSVGFont(url);
  } else if (extension === "json") {
    return loadProFont(url);
  } else {
    return loadOpenTypeFont(url);
  }
};

const loadSVGFont = async (url: string) => {
  const response = await fetch(url);
  const text = await response.text();
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, "image/svg+xml");
  return new SVGFont(doc, symbolRetriever);
};

const loadOpenTypeFont = async (url: string) => {
  const font = await opentype.load(url);
  return new OpenTypeFont(font, symbolRetriever, { isSingleLine: false });
};

const symbolRetriever: FontSymbolRetriever = (symbol) => {
  return externalSVGManager.getEmojiSVGFromURL(symbol.source);
};

/**
 * Even if the user is not pro, we decrypt and display the font as a preview. On
 * export, we gate on pro status if the project uses pro fonts.
 */
const loadProFont = async (url: string) => {
  const response = await fetch(url);
  const {
    payload: { type, isSingleLine, glyphAlternates, encryptedData },
  } = (await response.json()) as CuttleFontInfo;
  const passphrase =
    "Cuttle's font licenses allow you to use these fonts within our app, but not to download them. Thanks for understanding.";
  const encyptedArrayBuffer = await base64StringToBlob(encryptedData).arrayBuffer();
  const decryptedArrayBuffer = await decryptFile(encyptedArrayBuffer, passphrase);
  if (type === "image/svg+xml") {
    // TODO: parse SVG
  } else {
    const font = opentype.parse(decryptedArrayBuffer);
    return new OpenTypeFont(font, symbolRetriever, {
      isSingleLine,
      glyphAlternates,
    });
  }
};
