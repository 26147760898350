import * as trigMath from "../geom/env/trig";
import * as vecMath from "../geom/env/vec-math";
import * as constants from "../geom/math/constants";
import * as random from "../geom/math/random";
import * as scalarMath from "../geom/math/scalar-math";
import * as geomFactories from "./geom-factories";

import { Geometry, Graphic, pairs, range, toString } from "../geom";
import { convertUnits } from "../geom/env/convert-units";
import { imageManager } from "../image-manager";
import { externalSVGManager } from "../io/external-svg";
import { fontManager } from "../io/font-manager";
import { Env } from "../model/env";
import { operatorOverloads } from "./operator-overloads";

// For some reason Math and JSON don't make it through and needs to be copied
// like this. ???
const mathCopy: { [key: string]: any } = {};
for (let key of Object.getOwnPropertyNames(Math)) {
  mathCopy[key] = (Math as any)[key];
}
const jsonCopy: { [key: string]: any } = {};
for (let key of Object.getOwnPropertyNames(JSON)) {
  jsonCopy[key] = (JSON as any)[key];
}

const globalEnvironmentObject = {
  ...mathCopy,
  ...constants,
  ...scalarMath,
  ...trigMath,
  ...vecMath, // Overwrites some scalar math functions
  ...random,
  ...geomFactories,

  Geometry,
  Graphic,

  // Units
  convertUnits,

  // Array Utilities
  pairs,
  range,

  toString,

  getFontFromURL: fontManager.getFontFromURL,
  getImageFromURL: imageManager.getImageFromURL,
  getEmojiSVGFromURL: externalSVGManager.getEmojiSVGFromURL,

  operatorOverloads,

  // Include console to appease the reference checker even though it will be
  // overwritten by a specific version for each run.
  console: window.console,

  // Include `performance.now()` high resolution timing for benchmarks.
  performance: {
    now() {
      return performance.now();
    },
  },

  Math: mathCopy,
  JSON: jsonCopy,

  NaN,
  Infinity,

  isNaN,
  isFinite,

  parseInt,
  parseFloat,

  decodeURI,
  decodeURIComponent,
  encodeURI,
  encodeURIComponent,
  escape,
  unescape,

  Object,
  Function,
  String,
  Boolean,
  Number,
  Date,
  RegExp,

  Error,
  EvalError,
  RangeError,
  ReferenceError,
  SyntaxError,
  TypeError,
  URIError,

  Array,
  ArrayBuffer,
  DataView,
  Int8Array,
  Uint8Array,
  Uint8ClampedArray,
  Int16Array,
  Uint16Array,
  Int32Array,
  Uint32Array,
  Float32Array,
  Float64Array,

  Set,
  WeakSet,
  Map,
  WeakMap,
};

export const globalEnvironment = new Env().setAll(globalEnvironmentObject);
export const globalEnvironmentKeys = Object.keys(globalEnvironmentObject);
