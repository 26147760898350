import { getLineInfo, parse } from "acorn";
import acornGlobals from "acorn-globals";
import * as acornWalk from "acorn-walk";
import { generate } from "astring";
import type * as ESTree from "estree";

import { nonNull } from "../geom";
import { logManager, LogMessage } from "../log-manager";
import { Range } from "../util";
import { ACORN_PARSE_OPTIONS } from "./compile-options";
import { transformAst } from "./compile-transform-ast";
import { Env } from "./env";
import { Literal, parseLiteral } from "./parse-literal";
import { ExpressionTrace } from "./trace";

const cloneObject = (object: any): any => {
  if (object === undefined) return undefined;
  if (object === null) return null;
  if (typeof object.clone === "function") {
    return object.clone();
  }
  if (Array.isArray(object)) {
    return object.map(cloneObject);
  }
  if (typeof object === "object") {
    const result: any = {};
    for (let key in object) {
      result[key] = cloneObject(object[key]);
    }
    return result;
  }
  return object;
};

export type Runner = (env: Env) => ExpressionTrace;

export interface Reference {
  name: string;
  ranges: Range[];
}

const makeConstantRunner = (evaluationResult: any, messages: LogMessage[]): Runner => {
  const result = new ExpressionTrace(evaluationResult, messages);
  return () => result;
};

/**
 * Parses a jsCode string using Acorn. Can handle either a single expression or
 * some code with a return statement. If there's a parse error, returns a
 * Message.
 */
const jsCodeToAst = (jsCode: string): { ast: ESTree.Node; prefixLength: number } | LogMessage => {
  // First try to see if it's a single expression (a "one-liner") by prepending
  // "return ".
  const PREFIX_CODE = "return ";
  const prependedJsCode = PREFIX_CODE + jsCode;
  try {
    const ast = parse(prependedJsCode, ACORN_PARSE_OPTIONS) as ESTree.Node;
    // Ensure the result only has a single node, meaning we started with just a
    // single expression.
    if (ast.type === "Program" && ast.body.length === 1 && ast.body[0].type === "ReturnStatement") {
      return { ast, prefixLength: PREFIX_CODE.length };
    }
  } catch (error) {}

  // Parsing as a single expression didn't work, so parse normally.
  try {
    const ast = parse(jsCode, ACORN_PARSE_OPTIONS) as ESTree.Node;
    return { ast, prefixLength: 0 };
  } catch (error) {
    // TypeScript: I would prefer to check `if (error instanceof SyntaxError)
    // ...` but Acorn doesn't have a type definition for SyntaxError. But the
    // documentation says this will have a message and loc.line on it. See:
    // https://github.com/acornjs/acorn/tree/master/acorn
    const syntaxError = error as any;
    return new LogMessage(syntaxError?.message, "error", syntaxError?.loc?.line);
  }
};

export interface CompileResult {
  runner: Runner;
  references: Reference[];
  parseSuccess: boolean;
  literal?: Literal;
}
export const compile = (jsCode: string): CompileResult => {
  let jsCodePrefixLength = 0;

  // Check if literal as optimization.
  const literal = parseLiteral(jsCode);
  if (literal) {
    if ((literal.type === "Number" || literal.type === "Vec") && literal.unit) {
      // If the literal has a unit (only Numbers and Vecs are allowed to have
      // units) then we need to wrap the expression in a function call that
      // converts it from the specified unit to project units. This cannot be a
      // constant runner since it depends on the project units.
      jsCode = `convertUnits((${literal.string}), "${literal.unit}", cuttle.project.units)`;
      jsCodePrefixLength = 14; // "convertUnits((".length;
    } else {
      // The expression is a simple literal with no unit. Make a constant.
      return {
        runner: makeConstantRunner(literal.value, []),
        references: [],
        parseSuccess: true,
        literal,
      };
    }
  }

  const result = jsCodeToAst(jsCode);
  if (result instanceof LogMessage) {
    return {
      runner: makeConstantRunner(undefined, [result]),
      references: [],
      parseSuccess: false,
      literal,
    };
  }

  const { ast } = result;

  const importExpressions = importExpressionsForAST(ast);
  if (importExpressions.length > 0) {
    return {
      runner: makeConstantRunner(
        undefined,
        nonNull(
          importExpressions.map((node) => {
            const line = node.loc?.end.line;
            if (line !== undefined) {
              return new LogMessage("Imports are not allowed inside of expressions", "error", line);
            }
          })
        )
      ),
      references: [],
      parseSuccess: false,
      literal,
    };
  }

  const references = globalReferencesForAST(ast, result.prefixLength - jsCodePrefixLength);
  const assembledCode = assembleCode(ast, references);

  let evaled: Function;
  try {
    evaled = new Function("_ENV", assembledCode);
  } catch (error) {
    // Very infrequently Acorn will parse even though the browser doesn't like
    // the syntax of the code. This might also mean we created invalid assembled
    // code.
    console.warn("Acorn parsed but eval failed.", assembledCode);
    const messageString = error instanceof Error ? `Parse Error: ${error.message}` : `Parse Error`;
    return {
      runner: makeConstantRunner(undefined, [new LogMessage(messageString, "error", -1)]),
      references: [],
      parseSuccess: false,
      literal,
    };
  }

  const runner = (env: Env) => {
    const envObject: { [key: string]: any } = {};

    // Clone all references, error early if a reference doesn't exist.
    for (let reference of references) {
      const name = reference.name;
      // NOTE: Always call env.get to set up a dependency, but then check to see
      // if the value is really undefined or if we have a missing reference.
      const value = env.get(name);
      if (value === undefined && !env.has(name)) {
        const line = getLineInfo(jsCode, reference.ranges[0].start).line;
        const message = `Could not find a reference named '${name}'`;
        return new ExpressionTrace(undefined, [new LogMessage(message, "error", line)]);
      }
      envObject[name] = cloneObject(value);
    }

    envObject["operatorOverloads"] = env.get("operatorOverloads");

    // Used for console and caught errors
    let lastLineEvaluated = 0;
    const lineNum = (n: number) => {
      lastLineEvaluated = n;
    };
    envObject["lineNum"] = lineNum;

    const messages: LogMessage[] = [];
    const onMessageLogged = (message: LogMessage) => {
      messages.push(message.withLineNumber(lastLineEvaluated));
    };

    // Start listening for logged messages.
    logManager.subscribe(onMessageLogged);

    let expressionTrace: ExpressionTrace;
    try {
      const evaluationResult = evaled(envObject);
      expressionTrace = new ExpressionTrace(evaluationResult, messages);
    } catch (error) {
      let messageString: string;
      if (error instanceof Error) {
        messageString = error.message;
      } else if (typeof error === "string") {
        messageString = error;
      } else {
        messageString = "Unknown error";
      }
      logManager.consoleError(messageString);
      expressionTrace = new ExpressionTrace(undefined, messages);
    }

    logManager.unsubscribe(onMessageLogged);

    return expressionTrace;
  };

  return {
    runner,
    references,
    parseSuccess: true,
    literal,
  };
};

const globalReferencesForAST = (ast: ESTree.Node, prefixLength: number) => {
  const references: Reference[] = [];
  // Note that acornGlobals(ast) mutates the AST, adding "parents" array to
  // Identifier nodes.
  for (let global of acornGlobals(ast)) {
    const name = global.name;
    if (name === "this") continue;
    const reference: Reference = {
      name,
      ranges: global.nodes.map((node: any) => {
        // Places that use ranges, for example for renaming a parameter, don't
        // know that we can add "return " or "toProjectUnits((" while compiling.
        // Make the ranges match the input code.
        const rangeStart = node.start - prefixLength;
        const rangeEnd = node.end - prefixLength;
        return {
          start: Math.max(0, rangeStart),
          end: Math.max(0, rangeEnd),
        };
      }),
    };
    references.push(reference);
  }
  return references;
};

const importExpressionsForAST = (ast: ESTree.Node) => {
  const importExpressions: ESTree.Node[] = [];
  acornWalk.simple(ast as acorn.Node, {
    ImportExpression(node) {
      importExpressions.push(node as ESTree.Node);
    },
  });
  return importExpressions;
};

const assembleCode = (ast: any, references: Reference[]) => {
  ast = transformAst(ast);
  const jsCode = generate(ast);

  let prelude = "";
  for (let reference of references) {
    const name = reference.name;
    prelude += `let ${name} = _ENV["${name}"];`;
  }

  const assembledCode = "'use strict';" + prelude + jsCode;
  return assembledCode;
};
