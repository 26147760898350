import m from "mithril";
import { cuttleSymbolsShapes } from "../examples-config";
import { EmojiData } from "../examples-types";
import { canonicalAssetsOrigin } from "../shared/config";
import { Tooltipped } from "../shared/popup";
import { debounce } from "../shared/util";
import { SelectListGroupWithQuery, SelectListOption } from "./basic/select-list";
import { shapeIndex } from "./shape-index";

const ALL_SHAPES_CATEGORY = "All";
const CUTTLE_SHAPES_CATEGORY = "Cuttle Symbols";
const EMOJI_CATEGORY = "Emoji";

const cuttleShapes: SelectListOption[] = cuttleSymbolsShapes.map((exampleShape) => {
  const value = `${canonicalAssetsOrigin}${exampleShape.path}.svg`;
  return {
    label: exampleShape.name,
    value,
    keywords:
      exampleShape.name.toLocaleLowerCase() +
      (exampleShape.keywords ? " " + exampleShape.keywords.join(" ") : ""),
    render: () =>
      m(
        Tooltipped,
        { message: () => exampleShape.name },
        m("img", { loading: "lazy", src: value, alt: exampleShape.name })
      ),
  };
});

interface ShapePickerPopupAttrs {
  value: string;
  onchange: (value: string) => void;
}
export const ShapePickerPopup: m.ClosureComponent<ShapePickerPopupAttrs> = (vnode) => {
  const state = new ShapePickerState(vnode.attrs.value);
  let currentValue = vnode.attrs.value;

  return {
    view: (vnode) => {
      const { onchange } = vnode.attrs;

      return m(
        ".shape-picker-popup",
        m(SelectListGroupWithQuery, {
          onQuery: (query: string) => {
            state.category = ALL_SHAPES_CATEGORY;
            state.setQuery(query);
          },
          groups: [
            {
              key: "category",
              options: [ALL_SHAPES_CATEGORY, CUTTLE_SHAPES_CATEGORY, EMOJI_CATEGORY],
              value: state.category,
              onSelect: (newCategory: string) => {
                state.setCategory(newCategory);
              },
            },
            {
              key: "shape",
              itemClassName: "shape-tile",
              // Sync with grid-template-columns in shape-picker.less
              columnCount: 5,
              options: state.results,
              value: currentValue,
              onSelect: (newShape: string) => {
                currentValue = newShape;
                onchange(newShape);
              },
            },
          ],
          primaryGroupKey: "shape",
        })
      );
    },
    oncreate({ attrs: { value }, dom }) {
      if (value === "") {
        (dom.querySelector("input.query") as HTMLElement)?.focus();
      }
    },
  };
};

class ShapePickerState {
  query: string = "";
  category: string = ALL_SHAPES_CATEGORY;
  results: SelectListOption[] = [];
  allEmoji: SelectListOption[] = [];

  constructor(value: string) {
    this.allEmoji = shapeIndex.search("").map(makeEmojiListItem);
    this.queryShapes();
    // TODO: if we add more categories, set initial category based on value.
  }

  setCategory(category: string) {
    this.category = category;
    this.queryShapes();
  }

  private async queryShapes() {
    const queryLower = this.query.toLowerCase();
    if (queryLower === "") {
      this.results = [];
      if (this.category === ALL_SHAPES_CATEGORY || this.category === CUTTLE_SHAPES_CATEGORY) {
        this.results = this.results.concat(cuttleShapes);
      }
      if (this.category === ALL_SHAPES_CATEGORY || this.category === EMOJI_CATEGORY) {
        this.results = this.results.concat(this.allEmoji);
      }
    } else {
      this.results = [];
      if (this.category === ALL_SHAPES_CATEGORY || this.category === CUTTLE_SHAPES_CATEGORY) {
        const querySplit = queryLower.split(" ").filter((word) => word.length > 0);
        const cuttleResults = cuttleShapes.filter((shape) =>
          querySplit.some((queryWord) => shape.keywords?.includes(queryWord))
        );
        this.results = this.results.concat(cuttleResults);
      }
      if (this.category === ALL_SHAPES_CATEGORY || this.category === EMOJI_CATEGORY) {
        const emojiResults = shapeIndex.search(queryLower).map(makeEmojiListItem);
        this.results = this.results.concat(emojiResults);
      }
    }
    // Async after typing
    m.redraw();
  }
  private queryShapesDebounced = debounce(this.queryShapes.bind(this), 200);

  setQuery(query: string) {
    this.query = query;
    this.queryShapesDebounced();
  }
}

function makeEmojiListItem(emoji: EmojiData): SelectListOption {
  const { name, unified, unicode } = emoji;
  const src = `${canonicalAssetsOrigin}/noto-emoji-600/${unified}.svg`;
  return {
    label: name,
    value: src,
    render: () =>
      m(Tooltipped, { message: () => name }, m("img", { loading: "lazy", src, alt: unicode })),
  };
}
