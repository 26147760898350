import { assert } from "../geom";
import { CodeComponent, Component } from "../model/component";
import { Element } from "../model/element";
import { ComponentFocus } from "../model/focus";
import { Instance } from "../model/instance";
import { Modifier } from "../model/modifier";
import { Project } from "../model/project";
import { isBuiltin } from "../model/registry";
import { SelectableInstance } from "../model/selectable";
import { PortableProjectData } from "../model/snapshot";
import { toastState } from "./toast-message";

/** Also used to import components and dependencies from another project.
 * Mutates and returns PortableProjectData with unique names and relinked
 * dependencies. */
export function pastePortableProjectData(
  project: Project,
  portableProjectData: PortableProjectData
) {
  const { elements, instances, components, modifiers, projectParameters, componentParameters } =
    portableProjectData;

  const firstComponent = firstComponentForComponentOnlyPortableProjectData(portableProjectData);
  // Must be focused on a Component to paste elements (but we can continue to
  // import "dependency" Components without elements with any focus)
  if (elements.length > 0 && !(project.focus instanceof ComponentFocus)) {
    toastState.showBasic({
      type: "error",
      message: `Can't paste that here.`,
      nextStep: "Focus a component to paste that.",
    });
    return;
  }

  for (let parameter of projectParameters) {
    if (!project.hasParameterWithName(parameter.name)) {
      project.parameters.push(parameter);
    }
  }

  if (project.focus instanceof ComponentFocus) {
    for (let parameter of componentParameters) {
      if (!project.focus.component.hasParameterWithName(parameter.name)) {
        project.focus.component.parameters.push(parameter);
      }
    }
  }

  const dependencyMap = new Map();

  for (let component of components) {
    if (isBuiltin(component)) {
      console.warn("Paste contained a built-in component!", component);
      continue;
    }

    const existing = project.componentByName(component.name);
    if (existing) {
      if (
        elements.length > 0 &&
        project.focus instanceof ComponentFocus &&
        existing === project.focus.component
      ) {
        toastState.showBasic({
          type: "error",
          message: `Can't paste an instance of “${component.name}” into itself.`,
          nextStep: "You can duplicate this component and paste there.",
        });
        return;
      }
      if (
        firstComponent &&
        (existing.name === firstComponent.name || existing.id === firstComponent.id)
      ) {
        toastState.showBasic({
          type: "error",
          message: `“${existing.name}” already exists.`,
          nextStep:
            "If you want another version, you can choose “Duplicate Component” in the component menu.",
        });
        return;
      }
      dependencyMap.set(component, existing);
    } else {
      // This is a new component that we need to add to the project.
      if (component.isImmutable) {
        // As long as hidden immutable components are in the same array as user
        // components, putting them at the start makes things like reordering
        // simpler.
        project.insertComponent(component, 0);
      } else {
        project.insertComponent(component);
      }
      dependencyMap.set(component, component);
    }
  }

  for (let modifier of modifiers) {
    if (isBuiltin(modifier)) {
      console.warn("Paste contained a built-in modifier!", modifier);
      continue;
    }
    const existing = project.modifierByName(modifier.name);
    if (existing) {
      dependencyMap.set(modifier, existing);
    } else {
      project.modifiers.push(modifier);
      dependencyMap.set(modifier, modifier);
    }
  }

  const relink = (item: Component | CodeComponent | Modifier | Element | Instance) => {
    if (isBuiltin(item)) return;
    if (item instanceof Instance) {
      // Only definitions (Component, CodeComponent, Modifier) will be builtins.
      if (isBuiltin(item.definition)) return;
      item.definition = dependencyMap.get(item.definition);
      assert(item.definition, "item.definition not found while relinking during paste or import.");
    } else if (item instanceof Element) {
      item.allInstances().forEach(relink);
      item.children.forEach(relink);
    } else if (item instanceof Component) {
      relink(item.element);
    } else if (item instanceof CodeComponent) {
      // Nothing needs to be done.
    } else if (item instanceof Modifier) {
      // Nothing needs to be done.
    }
  };

  elements.forEach(relink);
  components.forEach(relink);
  modifiers.forEach(relink);

  // If the paste only contained components, then we need to return something
  // from this function to indicate the the paste was successful. Return the
  // first pasted component.
  if (firstComponent) {
    return firstComponent;
  }

  const selectionNodes = project.selection.allNodes();
  if (instances.length && selectionNodes.items.length) {
    // Paste copied modifiers on selected elements
    const toSelect: SelectableInstance[] = [];
    for (let selectableNode of selectionNodes.items) {
      const element = selectableNode.node.source;
      for (let instance of instances) {
        const instanceClone = instance.clone();
        element.pasteModifier(instanceClone);
        toSelect.push(new SelectableInstance(selectableNode.node, instanceClone));
      }
    }
    project.selectItems(toSelect);
  } else if (elements.length > 0) {
    // Or, paste elements
    const { insertionParent, insertionIndex } = project.insertionPoint({
      forbidCollapsedParentPath: true,
    });
    const nodes = project.spliceNodes([], elements, insertionParent, insertionIndex);
    project.selectNodes(nodes);

    const elementNameGenerator = project.makeElementNameGenerator();
    elements.forEach((element) => {
      project.ensureUniqueNames(element, elementNameGenerator);
    });
  } else {
    // Clear selection if not pasting elements or instances
    project.selection.clear();
  }

  return project.selection;
}

/**
 * @returns a single component if the portable project data only contains
 * components. This means it was created from a component copy and the rest of
 * the components in the data are dependencies. (portable project data cannot
 * contain built-in components).
 */
const firstComponentForComponentOnlyPortableProjectData = (
  portableProjectData: PortableProjectData
) => {
  if (
    portableProjectData.elements.length === 0 &&
    portableProjectData.instances.length === 0 &&
    portableProjectData.components.length > 0
  ) {
    return portableProjectData.components[0];
  }
  return undefined;
};
