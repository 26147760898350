import { extensionFromPath } from "./util";

const localImageFileTypes = [
  //
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/webp",
];
const uploadableImageFileTypes = [
  //
  ...localImageFileTypes,
  "image/tiff",
  "image/heic",
  "image/heif",
];
export const imageFileTypes = [
  //
  ...uploadableImageFileTypes,
  "image/svg+xml",
];

const uploadableFontTypes = [
  //
  "font/ttf",
  "font/otf",
  "font/woff",
  "font/woff2",
];
export const fontFileTypes = [
  //
  ...uploadableFontTypes,
  "font/ttc",
];

const knownFileTypes = [...imageFileTypes, ...fontFileTypes];

export const fontMimeTypesByFileExtension: Record<string, string | undefined> = {
  otf: "font/otf",
  ttc: "font/ttc",
  ttf: "font/ttf",
  woff: "font/woff",
  woff2: "font/woff2",
};
export const imageMimeTypesByFileExtension: Record<string, string | undefined> = {
  bmp: "image/bmp",
  gif: "image/gif",
  heic: "image/heic",
  heif: "image/heif",
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  png: "image/png",
  svg: "image/svg+xml",
  tif: "image/tiff",
  tiff: "image/tiff",
  webp: "image/webp",
};

const mimeTypesByFileExtension: Record<string, string | undefined> = {
  ...fontMimeTypesByFileExtension,
  ...imageMimeTypesByFileExtension,
};

/**
 * Check for a known file type, falling back to a known file extension.
 * Ref: https://textslashplain.com/2018/07/26/be-skeptical-of-client-reported-mime-content-types/
 */
export const mimeTypeForFile = (file: File) => {
  const { type } = file;
  if (type && knownFileTypes.includes(type)) {
    return type;
  }
  const extension = extensionFromPath(file.name);
  if (extension && mimeTypesByFileExtension.hasOwnProperty(extension)) {
    return mimeTypesByFileExtension[extension];
  }
  return undefined;
};

export const isSupportedSVGFile = (file: File) => {
  return mimeTypeForFile(file) === "image/svg+xml";
};
export const isSupportedFontFile = (file: File) => {
  const type = mimeTypeForFile(file);
  if (!type) return false;
  return fontFileTypes.includes(type);
};
export const isSupportedUploadableFontFile = (file: File) => {
  const type = mimeTypeForFile(file);
  if (!type) return false;
  return uploadableFontTypes.includes(type);
};
export const isSupportedUploadableImageFile = (file: File) => {
  const type = mimeTypeForFile(file);
  if (!type) return false;
  return uploadableImageFileTypes.includes(type);
};
export const isSupportedLocalImageFile = (file: File) => {
  const type = mimeTypeForFile(file);
  if (!type) return false;
  return localImageFileTypes.includes(type);
};
