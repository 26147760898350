import {
  BoundingBox,
  CubicSegment,
  GEOMETRIC_TOLERANCE,
  Geometry,
  LineSegment,
  OverlapResult,
  OverlapSpan,
  PrimitiveOverlapResult,
  PrimitiveSpan,
  approximatelyEqual,
  areaOfInterestForPartitionedGeometries,
  assert,
  pairwiseGroup,
  pairwiseSplit,
  primitivesFromGeometry,
} from "..";
import { isSegment } from "../geometry/primitive-utils";

export const partitionedGeometryOverlaps = (
  geometries1: Geometry[],
  geometries2: Geometry[],
  areaOfInterest?: BoundingBox,
  tolerance = GEOMETRIC_TOLERANCE
) => {
  if (tolerance <= 0) tolerance = GEOMETRIC_TOLERANCE;

  areaOfInterest = areaOfInterestForPartitionedGeometries(
    geometries1,
    geometries2,
    areaOfInterest,
    tolerance
  );

  const primitives1 = primitivesFromGeometry(geometries1, areaOfInterest);
  const primitives2 = primitivesFromGeometry(geometries2, areaOfInterest);

  const primResults: PrimitiveOverlapResult[] = [];
  for (const prim1 of primitives1) {
    for (const prim2 of primitives2) {
      if (prim1 === prim2) continue;
      const res = prim1._overlapWithPrimitive(prim2, tolerance);
      if (res) {
        primResults.push(res);
      }
    }
  }

  return pathOverlapsFromPrimitiveOverlaps(primResults);
};

export const geometryOverlaps = (
  geometries: Geometry[],
  areaOfInterest?: BoundingBox,
  tolerance = GEOMETRIC_TOLERANCE
) => {
  if (tolerance <= 0) tolerance = GEOMETRIC_TOLERANCE;
  const primitives = primitivesFromGeometry(geometries, areaOfInterest);

  const primResults: PrimitiveOverlapResult[] = [];
  const n = primitives.length;
  for (let i1 = 0; i1 < n; ++i1) {
    const p1 = primitives[i1];
    for (let i2 = i1 + 1; i2 < n; ++i2) {
      const p2 = primitives[i2];
      const overlap = p1._overlapWithPrimitive(p2, tolerance);
      if (overlap) {
        primResults.push(overlap);
      }
    }
  }

  return pathOverlapsFromPrimitiveOverlaps(primResults);
};

/**
 * Canonical overlap ordering:
 * - No duplicate overlaps.
 * - Overlaps are grouped by their first source path, then by their second.
 * - For each overlap, `prim1` always comes before `prim2`.
 *
 * @param primOverlaps Must be an array of primitive overlaps in canonical
 * ordering.
 *
 * @internal
 */
const pathOverlapsFromPrimitiveOverlaps = (primResults: PrimitiveOverlapResult[]) => {
  const results: OverlapResult[] = [];

  const pathResults = onlyDefined(primResults.map((res) => overlapFromSegmentOverlap(res)));
  const resultsGroupedByPaths = pairwiseGroup(pathResults, (a, b) => overlapsSharePaths(a, b));
  for (const group of resultsGroupedByPaths) {
    // Sort results by the first time of path2. We rely on the fact that we
    // iterate over primitive pairs in a certain order. Span1 will already be
    // sorted due to the order of iteration, so all we need to do is sort span2.
    group.sort((a, b) => a.span2.time1 - b.span2.time1);
    const connectedResults = pairwiseSplit(group, (a, b) => !isConnectedAssumingOpenPaths(a, b));
    const mergedResults = connectedResults.map(overlapByMergingConnected);
    mergeLoopConnected(mergedResults);
    results.push(...mergedResults);
  }

  return results;
};

const overlapsSharePaths = (res1: OverlapResult, res2: OverlapResult) => {
  return res1.span1.path === res2.span1.path && res1.span2.path === res2.span2.path;
};

const onlyDefined = <T>(array: (T | undefined)[]): T[] => {
  return array.filter((e) => e !== undefined) as T[];
};

const overlapFromSegmentOverlap = (res: PrimitiveOverlapResult): OverlapResult | undefined => {
  const span1 = overlapSpanFromSegmentSpan(res.span1);
  if (span1 === undefined) return undefined;
  const span2 = overlapSpanFromSegmentSpan(res.span2);
  if (span2 === undefined) return undefined;
  return { span1, span2 };
};

const overlapSpanFromSegmentSpan = (span: PrimitiveSpan): OverlapSpan | undefined => {
  const segment = span.primitive;
  if (!isSegment(segment)) return undefined;
  const { source } = segment;
  if (!source) return undefined;
  return {
    path: source.path,
    time1: source.time + span.time1,
    time2: source.time + span.time2,
  };
};

const overlapByMergingConnected = (results: OverlapResult[]) => {
  assert(results.length > 0);
  const firstRes = results[0];
  const lastRes = results[results.length - 1];

  const res: OverlapResult = {
    span1: {
      path: firstRes.span1.path,
      time1: Math.min(firstRes.span1.time1, lastRes.span1.time1),
      time2: Math.max(firstRes.span1.time2, lastRes.span1.time2),
    },
    span2: {
      path: firstRes.span2.path,
      // Results are pre-sorted by span2.time1, so we can just take the first
      // and last times for these.
      time1: firstRes.span2.time1,
      time2: lastRes.span2.time2,
    },
  };

  return res;
};

const mergeLoopConnected = (results: OverlapResult[]) => {
  for (let i = 1; i < results.length; ++i) {
    const res1 = results[i - 1];
    const res2 = results[i];
    if (isLoopConnected(res1, res2)) {
      // Merge res2 into res1.
      res1.span1 = spanByMergingLoopConnectedSpans(res1.span1, res2.span1);
      res1.span2 = spanByMergingLoopConnectedSpans(res1.span2, res2.span2);
      // Remove res2 from the array and rewind the current step.
      results.splice(i--, 1);
    }
  }
};

const spanByMergingLoopConnectedSpans = (
  res1span: OverlapSpan,
  res2span: OverlapSpan
): OverlapSpan => {
  const { path } = res1span;
  const loopTime = path.anchors.length;
  if (res1span.time1 % loopTime === res2span.time2 % loopTime) {
    const minTime = Math.min(res1span.time2, res2span.time1);
    const maxTime = Math.max(res1span.time2, res2span.time1);
    if (res1span.time1 === res2span.time2) {
      return { path, time1: minTime, time2: maxTime };
    } else {
      return { path, time1: maxTime, time2: minTime + loopTime };
    }
  } else if (res1span.time2 % loopTime === res2span.time1 % loopTime) {
    const minTime = Math.min(res1span.time1, res2span.time2);
    const maxTime = Math.max(res1span.time1, res2span.time2);
    if (res1span.time2 === res2span.time1) {
      return { path, time1: minTime, time2: maxTime };
    } else {
      return { path, time1: maxTime, time2: minTime + loopTime };
    }
  }
  assert(false); // Shouldn't be able to get here..
};

const isLoopConnected = (res1: OverlapResult, res2: OverlapResult) => {
  const path1 = res1.span1.path;
  const path2 = res1.span2.path;

  // Make sure there's a stitch point on both. Sometimes one result can contain
  // two copies of the same span.
  if (
    !path1.isSameTime(res1.span1.time2, res2.span1.time1) &&
    !path1.isSameTime(res1.span1.time1, res2.span1.time2)
  ) {
    return false;
  }
  if (
    !path2.isSameTime(res1.span2.time2, res2.span2.time1) &&
    !path2.isSameTime(res1.span2.time1, res2.span2.time2)
  ) {
    return false;
  }

  if (path1.closed) {
    const loopTime = path1.anchors.length;
    if (res1.span1.time1 === 0 && res2.span1.time2 === loopTime) return true; // Forward
    if (res2.span1.time1 === 0 && res1.span1.time2 === loopTime) return true; // Reverse
  }
  if (path2.closed) {
    const loopTime = path2.anchors.length;
    if (res1.span2.time1 === 0 && res2.span2.time2 === loopTime) return true; // Forward
    if (res2.span2.time1 === 0 && res1.span2.time2 === loopTime) return true; // Reverse
  }

  return false;
};

const isConnectedAssumingOpenPaths = (res1: OverlapResult, res2: OverlapResult) => {
  // Spans of the second path must be connected, since they are sorted this way.
  // We know that the overlaps passed in will be between segments, so we can use
  // approximate functions here.
  if (!approximatelyEqual(res1.span2.time2, res2.span2.time1)) return false;

  if (approximatelyEqual(res1.span1.time2, res2.span1.time1)) return true;
  if (approximatelyEqual(res1.span1.time1, res2.span1.time2)) return true;

  return false;
};

export const segmentOverlap = (
  segment1: LineSegment | CubicSegment,
  segment2: LineSegment | CubicSegment,
  tolerance: number
): PrimitiveOverlapResult | undefined => {
  let s1 = segment1.timeAtPoint(segment2.s, tolerance);
  let e1 = segment1.timeAtPoint(segment2.e, tolerance);
  let s2 = segment2.timeAtPoint(segment1.s, tolerance);
  let e2 = segment2.timeAtPoint(segment1.e, tolerance);

  // TODO: This will fail to return an overlap between two cubic segments if the
  // `timeAtPointOnCubic` result is ambiguous. This could happen if the point
  // lies on coincident `s` and `e` points, or any other point where the cubic
  // crosses itself. A possible solution would be to add a `timesAtPoint`
  // function that returns all roots that fall within a tolerance, and then
  // check against all combinations of those until we find an overlap. This
  // would complicate this function significantly though, and it seems like this
  // is not a common situation in real world use.

  if (s1 !== undefined && e1 !== undefined) {
    // segment2 is contained within segment1.
    return segmentSlicesOverlap(segment1, s1, e1, segment2, 0, 1, tolerance);
  }
  if (s2 !== undefined && e2 !== undefined) {
    // segment1 is contained within segment2.
    return segmentSlicesOverlap(segment1, 0, 1, segment2, s2, e2, tolerance);
  }

  if (s1 !== undefined && s2 !== undefined) {
    // The start of segment2 overlaps the start of cubic2.
    return segmentSlicesOverlap(segment1, 0, s1, segment2, 0, s2, tolerance);
  }
  if (s1 !== undefined && e2 !== undefined) {
    // The start of segment2 overlaps the end of segment1.
    return segmentSlicesOverlap(segment1, s1, 1, segment2, 0, e2, tolerance);
  }

  if (s2 !== undefined && e1 !== undefined) {
    // The end of segment2 overlaps the start of segment1.
    return segmentSlicesOverlap(segment1, 0, e1, segment2, s2, 1, tolerance);
  }
  if (e1 !== undefined && e2 !== undefined) {
    // The end of segment2 overlaps the end of segment1.
    return segmentSlicesOverlap(segment1, e1, 1, segment2, e2, 1, tolerance);
  }

  return undefined;
};

/**
 * Finds any overlap between two segment spans.
 *
 * Caller can rely that time1 <= time2.
 *
 * @internal
 */
const segmentSlicesOverlap = (
  segment1: LineSegment | CubicSegment,
  s1: number,
  e1: number,
  segment2: LineSegment | CubicSegment,
  s2: number,
  e2: number,
  tolerance: number
): PrimitiveOverlapResult | undefined => {
  // Prevent endpoints overlapping.
  if (approximatelyEqual(s1, e1) || approximatelyEqual(s2, e2)) {
    return undefined;
  }

  const isStraight1 = segment1.isStraight();
  const isStraight2 = segment2.isStraight();
  // Segments must both be straight or not straight to overlap.
  if (isStraight1 !== isStraight2) {
    return undefined;
  }

  // Enforce that s1 <= e1 and e1 <= e2. This invariant must be maintained until
  // the function returns.
  if (s1 > e1) [s1, e1] = [e1, s1];
  if (s2 > e2) [s2, e2] = [e2, s2];

  const slice1 = segment1.slice(s1, e1);
  // Bail if the slice is smaller than the tolerance.
  // TODO: Maybe compute the hull length here for efficiency?
  if (slice1.length() <= tolerance) return undefined;

  const slice2 = segment2.slice(s2, e2);
  // Bail if the slice is smaller than the tolerance.
  if (slice2.length() <= tolerance) return undefined;

  let isOverlap = false;

  if (isStraight1 && isStraight2) {
    // If the segments are straight then they must overlap.
    isOverlap = true;
  } else if (slice1 instanceof CubicSegment && slice2 instanceof CubicSegment) {
    // If we found an overlap, the sliced segments will be equal.
    isOverlap =
      slice1._almostEquals(slice2, tolerance) || slice1._almostEqualsReverse(slice2, tolerance);
  }

  if (isOverlap) {
    // Snap times that are very close to the endpoints. This simplifies
    // comparisons later when merging overlaps. Baking in the tolerance here
    // allows use of simple approximate comparisons later when we don't have
    // slice endpoint info.
    const segment1Len = segment1.length();
    const segment2Len = segment2.length();
    if (s1 * segment1Len <= tolerance) s1 = 0;
    if ((1 - e1) * segment1Len <= tolerance) e1 = 1;
    if (s2 * segment2Len <= tolerance) s2 = 0;
    if ((1 - e2) * segment2Len <= tolerance) e2 = 1;

    return {
      span1: { primitive: segment1, time1: s1, time2: e1 },
      span2: { primitive: segment2, time1: s2, time2: e2 },
    };
  }

  return undefined;
};
