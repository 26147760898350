import m from "mithril";
import { createPopup } from "./popup";

/** Spawns a popup with a form with one text input. */
export const createPopupPrompt = (
  options: {
    spawnFrom: HTMLElement | { x: number; y: number };
  } & PromptAttributes
) => {
  const { spawnFrom, label, initialValue, onsubmit, placeholder, note, autofocus, oncancel } =
    options;
  const promptPopup = createPopup({
    spawnFrom,
    view: () =>
      m(Prompt, {
        label,
        initialValue,
        placeholder,
        note,
        autofocus,
        onsubmit: (str) => {
          promptPopup.close();
          onsubmit(str);
        },
        oncancel: () => {
          promptPopup.close();
        },
      }),
    overlay: true,
    onclose: () => {
      oncancel?.();
    },
  });
};

interface PromptAttributes {
  label: string;
  initialValue?: string;
  onsubmit: (str: string) => void;
  oncancel?: () => void;
  placeholder?: string;
  note?: string;
  autofocus?: boolean;
}
/** Basic form with one text input, that sends the text value on submit. */
const Prompt: m.Component<PromptAttributes> = {
  view: ({ attrs: { label, placeholder, note, autofocus, onsubmit, oncancel } }) => {
    const onkeyup = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        oncancel?.();
      }
    };
    return m(".prompt", [
      m(
        "form",
        {
          onsubmit: (e: SubmitEvent) => {
            e.preventDefault();
            onsubmit(
              ((e.target as HTMLFormElement).querySelector(".prompt-input") as HTMLInputElement)
                .value
            );
          },
        },
        [
          m("label", {}, [
            label,
            m("input.prompt-input", {
              type: "text",
              placeholder,
              autofocus,
              onkeyup,
            }),
          ]),
          note && m(".prompt-note", note),
          m("input", { type: "submit", value: "OK" }),
        ]
      ),
    ]);
  },
  oncreate: ({ dom, attrs: { autofocus, initialValue } }) => {
    // `autofocus` attribute on text input doesn't work when there is already an
    // element with focus, eg contenteditable text.
    if (autofocus) {
      (dom.querySelector(".prompt-input") as HTMLInputElement)?.focus();
    }
    // initialValue pattern without ClosureComponent
    if (initialValue) {
      (dom.querySelector(".prompt-input") as HTMLInputElement).value = initialValue;
    }
  },
};
