import { AffineMatrix, Vec } from "../../geom/index";
import { globalState } from "../../global-state";
import { CanvasPoint } from "../../model/canvas-point";
import { contextTransformMatrixForNode } from "../../model/transform-utils";
import {
  constrainCanvasPoint,
  idealUnitSquareScaleForContext,
  minimumPrecisionPositionForCanvasPointInContext,
  worldPositionFromEvent,
} from "../util";
import { CanvasInterfaceElement } from "./canvas-interface-element";

export class DefinitionDragUI implements CanvasInterfaceElement {
  readonly id = "definition-drag";

  cursor() {
    return "select";
  }

  hitTest(worldPosition: Vec) {
    if (globalState.definitionDrag) {
      return { isHit: true };
    }
    return undefined;
  }

  onPointerEnter(event: PointerEvent) {
    const { definitionDrag } = globalState;
    if (!definitionDrag) return;

    // We must activate the tool before caching snapping data since snapping
    // data is cleared between tools.
    globalState.activateTool("Select");

    // NOTE: We might need to check if we can insert before updating the
    // snapping state. Does this have side effects if we don't actually create
    // geometry and start a drag?
    {
      globalState.updateGhostGeometry();

      if (globalState.deviceStorage.geometrySnappingEnabled) {
        globalState.snapping.cacheSnappingDataForInterface(globalState.interfaceElements);
      }
    }
  }

  onPointerMove(event: PointerEvent) {
    const { definitionDrag } = globalState;
    if (!definitionDrag) return;

    if (!definitionDrag.node) {
      const { insertionParent, insertionIndex } = globalState.project.insertionPoint({
        forbidCollapsedParentPath: true,
      });
      if (insertionParent !== undefined) {
        definitionDrag.tryInsert(insertionParent, insertionIndex);
      }
    }

    const transformer = definitionDrag.transformer();
    if (!transformer) return;

    const parentNode = definitionDrag.parentNode();
    if (!parentNode) return;

    const currentPoint = new CanvasPoint(worldPositionFromEvent(event));
    if (globalState.deviceStorage.geometrySnappingEnabled) {
      globalState.snapping.updateSnappingPointNearWorldPosition(currentPoint.worldPosition);
    }
    constrainCanvasPoint(currentPoint);

    const contextMatrix = contextTransformMatrixForNode(parentNode);
    if (contextMatrix) {
      const inverseContextMatrix = contextMatrix.clone().invert();
      const { contextPosition } = minimumPrecisionPositionForCanvasPointInContext(
        currentPoint,
        inverseContextMatrix
      );
      let scale: number | "auto" | undefined | Vec = definitionDrag.initialScale();
      if (scale === "auto") {
        // This is really reserved for unit size shapes, so I'm not sure how
        // generically applicable this is outside of built in components. Maybe
        // emoji?
        scale = idealUnitSquareScaleForContext(inverseContextMatrix);
      }
      const preserveScale = scale === undefined;
      transformer.transformContext(
        AffineMatrix.fromTransform({ position: contextPosition, scale }),
        { preserveScale }
      );
    }
  }

  onPointerUp(event: PointerEvent) {
    globalState.clearGhostGeometry();
    globalState.snapping.clear();
  }

  onPointerLeave(event: PointerEvent) {
    const { definitionDrag } = globalState;
    if (definitionDrag) {
      definitionDrag.remove();
    }
    this.onPointerUp(event);
  }
}
