import m from "mithril";

import { debounce } from "./shared/util";
import { globalState } from "./global-state";

/**
 * In general we are aggressive about calling
 * `globalState.checkpointAfterNextEvaluation()`. We do it after any browser
 * event that might have caused a change in project state. If there was no
 * change, the current snapshot will be equal to the previous one so the
 * checkpoint will not modify the undo stack. However, sometimes we want to
 * coalesce checkpoints so that we don't create lots of very similar snapshots
 * in the undo stack. For example: typing a word rapidly, nudging with key
 * repeat, or any drag gesture. There are two ways to control this:
 *
 * 1. You can set `globalState.isMidGesture = true`. This will turn off
 *    checkpointing entirely. Of course remember to set it back to false when
 *    the gesture is complete.
 * 2. You can call `globalState.markNextCheckpointIsAmend(true)`. This will
 *    cause the next frame's checkpoint to be an amend, meaning it will coalesce
 *    onto the previous one. Use this for "key repeat" style interactions.
 *    Conversely, if you know an edit should _not_ be an amend, calling
 *    `globalState.markNextCheckpointIsAmend(false)` will force the next
 *    checkpoint to be a commit.
 */

const isModifierKey = (key: string) => {
  return key === "Shift" || key === "Alt" || key === "Control" || key === "Meta";
};

const debouncedCheckpoint = debounce(() => {
  globalState.checkpointAfterNextEvaluation();

  // Need to trigger a redraw since this will be called from outside a mithril
  // event handler.
  m.redraw();
}, 500);

export const registerCheckpointEventHandlers = () => {
  window.addEventListener(
    "keydown",
    (event: KeyboardEvent) => {
      if (!isModifierKey(event.key)) {
        // Pressing a modifier key should never trigger a checkpoint
        debouncedCheckpoint();
      }
    },
    // Use capture to make sure we checkpoint on every keydown
    true
  );

  // Checkpoint on pointer down so that immaterial state like the selection is
  // restored on undo.
  window.addEventListener("pointerdown", () => globalState.checkpointAfterNextEvaluation(), true);
  window.addEventListener("pointerup", () => globalState.checkpointAfterNextEvaluation(), true);

  document.addEventListener("cut", () => globalState.checkpointAfterNextEvaluation());
  document.addEventListener("paste", () => globalState.checkpointAfterNextEvaluation());

  // Wheel events don't come in start/stop pairs, but we want to pause
  // evaluation while the user is zooming in order to avoid smashing the API
  // with snapshot saves. Create a debounced checkpointing function that will
  // only trigger some time after the last event occurs. This can be used for
  // both wheel and trackpad gestures.
  window.addEventListener("wheel", debouncedCheckpoint, true);
  window.addEventListener("gesturestart", debouncedCheckpoint, true);
  window.addEventListener("gesturechange", debouncedCheckpoint, true);
  window.addEventListener("gestureend", debouncedCheckpoint, true);
};
