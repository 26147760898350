import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { initCuttleGeometry } from "./geom";
import { imageManager } from "./image-manager";

export async function initCuttle() {
  // Set up dayjs with the RelativeTime extension.
  // https://day.js.org/docs/en/plugin/relative-time
  dayjs.extend(relativeTime);

  await initCuttleGeometry((url) => imageManager.getImageFromURL(url));
}
