/**
 * Use this file to store debug flags. Generally these should all be false, but
 * you can set them to true when in development and debugging certain aspects of
 * the editor.
 */

export const config = ((window as any).cuttleConfig = {
  /**
   * This will turn on a debug view in the outline that shows which elements are
   * memoized (with a ⚡️) and which of these were computed on the last frame (red
   * background). You can click a ⚡️ to `console.log` the memoization entry.
   */
  showMemoizationInOutline: false,

  /**
   * Traces should not be mutated after they are created. If you turn on this
   * flag, we'll deep freeze all trace results after they're created.
   */
  freezeTraces: false,

  /**
   * This will re-run the evaluation without memoization on and check that the
   * result is the same. Use this to test correctness of memoization.
   */
  checkMemoizationResults: false,
});
