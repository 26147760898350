import m from "mithril";

import { accountState } from "../shared/account";
import { logEvent } from "../shared/log-event";
import { modalState, ModalX } from "../shared/modal";
import { openUpgradeModal } from "../shared/upgrade-modal";

const DownloadCountModal: m.Component = {
  view() {
    const downloadsThisMonth = accountState.storageGet("downloadsThisMonth") ?? 0;
    const downloadsRemaining = 10 - downloadsThisMonth;
    return m(".modal-box.download-count-modal", [
      m("h1", "You’re making a lot of cool stuff!"),
      m(
        "h3",
        "Join ",
        m("a[href=/pricing][target=CuttlePricingWindow]", "Cuttle Pro"),
        " for unlimited downloads."
      ),
      m(
        "p.downloads-remaining",
        "You have ",
        downloadsRemaining > 0 ? m("strong", downloadsRemaining) : "no more",
        " free ",
        downloadsRemaining === 1 ? "download" : "downloads",
        " remaining this month."
      ),
      m(".editor-modal-actions", [
        m("button", { onclick: () => openUpgradeModal("downloads", true) }, "Join Cuttle Pro"),
        m("button.secondary", { onclick: () => modalState.close() }, "No thanks"),
      ]),
    ]);
  },
};

export const openDownloadCountModal = () => {
  const downloadsThisMonth = accountState.storageGet("downloadsThisMonth") ?? 0;
  logEvent("download count modal", { downloadsThisMonth });
  modalState.open({
    modalView: () => {
      return m(DownloadCountModal);
    },
  });
};
