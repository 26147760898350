import {
  AffineMatrix as AffineMatrixClass,
  Anchor as AnchorClass,
  Axis as AxisClass,
  BoundingBox as BoundingBoxClass,
  Color as ColorClass,
  CompoundPath as CompoundPathClass,
  Containment as ContainmentClass,
  CubicSegment as CubicSegmentClass,
  Fill as FillClass,
  Graphic,
  Group as GroupClass,
  ImageFill as ImageFillClass,
  LineSegment as LineSegmentClass,
  Path as PathClass,
  PathContainment,
  Ray as RayClass,
  RichText as RichTextClass,
  RichTextGlyph as RichTextGlyphClass,
  RichTextSymbol as RichTextSymbolClass,
  StrokeAlignment,
  StrokeCap,
  Stroke as StrokeClass,
  StrokeJoin,
  Vec as VecClass,
} from "../geom";

const copyClass = (destination: Function, source: Function) => {
  // Copy prototype
  destination.prototype = source.prototype;

  // Copy static methods
  for (let name of Object.getOwnPropertyNames(source)) {
    const prop = (source as any)[name];
    if (typeof prop === "function") {
      (destination as any)[name] = prop;
    }
  }
};

export const RichText = function (...args: ConstructorParameters<typeof RichTextClass>) {
  return new RichTextClass(...args);
};
copyClass(RichText, RichTextClass);
type RichText = RichTextClass;

export const RichTextGlyph = function (index: number) {
  return new RichTextGlyphClass(index);
};
copyClass(RichTextGlyph, RichTextGlyphClass);
type RichTextGlyph = RichTextGlyphClass;

export const RichTextSymbol = function (key: string) {
  return new RichTextSymbolClass(key);
};
copyClass(RichTextSymbol, RichTextSymbolClass);
type RichTextSymbol = RichTextSymbolClass;

export const Vec = function (x?: number, y?: number) {
  return new VecClass(x, y);
};
copyClass(Vec, VecClass);
type Vec = VecClass;

export const AffineMatrix = function (
  a?: number,
  b?: number,
  c?: number,
  d?: number,
  tx?: number,
  ty?: number
) {
  return new AffineMatrixClass(a, b, c, d, tx, ty);
};
copyClass(AffineMatrix, AffineMatrixClass);
type AffineMatrix = AffineMatrixClass;

export const BoundingBox = function (min?: Vec, max?: Vec) {
  return new BoundingBoxClass(min, max);
};
copyClass(BoundingBox, BoundingBoxClass);
type BoundingBox = BoundingBoxClass;

export const Color = function (r?: number, g?: number, b?: number, a?: number) {
  return new ColorClass(r, g, b, a);
};
copyClass(Color, ColorClass);
type Color = ColorClass;

export const Stroke = function (
  color?: Color,
  hairline?: boolean,
  width?: number,
  alignment?: StrokeAlignment,
  cap?: StrokeCap,
  join?: StrokeJoin,
  miterLimit?: number
) {
  return new StrokeClass(color, hairline, width, alignment, cap, join, miterLimit);
};
copyClass(Stroke, StrokeClass);
type Stroke = StrokeClass;

export const Fill = function (color?: Color) {
  return new FillClass(color);
};
copyClass(Fill, FillClass);
type Fill = FillClass;

export const ImageFill = function (image: string, opacity?: number, transform?: AffineMatrix) {
  return new ImageFillClass(image, opacity, transform);
};
copyClass(ImageFill, ImageFillClass);
type ImageFill = ImageFillClass;

export const Anchor = function (position?: Vec, handleIn?: Vec, handleOut?: Vec) {
  return new AnchorClass(position, handleIn, handleOut);
};
copyClass(Anchor, AnchorClass);
type Anchor = AnchorClass;

export const Axis = function (origin: Vec, direction: Vec) {
  return new AxisClass(origin, direction);
};
copyClass(Axis, AxisClass);
type Axis = AxisClass;

export const Ray = function (origin: Vec, direction: Vec) {
  return new RayClass(origin, direction);
};
copyClass(Ray, RayClass);
type Ray = RayClass;

export const LineSegment = function (s: Vec, e: Vec) {
  return new LineSegmentClass(s, e);
};
copyClass(LineSegment, LineSegmentClass);
type LineSegment = LineSegmentClass;

export const CubicSegment = function (s: Vec, cs: Vec, ce: Vec, e: Vec) {
  return new CubicSegmentClass(s, cs, ce, e);
};
copyClass(CubicSegment, CubicSegmentClass);
type CubicSegment = CubicSegmentClass;

export const Path = function (anchors?: Anchor[], closed?: boolean, stroke?: Stroke, fill?: Fill) {
  return new PathClass(anchors, closed, stroke, fill);
};
copyClass(Path, PathClass);
type Path = PathClass;

export const CompoundPath = function (paths?: Path[], stroke?: Stroke, fill?: Fill) {
  return new CompoundPathClass(paths, stroke, fill);
};
copyClass(CompoundPath, CompoundPathClass);
type CompoundPath = CompoundPathClass;

export const Group = function (items?: Graphic[]) {
  return new GroupClass(items);
};
copyClass(Group, GroupClass);
type Group = GroupClass;

export const Containment = function (contained?: PathContainment[]) {
  return new ContainmentClass(contained);
};
copyClass(Containment, ContainmentClass);
type Containment = ContainmentClass;
