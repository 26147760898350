import m from "mithril";

import { globalState } from "../../global-state";
import { Instance } from "../../model/instance";
import { Modifier } from "../../model/modifier";
import { Node } from "../../model/node";
import { SelectableInstance } from "../../model/selectable";
import { Selection } from "../../model/selection";
import { Icon20 } from "../../shared/icon";
import { classNames } from "../../shared/util";
import { startDrag } from "../start-drag";
import { POINTER_EVENT_BUTTONS_NONE } from "../util";

interface ModifierBadgeAttrs {
  node: Node;
  modifier: Instance;
  index: number;
}
export const ModifierBadge: m.Component<ModifierBadgeAttrs> = {
  view({ attrs: { node, modifier, index } }) {
    const selectable = new SelectableInstance(node, modifier);

    const elementTrace = globalState.traceForNode(node);
    const instanceTrace = elementTrace?.modifiers.find((m) => m.source === modifier);
    const isError = instanceTrace && !instanceTrace.isSuccess();

    return m(
      ".modifier-badge",
      {
        onpointerover: (event: PointerEvent) => {
          if (event.buttons === POINTER_EVENT_BUTTONS_NONE) {
            event.stopPropagation();
            if (globalState.project.selectableExistsAndIsValid(selectable)) {
              globalState.project.hoveredItem = selectable;
            }
          }
        },
        onpointerdown: (event: PointerEvent) => {
          event.stopPropagation();
          startDrag(event, {
            cursor() {
              return "grabbing";
            },
            onConsummate() {
              let draggingSelection: Selection;
              if (globalState.project.selection.contains(selectable)) {
                draggingSelection = globalState.project.selection
                  .directlySelectedInstances()
                  .mutables();
              } else {
                draggingSelection = new Selection([selectable]);
              }
              if (!draggingSelection.isEmpty()) {
                globalState.modifierReorder = {
                  // We know all items are SelectableInstance because they were
                  // returned by directlySelectedInstances(). This could be
                  // better expressed in the Selection type.
                  selectableInstances: draggingSelection.items as SelectableInstance[],
                };
              }
            },
            onUp() {
              const { modifierReorder } = globalState;
              if (
                modifierReorder?.hoveredInsertionNode &&
                modifierReorder.hoveredInsertionIndex !== undefined
              ) {
                let { hoveredInsertionNode, hoveredInsertionIndex } = modifierReorder;
                // Remove dragged modifiers from their elements
                for (let { node, instance } of modifierReorder.selectableInstances) {
                  const index = node.source.modifiers.indexOf(instance);
                  if (
                    node.source === hoveredInsertionNode.source &&
                    index < hoveredInsertionIndex
                  ) {
                    hoveredInsertionIndex--;
                  }
                  node.source.modifiers.splice(index, 1);
                }
                // Insert dragged modifiers at the insertion index
                hoveredInsertionNode.source.modifiers.splice(
                  hoveredInsertionIndex,
                  0,
                  ...modifierReorder.selectableInstances.map((item) => item.instance)
                );
                globalState.project.selectItems(
                  modifierReorder.selectableInstances.map(({ instance }) => {
                    return new SelectableInstance(hoveredInsertionNode, instance);
                  })
                );
              }
              globalState.modifierReorder = null;
            },
            onCancel() {
              globalState.modifierReorder = null;
              if (event.shiftKey) {
                globalState.project.toggleSelectItem(selectable);
              } else {
                globalState.project.selectItem(selectable);
              }
            },
          });
        },
        className: classNames({
          selected: globalState.project.selection.contains(selectable),
          "selected-indirect": globalState.project.selection.isInstanceIndirectlySelected(
            selectable.instance
          ),
          hovered: globalState.project.isItemHovered(selectable),
          disabled: !modifier.isEnabled,
          error: isError,
        }),
      },
      [
        m(OutlineModifierReorderHitTarget, {
          where: "before",
          insertionNode: node,
          insertionIndex: index,
        }),
        m(".modifier-badge-pill", [
          modifier.definition instanceof Modifier && modifier.definition.icon !== undefined
            ? m(".modifier-badge-icon", m(Icon20, { icon: modifier.definition.icon }))
            : null,
          m(".modifier-badge-name", modifier.definition.name),
        ]),
        m(OutlineModifierReorderHitTarget, {
          where: "after",
          insertionNode: node,
          insertionIndex: index + 1,
        }),
      ]
    );
  },
};

interface OutlineModifierReorderHitTargetAttrs {
  where: "after" | "before" | "inside" | "end";
  insertionNode: Node;
  insertionIndex: number;
}
export const OutlineModifierReorderHitTarget: m.Component<OutlineModifierReorderHitTargetAttrs> = {
  view({ attrs: { where, insertionNode, insertionIndex } }) {
    if (!globalState.modifierReorder) return null;
    const onpointerenter = () => {
      if (!globalState.modifierReorder) return;
      globalState.modifierReorder.hoveredInsertionNode = insertionNode;
      globalState.modifierReorder.hoveredInsertionIndex = insertionIndex;
    };
    const onpointerleave = () => {
      if (!globalState.modifierReorder) return;
      globalState.modifierReorder.hoveredInsertionNode = undefined;
      globalState.modifierReorder.hoveredInsertionIndex = undefined;
    };
    const className = classNames({
      hovered:
        globalState.modifierReorder.hoveredInsertionNode?.equalsNode(insertionNode) &&
        globalState.modifierReorder.hoveredInsertionIndex === insertionIndex,
      [where]: true,
    });
    return m(".outline-modifier-reorder-hit-target", { className, onpointerenter, onpointerleave });
  },
};
