import m from "mithril";

import { IconSVG } from "./icon";

interface PlayButtonOverlayedAttrs {
  size: number;
  onclick?: () => void;

  // Set this to true to hide the play button (e.g. if we're overlaying a play
  // button on the video itself and we want to hide the play button once its
  // playing.)
  hidePlayButton?: boolean;
}
export const PlayButtonOverlayed: m.Component<PlayButtonOverlayedAttrs> = {
  view(vnode) {
    const { size, onclick, hidePlayButton } = vnode.attrs;

    const mOverlay = m(".play-button-overlay-container", { onclick }, [
      m(".play-button-overlay", { style: { width: `${size}px`, height: `${size}px` } }, [
        m(IconSVG, { icon: "play_button_overlay" }),
      ]),
    ]);

    return m(".play-button-overlayed", [vnode.children, hidePlayButton ? null : mOverlay]);
  },
};
