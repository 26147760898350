import m from "mithril";

import { Unit } from "../geom";
import { ContextSelectable } from "../model/selectable";
import { ExpressionTrace } from "../model/trace";
import { ObjectInspector } from "./object-inspector";

export interface EvaluationResultAttrs {
  expressionTrace: ExpressionTrace | undefined;
  selectable: ContextSelectable;
  units: Unit | undefined;
}
export const EvaluationResult: m.Component<EvaluationResultAttrs> = {
  view({ attrs: { expressionTrace, selectable, units } }) {
    if (expressionTrace?.isSuccess) {
      const result = expressionTrace.evaluationResult;
      return m(
        ".evaluation-result",
        m(ObjectInspector, { object: result, numberUnits: units, selectable })
      );
    }
    return;
  },
};
