import FileSaver from "file-saver";

import { Vec, assert } from "../geom";
import { globalState } from "../global-state";
import { CodeComponent, Component } from "../model/component";
import { SVGStringFromFramedGraphic } from "./export-svg";
import { framedGraphicFitToSize } from "./framed-graphic";

export const exportProjectExamples = () => {
  const snapshot = globalState.getProjectSnapshot().toString();
  const thumbnails: Record<string, string> = {};
  for (const component of globalState.project.components) {
    thumbnails[component.name] = thumbnailSVGStringForComponent(component);
  }
  const projectData = { snapshot, thumbnails };
  const exportString = JSON.stringify(projectData);
  const blob = new Blob([exportString], { type: "application/json" });

  const filename = `${globalState.storage.getProjectId()}.json`;
  FileSaver.saveAs(blob, filename);
};

const thumbnailSVGStringForComponent = (component: Component | CodeComponent) => {
  const trace = globalState.traceForComponent(component);
  assert(trace?.isSuccess());

  const framedGraphic = framedGraphicFitToSize(trace.result, new Vec(72), "px");
  assert(framedGraphic);

  const svgString = SVGStringFromFramedGraphic(framedGraphic, {
    hairlineStrokeWidth: 1,
    maximumFractionDigits: 2,
  });
  return svgString.replace(/\n/g, "");
};
