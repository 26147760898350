// These are used for escaping SVG comments, so `--` in the string doesn't

import { isArray, isObject } from "./geom";
import { assetsOrigin, canonicalAssetsOrigin } from "./shared/config";

// terminate the comment and break the SVG.
export const escapeHyphen = (s: string) => {
  return s.replace(/-|_/g, (char) => (char === "-" ? "_h" : "_u"));
};
export const unescapeHyphen = (s: string) => {
  return s.replace(/_h|_u/g, (char) => (char === "_h" ? "-" : "_"));
};

// To put a URL in an XML comment, we need to convert `-` and `_` to entities so
// that the URL doesn't get broken by our `escapeHyphen` calls.
export const escapeURLForXMLComment = (s: string) => {
  return s.replace(/-/g, "%2D").replace(/_/g, "%5F");
};

// Ref: https://github.com/dylang/node-xml/blob/master/lib/escapeForXML.js
const XML_CHARACTER_MAP: Record<string, string> = {
  "&": "&amp;",
  '"': "&quot;",
  "'": "&apos;",
  "<": "&lt;",
  ">": "&gt;",
};
export const escapeForXML = (s: string) => {
  return String(s).replace(/([&"<>'])/g, function (_str, item) {
    return XML_CHARACTER_MAP[item];
  });
};

export const escapeStringLiteral = (s: string) => {
  return s.replace(/\\/g, "\\\\").replace(/"/g, '\\"').replace(/\n/g, "\\n");
};
export const unescapeStringLiteral = (s: string) => {
  return s.replace(/\\n/g, "\n").replace(/\\"/g, '"').replace(/\\\\/g, "\\");
};

export const sanitizeName = (name: string) => {
  name = name.replace(/[^$_a-zA-Z0-9]/g, "");
  if (/[0-9]/.test(name[0])) {
    name = "_" + name;
  }
  return name;
};

/**
 * Returns a filename that ends with `extension` and contains no more than 255
 * characters, including the extension. Extensions are expected to start with
 * ".", for example ".svg", ".pdf".
 * @internal
 */
export const sanitizeFilename = (filename: string, extension: string) => {
  // If the filename includes an extension, remove it.
  let basename = filename;
  const dotIndex = filename.lastIndexOf(".");
  if (dotIndex > 0) {
    basename = basename.slice(0, dotIndex);
  }

  // Remove potentially unsupported characters from the filename. If the
  // filename contains a newline, Safari replaces the download filename with
  // "example.com", bizarrely. We are being overly conservative here, but we can
  // dial it back later if needed.
  basename = basename.replace(/\n+/g, " ").replace(/[^ ._\-a-zA-Z0-9]/g, "");

  // Remove whitespace from the beginning and end.
  basename = basename.trim();

  // Limit the filename to 255 characters, including extension. Safari has a
  // problem downloading filenames longer than this.
  basename = basename.slice(0, 255 - extension.length);

  return basename + extension;
};

/**
 * @returns a string with any number of trailing `char` removed.
 */
export const trimEnd = (s: string, char: string) => {
  let len = s.length;
  while (s.charAt(--len) === char);
  return s.substring(0, len + 1);
};

export interface Range {
  start: number;
  end: number;
}
export interface Substitution {
  range: Range;
  subranges: Range[];
  replacement: string;
}

/**
 * @returns the number of digits after the decimal place.
 */
export const precisionDigitsForNumberString = (str: string) => {
  // TODO: Deal with eE stuff.
  const decimalIndex = str.indexOf(".");
  if (decimalIndex === -1) return 0;
  return str.length - decimalIndex - 1;
};

export const performSubstitutions = (s: string, substitutions: Substitution[]) => {
  // Substitutions are not allowed to partially overlap, however they are
  // allowed to entirely contain each other.

  // We want to order the substitutions so that we perform the innermost ones
  // first. We can do this by sorting by the length of the range we're
  // replacing. If we replace shorter ranges first, we're guaranteed to replace
  // inner ranges before any range that contains them.
  const length = (substitution: Substitution) => substitution.range.end - substitution.range.start;
  substitutions.sort((a, b) => length(a) - length(b));

  while (substitutions.length > 0) {
    const substitution = substitutions.shift() as Substitution;
    const { start, end } = substitution.range;

    const before = s.substring(0, start);
    const after = s.substring(end);

    const substrings = substitution.subranges.map((range) => s.substring(range.start, range.end));
    let replacement = substitution.replacement;
    substrings.forEach((substring, i) => {
      const regexp = new RegExp("\\$" + i, "g");
      replacement = replacement.replace(regexp, substring);
    });

    // Perform the replacement
    s = before + replacement + after;

    // Calculate the offset. This is the amount that any character indices after
    // the substitution need to be adjusted by so that they're still pointing at
    // the correct spot in the string.
    const offset = replacement.length - (end - start);

    // Make the offset adjustment on the remaining substitutions.
    const adjustRange = (range: Range) => {
      if (range.start > start) {
        range.start += offset;
      }
      if (range.end > start) {
        range.end += offset;
      }
    };
    substitutions.forEach((substitution) => {
      adjustRange(substitution.range);
      substitution.subranges.forEach(adjustRange);
    });
  }

  return s;
};

/**
 * Returns a duplicate-free version of the array using === equality.
 */
export const unique = <T>(arr: T[]) => {
  const result: T[] = [];
  for (let item of arr) {
    if (result.indexOf(item) === -1) {
      result.push(item);
    }
  }
  return result;
};

export const alertAndThrow = (message: string) => {
  window.alert(message);
  throw new Error(message);
};

/**
 * Note: you can't get this information after init, because `refreshProjectUrl`
 * sets the URL to the canonical version, which removes the search params.
 */
export const urlParts: (url?: string) => {
  projectId: string | undefined;
  edit: boolean;
  view: boolean;
  embed: boolean;
} = (url?: string) => {
  const location = url ? new URL(url) : window.location;
  const { search, pathname } = location;

  const params = new URLSearchParams(search);
  const edit = params.get("edit") !== null;
  const view = params.get("view") !== null;
  const embed = params.get("embed") !== null;

  const parts = pathname.split("/");
  const [_, user, projectTitleAndId] = parts;
  if (user === "intro") {
    // https://cuttle.xyz/intro
    return { projectId: "_intro", edit: true, view: false, embed: false };
  }
  if (user === "" || user === "editor") {
    // Stagiing URLs like https://branch-name--cuttle-editor.netlify.app/editor/
    return { projectId: "_staging", edit: true, view: false, embed };
  }
  const words = projectTitleAndId ? projectTitleAndId.split("-") : [];
  const projectId = words.length ? words[words.length - 1] : undefined;

  return { projectId, edit, view, embed };
};

export async function asyncTimeout(ms: number = 0): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const normalizeAssetsUrl = (url: string) => {
  if (url.startsWith(canonicalAssetsOrigin)) {
    return assetsOrigin + url.slice(canonicalAssetsOrigin.length);
  }
  return url;
};

/**
 * Compares two variables of unknown type for value equality.
 *
 * Arrays and Objects are considered to be equal if their defined properties are
 * all equal. Only object properties that are not undefined are considered. JSON
 * encoding drops undefined properties, so we also ignore it for compatibility
 * with parsed JSON.
 *
 * ```
 * deepEqual({ a: 1, b: undefined }, { a: 1 }) === true`.
 * ```
 */
export const deepEquals = (a: unknown, b: unknown): boolean => {
  if (a === b) return true;
  if (isArray(a) && isArray(b)) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; ++i) {
      if (!deepEquals(a[i], b[i])) return false;
    }
    return true;
  }
  if (isObject(a) && isObject(b)) {
    const aKeys = definedKeys(a);
    const bKeys = definedKeys(b);
    if (aKeys.length !== bKeys.length) return false;
    if (!aKeys.every((key) => bKeys.includes(key))) return false;
    for (const key of aKeys) {
      if (!deepEquals(a[key], b[key])) return false;
    }
    return true;
  }
  return false;
};
const definedKeys = (obj: Record<string, unknown>) => {
  const keys: string[] = [];
  for (const key in obj) {
    if (obj[key] !== undefined) keys.push(key);
  }
  return keys;
};

export const isDevelopmentServer = () => {
  const { hostname } = window.location;
  if (hostname === "localhost.cuttle.xyz") return true;
  return false;
};
export const isStagingServer = () => {
  const { hostname } = window.location;
  if (hostname === "staging.cuttle.xyz") return true;
  if (hostname === "staging.cuttlexyz.com") return true;
  return false;
};
