import m from "mithril";

import { Editor, Node } from "@tiptap/core";
import type { Extensions } from "@tiptap/core";
import Heading from "@tiptap/extension-heading";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import History from "@tiptap/extension-history";

import { domForVnode } from "../../shared/util";

// Limited schema: doc can only have one h1
const Document = Node.create({
  name: "doc",
  topNode: true,
  content: "heading",
  marks: "",
});

interface DocEditorTitleAttrs {
  value: string;
  editable: boolean;
  /** Only hit on blur or pressing Enter. */
  onchange: (value: string) => void;
}
export const DocEditorTitle: m.ClosureComponent<DocEditorTitleAttrs> = (initialVnode) => {
  let latestVnode = initialVnode;
  let editor: Editor | undefined;
  return {
    view(vnode) {
      latestVnode = vnode;
      return m(".doc-editor-title");
    },
    oncreate(vnode) {
      const el = domForVnode(vnode);
      const { value, editable } = vnode.attrs;

      const extensions: Extensions = [Document, Heading, Text];
      if (editable) {
        extensions.push(
          // Shown with empty title
          Placeholder.configure({ placeholder: "Project name" }),
          History
        );
      }

      editor = new Editor({
        element: el as HTMLElement,
        editable,
        extensions,
        content: {
          type: "doc",
          content: [
            {
              type: "heading",
              attrs: { level: 1 },
              content: value ? [{ type: "text", text: value }] : undefined,
            },
          ],
        },
        editorProps: {
          handleKeyDown(_, event) {
            if (editable && event.key === "Enter") {
              latestVnode.attrs.onchange(el.textContent || "");
              return true;
            }
            return false;
          },
        },
        // We don't need to set the project name / url on every key (onUpdate)
        onBlur() {
          if (editable) {
            latestVnode.attrs.onchange(el.textContent || "");
          }
        },
      });
    },
    onremove() {
      if (editor) {
        editor.destroy();
      }
    },
  };
};
