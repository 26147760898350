import { Color, Vec } from "../geom";
import { Expression } from "./expression";
import { Modifier } from "./modifier";
import {
  makeAngleParameter,
  makeBooleanParameter,
  makeColorParameter,
  makeDistanceParameter,
  makeImageParameter,
  makeImageTransformParameter,
  makePercentageParameter,
  makePointParameter,
  makeScalarParameter,
  makeSelectParameter,
  makeSizeParameter,
  makeVectorParameter,
} from "./parameter";
import { PIPoint } from "./parameter-interface";
import { registerBuiltin } from "./registry";

export const TransformDefinition = new Modifier();
TransformDefinition.isImmutable = true;
TransformDefinition.name = "Transform";
TransformDefinition.parameters = [
  makePointParameter("position", new Vec()),
  makeAngleParameter("rotation", 0),
  makeSizeParameter("scale", 1),
  makeAngleParameter("skew", 0),
  makePointParameter("origin", new Vec()),
];
TransformDefinition.code = new Expression(`if (modulo(skew, 180) === 90) {
  return undefined;
}
return AffineMatrix.fromTransform({position, rotation, scale, skew, origin});
`);
TransformDefinition.icon = "outline_transform";
registerBuiltin("TransformDefinition", TransformDefinition);

export const FillDefinition = new Modifier();
FillDefinition.isImmutable = true;
FillDefinition.name = "Fill";
FillDefinition.parameters = [
  makeSelectParameter("type", "color", ["color", "image"]),
  makeColorParameter("color", new Color(0.5, 0.5, 0.5, 1)),
  makeImageParameter("image", ""),
  makePercentageParameter("opacity", 1, 0),
  makeImageTransformParameter("transform", "fill"),
];
FillDefinition.code = new Expression(`if (type === "color") {
  if (!Color.isValid(color)) return input.removeFill();
  return input.assignFill(Fill(color));
} else if (type === "image") {
  const img = getImageFromURL(image);
  if (!img) return input.removeFill();

  const box = input.boundingBox();
  if (!box) return input.removeFill();
  const center = box.center();

  let matrix;
  if (transform === "fill" || transform === "fit") {
    const translation = center - 0.5 * Vec(img.width, img.height);
    matrix = AffineMatrix.fromTranslation(translation);
    if (transform === "fill") {
      const ratio = Math.max(box.width() / img.width, box.height() / img.height);
      matrix.preMul(AffineMatrix.fromCenterScale(center, ratio));
    } else if (transform === "fit") {
      const ratio = Math.min(box.width() / img.width, box.height() / img.height);
      matrix.preMul(AffineMatrix.fromCenterScale(center, ratio));
    }
  } else if (AffineMatrix.isValid(transform)) {
    matrix = transform;
  }

  if (!matrix) return input;

  return input.assignFill(ImageFill(image, opacity, matrix));
}
`);
FillDefinition.icon = "outline_fill";
registerBuiltin("FillDefinition", FillDefinition);

export const StrokeDefinition = new Modifier();
StrokeDefinition.isImmutable = true;
StrokeDefinition.name = "Stroke";
StrokeDefinition.parameters = [
  makeColorParameter("color", new Color(0, 0, 0, 1)),
  makeBooleanParameter("hairline", true),
  makeDistanceParameter("width", 0.1),
  makeSelectParameter("alignment", "centered", ["centered", "inner", "outer"]),
  makeSelectParameter("cap", "round", ["butt", "round", "square"]),
  makeSelectParameter("join", "round", ["miter", "round", "bevel"]),
  makeScalarParameter("miterLimit", 4),
];
StrokeDefinition.code = new Expression(`if (Color.isValid(color)) {
  return input.assignStroke(Stroke(color, hairline, width, alignment, cap, join, miterLimit));
} else {
  return input.removeStroke();
}
`);
StrokeDefinition.icon = "outline_stroke";
registerBuiltin("StrokeDefinition", StrokeDefinition);

export const GroupDefinition = new Modifier();
GroupDefinition.isImmutable = true;
GroupDefinition.name = "Group";
GroupDefinition.code = new Expression("input");
registerBuiltin("GroupDefinition", GroupDefinition);

export const CompoundPathDefinition = new Modifier();
CompoundPathDefinition.isImmutable = true;
CompoundPathDefinition.name = "Compound Path";
CompoundPathDefinition.code = new Expression("CompoundPath(input.allPaths())");
registerBuiltin("CompoundPathDefinition", CompoundPathDefinition);

export const PathDefinition = new Modifier();
PathDefinition.isImmutable = true;
PathDefinition.name = "Path";
PathDefinition.parameters = [makeBooleanParameter("closed", false)];
PathDefinition.code = new Expression("Path(input.allAnchors(), closed)");
registerBuiltin("PathDefinition", PathDefinition);

export type AnchorHandleConstraint = "free" | "tangent" | "mirror";
export const AnchorDefinition = new Modifier();
AnchorDefinition.isImmutable = true;
AnchorDefinition.isPassThrough = true;
AnchorDefinition.isShowGuides = true;
AnchorDefinition.name = "Anchor";
const anchorPositionParameter = makePointParameter("position", new Vec());
(anchorPositionParameter.interface as PIPoint).isVisible = false;
AnchorDefinition.parameters = [
  anchorPositionParameter,
  makeVectorParameter("handleIn", new Vec(), "position"),
  makeVectorParameter("handleOut", new Vec(), "position"),
  makeSelectParameter("handleConstraint", "free", ["free", "tangent", "mirror"]),
];
AnchorDefinition.code = new Expression("Anchor(position, handleIn, handleOut)");
registerBuiltin("AnchorDefinition", AnchorDefinition);
