export const defaultCodeComponentJsCode = `const c = Path([
  Anchor(
    Vec(0.35, -0.35), // position
    Vec(0.0, 0.0),    // handleIn
    Vec(-1.1, -0.6)   // handleOut
  ),
  Anchor(Vec(0.35, 0.35), Vec(-1.1, 0.6), Vec(0.0, 0.0)),
]);

const o = Circle().transform({
  position: Vec(-0.07, 0.0),
});

const d = Polygon({ sides: 3 }).transform({
  position: Vec(-0.15, 0.0),
  rotation: 90,
  scale: 1.1
});

const e = Path.fromPoints([
  Vec(0.0, 0.5),
  Vec(-0.5, 0.0),
  Vec(0.0, -0.5),
  Vec(0.5, 0.0),
  Vec(-0.5, -0.0),
]);

const code = [c, o, d, e];

code.forEach((geom, i) => {
  // Spread the letters out
  const x = mix(-1.8, 1.8, i / 3);
  geom.transform({ position: Vec(x, 0) });
  
  // Assign a colored stroke
  const hue = i / 8 + 0.25;
  const color = Color.fromHSVA(hue, 0.6, 1.0, 1.0);
  geom.assignStroke(Stroke(
    color,
    false, // hairline
    0.10   // width
  ));
});

return code;`;
