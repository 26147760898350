import m from "mithril";

import { assert } from "../geom";
import { globalState } from "../global-state";
import { CanvasDimensions, Viewport } from "../model/viewport";
import { domForVnode } from "../shared/util";
import { CanvasInterface } from "./canvas-ui/canvas-interface";
import styleConstants from "./style-constants";
import { ViewportGestureEvent, ViewportGestureListener, devicePixelRatio } from "./util";

interface CanvasAttrs {
  viewport: Viewport;
  canvasDimensions: CanvasDimensions;
}
interface CanvasState {
  latestVnode: m.Vnode<CanvasAttrs>;
  context: CanvasRenderingContext2D | null;
  offscreenCanvas: HTMLCanvasElement;
  offscreenContext: CanvasRenderingContext2D | null;
  ui: CanvasInterface;
  gestureListener: ViewportGestureListener;
  onKeyEvent: (event: KeyboardEvent) => void;
}

// For some reason Chrome on Windows will not emit "wheel" events on an element
// without also having an event listener assigned to the document..
document.addEventListener("wheel", () => {});

export const Canvas: m.Component<CanvasAttrs, CanvasState> = {
  oninit(vnode) {
    this.latestVnode = vnode;
    this.ui = new CanvasInterface();
  },
  oncreate(vnode) {
    this.latestVnode = vnode;

    const wrapperElem = domForVnode(vnode);
    this.gestureListener = new ViewportGestureListener(
      wrapperElem,
      (event: ViewportGestureEvent) => {
        return this.ui.onViewportChange(event, this.latestVnode.attrs.viewport);
      }
    );

    const canvasElem = wrapperElem.getElementsByClassName("canvas")[0];
    assert(canvasElem instanceof HTMLCanvasElement);
    this.context = canvasElem.getContext("2d", { alpha: false });
    assert(this.context);

    // Create an offscreen canvas that will be used for precomposited rendering
    // operations, such as rendering a UI element with some transparency.
    this.offscreenCanvas = document.createElement("canvas");
    this.offscreenContext = this.offscreenCanvas.getContext("2d");
    assert(this.offscreenContext);

    this.context.fillStyle = styleConstants.white;
    this.context.fillRect(0, 0, canvasElem.width, canvasElem.height);

    this.onKeyEvent = (event: KeyboardEvent) => this.ui.onKeyEvent(event);
    window.addEventListener("keydown", this.onKeyEvent);
    window.addEventListener("keyup", this.onKeyEvent);
  },
  onupdate(vnode) {
    this.latestVnode = vnode;

    const { viewport, canvasDimensions } = vnode.attrs;

    const viewMatrix = viewport.viewMatrixWithCanvasDimensions(canvasDimensions);

    const pixelRatio = devicePixelRatio();
    const pixelWidth = canvasDimensions.size.x * pixelRatio;
    const pixelHeight = canvasDimensions.size.y * pixelRatio;

    this.offscreenCanvas.width = pixelWidth;
    this.offscreenCanvas.height = pixelHeight;

    assert(this.context);
    assert(this.offscreenContext);
    this.context.fillStyle = styleConstants.white;
    this.context.fillRect(0, 0, pixelWidth, pixelHeight);
    this.ui.render(
      viewMatrix,
      viewport,
      canvasDimensions.size,
      pixelRatio,
      this.context,
      this.offscreenContext
    );
    this.context.restore();
    this.offscreenContext.restore();
  },
  onremove(vnode) {
    this.gestureListener.dispose();
    window.removeEventListener("keydown", this.onKeyEvent);
    window.removeEventListener("keyup", this.onKeyEvent);
  },
  view({ attrs: { viewport, canvasDimensions } }) {
    const viewMatrix = viewport.viewMatrixWithCanvasDimensions(canvasDimensions);

    this.ui.updateInterfaceElements(viewMatrix);

    let cursor: string | undefined;
    if (globalState.isCanvasPanningOrPreparingToPan()) {
      cursor = globalState.isCanvasPanning ? "grabbing" : "grab";
    } else {
      const cursorElem = globalState.downInterfaceElement || globalState.hoveredInterfaceElement;
      cursor = cursorElem?.cursor?.();
    }

    const pixelRatio = devicePixelRatio();

    return m(
      ".viewport-main",
      {
        className: cursor ? `cursor-${cursor}` : undefined,
        onpointerdown: (event: PointerEvent) => this.ui.onPointerDown(event, viewport),
        onpointermove: (event: PointerEvent) => this.ui.onPointerMove(event),
        onpointerup: (event: PointerEvent) => this.ui.onPointerUp(event),
        onpointerenter: (event: PointerEvent) => this.ui.onPointerEnter(event),
        onpointerleave: (event: PointerEvent) => this.ui.onPointerLeave(event),
      },
      [
        m("canvas.canvas", {
          width: canvasDimensions.size.x * pixelRatio,
          height: canvasDimensions.size.y * pixelRatio,
          style: `width:${canvasDimensions.size.x}px;height:${canvasDimensions.size.y}px`,
        }),
        m(".html", this.ui.renderHtml(viewMatrix)),
      ]
    );
  },
};
