/** Converts html string with tables to paragraphs and spaced spans for import.
 * Can be extended to convert other HTML that we don't support. */
export function transformPastedHTML(html: string) {
  try {
    const pastedDoc = new DOMParser().parseFromString(html, "text/html");
    const tables = pastedDoc.querySelectorAll("table");
    tables.forEach((table) => {
      // Convert cells to text with spaces
      const cells = table.querySelectorAll("td, th");
      cells.forEach((cell) => {
        const span = document.createElement("span");
        span.textContent = cell.textContent + " ";
        cell.replaceWith(span);
      });
      // Convert rows to paragraphs
      const rows = table.querySelectorAll("tr");
      rows.forEach((row) => {
        const p = document.createElement("p");
        Array.from(row.children).forEach((child) => {
          p.appendChild(child);
        });
        row.replaceWith(p);
      });
      // Remove tbody els
      const tbody = table.querySelectorAll("tbody");
      tbody.forEach((tbody) => {
        Array.from(tbody.children).forEach((child) => {
          table.appendChild(child);
        });
        tbody.remove();
      });
      // Convert table to divs
      const div = document.createElement("div");
      Array.from(table.children).forEach((child) => {
        div.appendChild(child);
      });
      table.replaceWith(div);
    });
    return pastedDoc.body.innerHTML;
  } catch (e) {
    return html;
  }
}
