import {
  AnchorDefinition,
  CompoundPathDefinition,
  GroupDefinition,
  PathDefinition,
} from "./builtin-primitives";
import { Component } from "./component";
import { Element } from "./element";
import { InstanceDefinition } from "./instance-definition";
import { registerClass } from "./registry";

export class Node {
  parent: Node | null;
  source: Element;

  private _hash?: string;

  constructor(parent: Node | null, source: Element) {
    this.parent = parent;
    this.source = source;
  }

  /**
   * sourcePath should be in order from top to bottom.
   */
  static fromSourcePath(sourcePath: Element[]) {
    let node: Node | null = null;
    for (let source of sourcePath) {
      node = new Node(node, source);
    }
    return node;
  }

  hash() {
    // an address tracing from top to bottom
    if (this._hash) return this._hash;
    let hash = this.source.id + "/";
    if (this.parent) {
      hash = this.parent.hash() + hash;
    }
    return (this._hash = hash);
  }

  copy(node: Node) {
    this.parent = node.parent;
    this.source = node.source;
    this._hash = node._hash;
  }

  equalsNode(node: Node) {
    return this.hash() === node.hash();
  }

  isAncestorOfNode(node: Node) {
    return node.hash().startsWith(this.hash()) && node.hash() !== this.hash();
  }
  isAncestorOfNodes(nodes: Node[]) {
    return nodes.every((node) => this.isAncestorOfNode(node));
  }

  hasParentNode(): boolean {
    return this.parent !== null;
  }
  hasParentEqualToNode(node: Node): boolean {
    if (!this.parent) return false;
    return this.parent.equalsNode(node);
  }

  sourceChildren() {
    const { base } = this.source;
    if (base.definition instanceof Component) {
      return base.definition.element.children;
    }
    return this.source.children; // base.definition instanceof Modifier
  }
  hasChildNodes() {
    return this.childCount() > 0;
  }
  childCount() {
    return this.sourceChildren().length;
  }
  childNodes() {
    return this.sourceChildren().map((childElem) => new Node(this, childElem));
  }
  childNodeAtIndex(index: number) {
    return new Node(this, this.sourceChildren()[index]);
  }
  canAddChildren() {
    return (
      this.hasDefinition(GroupDefinition) ||
      this.hasDefinition(PathDefinition) ||
      this.hasDefinition(CompoundPathDefinition) ||
      (this.source.base.definition instanceof Component && !this.source.base.definition.isImmutable)
    );
  }
  indexInParent() {
    if (this.parent === null) return -1;
    return this.parent.indexOfChildNode(this);
  }
  indexOfChildNode(node: Node) {
    return this.sourceChildren().indexOf(node.source);
  }
  /**
   * Returns an array of indices such that if you followed childNodeAtIndex from
   * the top of the tree you'd get down to this. Used for determining the
   * ordering of Nodes.
   */
  indexPath() {
    const path: number[] = [];
    let node: Node = this;
    while (node.parent) {
      path.unshift(node.indexInParent());
      node = node.parent;
    }
    return path;
  }
  /**
   * Returns an array of sources from the top of the tree down to this. The
   * returned source path can be reconstituted as a Node with
   * Node.fromSourcePath(sourcePath).
   */
  sourcePath() {
    const path: Element[] = [];
    let node: Node | null = this;
    while (node) {
      path.unshift(node.source);
      node = node.parent;
    }
    return path;
  }

  isVisible(): boolean {
    if (this.parent === null) return true;
    return this.source.isVisible && this.parent.isVisible();
  }
  isLocked(): boolean {
    return this.source.isLocked;
  }

  isImmutableChildren(): boolean {
    if (this.parent === null) return false; // The focused component is always mutable.
    const { base } = this.source;
    return base.definition instanceof Component && base.definition.isImmutable;
  }
  isImmutable(): boolean {
    if (this.parent === null) return false; // The focused component is always mutable.
    return this.parent.isImmutableChildren() || this.parent.isImmutable();
  }

  createsNewContext() {
    return this.source.base.definition instanceof Component;
  }

  hasDefinition(definition: InstanceDefinition) {
    return this.source.base.definition === definition;
  }
  isAnchor() {
    return this.hasDefinition(AnchorDefinition);
  }
  isPath() {
    return this.hasDefinition(PathDefinition);
  }
}
registerClass("Node", Node);

export interface PassThrough {
  isPassThroughTranslation: boolean;
  isPassThroughRotation: boolean;
  isPassThroughScale: boolean;
  isPassThroughUniformScale: boolean;
}

export interface EnabledTransforms {
  position?: boolean;
  rotation?: boolean;
  scale?: boolean;
}
