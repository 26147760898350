import m from "mithril";

import { globalState } from "../global-state";
import { Thumbnail } from "./thumbnail";

export const ZoomHint: m.Component<{}> = {
  view() {
    const focusedComponent = globalState.project.focusedComponent();
    if (!focusedComponent) return;

    const trace = globalState.traceForComponent(focusedComponent);
    if (!trace) return;

    const geomBounds = trace.result?.boundingBox();
    if (!geomBounds) return;

    const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
    const viewportBounds = viewport.worldBoundingBox(globalState.canvasDimensions);
    const viewportArea = viewportBounds.area();
    if (viewportArea === 0) return;

    const zoomRatio = Math.max(
      geomBounds.width() / viewportBounds.width(),
      geomBounds.height() / viewportBounds.height()
    );
    const zoomedWayOut = zoomRatio > 0 && zoomRatio < 0.004;
    const zoomedWayIn = zoomRatio > 20;

    const showZoomHint =
      zoomedWayOut || zoomedWayIn || !viewportBounds.overlapsBoundingBox(geomBounds);

    return (
      showZoomHint &&
      m("button.zoom-hint", { onclick: () => globalState.zoomViewportToFitAll() }, [
        m(
          ".zoom-hint-thumbnail",
          m(Thumbnail, { graphic: trace.result, width: 96, height: 96, padding: 8 })
        ),
        m(".zoom-hint-label", "Reset Zoom"),
      ])
    );
  },
};
