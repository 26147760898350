import { Graphic, Vec, assert, graphicFromSVGString } from "../geom";
import { globalState } from "../global-state";
import { assetsOrigin } from "../shared/config";
import { normalizeAssetsUrl } from "../util";

export type ExternalSVGChangeSubscriber = () => void;

class ExternalSVGManager {
  private loadedExternalSVGs: { [url: string]: Graphic | undefined } = {};
  private subscribers: Set<ExternalSVGChangeSubscriber> = new Set();

  // Note this method is declared using => to bind `this`.
  getEmojiSVGFromURL = (url: string) => {
    url = normalizeAssetsUrl(url);

    if (this.loadedExternalSVGs.hasOwnProperty(url)) {
      const loaded = this.loadedExternalSVGs[url];
      return loaded ? loaded.clone() : undefined;
    }

    // So we don't call loadExternalSVG more than once per url
    this.loadedExternalSVGs[url] = undefined;

    loadExternalSVG(url)
      .then((externalSVG) => {
        this.loadedExternalSVGs[url] = externalSVG;
        for (let subscriber of this.subscribers) {
          subscriber();
        }
      })
      .catch((error) => {
        console.warn("ExternalSVG loading error:", error);
      });

    return;
  };

  subscribe(subscriber: ExternalSVGChangeSubscriber) {
    this.subscribers.add(subscriber);
  }
  unsubscribe(subscriber: ExternalSVGChangeSubscriber) {
    this.subscribers.delete(subscriber);
  }
}
export const externalSVGManager = new ExternalSVGManager();

const loadExternalSVG = async (url: string) => {
  parseExternalURL(url);
  const response = await fetch(url);
  const text = await response.text();
  assert(text, "Failed to load SVG");
  const graphic = graphicFromSVGString(text, {
    units: globalState.project.settings.units,
    origin: "middle",
    fitSize: new Vec(1, 1),
  });
  assert(graphic, "Failed to parse graphics from SVG");
  // Emoji default fill is added as the component is added to canvas, so it can
  // be changed in the interface.
  graphic.removeFill();
  graphic.removeStroke();
  return graphic;
};

/** Will throw if URL is in format that we're not expecting. For now, that's
 * only our noto-emoji-600 collection, like
 * https://assets.cuttle.xyz/noto-emoji-600/23f3.svg */
export function parseExternalURL(urlString: string) {
  const url = new URL(urlString);
  assert(url.protocol === "https:", 'URL protocol should be "https:"');
  assert(url.origin === assetsOrigin, 'URL host should be "assets.cuttle.xyz"');
  const [_, directory, filename] = url.pathname.split("/");
  assert(
    directory === "noto-emoji-600" || directory === "symbols",
    "URL directory should be known"
  );
  const [name, extension] = filename.split(".");
  assert(extension === "svg", 'URL extension should be "svg"');
  return { url, directory, name, extension };
}
