import { AffineMatrix, Vec } from "../geom";
import { registerClass } from "./registry";

export class CanvasPoint {
  worldPosition: Vec;

  isPreciseH: boolean;
  isPreciseV: boolean;

  // A point is specific if it comes from a place exactly known to the user,
  // such as an anchor, center, or midpoint. Points that come from "closeset
  // point" tests on segments and axes are not specific.
  isSpecific: boolean;

  constructor(worldPosition: Vec, isPreciseH = false, isPreciseV = false, isSpecific = false) {
    this.worldPosition = worldPosition;
    this.isPreciseH = isPreciseH;
    this.isPreciseV = isPreciseV;
    this.isSpecific = isSpecific;
  }

  clone() {
    return new CanvasPoint(
      this.worldPosition.clone(),
      this.isPreciseH,
      this.isPreciseV,
      this.isSpecific
    );
  }

  copy(canvasPoint: CanvasPoint) {
    this.worldPosition.copy(canvasPoint.worldPosition);
    this.isPreciseH = canvasPoint.isPreciseH;
    this.isPreciseV = canvasPoint.isPreciseV;
    this.isSpecific = canvasPoint.isSpecific;
  }

  affineTransform(affineMatrix: AffineMatrix) {
    this.worldPosition.affineTransform(affineMatrix);
    return this;
  }

  isPrecise() {
    return this.isPreciseH || this.isPreciseV;
  }
}
registerClass("CanvasPoint", CanvasPoint);
