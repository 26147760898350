import { Anchor, Path, Vec, assert } from "../../geom";
import { globalState } from "../../global-state";
import {
  AnchorDefinition,
  PathDefinition,
  StrokeDefinition,
  TransformDefinition,
} from "../../model/builtin-primitives";
import { CanvasPoint } from "../../model/canvas-point";
import { Expression } from "../../model/expression";
import { expressionCodeForVec } from "../../model/expression-code";
import { ComponentFocus } from "../../model/focus";
import { Instance } from "../../model/instance";
import { Node } from "../../model/node";
import { SelectableNode } from "../../model/selectable";
import { contextMatrixForNode, transformedGraphicForNode } from "../../model/transform-utils";
import { isModifierKey } from "../../shared/util";
import { CanvasBaseUI, CanvasDragUI, FocusedGeometryUI } from "../canvas-ui/canvas-base-interface";
import { CanvasInterfaceElement } from "../canvas-ui/canvas-interface-element";
import {
  GhostGeometryUI,
  HoveredGeometryUI,
  InteractableNodeGeometryUI,
  UnstyledNodeGeometryUI,
} from "../canvas-ui/geometry-interface";
import { GuidesUI } from "../canvas-ui/guides-interface";
import { SelectionOnlyHandlesUI } from "../canvas-ui/handles-interface";
import {
  FocusedPathSegmentsUI,
  InteractablePathSegmentUI,
} from "../canvas-ui/path-segment-interface";
import { SnappingGeometry, snappingRoseAtPoint } from "../snapping";
import {
  POINTER_EVENT_BUTTONS_NONE,
  constrainCanvasPoint,
  minimumPrecisionPositionForCanvasPointInContext,
  worldPositionFromEvent,
  worldPositionFromPixelPosition,
} from "../util";
import { startAnchorHandleDrag } from "./anchor-handle-drag";
import { Tool, handleNudgeEvent } from "./shared";

export class PenTool implements Tool {
  previewAnchorNode?: Node;
  previewAnchorGestureId?: Symbol;

  previewPathClosedGestureId?: Symbol;

  isSnappingStale = false;

  isDisabled(): boolean {
    return !(globalState.project.focus instanceof ComponentFocus);
  }

  markSnappingStale() {
    this.isSnappingStale = true;
    this.removePreviewAnchor();
    globalState.snapping.clear();
  }
  markSnappingReady() {
    this.isSnappingStale = false;
  }

  onPointerMove(event: PointerEvent) {
    this.ensurePreviewAnchorValid();

    if (globalState.deviceStorage.geometrySnappingEnabled) {
      if (!this.previewAnchorNode && !globalState.canvasPointerIsDown) {
        // When rolling over handles for example, we want to cache new snapping
        // state that doesn't include all of the reference points, etc from the
        // previous anchor placement.
        globalState.snapping.cacheSnappingDataForHoveredInterface();
      }
      const worldPosition = worldPositionFromEvent(event);
      if (event.shiftKey) {
        globalState.snapping.updateReferenceSnappingPointNearWorldPosition(worldPosition);
      } else {
        globalState.snapping.updateSnappingPointNearWorldPosition(worldPosition);
      }
    }
  }

  onPointerLeave(event: PointerEvent) {
    this.stopEditing();
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if (globalState.project.selection.isEmpty()) {
        globalState.activateTool("Select");
      } else {
        this.stopEditing();
        globalState.project.clearSelection();
        globalState.snapping.clear();
      }
    } else {
      handleNudgeEvent(event);
    }

    // Mark snapping stale in case the keyboard event was a delete, cut, nudge,
    // or some other operation that would invalidate the currenly snapping
    // geometry. Ignore modifier key presses since they may do things like
    // toggling reference geometry snapping.
    if (!isModifierKey(event.key)) {
      this.markSnappingStale();
    }
  }
  onKeyUp(event: KeyboardEvent) {
    if (!isModifierKey(event.key)) {
      this.markSnappingStale();
    }
  }

  ensurePreviewAnchorValid() {
    // Sometimes the project can change from outside this tool, such as when the
    // delete key is pressed, or an undo or redo occurs. We need to guard
    // against the preview anchor becoming invalid during events like this. This
    // method must be called at the top level of any method called from outside
    // this class.
    if (this.previewAnchorNode && !globalState.project.nodeExists(this.previewAnchorNode)) {
      this.removePreviewAnchor();
    }
  }

  interface() {
    this.ensurePreviewAnchorValid();

    let overrideClosed = this.previewPathClosedGestureId ? false : undefined;

    const interfaceElems: CanvasInterfaceElement[] = [
      new PenCanvasBaseUI(),
      new FocusedGeometryUI(),
      new FocusedPathSegmentsUI(PenPathSegmentUI, this.previewAnchorNode, overrideClosed),
    ];

    const pathNode = this.focusedPath();
    if (pathNode) {
      const pathChildNodes = pathNode.childNodes();

      let previewAnchorNodeIndex = -1;
      if (this.previewAnchorNode) {
        previewAnchorNodeIndex = pathNode.indexOfChildNode(this.previewAnchorNode);
      }

      let closingAnchorIndex = -1;
      if (canPathClose(pathChildNodes)) {
        const lastChildIndex = pathChildNodes.length - 1;
        const endAnchorIndex = this.endAnchorIndexForPathNode(pathNode);
        if (endAnchorIndex === 0 || (endAnchorIndex === 1 && previewAnchorNodeIndex === 0)) {
          closingAnchorIndex = lastChildIndex;
        } else if (
          endAnchorIndex === lastChildIndex ||
          (endAnchorIndex === lastChildIndex - 1 && previewAnchorNodeIndex === lastChildIndex)
        ) {
          closingAnchorIndex = 0;
        }
      }

      for (let i = 0; i < pathChildNodes.length; ++i) {
        if (i === previewAnchorNodeIndex) continue;
        if (i === closingAnchorIndex) {
          interfaceElems.push(new ClosingNodeGeometryUI(this, pathChildNodes[i]));
        } else {
          interfaceElems.push(new InteractableNodeGeometryUI(pathChildNodes[i]));
        }
      }
    }

    if (this.previewAnchorNode) {
      interfaceElems.push(new UnstyledNodeGeometryUI(this.previewAnchorNode));
    }

    interfaceElems.push(
      new GhostGeometryUI(),
      new GuidesUI(),
      new HoveredGeometryUI(),
      new SelectionOnlyHandlesUI(),
      new CanvasDragUI()
    );

    return interfaceElems;
  }

  activate() {
    this.previewAnchorNode = undefined;
    assert(this.previewAnchorGestureId === undefined);
    assert(this.previewPathClosedGestureId === undefined);
    this.isSnappingStale = false;

    // If a path is selected when the pen tool is activated, focus into it.
    const { selection } = globalState.project;
    if (
      selection.isSingle() &&
      selection.hasOnly(SelectableNode) &&
      selection.items[0].node.isPath()
    ) {
      globalState.project.focusNode(selection.items[0].node);
    }

    // Anchor selection is used to determine which end of the path we draw from,
    // so we don't want to clear the selection if we're already focused on a
    // path.
    if (!this.focusedPath()) {
      globalState.project.clearSelection();
    }
  }

  deactivate() {
    this.stopEditing();

    const { focus } = globalState.project;
    assert(focus instanceof ComponentFocus);

    // Focus out of the path. This reverses the behavior when the pen tool is
    // activated. We won't be focused on a path when the pen tool is first
    // activated, so check to make sure.
    if (focus.node.isPath()) {
      globalState.project.selectNode(focus.node);
    }
  }

  isPreviewingPathClosed() {
    return Boolean(this.previewPathClosedGestureId);
  }
  startPreviewingPathClosed() {
    this.previewPathClosedGestureId = globalState.startGesture("Pen Tool: Preview Path Closed");
  }
  stopPreviewingPathClosed() {
    if (this.previewPathClosedGestureId) {
      globalState.stopGesture(this.previewPathClosedGestureId);
      this.previewPathClosedGestureId = undefined;
    }
  }

  onCanvasPointerLeave(event: PointerEvent) {
    this.removePreviewAnchor();
  }

  onCanvasPointerMove(event: PointerEvent) {
    // Don't move the preview anchor while dragging.
    if (event.buttons !== POINTER_EVENT_BUTTONS_NONE) return;

    // Skip making modifications until we have current snapping data.
    if (this.isSnappingStale) return;

    this.ensurePreviewAnchorValid();

    const previewAnchorNode = this.ensurePreviewAnchor();

    const canvasPoint = new CanvasPoint(worldPositionFromEvent(event));
    constrainCanvasPoint(canvasPoint);

    setNodeTransformByCanvasPoint(previewAnchorNode, canvasPoint);
  }

  onCanvasPointerDown(event: PointerEvent) {
    this.ensurePreviewAnchorValid();

    const previewAnchorNode = this.ensurePreviewAnchor();

    // Important that the preview anchor is committed and cleared so that the
    // end anchor is selected correctly.
    globalState.project.selectNode(previewAnchorNode);
    this.clearPreviewAnchor();

    let parameterName: "handleIn" | "handleOut" = "handleOut";
    const parentPathNode = previewAnchorNode.parent;
    if (parentPathNode) {
      const endAnchorIndex = this.endAnchorIndexForPathNode(parentPathNode);
      const isReverse = parentPathNode.childCount() > 1 && endAnchorIndex === 0;
      if (isReverse) {
        parameterName = "handleIn";
      }
    }

    const referencePoint = globalState.snapping.currentPoint
      ? globalState.snapping.currentPoint.worldPosition
      : worldPositionFromEvent(event);
    addSnappingReferencePointWithRose(referencePoint);

    startAnchorHandleDrag(event, previewAnchorNode, parameterName, {
      overrideAnchorHandleConstraint: "mirror",
      assignAnchorHandleConstraint: "tangent",
      allowBreaking: true,
      disableSnappingCache: true,
    });
  }

  onClosingNodePointerEnter(node: Node, event: PointerEvent) {
    this.ensurePreviewAnchorValid();

    const pathNode = this.ensureFocusedPath();
    const pathTrace = globalState.traceForNode(pathNode);
    if (pathTrace?.base.result instanceof Path && !pathTrace.base.result.closed) {
      const { args } = pathNode.source.base;
      args.closed = new Expression("true");
      this.startPreviewingPathClosed();
    }
  }

  onClosingNodePointerLeave(node: Node, event: PointerEvent) {
    this.ensurePreviewAnchorValid();

    if (this.isPreviewingPathClosed()) {
      const pathNode = this.focusedPath();
      if (pathNode) {
        delete pathNode.source.base.args.closed;
      }
      this.stopPreviewingPathClosed();

      // When the pointer first moves after entering the canvas, the path may
      // still be temporarily closed. To avoid picking up snapping geometry
      // derived from the closed path we need to wait for evaluation to happen
      // (when the path will again be open) before caching snapping geometry.
      this.markSnappingStale();
    }
  }

  onClosingNodePointerDown(node: Node, event: PointerEvent) {
    this.ensurePreviewAnchorValid();

    const pathChildNodes = this.ensureFocusedPath().childNodes();
    const firstPathNode = pathChildNodes[0];
    const lastPathNode = pathChildNodes[pathChildNodes.length - 1];

    const startClosePath = (node: Node, handleName: "handleIn" | "handleOut") => {
      globalState.project.selectNode(node);
      if (globalState.snapping.currentPoint) {
        globalState.snapping.addReferencePoint(globalState.snapping.currentPoint.worldPosition);
      }
      startAnchorHandleDrag(event, node, handleName, {
        flip: true,
        allowBreaking: true,
        disableSnappingCache: true,
      });
      this.stopPreviewingPathClosed();
      event.preventDefault();
    };

    // Close path (natural order)
    if (node.equalsNode(firstPathNode)) {
      if (node.isAnchor()) {
        startClosePath(node, "handleIn");
      }
    }
    // Close path (reverse order)
    else if (node.equalsNode(lastPathNode)) {
      if (lastPathNode.isAnchor()) {
        startClosePath(lastPathNode, "handleOut");
      }
    }
  }

  onAfterEvaluation() {
    this.markSnappingReady();
  }

  endAnchor() {
    const pathNode = this.focusedPath();
    if (pathNode) {
      let endAnchorIndex = this.endAnchorIndexForPathNode(pathNode);
      if (endAnchorIndex >= 0) {
        const endAnchorNode = pathNode.childNodeAtIndex(endAnchorIndex);
        const endAnchor = transformedGraphicForNode(endAnchorNode);
        if (endAnchor instanceof Anchor) {
          return endAnchor;
        }
      }
    }
    return undefined;
  }

  private stopEditing() {
    this.removePreviewAnchor();
    this.stopPreviewingPathClosed();
  }

  private focusedPath() {
    const { focus } = globalState.project;
    if (focus instanceof ComponentFocus && focus.node.isPath()) {
      return focus.node;
    }
    return undefined;
  }
  private ensureFocusedPath(): Node {
    const { project } = globalState;

    assert(project.focus instanceof ComponentFocus);

    // Focus the closest mutable parent node
    let node = project.focus.node;
    while (node.parent && (node.isImmutable() || node.isImmutableChildren())) {
      node = node.parent;
    }

    if (!node.isPath()) {
      // Create a new path under the focused node.
      const pathElem = project.createElementWithDefinition(PathDefinition);
      pathElem.stroke = new Instance(StrokeDefinition);
      node = project.spliceNodes([], [pathElem], node)[0];
      project.clearSelection();
    }

    project.focusNode(node);

    return project.focus.node;
  }

  private ensurePreviewAnchor(): Node {
    if (this.previewAnchorNode && globalState.project.nodeExists(this.previewAnchorNode)) {
      return this.previewAnchorNode;
    }

    let pathNode = this.ensureFocusedPath();
    if (this.isNextClickCreatesNewPath()) {
      // If the next click will create a new path we need to eagerly create this
      // new path (but not focus it) so that the preview anchor has a place to
      // be inserted.
      const pathIndex = pathNode.indexInParent();
      const pathElem = globalState.project.createElementWithDefinition(PathDefinition);
      pathElem.stroke = new Instance(StrokeDefinition);
      pathNode = globalState.project.spliceNodes(
        [],
        [pathElem],
        pathNode.parent!,
        pathIndex + 1
      )[0];
    }

    const pathChildCount = pathNode.childCount();

    let anchorIndex = this.endAnchorIndexForPathNode(pathNode);
    if (anchorIndex === -1 || anchorIndex === pathChildCount - 1) {
      anchorIndex = pathChildCount;
    }
    const previewAnchorNode = this.insertPreviewAnchorAtIndex(anchorIndex, pathNode);
    this.previewAnchorNode = previewAnchorNode;

    return previewAnchorNode;
  }
  private insertPreviewAnchorAtIndex(index: number, parentPathNode: Node) {
    const { project } = globalState;

    this.previewAnchorGestureId = globalState.startGesture("Pen Tool: Preview Anchor");

    const anchorElem = project.createElementWithDefinition(AnchorDefinition);
    const [previewAnchorNode] = project.spliceNodes([], [anchorElem], parentPathNode, index);
    this.previewAnchorNode = previewAnchorNode;

    const worldPosition = globalState.canvasPointerPositionPixels
      ? worldPositionFromPixelPosition(globalState.canvasPointerPositionPixels)
      : new Vec();

    setNodeTransformByCanvasPoint(this.previewAnchorNode, new CanvasPoint(worldPosition));

    // Cache snapping state for the interface after the preview anchor is added.
    // It's important that this comes after so that the PenCanvasBaseUI can find
    // the end anchor reference point.
    if (globalState.deviceStorage.geometrySnappingEnabled) {
      globalState.snapping.cacheSnappingDataForInterface(globalState.interfaceElements);

      // Add a snapping rose for the current endpoint. This allows the user to
      // draw at certain angles from the last anchor.
      const endAnchor = this.endAnchor();
      if (endAnchor) {
        addSnappingReferencePointWithRose(endAnchor.position);
      }
    }

    return previewAnchorNode;
  }
  private removePreviewAnchor() {
    if (this.previewAnchorNode) {
      let nodesToRemove: Node[];
      if (this.previewAnchorNode.parent?.childCount() === 1) {
        nodesToRemove = [this.previewAnchorNode.parent];
      } else {
        nodesToRemove = [this.previewAnchorNode];
      }
      globalState.project.spliceNodes(nodesToRemove);
    }
    this.clearPreviewAnchor();
  }
  private clearPreviewAnchor() {
    this.previewAnchorNode = undefined;
    if (this.previewAnchorGestureId) {
      globalState.stopGesture(this.previewAnchorGestureId);
      this.previewAnchorGestureId = undefined;
    }
  }

  private isNextClickCreatesNewPath() {
    const focusedPath = this.ensureFocusedPath();

    // New paths will have no end anchor index since they have no children yet.
    if (focusedPath.childCount() === 0) return false;

    return this.endAnchorIndexForPathNode(focusedPath) === -1 || isPathClosed(focusedPath);
  }

  private endAnchorIndexForPathNode(pathNode: Node) {
    if (!this.isPreviewingPathClosed() && isPathClosed(pathNode)) return -1;
    if (pathNode.childCount() === 1 && this.previewAnchorNode) return 0;
    const selection = globalState.project.selection.allNodes();
    if (selection.isSingle()) {
      const node = selection.items[0].node;
      if (node.hasParentEqualToNode(pathNode)) {
        const [firstIndex, lastIndex] = this.firstAndLastIndicesForPathNode(pathNode);
        const selectedNodeIndex = node.indexInParent();
        if (selectedNodeIndex === firstIndex || selectedNodeIndex === lastIndex) {
          return selectedNodeIndex;
        }
      }
    }
    return -1;
  }

  private firstAndLastIndicesForPathNode(pathNode: Node) {
    const childCount = pathNode.childCount();

    let firstIndex = 0;
    let lastIndex = childCount - 1;

    if (childCount > 1) {
      const previewNodeIndex = this.previewAnchorNode?.indexInParent();
      if (previewNodeIndex === firstIndex) firstIndex++;
      if (previewNodeIndex === lastIndex) lastIndex--;
    }

    return [firstIndex, lastIndex];
  }
}

class PenCanvasBaseUI extends CanvasBaseUI {
  readonly id = "canvas-base-pen";

  cursor() {
    return globalState.canvasPointerIsDown ? "select" : "pen";
  }

  onPointerLeave(event: PointerEvent) {
    const tool = globalState.ensureEnabledActiveTool();
    if (tool instanceof PenTool) {
      tool.onCanvasPointerLeave(event);
    }
  }
  onPointerMove(event: PointerEvent) {
    const tool = globalState.ensureEnabledActiveTool();
    if (tool instanceof PenTool) {
      tool.onCanvasPointerMove(event);
    }
  }
  onPointerDown(event: PointerEvent) {
    const tool = globalState.ensureEnabledActiveTool();
    if (tool instanceof PenTool) {
      tool.onCanvasPointerDown(event);
    }
  }
}

class PenPathSegmentUI extends InteractablePathSegmentUI {
  onPointerDown(event: PointerEvent) {
    this.insertAnchorWithPointerEvent(event);
  }

  cursor() {
    return "pen-add";
  }

  snappingGeometry(isSource: boolean) {
    if (this.segmentWorld) {
      return new SnappingGeometry("Segment", this.segmentWorld);
    }
    return undefined;
  }
}

class ClosingNodeGeometryUI extends InteractableNodeGeometryUI {
  readonly id = "closing-anchor";

  private penTool: PenTool;

  constructor(penTool: PenTool, node: Node) {
    super(node);
    this.penTool = penTool;
  }

  cursor() {
    return "pen-close";
  }

  onPointerEnter(event: PointerEvent) {
    this.penTool.onClosingNodePointerEnter(this.node, event);
  }
  onPointerLeave(event: PointerEvent) {
    this.penTool.onClosingNodePointerLeave(this.node, event);
  }
  onPointerDown(event: PointerEvent) {
    this.penTool.onClosingNodePointerDown(this.node, event);
  }
}

const canPathClose = (pathChildNodes: Node[]) => {
  // Paths can only close if they either have 3 or more anchors, or 2 anchors, one
  // with modified handles
  if (pathChildNodes.length >= 2) {
    if (pathChildNodes.length >= 3) return true;
    for (let node of pathChildNodes) {
      const trace = globalState.traceForNode(node);
      if (trace?.result instanceof Anchor && !trace.result.hasZeroHandles()) {
        return true;
      }
    }
  }
  return false;
};

const isPathClosed = (pathNode: Node) => {
  const closedArg = pathNode.source.base.args.closed;
  if (closedArg) {
    const closedLiteral = closedArg?.literalValue();
    if (typeof closedLiteral === "boolean") return closedLiteral;
    const trace = globalState.traceForNode(pathNode);
    return trace?.result instanceof Path && trace.result.closed;
  }
  return false; // If no closed arg is set the default is false.
};

// TODO: Remove this deprecated function and replace with Transformer. We should
// really only have one way of transforming things.. It's possible this will
// become a source of bugs.
const setNodeTransformByCanvasPoint = (node: Node, canvasPoint: CanvasPoint) => {
  const contextMatrix = contextMatrixForNode(node);
  if (contextMatrix !== undefined) {
    const inverseContextMatrix = contextMatrix.clone().invert();
    const { contextPosition, fractionDigits } = minimumPrecisionPositionForCanvasPointInContext(
      canvasPoint,
      inverseContextMatrix
    );

    if (node.source.isPassThrough()) {
      for (const parameter of node.source.base.definition.allParameters()) {
        const paramInterface = parameter.interface;
        if (paramInterface?.isPositionTransformable) {
          const defaultValue = parameter.expression.literalValue();
          if (defaultValue instanceof Vec) {
            const value = defaultValue.clone().add(contextPosition);
            node.source.base.args[parameter.name] = new Expression(
              expressionCodeForVec(value, fractionDigits)
            );
          }
        }
      }
    } else {
      const { args } = (node.source.transform = new Instance(TransformDefinition));
      args.position = new Expression(expressionCodeForVec(contextPosition, fractionDigits));
    }
  }
};

const addSnappingReferencePointWithRose = (referencePoint: Vec) => {
  const { snapping } = globalState;

  // Clear out the reference points and geometry. We only want one reference
  // point at a time when drawing.
  snapping.clearReference();

  snapping.addReferencePoint(referencePoint);
  snapping.referenceGeometry.push(...snappingRoseAtPoint(referencePoint, 0, 15));
};
