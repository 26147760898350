import { AffineMatrix, BoundingBox, Graphic, Group, Path, Vec } from "../../geom";
import { globalState, SELECTION_DISTANCE_PX } from "../../global-state";
import { CodeComponent, Component } from "../../model/component";
import { Instance } from "../../model/instance";
import { Node } from "../../model/node";
import { SelectableInstance, SelectableNode } from "../../model/selectable";
import { Selection } from "../../model/selection";
import {
  contextTransformMatrixForNode,
  transformedGraphicForNode,
  transformOriginForNode,
} from "../../model/transform-utils";
import { isPointerEventDoubleClick } from "../../shared/util";
import {
  ANCHOR_GRAPHIC_RADIUS,
  paintDotToCanvas,
  paintGeometryToCanvas,
  paintNodeBaseToCanvas,
  paintSelectedInstanceToCanvas,
  paintStyledGraphicToCanvas,
} from "../canvas-geometry";
import { SnappingGeometry, SnappingPoint } from "../snapping";
import styleConstants from "../style-constants";
import { startMirroredHandleDragFromAnchorCenter } from "../tool/anchor-handle-drag";
import { toggleAnchorHandleConstraint } from "../tool/shared";
import { alignToPixelCenter } from "../util";
import { openEditorPopupForFirstTextParameter } from "./canvas-edit-text-parameter";
import { CanvasInterfaceElement } from "./canvas-interface-element";

const PATH_HIT_RADIUS_PX = 3;
const ANCHOR_HIT_RADIUS_PX = ANCHOR_GRAPHIC_RADIUS + 2;

export class InteractableNodeGeometryUI implements CanvasInterfaceElement {
  node: Node;

  isStyled: boolean;
  isSelected: boolean;
  isRenderedAsAnchor: boolean;
  isRenderedAsGuide: boolean;
  isPositionLocked: boolean;

  graphic?: Graphic;
  baseGraphic?: Graphic;

  constructor(node: Node, isStyled = false) {
    this.node = node;

    this.isRenderedAsGuide = node.source.guidesDisplay === "show-all-as-guides";
    this.isStyled = isStyled && !this.isRenderedAsGuide;
    this.isSelected = globalState.project.selection.isNodeDirectlySelected(node);
    this.isRenderedAsAnchor = this.node.isAnchor();
    this.isPositionLocked = this.isSelected
      ? globalState.project.selection.isPositionLocked()
      : node.source.isPositionLocked();

    this.graphic = transformedGraphicForNode(node);

    const trace = globalState.traceForNode(node);
    if (trace?.isSuccess()) {
      const contextTransformMatrix = contextTransformMatrixForNode(node);
      this.baseGraphic = trace.base.result.clone().affineTransform(contextTransformMatrix);

      // Also render paths with a single anchor as anchors.
      if (trace.result instanceof Path && trace.result.anchors.length === 1) {
        this.isRenderedAsAnchor = true;
      }
    }
  }

  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    if (this.isRenderedAsGuide) return; // GuidesUI will handle rendering this node.

    const { node, graphic } = this;

    if (!graphic) return;
    if (!this.isSelected && !node.isVisible()) return;
    if (!globalState.project.isNodeVisibleForEditing(node)) return;

    if (this.isStyled && !this.isRenderedAsAnchor) {
      const canvasSize = globalState.canvasDimensions.size;
      paintStyledGraphicToCanvas(graphic, viewMatrix, ctx, canvasSize);
      return;
    }

    if (!this.isStyled && node.source.modifiers.length > 0) {
      // Draw the last modifier on the stack, if any.
      const instance = node.source.modifiers[node.source.modifiers.length - 1];
      paintSelectedInstanceToCanvas(node, instance, viewMatrix, ctx);
    }

    paintNodeBaseToCanvas(node, viewMatrix, ctx);
  }

  hitTest(worldPosition: Vec, pixelsPerUnit: number) {
    if (!this.baseGraphic || !this.graphic) return;
    if (!this.isSelected && this.node.isLocked()) return;

    let distance = Infinity;
    const group = new Group([this.graphic, this.baseGraphic]);
    const result = group.closestPoint(worldPosition);
    if (result && result.position) {
      distance = worldPosition.distance(result.position);
    }

    let isHit = false;
    if (this.isRenderedAsAnchor) {
      // Convert to a signed distance where 0 is at the anchor's hit radius.
      distance -= ANCHOR_HIT_RADIUS_PX / pixelsPerUnit;
      isHit = distance <= 0;
    } else if (this.isStyled) {
      isHit = this.graphic.styleContainsPoint(worldPosition);
    }

    if (this.isSelected) {
      // Make selected shapes "hit" up to their selection distance. This ensures
      // that the existing selection always takes priority over underlying
      // shapes and avoids the situation where a selected path is dragged, but a
      // path underneath is moved because it happened to be slightly closer.
      isHit ||= distance <= SELECTION_DISTANCE_PX / pixelsPerUnit;
    }

    return { distance, isHit };
  }

  isContainedByBoundingBox(box: BoundingBox) {
    if (this.isRenderedAsGuide) return false; // Guides can't be box selected.
    return Boolean(this.baseGraphic?.isContainedByBoundingBox(box));
  }
  isOverlappedByBoundingBox(box: BoundingBox) {
    if (this.isRenderedAsGuide) return false; // Guides can't be box selected.
    return Boolean(this.baseGraphic?.isOverlappedByBoundingBox(box));
  }

  selectables() {
    if (this.node.isLocked()) return [];
    return [new SelectableNode(this.node)];
  }

  cursor(): string {
    if (globalState.isAltDown) {
      if (this.node.isAnchor()) {
        return "select-join-handles";
      }
      return "duplicate";
    }
    if (this.isPositionLocked) {
      return "select-locked";
    }
    return "select";
  }

  snappingGeometry(isSource: boolean) {
    if (!this.baseGraphic) return;
    if (!this.isSelected && this.node.isLocked() && isSource) return;
    return new SnappingGeometry(this.node.source.name, this.baseGraphic);
  }

  onPointerDown(event: PointerEvent) {
    const { project } = globalState;
    const isDoubleClick = isPointerEventDoubleClick(event);
    const isAnchor = this.node.isAnchor();
    if (isDoubleClick) {
      if (isAnchor) {
        toggleAnchorHandleConstraint(this.node);
      } else {
        onDoubleClickNode(this.node, event);
      }
    } else {
      if (isAnchor && event.altKey) {
        startMirroredHandleDragFromAnchorCenter(event, this.node);
        event.preventDefault();
      }
    }
  }
}

export class NodeOriginUI implements CanvasInterfaceElement {
  node: Node;
  isLocked: boolean;

  worldOriginPosition?: Vec;
  worldDefaultOriginPosition?: Vec;

  constructor(node: Node, isLocked: boolean) {
    this.node = node;
    this.isLocked = isLocked;

    const origin = transformOriginForNode(node);
    const contextTransformMatrix = contextTransformMatrixForNode(node);
    this.worldOriginPosition = origin.clone().affineTransform(contextTransformMatrix);
    this.worldDefaultOriginPosition = new Vec().affineTransform(contextTransformMatrix);
  }

  isValid() {
    return globalState.project.nodeExists(this.node);
  }

  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    if (!this.worldOriginPosition) return undefined;
    const origin = alignToPixelCenter(this.worldOriginPosition.clone().affineTransform(viewMatrix));
    const isHovered = globalState.project.isNodeHovered(this.node);
    const color = isHovered ? styleConstants.blue53 : styleConstants.blue63;
    paintDotToCanvas(origin, 3, color, ctx);
  }

  hitTest(worldPosition: Vec, pixelsPerUnit: number) {
    if (!this.worldOriginPosition) return undefined;
    const worldHitDistance = PATH_HIT_RADIUS_PX / pixelsPerUnit;
    const distance = worldPosition.distance(this.worldOriginPosition) - worldHitDistance;
    return { distance, isHit: distance <= 0 };
  }

  selectables() {
    return [new SelectableNode(this.node)];
  }

  cursor(): string {
    if (globalState.isAltDown) return "duplicate";
    if (this.isLocked) return "select-locked";
    return "select";
  }

  snappingPoints(isSource: boolean) {
    if (this.worldOriginPosition) {
      return [new SnappingPoint(this.worldOriginPosition, "Origin", "Origin")];
    }
    if (!isSource && this.worldDefaultOriginPosition) {
      return [new SnappingPoint(this.worldDefaultOriginPosition, "Default Origin", "Origin")];
    }
    return undefined;
  }
}

export class SelectionOriginsUI implements CanvasInterfaceElement {
  children: CanvasInterfaceElement[] = [];

  constructor(selection: Selection) {
    const isLocked = selection.isPositionLocked();
    for (let item of selection.items) {
      if (
        item instanceof SelectableNode &&
        item.node.source.transform?.isEnabled &&
        !item.node.isAnchor()
      ) {
        this.children.push(new NodeOriginUI(item.node, isLocked));
      }
    }
  }
}

export class HoveredGeometryUI implements CanvasInterfaceElement {
  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    if (globalState.hoveredObjectInspectorGeometry) {
      paintGeometryToCanvas(
        globalState.hoveredObjectInspectorGeometry,
        styleConstants.red47Alpha40,
        2,
        false,
        false,
        viewMatrix,
        ctx
      );
    }

    const { project } = globalState;
    const { hoveredItem } = project;
    if (hoveredItem && !project.selection.contains(hoveredItem)) {
      if (hoveredItem instanceof SelectableNode) {
        if (hoveredItem.node.source.guidesDisplay !== "show-all-as-guides") {
          paintNodeBaseToCanvas(hoveredItem.node, viewMatrix, ctx);
        }
      } else if (hoveredItem instanceof SelectableInstance) {
        paintSelectedInstanceToCanvas(hoveredItem.node, hoveredItem.instance, viewMatrix, ctx);
      }
    }
  }
}

class SelectedModifierGeometryUI implements CanvasInterfaceElement {
  node: Node;
  instance: Instance;

  constructor(node: Node, instance: Instance) {
    this.node = node;
    this.instance = instance;
  }

  isValid() {
    return globalState.project.nodeExists(this.node);
  }

  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    paintSelectedInstanceToCanvas(this.node, this.instance, viewMatrix, ctx);
  }
}

export class SelectedModifiersGeometryUI implements CanvasInterfaceElement {
  children: CanvasInterfaceElement[];

  constructor() {
    this.children = [];
    for (let item of globalState.project.selection.allNodesAndInstancesToShow().items) {
      if (item instanceof SelectableInstance) {
        this.children.push(new SelectedModifierGeometryUI(item.node, item.instance));
      }
    }
  }
}

export class StyledComponentGeometryUI implements CanvasInterfaceElement {
  graphic?: Graphic;
  assignDefaultStrokeToUnstyled: boolean;

  constructor(component: Component | CodeComponent) {
    const trace = globalState.traceForComponent(component);
    if (trace?.isSuccess()) {
      this.graphic = trace.result;
    }

    // Code components should render as they would look if dragged onto the
    // canvas, so we need to give them a default stroke if no style is already
    // assigned.
    this.assignDefaultStrokeToUnstyled = component instanceof CodeComponent;
  }

  isValid() {
    return this.graphic !== undefined;
  }

  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    if (!this.graphic) return;
    const canvasSize = globalState.canvasDimensions.size;
    paintStyledGraphicToCanvas(
      this.graphic,
      viewMatrix,
      ctx,
      canvasSize,
      this.assignDefaultStrokeToUnstyled
    );
  }
}

export class StyledNodeGeometryUI implements CanvasInterfaceElement {
  graphic?: Graphic;
  opacity: number;

  constructor(node: Node, opacity = 1) {
    if (globalState.project.isNodeVisibleForEditing(node)) {
      this.graphic = transformedGraphicForNode(node);
    }
    this.opacity = opacity;
  }

  isValid() {
    return this.graphic !== undefined;
  }

  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    if (!this.graphic) return;
    const canvasSize = globalState.canvasDimensions.size;
    paintStyledGraphicToCanvas(this.graphic, viewMatrix, ctx, canvasSize);
  }
  renderCanvasOpacity() {
    return this.opacity;
  }
}

export class UnstyledNodeGeometryUI implements CanvasInterfaceElement {
  node: Node;

  constructor(node: Node) {
    this.node = node;
  }

  isValid() {
    return globalState.project.nodeExists(this.node);
  }

  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    paintNodeBaseToCanvas(this.node, viewMatrix, ctx);
  }
}

export class SnappingGeometryUI implements CanvasInterfaceElement {
  node: Node;

  constructor(node: Node) {
    this.node = node;
  }

  isValid() {
    return globalState.project.nodeExists(this.node);
  }

  snappingGeometry() {
    const graphic = transformedGraphicForNode(this.node);
    if (graphic) {
      return new SnappingGeometry(this.node.source.name, graphic);
    }
  }
}

export class GhostGeometryUI implements CanvasInterfaceElement {
  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    const geometry = globalState.ghostGeometry;
    if (geometry) {
      paintGeometryToCanvas(
        geometry,
        styleConstants.blackAlpha20,
        1,
        false,
        false,
        viewMatrix,
        ctx
      );
    }
  }
}

export const onDoubleClickNode = (node: Node, event: PointerEvent) => {
  if (node.hasChildNodes() && !node.isImmutableChildren()) {
    globalState.project.clearSelection();
    globalState.project.focusNode(node);
  } else {
    openEditorPopupForFirstTextParameter(node, event);
  }
};
