import { globalState } from "../global-state";
import { hashForCustomFontURL } from "../io/font-manager";
import { getBuiltinFontsInfo } from "./builtin-fonts";
import { ElementNameGenerator, NameHash } from "./name-generator";

export const ALL_FONTS_CATEGORY = "All";
export const CUSTOM_FONTS_CATEGORY = "My Fonts";

// Defined in scripts/extract-google-fonts.js based on font subsets
const WORLD_FONTS_CATEGORY = "world";

const PRO_CATEGORY = "pro";
const SINGLE_LINE_PRO_CATEGORY = "single line pro";

// Explicitly define the order of the categories
const CATEGORY_SORTING = [
  ALL_FONTS_CATEGORY,
  CUSTOM_FONTS_CATEGORY,
  PRO_CATEGORY,
  "sans-serif",
  "serif",
  "display",
  "handwriting",
  "stencil",
  WORLD_FONTS_CATEGORY,
  "monospace",
  SINGLE_LINE_PRO_CATEGORY,
  "single line script",
  "single line sans",
  "single line serif",
];

// Don't show complex fonts in lists, only in search results
const COMPLEXITY_THRESHOLD = 7.0;
export const isFontComplex = (fontFamily: FontFamily) =>
  fontFamily.complexity > COMPLEXITY_THRESHOLD;

export interface FontFamily {
  category: string;
  family: string;
  search: string;
  variants: Array<FontVariant>;
  pro?: boolean;
  complexity: number;
}

export interface FontVariant {
  variant: string;
  label: string;
  value: string;
}

type FontListChangeSubscriber = () => void;

class FontList {
  /** Unique array of category names, sorted as they appear in the UI. */
  categories: Array<string> = [];

  // Internal data structures to make it easy to find what we need
  private sortedAll: Array<FontFamily> = [];
  private categoriesSet: Set<string> = new Set(CATEGORY_SORTING);
  private familiesByCategory: Map<string, Set<FontFamily>> = new Map();
  private familyByName: Map<string, FontFamily> = new Map();
  private familyByFileName: Map<string, FontFamily> = new Map();
  /** Custom fonts can be refreshed and combined with built-in fonts at any
   * time. This Set is used to prioritize the family names of our built-in
   * fonts. */
  private builtinFamilies: Set<string> = new Set();

  private subscribers: Set<FontListChangeSubscriber> = new Set();

  /** Cache so that built-in and custom fonts can be refreshed independently. */
  private builtinFontFamilies: FontFamily[] | undefined;
  private customFontFamilies: FontFamily[] | undefined;

  constructor() {
    // Font info loading is postponed until font picker is shown.
  }
  private processList(fontFamilies: FontFamily[]) {
    // Sort font families by name before processing, pro first.
    fontFamilies = fontFamilies.slice().sort((a, b) => {
      if (a.pro && !b.pro) return -1;
      if (!a.pro && b.pro) return 1;
      return a.family.localeCompare(b.family);
    });
    this.sortedAll = fontFamilies;

    this.categories = CATEGORY_SORTING.slice();
    this.categoriesSet = new Set(CATEGORY_SORTING);
    this.familiesByCategory = new Map();
    const sortedCategoryDefaultAll: Set<FontFamily> = new Set();
    this.familiesByCategory.set(ALL_FONTS_CATEGORY, sortedCategoryDefaultAll);
    this.familyByName = new Map();
    this.familyByFileName = new Map();

    for (let category of CATEGORY_SORTING) {
      this.categoriesSet.add(category);
      if (!this.familiesByCategory.has(category)) {
        this.familiesByCategory.set(category, new Set());
      }
    }

    for (let fontFamily of fontFamilies) {
      const { category, family: familyName, variants } = fontFamily;
      this.familyByName.set(familyName, fontFamily);

      // Don't list overly complex fonts in all or category lists
      if (!isFontComplex(fontFamily)) {
        // Category list
        let sortedCategory = this.familiesByCategory.get(category);
        // Should only happen if there is an unexpected new category
        if (!sortedCategory) {
          this.categories.push(category);
          this.categoriesSet.add(category);
          sortedCategory = new Set();
          this.familiesByCategory.set(category, sortedCategory);
        }
        sortedCategory.add(fontFamily);
        // All list
        if (category !== WORLD_FONTS_CATEGORY) {
          sortedCategoryDefaultAll.add(fontFamily);
        }
      }

      // Also list in "pro" categories
      if (fontFamily.pro) {
        if (fontFamily.category.includes("single line")) {
          this.familiesByCategory.get(SINGLE_LINE_PRO_CATEGORY)?.add(fontFamily);
        } else {
          this.familiesByCategory.get(PRO_CATEGORY)?.add(fontFamily);
        }
      }

      const familyVariantNames: string[] = [];
      for (let fontVariant of variants) {
        const { variant: variantName, value } = fontVariant;

        this.familyByFileName.set(getFileName(value), fontFamily);

        if (familyVariantNames.includes(variantName)) {
          // There is an existing family/variant combination, so we need to
          // dedupe the variant name. This will only happen with some custom
          // font situations, like uploading multiple versions of the same
          // family/variant font file.
          const names: NameHash = {};
          for (let name of familyVariantNames) {
            names[name] = true;
          }
          const nameGenerator = new ElementNameGenerator(names);
          const newVariantName = nameGenerator.generate(variantName);
          fontVariant.variant = newVariantName;
          fontVariant.label = `${familyName} (${newVariantName})`;
          familyVariantNames.push(newVariantName);
        } else {
          familyVariantNames.push(variantName);
        }
      }
    }
  }
  isFontInfoLoaded() {
    return this.builtinFontFamilies !== undefined && this.builtinFontFamilies.length > 0;
  }
  async ensureBuiltInFontsLoaded() {
    if (!this.builtinFontFamilies) {
      this.builtinFontFamilies = [];
      this.builtinFontFamilies = await getBuiltinFontsInfo();
      for (let option of this.builtinFontFamilies) {
        this.builtinFamilies.add(option.family);
      }
      const fontFamilies = [...(this.customFontFamilies || []), ...this.builtinFontFamilies];
      this.processList(fontFamilies);
      this.notifySubscribers();
    }
    return this.builtinFontFamilies;
  }
  async refreshCustomFonts() {
    await globalState.storage.refreshCustomFonts();
    const customFonts = globalState.storage.getCustomFonts();

    if (!customFonts) return;
    if (!customFonts.length) return;

    const builtinFontFamilies = await this.ensureBuiltInFontsLoaded();

    const customFontFamilies: FontFamily[] = [];

    customFonts.forEach((customFont) => {
      let { family, variant, hash } = customFont;
      // Dedupe built-in family names
      if (this.builtinFamilies.has(family)) {
        family = family + " (Custom)";
      }
      let fontFamily = customFontFamilies.find(
        (existingFamily) => existingFamily.family === family
      );
      if (!fontFamily) {
        fontFamily = {
          family,
          category: CUSTOM_FONTS_CATEGORY,
          search: family.toLowerCase() + " custom",
          variants: [],
          complexity: -1,
        };
        customFontFamilies.push(fontFamily);
      }
      fontFamily.variants.push({
        variant,
        label: `${family} (${variant})`,
        value: `cuttle-font://${hash}`,
      });
    });

    this.customFontFamilies = customFontFamilies;

    const fontFamilies = [...this.customFontFamilies, ...(this.builtinFontFamilies || [])];
    this.processList(fontFamilies);
    this.notifySubscribers();
  }

  familyAndVariantByNames(family: string, variant: string) {
    const fontFamily = this.familyByName.get(family);
    if (fontFamily) {
      const variantLower = variant.toLocaleLowerCase();
      const fontVariant = fontFamily.variants.find(
        (v) => v.variant.toLocaleLowerCase() === variantLower
      );
      return { fontFamily, fontVariant };
    }
    return;
  }
  familyAndVariantByValue(value: string) {
    const fileName = getFileName(value);
    let fontFamily = this.familyByFileName.get(fileName);
    if (fontFamily) {
      const fontVariant = fontFamily.variants.find(
        (fontVariant) => getFileName(fontVariant.value) === fileName
      );
      if (fontVariant) {
        return { fontFamily, fontVariant };
      }
    }
    return;
  }
  familyDefaultVariant(family: string) {
    const fontFamily = this.familyByName.get(family);
    if (!fontFamily) return;
    for (let fontVariant of fontFamily.variants) {
      const variantName = fontVariant.variant;
      if (variantName === "regular" || variantName === "Regular") return fontVariant;
    }
    return fontFamily.variants[0];
  }

  /**
   * "All" category without search query will exclude "world" category fonts.
   *
   * We do this because:
   * - They lack latin characters causing "tofu" blocks to show up.
   * - CJK fonts they have large sizes, and people probably don't want to use
   *   them if their design doesn't include those characters.
   * - The Noto list is long, and we don't want those variants around the
   *   default Noto font when first opening the picker.
   *
   * "All" category *with* search query will include "world" fonts.
   */
  filteredFontFamilyNames(category: string, filterQuery: string, ensureFamilyInAll?: string) {
    const families = this.familiesByCategory.get(category);
    if (!families) return [];
    let familiesArray = Array.from(families);
    if (filterQuery !== "") {
      if (category === ALL_FONTS_CATEGORY) {
        familiesArray = this.sortedAll;
      }
      const filterQueryLower = filterQuery.toLowerCase();
      familiesArray = familiesArray.filter(({ search }) => search.includes(filterQueryLower));
    }
    const familyInfo = familiesArray.map((fontFamily) => {
      return {
        label: fontFamily.family,
        value: fontFamily.family,
        pro: fontFamily.pro,
      };
    });
    if (
      category === ALL_FONTS_CATEGORY &&
      ensureFamilyInAll &&
      !familyInfo.some((info) => info.value === ensureFamilyInAll)
    ) {
      familyInfo.push({ label: ensureFamilyInAll, value: ensureFamilyInAll, pro: false });
      familyInfo.sort((a, b) => a.label.localeCompare(b.label));
    }
    return familyInfo;
  }

  filteredFontFamilies(category: string, filterQuery: string) {
    const families = this.familiesByCategory.get(category);
    if (!families) return [];
    let familiesArray = Array.from(families);
    if (filterQuery !== "") {
      if (category === ALL_FONTS_CATEGORY) {
        familiesArray = this.sortedAll;
      }
      const filterQueryLower = filterQuery.toLowerCase();
      familiesArray = familiesArray.filter(({ search }) => search.includes(filterQueryLower));
    }
    return familiesArray;
  }

  variantsByFamilyName(family: string) {
    const fontFamily = this.familyByName.get(family);
    if (!fontFamily) return;
    return fontFamily.variants.map((fontVariant) => fontVariant.variant);
  }

  nextCustomFontValue(value: string) {
    const familyAndVariant = this.familyAndVariantByValue(value);
    if (!familyAndVariant) return;

    const { fontFamily, fontVariant } = familyAndVariant;
    const { variants } = fontFamily;
    if (variants.length > 1) {
      const currentIndex = variants.indexOf(fontVariant);
      const nextIndex = (currentIndex + 1) % variants.length;
      return variants[nextIndex].value;
    }

    const myFonts = this.familiesByCategory.get(CUSTOM_FONTS_CATEGORY);
    if (myFonts && myFonts.size > 1) {
      const myFontFamilies = Array.from(myFonts);
      const currentIndex = myFontFamilies.indexOf(fontFamily);
      const nextIndex = (currentIndex + 1) % myFontFamilies.length;
      const nextFamily = myFontFamilies[nextIndex];
      const nextVariant = this.familyDefaultVariant(nextFamily.family);
      if (nextVariant) {
        return nextVariant.value;
      }
    }

    // Default back to Noto Sans
    return this.familyDefaultVariant("Noto Sans")?.value;
  }

  /**
   * 1. Optimistically remove a custom font from the list.
   * 2. Call the API method to remove font ownership.
   * 3. Refresh custom fonts from the from the server.
   */
  async removeCustomFont(value: string) {
    // Ensure the font is a custom font
    const hash = hashForCustomFontURL(value);
    if (!hash) return;

    // Optimistically remove the custom font from the list.
    const foundFamilyAndVariant = this.familyAndVariantByValue(value);
    if (!foundFamilyAndVariant) return;
    const familyIndex = this.sortedAll.indexOf(foundFamilyAndVariant.fontFamily);
    if (familyIndex === -1) return;
    const variantIndex = foundFamilyAndVariant.fontFamily.variants.indexOf(
      foundFamilyAndVariant.fontVariant
    );
    if (variantIndex === -1) return;
    foundFamilyAndVariant.fontFamily.variants.splice(variantIndex, 1);
    if (foundFamilyAndVariant.fontFamily.variants.length === 0) {
      this.sortedAll.splice(familyIndex, 1);
    }
    this.processList(this.sortedAll);
    // Because font picker is closed with the modal confirmation, this is enough
    // for the list to be updated when re-opening the font picker.

    // API call
    await globalState.storage.removeFontForLoggedInUser(hash);

    // This also calls this.processList and notifies subscribers. We expect no
    // change from the optimistically-pruned custom font list.
    await fontList.refreshCustomFonts();
  }

  subscribe(subscriber: FontListChangeSubscriber) {
    this.subscribers.add(subscriber);
  }
  unsubscribe(subscriber: FontListChangeSubscriber) {
    this.subscribers.delete(subscriber);
  }
  notifySubscribers() {
    for (let subscriber of this.subscribers) {
      subscriber();
    }
  }
}

// Utils

/** Matches the last part of the path. Done this way to match previous versions
 * of a Google font. For example, v23/libre.ttf matches v24/libre.ttf  */
const getFileName = (url: string) => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};

/** Matches just the last part of the given URLs, including different versions
 * of Google fonts.
 */
export const isFontFileNameInFontURLArray = (url: string, urlArray: ReadonlyArray<string>) => {
  const fileName = getFileName(url);
  return urlArray.some((value) => getFileName(value) === fileName);
};

export const filterFontFileNameFromFontURLArray = (
  url: string,
  urlArray: ReadonlyArray<string>
) => {
  const fileName = getFileName(url);
  return urlArray.filter((value) => getFileName(value) !== fileName);
};

// Singleton
export const fontList = new FontList();
