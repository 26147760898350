import { unitForUnitName } from "../geom";
import { expressionCodeForNumber, expressionCodeForVec } from "./expression-code";
import { parseLiteral } from "./parse-literal";
import { UpgradeSnapshotOptions } from "./project-snapshot-upgrade";

export const upgradeSnapshotUntyped = (parsed: any, options?: UpgradeSnapshotOptions) => {
  if (parsed.version === 1) {
    // The parameters of a Component or Modifier used to be a plain object with
    // name and expression, but we made Parameter its own class, so we need to
    // find all our Components and Modifiers, and convert all their parameters
    // to the Parameter class.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object") {
        if (o["@class"] === "Component" || o["@class"] === "Modifier") {
          // Parameters can't be shared, so we don't need to worry about @ref's.
          o.parameters.forEach((parameter: any) => {
            parameter["@class"] = "Parameter";
          });
        }
      }
    });
    parsed.version = 2;
  }

  if (parsed.version === 2) {
    // We had a bug where built-ins were getting renamed because later built-ins
    // referenced them as internals. Now that that's fixed, we need to correct
    // these misnamed built-ins.
    const fixes = {
      "PolygonDefinition/5": "PathDefinition",
      "PolygonDefinition/19": "AnchorDefinition",
      "PolygonDefinition/10": "RotationalRepeatDefinition",
      "RectangleDefinition/7": "RoundCornersDefinition",
    };
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object") {
        if (fixes.hasOwnProperty(o["@builtin"])) {
          o["@builtin"] = (fixes as any)[o["@builtin"]];
        }
      }
    });

    parsed.version = 3;
  }

  if (parsed.version === 3) {
    // We renamed the Stroke modifier to Outline Stroke, so we need to change
    // references to the built-in StrokeDefinition to point to the built-in
    // OutlineStrokeDefinition.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@builtin"] === "StrokeDefinition") {
        o["@builtin"] = "OutlineStrokeDefinition";
      }
    });

    parsed.version = 4;
  }

  if (parsed.version === 4) {
    // We got rid of transformArgs and just put those properties on args with
    // the names __transform_position, etc. So we need to find all Elements and
    // move their transformArgs into args appropriately.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Element") {
        if (o.transformArgs.position) {
          o.args.__transform_position = o.transformArgs.position;
        }
        if (o.transformArgs.rotation) {
          o.args.__transform_rotation = o.transformArgs.rotation;
        }
        if (o.transformArgs.scale) {
          o.args.__transform_scale = o.transformArgs.scale;
        }
        delete o.transformArgs;
      }
    });

    parsed.version = 5;
  }

  if (parsed.version === 5) {
    // We added a hasTransform property to Element which defaults to false. But
    // older files that have hasTransform undefined will then lose all their
    // transforms. So we'll check if an Element has undefined hasTransform and
    // then if it has any transform args we'll set its hasTransform to true, and
    // false otherwise.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Element") {
        if (o.hasTransform === undefined) {
          o.hasTransform = !!(
            o.args.__transform_position ||
            o.args.__transform_rotation ||
            o.args.__transform_scale ||
            o.args.__transform_matrix
          );
        }
      }
    });

    parsed.version = 6;
  }

  if (parsed.version === 6) {
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Project") {
        // We changed the canonical project unit names to the abbreviated
        // versions. For example "millimeters" becomes "mm". We look up project
        // units and swap in the corresponding abbreviation.
        if (typeof o.units === "string") {
          const unit = unitForUnitName(o.units);
          if (unit !== null) {
            o.units = unit;
          }
        }

        // transformCenter was made private and renamed _transformCenter. We
        // need to remove the old transformCenter property so that it doesn't
        // hide the the prototypes's transformCenter function.
        if (o.transformCenter !== undefined) {
          o._transformCenter = o.transformCenter;
          delete o.transformCenter;
        }
      }
    });

    parsed.version = 7;
  }

  if (parsed.version === 7) {
    const modifierIds = new Set();
    const componentIds = new Set();
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object") {
        if (o["@class"] === "Modifier") modifierIds.add(o["@id"]);
        else if (o["@class"] === "Component") componentIds.add(o["@id"]);
      }
    });

    walk(parsed.payload, (o) => {
      // We renamed "Join Paths" to "Merge Paths"
      if (o && typeof o === "object" && o["@builtin"] === "JoinPathsDefinition") {
        o["@builtin"] = "MergePathsDefinition";
      }
    });

    // Transform, Fill and Stroke have been converted from being enabled with
    // hasTransform, hasFill, hasStroke with associated __transform_xxx style
    // args. We need to replace these with Modifier Instances.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Element") {
        if (o.hasTransform) {
          o.transform = {
            "@class": "Instance",
            args: {},
            definition: { "@builtin": "TransformDefinition" },
          };
          if (o.args.__transform_position !== undefined) {
            o.transform.args.position = o.args.__transform_position;
            delete o.args.__transform_position;
          }
          if (o.args.__transform_rotation !== undefined) {
            o.transform.args.rotation = o.args.__transform_rotation;
            delete o.args.__transform_rotation;
          }
          if (o.args.__transform_scale !== undefined) {
            o.transform.args.scale = o.args.__transform_scale;
            delete o.args.__transform_scale;
          }
          if (o.args.__transform_matrix !== undefined) {
            o.transform.args.matrix = o.args.__transform_matrix;
            delete o.args.__transform_matrix;
          }
        }
        if (o.hasFill) {
          o.fill = { "@class": "Instance", args: {}, definition: { "@builtin": "FillDefinition" } };
          if (o.args.__fill_color !== undefined) {
            o.fill.args.color = o.args.__fill_color;
            delete o.args.__fill_color;
          }
        }
        if (o.hasStroke) {
          o.stroke = {
            "@class": "Instance",
            args: {},
            definition: { "@builtin": "StrokeDefinition" },
          };
          if (o.args.__stroke_color !== undefined) {
            o.stroke.args.color = o.args.__stroke_color;
            delete o.args.__stroke_color;
          }
          if (o.args.__stroke_hairline !== undefined) {
            o.stroke.args.hairline = o.args.__stroke_hairline;
            delete o.args.__stroke_hairline;
          }
          if (o.args.__stroke_width !== undefined) {
            o.stroke.args.width = o.args.__stroke_width;
            delete o.args.__stroke_width;
          }
          if (o.args.__stroke_alignment !== undefined) {
            o.stroke.args.alignment = o.args.__stroke_alignment;
            delete o.args.__stroke_alignment;
          }
          if (o.args.__stroke_cap !== undefined) {
            o.stroke.args.cap = o.args.__stroke_cap;
            delete o.args.__stroke_cap;
          }
          if (o.args.__stroke_join !== undefined) {
            o.stroke.args.join = o.args.__stroke_join;
            delete o.args.__stroke_join;
          }
          if (o.args.__stroke_miterLimit !== undefined) {
            o.stroke.args.miterlimit = o.args.__stroke_miterLimit;
            delete o.args.__stroke_miterLimit;
          }
        }

        delete o.hasTransform;
        delete o.hasFill;
        delete o.hasStroke;

        // Element args and definiton have been moved to a base Instance
        o.base = { "@class": "Instance", args: o.args, definition: o.definition };

        delete o.definition;
        delete o.args;
      }
    });

    // We restructured Component so that instead of an array of Elements it
    // simply has one top level element. We need to move Component.children to
    // Component.element.children
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Component") {
        o.element = {
          "@class": "Element",
          "@id": o["@id"] + "/element", // Separate @id revisions with "/"
          base: { "@class": "Instance", definition: { "@builtin": "GroupDefinition" } },
        };
        if (Array.isArray(o.children)) {
          o.element.children = o.children;
        }
        delete o.children;
      }
    });

    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Project") {
        o.focusedNode = {
          "@class": "Node",
          parent: null,
          source: { "@ref": o.focusedComponent["@ref"] + "/element" },
        };
        delete o.selectedNodes;
        delete o.expandedNodes;
      }
    });

    // Modifiers are now applied as badges instead of being their own groups
    // with children. We need to transform existing grouped modifiers into
    // Group elements with a modifier badge.
    const isBadgeModifierElement = (o: any) => {
      if (o && typeof o === "object" && o["@class"] === "Element") {
        let definition = o.base.definition;
        const builtin = o.base.definition["@builtin"];
        if (
          builtin &&
          builtin !== "GroupDefinition" &&
          builtin !== "ShapeDefinition" &&
          builtin !== "PathDefinition" &&
          builtin !== "AnchorDefinition" &&
          builtin !== "TextDefinition" &&
          builtin !== "RectangleDefinition" &&
          builtin !== "CircleDefinition" &&
          builtin !== "PolygonDefinition"
        ) {
          return true;
        } else if (definition["@class"] === "Modifier") {
          return true;
        } else if (
          definition["@ref"] !== undefined &&
          modifierIds.has(definition["@ref"].toString())
        ) {
          return true;
        }
      }
      return false;
    };
    walk(parsed.payload, (o) => {
      if (isBadgeModifierElement(o)) {
        if (o.transform) {
          // If the element has a transform we need to nest it inside a group to
          // preserve the order of operations. In version 8 transforms are
          // applied before modifiers, but previously they were applied after.
          o.children = [
            {
              "@class": "Element",
              name: o.name,
              base: { "@class": "Instance", definition: { "@builtin": "GroupDefinition" } },
              modifiers: [o.base],
              children: o.children,
            },
          ];
          o.name += " Transform";
          o.base = { "@class": "Instance", definition: { "@builtin": "GroupDefinition" } };
        } else {
          o.modifiers = [o.base];
          o.base = { "@class": "Instance", definition: { "@builtin": "GroupDefinition" } };
        }
      }
    });

    parsed.version = 8;
  }

  if (parsed.version === 8) {
    // We generalized selection to include things other than Nodes. Clear out
    // the old selection properties that are no longer used.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Project") {
        delete o.selectedNodes;
        delete o.hoveredNode;
        delete o.hoveredParamName;
      }
    });

    parsed.version = 9;
  }

  if (parsed.version === 9) {
    // We removed the Selection class and moved selection.items to
    // selectedItems. Since this isn't a public facing upgrade we'll just clear
    // out the selection.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Project") {
        delete o.selection;
      }
    });

    parsed.version = 10;
  }

  if (parsed.version === 10) {
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Element") {
        if (o.base.definition["@builtin"] === "AnchorDefinition") {
          if (o.transform?.args.position) {
            o.base.args.position = o.transform.args.position;
            delete o.transform;
          }
        }
      }
    });

    parsed.version = 11;
  }

  if (parsed.version === 11) {
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Instance") {
        if (o.definition["@builtin"] === "TransformDefinition") {
          if (o.args.matrix) {
            const literal = parseLiteral(o.args.matrix.jsCode);
            if (literal && literal.type === "AffineMatrix") {
              const transform = literal.value.toTransform();
              o.args.position = {
                "@class": "Expression",
                jsCode: expressionCodeForVec(transform.position),
              };
              o.args.rotation = {
                "@class": "Expression",
                jsCode: expressionCodeForNumber(transform.rotation),
              };
              o.args.scale = {
                "@class": "Expression",
                jsCode: expressionCodeForVec(transform.scale),
              };
              o.args.skew = {
                "@class": "Expression",
                jsCode: expressionCodeForNumber(transform.skew),
              };
            }
            delete o.args.matrix;
          }
        }
      }
    });

    parsed.version = 12;
  }

  if (parsed.version === 12) {
    // Before we used no Fill and Stroke to mean the default styling -- black
    // hairline stroke. But now we're explicit about the style -- having no fill
    // and no stroke means the shape is invisible. When you create a new Path,
    // Shape, or built-in (Circle, Rectangle, etc), we assign the default
    // styling by turning on the Element's stroke. Note that Circle, Rectangle,
    // etc have internal paths that are invisible! To migrate old projects,
    // we're looking for anything that would have been given the default styling
    // (i.e. any Element whose definition is a built-in other than Anchor and
    // Group), whose fill or stroke was not explicitly changed by the user, and
    // assigning the default styling.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Element") {
        const builtin = o.base.definition["@builtin"];
        if (
          typeof builtin === "string" &&
          builtin !== "AnchorDefinition" &&
          builtin !== "GroupDefinition"
        ) {
          if (!o.fill && !o.stroke) {
            o.stroke = { "@class": "Instance", definition: { "@builtin": "StrokeDefinition" } };
          }
        }
      }
    });

    parsed.version = 13;
  }

  if (parsed.version === 13) {
    // ElementsAndDependencies was changed to include parameter dependencies.
    // The old dependencies array is now an object. It's Component | Modifier
    // items need to be moved to the `componentsAndModifiers` property of the
    // new dependencies object.
    if (parsed.type === "ElementsAndDependencies") {
      parsed.payload.dependencies = {
        componentsAndModifiers: parsed.payload.dependencies,
        componentParameters: [],
        projectParameters: [],
      };
    }

    parsed.version = 14;
  }

  if (parsed.version === 14) {
    // Shape was renamed to CompoundPath pervasively. We need to update old
    // @builtins from "ShapeDefinition" to "CompoundPathDefinition". We also
    // need to replace references to Shape and .allShapes() or
    // .allShapesAndOrphanPaths() in user expression code.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object") {
        if (o["@builtin"] === "ShapeDefinition") {
          o["@builtin"] = "CompoundPathDefinition";
        } else if (o["@class"] === "Expression" && typeof o.jsCode === "string") {
          // Shape() -> CompoundPath()
          o.jsCode = o.jsCode.replace(/(\W+)(Shape)\s*\(/g, "$1CompoundPath(");

          // .allShapes() -> .allCompoundPaths()
          o.jsCode = o.jsCode.replace(/.allShapes\s*\(/g, ".allCompoundPaths(");

          // .allShapesAndOrphanedPaths -> .allCompoundPathsAndOrphanedPaths
          o.jsCode = o.jsCode.replace(
            /.allShapesAndOrphanedPaths\s*\(/g,
            ".allCompoundPathsAndOrphanedPaths("
          );
        }
      }
    });

    parsed.version = 15;
  }

  if (parsed.version === 15) {
    // We removed the "point" handleConstraint from AnchorDefinition. The
    // defualt handleConstraint is now "free".
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Element") {
        if (o.base.definition["@builtin"] === "AnchorDefinition") {
          if (o.base.args["handleConstraint"]?.jsCode === '"point"') {
            o.base.args["handleConstraint"].jsCode = '"free"';
          }
        }
      }
    });

    parsed.version = 16;
  }

  if (parsed.version === 16) {
    // Split-segment was found to be adding transform instances to Anchors.
    // Transforms are hidden on Anchors, but are still evaluated if they exist.
    // This results in strange behavior when the anchor is transformed, since
    // the rendered anchor incorporates the transform position but the
    // transformed position value does not.
    //
    // If an anchor has a transform, this adds its transform position to its
    // position parameter and removes the transform.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Element") {
        if (o.base.definition["@builtin"] === "AnchorDefinition") {
          if (o.transform?.args.position) {
            const transformPosition = parseLiteral(o.transform.args.position.jsCode);
            if (transformPosition && transformPosition.type === "Vec") {
              if (o.base.args.position) {
                const basePosition = parseLiteral(o.base.args.position.jsCode);
                if (basePosition && basePosition.type === "Vec") {
                  transformPosition.value.add(basePosition.value);
                }
              }
              o.base.args.position = {
                "@class": "Expression",
                jsCode: expressionCodeForVec(transformPosition.value),
              };
            }
            delete o.transform;
          }
        }
      }
    });

    parsed.version = 17;
  }

  if (parsed.version === 17) {
    // We added CodeComponents, which are like the old Generators, but have a
    // type now and don't show up in the modifiers menu. We need to look
    // through the project and find Elements that have a Modifier as their base
    // instance. All of those Modifiers need to be converted into
    // CodeComponents.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Element") {
        const definition = o.base.definition;
        if (definition["@builtin"] === undefined && definition["@class"] === "Modifier") {
          definition["@class"] = "CodeComponent";
          if (parsed.type === "Project") {
            const id = definition["@id"];
            parsed.payload.components.push({ "@ref": id });
            parsed.payload.modifiers = parsed.payload.modifiers.filter(
              (m: any) => m["@ref"] !== id
            );
          }
        }
      }
    });

    // We also combined project.focusedNode and project.focusedComponent into
    // project.focus
    if (parsed.type === "Project") {
      const project = parsed.payload;
      project.focus = {
        "@class": "ComponentFocus",
        component: project.focusedComponent,
        node: project.focusedNode,
      };
      delete project.focusedComponent;
      delete project.focusedNode;
    }

    parsed.version = 18;
  }

  if (parsed.version === 18) {
    // We changed the names of a few functions in the geometry library. We'll
    // try to find/replace functions with these names.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Expression") {
        // .tightBoundingBox() -> .boundingBox()
        o.jsCode = o.jsCode.replace(/\.tightBoundingBox\s*\(/g, ".boundingBox(");

        // .segments() -> .segmentPaths()
        o.jsCode = o.jsCode.replace(/\.segments\s*\(/g, ".segmentPaths(");

        // .edges() -> .edgePaths()
        o.jsCode = o.jsCode.replace(/\.edges\s*\(/g, ".edgePaths(");
      }
    });

    parsed.version = 19;
  }

  if (parsed.version === 19) {
    // We changed CodeComponent so that it no longer inherits from Modifier,
    // which caused Project's `editingModifiers` nomenclature to no longer be
    // accurate. It's now named `definitionsEditingCode`.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Project") {
        o.definitionsEditingCode = o.editingModifiers;
      }
    });

    parsed.version = 20;
  }

  if (parsed.version === 20) {
    // Variations of built-in components and modifiers were found to have
    // parameter interfaces that were references to built-in internals. These
    // should have been cloned. Since we don't yet allow users to specify
    // interfaces on parameters, we'll clear any out.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Parameter") {
        delete o.interface;
      }
    });

    parsed.version = 21;
  }

  if (parsed.version === 21) {
    // Components and Modifiers store their origin project id, to be able to
    // link back to the originating project
    let projectId = options?.defaultProjectId ?? "";

    walk(parsed.payload, (o) => {
      if (
        o &&
        typeof o === "object" &&
        (o["@class"] === "Component" ||
          o["@class"] === "Modifier" ||
          o["@class"] === "CodeComponent")
      ) {
        if (!o.projectId) {
          o.projectId = projectId;
        }
        // Component and Modifier o.id is set to a new UUID in their base
        // Instance initialization, so we don't need to add them during
        // migration here.
      }
    });

    parsed.version = 22;
  }

  if (parsed.version === 22) {
    // We changed RectangleDefinition's default `scale` parameter from `Vec(1,
    // 1)` to simply `1` (uniform scale). In order to preserve the behavior of
    // existing Rectangle scaling behavior, we need to assign non-uniform scale
    // arguments to all rectangle instances without an existing arg.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Element") {
        if (o.base.definition["@builtin"] === "RectangleDefinition1") {
          if (o.transform && !o.transform.args.scale) {
            o.transform.args.scale = { "@class": "Expression", jsCode: "Vec(1.00, 1.00)" };
          }
        }
      }
    });

    parsed.version = 23;
  }

  if (parsed.version === 23) {
    // Rectangle was updated to have its center at Vec(0, 0). We try to upgrade
    // instances of old rectangles by subtracting Vec(0.5) from the origin, if
    // it's either a literal, or doesn't exist.
    //
    // We also append any `Rectangle()` first-class call with a transform that
    // moves it back to where the old rectangle component would have been.
    //
    // We also changed the default fill from black to 50% gray. Old fills need
    // to be updated.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object") {
        // Rectangle instances
        if (o["@class"] === "Element" && o.base.definition["@builtin"] === "RectangleDefinition1") {
          if (!o.transform || !o.transform.isEnabled) {
            o.transform = {
              "@class": "Instance",
              args: {},
              definition: { "@builtin": "TransformDefinition" },
              isEnabled: true,
            };
          }
          const args = o.transform.args;
          if (args.origin) {
            const originLiteral = parseLiteral(args.origin.jsCode);
            if (originLiteral && originLiteral.type === "Vec") {
              originLiteral.value.subScalar(0.5);
              const jsCode = expressionCodeForVec(originLiteral.value);
              args.origin = { "@class": "Expression", jsCode };
              o.base.definition["@builtin"] = "RectangleDefinition2";
            }
          } else {
            args.origin = { "@class": "Expression", jsCode: "Vec(-0.5, -0.5)" };
            o.base.definition["@builtin"] = "RectangleDefinition2";
          }
        }

        // Expressions
        if (o["@class"] === "Expression") {
          if (typeof o.jsCode === "string") {
            o.jsCode = o.jsCode.replaceAll(
              "Rectangle()",
              "Rectangle().transform({ position: Vec(0.5, 0.5) })"
            );
          }
        }

        // Fills
        if (o["@class"] === "Instance" && o.definition["@builtin"] === "FillDefinition") {
          if (!o.args.color) {
            o.args.color = { "@class": "Expression", jsCode: "Color(0.000, 0.000, 0.000, 1.000)" };
          }
        }
      }
    });

    parsed.version = 24;
  }

  if (parsed.version === 24) {
    // We changed MirrorRepeatDefinition's default `reverse` parameter from
    // `true` to `false`. In order to preserve the behavior of existing Mirror
    // Repeats, we need to assign `reverse` arguments to all instances
    // without an existing arg.
    walk(parsed.payload, (o) => {
      if (
        o &&
        typeof o === "object" &&
        o["@class"] === "Instance" &&
        o.definition["@builtin"] === "MirrorRepeatDefinition"
      ) {
        if (!o.args.reverse) {
          o.args.reverse = { "@class": "Expression", jsCode: "true" };
        }
      }
    });

    parsed.version = 25;
  }

  if (parsed.version === 25) {
    // Guides dragged from the rulers were changed from HorizontalGuide and
    // VerticalGuide to just a LineGuide with a 0° or 90° angle.
    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Instance") {
        if (o.definition["@builtin"] === "HorizontalGuideDefinition") {
          o.definition["@builtin"] = "LineGuideDefinition";
        }
        if (o.definition["@builtin"] === "VerticalGuideDefinition") {
          o.definition["@builtin"] = "LineGuideDefinition";
          o.args.angle = { "@class": "Expression", jsCode: "90" };
        }
      }
    });

    parsed.version = 26;
  }

  if (parsed.version === 26) {
    // We removed the `clockwise` parameter from Rotational Repeat. In its
    // place, the new `endAngle` parameter can be set to -360 to achieve the
    // same effect.
    walk(parsed.payload, (o) => {
      if (
        o &&
        typeof o === "object" &&
        o["@class"] === "Instance" &&
        o.definition["@builtin"] === "RotationalRepeatDefinition"
      ) {
        if (o.args.clockwise) {
          const literal = parseLiteral(o.args.clockwise.jsCode);
          if (literal && literal.type === "Boolean" && literal.value === false) {
            o.args.endAngle = { "@class": "Expression", jsCode: "-360" };
          }
          delete o.args.clockwise;
        }
      }
    });

    parsed.version = 27;
  }

  if (parsed.version === 27) {
    // `ElementsAndDependencies` was changed to `PortableProjectData`. Flatten
    // and remove `dependencies`, and separate `components` and `modifiers`.
    if (parsed.type === "ElementsAndDependencies") {
      const modifierIds = new Set();
      const componentIds = new Set();
      walk(parsed.payload, (o) => {
        if (o && typeof o === "object") {
          if (o["@class"] === "Modifier") {
            modifierIds.add(o["@id"]);
          } else if (o["@class"] === "Component" || o["@class"] === "CodeComponent") {
            componentIds.add(o["@id"]);
          }
        }
      });

      parsed.type = "PortableProjectData";
      // Discards @builtin items
      parsed.payload.components = parsed.payload.dependencies.componentsAndModifiers.filter(
        (maybeComponent: any) =>
          maybeComponent.hasOwnProperty("@ref") && componentIds.has(maybeComponent["@ref"])
      );
      parsed.payload.modifiers = parsed.payload.dependencies.componentsAndModifiers.filter(
        (maybeModifier: any) =>
          maybeModifier.hasOwnProperty("@ref") && modifierIds.has(maybeModifier["@ref"])
      );
      parsed.payload.componentParameters = parsed.payload.dependencies.componentParameters;
      parsed.payload.projectParameters = parsed.payload.dependencies.projectParameters;
      delete parsed.payload.dependencies;
    }
    parsed.version = 28;
  }

  if (parsed.version === 28) {
    // Project settings were moved from the root of Project to
    // `project.settings`. We need to copy the settings to the new location.

    if (parsed.type === "Project") {
      const project = parsed.payload;
      project.settings = {
        units: project.units,
        hairlineStrokeWidth: project.hairlineStrokeWidth,
        tolerance: project.tolerance,
        "@class": "ProjectSettings",
      };
    }

    parsed.version = 29;
  }

  if (parsed.version === 29) {
    // In a previous update, we changed the way that PISelect options values
    // were stored. Specifically, we removed the "" quotes so that the _value_
    // rather than the _expression_ was represented. We now need to remove
    // quotes from any existing PISelect options.

    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "PISelect") {
        if (Array.isArray(o.options)) {
          for (let option of o.options) {
            if (!(typeof option.value === "string")) continue;
            const literal = parseLiteral(option.value);
            if (literal && literal.type === "String") {
              option.value = literal.value;
            }
          }
        }
      }
    });

    parsed.version = 30;
  }

  if (parsed.version === 30) {
    // During dev of the raster images feature, we had a built-in shape called
    // "Image". This was later renamed to "ImageFrame". To preserve project
    // data, we need to update the old names.

    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@builtin"] === "ImageDefinition") {
        o["@builtin"] = "ImageFrameDefinition";
      }
    });

    parsed.version = 31;
  }

  if (parsed.version === 31) {
    // We changed the parameterization of the Text Within Box modifier.

    walk(parsed.payload, (o) => {
      if (
        o &&
        typeof o === "object" &&
        o["@class"] === "Instance" &&
        o.definition["@builtin"] === "TextWithinBoxDefinition"
      ) {
        let preventOverhangs = true;
        if (o.args.preventOverhangs) {
          const literal = parseLiteral(o.args.preventOverhangs.jsCode);
          if (literal && literal.type === "Boolean") {
            preventOverhangs = literal.value;
          }
          delete o.args.preventOverhangs;
        }
        if (preventOverhangs) {
          o.args.scaling = { "@class": "Expression", jsCode: `"shrink"` };
        }

        let verticalAlign = "middle";
        if (o.args.verticalAlign) {
          const literal = parseLiteral(o.args.verticalAlign.jsCode);
          if (literal && literal.type === "String") {
            verticalAlign = literal.value;
          }
        }
        if (verticalAlign === "baseline") {
          o.args.verticalAlign = { "@class": "Expression", jsCode: `"top"` };
        }
      }
    });

    parsed.version = 32;
  }

  if (parsed.version === 32) {
    // We changed SelectableParameter and SelectableComponentParameter so that
    // they store a parameter reference instead of just a name. All these
    // parameters are invalid and must be removed. We could selectively clear
    // them out of the selection but it's simpler to clear the whole selection.

    walk(parsed.payload, (o) => {
      if (o && typeof o === "object" && o["@class"] === "Project") {
        if (typeof o.selection === "object") {
          o.selection.items = [];
        }
      }
    });

    parsed.version = 33;
  }

  if (parsed.version === 33) {
    // We removed cricut.svg as a format, and made it a postprocess option.

    walk(parsed.payload, (o) => {
      if (
        o &&
        typeof o === "object" &&
        (o["@class"] === "Component" || o["@class"] === "CodeComponent")
      ) {
        if (o.defaultExportFormat === "cricut.svg") {
          o.defaultExportFormat = "svg";
        }
      }
    });

    parsed.version = 34;
  }
};

const walk = (o: any, fn: (o: any) => void) => {
  fn(o);
  if (Array.isArray(o)) {
    o.forEach((child) => walk(child, fn));
  } else if (o && typeof o === "object") {
    Object.values(o).forEach((child) => walk(child, fn));
  }
};
