import m from "mithril";

import { assert } from "../../geom";
import { globalState } from "../../global-state";
import { CodeComponent, Component } from "../../model/component";
import { Instance } from "../../model/instance";
import { Modifier } from "../../model/modifier";
import { Node } from "../../model/node";
import { SelectableInstance } from "../../model/selectable";
import { Icon20 } from "../../shared/icon";
import { Tooltipped } from "../../shared/popup";
import { Checkbox } from "../basic/checkbox";
import { InstanceInspectorContents } from "./instance-inspector-contents";

export interface InstanceInspectorMultiAttrs {
  nodesInDocumentOrder: Node[];
  definition: Modifier;
  slot: "fill" | "stroke" | "transform";
  rootComponent: Component | CodeComponent;
}
export const InstanceInspectorMulti: m.Component<InstanceInspectorMultiAttrs> = {
  view({ attrs: { nodesInDocumentOrder: nodes, definition, slot, rootComponent } }) {
    const isImmutable = nodes.some((node) => node.isImmutable());
    if (isImmutable) return;

    let isError = false;
    let enabledCount = 0;
    for (let node of nodes) {
      const instance = node.source[slot];
      if (instance) {
        if (instance.isEnabled) {
          enabledCount++;
        }
        if (!isError) {
          const instanceTrace = globalState.traceForNode(node)?.traceForInstance(instance);
          if (instanceTrace && !instanceTrace.isSuccess()) {
            isError = true;
          }
        }
      }
    }

    const isAllEnabled = enabledCount === nodes.length;
    const isMixedEnabled = !isAllEnabled && enabledCount > 0;

    const onClickEnabledCheckbox = (e: MouseEvent) => {
      if (isImmutable) return;
      const isChecked =
        e.target instanceof HTMLButtonElement ||
        (e.target instanceof HTMLInputElement && e.target.checked);
      let firstEnabledInstance: Instance | undefined;
      for (let node of nodes) {
        const instance = node.source[slot];
        if (instance?.isEnabled) {
          firstEnabledInstance = instance;
        }
      }
      for (let node of nodes) {
        const instance = node.source[slot];
        if (instance instanceof Instance) {
          instance.isEnabled = isChecked;
        } else if (isChecked) {
          if (firstEnabledInstance) {
            node.source[slot] = firstEnabledInstance.clone();
          } else {
            node.source[slot] = new Instance(definition);
          }
        }
      }
    };

    let mCheckbox: m.Children = m(Checkbox, {
      disabled: isImmutable,
      // We want to be able to enable all in selection with one click
      checked: isAllEnabled,
      mixed: isMixedEnabled,
      onclick: onClickEnabledCheckbox,
    });

    if (isMixedEnabled) {
      mCheckbox = m(
        Tooltipped,
        {
          message: () => [`Mixed ${slot}.`, m("br"), `Click to add ${slot} to all selected items.`],
        },
        mCheckbox
      );
    }

    return m(".inspector-section", [
      m(
        ".inspector-header.inspector-row",
        m("label.inspector-header-label", [
          m(".inspector-header-enabled", mCheckbox),
          definition instanceof Modifier && definition.icon !== undefined
            ? m(".inspector-header-icon", m(Icon20, { icon: definition.icon }))
            : undefined,
          m(".inspector-header-name", definition.name),
        ])
      ),
      isAllEnabled &&
        m(InstanceInspectorContents, {
          selectables: nodes.map((node) => {
            const instance = node.source[slot];
            assert(instance);
            return new SelectableInstance(node, instance);
          }),
          rootComponent,
          isEditingDefinition: false,
        }),
    ]);
  },
};
