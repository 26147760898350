import { isNumber } from "../shared/util";
import { FillDefinition, StrokeDefinition, TransformDefinition } from "./builtin-primitives";
import { Instance } from "./instance";
import { ModelObject } from "./model-object";
import { registerClass } from "./registry";

export type GuidesDisplay = "show" | "show-all-as-guides" | "hide";

export class Element extends ModelObject {
  name = "";

  base: Instance;
  transform: Instance | null = null;

  modifiers: Instance[] = [];

  fill: Instance | null = null;
  stroke: Instance | null = null;

  children: Element[] = [];

  isLocked = false;
  isVisible = true;

  /** `"show"` means default, which is controlled by InstanceDefinition's `isShowGuides`.
   * `"show-all-as-guides"` is the other option.
   */
  guidesDisplay: GuidesDisplay = "show";

  materialKeys() {
    return [
      "name",
      "base",
      "transform",
      "modifiers",
      "fill",
      "stroke",
      "children",
      "isLocked",
      "isVisible",
      "guidesDisplay",
    ];
  }

  constructor(base: Instance) {
    super();
    this.base = base;
  }

  clone() {
    const element = this.cloneWithoutChildren();
    element.children = this.children.map((child) => child.clone());
    return element;
  }
  cloneWithoutChildren() {
    const element = new Element(this.base.clone());
    element.name = this.name;
    if (this.transform) element.transform = this.transform.clone();
    element.modifiers = this.modifiers.map((modifier) => modifier.clone());
    if (this.fill) element.fill = this.fill.clone();
    if (this.stroke) element.stroke = this.stroke.clone();
    element.isLocked = this.isLocked;
    element.isVisible = this.isVisible;
    element.guidesDisplay = this.guidesDisplay;
    return element;
  }

  hasInstance(instance: Instance) {
    return (
      instance === this.base ||
      instance === this.transform ||
      instance === this.fill ||
      instance === this.stroke ||
      this.modifiers.includes(instance)
    );
  }

  /**
   * Returns all instances including the standard instances and modifiers.
   */
  allInstances() {
    const instances = this.standardInstances();
    instances.push(...this.modifiers);
    return instances;
  }

  /**
   * Returns the standard instances (everything before the modifiers array)
   * inside this element in the order they will be evaluated. The ordering is
   * manually synchronized for now since we don't expect this will change often.
   */
  standardInstances() {
    const instances = [this.base];
    if (this.transform) instances.push(this.transform);
    if (this.fill) instances.push(this.fill);
    if (this.stroke) instances.push(this.stroke);
    return instances;
  }

  allExpressions() {
    return this.allInstances().flatMap((instance) => instance.allExpressions());
  }

  allDescendants() {
    const descendants: Element[] = [];
    const recurse = (element: Element) => {
      descendants.push(element);
      element.children.forEach(recurse);
    };
    recurse(this);
    return descendants;
  }

  removeModifier(modifier: Instance) {
    const index = this.modifiers.indexOf(modifier);
    if (index >= 0) {
      this.modifiers.splice(index, 1);
    } else if (modifier.isEnabled) {
      // For transform, fill, stroke
      modifier.isEnabled = false;
    }
  }

  hasEnabledModifier() {
    return this.modifiers.some((modifier) => modifier.isEnabled);
  }

  /** Checks the modifier definition to add it to the element stroke, fill,
   * transform, or modifiers */
  pasteModifier(modifier: Instance) {
    if (modifier.definition === StrokeDefinition) {
      this.stroke = modifier;
    } else if (modifier.definition === FillDefinition) {
      this.fill = modifier;
    } else if (modifier.definition === TransformDefinition) {
      this.transform = modifier;
    } else {
      this.modifiers.push(modifier);
    }
  }

  isPassThrough() {
    return this.base.definition.isPassThrough;
  }

  isPositionLocked(): boolean {
    const { transform } = this;
    const { definition, args } = this.base;
    if (definition.isPassThrough) {
      for (let parameter of definition.allParameters()) {
        if (parameter.interface?.isPositionTransformable) {
          // We can't pass through to parameters with expressions.
          if (args[parameter.name]?.isComputed()) return true;
        }
      }
    } else {
      if (transform?.args.position?.isComputed()) return true;
    }
    return false;
  }
  isRotationLocked(): boolean {
    const { transform } = this;
    const { definition, args } = this.base;
    if (definition.isPassThrough) {
      for (let parameter of definition.allParameters()) {
        if (parameter.interface?.isRotationTransformable) {
          // We can't pass through to parameters with expressions.
          if (args[parameter.name]?.isComputed()) return true;
        }
      }
    } else {
      if (transform?.args.rotation?.isComputed()) return true;
    }
    return false;
  }
  isScaleLocked(): boolean {
    const { transform } = this;
    const { definition, args } = this.base;
    if (definition.isPassThrough) {
      for (let parameter of definition.allParameters()) {
        if (parameter.interface?.isScaleTransformable) {
          // We can't pass through to parameters with expressions.
          if (args[parameter.name]?.isComputed()) return true;
        }
      }
    } else {
      if (transform?.args.scale?.isComputed()) return true;
    }
    return false;
  }
  isSkewLocked(): boolean {
    const { transform } = this;
    const { definition, args } = this.base;
    if (definition.isPassThrough) {
      for (let parameter of definition.allParameters()) {
        if (parameter.interface?.isSkewTransformable) {
          // We can't pass through to parameters with expressions.
          if (args[parameter.name]?.isComputed()) return true;
        }
      }
    } else {
      if (transform?.args.skew?.isComputed()) return true;
    }
    return false;
  }
  isAnyTransformLocked(): boolean {
    return (
      this.isPositionLocked() ||
      this.isRotationLocked() ||
      this.isScaleLocked() ||
      this.isSkewLocked()
    );
  }

  isUniformScale() {
    const scaleArg = this.transform?.args.scale;
    if (scaleArg) {
      // If the transform has a scale arg already, we use it to determine if the
      // transform is uniform scale. Scalars imply uniform scale, Vecs
      // non-uniform.
      return isNumber(scaleArg.literalValue());
    }

    // The default transform has a uniform scale.
    return true;
  }
}
registerClass("Element", Element);
