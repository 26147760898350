import m from "mithril";
import {
  AffineMatrix,
  Anchor,
  BoundingBox,
  CubicSegment,
  LineSegment,
  MINIMUM_TOLERANCE,
  Path,
  scaleFactorForUnitConversion,
  Vec,
} from "../../geom";
import { atan2 } from "../../geom/env/trig";
import { pathToCanvasPath } from "../../geom/io/canvas";
import { assert, pairs } from "../../geom/util";
import { globalState } from "../../global-state";
import { LiteralNumber } from "../../model/parse-literal";
import { SelectableNode } from "../../model/selectable";
import { Selection } from "../../model/selection";
import {
  Alignment,
  alignNodes,
  scaleSelectionTransformBox,
  transformBoxForSelection,
} from "../../model/transform-utils";
import { EditableText } from "../../shared/editable-text";
import { IconButton } from "../../shared/icon";
import { SnappingPoint } from "../snapping";
import styleConstants from "../style-constants";
import { toastState } from "../toast-message";
import {
  startSelectionTransformBoxDrag,
  startSelectionTransformBoxRotateDrag,
} from "../tool/selection-drag";
import { POINTER_EVENT_BUTTONS_LEFT } from "../util";
import { CanvasInterfaceElement } from "./canvas-interface-element";
import { TransformBoxDimension, TransformBoxLabel } from "./transform-box";

const TRANSFORM_BOX_PADDING_PX = 4 * 2 + 2;
const TRANSFORM_BOX_HANDLE_SPACING_PX = 8;
const TRANSFORM_BOX_HANDLE_HIT_RADIUS_PX = 2;
const TRANSFORM_BOX_HANDLE_SIZE_PX = 8;

export class TransformBoxUI implements CanvasInterfaceElement {
  private selection: Selection<SelectableNode>;

  private isImmutable: boolean;
  private isPositionLocked: boolean;
  private isRotationLocked: boolean;
  private isScaleLocked: boolean;

  // Group data that might not exist later so that we only need to check once to
  // see that the whole structure is defined, rather than each property.
  private boxData?: {
    boundingBox: BoundingBox;
    boundingBoxTransform: AffineMatrix;

    worldBoxWidth: number;
    worldBoxHeight: number;

    viewBoxPath: Path;
    viewBoxPathSegmentNormals: Vec[];
    paddedViewBoxPathSegments: (LineSegment | CubicSegment)[];
  };

  children: CanvasInterfaceElement[] = [];

  constructor(selection: Selection<SelectableNode>, viewMatrix: AffineMatrix) {
    this.selection = selection;

    this.isImmutable = selection.isImmutable();
    this.isPositionLocked = selection.isPositionLocked();
    this.isScaleLocked =
      this.isPositionLocked || selection.isScaleLocked() || selection.isSkewLocked();
    this.isRotationLocked =
      (this.isPositionLocked && selection.isSingle()) || selection.isRotationLocked();

    const { boundingBox, boundingBoxTransform } = transformBoxForSelection(selection);
    if (!boundingBox || !boundingBoxTransform) return;
    if (boundingBox.min.equals(boundingBox.max)) return;
    if (boundingBoxTransform.determinant() === 0) return;

    const boundingBoxPath = Path.fromBoundingBox(boundingBox);
    if (boundingBoxTransform.isMirror()) {
      boundingBoxPath.reverse();
    }

    const worldBoxPath = boundingBoxPath.clone().affineTransform(boundingBoxTransform);
    const worldBoxWidth = worldBoxPath.anchors[0].position.distance(
      worldBoxPath.anchors[1].position
    );
    const worldBoxHeight = worldBoxPath.anchors[1].position.distance(
      worldBoxPath.anchors[2].position
    );

    const viewBoxPath = boundingBoxPath
      .clone()
      .affineTransform(boundingBoxTransform)
      .affineTransform(viewMatrix);
    const viewBoxPathSegments = pairs(viewBoxPath.anchors, true);

    const viewBoxPathSegmentNormals = viewBoxPathSegments.map(([a1, a2]) =>
      a2.position.clone().sub(a1.position).normalize()
    );
    viewBoxPathSegmentNormals.unshift(viewBoxPathSegmentNormals.pop()!);
    const viewBoxPathCornerNormals = viewBoxPathSegmentNormals.map((normal, i) => [
      viewBoxPathSegmentNormals[(i + 3) % 4],
      normal,
    ]);

    // If the transform box has zero width or height we need to extrapolate the
    // normal from the previous segment (it will always be +90°)
    viewBoxPathCornerNormals.forEach(([n1, n2], i) => {
      if (n2.isZero()) n2.copy(n1).rotate90();
    });

    const viewBoxPathCornerCombinedNormals = viewBoxPathCornerNormals.map(([n1, n2]) =>
      n1.clone().add(n2).normalize()
    );

    // Expand the bounding box path with some padding
    const paddedViewBoxPath = viewBoxPath.clone();
    for (let i = 0; i < 4; ++i) {
      const [n1, n2] = viewBoxPathCornerNormals[i];
      let length = TRANSFORM_BOX_PADDING_PX;
      const cosTheta = -n1.dot(n2);
      if (cosTheta !== 0) {
        // If the normal vectors are not orthogonal we need to expand more so
        // that the path keeps a consistent distance from its starting shape
        length /= Math.sin(Math.acos(cosTheta));
      }
      const offset = n1.clone().add(n2).mulScalar(length);
      paddedViewBoxPath.anchors[i].position.add(offset).round();
    }
    const paddedViewBoxPathSegments = paddedViewBoxPath.segments();

    this.boxData = {
      boundingBox,
      boundingBoxTransform,
      worldBoxWidth,
      worldBoxHeight,
      viewBoxPath,
      viewBoxPathSegmentNormals,
      paddedViewBoxPathSegments,
    };

    SideResizeHandles: {
      for (let i = 0; i < 4; ++i) {
        const { s: a1, e: a2 } = paddedViewBoxPathSegments[i];
        const padding = TRANSFORM_BOX_HANDLE_SIZE_PX + TRANSFORM_BOX_HANDLE_SPACING_PX;

        if (a1.distance(a2) > padding * 2) {
          const p1 = a2.clone().sub(a1).normalize().mulScalar(padding).add(a1);
          const p2 = a1.clone().sub(a2).normalize().mulScalar(padding).add(a2);

          const id = "transform-box-side-" + i;

          this.children.push({
            hitTest: (worldPosition: Vec, pixelsPerUnit: number) => {
              const viewPosition = worldPosition.clone().affineTransform(viewMatrix);
              const lineSegment = new LineSegment(p1, p2);
              const closestPoint = lineSegment.closestPoint(viewPosition);
              if (closestPoint) {
                const distance =
                  (closestPoint.position.distance(viewPosition) -
                    TRANSFORM_BOX_HANDLE_HIT_RADIUS_PX) /
                  pixelsPerUnit;
                return { distance, isHit: distance <= 0 };
              }
              return;
            },
            onPointerDown: (event: PointerEvent) => {
              if (this.isImmutable) {
                toastState.showBasic({
                  type: "error",
                  message: "Can't resize because a selected shape is immutable.",
                });
                return;
              }
              if (this.isScaleLocked) {
                toastState.showBasic({
                  type: "error",
                  message: "Can't resize because a formula is being used in the shape's Transform.",
                  nextStep: "Try changing the scale directly in the Inspector.",
                });
                return;
              }
              const originPosition = boundingBoxPath.anchors[(i + 2) % 4].position
                .clone()
                .mix(boundingBoxPath.anchors[(i + 3) % 4].position, 0.5);
              const handlePosition = boundingBoxPath.anchors[i].position
                .clone()
                .mix(boundingBoxPath.anchors[(i + 1) % 4].position, 0.5);
              if (originPosition.distance(handlePosition) > MINIMUM_TOLERANCE) {
                startSelectionTransformBoxDrag(
                  event,
                  this.selection,
                  boundingBoxTransform,
                  originPosition,
                  handlePosition,
                  true,
                  this.isPositionLocked
                );
              }
            },
            cursor: () => {
              if (this.isScaleLocked) return "select-locked";
              const frame = cursorFrameIndexForNormal(viewBoxPathSegmentNormals[i], 12, 0, 180);
              return `resize-arrows-${frame}`;
            },
            renderCanvas: (viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) => {
              // Skip rendering if we're not hovered or grabbed.
              const interfaceElem =
                globalState.downInterfaceElement ?? globalState.hoveredInterfaceElement;
              if (interfaceElem?.id !== id) return null;

              ctx.lineCap = "square";
              ctx.lineJoin = "miter";

              // Back
              ctx.strokeStyle = styleConstants.white;
              ctx.lineWidth = 4;
              ctx.beginPath();
              ctx.moveTo(p1.x, p1.y);
              ctx.lineTo(p2.x, p2.y);
              ctx.stroke();

              // Front
              ctx.strokeStyle = styleConstants.blue63;
              ctx.lineWidth = 2;
              ctx.beginPath();
              ctx.moveTo(p1.x, p1.y);
              ctx.lineTo(p2.x, p2.y);
              ctx.stroke();
            },
            snappingPoints: (isSource: boolean) => {
              if (!isSource) return undefined;
              const worldPosition = worldBoxPath.anchors[i].position
                .clone()
                .mix(worldBoxPath.anchors[(i + 1) & 3].position, 0.5);
              const snappingPoint = new SnappingPoint(worldPosition, "Transform Box", "Box");
              return [snappingPoint];
            },
            id,
          });
        }
      }
    }

    RotationHandles: {
      for (let i = 0; i < 4; ++i) {
        const { position } = paddedViewBoxPath.anchors[i];
        const center = viewBoxPathCornerCombinedNormals[i]
          .clone()
          .mulScalar(TRANSFORM_BOX_HANDLE_HIT_RADIUS_PX * 2)
          .add(position);
        const boundingBoxHandlePosition = worldBoxPath.anchors[i].position;
        this.children.push({
          hitTest(worldPosition: Vec, pixelsPerUnit: number) {
            const viewPosition = worldPosition.clone().affineTransform(viewMatrix);
            const distance =
              (viewPosition.distance(center) - TRANSFORM_BOX_HANDLE_HIT_RADIUS_PX) / pixelsPerUnit;
            return { distance, isHit: distance <= 0 };
          },
          onPointerDown: (event: PointerEvent) => {
            if (this.isImmutable) {
              toastState.showBasic({
                type: "error",
                message: "Can't rotate because a selected shape is immutable.",
              });
              return;
            }
            if (this.isRotationLocked) {
              toastState.showBasic({
                type: "error",
                message: "Can't rotate because a formula is being used in the shape's Transform.",
                nextStep: "Try changing the rotation directly in the Inspector.",
              });
              return;
            }
            startSelectionTransformBoxRotateDrag(event, this.selection, boundingBoxHandlePosition);
          },
          cursor: () => {
            if (this.isRotationLocked) return "select-locked";
            return `rotate-arrows-${cursorFrameIndexForNormal(
              viewBoxPathCornerCombinedNormals[i],
              24,
              225,
              360
            )}`;
          },
          snappingPoints: (isSource: boolean) => {
            if (!isSource) return undefined;
            const snappingPoint = new SnappingPoint(
              boundingBoxHandlePosition,
              "Transform Box",
              "Box"
            );
            return [snappingPoint];
          },
        });
      }
    }

    CornerResizeHandles: {
      for (let i = 0; i < 4; ++i) {
        const p2 = paddedViewBoxPath.anchors[i].position;
        const [p1, p3] = viewBoxPathCornerNormals[i].map((n) =>
          n.clone().mulScalar(-TRANSFORM_BOX_HANDLE_SIZE_PX).add(p2)
        );
        const path = new Path([new Anchor(p1), new Anchor(p2), new Anchor(p3)]);
        this.children.push({
          hitTest: (worldPosition: Vec, pixelsPerUnit: number) => {
            const viewPosition = worldPosition.clone().affineTransform(viewMatrix);
            const position = path.closestPoint(viewPosition)?.position;
            if (!position) return undefined;
            const distance =
              (viewPosition.distance(position) - TRANSFORM_BOX_HANDLE_HIT_RADIUS_PX) /
              pixelsPerUnit;
            return { distance, isHit: distance <= 0 };
          },
          onPointerDown: (event: PointerEvent) => {
            if (this.isImmutable) {
              toastState.showBasic({
                type: "error",
                message: "Can't resize because a selected shape is immutable.",
              });
              return;
            }
            if (this.isScaleLocked) {
              toastState.showBasic({
                type: "error",
                message: "Can't resize because a formula is being used in the shape's Transform.",
                nextStep: "Try changing the scale directly in the Inspector.",
              });
              return;
            }
            const boundingBoxOriginPosition = boundingBoxPath.anchors[(i + 2) % 4].position;
            const boundingBoxHandlePosition = boundingBoxPath.anchors[i].position;
            startSelectionTransformBoxDrag(
              event,
              this.selection,
              boundingBoxTransform,
              boundingBoxOriginPosition,
              boundingBoxHandlePosition,
              false,
              this.isPositionLocked
            );
          },
          cursor: () => {
            if (this.isScaleLocked) return "select-locked";
            const frame = cursorFrameIndexForNormal(
              viewBoxPathCornerCombinedNormals[i],
              12,
              0,
              180
            );
            return `resize-arrows-${frame}`;
          },
          renderCanvas: (viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) => {
            ctx.lineCap = "square";
            ctx.lineJoin = "miter";

            // Back
            ctx.strokeStyle = styleConstants.white;
            ctx.lineWidth = 4;
            ctx.beginPath();
            pathToCanvasPath(path, ctx);
            ctx.stroke();

            // Front
            ctx.strokeStyle = styleConstants.blue63;
            ctx.lineWidth = 2;
            ctx.beginPath();
            pathToCanvasPath(path, ctx);
            ctx.stroke();
          },
          snappingPoints: (isSource: boolean) => {
            if (!isSource) return undefined;
            const snappingPoint = new SnappingPoint(
              worldBoxPath.anchors[i].position,
              "Transform Box",
              "Box"
            );
            return [snappingPoint];
          },
        });
      }
    }
  }

  isValid() {
    return this.selection.items.every((item) =>
      globalState.project.selectableExistsAndIsValid(item)
    );
  }

  cursor() {
    if (this.isPositionLocked) return "select-locked";
    if (globalState.isAltDown) return "duplicate";
    return "select";
  }

  selectables() {
    return this.selection.items;
  }

  renderHtml(viewMatrix: AffineMatrix) {
    const focusedComponent = globalState.project.focusedComponent();
    assert(focusedComponent, "Should only be used when a component is focused");

    if (!this.boxData) return;

    let mTools: m.Children;
    Tools: {
      if (!this.selection.isSingle() && !globalState.isMidGesture()) {
        const alignAction = (alignment: Alignment) => {
          return (event: PointerEvent) => {
            if (event.buttons !== POINTER_EVENT_BUTTONS_LEFT) return;
            event.stopPropagation();
            const nodes = this.selection.allNodes().mutables().toNodes();
            alignNodes(nodes, alignment);
          };
        };

        const { s: t1, e: t2 } = this.boxData.paddedViewBoxPathSegments[0];
        const { s: l1, e: l2 } = this.boxData.paddedViewBoxPathSegments[3];
        const x = Math.floor(l1.x);
        const y = Math.floor(t1.y);
        const width = Math.floor(t2.x - t1.x);
        const height = Math.floor(l1.y - l2.y);
        const topStyle = `left:${x}px;top:${y}px;width:${width}px`;
        const leftStyle = `left:${x}px;top:${y}px;height:${height}px`;

        mTools = [
          m(".transform-box-tools", { style: topStyle }, [
            m(".align-tools.horizontal", [
              m(IconButton, { icon: "align_left", onpointerdown: alignAction("left") }),
              m(IconButton, { icon: "align_center", onpointerdown: alignAction("center") }),
              m(IconButton, { icon: "align_right", onpointerdown: alignAction("right") }),
            ]),
          ]),
          m(".transform-box-tools.left", { style: leftStyle }, [
            m(".align-tools.vertical", [
              m(IconButton, { icon: "align_top", onpointerdown: alignAction("top") }),
              m(IconButton, { icon: "align_middle", onpointerdown: alignAction("middle") }),
              m(IconButton, { icon: "align_bottom", onpointerdown: alignAction("bottom") }),
            ]),
          ]),
        ];
      }
    }

    let mLabelAndDimensions: m.Children;
    LabelAndDimensions: {
      // Find the best (bottom facing) side for placement of the dimensions text
      const boxBottomSegmentIndex =
        this.boxData.viewBoxPathSegmentNormals[0].y + 0.001 >=
        this.boxData.viewBoxPathSegmentNormals[2].y
          ? 0
          : 2;
      const boxRightSegmentIndex =
        this.boxData.viewBoxPathSegmentNormals[1].y + 0.001 >=
        this.boxData.viewBoxPathSegmentNormals[3].y
          ? 1
          : 3;

      const { s: r1, e: r2 } = this.boxData.paddedViewBoxPathSegments[boxRightSegmentIndex];
      const boxRightCenter = r1.clone().add(r2).mulScalar(0.5);
      const boxRightNormal = r1.clone().sub(r2).rotate90().normalize();

      const { s: b1, e: b2 } = this.boxData.paddedViewBoxPathSegments[boxBottomSegmentIndex];
      const boxBottomCenter = b1.clone().add(b2).mulScalar(0.5);
      const boxBottomNormal = b1.clone().sub(b2).rotate90().normalize();

      const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
      const { fractionDigits } = viewport.precisionInfo();

      let mLabel: m.Children;
      if (this.selection.isSingle()) {
        const { s: t1, e: t2 } =
          this.boxData.paddedViewBoxPathSegments[
            ((boxBottomNormal.y > boxRightNormal.y ? boxBottomSegmentIndex : boxRightSegmentIndex) +
              2) %
              4
          ];
        const boxTopCenter = t1.clone().add(t2).mulScalar(0.5);
        const boxTopNormal = t1.clone().sub(t2).rotate90().normalize();

        const element = this.selection.items[0].node.source;
        mLabel = m(
          TransformBoxLabel,
          {
            position: boxTopCenter,
            normal: boxTopNormal,
            rotation: 180,
          },
          m(
            EditableText,
            {
              value: element.name,
              singleClick: true,
              onchange: (name: string) => {
                globalState.project.renameElement(element, name);
              },
            },
            element.name
          )
        );
      }

      const { boundingBox, boundingBoxTransform, worldBoxWidth, worldBoxHeight } = this.boxData;

      const onChangeWidth = (literal: LiteralNumber) => {
        if (!isFinite(literal.value)) return;
        let value = literal.value;
        if (literal.unit) {
          value *= scaleFactorForUnitConversion(literal.unit, globalState.project.settings.units);
        }
        const ratio = value / worldBoxWidth;
        const scale = this.selection.isUniformScale() ? new Vec(ratio) : new Vec(ratio, 1);
        scaleSelectionTransformBox(this.selection, boundingBox, boundingBoxTransform, scale);
      };
      const onChangeHeight = (literal: LiteralNumber) => {
        if (!isFinite(literal.value)) return;
        let value = literal.value;
        if (literal.unit) {
          value *= scaleFactorForUnitConversion(literal.unit, globalState.project.settings.units);
        }
        const ratio = value / worldBoxHeight;
        const scale = this.selection.isUniformScale() ? new Vec(ratio) : new Vec(1, ratio);
        scaleSelectionTransformBox(this.selection, boundingBox, boundingBoxTransform, scale);
      };

      mLabelAndDimensions = [
        mLabel,
        m(
          TransformBoxLabel,
          {
            position: boxBottomCenter,
            normal: boxBottomNormal,
          },
          m(TransformBoxDimension, {
            name: "width",
            value: worldBoxWidth,
            fractionDigits,
            isLocked: this.isScaleLocked,
            onChange: onChangeWidth,
          })
        ),
        m(
          TransformBoxLabel,
          {
            position: boxRightCenter,
            normal: boxRightNormal,
          },
          m(TransformBoxDimension, {
            name: "height",
            value: worldBoxHeight,
            fractionDigits,
            isLocked: this.isScaleLocked,
            onChange: onChangeHeight,
          })
        ),
      ];
    }

    return [mLabelAndDimensions, mTools];
  }
}

const cursorFrameIndexForNormal = (
  normal: Vec,
  frameCount: number,
  angleStart: number,
  angleSpan: number
) => {
  const angle = angleStart - atan2(normal.y, normal.x);
  return Math.round(angle * (frameCount / angleSpan) + frameCount) % frameCount;
};
