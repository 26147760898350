/**
 * The conversion factor from degrees to radians.
 *
 * See `radiansFromDegrees()`.
 */
export const RADIANS_PER_DEGREE = Math.PI / 180;

/**
 * The conversion factor from radians to degrees.
 *
 * See `degreesFromRadians()`.
 */
export const DEGREES_PER_RADIAN = 180 / Math.PI;

// TODO: These should be made more specific for position, angle, dot product,
// etc.
export const DEFAULT_TOLERANCE = 1e-3;
export const MINIMUM_TOLERANCE = 1e-6;

/**
 * The minimum distance that matters when comparing the position of two points.
 *
 * @internal
 */
export const GEOMETRIC_TOLERANCE = 1e-7;

/**
 * The ratio of a circle's circumference to its diameter. Pi (**_π_**) radians
 * equates to a 180° rotation.
 */
export const PI = Math.PI;

/**
 * Defined to be `PI * 2`. In radians, this represents a full 360° rotation.
 */
export const TWO_PI = PI * 2;

/**
 * Equal to `TWO_PI`. Tau (**_τ_**) is the ratio of a circle's circumference to its
 * radius.
 */
export const TAU = TWO_PI;

/**
 * The smallest relative difference we care about when comparing two values.
 * This is a fallback and usually it's better to use an epsilon for your
 * specific context.
 *
 * @internal
 */
export const DEFAULT_EPSILON = Number.EPSILON * 2;

/**
 * The smallest difference used to determine if two path times are equal. This
 * value was determined by hand tuning segment intersection results until no
 * self intersections were found.
 *
 * @internal
 */
export const PATH_TIME_EPSILON = 1e-7;

export const APPROXIMATE_EPSILON = 1e-7;

export const DEFAULT_PRECISION = 6;
