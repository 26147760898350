import m from "mithril";

import { globalState } from "../global-state";
import { Icon20 } from "../shared/icon";
import { CreatedPopup, createPopup } from "../shared/popup";
import { classNames, domForVnode } from "../shared/util";
import { Thumbnail } from "./thumbnail";
import { showTouchScreenWarningToast } from "./toast-message";

let menuActive = false;
let createdPopup: CreatedPopup | undefined;

export const TopMenuSmall: m.Component<{}> = {
  view: function (vnode) {
    const onclick = () => {
      const spawnFrom = domForVnode(vnode);
      createdPopup = createPopup({
        spawnFrom,
        view: () => m(TopMenuSmallContents),
        className: "top-menu-small-popup",
        placement: "bottom-start",
        onclose: () => {
          menuActive = false;
        },
      });
      menuActive = true;
    };

    const className = classNames({ active: menuActive });

    return m("button.menu-button", { className, onclick }, [
      m(".menu-button-icon", m(Icon20, { icon: "hamburger" })),
      m(".menu-button-label", globalState.storage.getProjectName()),
    ]);
  },
};

const TopMenuSmallContents: m.Component<{}> = {
  view: function (vnode) {
    const gotoDoc = () => {
      globalState.project.focusItem(globalState.project.documentation);
      createdPopup?.close();
    };
    const focusedDocumentation = globalState.project.focusedDocumentation();
    const focusedComponent = globalState.project.focusedComponent();
    const docClassName = classNames({ active: Boolean(focusedDocumentation) });
    return [
      m("a.menu-small-item", { href: "/", target: "_top" }, [
        m(".menu-small-item-icon", m(Icon20, { icon: "cuttle_logo" })),
        m(".menu-small-item-label", "About Cuttle"),
      ]),
      m(".menu-small-separator"),
      m(".menu-small-item", { className: docClassName, onclick: gotoDoc }, [
        m(".menu-small-item-icon", m(Icon20, { icon: "doc" })),
        m(".menu-small-item-label", globalState.project.documentation.name),
      ]),
      m(".menu-small-separator"),
      ...globalState.project.components.map((component) => {
        const geometry = globalState.tracesByDefinition.get(component)?.result;
        const gotoComponent = () => {
          globalState.project.focusItem(component);
          globalState.zoomViewportToFitAll();
          // Show a warning the first time we switch to a component on a touch device.
          showTouchScreenWarningToast();
          createdPopup?.close();
        };
        const className = classNames({ active: focusedComponent === component });
        return m(".menu-small-item", { className, onclick: gotoComponent }, [
          m(
            ".menu-small-item-icon",
            m(Thumbnail, { graphic: geometry, width: 20, height: 20, padding: 2 })
          ),
          m(".menu-small-item-label", component.name),
        ]);
      }),
    ];
  },
};
