import m from "mithril";
import { Select } from "./basic/select";
import { Unit, units } from "../geom/io/units";

interface UnitSelectAttrs {
  value: Unit;
  onChange: (unit: Unit) => void;
}

export const UnitSelect: m.Component<UnitSelectAttrs> = {
  view: ({ attrs: { value, onChange } }) => {
    return m(Select, {
      options: units,
      className: "unit-select",
      selected: value,
      onchange: (newValue) => {
        onChange(newValue as Unit);
      },
    });
  },
};
