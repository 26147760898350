import { CodeComponent, Component } from "./model/component";

export interface BuiltinExampleShape {
  type: "builtin";

  definition: Component | CodeComponent;

  keywords?: string[]; // Additional keywords to include
}
export interface SnapshotExampleShape {
  type: "guts" | "component";

  name: string;
  project: string;

  // The name of the component to use. Specify this if `name` must be different from the component name.
  componentName?: string;

  // The component to use when rendering the thumbnail SVG
  thumbnail?: string;

  // Any additional keywords to include
  keywords?: string[];

  isAutoScale?: boolean;
}
export interface EmojiExampleShape {
  type: "emoji";

  name: string;

  /* No keywords array. Querying emoji shapes happens via shapeIndex.search. */

  emoji: string;
  unicodeId: string;
}
export interface CuttleSymbolExampleShape {
  type: "cuttle-symbol";

  name: string;
  keywords?: string[];

  path: string;
}
export type ExampleShape =
  | BuiltinExampleShape
  | SnapshotExampleShape
  | EmojiExampleShape
  | CuttleSymbolExampleShape;
export type ExampleShapeDisplay = "one-up" | "one-up-compact" | "two-up" | "four-up";

export interface ExampleCategory {
  category: string;

  display: ExampleShapeDisplay;
  displayMore?: ExampleShapeDisplay;

  featuredShapes: string[];
  shapes: ExampleShape[];
}

export const nameForExampleShape = (shape: ExampleShape) => {
  if (shape.type === "builtin") return shape.definition.name;
  return shape.name;
};

/* Matches the JSON created in scripts/extract-emoji-font */
export interface EmojiData {
  name: string;
  unified: string;
  unicode: string;
  keywords: string;
}

export const makeEmojiExampleShape = (emoji: EmojiData): EmojiExampleShape => {
  const { name, unified, unicode } = emoji;
  return {
    type: "emoji",
    name,
    emoji: unicode,
    unicodeId: unified,
  };
};
