import m from "mithril";

import { globalState } from "./global-state";
import { handleAcceleratorKeyDownEvent } from "./shared/keyboard";
import { hasActiveElementFocus, isKeyboardEventForPlainTextField } from "./shared/util";

export const registerKeyboardEventHandlers = () => {
  /**
   * Keyboard State Tracking
   *
   * These event listeners keep track of which keys are pressed. We use this state
   * in other parts of the application, for example to determine which cursor to
   * show. We use capture here to make sure these events fire event if propagation
   * is stopped somewhere in the bubble chain.
   */
  window.addEventListener(
    "keydown",
    (event: KeyboardEvent) => {
      if (event.key === "Alt") globalState.isAltDown = true;
      else if (event.key === "Shift") globalState.isShiftDown = true;
      else if (event.key === "Control") globalState.isControlDown = true;
      else if (event.key === "Meta") globalState.isMetaDown = true;
      else if (event.key === " ") {
        // Ignore spacebar presses if a modifier key is down. In certain cases
        // this can cause the canvas to get stuck panning, such as pressing
        // ctrl-cmd-space to bring up the MacOS character palette.
        if (event.ctrlKey || event.altKey || event.metaKey) return;
        globalState.isSpaceDown = true;
      }
    },
    true
  );
  window.addEventListener(
    "keyup",
    (event: KeyboardEvent) => {
      if (event.key === "Alt") globalState.isAltDown = false;
      else if (event.key === "Shift") globalState.isShiftDown = false;
      else if (event.key === "Control") globalState.isControlDown = false;
      else if (event.key === "Meta") globalState.isMetaDown = false;
      else if (event.key === " ") globalState.isSpaceDown = false;
    },
    true
  );

  /**
   * The default keyboard behavior should be delegated here. Specific keyboard
   * contexts (like expression editors, text fields) can override this behavior
   * by stopping propagation on the keyboard events they handle.
   */
  window.addEventListener("keydown", (event: KeyboardEvent) => {
    if (!isKeyboardEventForPlainTextField(event)) {
      // CodeMirror instances won't bubble events that they handle, so we can
      // handle keyboard accelerators (like Cmd-Z) here.
      handleAcceleratorKeyDownEvent(event);
    }

    if (!hasActiveElementFocus()) {
      // Forward keyboard events to the active tool
      if (globalState.project.hasFocusedComponent()) {
        globalState.ensureEnabledActiveTool().onKeyDown?.(event);
      }
    }

    // Kick off a mithril redraw on every keydown
    m.redraw();
  });

  window.addEventListener("keyup", (event: KeyboardEvent) => {
    // Also redraw on keyup in order to update the cursor when a modifier key
    // was pressed.
    m.redraw();
  });
};
