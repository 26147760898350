import { globalState } from "../global-state";
import { isArray, isNumber, isObject, isString } from "../shared/util";
import { uuid } from "../shared/uuid-v4";
import { alertAndThrow, isDevelopmentServer, isStagingServer } from "../util";
import { upgradeSnapshotUntyped } from "./project-snapshot-upgrade-untyped";

export const currentVersion = 41;

export interface UpgradeSnapshotOptions {
  /**
   * This projectId will be assigned to components and modifiers from project
   * versions 21 and older.
   */
  defaultProjectId: string;
}

export interface ParsedSnapshot {
  app: "Cuttle";
  type: string;
  version: number;
  payload: Record<string, unknown>;
}

const isParsed = (parsed: unknown): parsed is ParsedSnapshot => {
  if (!isObject(parsed)) return false;
  if (parsed.app !== "Cuttle") return false;
  if (!isString(parsed.type)) return false;
  if (!isNumber(parsed.version)) return false;
  if (!isObject(parsed.payload)) return false;
  return true;
};

export const upgradeSnapshot = (parsed: unknown, options?: UpgradeSnapshotOptions) => {
  if (!isParsed(parsed)) return null;

  // Early out if no ungrading is required.
  if (parsed.version === currentVersion) return parsed;

  // Prevent upgrading published projects accidentally when working from a
  // localhost or staging server.
  if (isStagingServer() && globalState.storage.hasWritePermission()) {
    const shouldUpgrade = window.confirm(
      `You are on staging. Do you really want to upgrade from version ${parsed.version} to ${currentVersion}?`
    );
    if (!shouldUpgrade) {
      alertAndThrow("Close this window to prevent overwriting the project.");
    }
  }

  // Run our previous upgrades that were less strictly typed.
  upgradeSnapshotUntyped(parsed, options);

  if (parsed.version === 34) {
    // We're making Connected Text a built-in component so that it can be
    // upgraded more easily. If the project has an immutable Connected Text
    // component, remove it and point all references to the built-in.

    // Find an immutable component named "Connected Text". We need to search the
    // entire snapshot since we don't know which instance will be the one with
    // "@id".
    let connectedTextId: unknown;
    walk(parsed.payload, (o) => {
      // Just return if we already found it.
      if (connectedTextId !== undefined) return;

      if (o["@class"] === "CodeComponent" && o.isImmutable && o.name === "Connected Text") {
        connectedTextId = o["@id"];
      }
    });

    if (connectedTextId !== undefined) {
      const isConnectedText = (o: unknown): o is Record<string, unknown> => {
        if (isObject(o)) {
          if (o["@ref"] === connectedTextId) return true;
          if (o["@id"] === connectedTextId) return true;
        }
        return false;
      };

      // Remove the Connected Text component from the project.
      walk(parsed.payload, (o) => {
        if (
          (o["@class"] === "Project" || o["@class"] === "PortableProjectData") &&
          isArray(o.components)
        ) {
          const index = o.components.findIndex(isConnectedText);
          if (index >= 0) {
            // OK to remove the Connected Text from the project here since we
            // know it doesn't contain references to anything else in the
            // project. To remove a normal project component, we would need to
            // find ids that are @ref'd in other parts of the JSOG.
            o.components.splice(index, 1);
          }
        }
      });

      // Convert any remaining instances to builtins.
      walk(parsed.payload, (o) => {
        if (o["@class"] === "Instance" && isConnectedText(o.definition)) {
          if (isObject(o.args) && o.args.welding === undefined && isObject(o.args.union)) {
            // Some of the older versions of Connected Text used a "union"
            // boolean parameter instead of the "welding" select. Upgrade these
            // to the new parameter.
            if (o.args.union.jsCode === "false") {
              o.args.welding = { "@class": "Expression", jsCode: `"Weld nothing"` };
            }
            delete o.args.union;
          }
          o.definition = { "@builtin": "ConnectedTextDefinition" };
        }
      });

      if (isDevelopmentServer()) {
        // Confirm that we have replaced all references to Connected Text.
        walk(parsed.payload, (o) => {
          if (isConnectedText(o)) {
            alertAndThrow("Found a Connected Text!");
          }
        });
      }
    }

    parsed.version = 35;
  }

  if (parsed.version === 35) {
    // We're making Warped Text a built-in modifier so that it can be upgraded
    // more easily. If the project has an immutable Warped Text modifier, remove
    // it and point all references to the built-in.

    // Find an immutable modifier named "Warped Text". We need to search the
    // entire snapshot since we don't know which instance will be the one with
    // "@id".
    let warpedTextId: unknown;
    walk(parsed.payload, (o) => {
      // Just return if we already found it.
      if (warpedTextId !== undefined) return;

      if (o["@class"] === "Modifier" && o.isImmutable && o.name === "Warped Text") {
        warpedTextId = o["@id"];
      }
    });

    if (warpedTextId !== undefined) {
      const isWarpedText = (o: unknown): o is Record<string, unknown> => {
        if (isObject(o)) {
          if (o["@ref"] === warpedTextId) return true;
          if (o["@id"] === warpedTextId) return true;
        }
        return false;
      };

      // Remove the Warped Text modifier from the project.
      walk(parsed.payload, (o) => {
        if (
          (o["@class"] === "Project" || o["@class"] === "PortableProjectData") &&
          isArray(o.modifiers)
        ) {
          const index = o.modifiers.findIndex(isWarpedText);
          if (index >= 0) {
            // OK to remove the Warped Text modifier from the project here since
            // we know it doesn't contain references to anything else in the
            // project. To remove a normal project component, we would need to
            // find ids that are @ref'd in other parts of the JSOG.
            o.modifiers.splice(index, 1);
          }
        }
      });

      // Convert any remaining instances to builtins.
      walk(parsed.payload, (o) => {
        if (o["@class"] === "Instance" && isWarpedText(o.definition)) {
          o.definition = { "@builtin": "WarpedTextDefinition" };
        }
      });

      if (isDevelopmentServer()) {
        // Confirm that we have replaced all references to Warped Text.
        walk(parsed.payload, (o) => {
          if (isWarpedText(o)) {
            alertAndThrow("Found a Warped Text!");
          }
        });
      }
    }

    parsed.version = 36;
  }

  if (parsed.version === 36) {
    // We changed the encoding so that only ModelObjects have an @id property,
    // which is a UUID string. Because we assign the @id to ModelObject's .id
    // property, we need to make sure these are all valid UUIDs. Replace all @id
    // strings with UUIDs for convenience.
    const objectsById = new Map<string, { object: Record<string, unknown>; key: string }[]>();
    const addObject = (object: Record<string, unknown>, key: string, id: string) => {
      let objects = objectsById.get(id);
      if (objects === undefined) {
        objects = [];
        objectsById.set(id, objects);
      }
      objects.push({ object, key });
    };

    // Find all objects with an @id or @ref key.
    const keysToReplace = ["@id", "@ref"];
    walk(parsed.payload, (o) => {
      if (isObject(o)) {
        for (const key of keysToReplace) {
          const id = o[key];
          if (isString(id) && isFinite(+id)) {
            addObject(o, key, id);
          }
        }
      }
    });

    // Replace object IDs with UUIDs.
    for (const [_, objects] of objectsById) {
      // Try to pull the @id from the original .id property to preserve
      // component and modifier ids.
      const original = objects.find((o) => o.key === "@id");
      const id = original?.object.id ?? uuid();
      for (const o of objects) {
        o.object[o.key] = id;
      }
    }

    parsed.version = 37;
  }

  if (parsed.version === 37) {
    // We changed the default stroke join and cap to "round" becuase this is
    // nearly always a better default when designing a cut file. The previous
    // "miter" default produced too many unwanted spikes and artifacts. We must
    // assign the previous default values as args if they haven't already been
    // changed.
    walk(parsed.payload, (o) => {
      if (o["@class"] === "Instance" && isObject(o.definition) && isObject(o.args)) {
        const builtin = o.definition["@builtin"];
        if (builtin === "ExpandDefinition1" || builtin === "ContractDefinition1") {
          if (o.args.join === undefined) {
            o.args.join = { "@class": "Expression", jsCode: `"miter"` };
          }
        }
        if (builtin === "StrokeDefinition" || builtin === "OutlineStrokeDefinition") {
          const isHairline =
            builtin === "StrokeDefinition" &&
            (!isObject(o.args.hairline) || o.args.hairline.jsCode === "true");
          if (!isHairline) {
            if (o.args.join === undefined) {
              o.args.join = { "@class": "Expression", jsCode: `"miter"` };
            }
            if (o.args.cap === undefined) {
              o.args.cap = { "@class": "Expression", jsCode: `"butt"` };
            }
          }
        }
      }
    });

    parsed.version = 38;
  }

  if (parsed.version === 38) {
    // We changed the meaining of `advanceWidth` and replaced it with
    // `advanceX`, which should now be used to accumulate glyph X positions.
    // Some of our templates use `advanceWidth`, but we can't change forks of
    // those templates. Look for trivially copied versions of the modifier from
    // these projects and upgrade uses of `advanceWidth` to `advanceX`.
    const upgrades = [
      ["Modifier", "Split Text", 7682],
      ["Modifier", "Text For Split", 3079],
      ["CodeComponent", "Text With Emoji", 4216],
    ];
    walk(parsed.payload, (o) => {
      for (const [className, name, codeLength] of upgrades) {
        if (o["@class"] === className && o.name === name) {
          if (isObject(o.code) && isString(o.code.jsCode) && o.code.jsCode.length === codeLength) {
            o.code.jsCode = o.code.jsCode.replace(/advanceWidth/g, "advanceX");
          }
        }
      }
    });

    parsed.version = 39;
  }

  if (parsed.version === 39) {
    // We changed the default "align" and "verticalAlign" values in Text
    // components from "baseline" to "center" and "left" to "middle". Add args
    // with with old defaults to instances that didn't have args already.
    walk(parsed.payload, (o) => {
      if (o["@class"] === "Instance" && isObject(o.definition) && isObject(o.args)) {
        const builtin = o.definition["@builtin"];
        if (
          builtin === "TextDefinition" ||
          builtin === "ConnectedTextDefinition" ||
          builtin === "ConnectedTextWithTailsDefinition"
        ) {
          if (o.args.align === undefined) {
            o.args.align = { "@class": "Expression", jsCode: `"left"` };
          }
          if (o.args.verticalAlign === undefined) {
            o.args.verticalAlign = { "@class": "Expression", jsCode: `"baseline"` };
          }
        }
      }
    });

    parsed.version = 40;
  }

  if (parsed.version === 40) {
    /**
     * Changed TextAlongPathDefinition to support new fit and flip options.
     * The parameter "scaleToFit" becomes "fit" with a new "lines evenly spaced" option.
     * - true → "scale"
     * - false → "none"
     * The parameter "flip" has a new "auto" option.
     * - true → "all"
     * - false → "none"
     */
    walk(parsed.payload, (o) => {
      if (o["@class"] === "Instance" && isObject(o.definition) && isObject(o.args)) {
        const builtin = o.definition["@builtin"];
        if (builtin === "TextAlongPathDefinition") {
          if (isObject(o.args.scaleToFit)) {
            const oldCode = o.args.scaleToFit.jsCode;
            let jsCode;
            if (oldCode === "true") {
              jsCode = `"scale"`;
            } else if (oldCode === "false") {
              jsCode = `"none"`;
            } else {
              jsCode = `Boolean(${oldCode}) ? "scale" : "none"`;
            }
            o.args.fit = { "@class": "Expression", jsCode };
            delete o.args.scaleToFit;
          }
          if (isObject(o.args.flip)) {
            const oldCode = o.args.flip.jsCode;
            let jsCode;
            if (oldCode === "true") {
              jsCode = `"all"`;
            } else if (oldCode === "false") {
              jsCode = `"none"`;
            } else {
              jsCode = `Boolean(${oldCode}) ? "all" : "none"`;
            }
            o.args.flip = { "@class": "Expression", jsCode };
          }
        }
      }
    });

    parsed.version = 41;
  }

  // We should be upgraded to the current version now.
  if (parsed.version !== currentVersion) {
    alertAndThrow(
      `You might be pasting from a newer version of Cuttle. Refresh this page to upgrade to the latest version and try again. (Unable to upgrade snapshot from version ${parsed.version} to ${currentVersion}.)`
    );
  }

  return parsed;
};

const walk = (o: unknown, fn: (o: Record<string, unknown>) => void) => {
  if (isArray(o)) {
    for (let i = 0; i < o.length; ++i) {
      walk(o[i], fn);
    }
  } else if (isObject(o)) {
    fn(o);
    for (let key in o) {
      walk(o[key], fn);
    }
  }
};
