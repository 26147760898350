import { assert, isObject } from "../geom";
import { deepEquals, unescapeHyphen } from "../util";
import { CodeComponent, Component } from "./component";
import { Element } from "./element";
import { Instance } from "./instance";
import { Modifier } from "./modifier";
import { Parameter } from "./parameter";
import { Project } from "./project";
import {
  UpgradeSnapshotOptions,
  currentVersion,
  upgradeSnapshot,
} from "./project-snapshot-upgrade";
import { registerClass } from "./registry";
import { decode, encodeMaterial } from "./serialize";

export class ProjectSnapshot {
  encodedMaterialProject: Record<string, unknown>;
  encodedImmaterialProperties: Record<string, unknown>;

  constructor(
    encodedMaterialProject: Record<string, unknown>,
    encodedImmaterialProperties: Record<string, unknown> = {}
  ) {
    this.encodedMaterialProject = encodedMaterialProject;
    this.encodedImmaterialProperties = encodedImmaterialProperties;
  }

  difference(snapshot: ProjectSnapshot) {
    const isMaterialDifference = !deepEquals(
      this.encodedMaterialProject,
      snapshot.encodedMaterialProject
    );
    if (isMaterialDifference) return "material";

    const isImmaterialDifference = !deepEquals(
      this.encodedImmaterialProperties,
      snapshot.encodedImmaterialProperties
    );
    if (isImmaterialDifference) return "immaterial";

    return "none";
  }

  toJSON() {
    return {
      app: "Cuttle",
      type: "Project",
      version: currentVersion,
      // NOTE: Only save the material subset of the project.
      payload: this.encodedMaterialProject,
    };
  }

  toString() {
    return JSON.stringify(this.toJSON());
  }

  toProject() {
    // Merge material and immaterial properties and decode them together.
    const project = decode({
      ...this.encodedMaterialProject,
      ...this.encodedImmaterialProperties,
    });
    assert(project instanceof Project);

    project.ensureValidFocusedComponent();

    return project;
  }

  static fromProject(project: Project, options?: { bypassReducerCache?: boolean }) {
    const bypassReducerCache = options?.bypassReducerCache;

    const encodedProject = encodeMaterial(project, { bypassReducerCache });
    assert(isObject(encodedProject), "Expected the encoded project to be an object.");

    const immaterialOptions = {
      indirectlyReferenceModelObjects: true,
    };
    const encodedImmaterial: Record<string, unknown> = {};
    for (const key of project.immaterialKeys()) {
      const value: unknown = (project as any)[key];
      encodedImmaterial[key] = encodeMaterial(value, immaterialOptions);
    }

    return new ProjectSnapshot(encodedProject, encodedImmaterial);
  }

  static fromJSON(json: any, options?: UpgradeSnapshotOptions) {
    json = upgradeSnapshot(json, options);
    if (json?.type === "Project") {
      const encoded = json.payload;
      // NOTE: We only save the material subset to JSON, so we can also set
      // `encoded` as `encodedMaterialSubset`.
      return new ProjectSnapshot(encoded);
    }
    return undefined;
  }

  static fromString(s: string, options?: UpgradeSnapshotOptions) {
    try {
      const parsed = JSON.parse(s);
      return ProjectSnapshot.fromJSON(parsed, options);
    } catch (error) {
      console.warn(error);
    }
    console.warn("Failed to parse Project snapshot", s);
    return undefined;
  }
}

export class PortableProjectData {
  elements: Element[] = [];
  instances: Instance[] = [];
  components: (Component | CodeComponent)[] = [];
  modifiers: Modifier[] = [];
  componentParameters: Parameter[] = [];
  projectParameters: Parameter[] = [];

  componentWithName(name: string) {
    return this.components?.find((component) => component.name === name);
  }
}
registerClass("PortableProjectData", PortableProjectData);

export class PortableProjectDataSnapshot {
  encoded: unknown;

  constructor(encoded: unknown) {
    this.encoded = encoded;
  }

  toString() {
    return JSON.stringify({
      app: "Cuttle",
      type: "PortableProjectData",
      version: currentVersion,
      payload: this.encoded,
    });
  }

  toPortableProjectData() {
    const projectData = decode(this.encoded, {
      // Important to assign new IDs here! We need to ensure any data inserted
      // into a project has a unique ID. For example when pasting the same thing
      // multiple times.
      assignNewIds: true,
    });
    if (projectData instanceof PortableProjectData) {
      return projectData;
    }
  }

  static fromPortableProjectData(portableProjectData: PortableProjectData) {
    const encoded = encodeMaterial(portableProjectData);
    return new PortableProjectDataSnapshot(encoded);
  }

  static fromJSON(j: any, options?: UpgradeSnapshotOptions) {
    const parsed = upgradeSnapshot(j, options);
    if (parsed?.type !== "PortableProjectData") {
      throw new Error('Expected "type" to be "PortableProjectData"');
    }
    const encoded = parsed.payload;
    return new PortableProjectDataSnapshot(encoded);
  }

  static fromString(s: string, options?: UpgradeSnapshotOptions) {
    try {
      let parsed = JSON.parse(s);
      parsed = upgradeSnapshot(parsed, options);
      if (parsed?.type === "PortableProjectData") {
        const encoded = parsed.payload;
        return new PortableProjectDataSnapshot(encoded);
      }
    } catch (error) {
      console.warn(error);
    }
    console.warn("Failed to parse PortableProjectData snapshot", s);
    return null;
  }

  static fromSVGString(s: string, options?: UpgradeSnapshotOptions) {
    const match = /<!--([^-]*)-->/.exec(s);
    if (match) {
      const comment = match[1];
      const elementsAndDepsString = unescapeHyphen(comment);
      return PortableProjectDataSnapshot.fromString(elementsAndDepsString, options);
    }
    return;
  }
}
