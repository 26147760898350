/*
 * Forked from https://github.com/ueberdosis/tiptap/tree/main/packages/extension-image
 * to only support images uploaded to Cuttle's S3 + Imgix setup.
 */

import { Node, nodeInputRule, mergeAttributes } from "@tiptap/core";
import { isImagePathPassthrough } from "../../shared/util";
import { publicBucketName } from "../../shared/config";

// Keep this in sync with @doc-width in src/cuttle-editor/src/shared/style/global.less
const IMAGE_WIDTH = 700;

export interface CuttleImageOptions {
  inline: boolean;
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    cuttleImage: {
      /**
       * Add an image
       */
      setCuttleImage: (options: { path: string; alt?: string }) => ReturnType;
    };
  }
}

export const inputRegex = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

const imgixUrl = (path: string, passthrough: boolean, devicePixelRatio: 1 | 2) => {
  if (passthrough) {
    return `https://${publicBucketName}.s3.us-west-1.amazonaws.com/${path}`;
  }
  // "Variable quality" reduces file size a bit for the high DPR source
  // https://docs.imgix.com/tutorials/responsive-images-srcset-imgix#use-variable-quality
  const q = 40 + 40 / devicePixelRatio;
  return `https://${publicBucketName}.imgix.net/${path}?auto=compress,format&q=${q}&fit=max&width=${IMAGE_WIDTH}&height=1280&dpr=${devicePixelRatio}`;
};

export const CuttleImage = Node.create<CuttleImageOptions>({
  name: "cuttleImage",

  // Set with `extend` in DocEditor, if it is editable
  selectable: false,
  draggable: false,

  addOptions() {
    return {
      inline: false,
      HTMLAttributes: {},
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? "inline" : "block";
  },

  addAttributes() {
    return {
      path: {
        default: null,
      },
      alt: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "img[path]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const isLocalDebug = HTMLAttributes.path.substring(0, 5) === "data:";
    let src: string;
    let srcset: undefined | string;
    if (isLocalDebug) {
      // Local debug uses a data: URL directly
      src = HTMLAttributes.path;
    } else {
      const passthrough = isImagePathPassthrough(HTMLAttributes.path);
      // Production makes an Imgix URL: https://docs.imgix.com/apis/rendering
      const standardSize = imgixUrl(HTMLAttributes.path, passthrough, 1);
      src = standardSize;
      if (!passthrough) {
        // TODO: max intrinsic: don't specify retinaSize for images <= 640px
        const retinaSize = imgixUrl(HTMLAttributes.path, passthrough, 2);
        srcset = `${standardSize} 1x, ${retinaSize} 2x`;
      }
    }
    return [
      "figure",
      { class: "doc-image" },
      ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { src, srcset })],
    ];
  },
  renderText({ node }) {
    return `[Cuttle image embed: ${imgixUrl(node.attrs.path, true, 1)}]\n\n`;
  },

  addCommands() {
    return {
      setCuttleImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, alt, src] = match;

          return { src, alt };
        },
      }),
    ];
  },
});
