import m from "mithril";

import { Documentation } from "../model/documentation";
import { SettingsFocus } from "../model/focus";
import { Project, ProjectSettings } from "../model/project";
import { IconSVG } from "../shared/icon";
import { classNames } from "../shared/util";

interface DocumentationViewAttrs {
  project: Project;
  documentation: Documentation;
}
export const DocumentationView: m.Component<DocumentationViewAttrs> = {
  view({ attrs: { project, documentation } }) {
    const onpointerdown = () => {
      project.focusItem(documentation);
    };
    const className = classNames({
      focused: project.focusedDocumentation() === documentation,
    });

    return m(".components-custom-item", { className, onpointerdown }, [
      m(IconSVG, { icon: "doc" }),
      m(".components-custom-item-name", documentation.name),
    ]);
  },
};

interface SettingsViewAttrs {
  project: Project;
  settings: ProjectSettings;
}
export const SettingsView: m.Component<SettingsViewAttrs> = {
  view({ attrs: { project, settings } }) {
    const onpointerdown = () => {
      project.focusItem(settings);
    };
    const className = classNames({
      focused: project.focus instanceof SettingsFocus && project.focus.settings === settings,
    });

    return m(".components-custom-item", { className, onpointerdown }, [
      m(IconSVG, { icon: "settings" }),
      m(".components-custom-item-name", "Units & Settings"),
    ]);
  },
};
