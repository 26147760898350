import { CompoundPath, Group } from "./geom";
import { Graphic } from "./geom/graphic/graphic";

/**
 * Flattens nested arrays of Geometry into Groups. This is used primarily for
 * convienience when writing Modifiers.
 *
 * @param graphic Any Geometry, or nested arrays of Geometry
 */
export const normalizeGraphic = (graphic: unknown): unknown => {
  if (!graphic) return new Group();
  if (Array.isArray(graphic)) return new Group(graphic.flat(Infinity));
  return graphic;
};

/**
 * Recursively flattens geometry hierarchy by removing empty Groups and
 * collapsing Groups with only one item. Mutates input geometry.
 */
export const simplifyHierarchy = (graphic: Graphic): Graphic | undefined => {
  if (graphic instanceof Group) {
    const { items } = graphic;
    if (items.length === 0) return undefined;
    const simplifiedItems: Graphic[] = [];
    for (let item of items) {
      const simplified = simplifyHierarchy(item);
      if (simplified) {
        simplifiedItems.push(simplified);
      }
    }
    if (simplifiedItems.length === 0) return undefined;
    if (simplifiedItems.length === 1) return simplifiedItems[0];
    graphic.items = simplifiedItems;
  } else if (graphic instanceof CompoundPath) {
    const { paths } = graphic;
    if (paths.length === 0) return undefined;
    if (paths.length === 1) {
      return paths[0].copyStyle(graphic);
    }
  }
  return graphic;
};
