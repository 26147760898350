import m from "mithril";
import { domForVnode } from "../shared/util";

interface RectWatcherAttrs {
  className: string;
  view: (rect: DOMRect) => m.Children;
}
export const RectWatcher: m.ClosureComponent<RectWatcherAttrs> = () => {
  let latestRect: DOMRectReadOnly | undefined;
  let resizeObserver: ResizeObserver;

  return {
    oncreate(vnode) {
      resizeObserver = new ResizeObserver((entries) => {
        latestRect = entries[0].contentRect;
        m.redraw();
      });
      const dom = domForVnode(vnode);
      resizeObserver.observe(dom);
    },
    onremove(vnode) {
      resizeObserver.disconnect();
    },

    view({ attrs: { view, className } }) {
      if (latestRect === undefined) {
        m.redraw();
        return m("div", { class: className });
      } else {
        return m("div", { class: className }, view(latestRect));
      }
    },
  };
};
