import { BoundingBox, Path } from "..";

/**
 * This class is used to gather information about which paths contain other
 * paths. It represents the containment hierarchy of a set of paths.
 *
 * A path is contained withing another path if every point on it lies inside,
 * and it does not cross the other path.
 */
export class Containment {
  /**
   * Paths contained within this containment.
   */
  contained: PathContainment[];

  constructor(contained: PathContainment[] = []) {
    this.contained = contained;
  }

  /**
   * @returns the maximum depth of this containment. Depth indicates how deeply
   * nested the hierarchy is. If depth is zero, nothing is contained within this
   * containment.
   */
  maximumDepth() {
    return maxDepthForContainment(this, 0);
  }

  /**
   * @returns all paths contained within this containment and its contained,
   * recursively.
   */
  allPaths(): Path[] {
    return this.contained.flatMap((c) => c.allPaths());
  }

  /**
   * Constructs a containment from an array of paths. Paths are organized into a
   * number of top level path containments, and paths that ar containeed within
   * those paths.
   *
   * A path is contained withing another path if every point on it lies inside,
   * and it does not cross the other path.
   *
   * @param paths An array of non-overlapping paths
   */
  static fromPaths(paths: Path[]) {
    const containments: PathContainment[] = paths.map((path) => new PathContainment(path));

    // Sort containments by area large to small.
    containments.sort((a, b) => b._area - a._area);

    // Loop over the containments small to large. If we are contained by a larger
    // path, add ourself to that path's contained.
    for (let i = containments.length; --i >= 0; ) {
      const inner = containments[i];
      if (!inner.box || inner.path.anchors.length < 1) continue;

      // Check previous larger bounding boxes
      for (let j = i; --j >= 0; ) {
        const outer = containments[j];
        if (!outer.box) continue;

        // Inner paths must be contained within the bounding box of another path.
        if (outer.box.containsBoundingBox(inner.box) && outer.path.containsPath(inner.path)) {
          outer.contained.push(inner);
          containments.splice(i, 1);
          break;
        }
      }
    }

    return new Containment(containments);
  }
}

/**
 * Represents a path and the paths it contains.
 *
 * A path is contained withing another path if every point on it lies inside,
 * and it does not cross the other path.
 */
export class PathContainment extends Containment {
  /**
   * The boundary of the containment.
   */
  path: Path;

  /**
   * The tight bounding box of the containment boundary.
   */
  box: BoundingBox | undefined;

  /**
   * The area of the bounding box of the containment boundary. We cache this
   * value for sorting purposes.
   *
   * @internal
   */
  _area: number;

  constructor(path: Path) {
    super();
    this.path = path;
    this.box = path.boundingBox();
    this._area = this.box?.area() ?? 0;
  }

  allPaths(): Path[] {
    return [this.path, ...this.contained.flatMap((c) => c.allPaths())];
  }
}

const maxDepthForContainment = (containment: Containment, depth: number) => {
  let maxDepth = depth;
  for (const c of containment.contained) {
    maxDepth = Math.max(maxDepth, maxDepthForContainment(c, depth + 1));
  }
  return maxDepth;
};
