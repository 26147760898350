import dayjs from "dayjs";
import m from "mithril";
import { IconButton } from "../shared/icon";
import { createPopupMenu, MenuItem } from "../shared/popup";
import { classNames, domForVnode } from "../shared/util";
import { globalState } from "../global-state";
import type { SnapshotRow } from "../shared/api-types";
import { TextEditor } from "./text-editor";

export const VersionHistory: m.Component<{}> = {
  oninit: () => {
    globalState.storage.refreshSnapshots();
  },
  view: () => {
    const snapshots = globalState.storage.getSnapshots();
    let mInner: m.Children;
    if (!snapshots) {
      mInner = "Loading...";
    } else {
      const versions = snapshots.filter((snapshot) => !snapshot.isAutosave);
      versions.sort((a, b) => dayjs(b.lastModified).valueOf() - dayjs(a.lastModified).valueOf());
      const autosaves = snapshots.filter((snapshot) => snapshot.isAutosave);
      autosaves.sort((a, b) => dayjs(b.lastModified).valueOf() - dayjs(a.lastModified).valueOf());

      mInner = [
        m(".version-history-section", [
          m(".version-history-section-name", "Saved Versions"),
          m(
            ".version-history-section-description",
            "A saved version is created every time you export, print, publish, or explicitly save a version of your project."
          ),
          versions.map((snapshot) => m(Version, { snapshot })),
        ]),
        m(".version-history-section", [
          m(".version-history-section-name", "Auto-saves"),
          m(
            ".version-history-section-description",
            "An auto-save is created for each editing session."
          ),
          autosaves.map((snapshot) => m(Version, { snapshot })),
        ]),
      ];
    }

    return m(".version-history", [
      m(".version-history-header", [
        m(".version-history-header-name", "Version History"),
        m(IconButton, {
          icon: "x",
          onpointerdown: () => (globalState.isVersionHistoryOpen = false),
        }),
      ]),
      m(".version-history-scroller", mInner),
    ]);
  },
};

const Version: m.Component<{ snapshot: SnapshotRow }> = {
  view(vnode) {
    const {
      attrs: { snapshot },
    } = vnode;

    // Information about the snapshot
    const when = dayjs(snapshot.lastModified).fromNow();
    const isAutosave = snapshot.isAutosave;
    const name = isAutosave ? "Auto-save" : snapshot.name;
    const isCurrent = globalState.storage.getCurrentSnapshotId() === snapshot.snapshotId;
    const isPublished = globalState.storage.getPublishedSnapshotId() === snapshot.snapshotId;

    // Actions that can be performed
    const canRestore = !isCurrent;
    const canDelete = !isCurrent && !isPublished;

    const className = classNames({
      "version-history-current": isCurrent,
    });

    let mInfo: m.Children = [
      m(".version-history-name", [
        name,
        isPublished ? m(".version-history-badge", "Published") : null,
      ]),
    ];
    if (!snapshot.isAutosave) {
      mInfo.push(
        m(".version-history-description", [
          m(EditableComment, {
            value: snapshot.description,
            onchange: (newDescription) => {
              globalState.storage.updateSnapshotDescription(snapshot.snapshotId, newDescription);
            },
          }),
        ])
      );
    }

    const restoreSnapshot = async () => {
      if (!canRestore) return;
      const projectSnapshot = await globalState.storage.restoreSnapshot(snapshot.snapshotId);
      if (projectSnapshot) {
        // Project ID for any previous snapshots should be the same as the
        // current project id in storage.
        const projectId = globalState.storage.getProjectId();
        globalState.loadProjectSnapshot(projectSnapshot, projectId);
        globalState.storage.refreshSnapshots();
        // We want to be able to undo after restoring a version, and this won't
        // automatically do it because it's async, so call
        // checkpointAfterNextEvaluation. Also, we can't just immediately
        // checkpoint here because the preview png won't be right.
        globalState.checkpointAfterNextEvaluation();
        m.redraw();
      }
    };

    const deleteSnapshot = async () => {
      if (!canDelete) return;
      await globalState.storage.deleteSnapshot(snapshot.snapshotId);
    };

    const menuItems: MenuItem[] = [
      { label: "Restore this version", action: restoreSnapshot, enabled: () => canRestore },
      { type: "separator" },
      { label: "Delete this version", action: deleteSnapshot, enabled: () => canDelete },
    ];

    const dotdotdotPointerDown = () => {
      const el = domForVnode(vnode);
      const spawnFrom = el.querySelector(".version-history-dotdotdot") as HTMLElement;
      createPopupMenu({
        menuItems,
        spawnFrom,
        placement: "bottom-end",
      });
    };

    return m(".version-history-snapshot", { className }, [
      m(".version-history-extra", [
        canRestore && m("button", { onclick: restoreSnapshot }, "Restore"),
        m(IconButton, {
          icon: "dotdotdot",
          className: "version-history-dotdotdot",
          onpointerdown: dotdotdotPointerDown,
        }),
      ]),
      m("img.version-history-preview", { src: snapshot.previewImage }),
      mInfo,
      m(".version-history-date", when),
    ]);
  },
};

// Editable Comment - might move to its own file

interface EditableCommentAttrs {
  value: string;
  onchange: (value: string) => void;
}
const EditableComment: m.ClosureComponent<EditableCommentAttrs> = () => {
  let isEditing = false;
  return {
    view: ({ attrs: { value, onchange } }) => {
      return m(".editable-comment", [
        isEditing
          ? m(TextEditor, {
              value,
              initialSelection: { begin: 0, end: 0 },
              onchange,
              onblur: () => (isEditing = false),
            })
          : [
              m(".editable-comment-value", value),
              m(
                ".editable-comment-edit",
                { onclick: () => (isEditing = true) },
                isStringEmpty(value) ? "Add Description" : "Edit Description"
              ),
            ],
      ]);
    },
  };
};

const isStringEmpty = (str: string | undefined) => {
  if (str) {
    return !/[^\s]/.test(str);
  }
  return true;
};
