import { Anchor, CompoundPath, Fill, Geometry, ImageFill, Path, Stroke, Vec } from "..";

/**
 * The base type for canvas geometry that can be exported, and returned from
 * components and modifiers.
 */
export abstract class Graphic extends Geometry {
  abstract clone(): Graphic;

  /**
   * @returns all compound paths contained within this graphic, recursively.
   */
  allCompoundPaths(): CompoundPath[] {
    return [];
  }

  /**
   * @returns all paths contained within this graphic, recursively.
   */
  allPaths(): Path[] {
    return [];
  }

  /**
   * @returns all anchors contained within this graphic, recursively.
   */
  allAnchors(): Anchor[] {
    return [];
  }

  /**
   * @returns all paths and compound paths contained within this graphic,
   * recursively.
   */
  allPathsAndCompoundPaths(): (CompoundPath | Path)[] {
    return [];
  }

  /**
   * @returns all paths grouped by their stroke and fill colors.
   *
   * Paths must have identical stroke _and_ fill colors to be grouped together.
   */
  allPathsByColor(): Path[][] {
    const paths = this.allPaths();
    const pathsByColor: Path[][] = [];
    const isSameColor = (a?: Stroke | Fill | ImageFill, b?: Stroke | Fill | ImageFill) => {
      if (a === b) return true;
      if (a === undefined) return false;
      if (b === undefined) return false;
      if (a instanceof ImageFill && b instanceof ImageFill) {
      }
      if (a instanceof ImageFill) return false;
      if (b instanceof ImageFill) return false;
      return a.color.equals(b.color);
    };
    const addPath = (path: Path) => {
      // Check for existing paths of this color.
      for (let paths of pathsByColor) {
        const [{ stroke, fill }] = paths;
        if (isSameColor(path.stroke, stroke) && isSameColor(path.fill, fill)) {
          paths.push(path);
          return;
        }
      }
      // No match was found. Start a new color array.
      pathsByColor.push([path]);
    };
    for (let path of paths) addPath(path);
    return pathsByColor;
  }

  /**
   * @returns `true` if this graphic has either a stroke or a fill.
   */
  hasStyle(): boolean {
    return false;
  }

  /**
   * Assigns a fill to this graphic.
   *
   * @chainable
   */
  assignFill(fill: Fill | ImageFill): Graphic {
    return this;
  }

  /**
   * Removes a fill style from this graphic.
   *
   * @chainable
   */
  removeFill() {
    return this;
  }

  /**
   * Assigns a stroke style to this graphic.
   *
   * @chainable
   */
  assignStroke(stroke: Stroke): Graphic {
    return this;
  }

  /**
   * Removes a stroke style from this graphic.
   *
   * @chainable
   */
  removeStroke() {
    return this;
  }

  /**
   * Assigns both a stroke and fill style to this graphic.
   *
   * @chainable
   */
  assignStyle(fill: Fill | ImageFill, stroke: Stroke): Graphic {
    return this;
  }

  /**
   * Copies the stroke and fill from `graphic`.
   *
   * @chainable
   */
  copyStyle(graphic: Graphic): Graphic {
    return this;
  }

  /**
   * Scales the stroke width of this graphic by `scaleFactor`.
   *
   * @param scaleFactor The amount to scale existing strokes by
   * @chainable
   */
  scaleStroke(scaleFactor: number): Graphic {
    return this;
  }

  /**
   * Returns `true` if this graphic contains `point`.
   *
   * @param point The point to test
   */
  containsPoint(point: Vec): boolean {
    return false;
  }

  /**
   * Returns `true` if this graphic's style contains `point`.
   *
   * This method differs from `containsPoint()` in that it takes the stroke and
   * fill styling into account.
   *
   * @param point The point to test1
   */
  styleContainsPoint(point: Vec): boolean {
    return false;
  }

  /**
   * @returns The first styled graphic (Path or CompoundPath) in a group, or the
   * styled path itself. A path must have either a stroke or fill to be
   * considered styled.
   */
  firstStyled(): Path | CompoundPath | undefined {
    return undefined;
  }

  /**
   * @returns `true` if `graphic` is a valid graphic
   */
  static isValid(graphic: unknown): graphic is Graphic {
    if (graphic instanceof Graphic) return graphic.isValid();
    return false;
  }
}
