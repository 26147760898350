import { globalState } from "../../global-state";
import { SelectableComponentParameter, SelectableParameter } from "../../model/selectable";

export class ParameterStack {
  selectables: (SelectableParameter | SelectableComponentParameter)[];

  heroSelectable: SelectableParameter | SelectableComponentParameter;

  isImmutable: boolean;
  isMixed: boolean;
  isMultiline: boolean;

  preferEditingDefinition = false;

  constructor(
    selectables: (SelectableParameter | SelectableComponentParameter)[],
    preferEditingDefinition: boolean
  ) {
    const heroSelectable = (this.heroSelectable = selectables[0]);
    const heroDefinition = heroSelectable.definition();

    // Ensure that all parameters in the stack have the same definition.
    this.selectables = selectables.filter((s) => s.definition() === heroDefinition);

    const heroExpressionCode = heroSelectable.expression().latestCode();
    this.isImmutable = this.selectables.some((s) => s.isImmutable());
    this.isMixed = this.selectables.some((s) => s.expression().latestCode() !== heroExpressionCode);
    this.isMultiline = heroExpressionCode.includes("\n");

    this.preferEditingDefinition = preferEditingDefinition;
  }

  setEditingExpressionCode(jsCode: string) {
    for (let selectable of this.selectables) {
      const editableExpr = selectable.ensureEditableExpression(this.preferEditingDefinition);
      editableExpr.editingJsCode = jsCode;
    }
  }
  commitEditingExpression() {
    for (let selectable of this.selectables) {
      selectable.expression().commitEditingCode();
    }
  }
  tryCompileAndCommitEditingExpression() {
    for (let selectable of this.selectables) {
      // Get the expression. We don't use ensureEditableExpression here because
      // we don't want to create an arg in case the expression was not actually
      // edited.
      const expression = selectable.expression();
      if (!expression.isCommitted()) {
        const success = expression.tryCompileAndCommit(() => {
          return globalState.project.allNamesInExpressionScope(expression);
        });
      }
    }
  }
  revertToDefault() {
    for (let selectable of this.selectables) {
      selectable.revertToDefault();
    }
  }
}
