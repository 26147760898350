import m from "mithril";
import { createPopup } from "./popup";
import { domForVnode, isPointerEventDoubleClick, focusableSelectors, classNames } from "./util";

/**
 * Replaces any number of space-like things with one space.
 * See https://jkorpela.fi/chars/spaces.html
 */
export const trimWeirdSpaces = (name: string) => {
  return name.trim().replace(/[\s\u00A0\u1680\u180E\u2000-\u200B\u202F\u205F\u3000\uFEFF]+/g, " ");
};

// EditableText, when double clicked, will open a small overlay directly above
// the original text where the user can type in a new value.

interface EditableTextAttrs {
  value: string;
  onopen?: () => void;
  onclose?: () => void;
  onchange: (value: string) => void;

  // autoSelect will open the editor (if not already open) and select its text
  // when set to true. Use this if some other interface action (e.g. selecting
  // "Rename" from a menu or creating a new thing that needs a name) should
  // trigger the editor.
  autoSelect?: boolean;

  // If true, open the editor on the first click (don't wait for double click)
  singleClick?: boolean;
}

export const EditableText: m.ClosureComponent<EditableTextAttrs> = (initialVnode) => {
  let latestVnode = initialVnode;
  let isOpen = false;

  const hasChildren = () => {
    return Array.isArray(latestVnode.children) && latestVnode.children.length > 0;
  };

  const openEditor = () => {
    if (isOpen) return;
    const containerEl = domForVnode(latestVnode);

    const rectEl = hasChildren() ? containerEl : (containerEl.querySelector("span") as HTMLElement);
    const rect = rectEl.getBoundingClientRect();

    const onchange = (newValue: string) => {
      createdPopup.close();
      latestVnode.attrs.onchange(trimWeirdSpaces(newValue));
    };

    const onTabOut = (event: KeyboardEvent) => {
      // List of all focusable things plus these .editable-text components, to
      // be able to find the prev/next focusable around this spawning el.
      const allFocusable = Array.from(
        document.querySelectorAll(".editable-text," + focusableSelectors.join(","))
      );
      const containerElIndex = allFocusable.indexOf(containerEl);
      if (containerElIndex > -1) {
        const focusIndex = (containerElIndex + (event.shiftKey ? -1 : 1)) % allFocusable.length;
        // TODO: make .editable-text focusable, or skip over them here
        const nextFocusable = allFocusable[focusIndex];
        (nextFocusable as HTMLElement)?.focus();
      }
    };

    isOpen = true;
    const createdPopup = createPopup({
      view: () => m(EditableTextPopup, { value: latestVnode.attrs.value, onchange, onTabOut }),
      spawnFrom: { x: rect.left - 8, y: rect.top - 9 },
      className: "editable-text-popup",
      onclose: () => {
        isOpen = false;
        latestVnode.attrs.onclose?.();
      },
      overlay: "closeOnOutsidePointerDown",
    });
    latestVnode.attrs.onopen?.();
  };

  const onCreateOrUpdate = ({ attrs: { autoSelect } }: m.VnodeDOM<EditableTextAttrs>) => {
    if (autoSelect && !isOpen) {
      openEditor();
      m.redraw();
    }
  };

  return {
    view(vnode) {
      latestVnode = vnode;
      const {
        attrs: { value },
      } = vnode;
      const onpointerdown = (pointerDownEvent: PointerEvent) => {
        if (latestVnode.attrs.singleClick || isPointerEventDoubleClick(pointerDownEvent)) {
          pointerDownEvent.stopPropagation();
          openEditor();
        }
      };
      return m(
        ".editable-text",
        {
          onpointerdown,
          className: classNames({
            "single-click": Boolean(latestVnode.attrs.singleClick),
          }),
        },
        hasChildren() ? vnode.children : m("span", value)
      );
    },
    oncreate: onCreateOrUpdate,
    onupdate: onCreateOrUpdate,
  };
};

interface EditableTextPopupAttrs {
  value: string;
  onchange: (value: string) => void;
  onTabOut: (event: KeyboardEvent) => void;
}
const EditableTextPopup: m.ClosureComponent<EditableTextPopupAttrs> = (initialVnode) => {
  const { value, onchange, onTabOut } = initialVnode.attrs;
  return {
    view() {
      return m(".editable-text-contenteditable", value);
    },
    oncreate(vnode) {
      const el = domForVnode(vnode);
      const width = el.getBoundingClientRect().width;
      el.style.minWidth = width + "px";
      el.setAttribute("contenteditable", "true");
      el.spellcheck = false;
      selectAll(el);
      el.addEventListener("blur", () => {
        onchange(el.textContent ?? "");
      });
      el.addEventListener("keydown", (event: KeyboardEvent) => {
        if (event.code === "Enter") {
          event.preventDefault();
          el.blur();
        }
        if (event.code === "Tab") {
          event.preventDefault();
          el.blur();
          onTabOut(event);
        }
      });
    },
  };
};

// Helpers

const selectAll = (el: HTMLElement) => {
  el.focus();
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
    const selectAllRange = document.createRange();
    selectAllRange.selectNodeContents(el);
    selection.addRange(selectAllRange);
  }
};
