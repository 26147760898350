import m from "mithril";

import { globalState } from "../global-state";
import { SiteContainer } from "../shared/site-layout/site-layout";
import { isObject, isString } from "../shared/util";
import { ComponentEmbed } from "./doc-editor/component-embed";
import { Expression } from "../model/expression";

/**
 * Todo:
 *
 * Check if certain project can be embedded in certain domain.
 *
 * API to communicate changed parameter set out to embedding page.
 */
export const AppEmbed: m.Component = {
  oncreate() {
    window.addEventListener("message", (event) => {
      const data = event.data;
      if (isObject(data)) {
        const { action } = data;
        if (action === "setProjectParameter") {
          const { name, expressionCode } = data;
          if (isString(name) && isString(expressionCode)) {
            const parameter = globalState.project.parameterWithName(name);
            if (parameter) {
              parameter.expression = new Expression(expressionCode);
              m.redraw();
            }
          }
        }
      }
    });

    // Tell the iframe parent that we're ready to recieve messages.
    parent.postMessage({ status: "ready" });
  },
  view() {
    globalState.updateEvaluation();

    const componentIds = globalState.project.documentation.firstComponentEmbedIds() || [
      globalState.project.mainComponent().id,
    ];

    if (componentIds.length === 0) return "Embed error: no component found.";

    return m(
      ".app-embed",
      m(SiteContainer, [
        m(ComponentEmbed, {
          componentIds,
          hideActionsAndDimensions: true,
          hideParameters: true,
          hideRulers: true,
        }),
      ])
    );
  },
};
