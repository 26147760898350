import { Vec, assert } from "../../geom/index";
import { globalState } from "../../global-state";
import { CanvasInterfaceElement } from "./canvas-interface-element";

const PICKING_TOLERANCE = 1e-6;

export const pickInterfaceElemAtWorldPosition = (
  interfaceElements: CanvasInterfaceElement[],
  worldPosition: Vec
) => {
  const focusedComponent = globalState.project.focusedComponent();
  assert(focusedComponent, "Should only be used when a component is focused");

  const selectionDistance = globalState.selectionDistanceWorld();

  const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
  let hitElem: CanvasInterfaceElement | undefined;
  let closestElem: CanvasInterfaceElement | undefined;
  let closestDistance = Infinity;
  let closestIsLowPriority = true;
  for (let i = interfaceElements.length; --i >= 0; ) {
    const elem = interfaceElements[i];

    if (elem.isValid !== undefined && !elem.isValid()) continue; // Skip invalid interface elements
    if (elem.hitTest === undefined) continue; // Skip interface elements that are not interactable

    const result = elem.hitTest(worldPosition, viewport.pixelsPerUnit);
    if (result) {
      const { distance, isHit, isLowPriority } = result;

      if (distance !== undefined) {
        const isCloseEnough = distance <= selectionDistance;
        const isClosest = distance < closestDistance - PICKING_TOLERANCE;
        const isHighPriorityOverride = closestIsLowPriority && !isLowPriority;

        if (isCloseEnough && (isClosest || isHighPriorityOverride)) {
          closestElem = elem;
          closestDistance = distance;
          closestIsLowPriority = Boolean(isLowPriority);
        }
      }

      if (isHit) {
        hitElem = elem;
        break;
      }
    }
  }

  if (closestDistance <= selectionDistance) {
    return closestElem;
  }

  return hitElem;
};
