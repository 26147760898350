import m from "mithril";

import { range } from "../geom";
import { globalState } from "../global-state";
import { SelectableInstance } from "../model/selectable";
import { InstanceTrace } from "../model/trace";
import { EditableText } from "../shared/editable-text";
import { isNumber } from "../shared/util";
import { Checkbox } from "./basic/checkbox";
import { InspectorInstanceHeaderContents } from "./inspector/instance-inspector";

interface RepeatModifierSettingsAttrs {
  selectable: SelectableInstance;
}
export const RepeatModifierSettings: m.Component<RepeatModifierSettingsAttrs> = {
  view({ attrs: { selectable } }) {
    const { instance } = selectable;

    const isCustomized = instance.repeatIndexVariableName !== null;

    const toggleCustomized = () => {
      if (instance.repeatIndexVariableName) {
        instance.repeatIndexVariableName = null;
      } else {
        globalState.project.createRepeatIndexVariable(selectable);
      }
    };

    return [
      m("label.inspector-row.instance-settings-category", [
        m(Checkbox, { checked: isCustomized, disabled: false, onclick: toggleCustomized }),
        m(".instance-settings-category-name", "Customize Each Repetition"),
      ]),
      isCustomized && [
        m(CustomizedRepeatModifierSettings, { selectable }),
        m(
          ".inspector-row.comment",
          m(
            "p",
            "This “index variable” takes on a different value for each repetition, starting at 0 and increasing sequentially. You can use it in expressions to make each repetition different."
          )
        ),
      ],
    ];
  },
};

interface CustomizedRepeatModifierSettingsAttrs {
  selectable: SelectableInstance;
}
const CustomizedRepeatModifierSettings: m.Component<CustomizedRepeatModifierSettingsAttrs> = {
  view({ attrs: { selectable } }) {
    const renameIndexVariable = (name: string) => {
      globalState.project.renameRepeatIndexVariable(selectable, name);
    };
    return [
      m(".inspector-row.instance-setting", [
        m(".instance-setting-name", [
          m(EditableText, {
            value: selectable.instance.repeatIndexVariableName!,
            onchange: renameIndexVariable,
          }),
        ]),
        m(
          ".instance-setting-value",
          m(RepetitionsParameterValue, { trace: selectable.instanceTrace() })
        ),
      ]),
    ];
  },
};

interface RepetitionsParameterValueAttrs {
  trace?: InstanceTrace;
}
const RepetitionsParameterValue: m.Component<RepetitionsParameterValueAttrs> = {
  view({ attrs: { trace } }) {
    const repetitionsTrace = trace?.args.repetitions;
    if (!repetitionsTrace?.isSuccess) return;

    // If repetitions is invalid or zero, inputs are still evaluated with the
    // index variable set to `0`.
    let repetitions = 1;
    {
      const result = repetitionsTrace.evaluationResult;
      if (isNumber(result) && isFinite(result) && result > 0) {
        repetitions = result;
      }
    }

    const firstReps = Math.min(10, repetitions) - 1;
    if (firstReps < 0) return;

    const lastRep = repetitions - 1;
    return m(".repetitions-sequence", [
      firstReps > 0 &&
        m("span.first-repetitions", [
          range(firstReps).join(", "),
          m("span", firstReps < lastRep ? " … " : ", "),
        ]),
      m("span", lastRep),
    ]);
  },
};

interface ScopeRepeatModifiersInspectorAttrs {
  selectable: SelectableInstance;
}
export const ScopeRepeatModifiersInspector: m.Component<ScopeRepeatModifiersInspectorAttrs> = {
  view({ attrs: { selectable } }) {
    const instances = globalState.project.customizedRepeatModifiersInInstanceScope(selectable);
    if (instances.length === 0) return;
    return instances.map((selectable) => {
      if (!selectable.instance.isEnabled) return;
      return m(".inspector-section.repeat-variables.editing-definition", [
        m(".inspector-row.inspector-header", [
          m(InspectorInstanceHeaderContents, {
            selectable,
            isNameEditable: false,
            hideEnabledCheckbox: true,
          }),
        ]),
        m(CustomizedRepeatModifierSettings, { selectable }),
      ]);
    });
  },
};
