import { Unit, valueByUnitConversion } from "../geom";
import { Substitution, performSubstitutions } from "../util";
import { CompileResult, compile } from "./compile";
import { Env } from "./env";
import { ModelObject } from "./model-object";
import { registerClass } from "./registry";
import { ExpressionTrace } from "./trace";

export class Expression extends ModelObject {
  jsCode: string; // The code that actually runs when this expression is evaluated

  editingJsCode: string | null; // The code that is currently being edited

  private _compiledJsCode?: string;
  private _compiled?: CompileResult;

  materialKeys() {
    return ["jsCode", "editingJsCode"];
  }

  constructor(jsCode = "", editingJsCode?: string | null) {
    super();
    this.jsCode = jsCode; // String of Javascript code
    this.editingJsCode = editingJsCode !== undefined ? editingJsCode : null;
  }

  runsSameCodeAs(expression: Expression) {
    return this.jsCode === expression.jsCode;
  }

  compiled(): CompileResult {
    if (this.jsCode === this._compiledJsCode) {
      return this._compiled as CompileResult;
    }
    this._compiled = compile(this.jsCode);
    this._compiledJsCode = this.jsCode;
    return this._compiled;
  }

  evaluate(env: Env) {
    const compileResult = this.compiled();
    const runnerResult = compileResult.runner(env);
    return runnerResult;
  }

  isLiteral() {
    return this.literal() !== undefined;
  }
  isComputed() {
    return !this.isLiteral();
  }

  isEmpty() {
    return this.jsCode.length === 0;
  }

  isCommitted() {
    return this.editingJsCode === null;
  }

  literal() {
    return this.compiled().literal;
  }
  literalValue() {
    return this.literal()?.value;
  }
  literalUnit() {
    const literal = this.compiled().literal;
    if (literal && (literal.type === "Number" || literal.type === "Vec")) {
      return literal.unit;
    }
  }
  literalValueInUnit(unit: Unit) {
    const literal = this.compiled().literal;
    if (literal) {
      if ((literal.type === "Number" || literal.type === "Vec") && literal.unit) {
        return valueByUnitConversion(literal.value, literal.unit, unit);
      }
      return literal.value;
    }
  }

  replaceReferences(oldName: string, newName: string) {
    const substitutions: Substitution[] = [];
    this.compiled().references.forEach((reference) => {
      if (reference.name === oldName) {
        reference.ranges.forEach((range) => {
          substitutions.push({ range, subranges: [], replacement: newName });
        });
      }
    });
    this.jsCode = performSubstitutions(this.jsCode, substitutions);
  }

  clone() {
    return new Expression(this.jsCode, this.editingJsCode);
  }

  latestCode() {
    return this.editingJsCode ?? this.jsCode;
  }

  tryCompileAndCommit(allowedReferenceNames: () => string[]) {
    if (this.editingJsCode === null) return false;

    const compiled = compile(this.editingJsCode);
    if (!compiled.parseSuccess) return false;

    if (compiled.references.length > 0) {
      // Computing names in scope currently requires building a full
      // dependency graph of the project. This is very slow, so only do it if
      // the expression contains any references.
      const allowedNames = allowedReferenceNames();
      if (!compiled.references.every((ref) => allowedNames.includes(ref.name))) {
        return false;
      }
    }

    this.commitEditingCode();
    this._compiled = compiled;
    return true;
  }

  /** On blur or enter or play button pressed. */
  commitEditingCode() {
    if (this.editingJsCode !== null) {
      this.jsCode = this.editingJsCode;
      this.editingJsCode = null;
    }
  }
}
registerClass("Expression", Expression);

/**
 * This is only used for evaluating first-class Components and Modifiers, where
 * you want to explicitly pass in (evaluated) values for args on an Instance.
 */
export class ExplicitValue extends Expression {
  value: unknown;

  constructor(value: unknown) {
    super("// Explicit Value");
    this.value = value;
  }

  evaluate(env: Env): ExpressionTrace {
    return new ExpressionTrace(this.value);
  }
}
