/**
 * Converts any number, array or object to a string.
 *
 * Numbers with very long fractional parts that are very close to a shorter
 * number will be rounded.
 *
 * For example: `toString(0.9999999999999) = "1"`
 *
 * @param value Any number or object to convert
 */
export const toString = (value: unknown): string => {
  if (typeof value === "number") return beautyStringForNumber(value);
  if (typeof value === "string") return value;
  if (typeof value === "function") return value.name;
  if (typeof value === "object") {
    if (Array.isArray(value)) {
      return `[${value.map((v) => toString(v)).join(",")}]`;
    }
    return JSON.stringify(value);
  }
  return String(value);
};

export const beautyStringForNumber = (
  x: number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 16
) => {
  // Chop 4 digits off the end of the number.
  const str = x.toFixed(maximumFractionDigits - 4);

  // Number.toFixed can return scientific notation for very large values. Ignore these.
  if (str.indexOf("e") === -1) {
    // Remove any trailing zeros
    let len = str.length;
    let i = len;
    while (--i >= 0 && str[i] === "0");

    if (len - i > 4) {
      // If we have removed at least 4 zeros we call this a win
      let outStr = str.slice(0, i + 1);

      // Normalize zero values "-0.00" --> "0.00"
      if (outStr === "-0.") outStr = "0.";

      const decimalIndex = outStr.lastIndexOf(".");
      if (minimumFractionDigits > 0) {
        // Pad string to the minimum number of fractional digits with zeros
        return outStr.padEnd(decimalIndex + minimumFractionDigits + 1, "0");
      }
      if (decimalIndex === outStr.length - 1) {
        // Remove the trailing decimal
        return outStr.slice(0, decimalIndex);
      }
      return outStr;
    }
  }

  // If we can't beautify the string, use whatever JavaScript thinks is the
  // shortest represention in decimal. This will not output scientific notation.
  return x.toLocaleString("en-US", {
    minimumFractionDigits,
    maximumFractionDigits: 20,
    useGrouping: false,
  });
};

export const stringForNumber = (x: number, maximumFractionDigits?: number): string => {
  if (maximumFractionDigits !== undefined) {
    let str = x.toFixed(maximumFractionDigits);

    if (maximumFractionDigits > 0) {
      // Remove zeroes until we hit the desired precision.
      let len = str.length;
      let i = len - 1;
      let iMin = len - maximumFractionDigits;
      while (i >= iMin && str[i] === "0") i--;

      // If we removed the entire fractional part, remove the decimal point too.
      if (str[i] === ".") i--;

      str = str.slice(0, i + 1);
    }

    // Don't ever return negative zero.
    if (str === "-0") return "0";

    return str;
  }
  return x.toString();
};
