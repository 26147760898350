import { config } from "../config";
import { AffineMatrix, Graphic, Group, _seedGlobalRandom, assert } from "../geom";
import { normalizeGraphic } from "../geom-internal";
import { LogMessage } from "../log-manager";
import { isNumber } from "../shared/util";
import { allBuiltinShapes } from "./builtin";
import { CodeComponent, Component } from "./component";
import { Element } from "./element";
import { Env } from "./env";
import { EvaluateElementMemoizer } from "./evaluate-element-memoizer";
import { ExplicitValue } from "./expression";
import { Instance } from "./instance";
import { makeGlobalEnv } from "./make-global-env";
import { Modifier } from "./modifier";
import { ParameterInterface } from "./parameter-interface";
import { Project } from "./project";
import { ElementTrace, ExpressionTrace, InstanceTrace, ProjectTrace } from "./trace";

export class ProjectEvaluator {
  evaluateElementMemoizer?: EvaluateElementMemoizer;

  constructor(evaluateElementMemoizer?: EvaluateElementMemoizer) {
    this.evaluateElementMemoizer = evaluateElementMemoizer;
  }

  evaluateProject(project: Project) {
    /**
     * A note about environments
     *
     * globalEnv:
     * - Accessible in any expression
     * - Contains JS and Cuttle Geometry Library functions and constants
     * - Contains Builtin and project Components (callable as functions)
     *
     * projectParamsEnv:
     * - Accessible in any expression
     * - Contains evaluated project parameters
     *
     * paramsEnv:
     * - Accessible in parameter expressions of Instance definitions
     * - Inherits from globalEnv and projectParamsEnv
     * - Contains previously evaluated parameters of the same Instance
     *   definition. This allows parameters to reference previous parameters.
     *
     * argsEnv:
     * - Accessible in overridden parameters (arguments). Instance arguments
     *   override their corresponding parameter expressions.
     * - Inherits from globalEnv and projectParamsEnv
     * - Contains evaluated parameters from the Instance's context Component
     */

    const globalEnv = makeGlobalEnv(project, this);

    const projectInstance = new Instance(project);
    const projectBaseTrace = this.evaluateInstance(
      projectInstance,
      new Env(),
      globalEnv,
      globalEnv
    );

    const definitions = allBuiltinShapes.concat(project.components);
    const definitionTraces = definitions.map((definition, i) => {
      _seedGlobalRandom(i.toString()); // TODO: Assign a persistent seed to each component
      const instance = new Instance(definition);
      const paramsEnv = new Env(globalEnv);

      const trace = this.evaluateInstance(instance, new Env(), paramsEnv, globalEnv);

      if (config.checkMemoizationResults) {
        const evaluateElementMemoizer = this.evaluateElementMemoizer;
        this.evaluateElementMemoizer = undefined;
        _seedGlobalRandom(i.toString()); // TODO: Assign a persistent seed to each component
        const instance = new Instance(definition);
        const paramsEnv = new Env(globalEnv);

        const trace2 = this.evaluateInstance(instance, new Env(), paramsEnv, globalEnv);
        this.evaluateElementMemoizer = evaluateElementMemoizer;
        assert(
          JSON.stringify(trace.result) === JSON.stringify(trace2.result),
          "Memoized result not equal to non-memoized result"
        );
      }

      return trace;
    });

    this.evaluateElementMemoizer?.clean();

    return new ProjectTrace(projectBaseTrace, definitionTraces);
  }

  evaluateElement(element: Element, argsEnv: Env, globalEnv: Env): ElementTrace {
    if (this.evaluateElementMemoizer) {
      return this.evaluateElementMemoizer.evaluateElement(element, argsEnv, globalEnv, this);
    } else {
      return this.evaluateElementInner(element, argsEnv, globalEnv);
    }
  }

  evaluateElementInner(element: Element, argsEnv: Env, globalEnv: Env): ElementTrace {
    const lastIndex = element.modifiers.length - 1;
    const trace = this.evaluateElementUpToModifierIndex(element, argsEnv, globalEnv, lastIndex);

    if (config.freezeTraces) deepFreeze(trace.result);

    return trace;
  }

  evaluateElementUpToModifierIndex(
    element: Element,
    argsEnv: Env,
    globalEnv: Env,
    index: number
  ): ElementTrace {
    if (index < 0) {
      return this.evaluateElementBaseTransformStyle(element, argsEnv, globalEnv);
    }

    const modifier = element.modifiers[index];
    if (modifier.isEnabled && modifier.isRepeatModifier()) {
      return this.evaluateElementUpToRepeatModifierAtIndex(element, argsEnv, globalEnv, index);
    }

    // Evaluate everything before this modifier.
    const trace = this.evaluateElementUpToModifierIndex(element, argsEnv, globalEnv, index - 1);
    if (!trace._isSuccess) return trace;

    // Evaluate this modifier.
    if (modifier.isEnabled) {
      const input = trace.result;
      assert(input instanceof Graphic);

      const paramsEnv = new Env(globalEnv);
      paramsEnv.set("input", input);

      const instanceTrace = this.evaluateInstance(modifier, argsEnv, paramsEnv, globalEnv);
      trace.modifiers.push(instanceTrace);

      trace.result = instanceTrace.result;
      trace._isSuccess = instanceTrace._isSuccess;
    }

    return trace;
  }

  evaluateElementUpToRepeatModifierAtIndex(
    element: Element,
    argsEnv: Env,
    globalEnv: Env,
    index: number
  ) {
    const instance = element.modifiers[index];
    const instanceTrace = new InstanceTrace(instance);

    // Create an env for the modifier's parameters.
    const paramsEnv = new Env(globalEnv);

    // Must be pre-sanitized.
    const indexVariableName = instance.repeatIndexVariableName;

    const zeroRepetitionsTrace = () => {
      let iterationArgsEnv = argsEnv;

      // The index variable might still be used even if repetitions is zero. To
      // avoid breaking expressions in any of the previous modifiers, we set the
      // index variable to `0`.
      if (indexVariableName) {
        iterationArgsEnv = new Env(argsEnv);
        iterationArgsEnv.set(indexVariableName, 0);
      }

      const trace = this.evaluateElementUpToModifierIndex(
        element,
        iterationArgsEnv,
        globalEnv,
        index - 1
      );
      trace.modifiers.push(instanceTrace);
      trace.result = new Group();

      return trace;
    };

    // Evaluate the repeat modifier's parameters. We need to do this in advance
    // so that we have access to the `repetitions` parameter. We evaluate all
    // parameters because `repetitions` could be derived from a previous
    // parameter.
    let success = this.evaluateInstanceParameters(instanceTrace, instance, argsEnv, paramsEnv);

    if (!success) {
      instanceTrace._isSuccess = success;
      return zeroRepetitionsTrace();
    }

    const repetitions = instanceTrace.resultForArgName("repetitions");
    if (!isValidRepetitions(repetitions)) {
      return zeroRepetitionsTrace();
    }

    const count = Math.trunc(repetitions);
    if (count < 1) {
      instanceTrace._isSuccess = success;
      return zeroRepetitionsTrace();
    }

    const inputs = new Array<Graphic>(count);
    let errorTrace: ElementTrace | undefined;
    let heroTrace: ElementTrace;

    // If the user has set an index variable name for this repeat modifier, then
    // we must evaluate each repetition individually.
    if (indexVariableName) {
      const inputsTraces = new Array<ElementTrace>(count);
      for (let i = 0; i < count; i++) {
        // Set the iteration index on the argsEnv. This means the index variable
        // will only be accessible in argument (overridden) expressions.
        const iterationArgsEnv = new Env(argsEnv);
        iterationArgsEnv.set(indexVariableName, i);

        const inputTrace = this.evaluateElementUpToModifierIndex(
          element,
          iterationArgsEnv,
          globalEnv,
          index - 1
        );

        // Assign the erroring input trace as the hero so the user can inspect it.
        if (!inputTrace._isSuccess) {
          errorTrace = inputTrace;
          break;
        }

        inputsTraces[i] = inputTrace;

        assert(inputTrace.result instanceof Graphic);
        inputs[i] = inputTrace.result;
      }

      heroTrace = errorTrace ?? inputsTraces[0];
    }

    // If an index variable is not set, then we can simply duplicate the input
    // without re-evaluating.
    else {
      const inputTrace = this.evaluateElementUpToModifierIndex(
        element,
        argsEnv,
        globalEnv,
        index - 1
      );
      if (!inputTrace._isSuccess) {
        errorTrace = inputTrace;
        heroTrace = inputTrace;
      } else {
        heroTrace = inputTrace;
        assert(inputTrace.result instanceof Graphic);
        inputs[0] = inputTrace.result;
        for (let i = 1; i < count; ++i) {
          inputs[i] = inputs[0].clone();
        }
      }
    }

    if (errorTrace === undefined) {
      paramsEnv.set("inputs", inputs);

      // Evaluate modifier code.
      this.evaluateInstanceResult(instanceTrace, instance, paramsEnv, globalEnv);
    }

    heroTrace.modifiers.push(instanceTrace);

    heroTrace.result = instanceTrace.result;
    heroTrace._isSuccess = instanceTrace._isSuccess;

    return heroTrace;
  }

  evaluateElementBaseTransformStyle(element: Element, argsEnv: Env, globalEnv: Env): ElementTrace {
    const trace = new ElementTrace(element);

    // We'll be building up an environment as we evaluate expressions.
    const paramsEnv = new Env(globalEnv);

    EvaluateBase: {
      const { base } = element;
      if (base.definition instanceof Modifier) {
        // We need to set up paramsEnv.input so that our modifier code has
        // access to it.
        const childResults: Graphic[] = [];
        for (let childElement of element.children) {
          // We don't need to clone here because `input` will get cloned by
          // Expression.evaluate when we send it in to the Modifier. Note we're
          // using argsEnv (not paramsEnv) here because the childNodes are in
          // the same context as we are.
          const childElementTrace = this.evaluateElement(childElement, argsEnv, globalEnv);
          trace.children.push(childElementTrace);

          // Accumulate visible input Geometry
          if (
            childElementTrace.isSuccess() &&
            childElement.isVisible &&
            childElement.guidesDisplay !== "show-all-as-guides"
          ) {
            childResults.push(childElementTrace.result);
          }
        }
        paramsEnv.set("input", new Group(childResults));
      }

      trace.base = this.evaluateInstance(element.base, argsEnv, paramsEnv, globalEnv);
      if (!trace.base.isSuccess()) {
        return trace;
      }
    }

    let input = trace.base.result;

    EvaluateTransform: {
      if (element.transform?.isEnabled) {
        const paramsEnv = new Env(globalEnv);
        paramsEnv.set("input", input);

        trace.transform = this.evaluateInstance(element.transform, argsEnv, paramsEnv, globalEnv);
        if (trace.transform.isSuccess()) {
          input = trace.transform.result;
        } else {
          return trace;
        }
      }
    }

    EvaluateFillAndStroke: {
      if (element.fill?.isEnabled) {
        const paramsEnv = new Env(globalEnv);
        paramsEnv.set("input", input);

        trace.fill = this.evaluateInstance(element.fill, argsEnv, paramsEnv, globalEnv);
        if (trace.fill.isSuccess()) {
          input = trace.fill.result;
        } else {
          return trace;
        }
      }
      if (element.stroke?.isEnabled) {
        const paramsEnv = new Env(globalEnv);
        paramsEnv.set("input", input);

        trace.stroke = this.evaluateInstance(element.stroke, argsEnv, paramsEnv, globalEnv);
        if (trace.stroke.isSuccess()) {
          input = trace.stroke.result;
        } else {
          return trace;
        }
      }
    }

    trace.result = input;
    trace._isSuccess = true;

    return trace;
  }

  evaluateInstance(
    instance: Instance,
    argsEnv: Env,
    paramsEnv: Env,
    globalEnv: Env
  ): InstanceTrace {
    const trace = new InstanceTrace(instance);

    let success = this.evaluateInstanceParameters(trace, instance, argsEnv, paramsEnv);
    if (success) {
      success = this.evaluateInstanceResult(trace, instance, paramsEnv, globalEnv);
    }

    return trace;
  }

  evaluateComponentFunction(
    component: Component | CodeComponent,
    args: Record<string, unknown>,
    globalEnv: Env
  ) {
    const instance = new Instance(component);
    for (let name in args) {
      instance.args[name] = new ExplicitValue(args[name]);
    }
    const argsEnv = new Env();
    const paramsEnv = new Env(globalEnv);
    return this.evaluateInstance(instance, argsEnv, paramsEnv, globalEnv);
  }

  evaluateModifierFunction(
    modifier: Modifier,
    args: Record<string, unknown>,
    input: unknown,
    globalEnv: Env
  ) {
    const instance = new Instance(modifier);
    for (let name in args) {
      instance.args[name] = new ExplicitValue(args[name]);
    }

    const argsEnv = new Env();
    const paramsEnv = new Env(globalEnv);

    const trace = new InstanceTrace(instance);
    let success = this.evaluateInstanceParameters(trace, instance, argsEnv, paramsEnv);
    if (!success) return trace;

    if (modifier.isRepeat) {
      // Repeat modifiers require "inputs" to be an array with an element for
      // each repetition. Even though customized repetitions are not supported
      // in this context, we need to create the array.
      const repetitions = trace.resultForArgName("repetitions");
      if (!isValidRepetitions(repetitions)) return trace;
      if (repetitions < 1) {
        // Explicity allow zero repetitions. The result is just an empty group
        // and the modifier code is not evaluated.
        trace.result = new Group();
        trace._isSuccess = success;
        return trace;
      }
      paramsEnv.set("inputs", new Array<unknown>(repetitions).fill(input));
    } else {
      paramsEnv.set("input", input);
    }

    success = this.evaluateInstanceResult(trace, instance, paramsEnv, globalEnv);
    return trace;
  }

  evaluateInstanceParameters(
    trace: InstanceTrace,
    instance: Instance,
    argsEnv: Env,
    paramsEnv: Env
  ) {
    const { parameters, circularPaths } = instance.parametersInEvaluationOrder();
    let allSuccess = true;
    for (let parameter of parameters) {
      let expressionTrace: ExpressionTrace;
      if (instance.hasArgumentWithName(parameter.name)) {
        // Args are evaluated using the argsEnv (e.g. from the context).
        expressionTrace = instance.args[parameter.name].evaluate(argsEnv);
      } else {
        // Parameters that are not overridden by an arg are evaluated using the
        // paramsEnv we're building up.
        expressionTrace = parameter.expression.evaluate(paramsEnv);
      }

      if (circularPaths.length > 0) {
        const paramCircularPaths = circularPaths.filter(
          (path) => path[path.length - 1] === parameter.name
        );
        if (paramCircularPaths.length > 0) {
          const messages = paramCircularPaths.map((path) => {
            return new LogMessage(
              `Circular reference found (${path.join(" ⏵ ")})
  One of the parameters referenced in this expression also references this parameter!`,
              "error",
              -2
            );
          });
          expressionTrace = expressionTrace.traceByAppendingMessages(messages);
        }
      }

      if (expressionTrace.isSuccess && parameter.interface instanceof ParameterInterface) {
        // Use the parameter interface to perform simple validation of the result.
        if (!parameter.interface.isValidValue(expressionTrace.evaluationResult)) {
          expressionTrace = expressionTrace.traceByAppendingMessages([
            new LogMessage(parameter.interface.invalidValueMessage(), "warn", -1),
          ]);
        }
      }

      trace.args[parameter.name] = expressionTrace;

      const sanitizedName = parameter.sanitizedName();
      paramsEnv.set(sanitizedName, expressionTrace.evaluationResult);

      allSuccess &&= expressionTrace.isSuccess;
    }
    return allSuccess;
  }

  evaluateInstanceResult(trace: InstanceTrace, instance: Instance, paramsEnv: Env, globalEnv: Env) {
    const { definition } = instance;
    if (definition instanceof Modifier || definition instanceof CodeComponent) {
      // paramsEnv["input"] (or ["inputs"] for repeat modifiers) must be set to
      // the evaluated child geometry.
      trace.code = definition.code.evaluate(paramsEnv);
      if (!trace.code.isSuccess) {
        return false;
      }

      const { evaluationResult } = trace.code;
      if (evaluationResult instanceof AffineMatrix) {
        // Modifiers are allowed to return an AffineMatrix instead of Geometry
        if (!evaluationResult.isValid()) return false;

        const input = paramsEnv.get("input");

        // We can't transform an invalid graphic, so return unsuccessful.
        if (!Graphic.isValid(input)) return false;

        trace.result = input.clone().affineTransform(evaluationResult);
      } else {
        // Result is Graphic
        const result = normalizeGraphic(evaluationResult);

        if (!Graphic.isValid(result)) return false;

        trace.result = result;
      }
    } else if (definition instanceof Component) {
      trace.element = this.evaluateElement(definition.element, paramsEnv, globalEnv);

      if (!trace.element._isSuccess) return false;

      trace.result = trace.element.result;
    }

    trace._isSuccess = true;

    if (config.freezeTraces) deepFreeze(trace.result);

    return true;
  }

  /**
   * Ad-hoc evaluates an array of elements as if they were children of the focused
   * component. This is used in cases such as copying segments where we want to
   * get geometry for elements that were only created for the purpose of making a
   * snapshot and have not been added to the project.
   */
  evaluateElementsWithinFocusedComponent(project: Project, elements: Element[]) {
    const focusedComponent = project.focusedComponent();
    assert(focusedComponent, "Should only be used when a component is focused");

    const globalEnv = makeGlobalEnv(project, this);

    const projectInstance = new Instance(project);
    this.evaluateInstance(projectInstance, new Env(), globalEnv, globalEnv);

    // TODO: Each component needs a persistent random seed, otherwise this
    // evaluation may differ from the project evaluation.
    const instance = new Instance(focusedComponent);
    const paramsEnv = new Env(globalEnv);
    this.evaluateInstance(instance, new Env(), paramsEnv, globalEnv);

    return elements.map((element) => this.evaluateElement(element, paramsEnv, globalEnv));
  }
}

const deepFreeze = (o: unknown) => {
  if (o && typeof o === "object") {
    // We'll assume if an object is already frozen that it's also deep frozen
    // (i.e. its descendants are also frozen).
    if (Object.isFrozen(o)) return;
    Object.freeze(o);
    for (let key of Object.getOwnPropertyNames(o)) {
      deepFreeze((o as any)[key]);
    }
  }
};

const isValidRepetitions = (repetitions: unknown): repetitions is number => {
  return isNumber(repetitions) && isFinite(repetitions);
};
