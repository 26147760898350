import m from "mithril";
import { accountState } from "../shared/account";
import { modalState, ModalX } from "../shared/modal";

interface AccountModalAttrs {
  onSuccess?: () => void;
  onDismiss?: () => void;
}

const CreateAccountToDownloadModal: m.Component<AccountModalAttrs> = {
  view({ attrs: { onSuccess, onDismiss } }) {
    return m(".modal-box.account-modal.create-account-to-download-modal", [
      m(ModalX),
      m(
        "p.create-account-to-download-message",
        "Create a free account",
        m("br"),
        "to download this project"
      ),
      m(
        "button",
        { onclick: () => accountState.openAccountModal("signup", onSuccess, onDismiss) },
        "Create Account"
      ),
      m("p.account-error"),
      m("p", [
        "Have an account? ",
        m(
          "span[role=link]",
          {
            onclick: () => accountState.openAccountModal("login", onSuccess, onDismiss),
          },
          "Log in"
        ),
      ]),
    ]);
  },
};

export const openCreateAccountToDownloadModal = () => {
  return new Promise<boolean>((resolve) => {
    const onSuccess = () => resolve(true);
    const onDismiss = () => resolve(false);
    modalState.open({
      modalView: () => m(CreateAccountToDownloadModal, { onSuccess, onDismiss }),
      onDismiss,
    });
  });
};
