import m from "mithril";
import {
  AffineMatrix,
  BoundingBox,
  CompoundPath,
  Graphic,
  Group,
  Path,
  TAU,
  Vec,
} from "../geom/index";
import { pathToCanvasPath } from "../geom/io/canvas";
import { globalState } from "../global-state";
import { CustomCanvas } from "./custom-canvas";
import styleConstants from "./style-constants";
import { devicePixelRatio } from "./util";

const placeholderThumbnail = (width: number, height: number) => {
  return m(".thumbnail", { style: `width:${width}px;height:${height}px` });
};

interface ThumbnailAttrs {
  graphic: Graphic | undefined;
  parameterPoints?: Vec[];

  width: number;
  height: number;
  padding: number;

  isHovered?: boolean;
  isFocused?: boolean;
  isBuiltin?: boolean;

  fillColor?: string;
  strokeColor?: string;
}
interface ThumbnailState {
  latestPixelRatio?: number;
}
export const Thumbnail: m.Component<ThumbnailAttrs, ThumbnailState> = {
  onbeforeupdate(vnode, old) {
    if (globalState.isFirstRedrawAfterBecameVisible) return true;
    // Skip re-rendering thumbnails for memoized components. A memoized
    // component's "result" will be triple-equal to it's previous value, since
    // the value is cached.
    const isSame =
      vnode.attrs.graphic === old.attrs.graphic &&
      vnode.attrs.isHovered === old.attrs.isHovered &&
      vnode.attrs.isFocused === old.attrs.isFocused &&
      this.latestPixelRatio === devicePixelRatio();
    if (isSame) {
      return false; // Prevent calling view.
    }
    return true;
  },
  onupdate() {
    // Stash the last pixel ratio so we know when the thumbnail needs to be
    // updated again in onbeforeupdate.
    this.latestPixelRatio = devicePixelRatio();
  },
  view({
    attrs: {
      graphic,
      parameterPoints,
      width,
      height,
      padding,
      isHovered,
      isFocused,
      isBuiltin,
      fillColor,
      strokeColor,
    },
  }) {
    if (!graphic) {
      return placeholderThumbnail(width, height);
    }

    const containerSize = new Vec(width - padding * 2, height - padding * 2);

    let boundingBox = graphic.looseBoundingBox();

    if (parameterPoints) {
      for (let point of parameterPoints) {
        if (boundingBox) {
          boundingBox.expandToIncludePoint(point);
        } else {
          boundingBox = new BoundingBox(point.clone(), point.clone());
        }
      }
    }

    if (!boundingBox) return placeholderThumbnail(width, height);

    let size = boundingBox.size();
    if (size.isZero()) {
      boundingBox.expandScalar(0.5);
      size.set(1, 1);
    }

    // Calculate offset for centering
    const sizeRatio = size.x / size.y;
    const containerSizeRatio = containerSize.x / containerSize.y;
    let offset: Vec;
    if (sizeRatio > containerSizeRatio) {
      const difference = size.x / containerSizeRatio - size.y;
      offset = new Vec(0, difference / 2);
    } else {
      const difference = size.y * containerSizeRatio - size.x;
      offset = new Vec(difference / 2, 0);
    }

    const scale = Math.min(containerSize.x / size.x, containerSize.y / size.y);

    const viewMatrix = AffineMatrix.fromTransform({ position: new Vec(padding, padding) })
      .mul(AffineMatrix.fromTransform({ scale }))
      .mul(AffineMatrix.fromTransform({ position: offset.clone().sub(boundingBox.min) }));

    if (!fillColor) {
      if (isBuiltin) {
        fillColor = isHovered ? styleConstants.blue63Alpha80 : styleConstants.gray70;
      } else {
        fillColor = isFocused
          ? styleConstants.blue53Alpha20
          : isHovered
          ? styleConstants.blue63Alpha20
          : styleConstants.blackAlpha10;
      }
    }
    if (!strokeColor) {
      strokeColor = isFocused
        ? styleConstants.blue53
        : isHovered
        ? styleConstants.blue63
        : styleConstants.gray70;
    }
    const closedStrokeColor: string | undefined = isBuiltin ? undefined : strokeColor;

    const renderThumbnail = (viewportSize: Vec, ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, viewportSize.x, viewportSize.y);

      paintThumbnailGraphicToCanvas(
        graphic,
        fillColor,
        strokeColor,
        closedStrokeColor,
        viewMatrix,
        ctx
      );

      if (parameterPoints) {
        const viewPoint = new Vec();
        const strokeColor = isFocused || isHovered ? styleConstants.blue43 : styleConstants.gray24;
        for (let point of parameterPoints) {
          viewPoint.copy(point).affineTransform(viewMatrix).round();

          ctx.fillStyle = strokeColor;
          ctx.beginPath();
          ctx.ellipse(viewPoint.x, viewPoint.y, 2, 2, 0, 0, TAU);
          ctx.fill();

          ctx.fillStyle = styleConstants.white;
          ctx.beginPath();
          ctx.ellipse(viewPoint.x, viewPoint.y, 1, 1, 0, 0, TAU);
          ctx.fill();
        }
      }
    };

    return m(
      ".thumbnail",
      { style: `width:${width}px; height:${height}px;` },
      m(CustomCanvas, {
        viewportSize: new Vec(width, height),
        render: renderThumbnail,
      })
    );
  },
};

const paintThumbnailGraphicToCanvas = (
  graphic: Graphic,
  fillColor: string | undefined,
  strokeColor: string | undefined,
  closedStrokeColor: string | undefined,
  viewMatrix: AffineMatrix,
  ctx: CanvasRenderingContext2D
) => {
  // Group
  if (graphic instanceof Group) {
    for (let item of graphic.items) {
      paintThumbnailGraphicToCanvas(
        item,
        fillColor,
        strokeColor,
        closedStrokeColor,
        viewMatrix,
        ctx
      );
    }
  }

  // Paths
  else if (graphic instanceof Path || graphic instanceof CompoundPath) {
    ctx.beginPath();
    const viewGraphic = graphic.clone().affineTransform(viewMatrix);
    pathToCanvasPath(viewGraphic, ctx);

    if (fillColor) {
      if (graphic instanceof CompoundPath || graphic.closed) {
        ctx.fillStyle = fillColor;
        ctx.fill("evenodd");
        strokeColor = closedStrokeColor;
      }
    }

    if (strokeColor) {
      ctx.lineCap = "round";
      ctx.lineJoin = "round";
      ctx.lineWidth = 1;
      ctx.strokeStyle = strokeColor;
      ctx.stroke();
    }
  }
};
