import { Extension } from "@tiptap/core";
import type { EditorState } from "@tiptap/pm/state";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import type { EditorView } from "@tiptap/pm/view";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

import { globalState } from "../../global-state";
import { checkImageSizeAndType, pickImage } from "../image-upload";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    imageUploadExtension: {
      /**
       * Pick an image file, then trigger upload.
       */
      cuttleImagePick: () => ReturnType;
      /**
       * Add a placeholder decoration while image file uploads.
       */
      cuttleImageUpload: (options: { file: File }) => ReturnType;
    };
  }
}

export const ImageUploadExtension = Extension.create({
  name: "imageUploadExtension",
  addProseMirrorPlugins() {
    return [pmImageUploadPlugin];
  },
  addCommands() {
    return {
      cuttleImagePick: () => {
        return ({ commands }) => {
          pickImage().then((file) => commands.cuttleImageUpload({ file }));
          return true;
        };
      },
      cuttleImageUpload: ({ file }) => {
        return ({ view }) => {
          if (!checkImageSizeAndType(file)) {
            return false;
          }

          // A fresh object to act as the ID for this upload
          const id = {};

          // Placeholder dance. Insert placeholder before current node. For
          // example, with a text selection in a nested list, the image will be
          // inserted before the whole list.
          const transaction = view.state.tr;
          const anchorResolvedPos = transaction.selection.$anchor;
          const pos = anchorResolvedPos.before(1);
          transaction.setMeta(pmImageUploadPluginKey, {
            add: { id, pos, fileName: file.name },
          });
          view.dispatch(transaction);

          // Upload dance.
          globalState.storage.uploadFile(file).then(
            (path: string) => {
              const pos = findImageUploadPlaceholder(view.state, id);
              // If the content around the placeholder has been deleted, drop
              // the image
              if (pos === undefined) return;
              // Otherwise, insert it at the placeholder's position, and remove
              // the placeholder
              view.dispatch(
                view.state.tr
                  .replaceWith(pos, pos, [
                    view.state.schema.nodes.cuttleImage.create({
                      path,
                    }),
                  ])
                  .setMeta(pmImageUploadPlugin, { remove: { id } })
              );
              // Reposition floating menu with new image
              triggerRedrawMenuHack(view);
            },
            () => {
              // On failure, just clean up the placeholder
              const transaction = view.state.tr;
              view.dispatch(transaction.setMeta(pmImageUploadPlugin, { remove: { id } }));
            }
          );
          // Reposition floating menu with placeholder
          triggerRedrawMenuHack(view);
          return true;
        };
      },
    };
  },
});

/**
 * # Placeholder Plugin
 * Holds space for the image while it's uploading
 */
const pmImageUploadPluginKey = new PluginKey("cuttleImageUploadPlaceholder");
const pmImageUploadPlugin = new Plugin({
  state: {
    init() {
      return DecorationSet.empty;
    },
    apply(tr, set) {
      // Adjust decoration positions to changes made by the transaction
      set = set.map(tr.mapping, tr.doc);
      // See if the transaction adds or removes any placeholders
      const action = tr.getMeta(pmImageUploadPluginKey);
      if (action && action.add) {
        const { id, pos, fileName } = action.add;
        const widget = document.createElement("placeholder");
        widget.textContent = `Uploading ${fileName}...`;
        const deco = Decoration.widget(pos, widget, { id });
        set = set.add(tr.doc, [deco]);
      } else if (action && action.remove) {
        set = set.remove(
          set.find(
            undefined,
            undefined,
            (spec: { [key: string]: any }) => spec.id == action.remove.id
          )
        );
      }
      return set;
    },
  },
  props: {
    decorations(state) {
      // TS thinks that `this` here is EditorProps, but it is instanceof Plugin.
      return (this as Plugin).getState(state);
    },
    handlePaste(view, event: ClipboardEvent) {
      const editor = globalState.activeDocEditor;
      if (!editor) return false;
      const files = event?.clipboardData?.files;
      if (!files || !files.length) return false;
      const file = files[0];
      if (!file) return false;
      // Did Tiptap extension command run successfully?
      const success = editor.chain().focus().cuttleImageUpload({ file }).run();
      // Was ProseMirror paste handled here?
      return success;
    },
  },
  key: pmImageUploadPluginKey,
});

function findImageUploadPlaceholder(state: EditorState, id: {}) {
  const decos = pmImageUploadPlugin.getState(state) as DecorationSet;
  const found = decos.find(undefined, undefined, (spec: { [key: string]: any }) => spec.id == id);
  return found.length ? found[0].from : undefined;
}

// export function findCurrentImageUploadPlaceholder(state: EditorState) {
//   const decos = pmImageUploadPlugin.getState(state) as DecorationSet;
//   const anchor = state.selection.$anchor;
//   return decos.find(anchor.pos, anchor.pos).length > 0;
// }

/** Hack to trigger floating menu reposition */
function triggerRedrawMenuHack(view: EditorView) {
  setTimeout(() => {
    (view.dom as HTMLElement).blur();
    view.focus();
  });
}
