import CodeMirror from "codemirror";
import m from "mithril";
import { numberRangesForString } from "../model/code-ranges";
import { CodeEditorSelection } from "../model/focus";
import { classNames, debounce, domForVnode, isMacPlatform } from "../shared/util";
import { precisionDigitsForNumberString } from "../util";
import {
  CodeHint,
  codeMirrorExtraKeys,
  codeMirrorHintOptions,
  CodeMirrorPool,
} from "./code-mirror";
import { startNumberScrubDrag } from "./start-drag";

const cmdKey = isMacPlatform() ? "Cmd" : "Ctrl";

const NUMBER_SCRUB_EVENT_NAME = "cuttle-number-scrub";

// ----------------------------------------------------------------------------
// CodeMirror Config
// ----------------------------------------------------------------------------

const codeMirrorOptions = {
  mode: "javascript",
  tabSize: 2,
  indentUnit: 2,
  indentWithTabs: false,
  matchBrackets: true,
  lineWrapping: true,
  dragDrop: false,
  undoDepth: 0,
  extraKeys: codeMirrorExtraKeys,
};

const cmPool = new CodeMirrorPool(codeMirrorOptions);

// TODO: If the cursor isn't in view, it should scroll the parent container (use
// scrollIntoView).

// TODO: Consider switching to Sublime addon mode, which would mean we could get
// rid of some of the above tab, etc keymaps:
// - https://codemirror.net/doc/manual.html#keymaps
// - https://codemirror.net/demo/sublime.html
// - https://github.com/codemirror/CodeMirror/issues/988#issuecomment-556928041
// - https://codemirror.net/doc/manual.html#config

// TODO: Autocomplete. Both javascript and autocompleting whatever names are in
// scope (these will need to be passed in as another attr to the CodeEditor
// component.) Helpful links:
// - http://codemirror.net/doc/manual.html#addon_show-hint
// - https://github.com/cdglabs/apparatus/blob/master/src/View/ExpressionCode.coffee#L152

// TODO: Number precision niceness. We'd like to "compress" numbers with lots of
// decimal precision with ellipses and then remove these ellipses when you're
// actively editing the number. More thought needed. Helpful links:
// - https://codemirror.net/doc/manual.html#api_marker
// - https://github.com/cdglabs/apparatus/blob/master/src/View/ExpressionCode.coffee#L152

// TODO: Eventually we'd like to add back typescript-level support to
// autocomplete. Things to look into would be running the typescript compiler on
// the code in order to determine autocompletions (and maybe mark static
// errors). There's also this "tern" thing.
// - https://ts-ast-viewer.com/
// - https://github.com/microsoft/TypeScript/wiki/Using-the-Compiler-API
// - https://ternjs.net/
// - https://codemirror.net/demo/tern.html

// ----------------------------------------------------------------------------
// Number Scrubbing
// ----------------------------------------------------------------------------

const getNumberPositionFromPointerEvent = (cm: CodeMirror.Editor, pointerEvent: PointerEvent) => {
  const position = cm.coordsChar(
    { left: pointerEvent.clientX, top: pointerEvent.clientY },
    "window"
  );

  // Make sure we're really on the right character.
  if (cm.cursorCoords(position, "window").left < pointerEvent.clientX) {
    position.ch++;
  }

  let token = cm.getTokenAt(position, true);

  // If the cursor is placed at the leftmost pixel of a number, the token just
  // before the number is selected by mistake.
  if (token.type !== "number") {
    position.ch++;
    token = cm.getTokenAt(position, true);
  }

  const line = position.line;
  let start = { line, ch: token.start };
  let end = { line, ch: token.end };

  // Bring in a negative sign if necessary. TODO: We should check to make sure
  // we're not grabbing the "-" from a subtraction (e.g. 22-30).
  if (start.ch > 0) {
    const earlyStart = { line: start.line, ch: start.ch - 1 };
    if (cm.getRange(earlyStart, start) === "-") {
      start = earlyStart;
    }
  }

  return { start, end };
};

const startNumberScrub = (cm: CodeMirror.Editor, downEvent: PointerEvent) => {
  // We need to preventDefault to prevent CodeMirror from starting a drag
  // selection gesture.
  downEvent.preventDefault();

  const { start, end } = getNumberPositionFromPointerEvent(cm, downEvent);
  cm.focus();
  cm.setSelection(start, end);

  const word = cm.getSelection();
  const precisionDigits = Math.min(3, precisionDigitsForNumberString(word));

  // Don't scrub with touch, as it conflicts with selection moving
  if (downEvent.pointerType === "touch") {
    return;
  }

  startNumberScrubDrag(downEvent, {
    startValue: +word,
    fractionDigits: precisionDigits,
    onChange(newValue: number, fractionDigits: number) {
      let newWord = newValue.toFixed(fractionDigits);
      // Prevent "-0.00"
      if (+newWord === 0) {
        newWord = (0).toFixed(fractionDigits);
      }
      cm.replaceSelection(newWord, "around");
      CodeMirror.signal(cm, NUMBER_SCRUB_EVENT_NAME, cm);
    },
  });
};

// ----------------------------------------------------------------------------
// Number Tabbing
// ----------------------------------------------------------------------------

const isAtStartOrEndOfText = (cm: CodeMirror.Editor) => {
  const cursor = cm.getCursor();
  const selections = cm.listSelections();
  const lastLine = cm.lastLine();
  const atStartOrEndOfText =
    selections.length === 1 &&
    ((cursor.line === 0 && cursor.ch === 0) ||
      (cursor.line === lastLine && cursor.ch === cm.getLine(lastLine).length));
  return atStartOrEndOfText;
};

const vecFieldNumberMatchRanges = (cm: CodeMirror.Editor) => {
  if (cm.lastLine() > 0) return;
  const str = cm.getLine(0);
  return numberRangesForString(str);
};

// ----------------------------------------------------------------------------
// Line Annotations
// ----------------------------------------------------------------------------

export interface LineAnnotation {
  line: number;
  view: () => m.Vnode;
}

interface ManagedLineWidget extends LineAnnotation {
  lineWidget: CodeMirror.LineWidget;
  containerEl: HTMLElement;
}

class LineWidgetManager {
  cm: CodeMirror.Editor;
  managedLineWidgets: ManagedLineWidget[];

  constructor(cm: CodeMirror.Editor) {
    this.cm = cm;
    this.managedLineWidgets = [];
  }

  updateWithLineAnnotations(lineAnnotations: LineAnnotation[]) {
    // Remove widgets that no longer exist.
    this.managedLineWidgets = this.managedLineWidgets.filter((managedLineWidget) => {
      const stillExists = lineAnnotations.some(
        (lineAnnotation) => lineAnnotation.line === managedLineWidget.line
      );
      if (!stillExists) {
        this.dispose(managedLineWidget);
      }
      return stillExists;
    });

    // Update all existing widgets. Create widgets for lineAnnotations that
    // don't have widgets.
    for (let lineAnnotation of lineAnnotations) {
      const managedLineWidget = this.managedLineWidgets.find(
        (managedLineWidget) => managedLineWidget.line === lineAnnotation.line
      );
      if (managedLineWidget) {
        managedLineWidget.view = lineAnnotation.view;
      } else {
        this.managedLineWidgets.push(this.create(lineAnnotation));
      }
    }

    // Update all heights next frame.
    window.requestAnimationFrame(() => {
      for (let managedLineWidget of this.managedLineWidgets) {
        managedLineWidget.lineWidget.changed();
      }
    });
  }

  private create(lineAnnotation: LineAnnotation): ManagedLineWidget {
    const containerEl = document.createElement("div");
    containerEl.className = "codemirror-widget-container";

    let { line, view } = lineAnnotation;

    let cmLine = line;
    if (cmLine < 0) cmLine = this.cm.lastLine() + 1;
    const lineWidget = this.cm.addLineWidget(cmLine - 1, containerEl);

    const managedLineWidget: ManagedLineWidget = { line, view, lineWidget, containerEl };
    m.mount(containerEl, {
      view() {
        return managedLineWidget.view();
      },
    });

    return managedLineWidget;
  }

  private dispose(managedLineWidget: ManagedLineWidget) {
    m.mount(managedLineWidget.containerEl, null);
    managedLineWidget.lineWidget.clear();
  }

  disposeAll() {
    for (let managedLineWidget of this.managedLineWidgets) {
      this.dispose(managedLineWidget);
    }
    this.managedLineWidgets = [];
  }
}

// ----------------------------------------------------------------------------
// Code Editor Component
// ----------------------------------------------------------------------------

interface CodeEditorAttrs {
  value: string;
  lineAnnotations?: LineAnnotation[];
  readOnly?: boolean;
  confirmOnEnter?: boolean;

  /**
   * Specifying forceSelections or forceFocused means that the parent is
   * assuming responsibility for managing those states. This code editor's focus
   * and selection will be communicated through the `onFocus`, `onBlur` and
   * `onSelection` callbacks. The parent should update its state when these
   * change, otherwise they'll be overridden the next time this component is
   * updated.
   */
  forceSelections?: CodeEditorSelection[] | "all";
  forceFocused?: boolean;

  initialSelections?: CodeEditorSelection[] | "all";

  onFocus?: () => void;
  onBlur?: () => void;
  onSelection?: (selections: CodeEditorSelection[]) => void;

  codeHints?: () => CodeHint[];

  onChange: (value: string) => void;

  onConfirm?: () => void;
  onCommandEnter?: () => void;
  onNumberScrub?: () => void; // Fires after change event

  // Fires when the CodeMirror instance is created. Allows outer components to
  // do things like change the selection and focus.
  onCodeMirror?: (cm: CodeMirror.Editor) => void;
}
export const CodeEditor: m.ClosureComponent<CodeEditorAttrs> = (initialVnode) => {
  let latestVnode = initialVnode;
  let cm: CodeMirror.Editor;
  let lineWidgetManager: LineWidgetManager;

  let nextFocusViaPointer = false;
  let isFocused = false;

  const isMultiline = () => latestVnode.attrs.value.includes("\n");

  // ResizeObserver setup and cleanup in oncreate, onremove
  let queuedRefresh: void | number;
  const resizeObserver = new ResizeObserver(() => {
    // Debounce with requestAnimationFrame if last refresh hasn't happened, as
    // we don't want to hit a loop with ResizeObserver
    if (cm && !queuedRefresh) {
      queuedRefresh = requestAnimationFrame(() => {
        cm.refresh();
        queuedRefresh = undefined;
      });
    }
  });

  const onCodeMirrorChange = () => {
    const { onChange, value } = latestVnode.attrs;
    const newValue = cm.getValue();
    if (newValue !== value) {
      onChange(newValue);
      m.redraw();
    }
  };

  const onCodeMirrorCursorActivity = () => {
    if (cm.hasFocus()) {
      latestVnode.attrs.onSelection?.(cm.listSelections());
    }
  };

  const onCodeMirrorFocus = () => {
    isFocused = true;
    if (!nextFocusViaPointer) {
      // Probably keyboard focus: match normal DOM input selection behavior
      if (isMultiline()) {
        cm.execCommand("goDocStart");
      } else {
        cm.execCommand("selectAll");
      }
    }
    nextFocusViaPointer = false;
    latestVnode.attrs.onFocus?.();
    m.redraw();
  };

  const onCodeMirrorBlur = () => {
    isFocused = false;
    latestVnode.attrs.onSelection?.([]);
    latestVnode.attrs.onBlur?.();
    m.redraw();
  };

  const onScrub = () => {
    latestVnode.attrs.onNumberScrub?.();
  };

  const codeMirrorKeyMap: CodeMirror.KeyMap = {
    Enter: (cm) => {
      if (latestVnode.attrs.confirmOnEnter) {
        cm.getInputField().blur();
        m.redraw();
        latestVnode.attrs.onConfirm?.();
      } else {
        cm.execCommand("newlineAndIndent");
      }
    },
    Tab: (cm) => {
      const atStartOrEnd = isAtStartOrEndOfText(cm);

      if (isMultiline()) {
        if (atStartOrEnd) {
          // Browser default: tab to next focusable
          return CodeMirror.Pass;
        }

        // At start of line(s) or within indent(s)
        const somethingSelected = cm.somethingSelected();
        const selections = cm.listSelections();
        const shouldIndentMore =
          somethingSelected ||
          selections.every((selection) => {
            const { anchor } = selection;
            if (anchor.line === 0) return false;
            if (anchor.ch === 0) return true;
            const indentation: number = cm.getStateAfter(anchor.line).indented;
            return anchor.ch <= indentation;
          });
        if (shouldIndentMore) {
          cm.execCommand("indentMore");
          return;
        }

        // Otherwise, go to end of doc, so next tab will fall to browser
        cm.execCommand("goDocEnd");
        return;
      }

      if (!atStartOrEnd) {
        // Tab to next number on line
        const numberRanges = vecFieldNumberMatchRanges(cm);
        if (numberRanges) {
          const cursor = cm.getCursor();
          const nextNumber = numberRanges.find((range) => range.begin >= cursor.ch);
          if (nextNumber) {
            cm.setSelection(
              { line: cursor.line, ch: nextNumber.begin },
              { line: cursor.line, ch: nextNumber.end }
            );
            return;
          }
        }
      }

      // Browser default
      return CodeMirror.Pass;
    },
    ["Shift-Tab"]: (cm) => {
      const atStartOrEnd = isAtStartOrEndOfText(cm);

      if (isMultiline()) {
        if (atStartOrEnd) {
          // Browser default: tab to previous focusable
          return CodeMirror.Pass;
        }

        // At start of line(s) or within indent(s)
        const somethingSelected = cm.somethingSelected();
        const selections = cm.listSelections();
        const shouldIndentLess =
          somethingSelected ||
          selections.every((selection) => {
            const { anchor } = selection;
            if (anchor.line === 0) return false;
            const indentation: number = cm.getStateAfter(anchor.line).indented;
            return indentation > 0 && anchor.ch <= indentation;
          });
        if (shouldIndentLess) {
          cm.execCommand("indentLess");
          return;
        }

        // Go to doc start, so next shift-tab will fall to browser
        cm.execCommand("goDocStart");
        return;
      }

      if (!atStartOrEnd) {
        // Tab to previous number on line
        const numberRanges = vecFieldNumberMatchRanges(cm);
        if (numberRanges) {
          const cursor = cm.getCursor();
          numberRanges.reverse();
          const prevNumber = numberRanges.find((range) => range.end < cursor.ch);
          if (prevNumber) {
            cm.setSelection(
              { line: cursor.line, ch: prevNumber.begin },
              { line: cursor.line, ch: prevNumber.end }
            );
            return;
          }
        }
      }

      // Browser default
      return CodeMirror.Pass;
    },
    Backspace: (cm) => {
      // If anything is selected, just do normal backspace.
      if (cm.somethingSelected()) {
        cm.execCommand("delCharBefore");
        return;
      }

      // Look at all the cursor positions. If all of them are at soft tabs, then
      // we'll indent less, otherwise just do normal backspace.
      const indentUnit = cm.getOption("indentUnit") ?? 2;
      const cursorPositions = cm.listSelections().map((selection) => selection.anchor);
      let shouldDelChar = false;
      for (let cursorPosition of cursorPositions) {
        const indentation: number = cm.getStateAfter(cursorPosition.line).indented;
        const atSoftTab =
          cursorPosition.ch > 0 && // Not at the beginning of the line.
          cursorPosition.ch <= indentation && // All indentation before the cursor.
          cursorPosition.ch % indentUnit === 0; // At a tab spot.
        if (!atSoftTab) shouldDelChar = true;
      }

      if (shouldDelChar) {
        cm.execCommand("delCharBefore");
        return;
      }

      cm.execCommand("indentLess");
    },
    // Commits a code change
    [`${cmdKey}-Enter`]: () => {
      latestVnode.attrs.onCommandEnter?.();
      return;
    },
    // Does the same as cmd+Enter
    [`${cmdKey}-S`]: () => {
      latestVnode.attrs.onCommandEnter?.();
      return;
    },
    // "Find" plugin, only for multiline, otherwise eat the event
    [`${cmdKey}-F`]: () => {
      if (isMultiline()) {
        cm.execCommand("find");
      }
      return;
    },
    [`${cmdKey}-G`]: () => {
      if (isMultiline()) {
        cm.execCommand("findNext");
      }
      return;
    },
    [`Shift-${cmdKey}-F`]: () => {
      if (isMultiline()) {
        cm.execCommand("findPrev");
      }
      return;
    },
    [`Shift-${cmdKey}-G`]: () => {
      if (isMultiline()) {
        cm.execCommand("findPrev");
      }
      return;
    },
  };

  // This code is to make CodeMirror wrap text while preserving the indent size.
  // Also see the "word-break" in codemirror.less. Idea via:
  // https://codemirror.net/demo/indentwrap.html
  const onCodeMirrorRenderLine = (
    cm: CodeMirror.Editor,
    line: CodeMirror.LineHandle,
    el: HTMLElement
  ) => {
    const basePadding = 4;
    const tabSize = cm.getOption("tabSize") ?? codeMirrorOptions.tabSize ?? 2;
    const off = (CodeMirror.countColumn(line.text, null, tabSize) + 2) * cm.defaultCharWidth();
    el.style.textIndent = "-" + off + "px";
    el.style.paddingLeft = basePadding + off + "px";
  };

  const onpointerdown = (pointerDownEvent: PointerEvent) => {
    if (latestVnode.attrs.readOnly) return;

    nextFocusViaPointer = true;

    // Don't start a number scrub if the user is holding down a modifier key.
    // We want to be able to do the standard modifier cursor actions.
    if (
      pointerDownEvent.shiftKey ||
      pointerDownEvent.ctrlKey ||
      pointerDownEvent.altKey ||
      pointerDownEvent.metaKey
    ) {
      return;
    }
    // Only number scrub with primary button click.
    if (pointerDownEvent.pointerType === "mouse" && pointerDownEvent.button !== 0) {
      return;
    }

    const el = pointerDownEvent.target as HTMLElement;
    if (el.matches(".cm-number")) {
      startNumberScrub(cm, pointerDownEvent);
    }
  };
  const onpointerup = () => {
    nextFocusViaPointer = false;
  };

  const setCodeMirrorSelections = (selections: CodeEditorSelection[] | "all") => {
    if (selections === "all") {
      cm.execCommand("selectAll");
    } else {
      cm.setSelections(selections, undefined, { scroll: false });
    }
  };

  const updateCodeMirror = () => {
    const { value, lineAnnotations, readOnly, forceSelections, forceFocused, initialSelections } =
      latestVnode.attrs;
    if (cm) {
      if (cm.getValue() !== value) {
        cm.setValue(value);
      }
      cm.setOption("readOnly", readOnly);

      if (forceFocused) {
        cm.focus();
      }
      if (forceSelections) {
        setCodeMirrorSelections(forceSelections);
      }
    }
    if (lineAnnotations) {
      lineWidgetManager.updateWithLineAnnotations(lineAnnotations);
    }
  };

  return {
    oncreate(vnode) {
      const el = domForVnode(vnode);

      cm = cmPool.create(el);
      lineWidgetManager = new LineWidgetManager(cm);

      updateCodeMirror();

      const { initialSelections } = vnode.attrs;
      if (initialSelections) {
        cm.focus();
        setCodeMirrorSelections(initialSelections);
      }

      vnode.attrs.onCodeMirror?.(cm);

      cm.on("change", onCodeMirrorChange);
      cm.on("cursorActivity", onCodeMirrorCursorActivity);
      cm.on("focus", onCodeMirrorFocus);
      cm.on("blur", onCodeMirrorBlur);
      cm.on("renderLine", onCodeMirrorRenderLine);
      cm.on(NUMBER_SCRUB_EVENT_NAME, onScrub);
      cm.addKeyMap(codeMirrorKeyMap);

      if (vnode.attrs.codeHints) {
        const showHint = () => {
          if (latestVnode.attrs.codeHints === undefined) return;
          const hintOptions = codeMirrorHintOptions(latestVnode.attrs.codeHints());
          cm.showHint(hintOptions);
        };
        const showHintDebounced = debounce(showHint, 150);
        cm.on("keydown", function (cm: CodeMirror.Editor, event) {
          // Ctrl + space
          if (event.ctrlKey && event.keyCode === 32) {
            showHint();
          }
          // Letter keys and underscore
          else if (
            !event.ctrlKey &&
            !event.metaKey &&
            (event.key === "_" || (event.keyCode >= 65 && event.keyCode <= 90))
          ) {
            showHintDebounced();
          }
        });
      }

      resizeObserver.observe(el);
    },
    onupdate() {
      updateCodeMirror();
    },
    onremove() {
      // Important: we need to clean up `cm` before it can be reused.
      cm.off("change", onCodeMirrorChange);
      cm.off("cursorActivity", onCodeMirrorCursorActivity);
      cm.off("focus", onCodeMirrorFocus);
      cm.off("blur", onCodeMirrorBlur);
      cm.off("renderLine", onCodeMirrorRenderLine);
      cm.off(NUMBER_SCRUB_EVENT_NAME, onScrub);
      cm.removeKeyMap(codeMirrorKeyMap);
      lineWidgetManager.disposeAll();
      cmPool.dispose(cm);
      resizeObserver.disconnect();
      if (queuedRefresh) cancelAnimationFrame(queuedRefresh);
    },
    view(vnode) {
      latestVnode = vnode;

      const { readOnly } = latestVnode.attrs;

      return m(".code-editor", {
        className: classNames({
          readOnly,
        }),
        onpointerdown,
        onpointerup,
      });
    },
  };
};
