import { AffineMatrix, Geometry, Vec } from "../../geom";
import { globalState } from "../../global-state";
import { CodeComponentFocus, ComponentFocus } from "../../model/focus";
import { Node } from "../../model/node";
import { SelectableNode } from "../../model/selectable";
import { Selection } from "../../model/selection";
import { contextMatrixForNode, transformedGraphicForNode } from "../../model/transform-utils";
import { isPointerEventDoubleClick } from "../../shared/util";
import { paintGeometryToCanvas } from "../canvas-geometry";
import { SnappingGeometry } from "../snapping";
import styleConstants from "../style-constants";
import { CanvasInterfaceElement } from "./canvas-interface-element";
import { onDoubleClickNode } from "./geometry-interface";

export class LoggedGeometryUI implements CanvasInterfaceElement {
  selectable: SelectableNode | undefined;
  geometry: SnappingGeometry[] | undefined;
  isSelected: boolean;
  isHidden: boolean;
  isForceDisplaySelected: boolean;

  constructor(
    geometry?: SnappingGeometry[],
    selectable?: SelectableNode,
    isHidden = false,
    isForceDisplaySelected = false
  ) {
    this.geometry = geometry;
    this.selectable = selectable;
    this.isSelected = Boolean(
      selectable && globalState.project.selection.isNodeInderectlySelected(selectable.node)
    );
    this.isForceDisplaySelected = isForceDisplaySelected;
    this.isHidden = isHidden;
  }

  isValid() {
    return this.geometry !== undefined;
  }

  snappingGeometry(isSource: boolean) {
    return this.geometry;
  }

  renderCanvas(viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) {
    if (!this.geometry) return;

    const isHovered =
      !this.isForceDisplaySelected &&
      this.selectable &&
      globalState.project.isItemHovered(this.selectable);
    const isSelected = this.isSelected || this.isForceDisplaySelected;
    const isSelectable = this.selectable !== undefined;

    for (let snappingGeom of this.geometry) {
      const strokeWidth = isHovered || isSelected ? 2.0 : 1.5;
      const color = isSelectable ? styleConstants.red47Alpha60 : styleConstants.red47Alpha40;
      for (let geom of snappingGeom.geometry) {
        paintGeometryToCanvas(geom, color, strokeWidth, isSelected, false, viewMatrix, ctx);
      }
    }
  }

  selectables() {
    if (!this.selectable) return [];
    return [this.selectable];
  }

  cursor() {
    return "select";
  }

  hitTest(worldPosition: Vec, pixelsPerUnit: number) {
    if (!this.selectable) return;
    if (!this.geometry) return;
    if (this.selectable.node.isLocked()) return;
    let minDistanceSq = Infinity;
    let position: Vec | undefined;
    for (let { geometry } of this.geometry) {
      for (let geom of geometry) {
        const pos = geom.closestPoint(worldPosition)?.position;
        if (pos) {
          const dsq = worldPosition.distanceSquared(pos);
          if (dsq < minDistanceSq) {
            minDistanceSq = dsq;
            position = pos;
          }
        }
      }
    }
    if (!position) return undefined;
    return {
      distance: worldPosition.distance(position),
      isLowPriority: !this.isSelected,
    };
  }

  onPointerDown(event: PointerEvent) {
    const { selectable } = this;
    if (selectable instanceof SelectableNode) {
      if (isPointerEventDoubleClick(event)) {
        onDoubleClickNode(selectable.node, event);
      }
    }
  }
}

export class GuidesUI implements CanvasInterfaceElement {
  children: CanvasInterfaceElement[];

  constructor(interactables?: Selection) {
    this.children = [];

    const { focus } = globalState.project;

    // Component
    if (focus instanceof ComponentFocus || focus instanceof CodeComponentFocus) {
      const trace = globalState.traceForComponent(focus.component);
      if (trace) {
        const geometry: (Geometry | Vec)[] = [];
        for (let loggedGeom of trace.loggedGeometry()) {
          geometry.push(loggedGeom.geometry.clone());
        }
        if (geometry.length > 0) {
          const snappingGeom = new SnappingGeometry("", geometry);
          this.children.push(new LoggedGeometryUI([snappingGeom], undefined, false, true));
        }
      }
    }

    // Component Children
    if (focus instanceof ComponentFocus) {
      const geometry: SnappingGeometry[] = [];

      const topNode = globalState.project.topNode();
      if (!topNode) return;

      const focusedNode = focus.node;
      const selection = globalState.project.selection.allNodesAndInstancesToShow();

      const addGeometryForNode = (outGeometry: SnappingGeometry[], node: Node) => {
        if (!node.isVisible()) return;

        const isSelected = selection.isNodeInderectlySelected(node);

        let outGeom = outGeometry;

        // If this node is interactive it'll need its own LoggedGeometryUI with a
        // selectable.
        const isInteractive = interactables?.isNodeDirectlySelected(node);
        if (isInteractive) {
          outGeom = [];
        }

        const { guidesDisplay } = node.source;

        if (guidesDisplay === "show-all-as-guides") {
          // Add the result graphic as a guide
          const graphic = transformedGraphicForNode(node);
          if (graphic) {
            outGeom.push(new SnappingGeometry("", graphic));
          }
        }

        if (guidesDisplay !== "hide") {
          const trace = globalState.traceForNode(node);
          if (trace) {
            const contextMatrix = contextMatrixForNode(node);
            for (const instanceTrace of trace.instanceTraces()) {
              if (instanceTrace.source.definition.isShowGuides) {
                for (const loggedGeom of instanceTrace.loggedGeometry()) {
                  if (isSelected || loggedGeom.logLevel === "guide") {
                    const contextGeom = loggedGeom.geometry.clone().affineTransform(contextMatrix);
                    outGeom.push(new SnappingGeometry("", contextGeom));
                  }
                }
              }
            }
          }
        }

        if (
          (guidesDisplay !== "hide" && node.source.base.definition.isShowGuides) ||
          // To make guides visible inside of the focused node, regardless of the
          // focused node's `isShowNodeGuides` status, we recurse into every node up
          // to and including the focused node. This ensures that the geometry
          // will be added for the focused node's childen.
          node.equalsNode(focusedNode) ||
          node.isAncestorOfNode(focusedNode)
        ) {
          for (let childNode of node.childNodes()) {
            addGeometryForNode(outGeom, childNode);
          }
        }

        if (isInteractive && outGeom.length > 0) {
          const isHidden = isSelected && node.source.guidesDisplay === "hide";
          this.children.push(new LoggedGeometryUI(outGeom, new SelectableNode(node), isHidden));
        }
      };

      addGeometryForNode(geometry, topNode);

      if (geometry.length > 0) {
        // Insert the UI for all non interactive geometry underneath the others.
        this.children.unshift(new LoggedGeometryUI(geometry));
      }
    }
  }
}
