import m from "mithril";

import { Node } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import SuperExpressive from "super-expressive";

import { globalState } from "../../global-state";
import { Card } from "../../shared/card";
import { canonicalOrigin } from "../../shared/config";
import { projectCardState } from "../../shared/projects-card-state";
import { initDragHandle } from "./prosemirror-drag-handle";

// Matches Cuttle project url and captures the project id. Eg:
// https://cuttle.xyz/@forresto/An-aperiodic-monotile-BgC9jiVONijH
// prettier-ignore
const cuttleProjectRegExp = SuperExpressive()
  .startOfInput
  .string(canonicalOrigin)
  .string("/@")
  .oneOrMore
  .anyChar
  .char("-")
  .namedCapture("id")
    .oneOrMore
    .word
  .end()
  .toRegex()

export const cuttleProjectIdForURL = (url: string) => {
  const result = cuttleProjectRegExp.exec(url.trim());
  if (!result || !result.groups) return undefined;
  return result.groups.id;
};

export interface CuttleProjectCardOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    cuttleProjectCard: {
      /**
       * Add a video player custom element (YouTube only for now)
       */
      insertCuttleProjectCard: (options: { url: string }) => ReturnType;
    };
  }
}

export const CuttleProjectCardNode = Node.create<CuttleProjectCardOptions>({
  name: "cuttleProjectCard",
  content: "",
  marks: "",
  group: "block",

  // Set with `extend` in DocEditor, if it is editable
  selectable: false,
  draggable: false,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  addAttributes() {
    return {
      cuttleprojectid: {
        default: null,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "figure",
        getAttrs: (el: globalThis.Node | string) => {
          if (typeof el === "string") {
            return false;
          }
          if (!(el instanceof HTMLElement)) {
            return false;
          }
          const cuttleprojectid = el.getAttribute("data-cuttle-project-id");
          if (!cuttleprojectid) return false;
          return {
            cuttleprojectid,
          };
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["figure", { ["data-cuttle-project-id"]: HTMLAttributes.cuttleprojectid }];
  },
  renderText({ node }) {
    return `[Cuttle project card embed: ${node.attrs.cuttleprojectid}]\n\n`;
  },
  addNodeView() {
    // NodeViewRenderer returns a ProseMirror NodeView. Reference:
    // https://prosemirror.net/docs/ref/#view.NodeView
    return (nodeViewRendererProps) => {
      const { node } = nodeViewRendererProps;

      const dom = document.createElement("figure");
      dom.setAttribute("data-cuttle-project-id", node.attrs.cuttleprojectid);
      mountProjectCard(dom, node.attrs.cuttleprojectid);

      const dragHandleNodeViewOptions = initDragHandle(dom, nodeViewRendererProps);

      return {
        dom,
        ...dragHandleNodeViewOptions,
        destroy() {
          // Call the drag handle destroy function since we are also overriding.
          dragHandleNodeViewOptions.destroy();
          unmountProjectCard(dom);
        },
      };
    };
  },
  addCommands() {
    return {
      insertCuttleProjectCard:
        (options) =>
        ({ chain, editor }) => {
          const { url } = options;
          const cuttleprojectid = cuttleProjectIdForURL(url);
          if (cuttleprojectid) {
            const { selection } = editor.state;
            const pos = selection.$head;

            return chain()
              .insertContentAt(pos.before(), [
                {
                  type: this.name,
                  attrs: { cuttleprojectid },
                },
              ])
              .run();
          }
          return false;
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("handlePasteCuttleProjectURL"),
        props: {
          handlePaste: (view, _event, slice) => {
            // Only look at one-line paste content
            if (slice.content.childCount !== 1) return false;

            const { state } = view;
            const { selection } = state;
            const { empty } = selection;

            // Pass through if something is selected
            if (!empty) return false;

            const pos = selection.$head;
            const node = pos.node();

            // Only continue if pasting on empty line
            if (node.content.size > 0) return false;

            let textContent = "";

            slice.content.forEach((node) => {
              textContent += node.textContent;
            });

            const cuttleprojectid = cuttleProjectIdForURL(textContent);

            if (!cuttleprojectid) return false;

            this.editor
              .chain()
              .insertContentAt(pos.before(), [
                {
                  type: this.name,
                  attrs: { cuttleprojectid },
                },
              ])
              .run();

            return true;
          },
        },
      }),
    ];
  },
});

function mountProjectCard(el: HTMLElement, projectId: string): void {
  m.mount(el, {
    view: function () {
      const project = projectCardState.getProject(projectId); // Will be "loading" or a ProjectCardData
      if (project === "loading") {
        return m("figcaption.loading-message", "Loading project info...");
      }
      return m(Card, { project, openLinksInNewTab: globalState.isEditingMode() });
    },
  });
}

export function unmountProjectCard(el: HTMLElement): void {
  m.mount(el, null);
}
