export type EnvSubscriber = (event: "get" | "set", key: string, value: unknown) => void;

export class Env {
  readonly parent: Env | undefined;
  private data: { [key: string]: unknown } = {};

  private subscribers: Set<EnvSubscriber> = new Set();

  constructor(parent?: Env) {
    this.parent = parent;
  }
  get(key: string): unknown {
    let value: unknown;
    if (this.data.hasOwnProperty(key)) {
      value = this.data[key];
    } else if (this.parent) {
      value = this.parent.get(key);
    } else {
      // TODO: Could be a throw?
      value = undefined;
    }
    this.subscribers.forEach((subscriber) => subscriber("get", key, value));
    return value;
  }
  has(key: string): boolean {
    if (this.data.hasOwnProperty(key)) {
      return true;
    } else if (this.parent) {
      return this.parent.has(key);
    }
    return false;
  }
  peek(key: string): unknown {
    if (this.data.hasOwnProperty(key)) {
      return this.data[key];
    } else if (this.parent) {
      return this.parent.peek(key);
    } else {
      // TODO: Could be a throw?
      return undefined;
    }
  }
  set(key: string, value: any) {
    this.data[key] = value;
    this.subscribers.forEach((subscriber) => subscriber("set", key, value));
    return this; // For chaining
  }
  setAll(data: { [key: string]: any }) {
    for (let key of Object.keys(data)) {
      this.set(key, data[key]);
    }
    return this;
  }

  // For debugging.
  peekAll() {
    return this.data;
  }

  subscribe(subscriber: EnvSubscriber) {
    this.subscribers.add(subscriber);
  }
  unsubscribe(subscriber: EnvSubscriber) {
    this.subscribers.delete(subscriber);
  }
}
