import m from "mithril";

import { GeneratedSnapshotExampleData } from "../examples";
import { basicShapesConfig, examplesConfig } from "../examples-config";
import {
  EmojiExampleShape,
  ExampleCategory,
  ExampleShape,
  ExampleShapeDisplay,
  SnapshotExampleShape,
  nameForExampleShape,
} from "../examples-types";
import { nonNull } from "../geom";
import { Vec } from "../geom/math/vec";
import { globalState } from "../global-state";
import { CodeComponent, Component } from "../model/component";
import { DependencyGraph } from "../model/dependency-graph";
import { SelectableComponentParameter } from "../model/selectable";
import { handlePositionInfoForParameter } from "../model/transform-utils";
import { accountState } from "../shared/account";
import { canonicalAssetsOrigin } from "../shared/config";
import { EditableText } from "../shared/editable-text";
import { Icon16, Icon20, IconButton, IconSVG } from "../shared/icon";
import { logEvent } from "../shared/log-event";
import {
  MenuItem,
  Tooltipped,
  createPopup,
  createPopupMenu,
  createRightClickPopupMenu,
} from "../shared/popup";
import { classNames, domForVnode, isPointerEventRightClick } from "../shared/util";
import {
  PAGE_ALL,
  PAGE_CATEGORY,
  PAGE_PROJECT,
  QueryResultsNone,
  creationPanelState,
} from "./components-query";
import { DefinitionDrag, GutsDrag, startDefinitionDrag } from "./definition-drag";
import { DocumentationView, SettingsView } from "./project-section";
import styleConstants from "./style-constants";
import { Thumbnail } from "./thumbnail";
import { toastState } from "./toast-message";
import { copyComponent } from "./top-menu-clipboard";
import { checkUserHasProFeature, ProFeatureTag } from "../shared/feature-check";

const createComponent = () => {
  const project = globalState.project;
  const component = project.createComponent();
  project.focusItem(component);
  globalState.autoSelectForRename = component;
};

const createCodeComponent = () => {
  const project = globalState.project;
  const codeComponent = project.createCodeComponent();
  project.focusItem(codeComponent);
  globalState.autoSelectForRename = codeComponent;
};

const componentTypesMenuItems = [
  { label: "New Component", action: createComponent },
  { label: "New Code Component 🧪", action: createCodeComponent },
];

const ComponentTypesMenu: m.Component<{}> = {
  view(vnode) {
    const onpointerdown = () => {
      createPopupMenu({
        menuItems: componentTypesMenuItems,
        spawnFrom: domForVnode(vnode),
        placement: "bottom-end",
      });
    };
    return m(IconButton, {
      icon: "chevron_down",
      className: "new-component-extra",
      onpointerdown,
    });
  },
};

export const Components: m.Component<{}> = {
  oncreate(vnode) {
    globalState.ensureExamplesData();
  },
  view(vnode) {
    const { query, category } = creationPanelState;

    const onInputQuery = (event: Event) => {
      const q = (event.target as HTMLInputElement).value;
      creationPanelState.setQuery(q);
    };

    const onClickShowMore = () => {
      creationPanelState.goTo(PAGE_ALL);
      accountState.storageSet("hasOpenedCreationPanel", true);
    };

    const onClickBack = () => {
      creationPanelState.goBack();
    };

    const disableMoreAndSearch = !(globalState.project.focusedComponent() instanceof Component);
    const enableCreationPanelAdvertisement =
      !disableMoreAndSearch && !accountState.storageGet("hasOpenedCreationPanel");

    return m(
      ".components",
      {
        className: classNames({
          "creation-panel-advertise": enableCreationPanelAdvertisement,
        }),
      },
      [
        m(
          "label.creation-panel-search",
          {
            "aria-label": "Search",
            className: classNames({ disabled: disableMoreAndSearch }),
          },
          [
            m(Icon20, { icon: "search" }),
            m("input", {
              type: "text",
              oninput: onInputQuery,
              value: query,
              placeholder: "Search",
              disabled: disableMoreAndSearch,
            }),
          ]
        ),
        m(
          ".creation-panel",
          m(
            ".creation-panel-pages",
            {
              style: {
                transform: `translateX(${creationPanelState.index * -199}px)`,
              },
            },
            [
              m(
                ".creation-panel-page",
                {
                  inert: creationPanelState.index !== PAGE_PROJECT,
                },
                [
                  m(
                    "button.creation-panel-browse-all",
                    {
                      onclick: onClickShowMore,
                      disabled: disableMoreAndSearch,
                      className: classNames({ disabled: disableMoreAndSearch }),
                    },
                    [
                      m(".icon20"),
                      m(".label", "Browse All Shapes"),
                      m(Icon20, { icon: "chevron_right" }),
                    ]
                  ),
                  m(".query-results.scrollable", [
                    m(ProjectPage, {
                      disableBuiltIns: disableMoreAndSearch,
                    }),
                  ]),
                ]
              ),
              m(
                ".creation-panel-page",
                {
                  inert: creationPanelState.index !== PAGE_ALL,
                },
                [
                  m(
                    "button",
                    {
                      "aria-label": "Back",
                      onclick: onClickBack,
                    },
                    [
                      m(Icon20, { icon: "chevron_left" }),
                      m(".label", query === "" ? "All Shapes" : "Search Results"),
                      m(".icon20"),
                    ]
                  ),
                  query === "" ? m(BrowsePage) : m(SearchResultsPage, { query }),
                ]
              ),
              category &&
                m(
                  ".creation-panel-page",
                  {
                    inert: creationPanelState.index !== PAGE_CATEGORY,
                  },
                  [
                    m(
                      "button",
                      {
                        "aria-label": "Back",
                        onclick: onClickBack,
                      },
                      [
                        m(Icon20, { icon: "chevron_left" }),
                        m(".label", category.category),
                        m(".icon20"),
                      ]
                    ),
                    m(CategoryPage, { category }),
                  ]
                ),
            ]
          )
        ),
      ]
    );
  },
};

interface ProjectPageAttrs {
  disableBuiltIns: boolean;
}
const ProjectPage: m.Component<ProjectPageAttrs> = {
  view(vnode) {
    const { disableBuiltIns } = vnode.attrs;

    return [
      m(
        ".component-category.basic-shapes",
        {
          className: classNames({ disabled: disableBuiltIns }),
        },
        [
          m(CategoryExamplesView, {
            category: basicShapesConfig,
            shapes: basicShapesConfig.shapes,
          }),
        ]
      ),
      m(
        Category,
        {
          title: "Project",
        },
        [
          m(DocumentationView, {
            project: globalState.project,
            documentation: globalState.project.documentation,
          }),
          m(SettingsView, {
            project: globalState.project,
            settings: globalState.project.settings,
          }),
        ]
      ),
      m(ProjectComponents),
    ];
  },
};

const BrowsePage: m.Component = {
  view() {
    if (!globalState.examplesData) {
      return m(ExampleDataLoading);
    }
    return m(
      ".query-results.scrollable",
      examplesConfig.map((category) => {
        const { shapes, featuredShapes } = category;
        return m(QueryResultsCategory, {
          category,
          shapes: nonNull(
            featuredShapes.map((name) =>
              shapes.find((shape) => nameForExampleShape(shape) === name)
            )
          ),
          hasMore: category.shapes.length > category.featuredShapes.length,
        });
      })
    );
  },
};

const ExampleDataLoading: m.Component = {
  view() {
    return m(".query-loading", [m(".loading-indicator"), m("span", "Loading")]);
  },
};

interface CategoryPageAttrs {
  category: ExampleCategory;
}
const CategoryPage: m.Component<CategoryPageAttrs> = {
  view({ attrs: { category } }) {
    const display = category.displayMore ?? category.display;
    return m(
      ".query-results.scrollable",
      m(".query-category", [
        m(
          ".query-category-components",
          { className: display },
          m(CategoryExamplesView, {
            category,
            shapes: category.shapes,
            overrideDisplay: display,
          })
        ),
      ])
    );
  },
};

interface SearchResultsPageAttrs {
  query: string;
}
const SearchResultsPage: m.Component<SearchResultsPageAttrs> = {
  view({ attrs: { query } }) {
    const { results } = creationPanelState;
    if (results.length === 0) {
      return m(QueryResultsNone, { query });
    }
    if (!globalState.examplesData) {
      return m(ExampleDataLoading);
    }
    return m(
      ".query-results.scrollable",
      results.map(({ category, results }) => {
        return m(QueryResultsCategory, { category, shapes: results });
      })
    );
  },
};

interface QueryResultsCategoryAttrs {
  category: ExampleCategory;
  shapes: ExampleShape[];
  hasMore?: boolean;
}
const QueryResultsCategory: m.Component<QueryResultsCategoryAttrs> = {
  view({ attrs: { category, shapes, hasMore } }) {
    if (shapes.length === 0) return;
    return m(".query-category.has-category-heading", [
      hasMore
        ? m(
            "button.query-category-title",
            {
              onclick: () => creationPanelState.setCategory(category),
            },
            [
              m(".label", category.category),
              m(".more", ["More", m(Icon20, { icon: "chevron_right" })]),
            ]
          )
        : m(".query-category-title", category.category),
      m(
        ".query-category-components",
        { className: category.display },
        m(CategoryExamplesView, { category, shapes })
      ),
    ]);
  },
};

const ProjectComponents: m.Component = {
  view() {
    const projectComponents = globalState.project.components;
    return m(Category, { title: "Components" }, [
      projectComponents.map((component, index) => {
        // Components brought in via creation panel examples are marked immutable
        // so that they don't show up in the project. They are removed from the
        // project when the last instance is removed. For reordering, we still
        // want the insertionIndex to be the project.components ordering.
        if (component.isImmutable) return;
        return [
          m(ComponentReorderHitTarget, { insertionIndex: index }),
          m(ComponentView, { definition: component, display: "one-up" }),
        ];
      }),
      m(ComponentReorderHitTarget, { insertionIndex: projectComponents.length }),
      m(".new-component", [
        m(Tooltipped, { className: "new-component-button", message: () => "New Component" }, [
          m(IconButton, {
            icon: "plus",
            onpointerdown: createComponent,
          }),
        ]),
        m(ComponentTypesMenu),
      ]),
    ]);
  },
};

interface CategoryAttrs {
  title: string;
}
const Category: m.ClosureComponent<CategoryAttrs> = (initialVnode) => {
  return {
    view({ children, attrs: { title } }) {
      return m(".component-category", [
        m(".component-category-header", m(".component-category-title", title)),
        m(".component-category-components", children),
      ]);
    },
  };
};

interface CategoryExamplesViewAttrs {
  category: ExampleCategory;
  shapes: ExampleShape[];
  overrideDisplay?: ExampleShapeDisplay;
}
const CategoryExamplesView: m.Component<CategoryExamplesViewAttrs> = {
  view({ attrs: { category, shapes, overrideDisplay } }) {
    const display = overrideDisplay ?? category.display;
    return shapes.map((shape) => {
      if (shape.type === "builtin") {
        return m(ComponentView, { definition: shape.definition, display });
      } else if (shape.type === "component" || shape.type === "guts") {
        return m(GutsView, { shape, display });
      } else if (shape.type === "emoji") {
        return m(EmojiView, { shape });
      } else if (shape.type === "cuttle-symbol") {
        return m(CuttleSymbolView, { assetPath: shape.path, name: shape.name, alt: shape.name });
      }
    });
  },
};

interface GutsViewAttrs {
  shape: SnapshotExampleShape;
  display: ExampleShapeDisplay;
}
const GutsView: m.Component<GutsViewAttrs> = {
  view(vnode) {
    const {
      attrs: { shape, display },
    } = vnode;

    const data = globalState.examplesData?.exampleDataWithName(
      shape.name
    ) as GeneratedSnapshotExampleData;
    if (!data) return;

    const onpointerdown = (downEvent: PointerEvent) => {
      const definitionDrag = new GutsDrag(data.snapshot, Boolean(shape.isAutoScale));
      startDefinitionDrag(downEvent, {
        definitionDrag,
        onCancel: () => {
          showDragOntoCanvasHintPopup(vnode);
        },
      });
      logEvent("create shape", { name: shape.name });
    };
    const mThumbnail = m(".thumbnail", m.trust(data.thumbnail));
    const mChildren =
      display === "one-up" || display === "one-up-compact"
        ? [m(".component-thumbnail", mThumbnail), m(".component-name", shape.name)]
        : m(
            ".component-thumbnail",
            m(
              Tooltipped,
              { message: () => shape.name, placement: "bottom-start", offsetH: 8, offsetV: 6 },
              mThumbnail
            )
          );
    return m(".component.example", { onpointerdown }, mChildren);
  },
};

interface EmojiViewAttrs {
  shape: EmojiExampleShape;
}
const EmojiView: m.Component<EmojiViewAttrs> = {
  view(vnode) {
    const {
      attrs: { shape },
    } = vnode;
    return m(CuttleSymbolView, {
      assetPath: `/noto-emoji-600/${shape.unicodeId}`,
      name: shape.name,
      alt: shape.emoji,
    });
  },
};

interface CuttleSymbolViewAttrs {
  assetPath: string;
  name: string;
  alt: string;
}
const CuttleSymbolView: m.Component<CuttleSymbolViewAttrs> = {
  view(vnode) {
    const {
      attrs: { assetPath, name, alt },
    } = vnode;
    const svgPath = `${canonicalAssetsOrigin}${assetPath}.svg`;
    const onpointerdown = (downEvent: PointerEvent) => {
      const snapshotString = `{"app":"Cuttle","type":"PortableProjectData","version":36,"payload":{"@class":"PortableProjectData","@id":"0","elements":[{"@id":"1","@class":"Element","name":"${name}","base":{"@id":"2","@class":"Instance","definition":{"@builtin":"EmojiDefinition"},"args":{"@id":"3","emoji":{"@id":"4","@class":"Expression","jsCode":"\\"${svgPath}\\"","editingJsCode":null}},"isEnabled":true,"repeatIndexVariableName":null},"transform":{"@id":"5","@class":"Instance","definition":{"@builtin":"TransformDefinition"},"args":{"@id":"6","position":{"@id":"7","@class":"Expression","jsCode":"Vec(0.00, 0.00)","editingJsCode":null}},"isEnabled":true,"repeatIndexVariableName":null},"modifiers":[],"fill":{"@id":"8","@class":"Instance","definition":{"@builtin":"FillDefinition"},"args":{"@id":"9"},"isEnabled":true,"repeatIndexVariableName":null},"stroke":null,"children":[],"isLocked":false,"isVisible":true,"guidesDisplay":"show"}],"instances":[],"components":[],"modifiers":[],"componentParameters":[],"projectParameters":[]}}`;
      const definitionDrag = new GutsDrag(snapshotString, true);
      startDefinitionDrag(downEvent, {
        definitionDrag,
        onCancel: () => {
          showDragOntoCanvasHintPopup(vnode);
        },
      });
      logEvent("create shape", { name });
    };
    const thumbnailSize = thumbnailSizesByDisplay["four-up"];
    return m(
      ".component.example.emoji.four-up",
      { onpointerdown, "aria-label": alt },
      m(
        Tooltipped,
        { message: () => name, placement: "bottom-start" },
        m("img", {
          loading: "lazy",
          src: svgPath,
          key: assetPath,
          alt,
          width: thumbnailSize.x,
          height: thumbnailSize.y,
        })
      )
    );
  },
};

interface ComponentViewAttrs {
  definition: Component | CodeComponent;
  display: ExampleShapeDisplay;
}
interface ComponentViewState {
  isHovered?: boolean;
}
const ComponentView: m.Component<ComponentViewAttrs, ComponentViewState> = {
  view(vnode) {
    const { definition, display } = vnode.attrs;
    const isPro = definition.isPro;

    const rename = (newName: string) => {
      globalState.project.renameDefinition(definition, newName);
    };

    const menuItems: MenuItem[] = [];
    let mExtra: m.Vnode | null = null;
    if (
      !definition.isImmutable &&
      (definition instanceof Component || definition instanceof CodeComponent)
    ) {
      menuItems.push(
        {
          label: "Copy Component",
          action: () => {
            copyComponent(definition);
          },
        },
        {
          label: "Duplicate Component",
          action: () => {
            globalState.project.duplicateComponent(definition);
          },
        },
        {
          label: "Delete Component",
          action: () => {
            const graph = new DependencyGraph(globalState.project);
            const paths = graph.dependentPathsForSource(definition);
            if (paths.length > 0) {
              toastState.showBasic({
                type: "error",
                message: [
                  "Can't delete this component because it's being used in ",
                  m("strong", paths[0].join(" ⏵ ")),
                  paths.length > 1 && [" and ", paths.length - 1, " others."],
                  ".",
                ],
                nextStep: "Remove this component from the rest of the project before deleting it.",
              });
            } else {
              globalState.project.removeComponent(definition);
            }
          },
        },
        {
          label: "Rename Component",
          action: () => {
            globalState.autoSelectForRename = definition;
          },
        }
      );
      const onpointerdown = (event: PointerEvent) => {
        event.stopPropagation();
        const spawnFrom = domForVnode(vnode);
        createPopupMenu({
          menuItems,
          spawnFrom,
          placement: "right-start",
        });
      };
      mExtra = m(".extra", [
        m(IconButton, { onpointerdown, icon: "dotdotdot", className: "extra-hidden" }),
      ]);
    }

    const dragging =
      globalState.definitionDrag instanceof DefinitionDrag &&
      globalState.definitionDrag.definition === definition;
    const definitionDragMessage = dragging && globalState.definitionDrag?.message;

    const focused = globalState.project.focusedComponent() === definition;

    const className = classNames({
      dragging,
      focused,
      message: Boolean(definitionDragMessage),
    });

    const onpointerdown = (downEvent: PointerEvent) => {
      if (isPointerEventRightClick(downEvent)) {
        if (menuItems.length > 0) {
          createRightClickPopupMenu(downEvent, menuItems);
        }
        return;
      }
      // Will need to be configurable if we add pro components with different
      // feature types.
      if (isPro && !checkUserHasProFeature("pro-fonts")) {
        return;
      }
      const definitionDrag = new DefinitionDrag(definition);
      startDefinitionDrag(downEvent, {
        definitionDrag,
        onUp: () => {
          if (!definition.isImmutable) {
            let newIndex = definitionDrag.hoveredInsertionIndex;
            if (newIndex !== undefined) {
              const oldIndex = globalState.project.components.indexOf(definition);
              globalState.project.components.splice(oldIndex, 1);
              if (oldIndex < newIndex) newIndex -= 1;
              globalState.project.components.splice(newIndex, 0, definition);
            }
          }
        },
        onCancel: () => {
          if (!definition.isImmutable) {
            globalState.project.focusItem(definition);
          } else {
            showDragOntoCanvasHintPopup(vnode);
          }
        },
      });
      if (definition.isImmutable) {
        logEvent("create shape", { name: definition.name });
      }
    };

    const onpointerenter = (event: PointerEvent) => {
      this.isHovered = true;
    };
    const onpointerleave = (event: PointerEvent) => {
      this.isHovered = false;
    };

    // Note that for Text and Emoji, this loads external resources (font, svg)
    // to render the icon. This is OK, because we want the shape to be available
    // before we start dragging the component to the canvas.
    const instanceTrace = globalState.tracesByDefinition.get(definition);
    const graphic = instanceTrace?.result;

    const parameterPoints: Vec[] = [];
    if (instanceTrace) {
      for (let parameter of definition.allParameters()) {
        if (parameter.hidden) continue;
        const selectable = new SelectableComponentParameter(definition, parameter);
        const info = handlePositionInfoForParameter(selectable);
        if (info) {
          parameterPoints.push(info.handlePosition);
        }
      }
    }

    let mComponentName: m.Child;
    let maybeWrapTooltip = (children: m.Child) => m("div", children);
    const showName = display === "one-up" || display === "one-up-compact";
    if (showName) {
      if (definition.isImmutable) {
        mComponentName = definition.name;
      } else {
        let autoSelect = false;
        if (globalState.autoSelectForRename === definition) {
          autoSelect = true;
          globalState.autoSelectForRename = undefined;
        }
        mComponentName = m(EditableText, { value: definition.name, onchange: rename, autoSelect });
      }
    } else {
      maybeWrapTooltip = (children: m.Child) =>
        m(Tooltipped, { message: () => definition.name, placement: "bottom-start" }, children);
    }

    let mMessage: m.Child;
    if (definitionDragMessage) {
      mMessage = m(".component-message.tooltip", definitionDragMessage);
    }

    const isEditableCodeComponent = definition instanceof CodeComponent && !definition.isImmutable;

    const thumbnailSize = thumbnailSizesByDisplay[display];

    return m(".component", { className, onpointerdown, onpointerenter, onpointerleave }, [
      m(
        ".component-thumbnail",
        maybeWrapTooltip(
          definition.icon
            ? m(
                ".thumbnail-icon",
                { className: classNames({ hover: this.isHovered }) },
                m(IconSVG, { icon: definition.icon })
              )
            : m(Thumbnail, {
                graphic,
                width: thumbnailSize.x,
                height: thumbnailSize.y,
                padding: 2,
                parameterPoints,
                isHovered: this.isHovered,
                isFocused: focused,
                isBuiltin: definition.isImmutable,
                fillColor: display === "two-up" ? styleConstants.gray50 : undefined,
              })
        )
      ),
      isEditableCodeComponent &&
        m(".code-component-badge", m(Icon16, { icon: "code_component_badge" })),
      showName && m(".component-name", mComponentName),
      isPro && m(ProFeatureTag),
      mExtra,
      mMessage,
    ]);
  },
};

const thumbnailSizesByDisplay: Record<ExampleShapeDisplay, Vec> = {
  "four-up": new Vec(36),
  "one-up": new Vec(36),
  "one-up-compact": new Vec(36, 24),
  "two-up": new Vec(72),
};

const showDragOntoCanvasHintPopup = <Attrs, State>(vnode: m.Vnode<Attrs, State>) => {
  // Show hint "Drag into canvas"
  const createdPopup = createPopup({
    view: () => {
      return "Drag onto canvas →";
    },
    spawnFrom: domForVnode(vnode),
    placement: "right",
    className: "tooltip",
    offset: -8,
    overlay: "closeOnOutsidePointerDown",
  });
};

interface ComponentReorderHitTargetAttrs {
  insertionIndex: number;
}
const ComponentReorderHitTarget: m.Component<ComponentReorderHitTargetAttrs> = {
  view({ attrs: { insertionIndex } }) {
    if (!(globalState.definitionDrag instanceof DefinitionDrag)) return;
    const definition = globalState.definitionDrag.definition;

    if (
      !(definition instanceof Component || definition instanceof CodeComponent) ||
      definition.isImmutable
    ) {
      return;
    }

    // Don't show it if it wouldn't result in a reordering.
    const oldIndex = globalState.project.components.indexOf(definition);
    if (insertionIndex === oldIndex || insertionIndex === oldIndex + 1) {
      return;
    }

    const onpointerenter = () => {
      if (!(globalState.definitionDrag instanceof DefinitionDrag)) return;
      globalState.definitionDrag.hoveredInsertionIndex = insertionIndex;
    };
    const onpointerleave = () => {
      if (!(globalState.definitionDrag instanceof DefinitionDrag)) return;
      globalState.definitionDrag.hoveredInsertionIndex = undefined;
    };
    const className = classNames({
      hovered: globalState.definitionDrag?.hoveredInsertionIndex === insertionIndex,
    });
    return m(".component-reorder-hit-target", { className, onpointerenter, onpointerleave });
  },
};
