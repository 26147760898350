import { isArray, isObject, isString } from "../shared/util";
import { uuid } from "../shared/uuid-v4";
import { ModelObject } from "./model-object";
import { encodedForOriginal } from "./model-object-reducer";
import { builtinRegistry, classRegistry } from "./registry";

/**
 * Encodes the material subset of any Cuttle data to a JSON friendly plain JS
 * object structure.
 *
 * @param options Setting `indirectlyReferenceModelObjects: true` will force any
 * ModelObject encountered to be encoded as a reference (@ref). This is used for
 * example when encoding immaterial project data like the selection.
 */
export const encodeMaterial = (
  original: unknown,
  options?: {
    indirectlyReferenceModelObjects?: boolean;
    bypassReducerCache?: boolean;
  }
) => {
  return encodedForOriginal(
    original,
    options?.indirectlyReferenceModelObjects,
    options?.bypassReducerCache
  );
};

export const decode = (encoded: unknown, options?: { assignNewIds?: boolean }) => {
  const assignNewId = Boolean(options?.assignNewIds);

  const outputted: { [id: string]: Record<string, unknown> } = {};
  const populateOutputted = (encoded: unknown) => {
    if (isArray(encoded)) {
      encoded.forEach(populateOutputted);
    } else if (isObject(encoded)) {
      if (encoded["@ref"] || encoded["@builtin"]) return;

      const id = encoded["@id"];
      if (isString(id)) {
        const className = encoded["@class"];
        if (isString(className)) {
          const klass = classRegistry[className];
          const instance = new klass();
          if (instance instanceof ModelObject) {
            const modelObject: MutableModelObject = instance;
            // Set the ModelObject id from "@id" or assign a new id.
            modelObject.id = assignNewId ? uuid() : id;
          }
          outputted[id] = instance;
        } else {
          outputted[id] = {};
        }
      }

      Object.values(encoded).forEach(populateOutputted);
    }
  };
  populateOutputted(encoded);

  const decodeInner = (encoded: unknown): unknown => {
    if (isArray(encoded)) {
      return encoded.map(decodeInner);
    }
    if (isObject(encoded)) {
      // If it's a builtin, return the builtin.
      const builtin = encoded["@builtin"];
      if (isString(builtin)) {
        if (!builtinRegistry.hasOwnProperty(builtin)) {
          throw "Unregistered builtin: " + builtin;
        }
        return builtinRegistry[builtin];
      }

      // If it's a ref, just return the object we already made (or started
      // making in the case of a circular reference).
      const ref = encoded["@ref"];
      if (isString(ref)) {
        return outputted[ref];
      }

      // If it's a id, we'll return outputted[id] but first we need to recurse
      // on the object.
      const id = encoded["@id"];
      let result: Record<string, unknown> = {};
      if (isString(id)) {
        result = outputted[id];
      } else {
        const className = encoded["@class"];
        if (isString(className)) {
          const klass = classRegistry[className];
          result = new klass();
        }
      }

      // Set the properties on result.
      for (let key of Object.keys(encoded)) {
        if (key.startsWith("@")) continue;
        const value = encoded[key];
        result[key] = decodeInner(value);
      }
      return result;
    }
    return encoded;
  };

  return decodeInner(encoded);
};

/**
 * Utility type that casts away `readonly` and makes everything mutable. We use
 * this in very specific places where `id` needs to change. Don't use this
 * unless you know what you're doing.
 */
export type MutableModelObject = {
  -readonly [K in keyof ModelObject]: ModelObject[K];
};
