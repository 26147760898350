import m from "mithril";

import { createPopup } from "../../shared/popup";
import { DocEditorRich } from "../doc-editor/doc-editor-rich";

import { expressionCodeForRichText } from "../../model/expression-code";
import { ParameterInterface, PIText } from "../../model/parameter-interface";

import { assert } from "../../geom";
import { globalState } from "../../global-state";
import type { Instance } from "../../model/instance";
import type { Node } from "../../model/node";
import type { Parameter } from "../../model/parameter";
import { SelectableParameter } from "../../model/selectable";
import { isString } from "../../shared/util";
import { RichText } from "../../geom";

/** Shows an on-canvas text editor popup if there is an applicable parameter to
 * edit. */
export const openEditorPopupForFirstTextParameter = (node: Node, event: PointerEvent) => {
  const selectableTextParameter = selectableParameterWithInterface(node, PIText);
  if (selectableTextParameter) {
    const y = Math.min(event.clientY + 16, window.innerHeight);
    openTextParameterPopup(selectableTextParameter, event.clientX, y);
  }
};

/**
 * Looks for an instance or modifier parameter with a matching type and literal
 * value, that can be selected in the inspector for editing. For example,
 * double-clicking text on the canvas can select a parameter with the text
 * parameter interface.
 */
const selectableParameterWithInterface = (
  node: Node,
  parameterInterfaceClass: typeof ParameterInterface
) => {
  let instance: Instance | undefined;
  let parameter: Parameter | undefined;

  // Check instance parameters
  const instanceParameter =
    node.source.base.definition.firstParameterWithInterface(parameterInterfaceClass);
  if (instanceParameter) {
    instance = node.source.base;
    parameter = instanceParameter;
  } else {
    // Check modifier parameters
    for (let i = 0, len = node.source.modifiers.length; i < len; i++) {
      const modifier = node.source.modifiers[i];
      const modifierParameter =
        modifier.definition.firstParameterWithInterface(parameterInterfaceClass);
      if (modifierParameter) {
        instance = modifier;
        parameter = modifierParameter;
        break;
      }
    }
  }

  if (instance && parameter) {
    // Don't select if instance has arg that is not literal. Future: follow
    // reference to component / project parameter?
    if (
      instance.hasArgumentWithName(parameter.name) &&
      !instance.args[parameter.name].isLiteral()
    ) {
      return;
    }

    return new SelectableParameter(node, instance, parameter);
  }

  return;
};

const openTextParameterPopup = (selectable: SelectableParameter, x: number, y: number) => {
  if (globalState.isCanvasTextParameterOpen) return;

  const initialValue = selectable.expression().literalValue();
  if (!(isString(initialValue) || initialValue instanceof RichText)) return;

  const onchange = (changedValue: RichText) => {
    const expression = selectable.ensureEditableExpression();
    expression.jsCode = expressionCodeForRichText(changedValue);
  };
  const onclose = () => {
    globalState.isCanvasTextParameterOpen = false;
  };

  globalState.isCanvasTextParameterOpen = true;

  const createdPopup = createPopup({
    view: () => {
      function closePopup() {
        createdPopup.close();
        m.redraw();
      }

      // On undo, the selectable becomes invalid. Close the popup.
      if (!globalState.project.selectableExistsAndIsValid(selectable)) {
        closePopup();
        return;
      }

      let value = selectable.expression().literalValue();
      if (!(value instanceof RichText || isString(value))) {
        closePopup();
        return;
      }

      return m(DocEditorRich, {
        value,
        editable: true,
        onchange,
        autoselect: true,
      });
    },
    spawnFrom: { x, y },
    className: "canvas-text-parameter-popup",
    // The popup style class has min-width, which is what the `placement` logic
    // will measure (the text editor isn't ready when popup measures the div).
    placement: "bottom-start",
    offsetV: 8,
    onclose,
  });
};
