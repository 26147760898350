import m from "mithril";

import { GettingStartedState } from "../model/getting-started-state";
import { preloadManager } from "../preload-manager";
import { accountState } from "../shared/account";
import { PlayButtonOverlayed } from "../shared/play-button-overlayed";
import { classNames, domForVnode } from "../shared/util";
import { globalState } from "../global-state";
import { toastState } from "./toast-message";
import { Icon20 } from "../shared/icon";

interface GettingStartedModalAttrs {
  gettingStartedState: GettingStartedState;
  isSmallWindow: boolean;
}
export const GettingStartedModal: m.Component<GettingStartedModalAttrs> = {
  view: ({ attrs: { gettingStartedState, isSmallWindow } }) => {
    const mode = gettingStartedState.mode;

    return m(
      ".getting-started",
      mode === "watch"
        ? m(Lesson, { gettingStartedState })
        : m(Try, { gettingStartedState, isSmallWindow })
    );
  },
};

interface PipsAttrs {
  filledPips: number;
  totalPips: number;
}
const Pips: m.Component<PipsAttrs> = {
  view: ({ attrs: { filledPips, totalPips } }) => {
    const mPips = [];
    for (let i = 0; i < totalPips; i++) {
      const className = classNames({
        done: i < filledPips,
      });
      mPips.push(m(".pip", { className }));
    }
    return m(".pips", { title: `Step ${filledPips} of ${totalPips}` }, mPips);
  },
};

interface LessonAttrs {
  gettingStartedState: GettingStartedState;
}
const Lesson: m.Component<LessonAttrs> = {
  view: (vnode) => {
    const gettingStartedState = vnode.attrs.gettingStartedState;
    const step = gettingStartedState.currentStep();
    const videoSrc = preloadManager.get(step.videoUrl);
    const captionsSrc = preloadManager.get(step.captionsUrl);

    const gotoTry = () => {
      gettingStartedState.goto(gettingStartedState.stepIndex, "try");
    };

    return m(".modal-container", [
      m(".modal-background-overlay"),
      m(".editor-modal-box", [
        m(".lesson-title", [
          m("h1", [
            step.title,
            m(
              "span.step-counter",
              gettingStartedState.stepIndex + 1,
              "/",
              gettingStartedState.steps.length
            ),
          ]),
        ]),
        // The idea for having a manually sized SVG to create a grid cell of
        // known ratio via https://stackoverflow.com/a/53245657
        m("svg.lesson-ratio", { viewBox: "0 0 16 9" }),
        // This next div is overlayed on the previous grid cell in css
        m(".lesson", [
          m(LessonVideo, {
            videoSrc,
            captionsSrc,
            autoplay: !gettingStartedState.isFirstStep(),
            onended: gotoTry,
          }),
        ]),
        m(".lesson-transcript", step.transcript),
        m(".lesson-actions", [m("button.secondary", { onclick: gotoTry }, "Try it now →")]),
      ]),
    ]);
  },
};

interface LessonVideoAttrs {
  videoSrc: string;
  captionsSrc: string;
  autoplay: boolean;
  onended?: () => void;
}
const LessonVideo: m.ClosureComponent<LessonVideoAttrs> = (initialVnode) => {
  let hidePlayButton = initialVnode.attrs.autoplay;
  return {
    view: (vnode) => {
      const { videoSrc, captionsSrc, autoplay, onended } = vnode.attrs;
      return m(
        PlayButtonOverlayed,
        {
          size: 120,
          hidePlayButton,
          onclick: () => {
            hidePlayButton = true;
            const dom = domForVnode(vnode);
            const videoEl = dom.querySelector("video");
            videoEl?.play();
          },
        },
        [
          m(
            "video",
            {
              // Needed to help Firefox refresh the video and caption tracks. We
              // want to create a brand new <video> element if either the src or
              // captions change.
              key: videoSrc + captionsSrc,
              controls: true,
              autoplay,
              preload: "auto",
              onended: onended,
              // Needed to load subtitles/captions
              crossorigin: "anonymous",
            },
            [
              m("source", { src: videoSrc, type: "video/mp4" }),
              m("track", {
                src: captionsSrc,
                label: "English",
                kind: "captions",
                srclang: "en",
                default: true,
              }),
            ]
          ),
        ]
      );
    },
  };
};

interface TryAttrs {
  gettingStartedState: GettingStartedState;
  isSmallWindow: boolean;
}
const Try: m.Component<TryAttrs> = {
  view: ({ attrs: { gettingStartedState, isSmallWindow } }) => {
    const step = gettingStartedState.currentStep();
    const videoThumbSrc = preloadManager.get(step.videoThumbUrl);

    const gotoWatch = () => {
      gettingStartedState.goto(gettingStartedState.stepIndex, "watch");
    };
    const gotoNext = () => {
      gettingStartedState.goto(gettingStartedState.stepIndex + 1, "watch");
    };

    return m(".try", [
      isSmallWindow && m(SmallWindowWarning),
      m("video.step-video-thumb", {
        src: videoThumbSrc,
        controls: false,
        autoplay: true,
        loop: true,
        muted: true,
        crossorigin: "anonymous",
      }),
      m(".step-prompt", step.prompt),
      m(".step-actions", [
        m("button.secondary", { onclick: gotoWatch }, "Watch Video Again"),
        gettingStartedState.isLastStep()
          ? m(FinishButton)
          : m("button", { onclick: gotoNext }, "Next →"),
      ]),
    ]);
  },
};

/** Fork current state of project. */
const FinishButton: m.ClosureComponent<{}> = () => {
  let isCopying = false;
  const onclick = async () => {
    if (isCopying) return;
    isCopying = true;
    await accountState.storageSet("hasCompletedIntroSequence", true);
    const snapshotPayload = await globalState.snapshotPayload();
    // Navigates to forked project and reloads the browser
    const success = await globalState.storage.saveCopy(snapshotPayload);
    if (success) {
      globalState.closeGettingStartedSequence();
      toastState.showBasic({
        type: "info",
        message: "You made a copy. Your changes will now save to your account.",
      });
    } else {
      isCopying = false;
      m.redraw();
    }
  };
  return {
    view() {
      return m(
        "button",
        { onclick, disabled: isCopying, classNames: { secondary: isCopying } },
        isCopying ? "Saving…" : "Finish →"
      );
    },
  };
};

const SmallWindowWarning: m.Component<{}> = {
  view() {
    return m(".instance-inspector-message-warning", [
      m(
        ".instance-inspector-message-icon",
        { "aria-label": "Warning" },
        m(Icon20, { icon: "warning" })
      ),
      m(
        ".instance-inspector-message-text",
        "Note: you'll need a larger window (720px or more) to do the interactive steps."
      ),
    ]);
  },
};
