import m from "mithril";

import { ExportFormat } from "../../model/export-format";
import { sanitizeFilename } from "../../util";
import { Select, SelectOption } from "./select";
import { TextInput } from "./text-input";

interface FilenameInputAttrs {
  filename: string;
  format: ExportFormat;
  formatOptions: SelectOption[];
  onchange: (filename: string, format: ExportFormat) => void;
  autoSelect?: boolean;
}
export const FilenameInput: m.ClosureComponent<FilenameInputAttrs> = (initialVnode) => {
  const initialFilename = initialVnode.attrs.filename;
  let latestFormat = initialVnode.attrs.format;
  return {
    view({ attrs: { filename, format, formatOptions, autoSelect, onchange } }) {
      const performChange = (newFilename: string, newFormat: string) => {
        const extension = "." + newFormat;
        newFilename = sanitizeFilename(newFilename, extension);
        // If we sanitized the filename to death, revert to default.
        if (newFilename === extension) {
          newFilename = sanitizeFilename(initialFilename, extension);
        }
        latestFormat = newFormat as ExportFormat;
        onchange(newFilename, newFormat as ExportFormat);
      };
      const extensionIndex = filename.lastIndexOf(".");
      return m(".filename-input", [
        m(TextInput, {
          value: filename,
          initialSelectionRange: autoSelect
            ? {
                start: 0,
                end: extensionIndex > 0 ? extensionIndex : filename.length,
              }
            : undefined,
          onchange: (newFilename) => performChange(newFilename, latestFormat),
        }),
        m(Select, {
          options: formatOptions,
          selected: format,
          onchange: (newFormat) => performChange(filename, newFormat),
        }),
      ]);
    },
  };
};
