// Vendored on 2021-09-01 from
// https://gist.github.com/bentranter/ed524091170137a72c1d54d641493c1f
//
// Crypto.randomUUID() is not widely available yet
// https://developer.mozilla.org/en-US/docs/Web/API/Crypto
//

/**
 * Generates a RFC 4122 compliant universally unique identifier (uuid-v4, like
 * xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx) using the crypto API.
 */
export function uuid() {
  // get sixteen unsigned 8 bit random values
  var u = window.crypto.getRandomValues(new Uint8Array(16));

  // set the version bit to v4
  u[6] = (u[6] & 0x0f) | 0x40;

  // set the variant bit to "don't care" (yes, the RFC
  // calls it that)
  u[8] = (u[8] & 0xbf) | 0x80;

  // hex encode them and add the dashes
  var uid = "";
  uid += u[0].toString(16);
  uid += u[1].toString(16);
  uid += u[2].toString(16);
  uid += u[3].toString(16);
  uid += "-";

  uid += u[4].toString(16);
  uid += u[5].toString(16);
  uid += "-";

  uid += u[6].toString(16);
  uid += u[7].toString(16);
  uid += "-";

  uid += u[8].toString(16);
  uid += u[9].toString(16);
  uid += "-";

  uid += u[10].toString(16);
  uid += u[11].toString(16);
  uid += u[12].toString(16);
  uid += u[13].toString(16);
  uid += u[14].toString(16);
  uid += u[15].toString(16);

  return uid;
}

// outputs something like:
// 6fc4b899-44af-4092-a0ee-b391e90eef12
