import { ImageData } from "./geom/io/image-data";

export type ImageChangeSubscriber = () => void;

class ImageManager {
  private loadedImages: { [url: string]: ImageData | undefined } = {};
  private subscribers: Set<ImageChangeSubscriber> = new Set();

  getImageFromURL = (url: string) => {
    if (!url) return;

    if (this.loadedImages.hasOwnProperty(url)) {
      return this.loadedImages[url];
    }

    // So we don't call loadFont more than once per url
    this.loadedImages[url] = undefined;

    loadImageData(url)
      .then((image) => {
        this.loadedImages[url] = image;
        for (let subscriber of this.subscribers) {
          subscriber();
        }
      })
      .catch((error) => {
        console.warn("Image loading error:", error);
      });
  };

  subscribe(subscriber: ImageChangeSubscriber) {
    this.subscribers.add(subscriber);
  }
  unsubscribe(subscriber: ImageChangeSubscriber) {
    this.subscribers.delete(subscriber);
  }
}
export const imageManager = new ImageManager();

const loadImageData = async (url: string) => {
  const res = await fetch(url, { method: "GET", mode: "cors" });
  if (!res.ok) {
    throw new Error(`Could not load image from ${url}`);
  }
  const blob = await res.blob();
  const [img, dataURL] = await Promise.all([readAsImage(blob), readAsDataURL(blob)]);
  const image = new ImageData(img, dataURL);
  return image;
};

const readAsDataURL = (blob: Blob) => {
  return new Promise<string>(async (resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(blob);
  });
};

// Safari has `window.createImageBitmap`, but does not support
// `ctx.createPattern` with an ImageBitmap. It also seems that drawing Images is
// faster than drawing ImageBitmaps.
const readAsImage = async (blob: Blob): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", () => reject("Could not read image"));
    img.crossOrigin = "anonymous"; // Use CORS same-origin
    img.src = URL.createObjectURL(blob);
    (img as any).deleteURL = function () {
      URL.revokeObjectURL(this.src);
    };
  });
};
