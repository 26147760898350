import m from "mithril";

import { globalState } from "../global-state";
import { ShareStatus } from "../shared/api-types";
import { Icon20 } from "../shared/icon";
import { copyToClipboard } from "../shared/util";

export const ShareMenu: m.ClosureComponent<{}> = () => {
  let isLoading = false;
  let isLinkCopied = false;

  return {
    view: () => {
      const shareStatus = globalState.storage.getShareStatus();
      const publishedSnapshotId = globalState.storage.getPublishedSnapshotId();
      const currentSnapshotId = globalState.storage.getCurrentSnapshotId();

      const setCurrentAsPublished = async () => {
        isLoading = true;
        const snapshotId = await globalState.saveVersion();
        const publishedDescription = globalState.project.documentation.firstParagraphText();
        await globalState.storage.setPublishedSnapshot(snapshotId, publishedDescription);
        isLoading = false;
        m.redraw();
      };

      const setShareStatus = async (shareStatus: ShareStatus) => {
        isLoading = true;
        globalState.storage.setShareStatus(shareStatus);
        if (shareStatus === "private") {
          await globalState.storage.setPublishedSnapshot();
        } else {
          if (!globalState.storage.getPublishedSnapshotId()) {
            await setCurrentAsPublished();
          }
        }
        isLoading = false;
        m.redraw();
      };

      const copyLink = () => {
        const url = globalState.storage.getProjectUrl();
        copyToClipboard(url);
        isLinkCopied = true;
        // TODO: Toast popup that URL has been copied
      };

      let mCopyLink = m(".cuttle-share-menu-copy-link", { onclick: copyLink }, [
        m(Icon20, { icon: isLinkCopied ? "check" : "copy_link" }),
        "Copy Link",
      ]);

      let mPublished: m.Children;
      if (isLoading) {
        mPublished = m("p", m(".loading-indicator"));
      } else if (!publishedSnapshotId) {
        mPublished = null;
      } else if (publishedSnapshotId !== currentSnapshotId) {
        mPublished = [
          m(".cuttle-share-menu-published-description", [
            m(
              "p",
              "The published version is different than the current version. External viewers will see the published version."
            ),
          ]),
          m(
            "p",
            m(
              ".cuttle-share-menu-published-button",
              { onclick: setCurrentAsPublished },
              "Publish this version"
            )
          ),
        ];
      } else {
        mPublished = [
          m(".cuttle-share-menu-published-description", [
            m(
              "p",
              "The current version is published. If you modify the current version, external viewers will continue to see the published version."
            ),
          ]),
        ];
      }

      return m(".cuttle-share-menu", [
        // Private, Unlisted, Public options
        m(".cuttle-share-menu-option", [
          m("input[type=radio][name=cuttle-share-menu][id=cuttle-share-menu-private]", {
            checked: shareStatus === "private",
            onchange: () => setShareStatus("private"),
          }),
          m("label.cuttle-share-menu-label[for=cuttle-share-menu-private]", [
            m(".cuttle-share-menu-label-title", "Private"),
            m(".cuttle-share-menu-label-description", "Only you can view this project"),
          ]),
        ]),
        m(".cuttle-share-menu-option", [
          m("input[type=radio][name=cuttle-share-menu][id=cuttle-share-menu-unlisted]", {
            checked: shareStatus === "unlisted",
            onchange: () => setShareStatus("unlisted"),
          }),
          m("label.cuttle-share-menu-label[for=cuttle-share-menu-unlisted]", [
            m(".cuttle-share-menu-label-title", "Unlisted"),
            m(".cuttle-share-menu-label-description", "Anyone with the link can view this project"),
          ]),
        ]),
        m(".cuttle-share-menu-option", [
          m("input[type=radio][name=cuttle-share-menu][id=cuttle-share-menu-public]", {
            checked: shareStatus === "public",
            onchange: () => setShareStatus("public"),
          }),
          m("label.cuttle-share-menu-label[for=cuttle-share-menu-public]", [
            m(".cuttle-share-menu-label-title", "Public"),
            m(
              ".cuttle-share-menu-label-description",
              "Anyone can view this project and it will be listed on your public profile page"
            ),
          ]),
        ]),

        // Published version section
        mPublished,

        // Copy link
        shareStatus !== "private" ? mCopyLink : null,
      ]);
    },
  };
};
