import m from "mithril";

import { globalState } from "../../global-state";
import { CodeComponent, Component } from "../../model/component";
import { DocumentationFocus } from "../../model/focus";
import { ParameterFolder } from "../../model/parameter";
import { SelectableInstance } from "../../model/selectable";
import { EditableText } from "../../shared/editable-text";
import { IconButton } from "../../shared/icon";
import { MenuItem, createPopupMenu } from "../../shared/popup";
import { classNames, domForVnode } from "../../shared/util";
import { Expander } from "../basic/expander";
import { startDrag } from "../start-drag";
import { InstanceParameterInspector } from "./instance-parameter-inspector";
import { ParameterReorderHitTarget } from "./parameter-reorder-hit-target";

interface InstanceParameterFolderInspectorAttrs {
  selectables: SelectableInstance[];
  rootComponent: Component | CodeComponent;
  folder: ParameterFolder;
  isEditingDefinition: boolean;
}
export const InstanceParameterFolderInspector: m.Component<InstanceParameterFolderInspectorAttrs> =
  {
    view(vnode) {
      const {
        attrs: { selectables, rootComponent, folder, isEditingDefinition },
      } = vnode;
      const isEmbed = globalState.project.focus instanceof DocumentationFocus;
      const definition = selectables[0].definition();

      if (isEmbed || !isEditingDefinition) {
        const isHidden = folder.parameters.every(
          (param) => !globalState.project.isParameterVisible(definition, param)
        );
        if (isHidden) return;
      }

      const isImmutable = selectables[0].isImmutable();
      const canEditFolder = isEditingDefinition && !isImmutable && !isEmbed;

      let mName: m.Child;
      {
        let mInnerName: m.Children = folder.name;
        if (canEditFolder) {
          const renameFolder = (value: string) => {
            folder.name = value;
          };
          const autoSelect = globalState.autoSelectForRename === folder;
          if (autoSelect) {
            globalState.autoSelectForRename = undefined;
          }
          mInnerName = m(EditableText, { value: folder.name, onchange: renameFolder, autoSelect });
        }
        mName = m(".parameter-folder-name", mInnerName);
      }

      let mExtra: m.Child;
      if (canEditFolder) {
        const removeFolder = () => {
          globalState.project.removeDefinitionParameterFolder(definition, folder);
          1;
        };
        const onpointerdown = (event: PointerEvent) => {
          event.stopPropagation();
          const menuItems: MenuItem[] = [
            {
              label: "Delete Parameter Folder",
              action: removeFolder,
            },
          ];
          createPopupMenu({
            menuItems,
            spawnFrom: domForVnode(vnode),
            placement: "top-end",
          });
        };
        mExtra = m(".extra", [
          m(IconButton, {
            icon: "dotdotdot",
            className: "extra-hidden",
            onpointerdown,
          }),
        ]);
      }

      const toggleExpanded = (event: PointerEvent) => {
        event.stopPropagation();
        const isExpanded = globalState.expandedParameterFolders.has(folder);
        if (isExpanded) {
          globalState.expandedParameterFolders.delete(folder);
        } else {
          globalState.expandedParameterFolders.add(folder);
        }
      };

      const onpointerdown = (event: PointerEvent) => {
        if (isEmbed) {
          return toggleExpanded(event);
        }
        startDrag(event, {
          cursor() {
            return "grabbing";
          },
          onConsummate() {
            globalState.parameterFolderReorder = { definition, folder };
          },
          onUp() {
            const { parameterFolderReorder } = globalState;
            if (parameterFolderReorder) {
              let newIndex = parameterFolderReorder.hoveredInsertionIndex;
              if (newIndex !== undefined) {
                const oldIndex = definition.parameterFolders.indexOf(folder);
                definition.parameterFolders.splice(oldIndex, 1);

                // If we're removing the folder from a previous position in
                // the same array, we need to decrement the insertion index by 1
                // to account for the removed parameter.
                if (oldIndex < newIndex) newIndex -= 1;

                definition.parameterFolders.splice(newIndex, 0, folder);
              }
            }
            globalState.parameterFolderReorder = null;
          },
          onCancel() {
            toggleExpanded(event);
          },
        });
      };
      const onpointerenter = () => {
        if (!globalState.parameterReorder) return;
        globalState.parameterReorder.hoveredInsertionParent = folder;
        globalState.parameterReorder.hoveredInsertionIndex = folder.parameters.length;
      };
      const onpointerleave = () => {
        if (!globalState.parameterReorder) return;
        globalState.parameterReorder.hoveredInsertionParent = undefined;
        globalState.parameterReorder.hoveredInsertionIndex = undefined;
      };

      const className = classNames({
        hovered: globalState.parameterReorder?.hoveredInsertionParent === folder,
      });

      const folderIsExpanded = globalState.expandedParameterFolders.has(folder);
      const folderHasParameters = folder.parameters.length > 0;
      const showFolderParameters = folderHasParameters && folderIsExpanded;

      return [
        m(
          ".inspector-row.parameter-folder",
          { className, onpointerdown, onpointerenter, onpointerleave },
          [
            m(Expander, { expanded: folderIsExpanded, onpointerdown: toggleExpanded }),
            mName,
            m(".inspector-header-space"),
            mExtra,
          ]
        ),
        showFolderParameters &&
          m(".inspector-indent", { className }, [
            folder.parameters.map((parameter, parameterIndex) => {
              const isLastParameter = parameterIndex === folder.parameters.length - 1;
              return [
                m(ParameterReorderHitTarget, {
                  definition,
                  insertionIndex: parameterIndex,
                  insertionParent: folder,
                }),
                m(InstanceParameterInspector, {
                  selectables,
                  parameter,
                  isEditingDefinition,
                  folder,
                }),
                isLastParameter &&
                  m(ParameterReorderHitTarget, {
                    definition,
                    insertionIndex: parameterIndex + 1,
                    insertionParent: folder,
                  }),
              ];
            }),
          ]),
      ];
    },
  };
