import Drawing, { Unit as DXFUnit } from "dxf-writer";
import { ACI_COLORS } from "./aci-colors";
import { FramedGraphic } from "./framed-graphic";
import { CubicSegment, LineSegment, Color, Fill, Unit } from "../geom";

const cuttleToDXFWriterUnits: Record<Unit, DXFUnit> = {
  in: "Inches",
  ft: "Feet",
  mm: "Millimeters",
  cm: "Centimeters",
  m: "Meters",
  px: "Unitless",
  pc: "Unitless",
  pt: "Unitless",
};

export const DXFStringFromFramedGraphic = (framedGraphic: FramedGraphic) => {
  let d = new Drawing();

  const dxfUnits = cuttleToDXFWriterUnits[framedGraphic.units];
  d.setUnits(dxfUnits);

  let layerCount = 0;

  for (let pathGroup of framedGraphic.graphic.allPathsByColor()) {
    const strokeColor = pathGroup[0].stroke?.color;
    let fillColor: Color | undefined;
    const fill = pathGroup[0].fill;
    if (fill instanceof Fill) {
      fillColor = fill.color;
    }

    // Default is 0, black
    let aciColor = Drawing.ACI.LAYER;
    // Hex color number
    let trueColor = 0x000000;

    if (strokeColor) {
      aciColor = colorToACI(strokeColor);
      trueColor = strokeColor.toRGB8Number();
    } else if (fillColor) {
      aciColor = colorToACI(fillColor);
      trueColor = fillColor.toRGB8Number();
    }

    // Set up layer
    const layerName = `Layer_${layerCount}`;
    d.addLayer(layerName, aciColor, "CONTINUOUS");
    d.setActiveLayer(layerName);
    d.setTrueColor(trueColor);

    // Draw paths
    for (let path of pathGroup) {
      const segments = path.segments();
      for (let segment of segments) {
        if (segment instanceof CubicSegment) {
          // @ts-ignore line
          d.drawSpline(cubicSegmentToDXFPoints(segment), 3);
        } else if (segment instanceof LineSegment) {
          const { s, e } = segment;
          d.drawLine(s.x, 0 - s.y, e.x, 0 - e.y);
        }
      }
    }

    layerCount++;
  }

  return d.toDxfString();
};

/**
 * Given a Cuttle `CubicSegment`, return the DXF points with y-axis flipped.
 */
function cubicSegmentToDXFPoints(segment: CubicSegment) {
  const { s, cs, ce, e } = segment;
  return [
    [s.x, 0 - s.y],
    [cs.x, 0 - cs.y],
    [ce.x, 0 - ce.y],
    [e.x, 0 - e.y],
  ];
}

/**
 * Given a Cuttle `Color`, return the closest ACI color number.
 */
function colorToACI(color: Color): number {
  // Cuttle Color to rgb
  const colorR = Math.round(color.r * 255);
  const colorG = Math.round(color.g * 255);
  const colorB = Math.round(color.b * 255);

  let minDistance = Infinity;
  let minDistanceIndex = -1;

  for (let i = 0, length = ACI_COLORS.length; i < length; i++) {
    const [r, g, b] = ACI_COLORS[i].rgb;

    // If exact match, return immediately. The seven default Cuttle colors map
    // to the first ACI colors, so those should be the most common ones to find.
    if (r === colorR && g === colorG && b === colorB) {
      return i;
    }

    // Squared, because we're comparing distances we don't need to take the square root
    const distanceSquared =
      Math.pow(r - colorR, 2) + Math.pow(g - colorG, 2) + Math.pow(b - colorB, 2);

    if (distanceSquared < minDistance) {
      minDistance = distanceSquared;
      minDistanceIndex = i;
    }
  }

  return minDistanceIndex;
}
