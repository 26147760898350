import m from "mithril";
import { AffineMatrix, Vec } from "../../geom";
import { globalState } from "../../global-state";
import { LineGuideDefinition } from "../../model/builtin-shapes";
import { Expression } from "../../model/expression";
import { expressionCodeForNumber } from "../../model/expression-code";
import { Viewport } from "../../model/viewport";
import {
  hasActiveElementFocus,
  isPointerEventMiddleClick,
  isPointerEventRightClick,
} from "../../shared/util";
import { DefinitionDrag, startDefinitionDrag } from "../definition-drag";
import { startDrag } from "../start-drag";
import styleConstants from "../style-constants";
import { toastState } from "../toast-message";
import { startCanvasPan } from "../tool/canvas-drag";
import { startSelectionDrag } from "../tool/selection-drag";
import { createSelectionRightClickMenu } from "../top-menu";
import {
  POINTER_EVENT_BUTTONS_NONE,
  ViewportGestureEvent,
  alignToPixelCenter,
  pixelPositionFromEvent,
  worldPositionFromEvent,
  worldPositionFromPixelPosition,
} from "../util";
import { openEditorPopupForFirstTextParameter } from "./canvas-edit-text-parameter";
import { paintGrid } from "./canvas-grid";
import { CanvasInterfaceElement } from "./canvas-interface-element";
import { paintRulers } from "./canvas-ruler";
import { DefinitionDragUI } from "./definition-drag-interface";
import { pickInterfaceElemAtWorldPosition } from "./pick";
import { SnappingUI } from "./snapping-interface";

export class CanvasInterface {
  currentPointerEvent?: PointerEvent;
  hoveredInterfaceElementNeedsUpdate = false;

  updateInterfaceElements(viewMatrix: AffineMatrix) {
    const interfaceElems = globalState.ensureEnabledActiveTool().interface(viewMatrix);
    interfaceElems.push(new DefinitionDragUI());
    interfaceElems.push(new SnappingUI());
    globalState.interfaceElements = flattenInterfaceElements(interfaceElems, []);
    if (this.hoveredInterfaceElementNeedsUpdate && !globalState.isCanvasPanningOrPreparingToPan()) {
      this.updateHoveredInterfaceElement();
    }
  }

  updateCurrentPointerEvent(event: PointerEvent) {
    this.currentPointerEvent = event;
    globalState.isShiftDown = event.shiftKey;
    globalState.isControlDown = event.ctrlKey;
    globalState.isAltDown = event.altKey;
    globalState.isMetaDown = event.metaKey;
  }

  updateHoveredInterfaceElement() {
    if (!globalState.project.hasFocusedComponent()) return;
    if (!this.currentPointerEvent) return;

    const worldPosition = worldPositionFromEvent(this.currentPointerEvent);
    const pickedInterfaceElem = pickInterfaceElemAtWorldPosition(
      globalState.interfaceElements,
      worldPosition
    );
    const hoveredId = globalState.hoveredInterfaceElement?.id;
    const pickedId = pickedInterfaceElem?.id;
    if (hoveredId !== pickedId) {
      if (hoveredId !== undefined) {
        const hoveredUI = globalState.hoveredInterfaceElement as CanvasInterfaceElement;
        hoveredUI.onPointerLeave?.(this.currentPointerEvent);
      }
      if (pickedId !== undefined) {
        const pickedUI = pickedInterfaceElem as CanvasInterfaceElement;
        pickedUI.onPointerEnter?.(this.currentPointerEvent);
      }
    }
    globalState.hoveredInterfaceElement = pickedInterfaceElem;

    // Set the project hovered selectable
    const selectables = pickedInterfaceElem?.selectables?.();
    if (selectables && selectables.length > 0) {
      if (globalState.project.selectableExistsAndIsValid(selectables[0])) {
        globalState.project.hoveredItem = selectables[0]; // TODO: Support hovering multiple selectables?
      }
    }

    this.hoveredInterfaceElementNeedsUpdate = false;
  }
  clearHoveredInterfaceElementWithEvent(event: PointerEvent) {
    if (
      isInterfaceElementValid(globalState.hoveredInterfaceElement) &&
      globalState.hoveredInterfaceElement.id !== undefined
    ) {
      globalState.hoveredInterfaceElement.onPointerLeave?.(event);
    }
    globalState.hoveredInterfaceElement = undefined;
  }

  render(
    viewMatrix: AffineMatrix,
    viewport: Viewport,
    viewportSize: Vec,
    pixelRatio: number,
    ctx: CanvasRenderingContext2D,
    offscreenCtx: CanvasRenderingContext2D
  ) {
    ctx.save();
    ctx.scale(pixelRatio, pixelRatio);

    const { units, gridDivisions } = globalState.project.settings;

    if (globalState.project.isGridEnabled) {
      paintGrid(viewMatrix, viewport, viewportSize, gridDivisions, ctx);
    }

    for (let element of globalState.interfaceElements) {
      if (isInterfaceElementValid(element) && element.renderCanvas) {
        const opacity = element.renderCanvasOpacity?.() ?? 1;
        if (opacity < 1) {
          // If transparent, we must render to an offscreen context, and then
          // draw back to the onscreen context.
          offscreenCtx.save();
          offscreenCtx.scale(pixelRatio, pixelRatio);
          offscreenCtx.clearRect(0, 0, viewportSize.x, viewportSize.y);
          element.renderCanvas(viewMatrix, offscreenCtx);
          offscreenCtx.restore();

          ctx.globalAlpha = opacity;
          const prevTransform = ctx.getTransform();
          ctx.resetTransform();
          ctx.drawImage(offscreenCtx.canvas, 0, 0);
          ctx.setTransform(prevTransform);
          ctx.globalAlpha = 1;
        } else {
          ctx.save();
          element.renderCanvas(viewMatrix, ctx);
          ctx.restore();
        }
      }
    }

    paintSelectionBox(viewMatrix, ctx);
    paintRulers(viewMatrix, viewport, viewportSize, units, gridDivisions, ctx);

    ctx.restore();
  }

  renderHtml(viewMatrix: AffineMatrix) {
    const onRulerPointerDown = (angle: number) => {
      return (downEvent: PointerEvent) => {
        downEvent.stopPropagation();
        startDefinitionDrag(downEvent, {
          definitionDrag: new DefinitionDrag(LineGuideDefinition, {
            angle: new Expression(expressionCodeForNumber(angle)),
          }),
        });
      };
    };
    return [
      m(".html-ruler-mask", [
        m(".html-ruler-offset", [
          globalState.interfaceElements.map((elem) => elem.renderHtml?.(viewMatrix)),
        ]),
      ]),
      m(".ruler-grab-h", {
        onpointerdown: onRulerPointerDown(0),
      }),
      m(".ruler-grab-v", {
        onpointerdown: onRulerPointerDown(90),
      }),
    ];
  }

  onPointerDown(event: PointerEvent, viewport: Viewport) {
    if (!globalState.project.hasFocusedComponent()) return;
    if (!isInterfaceElementValid(globalState.hoveredInterfaceElement)) return;

    this.updateCurrentPointerEvent(event);

    const { project } = globalState;

    const selectables = globalState.hoveredInterfaceElement.selectables?.();

    if (
      globalState.isSpaceDown ||
      isPointerEventRightClick(event) ||
      isPointerEventMiddleClick(event)
    ) {
      const onCancel = () => {
        if (isInterfaceElementValid(globalState.hoveredInterfaceElement)) {
          if (selectables) {
            if (!project.selection.contains(...selectables)) {
              project.selectItems(selectables);
            }
            createSelectionRightClickMenu(event);
          }
          globalState.hoveredInterfaceElement.onContextMenu?.(event);
        }
      };
      startCanvasPan(event, onCancel, viewport);
    } else {
      let shiftSelectToggle: boolean | undefined = undefined;

      if (selectables) {
        const alreadySelected = project.selection.contains(...selectables);
        if (event.shiftKey) {
          // Select everything anticipating that a drag will happen. Save the
          // toggle state so that if the drag is cancelled we can select or
          // deselect correctly.
          shiftSelectToggle = !alreadySelected;
          project.toggleSelectItems(selectables, true);
        } else if (!alreadySelected) {
          project.selectItems(selectables);
        }
      }

      globalState.canvasPointerIsDown = true;
      globalState.downInterfaceElement = globalState.hoveredInterfaceElement;
      globalState.downInterfaceElement.onPointerDown?.(event);

      // Start a drag if the interface has not handled the event
      if (selectables && !event.defaultPrevented && project.selection.contains(...selectables)) {
        if (project.selection.isImmutable()) {
          startDrag(event, {
            onConsummate: () => {
              toastState.showBasic({
                type: "error",
                message: "Can't move the selection because it contains an immutable shape.",
              });
            },
          });
          return;
        }
        if (project.selection.isPositionLocked()) {
          // Only show the toast if you try to drag.
          startDrag(event, {
            onConsummate: () => {
              toastState.showBasic({
                type: "error",
                message: "Can't move the selection because a formula is being used in a Transform.",
                nextStep: "Try changing the position directly in the Inspector.",
              });
            },
          });
          return;
        }

        const onCancel = () => {
          if (event.shiftKey) {
            project.toggleSelectItems(selectables, shiftSelectToggle);
          }
        };
        startSelectionDrag(event, project.selection, onCancel);
      }
    }
  }

  onPointerMove(event: PointerEvent) {
    if (!globalState.project.hasFocusedComponent()) return;

    this.updateCurrentPointerEvent(event);
    globalState.canvasPointerPositionPixels = pixelPositionFromEvent(event);
    if (isPointerEventRightClick(event)) return;
    const isPanning = globalState.isCanvasPanningOrPreparingToPan();
    if (!isPanning && !globalState.canvasPointerIsDown) {
      this.updateHoveredInterfaceElement();
    }
    if (isInterfaceElementValid(globalState.hoveredInterfaceElement)) {
      globalState.hoveredInterfaceElement.onPointerMove?.(event);
    }
    if (!isPanning) {
      globalState.ensureEnabledActiveTool().onPointerMove?.(event);
    }
  }

  onPointerEnter(event: PointerEvent) {
    if (!globalState.project.hasFocusedComponent()) return;

    this.updateCurrentPointerEvent(event);
    globalState.canvasPointerIsDown = event.buttons !== POINTER_EVENT_BUTTONS_NONE;
    if (!globalState.isCanvasPanningOrPreparingToPan()) {
      this.updateHoveredInterfaceElement();
    }
    globalState.ensureEnabledActiveTool().onPointerEnter?.(event);
  }
  onPointerLeave(event: PointerEvent) {
    if (!globalState.project.hasFocusedComponent()) return;

    this.updateCurrentPointerEvent(event);
    globalState.canvasPointerPositionPixels = undefined;
    globalState.canvasPointerIsDown = false;
    this.clearHoveredInterfaceElementWithEvent(event);
    globalState.ensureEnabledActiveTool().onPointerLeave?.(event);

    // Clear the pointer event so we know that the cursor is no longer on the
    // canvas.
    this.currentPointerEvent = undefined;
  }

  onPointerUp(event: PointerEvent) {
    if (!globalState.project.hasFocusedComponent()) return;

    this.updateCurrentPointerEvent(event);
    globalState.canvasPointerIsDown = false;
    if (isInterfaceElementValid(globalState.downInterfaceElement)) {
      globalState.downInterfaceElement.onPointerUp?.(event);
    }
    globalState.downInterfaceElement = undefined;
    if (!globalState.isCanvasPanningOrPreparingToPan()) {
      this.updateHoveredInterfaceElement();
    }
    if (globalState.definitionDrag?.node) {
      openEditorPopupForFirstTextParameter(globalState.definitionDrag.node, event);
    }
  }

  onKeyEvent(event: KeyboardEvent) {
    if (!globalState.project.hasFocusedComponent()) return;

    if (!hasActiveElementFocus()) {
      this.hoveredInterfaceElementNeedsUpdate = true;
    }
  }

  onViewportChange(event: ViewportGestureEvent, viewport: Viewport) {
    const delta = new Vec(event.deltaX, event.deltaY).mulScalar(0.75 / viewport.pixelsPerUnit);
    viewport.center.add(delta);

    const scaleFactor = event.deltaScale;
    if (globalState.canvasPointerPositionPixels) {
      const worldCenter = worldPositionFromPixelPosition(globalState.canvasPointerPositionPixels);
      viewport.scaleFromCenter(scaleFactor, worldCenter);
    } else {
      viewport.scale(scaleFactor);
    }
  }
}

const isInterfaceElementValid = (
  elem: CanvasInterfaceElement | undefined
): elem is CanvasInterfaceElement => {
  return elem !== undefined && (elem.isValid === undefined || elem.isValid());
};

const flattenInterfaceElements = (
  elements: CanvasInterfaceElement[],
  outElements: CanvasInterfaceElement[]
) => {
  for (let element of elements) {
    if (isInterfaceElementValid(element)) {
      outElements.push(element);
      if (element.children !== undefined) {
        flattenInterfaceElements(element.children, outElements);
      }
    }
  }
  return outElements;
};

const paintSelectionBox = (viewMatrix: AffineMatrix, ctx: CanvasRenderingContext2D) => {
  const { selectionBox } = globalState;
  if (!selectionBox) return;

  const pixelStartPos = selectionBox.min.clone().affineTransform(viewMatrix);
  const pixelCurrentPos = selectionBox.max.clone().affineTransform(viewMatrix);
  const rectMin = alignToPixelCenter(Vec.min(pixelStartPos, pixelCurrentPos));
  const rectMax = alignToPixelCenter(Vec.max(pixelStartPos, pixelCurrentPos));

  ctx.beginPath();
  ctx.rect(rectMin.x, rectMin.y, rectMax.x - rectMin.x, rectMax.y - rectMin.y);
  const isOverlapSelection = !globalState.isAltDown;
  if (isOverlapSelection) {
    ctx.fillStyle = styleConstants.blue63Alpha10;
    ctx.fill();
  }
  ctx.strokeStyle = styleConstants.blue63;
  ctx.lineWidth = 1;
  ctx.lineJoin = "miter";
  ctx.stroke();
};
