import m from "mithril";

import { accountState } from "../shared/account";
import { publicBucketName } from "../shared/config";
import { ProFeatureButton } from "../shared/feature-check";
import { isSupportedLocalImageFile } from "../shared/file-types";
import { classNames } from "../shared/util";
import { escapeStringLiteral } from "../util";
import { checkImageSizeAndType, pickImage, pickLocalImage, uploadImage } from "./image-upload";

interface ImageInputAttrs {
  url: string;
  onChange: (url: string) => void;
  /** In packaged view, we can use local `blob:` image URLs, and skip uploading images to s3. */
  isTemporary: boolean;
}
export const ImageInput: m.ClosureComponent<ImageInputAttrs> = () => {
  let isUploadInProgress = false;
  return {
    view({ attrs: { url, onChange, isTemporary } }) {
      const onClick = async (event: PointerEvent) => {
        // Non-pro can only work with local images.
        const hasProFeatures = accountState.featureFlags.hasProFeatures;
        const file = hasProFeatures ? await pickImage() : await pickLocalImage();

        // Don't continue if no image, too big, or unsupported type.
        if (!file) return;
        if (!checkImageSizeAndType(file)) return;

        // Temporary blob URL for local images, if supported.
        if (isTemporary && isSupportedLocalImageFile(file)) {
          const blobUrl = URL.createObjectURL(file);
          onChange(blobUrl);
          m.redraw();
          return;
        }

        isUploadInProgress = true;

        const url = await uploadImage(file);
        onChange(url);

        isUploadInProgress = false;
      };
      const buttonClassName = classNames({ secondary: true, hidden: url !== "" });
      return m(".literal-input.image-input", [
        m(
          ".image-input-swatch",
          {
            ondrop,
            style: `background: center/contain no-repeat url("${escapeStringLiteral(
              url
            )}"), center repeat url("/editor/images/image-swatch-checkerboard.svg")`,
          },
          m(
            ProFeatureButton,
            {
              feature: "images",
              className: buttonClassName,
              disabled: isUploadInProgress,
              onClick,
              enableProCheck: !isTemporary,
            },
            isUploadInProgress ? "Uploading…" : "Choose Image…"
          )
        ),
      ]);
    },
  };
};

const urlForImagePath = (path: string) => {
  // If the path already starts with a protocol, return it with no modification.
  if (/^[\w-]+:/.test(path)) return path;

  return `https://${publicBucketName}.imgix.net/${path}`;
};
