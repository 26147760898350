import m from "mithril";

import { Icon20 } from "../../shared/icon";
import { classNames } from "../../shared/util";

interface ExpanderAttrs {
  expanded: boolean;
  onpointerdown: (event: PointerEvent) => void;
  hidden?: boolean;
  className?: string;
}
export const Expander: m.Component<ExpanderAttrs> = {
  view({ attrs: { expanded, hidden, onpointerdown, className } }) {
    if (className === undefined) {
      className = "";
    } else {
      className += " ";
    }
    const expanderClassNames = classNames({ expanded, hidden });
    if (expanderClassNames) {
      className += classNames({ expanded, hidden });
    }
    return m(
      ".expander",
      { onpointerdown, className, "aria-expanded": String(expanded) },
      m(Icon20, { icon: "expander" })
    );
  },
};
