import { apiRequestSuccess } from "../shared/api-request";
import { transparentPngBlob } from "../shared/util";
import { saveProjectJSON, savePreviewPNG } from "./save-utils";

interface SavePayload {
  project: object;
  previewPNG: Blob | undefined;
  coverPhoto: string | undefined;
}

export class SaveManager {
  projectId: string;

  /**
   * activeSnapshotId starts as undefined. Once you do the first save, it will
   * have that autosave's snapshot id and it will never change again. Thus each
   * editing session creates up to one autosave (once the first checkpoint comes
   * in).
   */
  activeSnapshotId: number | undefined;

  queuedPayload: SavePayload | undefined;
  enroutePayload: SavePayload | undefined;

  /**
   * As an optimization, we won't save a png if it's the exact same size as the
   * last time we saved a png.
   *
   * TODO: Compare PNG hash instead of size.
   */
  lastPreviewPngSize = -1;

  maxSaveInterval = 5000; // Don't save more frequently than this interval (milliseconds).
  lastSaveTime = 0; // The last time (Date.now) that we attempted to save.
  saveAttemptId = 0; // Just for debugging.

  onSuccessCallbacks: ((snapshotId: number) => void)[] = [];

  constructor(projectId: string) {
    this.projectId = projectId;
  }

  isSynchronized() {
    return this.queuedPayload === undefined && this.enroutePayload === undefined;
  }

  queueSave(payload: SavePayload) {
    const promise: Promise<number> = new Promise((resolve, reject) => {
      this.onSuccessCallbacks.push(resolve);
    });
    this.queuedPayload = payload;
    this.poll();
    return promise;
  }

  async forceSave() {
    if (this.isSynchronized()) {
      return;
    }
    const promise: Promise<number> = new Promise((resolve, reject) => {
      this.onSuccessCallbacks.push(resolve);
    });
    this.poll(true);
    return promise;
  }

  /**
   * We'll call this whenever:
   * 1. We queue a save.
   * 2. An enroute save finishes.
   * 3. A debouncing timeout finishes.
   *
   * The `urgent` parameter will override any timing debouncing.
   */
  private poll(urgent = false) {
    if (this.isSynchronized()) {
      // We're all done.
      this.resolveCallbacks();
      return;
    }
    if (this.enroutePayload !== undefined) {
      // We're in the process of saving something, so don't start a new save.
      return;
    }
    if (this.queuedPayload === undefined) {
      // Nothing to save.
      return;
    }
    if (!urgent && Date.now() - this.lastSaveTime < this.maxSaveInterval) {
      // Too soon to start another save, wait a bit and retry.
      setTimeout(() => this.poll(), 500);
      return;
    }

    this.enroutePayload = this.queuedPayload;
    this.queuedPayload = undefined;
    this.sendEnroutePayloadToServer();
  }

  private resolveCallbacks() {
    if (!this.activeSnapshotId) {
      throw "Auto-save finished but activeSnapshotId is not defined";
    }

    for (let callback of this.onSuccessCallbacks) {
      callback(this.activeSnapshotId);
    }
    this.onSuccessCallbacks = [];
  }

  private async sendEnroutePayloadToServer() {
    if (!this.enroutePayload) {
      throw "enroutePayload is not defined";
    }

    this.lastSaveTime = Date.now();
    this.saveAttemptId++;

    try {
      console.log(`[Auto-save] ${this.saveAttemptId} started`);
      let { project, previewPNG, coverPhoto } = this.enroutePayload;

      // Save Project
      this.activeSnapshotId = (
        await apiRequestSuccess(
          "saveProject",
          {
            projectId: this.projectId,
            snapshotId: this.activeSnapshotId,
            coverPhoto,
          },
          { timeout: 30000, skipRedraw: true }
        )
      ).snapshotId;

      await saveProjectJSON(this.projectId, this.activeSnapshotId, project);

      // Set the autosave as the current snapshot
      // TODO: should this be done by the server on saveProject?
      await apiRequestSuccess(
        "updateProjectCurrentSnapshot",
        {
          projectId: this.projectId,
          currentSnapshotId: this.activeSnapshotId,
        },
        { skipRedraw: true }
      );

      // Save Preview PNG
      if (!previewPNG) {
        previewPNG = transparentPngBlob;
      }
      if (previewPNG.size !== this.lastPreviewPngSize) {
        this.lastPreviewPngSize = previewPNG.size;
        await savePreviewPNG(this.projectId, this.activeSnapshotId, previewPNG);
      }

      console.log(`[Auto-save] ${this.saveAttemptId} succeeded`);
      this.enroutePayload = undefined;
      this.poll();
    } catch (error) {
      console.warn(`[Auto-save] ${this.saveAttemptId} failed`, error);
      // Retry.
      setTimeout(() => this.sendEnroutePayloadToServer(), 500);
    }
  }
}
