/**
 * Closes some possible security holes in user expressions.
 *
 * TODO: Eventually we're considering evaluating all user code in a Web Worker.
 * Workers are already sandboxed by the browser, so at that time we should be
 * able to remove this code.
 */
export const initSandbox = () => {
  // Mostly taken from nice-script
  // https://github.com/PortSwigger/nice-script/blob/master/js/nice-script.js#L63-L107
  //
  // These close exploits that emulate `eval`. In particular, these test cases
  // will jailbreak without these holes plugged:
  //
  // (function(){}).constructor("alert(1)")();
  // (async function(){}).constructor('alert(2)')();
  // (async function*(){}).constructor('alert(3)')().next();
  //
  (Function.prototype.constructor as any) = null;
  Object.defineProperty(async function () {}.constructor.prototype, "constructor", {
    value: null,
    configurable: false,
    writable: false,
  });
  Object.defineProperty(async function* () {}.constructor.prototype, "constructor", {
    value: null,
    configurable: false,
    writable: false,
  });
  Object.defineProperty(function* () {}.constructor.prototype, "constructor", {
    value: null,
    configurable: false,
    writable: false,
  });
};
