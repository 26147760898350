import m from "mithril";

import { Icon20 } from "../../shared/icon";

interface CheckboxAttrs {
  checked: boolean;
  disabled?: boolean;
  mixed?: boolean;
  onclick: (e: MouseEvent) => void;
}
export const Checkbox: m.Component<CheckboxAttrs> = {
  view({ attrs: { checked, disabled, mixed, onclick } }) {
    const mCheckbox = m("input[type=checkbox]", {
      disabled,
      checked,
      ["aria-checked"]: mixed ? "mixed" : checked ? "true" : "false",
      onclick,
    });
    const mIcon = m(Icon20, {
      icon: mixed ? "checkbox_mixed" : checked ? "checkbox_checked" : "checkbox_unchecked",
    });
    return m(".checkbox", [mIcon, mCheckbox]);
  },
};
