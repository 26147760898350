import m from "mithril";
import { Parameter } from "../model/parameter";
import { PISelect, PISelectOption } from "../model/parameter-interface";
import { domForVnode } from "../shared/util";
import { unique } from "../util";
import { EditorModal } from "./modal";

interface SelectOptionsModalAttrs {
  parameter: Parameter;
  onChange: (options: PISelectOption[]) => void;
}
export const SelectOptionsModal: m.Component<SelectOptionsModalAttrs> = {
  oncreate(vnode) {
    const input = domForVnode(vnode).querySelector("textarea");
    if (input instanceof HTMLTextAreaElement) {
      const { parameter } = vnode.attrs;
      if (parameter.interface instanceof PISelect) {
        input.value = stringFromOptions(parameter.interface.options);
      }
    }
  },
  view(vnode) {
    const { parameter, onChange } = vnode.attrs;
    const updateOptions = () => {
      const input = domForVnode(vnode).querySelector("textarea");
      if (input instanceof HTMLTextAreaElement) {
        const options = optionsFromMultilineString(input.value);
        // Only update the interface if there are options.
        if (options.length > 0) {
          parameter.interface = new PISelect(options);
          onChange(options);
        }
      }
    };
    return m(
      EditorModal,
      {
        title: "Edit Options",
        actions: [
          {
            label: "Set Options",
            action: updateOptions,
          },
        ],
        widthAuto: true,
      },
      m(".select-options-modal-contents", [
        m("textarea", {
          placeholder: "New Line\nSeparated\nList",
          autofocus: true,
        }),
        m("p", "Write each option on a new line."),
      ])
    );
  },
};

const stringFromOptions = (options: ReadonlyArray<PISelectOption>) => {
  return options.map((option) => option.label).join("\n");
};

const optionsFromMultilineString = (str: string) => {
  const lines = str
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line !== "");
  return unique(lines).map((line) => ({ label: line, value: line }));
};
