import m from "mithril";

import { Vec } from "../geom";
import { globalState } from "../global-state";

const vecFromPointerEvent = (event: PointerEvent) => new Vec(event.clientX, event.clientY);

// Time thresholds are in milliseconds, distance thresholds are in pixels.
const consummationTimeThreshold = 200; // once the mouse is down at least this long the drag is consummated
const consummationDistanceThreshold = 4; // once the mouse moves at least this distance the drag is consummated

/**
 * Globally indicates if a drag is in-progress.
 *
 * TODO: This could be a map of pointerId -> isPointerDragConsummated when we
 * want to support multitouch.
 */
let isDraggingInternal = false;

/**
 * @returns `true` if a drag is in-progress, `false` otherwise.
 */
export const isDragging = () => isDraggingInternal;

type PointerEventHandler = (event: PointerEvent) => void;

export interface StartDragOptions {
  onConsummate?: PointerEventHandler;
  onCancel?: PointerEventHandler;
  onMove?: PointerEventHandler;
  onUp?: PointerEventHandler;

  /**
   * Sometimes functions are passed to startDrag that capture project state that
   * can become invalid in-between events. Rather than implementing checks for
   * this ad-hoc in every event handler, we provide an isValid callback. This
   * will be called before calling the handlers for any event, and if false is
   * returned the onCancel handler will be called, after which the drag is
   * finished and no other event handlers will be called.
   */
  isValid?: () => boolean;

  cursor?: () => string;
}

export const startDrag = (
  downEvent: PointerEvent,
  { onConsummate, onCancel, onMove, onUp, isValid, cursor }: StartDragOptions
) => {
  const dragStartPosition = new Vec(downEvent.clientX, downEvent.clientY);
  const dragStartTime = downEvent.timeStamp;

  let isDragConsummated = false;

  const onPointerMove = (moveEvent: PointerEvent) => {
    if (moveEvent.pointerId !== downEvent.pointerId) return;

    if (isValid !== undefined && !isValid()) {
      cleanup();
      onCancel?.(moveEvent);
      return;
    }

    if (isDragConsummated) {
      onMove?.(moveEvent);
      m.redraw();
    } else {
      const position = vecFromPointerEvent(moveEvent);
      if (
        moveEvent.timeStamp - dragStartTime >= consummationTimeThreshold ||
        position.distance(dragStartPosition) >= consummationDistanceThreshold
      ) {
        isDragConsummated = true;
        onConsummate?.(moveEvent);
        m.redraw();
      }
    }

    updateCursor();
  };

  const onPointerUp = (upEvent: PointerEvent) => {
    if (upEvent.pointerId !== downEvent.pointerId) return;

    cleanup();
    m.redraw();

    if (isValid !== undefined && !isValid()) {
      onCancel?.(upEvent);
      return;
    }

    if (isDragConsummated) {
      onUp?.(upEvent);
    } else {
      onCancel?.(upEvent);
    }
  };

  const updateCursor = () => {
    if (cursor) {
      window.document.body.setAttribute("data-css-cursor", cursor());
    }
  };

  const onKey = (event: KeyboardEvent) => {
    // Ignore key events. (Modfier keys can still be checked from the
    // PointerEvent, and globalState.isShiftDown etc are still captured via
    // `registerKeyboardEventHandlers`.)
    event.preventDefault();
    event.stopPropagation();

    updateCursor();
  };

  const cleanup = () => {
    // Indicate that the drag has ended.
    isDraggingInternal = false;

    window.removeEventListener("pointermove", onPointerMove);
    window.removeEventListener("pointerup", onPointerUp);
    window.removeEventListener("pointercancel", onPointerUp);
    window.removeEventListener("keydown", onKey);
    window.removeEventListener("keyup", onKey);
    if (cursor) {
      window.document.body.removeAttribute("data-css-cursor");
    }
    globalState.stopGesture(gestureId);
  };

  window.addEventListener("pointermove", onPointerMove);
  window.addEventListener("pointerup", onPointerUp);
  window.addEventListener("pointercancel", onPointerUp);
  window.addEventListener("keydown", onKey);
  window.addEventListener("keyup", onKey);

  // Indicate that the drag has started before consummation.
  isDraggingInternal = true;

  // Prevent checkpointing due to key presses and other events during a drag
  const gestureId = globalState.startGesture("startDrag");
};

export interface StartNumberScrubDragOptions {
  startValue: number;
  fractionDigits: number;
  onChange(newValue: number, fractionDigits: number): void;
  onUp?(pointerUpEvent: PointerEvent): void;
  onCancel?(pointerUpEvent: PointerEvent): void;
}
export const startNumberScrubDrag = (
  downEvent: PointerEvent,
  {
    startValue,
    fractionDigits: precisionDigits,
    onChange,
    onUp,
    onCancel,
  }: StartNumberScrubDragOptions
) => {
  let value = startValue;
  let prevEvent = downEvent;

  startDrag(downEvent, {
    cursor() {
      return "ew-resize";
    },
    onConsummate(moveEvent) {
      prevEvent = moveEvent;
    },
    onMove(moveEvent) {
      const delta = (moveEvent.clientX - prevEvent.clientX) / 3;

      // Speed up (10x) when Shift is held. Slow down (0.1x) when alt is held.
      const precisionSpeedMod = moveEvent.shiftKey ? -1 : moveEvent.altKey ? 1 : 0;
      const precision = precisionDigits + precisionSpeedMod;

      value += delta * Math.pow(10, -precision);

      // Avoid fractionDigits below zero, which is possible when shift is held.
      const fractionDigits = Math.max(0, precision);
      onChange(value, fractionDigits);

      prevEvent = moveEvent;
    },
    onUp,
    onCancel,
  });
};
