import m from "mithril";

import { AutoSelectComment, globalState } from "../../global-state";
import { CodeComponent, Component } from "../../model/component";
import { DocumentationFocus } from "../../model/focus";
import { InstanceDefinition } from "../../model/instance-definition";
import { Modifier } from "../../model/modifier";
import { ParameterFolder } from "../../model/parameter";
import { Project } from "../../model/project";
import { SelectableInstance } from "../../model/selectable";
import { EditableText } from "../../shared/editable-text";
import { Icon20, IconButton } from "../../shared/icon";
import { MenuItem, createPopupMenu, createRightClickPopupMenu } from "../../shared/popup";
import { classNames, isPointerEventRightClick } from "../../shared/util";
import { Checkbox } from "../basic/checkbox";
import { Expander } from "../basic/expander";
import { DocEditorText } from "../doc-editor/doc-editor-text";
import { RepeatModifierSettings } from "../repeat-modifier-settings";
import { InstanceInspectorContents } from "./instance-inspector-contents";

export interface InstanceInspectorAttrs {
  // node will only be undefined for the focused component instance (which has
  // no associated Node).
  selectable: SelectableInstance;
  rootComponent: Component | CodeComponent;
  isContext?: boolean;
  isModifier?: boolean;
}
export const InstanceInspector: m.Component<InstanceInspectorAttrs> = {
  view(vnode) {
    const {
      attrs: { selectable, rootComponent, isContext, isModifier },
    } = vnode;

    const { node, instance } = selectable;
    const { definition } = instance;

    const name = nameForDefinition(definition);

    const hasCode = definition instanceof CodeComponent || definition instanceof Modifier;
    const isImmutableDefinition = definition.isImmutable;
    const isEditingCode = isContext || globalState.project.isEditingCode(instance);
    const isEditingDefinition = isContext || isEditingCode;
    const isImmutable = selectable.isImmutable();
    const isEmbed = globalState.project.focus instanceof DocumentationFocus;
    const isProject = definition instanceof Project;
    const isComponent = definition instanceof Component || definition instanceof CodeComponent;
    const canSetComponentOptions = isEditingDefinition && isComponent;

    // Actions

    const openCollapsed = () => {
      if (isProject) {
        globalState.isProjectParameterInspectorOpen = true;
      } else if (isComponent) {
        globalState.isComponentParameterInspectorOpen = true;
      }
    };

    const canEditComment = isEditingDefinition && !isImmutableDefinition && !isEmbed;
    const canAddComment = canEditComment && definition.comment === undefined;
    const addComment = () => {
      openCollapsed();
      definition.comment = "";
      globalState.autoSelectForRename = new AutoSelectComment(definition);
    };
    const canRemoveComment = canEditComment && definition.comment !== undefined;
    const removeComment = () => {
      definition.comment = undefined;
    };

    const canCreateNewParameter = isEditingDefinition && !isImmutableDefinition && !isEmbed;
    const createNewParameter = () => {
      openCollapsed();
      const newParameter = globalState.project.createParameter(definition, "0");
      globalState.autoSelectForRename = newParameter;
    };
    const createNewFolder = () => {
      openCollapsed();
      const newFolder = new ParameterFolder("Folder");
      definition.parameterFolders.push(newFolder);
      globalState.autoSelectForRename = newFolder;
    };

    const canToggleEditing = !isContext && hasCode && !isEmbed;
    const toggleEditing = () => {
      globalState.project.setEditingCode(instance, !isEditingCode);
    };

    const canRemoveModifier =
      isModifier && !isImmutable && node.source.modifiers.includes(instance);
    const removeModifier = () => {
      globalState.project.setEditingCode(instance, false);
      node.source.removeModifier(instance);
      globalState.project.ensureProjectDataExists();
    };

    const menuItems: MenuItem[] = [];
    const handleRightClick = (event: PointerEvent) => {
      if (isPointerEventRightClick(event) && menuItems.length > 0) {
        createRightClickPopupMenu(event, menuItems);
      }
    };

    const renameDefinitionAutoSelect = () => {
      globalState.autoSelectForRename = definition;
    };

    const canEditName = isEditingDefinition && !isImmutableDefinition && !isEmbed;

    let mExtra: m.Children = [];
    // TODO: "..." menu with rename for project as well
    if (!isEditingDefinition) {
      if (canRemoveModifier) {
        menuItems.push({
          label: [
            "Remove ",
            m("span.user-defined-name", name),
            " from ",
            m("span.user-defined-name", node.source.name),
          ],
          action: removeModifier,
        });
      }
      menuItems.push({
        label: ["Create variation of ", m("span.user-defined-name", name)],
        action: () => globalState.project.createVariationOfInstance(instance),
      });
      if (canEditName) {
        menuItems.push({
          label: ["Rename ", m("span.user-defined-name", name)],
          action: renameDefinitionAutoSelect,
        });
      }
    }

    if (canSetComponentOptions) {
      menuItems.push({
        label: `Show guides in uses of “${definition.name}”`,
        icon: () => (definition.isShowGuides ? m(Icon20, { icon: "check" }) : undefined),
        action: () => (definition.isShowGuides = !definition.isShowGuides),
      });
      menuItems.push({
        label: `Scale uses of “${definition.name}” automatically`,
        icon: () => (definition.isAutoScale ? m(Icon20, { icon: "check" }) : undefined),
        action: () => (definition.isAutoScale = !definition.isAutoScale),
      });
      menuItems.push({
        label: "Transform Style",
        submenu: () => [
          {
            label: "Uniform Scale (default)",
            tooltip: () =>
              "Transformations add a Transform modifier. Scaling is uniform by default.",
            icon: () =>
              !definition.isPassThrough && definition.isDefaultUniformScale
                ? m(Icon20, { icon: "check" })
                : undefined,
            action: () => {
              definition.isDefaultUniformScale = true;
              globalState.project.setDefinitionIsPassThrough(definition, false);
            },
          },
          {
            label: "Freeform",
            tooltip: () =>
              "Transformations add a Transform modifier. Scaling is non-uniform by default.",
            icon: () =>
              !definition.isPassThrough && !definition.isDefaultUniformScale
                ? m(Icon20, { icon: "check" })
                : undefined,
            action: () => {
              definition.isDefaultUniformScale = false;
              globalState.project.setDefinitionIsPassThrough(definition, false);
            },
          },
          {
            label: "Handles Only (advanced)",
            tooltip: () =>
              "Transformations “pass through” to handles and other transformable parameters. Instances can't have a Transform modifier.",
            icon: () => (definition.isPassThrough ? m(Icon20, { icon: "check" }) : undefined),
            action: () => {
              globalState.project.setDefinitionIsPassThrough(definition, !definition.isPassThrough);
            },
          },
        ],
      });
    }

    const parameterAndCommentMenuItems: MenuItem[] = [];
    if (canCreateNewParameter) {
      parameterAndCommentMenuItems.push({
        label: "New Parameter Folder",
        action: createNewFolder,
      });
    }
    if (canAddComment) {
      parameterAndCommentMenuItems.push({
        label: "Add Comment",
        action: addComment,
      });
    }
    if (canRemoveComment) {
      parameterAndCommentMenuItems.push({
        label: "Remove Comment",
        action: removeComment,
      });
    }
    if (parameterAndCommentMenuItems.length > 0) {
      if (menuItems.length > 0) {
        menuItems.push({ type: "separator" });
      }
      menuItems.push(...parameterAndCommentMenuItems);
    }

    if (menuItems.length > 0) {
      const onpointerdown = (e: PointerEvent) => {
        createPopupMenu({
          menuItems,
          spawnFrom: e.target as HTMLElement,
          placement: "top-end",
        });
      };
      mExtra.push(m(IconButton, { icon: "dotdotdot", className: "extra-hidden", onpointerdown }));
    }
    if (canCreateNewParameter) {
      mExtra.push(
        m(IconButton, { icon: "plus", label: "New Parameter", onclick: createNewParameter })
      );
    }
    if (canToggleEditing) {
      const className = classNames({
        active: isEditingCode,
      });
      mExtra.push(
        m(IconButton, {
          icon: "edit",
          label: isImmutableDefinition ? "View Code" : "Edit Code",
          onclick: toggleEditing,
          className,
        })
      );
    }

    let mHeaderContent: m.Children;
    if (isContext) {
      mHeaderContent = m(InspectorCategoryHeader, { selectable });
    } else {
      mHeaderContent = m(InspectorInstanceHeaderContents, {
        selectable,
        isNameEditable: canEditName,
      });
    }

    let mComment: m.Children;
    if (definition.comment !== undefined) {
      let mCommentValue: m.Children;
      if (canEditComment) {
        mCommentValue = m(DocEditorText, {
          value: definition.comment,
          placeholder: "Write a note about this…",
          editable: canEditComment,
          onchange: (newValue) => (definition.comment = newValue),
          autofocus:
            globalState.autoSelectForRename instanceof AutoSelectComment &&
            globalState.autoSelectForRename.subject === definition,
        });
      } else if (definition.comment !== "") {
        mCommentValue = definition.comment.split("\n").map((line) => m("p", line));
      }
      if (mCommentValue) {
        mComment = m(".inspector-row.comment", mCommentValue);
      }
    }

    let isOpen = instance.isEnabled || isEditingCode;
    if (isContext) {
      if (isEmbed) {
        isOpen = true;
      } else if (definition instanceof Project) {
        isOpen = globalState.isProjectParameterInspectorOpen;
      } else if (definition instanceof Component) {
        isOpen = globalState.isComponentParameterInspectorOpen;
      } else {
        // Don't hide parameters of code components.
        isOpen = true;
      }
    }

    let mContents: m.Children;

    if (isOpen) {
      mContents = [
        mComment,
        m(InstanceInspectorContents, {
          selectables: [selectable],
          rootComponent,
          isEditingDefinition,
        }),
        instance.isRepeatModifier() && m(RepeatModifierSettings, { selectable }),
      ];
    } else if (isProject || isComponent) {
      // Don't show summary for modifiers.
      mContents = m(InspectorParametersSummary, { selectable, onclick: openCollapsed });
    }

    return m(
      ".inspector-section",
      {
        className: classNames({
          "editing-definition": isEditingDefinition,
        }),
      },
      [
        // In embed view, don't show project and component headers.
        !isEmbed &&
          m(".inspector-row.inspector-header", { onpointerdown: handleRightClick }, [
            mHeaderContent,
            m(".inspector-header-space"),
            mExtra,
          ]),
        mContents,
      ]
    );
  },
};

interface InspectorInstanceHeaderAttrs {
  selectable: SelectableInstance;
  isNameEditable: boolean;
  hideEnabledCheckbox?: boolean;
}
export const InspectorInstanceHeaderContents: m.Component<InspectorInstanceHeaderAttrs> = {
  view({ attrs: { selectable, isNameEditable, hideEnabledCheckbox } }) {
    const showEnabledCheckbox =
      !hideEnabledCheckbox &&
      // Some builtin base instances (Group, Path, CompoundPath) are implemented
      // as modifiers. These cannont be disabled.
      selectable.isModifier() &&
      !selectable.isBase();

    let mEnabled: m.Children;
    if (showEnabledCheckbox) {
      const isImmutable = selectable.isImmutable();
      const enabledCheckboxOnClick = (e: MouseEvent) => {
        if (isImmutable) return;
        const isChecked = e.target instanceof HTMLInputElement && e.target.checked;
        selectable.instance.isEnabled = isChecked;
      };
      mEnabled = m(".inspector-header-enabled", [
        m(Checkbox, {
          disabled: isImmutable,
          checked: selectable.instance.isEnabled,
          onclick: enabledCheckboxOnClick,
        }),
      ]);
    }

    const definition = selectable.definition();

    let mIcon: m.Children;
    if (definition instanceof Modifier && definition.icon) {
      mIcon = m(".inspector-header-icon", m(Icon20, { icon: definition.icon }));
    }

    let mHeaderContents: m.Children = [
      mEnabled,
      mIcon,
      m(
        ".inspector-header-name",
        {
          className: classNames({ editable: isNameEditable }),
          onclick: (e: PointerEvent) => e.stopPropagation(),
        },
        m(DefinitionName, { definition, editable: isNameEditable })
      ),
    ];

    // Wrap checkbox and name in label, but only if the name is not editable
    if (mEnabled && !isNameEditable) {
      mHeaderContents = m("label.inspector-header-label", mHeaderContents);
    }

    return mHeaderContents;
  },
};

interface InspectorCategoryHeaderAttrs {
  selectable: SelectableInstance;
}
export const InspectorCategoryHeader: m.Component<InspectorCategoryHeaderAttrs> = {
  view({ attrs: { selectable } }) {
    const definition = selectable.definition();
    const isProject = definition instanceof Project;
    const showExpander = definition.hasParameters() && !(definition instanceof CodeComponent);

    const onToggleExpanded = () => {
      if (isProject) {
        globalState.isProjectParameterInspectorOpen = !globalState.isProjectParameterInspectorOpen;
      } else {
        globalState.isComponentParameterInspectorOpen =
          !globalState.isComponentParameterInspectorOpen;
      }
    };
    const isExpanded = isProject
      ? globalState.isProjectParameterInspectorOpen
      : globalState.isComponentParameterInspectorOpen;

    return [
      m(
        ".inspector-header-name",
        {
          className: "editable",
          onclick: (e: PointerEvent) => e.stopPropagation(),
        },
        m(DefinitionName, { definition, editable: true })
      ),
      showExpander &&
        m(Expander, {
          expanded: isExpanded,
          onpointerdown: onToggleExpanded,
          className: "extra-hidden",
        }),
    ];
  },
};

interface DefinitionNameAttrs {
  definition: InstanceDefinition;
  editable: boolean;
}
const DefinitionName: m.Component<DefinitionNameAttrs> = {
  view({ attrs: { definition, editable } }) {
    const name = nameForDefinition(definition);

    if (!editable) {
      return name;
    }

    let autoSelect = false;
    if (globalState.autoSelectForRename === definition) {
      autoSelect = true;
      globalState.autoSelectForRename = undefined;
    }

    return m(EditableText, {
      value: name,
      onchange: (newName) => renameDefinition(definition, newName),
      autoSelect,
    });
  },
};

const nameForDefinition = (definition: InstanceDefinition) => {
  // Use the project's name if we're inspecting project parameters.
  if (definition instanceof Project) return globalState.storage.getProjectName();
  return definition.name;
};

const renameDefinition = (definition: InstanceDefinition, newName: string) => {
  if (definition instanceof Project) {
    globalState.storage.setProjectName(newName);
  } else if (
    definition instanceof Component ||
    definition instanceof CodeComponent ||
    definition instanceof Modifier
  ) {
    globalState.project.renameDefinition(definition, newName);
  }
};

interface InspectorParametersSummaryAttrs {
  selectable: SelectableInstance;
  onclick: () => void;
}
export const InspectorParametersSummary: m.Component<InspectorParametersSummaryAttrs> = {
  view: ({ attrs: { selectable, onclick } }) => {
    const names = selectable.instance.definition
      .allParameters()
      .map((parameter) => parameter.name)
      .join(", ");
    const comment = selectable.instance.definition.comment?.trim().replace(/\n/g, " ");
    if (!names && !comment) return;
    return m(".inspector-row.inspector-parameters-summary", { onclick }, [
      comment && m(".inspector-parameters-summary-comment", comment),
      m(".inspector-parameters-summary-names", names),
      m("button.small-pill", { onclick }, "Show All"),
    ]);
  },
};
