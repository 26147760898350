import m from "mithril";

// Import the geometry library in the canonical order.
import "./geom";

import "./style/main.less";

import { Cuttle } from "./cuttle";
import { globalState } from "./global-state";
import { initCuttle } from "./init";
import { logPageLoadCheckpoint } from "./page-load";
import { initSandbox } from "./sandbox";
import { analyticsPageLanding } from "./shared/analy-tics";
import { App } from "./view/app";
import { urlParts } from "./util";
import { AppEmbed } from "./view/app-embed";

logPageLoadCheckpoint("editor index.js");

// We want to override Mithril routing used in src/shared/nav, as we only use
// the initial route in cuttle-editor, and don't use Mithril (single page app)
// routing.
m.route.set = (path, data, options) => {
  window.location.href = path;
};
m.route.Link = {
  view({ attrs, children }) {
    return m("a", attrs, children);
  },
};

async function init() {
  analyticsPageLanding();

  initSandbox();

  await initCuttle();
  logPageLoadCheckpoint("pathkit");

  const { projectId, edit, view, embed } = urlParts();

  console.log("[Startup] Initializing storage.");
  const projectSnapshot = await globalState.storage.init(projectId);
  if (projectSnapshot) {
    const projectId = globalState.storage.getProjectId();
    globalState.loadProjectSnapshot(projectSnapshot, projectId);
    // For initial editor load, make sure we start off with a clean edit
    // history.
    globalState.resetEditHistory();
  }
  console.log("[Startup] Storage initialized. Starting editor.");

  // Make globalState accessible for debugging
  (window as any)["globalState"] = globalState;

  // In embed mode, mount simpler app
  if (embed) {
    globalState.enterViewingMode();
    m.mount(document.body, AppEmbed);
    logPageLoadCheckpoint("mount app");
    return;
  }

  globalState.initFocus({ projectId, edit, view, embed });

  // If we're loading from the /intro route, initialize "Getting Started" tour.
  if (globalState.storage.sequenceToLoad() === "intro") {
    console.log('[Startup] Starting the "Getting Started" tour.');
    globalState.openGettingStartedSequence();
  }

  // Make "console API" accessible
  (window as any)["Cuttle"] = Cuttle;

  // Render the app and set up auto-redraw.
  m.mount(document.body, App);
  logPageLoadCheckpoint("mount app");
}

init();
