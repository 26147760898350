import type { JSONContent } from "@tiptap/core";
import { assert } from "../geom";
import { accountState } from "../shared/account";
import { isArray, isNumber, isString } from "../shared/util";
import { ModelObject } from "./model-object";
import { registerClass } from "./registry";

const docBlockTypes = ["cuttleComponent", "cuttleImage", "videoPlayer"];

export const makeInitialDoc = () => {
  return {
    type: "doc",
    content: [
      {
        type: "paragraph",
      },
    ],
  };
};

export class Documentation extends ModelObject {
  name: string;
  doc: JSONContent;

  constructor(name: string, doc: JSONContent) {
    super();
    this.name = name;
    this.doc = doc;
  }

  materialKeys(): string[] {
    return ["name", "doc"];
  }

  /** Only returns an image path if there is an image uploaded by the logged in
   * user. Otherwise, we don't want to show the image on the explore page. */
  coverPhoto() {
    const loggedInUserId = accountState.loggedInUser?.userId;
    if (!loggedInUserId) {
      return;
    }

    const firstOriginalImage = this.doc.content?.find((block) => {
      if (block.type !== "cuttleImage") return false;

      const imagePath = block.attrs?.path as unknown;
      if (!isString(imagePath)) return false;

      const imageUserId = Number(imagePath.split("/")[0]);
      if (!isNumber(imageUserId)) return false;

      return imageUserId === loggedInUserId;
    });
    if (!firstOriginalImage) return;

    const coverPhotoPath = firstOriginalImage.attrs?.path as unknown;
    if (!isString(coverPhotoPath)) return;

    return coverPhotoPath;
  }

  hasContent() {
    return Boolean(
      this.doc.content?.some(
        (content) =>
          (content?.type && docBlockTypes.includes(content.type)) ||
          (content.content && content.content.length > 0)
      )
    );
  }

  firstComponentEmbedId() {
    const firstComponent = this.doc.content?.find((content) => content?.type === "cuttleComponent");
    if (!firstComponent) return;
    const componentId = firstComponent?.attrs?.componentid;
    if (isArray(componentId)) {
      assert(isString(componentId[0]));
      return componentId[0];
    }
    assert(isString(componentId));
    return componentId;
  }

  firstComponentEmbedIds() {
    const firstComponent = this.doc.content?.find((content) => content?.type === "cuttleComponent");
    if (!firstComponent) return;
    const componentId = firstComponent?.attrs?.componentid;
    if (isArray(componentId)) {
      assert(componentId.every(isString));
      return componentId;
    }
    assert(isString(componentId));
    return [componentId];
  }

  /** First paragraph's text, up to 280 characters. */
  firstParagraphText() {
    const maxLength = 280;

    let text = "";
    const firstParagraph = this.doc.content?.find((content) => {
      if (!content.type) return false;
      if (content.type === "paragraph" || content.type === "blockquote") {
        const contentText = getContentText(content);
        if (contentText && contentText.length > 0) {
          text = contentText;
          return true;
        }
      }
      return false;
    });
    if (!firstParagraph) return;
    if (!text) return;
    if (text.length <= maxLength) return text;

    // Slice at word boundary
    const words = text.split(" ");
    let truncatedLength = 0;
    let i = 0;

    for (; i < words.length; i++) {
      truncatedLength += words[i].length;
      if (truncatedLength > maxLength) {
        break;
      }
      truncatedLength += 1;
    }

    return words.slice(0, i).join(" ") + "…";
  }
}
registerClass("Documentation", Documentation);

function getContentText(content: JSONContent): string | undefined {
  if (content.content) {
    return content.content.map(getContentText).join("").trim();
  }
  if (content.text) {
    return content.text;
  }
  return;
}
