import m from "mithril";

import { assert } from "../../geom";

export interface SelectOption {
  value: string;
  label: string;
}
interface SelectAttrs {
  options: (string | SelectOption)[];
  className?: string;
  selected: string;
  onchange: (value: string) => void;
}
export const Select: m.Component<SelectAttrs> = {
  view({ attrs: { options, className, selected, onchange } }) {
    const selectOnChange = (event: Event) => {
      assert(event.target instanceof HTMLSelectElement);
      let value = options[event.target.selectedIndex];
      if (typeof value === "object") {
        value = value.value;
      }
      onchange(value);
    };
    return m(
      "select",
      { className, onchange: selectOnChange },
      options.map((option) => {
        let value: string;
        let label: string;
        if (typeof option === "object") {
          value = option.value;
          label = option.label;
        } else {
          value = option;
          label = option;
        }
        return m("option", { value, selected: selected === value }, label);
      })
    );
  },
};
