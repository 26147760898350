import { globalState } from "../global-state";
import { publicBucketName } from "../shared/config";
import {
  imageFileTypes,
  isSupportedUploadableImageFile,
  mimeTypeForFile,
} from "../shared/file-types";
import { logEvent } from "../shared/log-event";
import { toastState } from "./toast-message";
import { pickFile } from "./util";

const conversionExtensionsByMimeType: Record<string, string> = {
  "image/bmp": "jpg",
  "image/gif": "png",
  "image/tiff": "jpg",
  "image/heic": "jpg",
  "image/heif": "jpg",
};

const IMAGE_UPLOAD_MAX_BYTES = 20 * 1024 * 1024; // 20 MB

export const pickFilesForImport = async () => {
  const files = await pickFile({ accept: imageFileTypes.join(",") });
  if (!files || files.length < 1) {
    throw "No files chosen.";
  }
  return Array.from(files);
};
export const pickImage = async () => {
  const files = await pickFile({ accept: imageFileTypes.join(",") });
  if (!files || files.length < 1) {
    throw "No files chosen.";
  }
  return files[0];
};
export const pickLocalImage = async () => {
  const files = await pickFile({ accept: imageFileTypes.join(",") });
  if (!files || files.length < 1) {
    throw "No files chosen.";
  }
  return files[0];
};

export const checkImageSizeAndType = (file: File) => {
  if (file.size > IMAGE_UPLOAD_MAX_BYTES) {
    toastState.showBasic({
      message: "You chose a file larger than 20MB.",
      nextStep: "Try resizing or compressing the image before uploading.",
      type: "error",
    });
    return false;
  }

  if (!isSupportedUploadableImageFile(file)) {
    toastState.showBasic({
      message: `You chose a ${file.type} file to upload, but we don't support that type yet.`,
      nextStep: "Try uploading an image file.",
      type: "error",
    });
    return false;
  }

  return true;
};

export const uploadImage = async (file: File) => {
  if (!checkImageSizeAndType(file)) {
    throw "Image file format not supported";
  }
  const path = await globalState.storage.uploadFile(file);
  const url = urlForImageFilePath(file, path);
  logEvent("upload image", { url });
  return url;
};

const urlForImageFilePath = (file: File, path: string) => {
  // If the path already starts with a protocol, return it with no modification.
  if (/^[\w-]+:/.test(path)) return path;

  const type = mimeTypeForFile(file);
  if (type) {
    const convertToType = conversionExtensionsByMimeType[type];
    if (convertToType) {
      const q = 80;
      return `https://${publicBucketName}.imgix.net/${path}?q=${q}&fm=${convertToType}`;
    }
  }
  return `https://${publicBucketName}.imgix.net/${path}`;
};
