import { globalState } from "../global-state";
import { accountState } from "../shared/account";
import { checkUserHasProFeature } from "../shared/feature-check";
import { openUpgradeModal } from "../shared/upgrade-modal";
import { storage } from "../storage/storage";
import { openCreateAccountToDownloadModal } from "./create-account-to-download-modal";
import { openDownloadCountModal } from "./download-count-modal";

/**
 * Checks if the user is currently logged in and has downloads. If the user is
 * on a free plan, we increment their download count and open a modal showing
 * the current count.
 *
 * @returns `true` if the user can download, `false` otherwise.
 */
export const checkUserCanExport = async () => {
  if (storage.type === "local") return true;

  const isLoggedIn = await checkUserLoggedIn();
  const hasDownloads = checkUserHasDownloads();
  const hasProjectAccess = checkUserHasProjectAccess();
  const hasFontAccess = checkUserHasFontAccess();
  return isLoggedIn && hasDownloads && hasProjectAccess && hasFontAccess;
};

const checkUserLoggedIn = async () => {
  // You need to be logged in to download.
  if (!accountState.loggedInUser) {
    const success = await openCreateAccountToDownloadModal();
    if (!success) {
      return false;
    }
  }

  return true;
};

const checkUserHasDownloads = () => {
  if (!accountState.loggedInUser) return false;

  // Free users are limited to 10 downloads per month. This must come after
  // the check for logged in user above.
  if (accountState.loggedInUser.subscription.plan === "Free") {
    let downloadsThisMonth = accountState.storageGet("downloadsThisMonth") ?? 0;
    const downloadMonth = accountState.storageGet("downloadMonth");
    const currentDate = new Date();
    const currentDayOfMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const currentMonth = currentDate.getFullYear() + "/" + currentDayOfMonth;
    if (currentMonth === downloadMonth) {
      if (downloadsThisMonth >= 10) {
        // User has run out of downloads for this month. Open the upgrade modal.
        openUpgradeModal("downloads", true);
        return false; // Don't download.
      } else {
        downloadsThisMonth += 1;
        accountState.storageSet("downloadsThisMonth", downloadsThisMonth);
        if (downloadsThisMonth >= 7) {
          openDownloadCountModal();
        }
      }
    } else {
      accountState.storageSet("downloadMonth", currentMonth);
      accountState.storageSet("downloadsThisMonth", 1);
    }
  }

  return true;
};

const checkUserHasProjectAccess = () => {
  if (globalState.storage.isProTemplate()) {
    if (!accountState.loggedInUser) return false;
    if (!checkUserHasProFeature("templates")) return false;
  }
  return true;
};

const checkUserHasFontAccess = () => {
  if (globalState.project.usesProFont()) {
    if (!accountState.loggedInUser) return false;
    // You need to be on a paid plan to download a project that uses pro fonts.
    if (!checkUserHasProFeature("pro-fonts")) return false;
  }
  return true;
};

/** Users can interact with pro fonts in the packaged view, but can not export
 * the project or switch to edit view. */
export const checkUserCanEdit = () => {
  if (storage.type === "local") return true;

  if (!checkUserHasProjectAccess()) return false;
  if (!checkUserHasFontAccess()) return false;
  return true;
};
