import { AffineMatrix, almostEquals, BoundingBox, Vec } from "../geom";
import { clamp } from "../geom/math/scalar-math";
import { registerClass } from "./registry";

const minPixelsPerUnit = 1e-6;
const maxPixelsPerUnit = 1e6;

export class Viewport {
  static defaultPixelsPerUnit = 110; // 110 is DPI of a typical external monitor, so this is 1 inch default

  pixelsPerUnit = Viewport.defaultPixelsPerUnit;
  center = new Vec(0); // this point (in the component's top-level coordinate space) will be centered in the viewport

  viewMatrixWithCanvasDimensions(canvas: CanvasDimensions) {
    const canvasCenter = canvas.usableArea.center();
    const viewportCenter = this.center.clone().mulScalar(this.pixelsPerUnit);
    return AffineMatrix.fromTransform({
      position: canvasCenter.clone().sub(viewportCenter),
      scale: this.pixelsPerUnit,
    });
  }
  viewMatrixWithBounds(viewportBounds: BoundingBox) {
    return AffineMatrix.fromTransform({
      position: viewportBounds.center().sub(this.center.clone().mulScalar(this.pixelsPerUnit)),
      scale: this.pixelsPerUnit,
    });
  }

  clone() {
    const viewport = new Viewport();
    viewport.pixelsPerUnit = this.pixelsPerUnit;
    viewport.center = this.center.clone();
    return viewport;
  }

  scale(scale: number, quantize?: boolean) {
    const currentScale = this.pixelsPerUnit / Viewport.defaultPixelsPerUnit;
    let nextScale: number;
    if (quantize) {
      if (scale < 1) {
        // Zoom out to the next "fret"
        const base = 1 / scale;
        nextScale = Math.pow(base, Math.floor(Math.log(currentScale) / Math.log(base)));
      } else {
        // Zoom in to the next "fret"
        nextScale = Math.pow(scale, Math.ceil(Math.log(currentScale) / Math.log(scale)));
      }
      if (almostEquals(currentScale, nextScale)) {
        // We're already on a "fret". Zoom to the next one.
        nextScale = Math.pow(scale, Math.round(Math.log(currentScale * scale) / Math.log(scale)));
      }
    } else {
      nextScale = scale * currentScale;
    }
    const ppu = nextScale * Viewport.defaultPixelsPerUnit;
    this.pixelsPerUnit = clamp(ppu, minPixelsPerUnit, maxPixelsPerUnit);
  }
  scaleFromCenter(scale: number, center: Vec, quantize?: boolean) {
    const ppuBeforeScale = this.pixelsPerUnit;
    this.scale(scale, quantize);
    const ppuRatio = ppuBeforeScale / this.pixelsPerUnit;
    this.center.sub(center).mulScalar(ppuRatio).add(center);
  }
  scaleToFitBoundingBox(box: BoundingBox, canvas: CanvasDimensions) {
    if (!box.isFinite()) return;

    this.center = box.center();

    const size = box.size().mulScalar(1.1);
    if (size.isZero()) {
      this.pixelsPerUnit = Viewport.defaultPixelsPerUnit;
    } else {
      const usableSize = canvas.usableArea.size();
      const ppu = Math.min(usableSize.x / size.x, usableSize.y / size.y);
      this.pixelsPerUnit = clamp(ppu, minPixelsPerUnit, maxPixelsPerUnit);
    }
  }

  worldBoundingBox(canvas: CanvasDimensions) {
    const inverseViewMatrix = this.viewMatrixWithCanvasDimensions(canvas).invert();
    // The view matrix is assumed to not have any rotation or skew.
    return new BoundingBox(
      new Vec(0, 0).affineTransform(inverseViewMatrix),
      canvas.size.clone().affineTransform(inverseViewMatrix)
    );
  }

  precisionInfo() {
    const pixelScale = 1 / this.pixelsPerUnit;
    const exponent = Math.round(Math.log10(pixelScale));
    const increment = Math.pow(10, exponent);
    const fractionDigits = Math.max(0, -Math.floor(Math.log10(increment)));
    return { exponent, fractionDigits, increment };
  }
}
registerClass("Viewport", Viewport);

export class CanvasDimensions {
  size: Vec;
  usableArea: BoundingBox;

  constructor(size = new Vec(), usableArea?: BoundingBox) {
    this.size = size;
    this.usableArea = usableArea ?? new BoundingBox(new Vec(0, 0), size);
  }
}
