import m from "mithril";
import CodeMirror from "codemirror";

import { classNames, domForVnode, isMacPlatform } from "../shared/util";
import { codeMirrorExtraKeys, CodeMirrorPool } from "./code-mirror";
import { CharacterRange } from "../model/code-ranges";

// ----------------------------------------------------------------------------
// CodeMirror Config
// ----------------------------------------------------------------------------

const codeMirrorOptions = {
  mode: "text/plain",
  tabSize: 2,
  indentUnit: 2,
  indentWithTabs: false,
  lineWrapping: true,
  dragDrop: false,
  undoDepth: 0,
  extraKeys: codeMirrorExtraKeys,
};

const cmPool = new CodeMirrorPool(codeMirrorOptions);

// ----------------------------------------------------------------------------
// Text Editor Component
// ----------------------------------------------------------------------------

interface TextEditorAttrs {
  value: string;
  readOnly?: boolean;
  confirmOnEnter?: boolean;
  initialSelection?: CharacterRange | "all";
  onchange: (value: string) => void;
  onfocus?: () => void;
  onblur?: () => void;
}
export const TextEditor: m.ClosureComponent<TextEditorAttrs> = (initialVnode) => {
  let latestVnode = initialVnode;
  let cm: CodeMirror.Editor;

  let nextFocusViaPointer = false;

  // ResizeObserver setup and cleanup in oncreate, onremove
  let queuedRefresh: void | number;
  const resizeObserver = new ResizeObserver(() => {
    // Debounce with requestAnimationFrame if last refresh hasn't happened, as
    // we don't want to hit a loop with ResizeObserver
    if (cm && !queuedRefresh) {
      queuedRefresh = requestAnimationFrame(() => {
        cm.refresh();
        queuedRefresh = undefined;
      });
    }
  });

  const onCodeMirrorChange = () => {
    const { onchange, value } = latestVnode.attrs;
    const newValue = cm.getValue();
    if (newValue !== value) {
      onchange(newValue);
      m.redraw();
    }
  };

  const onCodeMirrorFocus = () => {
    if (!nextFocusViaPointer) {
      // Probably keyboard focus: match normal DOM input selection behavior
      const isMultiline = /\n/.test(latestVnode.attrs.value);
      if (isMultiline) {
        cm.execCommand("goDocStart");
      } else {
        cm.execCommand("selectAll");
      }
    }
    nextFocusViaPointer = false;
    latestVnode.attrs.onfocus?.();
    m.redraw();
  };

  const onCodeMirrorBlur = () => {
    latestVnode.attrs.onblur?.();
    m.redraw();
  };

  const cmdKey = isMacPlatform() ? "Cmd" : "Ctrl";

  const codeMirrorKeyMap = {
    Enter: (cm: CodeMirror.Editor) => {
      if (latestVnode.attrs.confirmOnEnter) {
        cm.getInputField().blur();
        m.redraw();
      } else {
        cm.execCommand("newlineAndIndent");
      }
    },
    // Disable "Find" plugin
    [`${cmdKey}-F`]: () => {
      return;
    },
    [`${cmdKey}-G`]: () => {
      return;
    },
    [`Shift-${cmdKey}-F`]: () => {
      return;
    },
    [`Shift-${cmdKey}-G`]: () => {
      return;
    },
  };

  const updateCodeMirror = () => {
    const { value, readOnly } = latestVnode.attrs;
    if (cm) {
      if (cm.getValue() !== value) {
        cm.setValue(value);
      }
      cm.setOption("readOnly", readOnly);
    }
  };

  const onclick = (event: PointerEvent) => {
    event.stopPropagation();
  };
  const onpointerdown = (event: PointerEvent) => {
    event.stopPropagation();
    nextFocusViaPointer = true;
  };
  const onpointerup = () => {
    nextFocusViaPointer = false;
  };

  return {
    oncreate(vnode) {
      const el = domForVnode(vnode);
      cm = cmPool.create(el);

      updateCodeMirror();

      const { initialSelection } = vnode.attrs;
      if (initialSelection) {
        cm.focus();
        if (initialSelection === "all") {
          cm.execCommand("selectAll");
        } else {
          cm.setSelection(
            { line: 0, ch: initialSelection.begin },
            { line: 0, ch: initialSelection.end }
          );
        }
      }

      cm.on("change", onCodeMirrorChange);
      cm.on("focus", onCodeMirrorFocus);
      cm.on("blur", onCodeMirrorBlur);
      cm.addKeyMap(codeMirrorKeyMap);

      resizeObserver.observe(el);
    },
    onupdate() {
      updateCodeMirror();
    },
    onremove() {
      // Important: we need to clean up `cm` before it can be reused.
      cm.off("change", onCodeMirrorChange);
      cm.off("focus", onCodeMirrorFocus);
      cm.off("blur", onCodeMirrorBlur);
      cm.removeKeyMap(codeMirrorKeyMap);
      cmPool.dispose(cm);
      resizeObserver.disconnect();
      if (queuedRefresh) cancelAnimationFrame(queuedRefresh);
    },
    view(vnode) {
      latestVnode = vnode;
      return m(".text-editor", {
        className: classNames({
          readonly: latestVnode.attrs.readOnly,
        }),
        onclick,
        onpointerdown,
        onpointerup,
      });
    },
  };
};
