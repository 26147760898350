/* This should replace text-editor eventually. */

import m from "mithril";

import type { Extensions, JSONContent } from "@tiptap/core";
import { Editor, Node, isNodeSelection } from "@tiptap/core";
import DropCursor from "@tiptap/extension-dropcursor";
import History from "@tiptap/extension-history";
import Image from "@tiptap/extension-image";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";

import { RichText, RichTextGlyph, RichTextSymbol } from "../../geom/text/rich-text";
import { IconButton } from "../../shared/icon";
import { createPopup } from "../../shared/popup";
import { domForVnode, isString } from "../../shared/util";
import { transformPastedHTML } from "./doc-editor-shared";
import { ShapePickerPopup } from "../shape-picker";
import { globalState } from "../../global-state";
import { assetsOrigin } from "../../shared/config";

const InlineImage = Image.extend({
  selectable: true,
  draggable: true,
  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      ["data-glyph-index"]: {
        default: null,
      },
    };
  },
  parseHTML() {
    return [
      /** Ignores pasted images that are not ours. */
      {
        tag: "img[src]",
        getAttrs: (dom) => {
          if (!(dom instanceof HTMLElement)) return false;
          const src = dom.getAttribute("src");
          if (src && src.startsWith(assetsOrigin)) {
            return { src };
          }
          return false;
        },
      },
      /** For RichTextGlyph placeholders */
      { tag: "img[alt]" },
      { tag: "img[data-glyph-index]" },
    ];
  },
}).configure({
  inline: true,
});

// Limited schema: doc can only have text and Symbols. Paragraphs become newlines.
const DocumentRichText = Node.create({
  name: "doc",
  topNode: true,
  content: "paragraph+",
  marks: "",
});

interface DocEditorRichAttrs {
  value: RichText | string;
  editable: boolean;
  /** Hit on every change. */
  onchange: (value: RichText) => void;
  /** Use when opening the editor with double-click. */
  autoselect?: boolean;
}
export const DocEditorRich: m.ClosureComponent<DocEditorRichAttrs> = (initialVnode) => {
  let latestVnode = initialVnode;
  let editor: Editor | undefined;

  /** If a single symbol is selected, get its URL to open the shape picker with. */
  const getSymbolValue = () => {
    if (!editor) return "";

    const selection = editor.state.selection;
    if (isNodeSelection(selection) && selection.node.type.name === "image") {
      return selection.node.attrs.src;
    }
    return "";
  };

  const addSymbol = (url: string) => {
    if (!editor) return;

    // We select the added shape, so that shape selections while the picker is
    // open replace the added shape.
    const initialSelection = editor.state.selection;
    const pos = Math.min(initialSelection.$anchor.pos, initialSelection.$head.pos);

    editor.chain().setImage({ src: url }).setNodeSelection(pos).run();
  };

  return {
    view(vnode) {
      latestVnode = vnode;
      return m(".doc-editor-rich", [
        m(".doc-editor-rich-wrap"),
        m(RichTextSymbolsMenuButton, { getSymbolValue, addSymbol }),
      ]);
    },
    oncreate(vnode) {
      const el = domForVnode(vnode).querySelector(".doc-editor-rich-wrap");
      const { value, editable } = vnode.attrs;

      const extensions: Extensions = [DocumentRichText, Paragraph, Text, InlineImage];
      if (editable) {
        extensions.push(History, DropCursor);
      }

      editor = new Editor({
        element: el as HTMLElement,
        editable,
        extensions,
        content: richTextToDoc(value),
        onUpdate: ({ editor }) => {
          if (el && editable) {
            const doc = editor.getJSON();
            latestVnode.attrs.onchange(docToRichText(doc));
          }
        },
        onBlur: ({ editor }) => {
          if (el && editable) {
            const doc = editor.getJSON();
            latestVnode.attrs.onchange(docToRichText(doc));
          }
        },
        editorProps: {
          attributes: {
            spellcheck: "false",
          },
          transformPastedHTML,
        },
      });

      if (vnode.attrs.autoselect) {
        editor.commands.focus();
        editor.commands.selectAll();
      } else {
        // Default selection (cursor) at the end, so symbols are added at the
        // end if added before focusing the text.
        editor.commands.setTextSelection(editor.state.doc.content.size);
      }

      // Testing
      // (window as any).ed = editor;
    },
    onupdate(vnode) {
      if (editor) {
        let { value } = vnode.attrs;
        if (isString(value)) {
          value = new RichText(value);
        }
        const rich = docToRichText(editor.getJSON());
        if (!value.equals(rich)) {
          editor.commands.setContent(richTextToDoc(value));
        }
      }
    },
    onremove() {
      if (editor) {
        editor.destroy();
      }
    },
  };
};

function richTextToDoc(rich: RichText | string): JSONContent {
  if (isString(rich)) {
    rich = new RichText(rich);
  }
  const content = rich.split("\n").map((line) => {
    return {
      type: "paragraph",
      content: line.items
        .map((item) => {
          if (isString(item)) {
            // text node's text can't be ""
            return item.length > 0 ? { type: "text", text: item } : undefined;
          } else if (item instanceof RichTextGlyph) {
            return { type: "image", attrs: { "data-glyph-index": item.index, alt: item.index } };
          } else if (item instanceof RichTextSymbol) {
            return { type: "image", attrs: { src: item.source } };
          }
        })
        .filter(Boolean) as JSONContent["content"],
    };
  });
  return {
    type: "doc",
    content,
  };
}

function docToRichText(doc: JSONContent): RichText {
  const rich = new RichText();
  if (doc.content) {
    for (let node of doc.content) {
      if (node.type === "paragraph") {
        // No newline for first paragraph
        if (rich.items.length > 0) {
          rich.append("\n");
        }
        if (node.content) {
          for (let child of node.content) {
            if (child.type === "text" && child.text) {
              rich.append(child.text);
            } else if (child.type === "image") {
              console.log("child", child);
              if (child.attrs?.src) {
                rich.append(new RichTextSymbol(child.attrs.src));
              } else if (child.attrs?.["data-glyph-index"]) {
                const index = parseInt(child.attrs["data-glyph-index"]);
                rich.append(new RichTextGlyph(index));
              }
            }
          }
        } else {
          // Needed to start RichText with a newline.
          rich.append("");
        }
      }
    }
  }
  return rich;
}

const RichTextSymbolsMenuButton: m.Component<{
  getSymbolValue: () => string;
  addSymbol: (url: string) => void;
}> = {
  view(vnode) {
    const openPopup = () => {
      const { getSymbolValue, addSymbol } = vnode.attrs;
      globalState.isPickerOpen = true;
      const gestureId = globalState.startGesture("Rich Text Symbol Picker");
      createPopup({
        view: () =>
          m(ShapePickerPopup, {
            value: getSymbolValue(),
            onchange: (value: string) => {
              addSymbol(value);
            },
          }),
        spawnFrom: domForVnode(vnode),
        placement: "top-end",
        onclose: () => {
          globalState.isPickerOpen = false;
          globalState.stopGesture(gestureId);
        },
        closeOnEnter: true,
      });
    };
    return m(".rich-text-symbols-menu-button", [
      m(IconButton, { icon: "insert_symbol", label: "Insert Symbol", onclick: openPopup }),
    ]);
  },
};
