import { Vec } from "../geom";

async function loadPDFDependencies() {
  try {
    const deps = await import("../dynamic-import/pdf-dependencies");
    return deps;
  } catch (error) {
    throw new Error("Failed to load PDF dependencies: " + error);
  }
}

export const PDFBlobFromSVGString = (svgString: string, pageSize: Vec) => {
  return new Promise<Blob>((resolve) => {
    loadPDFDependencies().then(({ PDFDocument, blobStream, SVGtoPDF }) => {
      const doc = new PDFDocument({ autoFirstPage: false });
      doc.addPage({ size: [pageSize.x, pageSize.y] });
      SVGtoPDF(doc, svgString, 0, 0, { assumePt: true });

      const stream = doc.pipe(blobStream());
      stream.on("finish", () => {
        const blob: Blob = stream.toBlob("application/pdf");
        resolve(blob);
      });
      doc.end();
    });
  });
};

// via https://gist.github.com/timdown/cfacd32f6b5e439bb02aaf142343ce4c A lot of
// this code is to work around Firefox issues. See this bug to see when we can
// simplify this. https://bugzilla.mozilla.org/show_bug.cgi?id=911444
export const printPDFBlobUsingIframe = (() => {
  // Firefox requires a delay between loading the iframe and calling print(),
  // otherwise it fails with an uncatchable error.
  const isFirefox = /Gecko\/\d/.test(navigator.userAgent);
  const isSafari = /Safari\/\d/.test(navigator.userAgent);

  // Specify the aforementioned delay for Firefox. 1000 seems to work. 100 is not enough.
  const nonChromeDelay = 1000;

  let iframe: HTMLIFrameElement;
  return (blob: Blob) => {
    const blobUrl = URL.createObjectURL(blob);
    if (iframe) {
      iframe.parentNode?.removeChild(iframe);
    }
    iframe = document.createElement("iframe");
    iframe.style.cssText =
      "width: 1px; height: 100px; position: fixed; left: 0; top: 0; opacity: 0; border-width: 0; margin: 0; padding: 0";
    iframe.src = blobUrl;

    iframe.addEventListener("load", () => {
      const printIframe = () => {
        try {
          iframe.focus();
          if (iframe.contentWindow === null) {
            throw "No browsing context";
          }
          try {
            iframe.contentWindow.document.execCommand("print", false);
          } catch (e) {
            iframe.contentWindow.print();
          }
        } catch (error) {
          console.error("Print failed: " + error, error);
        } finally {
          iframe.style.visibility = "hidden";
          iframe.style.left = "-1px";
          URL.revokeObjectURL(blobUrl);
        }
      };
      // Add a delay for Firefox and Safari.
      if (isFirefox || isSafari) {
        window.setTimeout(printIframe, nonChromeDelay);
      } else {
        printIframe();
      }
    });

    document.body.appendChild(iframe);
  };
})();
