import { assert, scaleFactorForUnitConversion } from "../geom";
import { paintGraphicToCanvas } from "../geom/io/canvas";
import { FramedGraphic } from "./framed-graphic";

export interface ExportPNGOptions {
  // Will be "transparent" if not specified.
  backgroundColor?: string;

  // Specified in the framed graphic's units.
  hairlineStrokeWidthPixels?: number;
}

// An estimate to not run out of memory with components with big dimensions.
const CANVAS_MAX_PIXEL_COUNT = 4096 * 4096;

const dummyCanvas = document.createElement("canvas");
const dummyCtx = dummyCanvas.getContext("2d");

export const PNGBlobFromFramedGraphic = (
  framedGraphic: FramedGraphic<"px">,
  options: ExportPNGOptions
) => {
  assert(framedGraphic.units === "px", "Framed graphics must be converted to points");

  return new Promise<Blob>((resolve, reject) => {
    if (!dummyCtx) {
      return reject("Failed to create PNG: No canvas context");
    }

    let hairlineStrokeWidth = options.hairlineStrokeWidthPixels;
    if (hairlineStrokeWidth) {
      const unitToPixelsScale = scaleFactorForUnitConversion(framedGraphic.units, "px");
      hairlineStrokeWidth *= unitToPixelsScale;
    }

    let graphic = framedGraphic.graphic;

    const canvasSize = framedGraphic.artboard.size().ceil();
    const pixelCount = canvasSize.x * canvasSize.y;
    if (pixelCount > CANVAS_MAX_PIXEL_COUNT) {
      // If the canvas size is too large scale it to fit within the maximum
      // pixel allocation.
      const scaleFactor = CANVAS_MAX_PIXEL_COUNT / pixelCount;
      canvasSize.mulScalar(scaleFactor).floor(); // Floor here so we don't exceed the max pixels again.
      graphic = graphic.clone().transform({ scale: scaleFactor }).scaleStroke(scaleFactor);
      if (hairlineStrokeWidth) {
        // If you actually wanted a massive PNG, you can scale this up, and the
        // hairline strokes will be scale up to the defined width.
        hairlineStrokeWidth *= scaleFactor;
      }

      // TODO: Move this toasting to view code.
      // let message = `The component is too large to export a PNG at full size, ${canvasSize.x}×${canvasSize.y} pixels.`;
      // message += ` Export scaled down to ${canvasSize.x}×${canvasSize.y} pixels.`;
      // // Make it smaller to fit.
      // toastState.showBasic({ type: "info", message });
    }

    dummyCanvas.width = canvasSize.x;
    dummyCanvas.height = canvasSize.y;

    if (options.backgroundColor) {
      dummyCtx.fillStyle = options.backgroundColor;
      dummyCtx.fillRect(0, 0, canvasSize.x, canvasSize.y);
    }

    paintGraphicToCanvas(graphic, dummyCtx, canvasSize, { hairlineStrokeWidth });

    dummyCanvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject("Failed to create PNG: Couldn't create a blob from the canvas");
      }
    }, "image/png");
  });
};
