import m from "mithril";

import { globalState } from "../global-state";
import { Icon20, IconName } from "../shared/icon";
import { Accelerator } from "../shared/keyboard";
import { Tooltipped } from "../shared/popup";
import { classNames } from "../shared/util";
import { ToolName } from "./tool/shared";

interface CanvasToolButtonAttrs {
  toolName: ToolName;
  icon: IconName;
  tooltip: string;
  accelerator?: Accelerator;
}
const ToolButton: m.Component<CanvasToolButtonAttrs> = {
  view({ attrs: { toolName, icon, tooltip, accelerator } }) {
    const isDisabled = globalState.tools[toolName].isDisabled?.();
    const className = classNames({
      disabled: isDisabled,
      active: globalState.activeToolName === toolName,
    });
    return m(
      Tooltipped,
      {
        message: () => [
          tooltip,
          accelerator && m("span.tooltip-accelerator", accelerator.toString()),
        ],
      },
      [
        m(
          `.tool-button.${toolName}`,
          {
            className,
            onclick: isDisabled
              ? undefined
              : (event: MouseEvent) => {
                  event.stopPropagation();
                  event.stopImmediatePropagation();
                  globalState.activateTool(toolName);
                },
          },
          m(Icon20, { icon })
        ),
      ]
    );
  },
};

interface ToolbarToggleAttrs {
  icon: IconName;
  isActive: boolean;
  tooltip: string;
  accelerator?: Accelerator;
  onclick: () => void;
  className?: string;
}
const ToolbarToggle: m.Component<ToolbarToggleAttrs> = {
  view({ attrs: { icon, isActive, tooltip, accelerator, onclick, className: customClassName } }) {
    let className = classNames({
      active: isActive,
    });
    if (customClassName) className += " " + customClassName;
    return m(
      Tooltipped,
      {
        message: () => [
          tooltip,
          accelerator && m("span.tooltip-accelerator", accelerator.toString()),
        ],
      },
      [m(".tool-button", { className, onclick }, m(Icon20, { icon }))]
    );
  },
};

export const Toolbar: m.Component = {
  view() {
    return m(".viewport-top-bar", [
      m(".toolbar-left"),
      m(".toolbar", [
        m(ToolButton, {
          toolName: "Select",
          icon: "select",
          tooltip: "Select",
          accelerator: new Accelerator("v"),
        }),
        m(ToolButton, {
          toolName: "Pen",
          icon: "pen",
          tooltip: "Pen",
          accelerator: new Accelerator("p"),
        }),
        m(".divider"),
        m(ToolButton, {
          toolName: "Rotate",
          icon: "rotate",
          tooltip: "Rotate",
          accelerator: new Accelerator("r"),
        }),
        m(ToolButton, {
          toolName: "Scale",
          icon: "scale",
          tooltip: "Scale Uniform",
          accelerator: new Accelerator("s"),
        }),
        m(ToolButton, {
          toolName: "Scale 1D",
          icon: "scale_linear",
          tooltip: "Scale Stretch",
        }),
        m(".divider"),
        m(ToolbarToggle, {
          icon: "grid_snap",
          tooltip: "Grid Snapping",
          isActive: globalState.deviceStorage.gridSnappingEnabled,
          accelerator: new Accelerator("g"),
          onclick: () =>
            (globalState.deviceStorage.gridSnappingEnabled =
              !globalState.deviceStorage.gridSnappingEnabled),
        }),
        m(ToolbarToggle, {
          icon: "geometry_snap",
          isActive: globalState.deviceStorage.geometrySnappingEnabled,
          tooltip: "Geometry Snapping",
          accelerator: new Accelerator("u"),
          onclick: () =>
            (globalState.deviceStorage.geometrySnappingEnabled =
              !globalState.deviceStorage.geometrySnappingEnabled),
        }),
      ]),
      m(".toolbar-right"),
    ]);
  },
};
