import m from "mithril";

import { scaleFactorForUnitConversion, valueByUnitConversion, Vec } from "../../geom";
import { RichText } from "../../geom/text/rich-text";
import { globalState } from "../../global-state";
import { CharacterRange, numberRangesForString } from "../../model/code-ranges";
import { Expression } from "../../model/expression";
import {
  expressionCodeForColor,
  expressionCodeForNumber,
  expressionCodeForRichText,
  expressionCodeForString,
  expressionCodeForValueWithUnit,
} from "../../model/expression-code";
import { CodeEditorSelection, DocumentationFocus } from "../../model/focus";
import {
  ParameterInterface,
  PIAngle,
  PIBoolean,
  PIColor,
  PICount,
  PIDistance,
  PIEmoji,
  PIFontSelect,
  PIImage,
  PIImageTransform,
  PIPercentage,
  PIPoint,
  PIScalar,
  PISelect,
  PISelectOption,
  PISize,
  PIText,
  PIVector,
} from "../../model/parameter-interface";
import { LiteralNumber, parseLiteral } from "../../model/parse-literal";
import { ContextSelectable } from "../../model/selectable";
import { ExpressionTrace } from "../../model/trace";
import { IconButton } from "../../shared/icon";
import { Tooltipped } from "../../shared/popup";
import { escapeStringLiteral, precisionDigitsForNumberString } from "../../util";
import { Checkbox } from "../basic/checkbox";
import { ColorPicker } from "../color-picker";
import { DocEditorRich } from "../doc-editor/doc-editor-rich";
import { EmojiPicker } from "../emoji-picker";
import { ExpressionEditor } from "../expression-editor";
import { FontPicker } from "../font-picker";
import { ImageInput } from "../image-input";
import { NumberScrubber } from "../number-scrubber";
import { UnitSelect } from "../unit-select";

interface ParameterInputAttrs {
  expression: Readonly<Expression>;
  expressionTrace?: ExpressionTrace;
  parameterInterface?: ParameterInterface;
  isImmutable?: boolean;
  forceMultiline?: boolean;
  isEditingExpression?: boolean;
  selectable?: ContextSelectable;
  onChangeCode?: (jsCode: string) => void;
  onCommitCode?: () => void;
  onChangeSelection?: (selections: CodeEditorSelection[]) => void;
  onExpressionEditorFocus?: () => void;
  onExpressionEditorBlur?: () => void;
  mixedHint?: m.Children;
}
export const ParameterInput: m.ClosureComponent<ParameterInputAttrs> = (initialVNode) => {
  let isCodeFocused = false;
  let initialSelections: CodeEditorSelection[] | "all" | undefined;

  // Stash expression code so that scrub events have access to the latest value.
  let latestExpressionCode = initialVNode.attrs.expression.latestCode();

  return {
    view({
      attrs: {
        expression,
        expressionTrace,
        parameterInterface,
        isImmutable,
        forceMultiline,
        isEditingExpression,
        selectable,
        onChangeCode,
        onCommitCode,
        onChangeSelection,
        onExpressionEditorFocus,
        onExpressionEditorBlur,
        mixedHint,
      },
    }) {
      latestExpressionCode = expression.latestCode();
      const isMultiline = forceMultiline || latestExpressionCode.includes("\n");

      const setFocusedExpressionSelections = (selections: CodeEditorSelection[] | "all") => {
        globalState.project.focusExpression(expression, selections);
        initialSelections = selections;
      };
      const clearFocusedExpression = () => {
        globalState.project.blurExpression();
      };

      const onSelectRange = (range: CharacterRange | "all") => {
        isCodeFocused = true;
        if (range === "all") {
          setFocusedExpressionSelections(range);
        } else {
          setFocusedExpressionSelections([
            {
              anchor: { line: 0, ch: range.begin },
              head: { line: 0, ch: range.end },
            },
          ]);
        }
      };

      const onFocus = () => {
        isCodeFocused = true;
        globalState.project.focusExpression(expression);
        onExpressionEditorFocus?.();
      };
      const onBlur = () => {
        isCodeFocused = false;
        onExpressionEditorBlur?.();
        clearFocusedExpression();
      };
      const onExpressionEditorChangeSelection = (selections: CodeEditorSelection[]) => {
        setFocusedExpressionSelections(selections);
        onChangeSelection?.(selections);
      };

      if (isEditingExpression && !isCodeFocused) {
        onSelectRange("all");
      }

      if (!isEditingExpression && !isMultiline && !isCodeFocused) {
        const onLiteralChangeRange = (
          range: CharacterRange,
          value: number,
          fractionDigits: number
        ) => {
          const codePrefix = latestExpressionCode.slice(0, range.begin);
          const codeSuffix = latestExpressionCode.slice(range.end);
          let codeValue = value.toFixed(fractionDigits);
          // Prevent "-0.00"
          if (+codeValue === 0) {
            codeValue = (0).toFixed(fractionDigits);
          }

          // The length of the value string may change, so we need to adjust the
          // range. Otherwise the prefix and suffix may not be sliced correctly
          // on subsequent changes.
          range.end = range.begin + codeValue.length;

          latestExpressionCode = codePrefix + codeValue + codeSuffix;
          onLiteralChange(latestExpressionCode);
        };

        const onLiteralChange = (jsCode: string) => {
          onChangeCode?.(jsCode);
        };

        const literalInput = literalInputForExpressionCode(
          latestExpressionCode,
          parameterInterface,
          Boolean(isImmutable),
          onLiteralChange,
          onLiteralChangeRange,
          onSelectRange,
          mixedHint
        );
        if (literalInput) return literalInput;
      }

      // Ignore selection and focus changes while editing the Read Me. This
      // fixes the wrong project parameter expression being focused, which is an
      // issue in the Read Me becuase there can be multiple project parameter
      // inspectors.
      //
      // TODO: Revisit this once parameter undo/redo works reliably in the Read
      // Me.
      let forceFocused: boolean | undefined;
      let forceSelections: CodeEditorSelection[] | "all" | undefined;
      const isEmbed = globalState.project.focus instanceof DocumentationFocus;
      if (!isEmbed) {
        const { expressionFocus } = globalState.project;
        if (expressionFocus && expressionFocus.expression === expression) {
          forceFocused = true;
          forceSelections = expressionFocus.selections;
        }
      }

      return [
        m(".expression-input", [
          m(ExpressionEditor, {
            expression,
            expressionTrace,
            readOnly: isImmutable,
            forceSelections,
            forceFocused,
            forceMultiline,
            initialSelections,
            selectable,
            onFocus,
            onBlur,
            onChangeCode,
            onCommitCode,
            onChangeSelection: onExpressionEditorChangeSelection,
          }),
        ]),
        mixedHint,
      ];
    },
  };
};

const literalInputForExpressionCode = (
  expressionCode: string,
  parameterInterface: ParameterInterface | undefined,
  readOnly: boolean,
  onChange: (expressionCode: string) => void,
  onChangeRange: (range: CharacterRange, value: number, fractionDigits: number) => void,
  onSelectRange: (range: CharacterRange | "all") => void,
  mixedHint: m.Children
) => {
  let mEditExpressionButton = m(".edit-expression", {
    onclick: () => onSelectRange("all"),
    tabIndex: -1,
  });

  const literal = parseLiteral(expressionCode);
  if (!literal) return;

  let mUnitSelect: m.Child;
  if (literal.type === "Number" || literal.type === "Vec") {
    const { unit } = literal;
    if (unit) {
      mUnitSelect = m(UnitSelect, {
        value: unit,
        onChange: (newUnit) => {
          const newValue = valueByUnitConversion(literal.value, unit, newUnit);
          const newExpression = expressionCodeForValueWithUnit(newValue, newUnit);
          onChange(newExpression);
        },
      });
    }
  }

  if (literal.type === "Number") {
    const ranges = numberRangesForString(expressionCode);
    if (ranges.length !== 1) return;
    const range = ranges[0];

    const fractionDigits = precisionDigitsForParameterLiteralNumber(literal, parameterInterface);

    if (
      parameterInterface === undefined ||
      parameterInterface instanceof PIScalar ||
      parameterInterface instanceof PIAngle ||
      parameterInterface instanceof PIDistance ||
      parameterInterface instanceof PICount
    ) {
      return m(".literal-input", [
        m(NumberScrubber, {
          stringValue: range.literal.string,
          suffix: parameterInterface instanceof PIAngle ? "°" : undefined,
          readOnly,
          fractionDigits,
          onChange(value, fractionDigits) {
            onChangeRange(range, value, fractionDigits);
          },
          onClick() {
            onSelectRange(range);
          },
          tabIndex: 0,
          onFocus() {
            onSelectRange(range);
          },
        }),
        mUnitSelect,
        mixedHint,
        mEditExpressionButton,
      ]);
    }

    if (parameterInterface instanceof PIPercentage) {
      return m(".literal-input", [
        m(NumberScrubber, {
          stringValue: expressionCodeForNumber(literal.value * 100),
          suffix: "%",
          readOnly,
          fractionDigits: Math.max(0, fractionDigits - 2),
          onChange(value, fractionDigits) {
            onChangeRange(range, value / 100, fractionDigits + 2);
          },
          onClick() {
            onSelectRange(range);
          },
          tabIndex: 0,
          onFocus() {
            onSelectRange(range);
          },
        }),
        mixedHint,
        mEditExpressionButton,
      ]);
    }
  }

  if (literal.type === "Boolean") {
    if (parameterInterface === undefined || parameterInterface instanceof PIBoolean) {
      const checkboxClick = (event: Event) => {
        if ((event.target as HTMLInputElement).checked) {
          onChange("true");
        } else {
          onChange("false");
        }
      };
      return m(
        ".literal-input.boolean-input",
        m(Checkbox, {
          checked: literal.value,
          disabled: readOnly,
          mixed: Boolean(mixedHint),
          onclick: checkboxClick,
        }),
        mixedHint,
        mEditExpressionButton
      );
    }
  }

  if (literal.type === "Vec") {
    if (
      parameterInterface === undefined ||
      parameterInterface instanceof PIVector ||
      parameterInterface instanceof PIPoint
    ) {
      const ranges = numberRangesForString(expressionCode);
      if (ranges.length !== 2) return;
      const [xRange, yRange] = ranges;
      return m(
        ".literal-input.vec-input",
        // no tabIndex here because we want the individual components of the
        // vector to be focusable
        [
          m(NumberScrubber, {
            stringValue: xRange.literal.string,
            prefix: "x",
            readOnly,
            fractionDigits: precisionDigitsForParameterLiteralNumber(literal.x, parameterInterface),
            onChange(value, fractionDigits) {
              onChangeRange(xRange, value, fractionDigits);
            },
            onClick() {
              onSelectRange(xRange);
            },
            tabIndex: 0,
            onFocus() {
              onSelectRange(xRange);
            },
          }),
          m(NumberScrubber, {
            stringValue: yRange.literal.string,
            prefix: "y",
            readOnly,
            fractionDigits: precisionDigitsForParameterLiteralNumber(literal.y, parameterInterface),
            onChange(value, fractionDigits) {
              onChangeRange(yRange, value, fractionDigits);
            },
            onClick() {
              onSelectRange(yRange);
            },
            tabIndex: 0,
            onFocus() {
              onSelectRange(yRange);
            },
          }),
          mUnitSelect,
          mixedHint,
          mEditExpressionButton,
        ]
      );
    }
  }

  if (parameterInterface instanceof PIColor) {
    if (literal.type !== "Color" && literal.type !== "String") return;
    if (literal.type === "String" && literal.value !== "none") return;
    const color = literal.type === "String" ? "none" : literal.value;
    return m(".literal-input.color-input", [
      m(ColorPicker, {
        color,
        onchange: (newColor) => {
          if (newColor === "none") {
            onChange(`"none"`);
          } else {
            onChange(expressionCodeForColor(newColor));
          }
        },
      }),
      mixedHint,
      mEditExpressionButton,
    ]);
  }

  if (parameterInterface instanceof PISize) {
    let mInput: m.Children | undefined;
    let isUniform = false;

    const ranges = numberRangesForString(expressionCode);

    if (literal.type === "Vec" && ranges.length === 2) {
      const [xRange, yRange] = ranges;
      const xDigits = precisionDigitsForParameterLiteralNumber(literal.x, parameterInterface);
      const yDigits = precisionDigitsForParameterLiteralNumber(literal.y, parameterInterface);
      mInput = [
        m(NumberScrubber, {
          stringValue: xRange.literal.string,
          prefix: "x",
          readOnly,
          fractionDigits: xDigits,
          onChange(value: number) {
            onChangeRange(xRange, value, xDigits);
          },
          onClick() {
            onSelectRange(xRange);
          },
          tabIndex: 0,
          onFocus() {
            onSelectRange(yRange);
          },
        }),
        m(NumberScrubber, {
          stringValue: yRange.literal.string,
          prefix: "y",
          readOnly,
          fractionDigits: yDigits,
          onChange(value: number) {
            onChangeRange(yRange, value, yDigits);
          },
          onClick() {
            onSelectRange(yRange);
          },
          tabIndex: 0,
          onFocus() {
            onSelectRange(yRange);
          },
        }),
      ];
    } else if (literal.type === "Number" && ranges.length === 1) {
      const scalarRange = ranges[0];
      const fractionDigits = precisionDigitsForParameterLiteralNumber(literal, parameterInterface);
      mInput = m(NumberScrubber, {
        stringValue: scalarRange.literal.string,
        prefix: "xy",
        readOnly,
        fractionDigits,
        onChange(value: number) {
          onChangeRange(scalarRange, value, fractionDigits);
        },
        onClick() {
          onSelectRange(scalarRange);
        },
        tabIndex: 0,
        onFocus() {
          onSelectRange(scalarRange);
        },
      });
      isUniform = true;
    }

    if (!mInput) return;

    return m(".literal-input.size-input", [
      mInput,
      mUnitSelect,
      m(Tooltipped, { message: () => (isUniform ? "Uniform Scale" : "Non-uniform Scale") }, [
        m(IconButton, {
          icon: isUniform ? "uniform_scale" : "non_uniform_scale",
          onclick: () => {
            if (literal.type === "Number") {
              // Spread the uniform scale to the X and Y scale.
              const newCode = expressionCodeForValueWithUnit(new Vec(literal.value), literal.unit);
              onChange(newCode);
            } else if (literal.type === "Vec") {
              // Take the X scale as the uniform scale.
              const newCode = expressionCodeForValueWithUnit(literal.value.x, literal.unit);
              onChange(newCode);
            }
          },
        }),
      ]),
      mixedHint,
      mEditExpressionButton,
    ]);
  }

  // NOTE: Must come before PISelect, since PIImageTransform extends PISelect.
  if (parameterInterface instanceof PIImageTransform) {
    let { options } = parameterInterface;

    let value: string;
    if (literal.type === "String") {
      value = literal.value;
    } else if (literal.type === "AffineMatrix") {
      options = [...options, { label: "custom", value: "custom" }];
      value = "custom";
    } else {
      return;
    }

    return m(".literal-input.select-input", [
      m(Select, { value, options, readOnly, onChange }),
      mixedHint,
      mEditExpressionButton,
    ]);
  }

  if (parameterInterface instanceof PISelect) {
    if (literal.type !== "String") return;
    const { options } = parameterInterface;
    const valueExistsInOptions = options.some((option) => option.value === literal.value);
    if (valueExistsInOptions) {
      return m(".literal-input.select-input", [
        m(Select, { value: literal.value, options, readOnly, onChange }),
        mixedHint,
        mEditExpressionButton,
      ]);
    }
  }

  if (parameterInterface instanceof PIEmoji) {
    if (literal.type !== "String") return;
    return m(".literal-input", [
      m(EmojiPicker, {
        value: literal.value,
        onchange: (newValue) => onChange(expressionCodeForString(newValue)),
      }),
      mEditExpressionButton,
    ]);
  }

  if (parameterInterface instanceof PIFontSelect) {
    if (literal.type !== "String") return;
    const { recommendedValues } = parameterInterface;
    return m(FontPicker, {
      value: literal.value,
      onchange: (newValue) => onChange(expressionCodeForString(newValue)),
      recommendedValues,
    });
  }

  if (parameterInterface instanceof PIText) {
    let richTextValue: RichText | undefined;
    if (literal.type === "RichText") {
      richTextValue = literal.value;
    }
    if (literal.type === "String") {
      richTextValue = new RichText(literal.value);
    }
    if (!richTextValue) return;
    return m(".literal-input.text-literal-input", [
      m(DocEditorRich, {
        value: richTextValue,
        editable: !readOnly,
        onchange: (newValue) => {
          if (richTextValue && !newValue.equals(richTextValue)) {
            onChange(expressionCodeForRichText(newValue));
            m.redraw();
          }
        },
      }),
      mixedHint,
    ]);
  }

  if (parameterInterface instanceof PIImage) {
    if (literal.type !== "String") return;
    const isTemporary = !globalState.isEditingMode();
    return m(ImageInput, {
      url: literal.value,
      onChange: (url) => {
        if (url !== literal.value) {
          onChange(`"${escapeStringLiteral(url)}"`);
        }
      },
      isTemporary,
    });
  }

  return;
};

const precisionDigitsForParameterLiteralNumber = (
  literal: LiteralNumber,
  parameterInterface: ParameterInterface | undefined
) => {
  const maxAutoPrecisuinDigits = 3;

  if (parameterInterface === undefined || parameterInterface.precisionDigits === "auto") {
    return Math.min(maxAutoPrecisuinDigits, precisionDigitsForNumberString(literal.string));
  }

  if (parameterInterface.precisionDigits === "nudge") {
    const focusedComponent = globalState.project.focusedComponent();
    if (!focusedComponent) {
      // In embedded component context, use the string precision.
      return Math.min(maxAutoPrecisuinDigits, precisionDigitsForNumberString(literal.string));
    }

    const viewport = globalState.viewportManager.viewportForComponent(focusedComponent);
    let precisionDigits = viewport.precisionInfo().fractionDigits;
    if (literal.unit) {
      // Adjust precision to account for the scale of the literal unit relative
      // to the project unit.
      const projectUnit = globalState.project.settings.units;
      const scaleFactor = scaleFactorForUnitConversion(projectUnit, literal.unit);
      precisionDigits = Math.max(0, precisionDigits - Math.round(Math.log10(scaleFactor)));
    }
    return precisionDigits;
  }

  return parameterInterface.precisionDigits;
};

interface SelectAttrs {
  value: string;
  options: ReadonlyArray<PISelectOption>;
  readOnly: boolean;
  onChange: (value: string) => void;
}
const Select: m.Component<SelectAttrs> = {
  view({ attrs: { value, options, readOnly, onChange } }) {
    const selectedOptionIndex = options.findIndex((option) => option.value === value);
    if (selectedOptionIndex < 0) return;
    const onchange = (event: Event) => {
      const index = (event.target as HTMLSelectElement).selectedIndex;
      onChange(expressionCodeForString(options[index].value));
    };
    return m(
      "select",
      { onchange, disabled: readOnly },
      options.map((option, i) => {
        return m("option", { selected: selectedOptionIndex === i }, option.label);
      })
    );
  },
};
