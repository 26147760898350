import { rotateArray } from "../geom";
import { simplifyHierarchy } from "../geom-internal";
import { globalState } from "../global-state";
import { GroupDefinition, PathDefinition, StrokeDefinition } from "../model/builtin-primitives";
import { DependencyGraph } from "../model/dependency-graph";
import { Expression } from "../model/expression";
import { Instance } from "../model/instance";
import { InstanceDefinition } from "../model/instance-definition";
import { Modifier } from "../model/modifier";
import { Node } from "../model/node";
import { SelectableInstance } from "../model/selectable";
import { Selection } from "../model/selection";
import { toastState } from "./toast-message";

export const bringSelectedNodesToFront = () => {
  const project = globalState.project;
  const selection = project.selection.allNodes().mutables().sortInDocumentOrder();
  for (let item of selection.items) {
    const parent = item.node.parent as Node; // We know that selected nodes have a parent
    project.spliceNodes([item.node], [item.node.source], parent, parent.childCount());
  }
};

export const bringSelectedNodesForward = () => {
  const project = globalState.project;
  const selection = project.selection.allNodes().mutables().sortInDocumentOrder().reverse();
  for (let item of selection.items) {
    const parent = item.node.parent as Node; // We know that selected nodes have a parent
    project.spliceNodes([item.node], [item.node.source], parent, item.node.indexInParent() + 2);
  }
};

export const sendSelectedNodesBackward = () => {
  const project = globalState.project;
  const selection = project.selection.allNodes().mutables().sortInDocumentOrder();
  let prevParent: Node | undefined;
  let prevInsertionIndex: number | undefined;
  for (let item of selection.items) {
    const parent = item.node.parent as Node;
    const indexInParent = item.node.indexInParent();
    const insertionIndex = Math.max(0, indexInParent - 1);
    // Checking if nodes are already at the back of the current context so that
    // the second selected one doesn't skip behind the first
    if (prevParent?.equalsNode(parent) && insertionIndex === prevInsertionIndex) {
      prevInsertionIndex = indexInParent;
    } else {
      prevInsertionIndex = insertionIndex;
      project.spliceNodes([item.node], [item.node.source], parent, insertionIndex);
    }
    prevParent = parent;
  }
};

export const sendSelectedNodesToBack = () => {
  const project = globalState.project;
  const selection = project.selection.allNodes().mutables().sortInDocumentOrder().reverse();
  for (let item of selection.items) {
    const parent = item.node.parent as Node;
    project.spliceNodes([item.node], [item.node.source], parent, 0);
  }
};

export const convertSelectedNodesToPaths = () => {
  const project = globalState.project;
  const selection = project.selection.allNodes().mutables().sortInDocumentOrder();
  const bakedNodes: Node[] = [];
  for (let item of selection.items) {
    const trace = globalState.traceForNode(item.node);
    if (!trace?.isSuccess()) return;

    let simplifiedGeometry = simplifyHierarchy(trace.result.clone());
    if (!simplifiedGeometry) continue;

    const element = project.bakeGeometry(simplifiedGeometry);
    if (element) {
      const insertionParent = item.node.parent as Node;
      const insertionIndex = item.node.indexInParent();
      const [newNode] = project.spliceNodes(
        [item.node],
        [element],
        insertionParent,
        insertionIndex
      );
      bakedNodes.push(newNode);
    }
  }
  project.selectNodes(bakedNodes);
};

export const extractAsComponent = () => {
  const project = globalState.project;
  const nodes = project.selection.allNodes().mutables().sortInDocumentOrder().toNodes();
  project.extractAsComponent(nodes);
};

export const wrapSelectionInModifier = (selection: Selection, definition: Modifier) => {
  const { project } = globalState;

  const nodes = selection.allNodes().topNodes().mutables().sortInDocumentOrder().toNodes();

  const wrappingElement = project.createElementWithDefinition(definition);
  wrappingElement.children = nodes.map((node) => node.source);

  const { insertionParent, insertionIndex } = project.insertionPoint();

  const [wrappingNode] = project.spliceNodes(
    nodes,
    [wrappingElement],
    insertionParent,
    insertionIndex
  );
  project.selectNode(wrappingNode);
  project.expandNode(wrappingNode);

  return wrappingNode;
};
export const wrapProjectSelectionInModifier = (definition: Modifier) => {
  return wrapSelectionInModifier(globalState.project.selection, definition);
};
export const wrapProjectSelectionInModifierAction = (definition: Modifier) => {
  return () => wrapProjectSelectionInModifier(definition);
};

export const badgeSelectedNodesWithNewModifierAction = () => {
  const modifier = globalState.project.createModifier();
  const instance = badgeSelectedNodesWithModifier(modifier);
  if (instance) {
    globalState.project.setEditingCode(instance, true);
    globalState.autoSelectForRename = modifier;
  }
};
export const badgeSelectedNodesWithModifier = (modifier: Modifier) => {
  const { project } = globalState;

  // If there is a single instance selected and nothing else, append the new
  // modifier after the selected instance.
  if (project.selection.isSingle() && project.selection.allInstances().isSingle()) {
    const { node, instance } = project.selection.items[0] as SelectableInstance;
    const modifierIndex = node.source.modifiers.indexOf(instance);
    const modifierInstance = new Instance(modifier);
    node.source.modifiers.splice(modifierIndex + 1, 0, modifierInstance);
    project.selectItem(new SelectableInstance(node, modifierInstance));
    return modifierInstance;
  }

  const nodesSelection = project.selection.allNodes().sortInDocumentOrder();
  if (nodesSelection.isEmpty()) return undefined;

  // If a single node is selected we can simply append the modifier to the top
  // if the node's modifier stack.
  if (nodesSelection.isSingle()) {
    let { node } = nodesSelection.items[0];
    if (node.isAnchor() && !project.isNodeExpanded(node.parent!)) {
      // A common problem for new users is that they will draw a path with the
      // pen tool and then immediately apply a modifier while the path is
      // focused. If the last anchor is selected this will apply the modifier to
      // the anchor, when the intention was to apply it to the path. We special
      // case this behavior here so that if only one anchor is selected in a
      // path that is not expanded we apply the modifier to the parent path.
      node = node.parent!;
    }
    const modifierInstance = new Instance(modifier);
    node.source.modifiers.push(modifierInstance);
    project.selectItem(new SelectableInstance(node, modifierInstance));
    return modifierInstance;
  }

  const groupNode = wrapSelectionInModifier(nodesSelection, GroupDefinition);
  const modifierInstance = new Instance(modifier);
  groupNode.source.modifiers.push(modifierInstance);
  project.selectItem(new SelectableInstance(groupNode, modifierInstance));
  return modifierInstance;
};
export const badgeSelectedNodesWithModifierAction = (modifier: Modifier) => {
  return () => badgeSelectedNodesWithModifier(modifier);
};

export const removeUnusedModifiersAction = () => {
  const graph = new DependencyGraph(globalState.project);
  const unusedModifiers = graph.unusedModifiers();
  for (const modifier of unusedModifiers) {
    globalState.project.removeModifier(modifier);
  }
  const message =
    "Removed unused modifiers: " + unusedModifiers.map((modifier) => modifier.name).join(", ");
  toastState.showBasic({
    type: "info",
    message,
    nextStep: "You can undo if this isn't what you expected.",
  });
};

/** Note: leaves things unevaluated b/c calls project.reparentNodes */
export const unwrapSelectedNodesWithDefinition = (definition: InstanceDefinition) => {
  const { project } = globalState;
  const nodes = project.selection.allNodes().mutables().sortInDocumentOrder().toNodes();
  const nodeArraysToSelect: Node[][] = [];
  for (let node of nodes) {
    if (node.hasDefinition(definition)) {
      const insertionParent = node.parent!; // Selectable nodes always have a parent
      const insertionIndex = node.indexInParent();
      const reparentedNodes = project.reparentNodes(
        node.childNodes(),
        insertionParent,
        insertionIndex
      );
      for (let reparentNode of reparentedNodes) {
        const el = reparentNode.source;
        // If children have no style, copy parent style, or add default stroke
        if (el.base.definition === PathDefinition && !el.fill?.isEnabled && !el.stroke?.isEnabled) {
          if (node.source.stroke?.isEnabled) {
            el.stroke = node.source.stroke.clone();
          } else {
            // Default stroke
            el.stroke = new Instance(StrokeDefinition);
          }
        }
      }
      if (reparentedNodes) {
        nodeArraysToSelect.push(reparentedNodes);
      }
      project.spliceNodes([node]);
    } else {
      nodeArraysToSelect.push([node]);
    }
  }
  if (nodeArraysToSelect.length > 0) {
    project.selectNodes(nodeArraysToSelect.flat());
  }
};

export const ungroupSelectedNodes = () => {
  unwrapSelectedNodesWithDefinition(GroupDefinition);
};

export const ungroupAllSelectedNodes = () => {
  const { project } = globalState;
  const nodes = project.selection.allNodes().mutables().sortInDocumentOrder().toNodes();
  const nodeArraysToSelect: Node[][] = [];
  for (let node of nodes) {
    if (node.hasDefinition(GroupDefinition)) {
      const childrenToReparent: Node[] = [];
      const recurse = (children: Node[]) => {
        children.forEach((child) => {
          if (child.hasDefinition(GroupDefinition)) {
            recurse(child.childNodes());
          } else {
            childrenToReparent.push(child);
          }
        });
      };
      recurse(node.childNodes());
      const reparentedNodes = project.reparentNodes(
        childrenToReparent,
        node.parent!, // Selectable nodes always have a parent
        node.indexInParent()
      );
      if (reparentedNodes) {
        nodeArraysToSelect.push(reparentedNodes);
      }
      project.spliceNodes([node]);
    } else {
      nodeArraysToSelect.push([node]);
    }
  }
  if (nodeArraysToSelect.length > 0) {
    project.selectNodes(nodeArraysToSelect.flat());
  }
};

export const splitPathAtAnchor = () => {
  const selection = globalState.project.selection.directlySelectedNodes().sortInDocumentOrder();
  for (const anchorNode of selection.toNodes()) {
    if (!anchorNode.isAnchor()) continue;

    // TODO: Rewrite this algorithm so that multiple splits in the same path are
    // possible. Currently a split will invalidate subsequent nodes, since we
    // iterate in document order. We want new elements to be created and
    // appended in document order. We could reverse the order and the current
    // logic would work, but it would reusult in opposite ordering and naming of
    // newly created paths.
    if (!globalState.project.nodeExists(anchorNode)) continue;

    const pathNode = anchorNode.parent;
    if (!pathNode || !pathNode.isPath()) continue;

    const closedExpr = pathNode.source.base.expressionForParameterWithName("closed");
    if (!closedExpr?.isLiteral()) continue;

    const isClosed = Boolean(closedExpr.literalValue());
    if (isClosed) {
      // Open the path.
      pathNode.source.base.args.closed = new Expression("false");

      // Rotate the path's children so that the selected anchor is first.
      const anchorIndex = anchorNode.indexInParent();
      rotateArray(pathNode.sourceChildren(), anchorIndex);

      // Make a copy of the anchor and append it to the path.
      const newAnchorElem = anchorNode.source.clone();
      const newNodes = globalState.project.spliceNodes([], [newAnchorElem], pathNode);
      globalState.project.selectNodes(newNodes);
    } else {
      if (!pathNode.parent) continue;

      const anchorIndex = anchorNode.indexInParent();

      // Can't split at the endpoints.
      if (anchorIndex === 0) continue;
      if (anchorIndex === pathNode.childCount() - 1) continue;

      // Copy the path and split its children
      const newPathElem = globalState.project.duplicateElementWithoutChildren(pathNode.source);
      const [newPathNode] = globalState.project.spliceNodes([], [newPathElem], pathNode.parent);

      // Copy the anchor and append it to the new path.
      const newAnchorElem = globalState.project.duplicateElement(anchorNode.source);
      const nodesToMove = pathNode.childNodes().slice(anchorIndex + 1);
      globalState.project.spliceNodes(
        nodesToMove,
        [newAnchorElem, ...nodesToMove.map((node) => node.source)],
        newPathNode
      );

      globalState.project.selectNode(newPathNode);
    }
  }
};
export const canSplitPathAtAnchor = () => {
  const selection = globalState.project.selection.directlySelectedNodes();
  return selection.isSingle() && selection.hasAnchor();
};

export const reversePathDirection = () => {
  for (let item of globalState.project.selection.items) {
    for (let node of item.nodes()) {
      if (node.source.children.length > 1) {
        node.source.children.reverse();
      }
      for (let childNode of node.childNodes()) {
        if (childNode.isAnchor()) {
          const { args } = childNode.source.base;
          const { handleIn, handleOut } = args;
          if (handleIn) {
            args.handleOut = handleIn;
          } else {
            delete args.handleOut;
          }
          if (handleOut) {
            args.handleIn = handleOut;
          } else {
            delete args.handleIn;
          }
        }
      }
    }
  }
};
export const canReversePathDirection = () => {
  // The selection has something with children
  return globalState.project.selection
    .directlySelectedNodes()
    .items.some(({ node }) => node.source.children.length > 1);
};
