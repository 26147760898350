import { isString, scaleFactorForUnitConversion } from "../geom";
import { globalState } from "../global-state";
import { CodeComponent, Component } from "../model/component";
import { ExportFormat } from "../model/export-format";
import { ProjectSettings } from "../model/project";
import { sanitizeFilename } from "../util";
import { ExportSVGOptions } from "./export-svg";

/**
 * The place where we record decisions about exporting a specific component.
 */
export interface ExportOptions {
  component: Component | CodeComponent;
  filename: string;
  format: ExportFormat;
}

/**
 * When exporting one or more project components, first use this function to
 * generate their initial export options. Then if necessary, a UI can be
 * presented to allow the user to change the export options before download.
 */
export const exportOptionsForComponents = (
  components: (Component | CodeComponent)[],
  customFormat?: ExportFormat
) => {
  const exportOptions: ExportOptions[] = [];
  for (const component of components) {
    const options = exportOptionsForComponent(component, customFormat);
    if (options) exportOptions.push(options);
  }
  return exportOptions;
};

/**
 * The multiple component version of this function should be preferred, but this
 * single component version is useful in specific cases where a custom name is
 * required.
 */
export const exportOptionsForComponent = (
  component: Component | CodeComponent,
  customFormat?: ExportFormat,
  customFilename?: string
) => {
  const format = customFormat ?? component.defaultExportFormat;
  if (format === "none") return undefined;
  const options: ExportOptions = {
    component,
    format,
    filename: filenameForComponent(component, format, customFilename),
  };
  return options;
};

const filenameForComponent = (
  component: Component | CodeComponent,
  customFormat: ExportFormat,
  customFilename?: string
) => {
  const extension = "." + customFormat;

  // Custom filename already provided.
  if (customFilename) {
    return sanitizeFilename(customFilename, extension);
  }

  // Check for a "_filename" magic parameter.
  if (component.hasParameterWithName("_filename")) {
    const trace = globalState.traceForComponent(component);
    if (trace?.isSuccess()) {
      const filename = trace.resultForArgName("_filename");
      if (isString(filename)) {
        const sanitizedFilename = sanitizeFilename(filename, extension);
        // Return the custom filename if we didn't sanitize it to death.
        if (sanitizedFilename !== extension) return sanitizedFilename;
      }
    }
  }

  return sanitizeFilename(
    `${globalState.storage.getProjectName()} - ${component.name}` + extension,
    extension
  );
};

export const exportSVGOptionsForPrint = (settings: ProjectSettings): ExportSVGOptions => {
  const pointsPerInch = scaleFactorForUnitConversion("in", "pt");
  const pointsPerUnit = scaleFactorForUnitConversion(globalState.project.settings.units, "pt");
  return {
    hairlineStrokeWidth: settings.hairlineStrokeWidth * pointsPerUnit,
    rasterizeImages: settings.rasterizeExportedImages,
    rasterizeImagesPixelsPerUnit: settings.rasterizeExportedImagesPixelsPerInch / pointsPerInch,
    maximumFractionDigits: 6,
  };
};
