import {
  Color,
  CompoundPath,
  Containment,
  Fill,
  Graphic,
  Group,
  Path,
  PathContainment,
  Stroke,
  groupBy,
} from "../geom";

export const prepareGraphicForCricut = (graphic: Graphic) => {
  // Re-group paths into compound paths by color.
  const result = graphic
    .allPathsByColor()
    .map((paths) => new CompoundPath(paths).copyStyle(paths[0]));

  // Change black fills to 50% gray.
  const black = new Color(0, 0, 0, 1);
  const gray = new Color(0.5, 0.5, 0.5, 1);
  for (let item of result) {
    if (item.fill instanceof Fill && item.fill.color.equals(black)) {
      item.fill.color = gray;
    }
    if (item.stroke?.color.equals(black)) {
      item.stroke.color = gray;
    }
  }

  return new Group(result);
};

export const prepareGraphicForGlowforge = (graphic: Graphic) => {
  const outPaths: (Path | CompoundPath)[] = [];

  const flatPaths = graphic
    .allPathsAndCompoundPaths()
    .filter((path) => {
      // Filter out filled paths. We consider these to be engraves, so no
      // preprocessing is required.
      if (path.fill) {
        outPaths.push(path);
        return false;
      }
      return true;
    })
    .flatMap((path) => {
      if (path instanceof CompoundPath) {
        return path.paths.map((innerPath) => innerPath.copyStyle(path));
      }
      return path;
    });

  let lastAppendedColorNumber = -1;

  const appendContainments = (
    containments: PathContainment[],
    originalColorNumber: number,
    inverseDepth: number
  ) => {
    // Add the distance from the maximum depth.
    let colorNumber = originalColorNumber + inverseDepth;

    // Clamp to white.
    colorNumber = Math.min(colorNumber, 0xffffff);

    const stroke = new Stroke(Color.fromRGB8Number(colorNumber));
    for (const { path, contained } of containments) {
      path.removeFill();
      path.assignStroke(stroke);
      outPaths.push(path);
      if (contained.length > 0) {
        appendContainments(contained, originalColorNumber, inverseDepth - 1);
      }
    }

    lastAppendedColorNumber = colorNumber;
  };

  // Group paths by stroke color into an array of [stroke color, paths]
  const colorGroups = Array.from(
    groupBy(flatPaths, (path) => {
      if (!path.stroke) return -1;
      // Return the numeric version of the color. This is more convenient to
      // compare and increment later.
      return path.stroke.color.toRGB8Number();
    })
  );

  // Sort lower color numbers in front.
  colorGroups.sort((a, b) => a[0] - b[0]);

  for (let [colorNumber, group] of colorGroups) {
    if (colorNumber === -1) {
      outPaths.push(...group);
    } else {
      const containment = Containment.fromPaths(group);
      const maxDepth = containment.maximumDepth();
      if (colorNumber <= lastAppendedColorNumber) {
        colorNumber = lastAppendedColorNumber + 1;
      }
      appendContainments(containment.contained, colorNumber, maxDepth - 1);
    }
  }

  return new Group(outPaths);
};
