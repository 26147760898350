/* This should replace text-editor eventually. */

import m from "mithril";

import { Editor, Node } from "@tiptap/core";
import type { Extensions, JSONContent } from "@tiptap/core";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import History from "@tiptap/extension-history";

import { domForVnode } from "../../shared/util";
import { globalState } from "../../global-state";

// Limited schema: doc can only have one paragraph, and no marks for now.
const Document = Node.create({
  name: "doc",
  topNode: true,
  content: "paragraph+",
  marks: "",
});

interface DocEditorTextAttrs {
  value: string;
  editable: boolean;
  placeholder: string;
  /** Hit on every change. */
  onchange: (value: string) => void;
  autofocus?: boolean;
}
export const DocEditorText: m.ClosureComponent<DocEditorTextAttrs> = (initialVnode) => {
  let latestVnode = initialVnode;
  let editor: Editor | undefined;
  return {
    view(vnode) {
      latestVnode = vnode;
      return m(".doc-editor-text");
    },
    oncreate(vnode) {
      const el = domForVnode(vnode);
      const { value, editable, placeholder, autofocus } = vnode.attrs;

      const extensions: Extensions = [Document, Paragraph, Text];
      if (editable) {
        extensions.push(
          // Shown with empty content
          Placeholder.configure({ placeholder }),
          History
        );
      }

      editor = new Editor({
        element: el as HTMLElement,
        editable,
        extensions,
        content: textToDoc(value),
        onUpdate: ({ editor }) => {
          if (el && editable) {
            const text = editor.getText({ blockSeparator: "\n" }) || "";
            latestVnode.attrs.onchange(text || "");
          }
        },
        onBlur: ({ editor }) => {
          if (el && editable) {
            // getText seems to be already trimmed, so the editor state is
            // different than the plain text value that it outputs. Make sure
            // the value and editor doc state are trimmed on blur.
            const text = editor.getText({ blockSeparator: "\n" }) || "";
            const trimmedText = text.trim();
            editor.commands.setContent(textToDoc(trimmedText));
            latestVnode.attrs.onchange(trimmedText);
          }
        },
      });

      if (autofocus) {
        editor.commands.focus();
        globalState.autoSelectForRename = undefined;
      }
    },
    onupdate(vnode) {
      if (editor) {
        const { value } = vnode.attrs;
        if (value !== editor.getText({ blockSeparator: "\n" })) {
          editor.commands.setContent(textToDoc(value));
        }
      }
    },
    onremove() {
      if (editor) {
        editor.destroy();
      }
    },
  };
};

function textToDoc(text: string): JSONContent {
  const content = text.split("\n").map((line) => ({
    type: "paragraph",
    // text node's text can't be ""
    content: line ? [{ type: "text", text: line }] : undefined,
  }));
  return {
    type: "doc",
    content,
  };
}
