import m from "mithril";
import { globalState } from "../global-state";
import { EditableText } from "../shared/editable-text";
import { UserLink } from "./project-meta";

interface TopMenuProjectAttrs {
  minWidthPixels: number;
}
export const TopMenuProject: m.Component<TopMenuProjectAttrs> = {
  view: ({ attrs: { minWidthPixels } }) => {
    // Don't show top menu in during "Getting Started" flow.
    if (globalState.storage.sequenceToLoad()) return;

    // Only show with canvas views, not with Settings or Read Me
    if (!globalState.project.focusedComponent()) return;

    let mOwner: m.Children;
    let mProjectName: m.Children;
    if (globalState.storage.hasWritePermission()) {
      mProjectName = m(".top-menu-project-name", [
        m(EditableText, {
          value: globalState.storage.getProjectName(),
          singleClick: true,
          onchange: (val) => globalState.storage.setProjectName(val),
        }),
      ]);
    } else {
      mOwner = m(UserLink, {
        username: globalState.storage.getProjectOwner(),
        displayName: globalState.storage.getProjectOwnerDisplayName(),
      });
      mProjectName = m(".top-menu-project-name", [globalState.storage.getProjectName()]);
    }

    return m(
      ".top-menu-project",
      {
        style: { "min-width": minWidthPixels + "px" },
      },
      m(".top-menu-project-title", [
        mOwner,
        mOwner && m(".top-menu-project-divider", "/"),
        mProjectName,
      ])
    );
  },
};
