import { Path, Vec } from "..";

/**
 * Plot a smooth function as a cubic bezier path
 *
 * Originally implemented by Jacob Rus in “Parametric curve to piecewise cubic
 * Bézier segments”: https://observablehq.com/@jrus/bezplot
 *
 * @param func A function taking a number `t` between `domainStart` and
 * `domainEnd` and returning a Vec. The function must be “smooth”.
 * @param domainStart The lowest value for `t` passed to `func`
 * @param domainEnd The highest value for `t` passed to `func`
 * @param tolerance Smaller tolerance values will result in a path that more
 * closely matches `func` but at the cost of adding more anchors
 */
export const pathFromSmoothFunction = (
  func: (t: number) => Vec,
  domainStart: number,
  domainEnd: number,
  tolerance: number
) => {
  // Evaluate function at endpoints and midpoint:
  const p0 = func(domainStart);
  const p3 = func(0.5 * (domainStart + domainEnd));
  const p6 = func(domainEnd);

  const points = cubicBezierPointsFromFunction(
    0,
    func,
    domainStart,
    domainEnd,
    tolerance,
    p0.x,
    p3.x,
    p6.x,
    p0.y,
    p3.y,
    p6.y
  );
  const closed = p0.distanceSquared(p6) <= tolerance * tolerance;

  return Path.fromCubicBezierPoints(points, closed);
};

const onethird = 1 / 3;
const chebpt7_1 = 0.5 - Math.sqrt(3) / 4;
const chebpt7_5 = 0.5 + Math.sqrt(3) / 4;

const MAX_SUBDIVISIONS = 32;

// Internal recursive helper function:
const cubicBezierPointsFromFunction = (
  i: number,
  func: (t: number) => Vec,
  t0: number,
  t6: number,
  tolerance: number,
  x0: number,
  x3: number,
  x6: number,
  y0: number,
  y3: number,
  y6: number
): Vec[] => {
  const td = t6 - t0;

  // Evaluate the function at the four new points for
  // this section, and find polynomial coefficients:
  const { x: x1, y: y1 } = func(t0 + td * chebpt7_1);
  const { x: x2, y: y2 } = func(t0 + td * 0.25);
  const { x: x4, y: y4 } = func(t0 + td * 0.75);
  const { x: x5, y: y5 } = func(t0 + td * chebpt7_5);

  const xcoeffs = vals2coeffs7(x0, x1, x2, x3, x4, x5, x6);
  const ycoeffs = vals2coeffs7(y0, y1, y2, y3, y4, y5, y6);
  let [xc0, xc1, xc2, xc3, xc4, xc5, xc6] = xcoeffs;
  let [yc0, yc1, yc2, yc3, yc4, yc5, yc6] = ycoeffs;

  const xresid = Math.abs(xc4) + Math.abs(xc5) + Math.abs(xc6); // to compare against the tolerance
  const yresid = Math.abs(yc4) + Math.abs(yc5) + Math.abs(yc6);

  // If we hit the desired tolerance, return a single bezier segment:
  if (xresid < tolerance && yresid < tolerance) {
    // Alias degree 6 polynomial to degree 3:
    (xc0 += xc6), (xc1 += xc5), (xc2 += xc4);
    (yc0 += yc6), (yc1 += yc5), (yc2 += yc4);

    // Convert from Chebyshev to Bernstein basis, and return:
    const xt0 = (3 * xc0 - 5 * xc2) * onethird;
    const xt1 = (15 * xc3 - xc1) * onethird;
    const yt0 = (3 * yc0 - 5 * yc2) * onethird;
    const yt1 = (15 * yc3 - yc1) * onethird;

    return [
      new Vec(x0, y0),
      new Vec(xt0 + xt1, yt0 + yt1),
      new Vec(xt0 - xt1, yt0 - yt1),
      new Vec(x6, y6),
    ];
  }

  if (++i > MAX_SUBDIVISIONS) {
    throw `Maximum subdivisions exceeded!
Make sure your function is smooth or try a larger tolerance.`;
  }

  // If we don't hit the tolerance, recursively bisect the domain:
  const leftPoints = cubicBezierPointsFromFunction(
    i,
    func,
    t0,
    0.5 * (t0 + t6),
    tolerance,
    x0,
    x2,
    x3,
    y0,
    y2,
    y3
  );
  const rightPoints = cubicBezierPointsFromFunction(
    i,
    func,
    0.5 * (t0 + t6),
    t6,
    tolerance,
    x3,
    x4,
    x6,
    y3,
    y4,
    y6
  );

  // Combine Bezier path sections from the left and right halves:
  leftPoints.pop();
  leftPoints.push(...rightPoints);
  return leftPoints;
};

const c0 = Math.sqrt(3); // "twiddle" factor
// output scaling factors
const d0 = 1 / 6;
const d1 = 1 / 12;
const vals2coeffs7 = (
  x0: number,
  x1: number,
  x2: number,
  x3: number,
  x4: number,
  x5: number,
  x6: number
) => {
  const z0 = x6 + x0;
  const z1 = x6 - x0;
  const z2 = x5 + x1;
  const z3 = c0 * (x5 - x1);
  const z4 = x4 + x2;
  const z5 = x4 - x2;
  const z6 = 2 * x3;
  const w0 = z0 + z6;
  const w1 = z0 - z6;
  const w2 = z2 + z4;
  const w3 = z2 - z4;
  const w4 = z1 + z5;
  return [
    d1 * (w0 + 2 * w2),
    d0 * (w4 + z3),
    d0 * (w1 + w3),
    d0 * (z1 - 2 * z5),
    d0 * (w0 - w2),
    d0 * (w4 - z3),
    d1 * (w1 - 2 * w3),
  ];
};
