import { IconName } from "../shared/icon";
import { Element } from "./element";
import { ExportFormat } from "./export-format";
import { Expression } from "./expression";
import { InstanceDefinition } from "./instance-definition";
import { registerClass } from "./registry";

export class Component extends InstanceDefinition {
  projectId = "";

  element: Element;

  defaultStyle: "none" | "fill" | "stroke" = "stroke";
  icon?: IconName;

  /** Export file type, or "none" to hide the download button in the packaged view. */
  defaultExportFormat: ExportFormat | "none";

  // Scales the component to the closest power of 10 such that a unit-square
  // fits on the canvas.
  isAutoScale = false;

  isPro = false;

  materialKeys() {
    return [...super.materialKeys(), "projectId", "element", "defaultExportFormat", "isAutoScale"];
  }

  hasContent() {
    return this.element.children.length > 0;
  }

  constructor(element: Element) {
    super();
    this.element = element;
    this.defaultExportFormat = "svg";
  }

  clone() {
    const component = new Component(this.element.clone());

    component.name = this.name;
    component.parameters = this.parameters.map((parameter) => parameter.clone());
    component.parameterFolders = this.parameterFolders.map((folder) => folder.clone());

    component.isPassThrough = this.isPassThrough;
    component.isShowGuides = this.isShowGuides;
    component.isImmutable = this.isImmutable;
    component.isDefaultUniformScale = this.isDefaultUniformScale;
    component.isAutoScale = this.isAutoScale;
    component.isPro = this.isPro;

    component.comment = this.comment;

    component.defaultStyle = this.defaultStyle;
    component.defaultExportFormat = this.defaultExportFormat;

    return component;
  }
}
registerClass("Component", Component);

export class CodeComponent extends InstanceDefinition {
  projectId = "";

  code = new Expression();

  defaultStyle: "none" | "fill" | "stroke" = "stroke";
  icon?: IconName;

  defaultExportFormat: ExportFormat | "none" = "svg";

  // Scales the component to the closest power of 10 such that a unit-square
  // fits on the canvas.
  isAutoScale = false;

  isPro = false;

  materialKeys() {
    return [...super.materialKeys(), "projectId", "code", "defaultExportFormat", "isAutoScale"];
  }
  hasContent() {
    return true;
  }

  clone() {
    const component = new CodeComponent();
    component.code = this.code.clone();

    component.name = this.name;
    component.parameters = this.parameters.map((parameter) => parameter.clone());
    component.parameterFolders = this.parameterFolders.map((folder) => folder.clone());

    component.isPassThrough = this.isPassThrough;
    component.isShowGuides = this.isShowGuides;
    component.isImmutable = this.isImmutable;
    component.isDefaultUniformScale = this.isDefaultUniformScale;
    component.isAutoScale = this.isAutoScale;
    component.isPro = this.isPro;

    component.comment = this.comment;

    component.defaultStyle = this.defaultStyle;
    component.defaultExportFormat = this.defaultExportFormat;

    return component;
  }
}
registerClass("CodeComponent", CodeComponent);
