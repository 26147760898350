import { Node, mergeAttributes } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import SuperExpressive from "super-expressive";
import { initDragHandle } from "./prosemirror-drag-handle";

// Translated to SuperExpressive from https://github.com/micnews/youtube-url/blob/master/index.js
// prettier-ignore
const youtubeRegExp = SuperExpressive()
  .startOfInput
  .string("http")
  .optional.string("s")
  .string("://")
  .optional.string("m.")
  .optional.string("www.")
  .anyOf
    .string("youtu.be/")
    .group
      .string("youtube.com/")
      .anyOf
        .string("embed/")
        .string("watch/")
        .string("shorts/")
        .string("v/")
        .string("?v=")
        .string("watch?v=")
      .end()
    .end()
  .end()
  .namedCapture("id")
    .oneOrMore
      .anyOf
        .word
        .string("-")
      .end()
  .end()
  .toRegex()

export const youtubeIdForURL = (url: string) => {
  const result = youtubeRegExp.exec(url.trim());
  if (!result || !result.groups) return undefined;
  return result.groups.id;
};

export interface VideoPlayerOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    videoPlayer: {
      /**
       * Add a video player custom element (YouTube only for now)
       */
      insertVideoPlayer: (options: { url: string }) => ReturnType;
    };
  }
}

export const VideoPlayerNode = Node.create<VideoPlayerOptions>({
  name: "videoPlayer",
  content: "",
  marks: "",
  group: "block",

  // Set with `extend` in DocEditor, if it is editable
  selectable: false,
  draggable: false,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  addAttributes() {
    return {
      videoid: {
        default: null,
      },
      provider: {
        default: "youtube",
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "figure",
        getAttrs: (el: HTMLElement | string) => {
          if (typeof el === "string") {
            return false;
          }
          if (!(el instanceof HTMLElement)) {
            return false;
          }
          const videoid = el.getAttribute("videoid");
          if (!videoid) return false;
          return {
            videoid,
            provider: "youtube",
          };
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    const figureAttrs = mergeAttributes(
      { class: "doc-video-player" },
      this.options.HTMLAttributes,
      HTMLAttributes
    );
    return ["figure", figureAttrs];
  },
  renderText({ node }) {
    return `[Cuttle video embed: https://youtu.be/${node.attrs.videoid}]\n\n`;
  },
  addNodeView() {
    // NodeViewRenderer returns a ProseMirror NodeView. Reference:
    // https://prosemirror.net/docs/ref/#view.NodeView
    return (nodeViewRendererProps) => {
      const { node, HTMLAttributes } = nodeViewRendererProps;

      const dom = document.createElement("figure");
      dom.classList.add("doc-video-player");
      const figureAttrs = mergeAttributes(
        { class: "doc-video-player" },
        this.options.HTMLAttributes,
        HTMLAttributes
      );
      for (let attr of Object.keys(figureAttrs)) {
        dom.setAttribute(attr, figureAttrs[attr]);
      }

      const iframeWrapperEl = document.createElement("div");
      iframeWrapperEl.classList.add("doc-video-player-iframe-wrapper");

      dom.appendChild(iframeWrapperEl);

      // Assuming HTMLAttributes.provider is "youtube" for now
      const iframeAttrs = {
        src: `https://www.youtube-nocookie.com/embed/${HTMLAttributes.videoid}?rel=0&modestbranding=1&playsinline=1`,
        allow: "fullscreen; picture-in-picture;",
      };
      const iframeEl = document.createElement("iframe");
      iframeEl.setAttribute("src", iframeAttrs.src);
      iframeEl.setAttribute("allow", iframeAttrs.allow);

      iframeWrapperEl.appendChild(iframeEl);

      const figcaptionEl = document.createElement("figcaption");
      const aEl = document.createElement("a");
      aEl.setAttribute("href", `https://youtu.be/${HTMLAttributes.videoid}`);
      aEl.setAttribute("target", "_blank");
      aEl.setAttribute("rel", "noreferrer noopener nofollow");
      aEl.textContent = "Watch on YouTube";
      figcaptionEl.appendChild(aEl);

      dom.appendChild(figcaptionEl);

      const dragHandleNodeViewOptions = initDragHandle(dom, nodeViewRendererProps);

      return {
        dom,
        ...dragHandleNodeViewOptions,
      };
    };
  },
  addCommands() {
    return {
      insertVideoPlayer:
        (options) =>
        ({ chain, editor }) => {
          const { url } = options;
          const videoid = youtubeIdForURL(url);
          if (videoid) {
            const { selection } = editor.state;
            const pos = selection.$head;

            return chain()
              .insertContentAt(pos.before(), [
                {
                  type: this.name,
                  attrs: { videoid, provider: "youtube" },
                },
              ])
              .run();
          }
          return false;
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("handlePasteVideoURL"),
        props: {
          handlePaste: (view, _event, slice) => {
            // Only look at one-line paste content
            if (slice.content.childCount !== 1) return false;

            const { state } = view;
            const { selection } = state;
            const { empty } = selection;

            // Pass through if something is selected
            if (!empty) return false;

            const pos = selection.$head;
            const node = pos.node();

            // Only continue if pasting on empty line
            if (node.content.size > 0) return false;

            let textContent = "";

            slice.content.forEach((node) => {
              textContent += node.textContent;
            });

            const videoid = youtubeIdForURL(textContent);

            if (!videoid) return false;

            this.editor
              .chain()
              .insertContentAt(pos.before(), [
                {
                  type: this.name,
                  attrs: { videoid, provider: "youtube" },
                },
              ])
              .run();

            return true;
          },
        },
      }),
    ];
  },
});
