import m from "mithril";

import { domForVnode } from "../shared/util";
import { importFilesToProject } from "./import-to-project";

// via https://codepen.io/moier/pen/XmvQqG

interface FileDragEvent extends DragEvent {
  dataTransfer: DataTransfer;
}
interface FileDropAttrs {
  onDropFiles: (e: FileDragEvent) => void;
}
export const FileDrop: m.Component<FileDropAttrs> = {
  oncreate(vnode) {
    const dropZone = domForVnode(vnode);

    const isFileDrag = (e: DragEvent): e is FileDragEvent => {
      return Boolean(e.dataTransfer?.types?.includes("Files"));
    };

    const showDropZone = (e: DragEvent) => {
      if (!isFileDrag(e)) return;
      dropZone.style.display = "block";
    };
    const hideDropZone = () => {
      dropZone.style.display = "none";
    };
    const allowDrag = (e: DragEvent) => {
      if (!isFileDrag(e)) return;
      e.dataTransfer.dropEffect = "copy";
      e.preventDefault();
    };
    const handleDrop = (e: DragEvent) => {
      if (!isFileDrag(e)) return;
      e.preventDefault();
      vnode.attrs.onDropFiles(e);
      hideDropZone();
    };

    window.addEventListener("dragenter", showDropZone);
    dropZone.addEventListener("dragenter", allowDrag);
    dropZone.addEventListener("dragover", allowDrag);
    dropZone.addEventListener("dragleave", hideDropZone);
    dropZone.addEventListener("drop", handleDrop);
  },
  view() {
    return m(".file-drop-zone");
  },
};

export const ImportFileDrop: m.Component = {
  view() {
    return m(FileDrop, {
      onDropFiles: (e) => {
        importFilesToProject(Array.from(e.dataTransfer.files));
      },
    });
  },
};
