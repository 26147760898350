import m from "mithril";

import { Vec } from "../../geom";
import { globalState } from "../../global-state";
import { expressionCodeForNumber } from "../../model/expression-code";
import { LiteralNumber, parseLiteral } from "../../model/parse-literal";
import { EditableCode } from "../editable-code";
import { PreciseNumber } from "../number-scrubber";
import { toastState } from "../toast-message";

interface TransformBoxLabelAttrs {
  position: Vec;
  normal: Vec;
  rotation?: number;
}
export const TransformBoxLabel: m.Component<TransformBoxLabelAttrs> = {
  view({ attrs: { position, normal, rotation }, children }) {
    const angle = (normal.angle() - 90).toFixed(1);
    const tx = Math.round(position.x);
    const ty = Math.round(position.y);
    const transform = `translate(-50%,-50%) translate(${tx}px,${ty}px) rotate(${angle}deg) translate(0,20px) rotate(${
      rotation ?? 0
    }deg)`;

    return m("div.transform-box-label", { style: { transform } }, children);
  },
};

interface TransformBoxDimensionAttrs {
  name: string;
  value: number;
  fractionDigits: number;
  isLocked: boolean;
  onChange: (literal: LiteralNumber) => void;
}
export const TransformBoxDimension: m.Component<TransformBoxDimensionAttrs> = {
  view({ attrs: { name, value, fractionDigits, isLocked, onChange } }) {
    const stringValue = expressionCodeForNumber(value, fractionDigits);
    const mDisplayValue = [
      m(PreciseNumber, {
        stringValue,
        fractionDigits,
      }),
      m("span.number-unit", globalState.project.settings.units),
    ];

    if (isLocked) {
      return m(
        ".dimension-editor.locked",
        {
          onpointerdown: (event: PointerEvent) => {
            event.stopPropagation();
            toastState.showBasic({
              type: "error",
              message: `Can't change the ${name} because a formula is being used in the shape's scale.`,
              nextStep: "Try changing the scale in the Inspector.",
            });
          },
        },
        mDisplayValue
      );
    }

    return m(
      EditableCode,
      {
        value: stringValue,
        onChange: (newValue: string) => {
          const literal = parseLiteral(newValue);
          if (literal && literal.type === "Number") {
            onChange(literal);
          }
        },
      },
      m(".dimension-editor", mDisplayValue)
    );
  },
};
