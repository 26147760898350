import m from "mithril";
import { examplesConfig } from "../examples-config";
import {
  ExampleCategory,
  ExampleShape,
  makeEmojiExampleShape,
  nameForExampleShape,
} from "../examples-types";
import { globalState } from "../global-state";
import { logEvent } from "../shared/log-event";
import { shapeIndex } from "./shape-index";

export const QueryResultsNone: m.ClosureComponent<{ query: string }> = () => {
  let sentSuggestion = "";

  return {
    view(vnode) {
      const { query } = vnode.attrs;
      const isSent = query === sentSuggestion;
      return m(".query-results-none", [
        m("p", "We didn't find any shapes for the search ", m("strong", `“${query}”`)),
        m("p", "Suggest this shape for future inclusion?"),
        m(
          "button.query-results-none-send-suggestion",
          {
            disabled: isSent,
            onclick: () => {
              sentSuggestion = query;
              logEvent("creation panel suggestion", { query });
            },
          },
          isSent ? "Sent! Thanks." : "Send Suggestion"
        ),
      ]);
    },
  };
};

/*
 * CreationPanelState stuff.
 */

interface CategoryResult {
  category: ExampleCategory;
  results: ExampleShape[];
}

export const PAGE_PROJECT = 0;
export const PAGE_ALL = 1;
export const PAGE_CATEGORY = 2;

type CreationPanelPageIndex = 0 | 1 | 2;

class CreationPanelState {
  query: string = "";
  category: ExampleCategory | undefined;
  index: CreationPanelPageIndex = PAGE_PROJECT;
  /** Will update asynchronously after setQuery */
  results: CategoryResult[] = [];

  goTo(index: CreationPanelPageIndex) {
    this.index = index;
  }
  goBack() {
    if (this.index > 0) {
      this.index--;
    }
    if (this.index === PAGE_PROJECT) {
      // Clear query when going back to project
      this.setQuery("");
    }
  }
  async setQuery(query: string) {
    this.query = query;

    // Search switches page to "All"
    if (query !== "") {
      this.results = [];
      this.goTo(PAGE_ALL);
      await this.queryAndSetResults(query);
      m.redraw();
    } else {
      this.results = [];
    }
  }
  setCategory(category: ExampleCategory | undefined) {
    this.category = category;
    if (category) {
      this.goTo(PAGE_CATEGORY);
    } else {
      this.goTo(PAGE_ALL);
    }
  }
  private async queryAndSetResults(query: string) {
    if (query === "") return [];
    const querySplit = query.split(" ").filter((word) => word.length > 0);

    const emojiResults = shapeIndex.search(query).map(makeEmojiExampleShape);

    const generatedExamplesData = await globalState.ensureExamplesData();

    const categoryResults = examplesConfig.map((category): CategoryResult => {
      // Special case for emoji. We perform the search with our shapeIndex and
      // then stuff the results into the category here.
      if (category.category === "Emoji") {
        return { category, results: emojiResults };
      }

      const results: ExampleShape[] = [];
      for (const shape of category.shapes) {
        const name = nameForExampleShape(shape);
        const data = generatedExamplesData.exampleDataWithName(name);
        if (
          data?.keywords.some((keyword) => {
            return querySplit.some((queryWord) => {
              return keyword.includes(queryWord);
            });
          })
        ) {
          results.push(shape);
        }
      }
      return { category, results };
    });

    // Filter out categories with no results
    this.results = categoryResults.filter(({ results }) => results.length > 0);
  }
}

export const creationPanelState = new CreationPanelState();
