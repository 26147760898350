import { AffineMatrix, Color, RichText, RichTextGlyph, RichTextSymbol, Unit, Vec } from "../geom";
import { beautyStringForNumber } from "../geom/io/to-string";
import { isString } from "../shared/util";
import { escapeStringLiteral } from "../util";

export const expressionCodeForNumber = (x: number, minimumFractionDigits?: number) => {
  return beautyStringForNumber(x, minimumFractionDigits);
};

export const expressionCodeForString = (s: string) => {
  return `"${escapeStringLiteral(s)}"`;
};

export const expressionCodeForVec = (v: Vec, minimumFractionDigits?: number) => {
  const expr = (x: number) => expressionCodeForNumber(x, minimumFractionDigits);
  return `Vec(${expr(v.x)}, ${expr(v.y)})`;
};

export const expressionCodeForColor = (c: Color) => {
  return `Color(${c.r.toFixed(3)}, ${c.g.toFixed(3)}, ${c.b.toFixed(3)}, ${c.a.toFixed(3)})`;
};

export const expressionCodeForAffineMatrix = (m: AffineMatrix, minimumFractionDigits?: number) => {
  const { a, b, c, d, tx, ty } = m;
  const expr = (x: number) => expressionCodeForNumber(x, minimumFractionDigits);
  return `AffineMatrix(${expr(a)}, ${expr(b)}, ${expr(c)}, ${expr(d)}, ${expr(tx)}, ${expr(ty)})`;
};

export const expressionCodeForValue = (value: unknown, minimumFractionDigits?: number) => {
  if (typeof value === "number") return expressionCodeForNumber(value, minimumFractionDigits);
  if (typeof value === "string") return expressionCodeForString(value);
  if (value instanceof Vec) return expressionCodeForVec(value, minimumFractionDigits);
  if (value instanceof Color) return expressionCodeForColor(value);
  if (value instanceof AffineMatrix)
    return expressionCodeForAffineMatrix(value, minimumFractionDigits);
  if (value instanceof RichText) return expressionCodeForRichText(value);
  return JSON.stringify(value);
};

export const expressionCodeForValueWithUnit = (
  value: number | Vec,
  unit?: Unit,
  minimumFractionDigits?: number
) => {
  const valueExpressionCode = expressionCodeForValue(value, minimumFractionDigits);
  if (unit) return `${valueExpressionCode} ${unit}`;
  return valueExpressionCode;
};

export const expressionCodeForRichText = (richText: RichText) => {
  // Demote empty RichText to string literals.
  if (richText.items.length === 0) {
    return expressionCodeForString("");
  }

  // Demote trivial single item RichText to string literals.
  if (richText.items.length === 1 && isString(richText.items[0])) {
    return expressionCodeForString(richText.items[0]);
  }

  return `RichText(${richText.items
    .map((item) => {
      if (isString(item)) return expressionCodeForString(item);
      if (item instanceof RichTextSymbol) return expressionCodeForRichTextSymbol(item);
      if (item instanceof RichTextGlyph) return expressionCodeForRichTextGlyph(item);
    })
    .join(", ")})`;
};

const expressionCodeForRichTextSymbol = (richTextSymbol: RichTextSymbol) => {
  return `RichTextSymbol(${expressionCodeForString(richTextSymbol.source)})`;
};

const expressionCodeForRichTextGlyph = (richTextGlyph: RichTextGlyph) => {
  return `RichTextGlyph(${richTextGlyph.index})`;
};
