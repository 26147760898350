import { AffineMatrix, Anchor, BoundingBox, Path, assert } from "../geom";
import { globalState } from "../global-state";
import { CodeComponent, Component } from "./component";
import { Instance } from "./instance";
import { Modifier } from "./modifier";
import { Node } from "./node";
import { Parameter } from "./parameter";
import { Project } from "./project";
import { registerClass } from "./registry";
import {
  contextMatrixForInstance,
  contextMatrixForNode,
  handlePositionInfoForParameter,
  transformedGraphicForNode,
} from "./transform-utils";

export interface Selectable {
  isValid(): boolean;
  isImmutable(): boolean;
  equals(selectable: Selectable): boolean;

  nodes(): Node[];
  allNodes(): SelectableNode[];
  allInstances(): SelectableInstance[];

  worldBoundingBox(): BoundingBox | undefined;
}

export interface ContextSelectable extends Selectable {
  contextMatrix(): AffineMatrix;
}

export class SelectableNode implements Selectable {
  node: Node;

  constructor(node: Node) {
    this.node = node;
  }

  isValid() {
    return true;
  }
  isImmutable() {
    return this.node.isImmutable();
  }
  equals(selectable: Selectable): boolean {
    return selectable instanceof SelectableNode && this.node.equalsNode(selectable.node);
  }

  nodes() {
    return [this.node];
  }
  allNodes() {
    return [this];
  }
  allInstances() {
    return [];
  }

  worldBoundingBox() {
    return transformedGraphicForNode(this.node)?.boundingBox();
  }
}
registerClass("SelectableNode", SelectableNode);

export class SelectableInstance implements Selectable, ContextSelectable {
  node: Node;
  instance: Instance;

  constructor(node: Node, instance: Instance) {
    this.node = node;
    this.instance = instance;
  }

  isValid() {
    return this.node.source.hasInstance(this.instance);
  }
  isImmutable() {
    return this.node.isImmutable();
  }
  isBase() {
    return this.node.source.base === this.instance;
  }
  isComponent() {
    const { definition } = this.instance;
    return definition instanceof Component || definition instanceof CodeComponent;
  }
  isModifier() {
    return this.instance.definition instanceof Modifier;
  }
  equals(selectable: Selectable): boolean {
    return (
      selectable instanceof SelectableInstance &&
      this.node.equalsNode(selectable.node) &&
      this.instance === selectable.instance
    );
  }

  nodes() {
    return [this.node];
  }
  allNodes() {
    return [new SelectableNode(this.node)];
  }
  allInstances() {
    return [this];
  }

  definition() {
    return this.instance.definition;
  }

  worldBoundingBox() {
    const instanceTrace = this.instanceTrace();
    if (!instanceTrace?.isSuccess()) return undefined;

    // TODO: This may only work for Modifiers. Other instances probably want to
    // be transformed by the contextTransformMatrix
    const contextMatrix = contextMatrixForNode(this.node);
    return instanceTrace.result.clone().affineTransform(contextMatrix).boundingBox();
  }

  instanceTrace() {
    const trace = globalState.traceForNode(this.node);
    return trace?.traceForInstance(this.instance);
  }

  contextMatrix() {
    return contextMatrixForInstance(this.node, this.instance);
  }
}
registerClass("SelectableInstance", SelectableInstance);

export class SelectableParameter implements Selectable, ContextSelectable {
  node: Node;
  instance: Instance;
  parameter: Parameter;

  constructor(node: Node, instance: Instance, parameter: Parameter) {
    this.node = node;
    this.instance = instance;
    this.parameter = parameter;
  }

  static fromName(node: Node, instance: Instance, name: string) {
    const parameter = instance.definition.parameterWithName(name);
    assert(parameter !== undefined);
    return new SelectableParameter(node, instance, parameter);
  }

  isValid() {
    return (
      this.node.source.hasInstance(this.instance) &&
      this.instance.definition.parameters.includes(this.parameter)
    );
  }
  isImmutable() {
    return this.node.isImmutable();
  }
  equals(selectable: Selectable): boolean {
    return (
      selectable instanceof SelectableParameter &&
      this.node.equalsNode(selectable.node) &&
      this.instance === selectable.instance &&
      this.parameter === selectable.parameter
    );
  }

  nodes() {
    return [this.node];
  }
  allNodes() {
    return [new SelectableNode(this.node)];
  }
  allInstances() {
    return [new SelectableInstance(this.node, this.instance)];
  }

  worldBoundingBox() {
    const info = handlePositionInfoForParameter(this);
    if (!info) return;
    return new BoundingBox(info.handlePosition.clone(), info.handlePosition.clone());
  }

  expression() {
    return this.instance.args[this.parameter.name] ?? this.parameter.expression;
  }
  ensureEditableExpression(preferEditingDefinition = false) {
    const { name } = this.parameter;
    const { args } = this.instance;
    if (!args.hasOwnProperty(name)) {
      const definitionExpression = this.parameter.expression;
      if (preferEditingDefinition && !this.instance.definition.isImmutable) {
        return definitionExpression;
      }
      args[name] = definitionExpression.clone();
    }
    return args[name];
  }
  instanceTrace() {
    const trace = globalState.traceForNode(this.node);
    return trace?.traceForInstance(this.instance);
  }
  expressionTrace() {
    const instanceTrace = this.instanceTrace();
    return instanceTrace?.args[this.parameter.name];
  }
  definition() {
    return this.instance.definition;
  }

  isTransformable() {
    return this.expression().isLiteral();
  }

  isOverridden() {
    return this.instance.args.hasOwnProperty(this.parameter.name);
  }
  setAsDefault() {
    const { name } = this.parameter;
    const arg = this.instance.args[name];
    assert(arg, `Arg ${name} does not exist. Can't set as default`);
    this.parameter.expression.jsCode = arg.jsCode;
  }
  revertToDefault() {
    delete this.instance.args[this.parameter.name];
  }

  contextMatrix() {
    return contextMatrixForInstance(this.node, this.instance);
  }
}
registerClass("SelectableParameter", SelectableParameter);

export class SelectableComponentParameter implements Selectable, ContextSelectable {
  component: Project | Component | CodeComponent;
  parameter: Parameter;

  constructor(component: Project | Component | CodeComponent, parameter: Parameter) {
    this.component = component;
    this.parameter = parameter;
  }

  isValid() {
    return this.component.parameters.includes(this.parameter);
  }
  isImmutable() {
    return this.component.isImmutable;
  }
  equals(selectable: Selectable): boolean {
    return (
      selectable instanceof SelectableComponentParameter &&
      this.component === selectable.component &&
      this.parameter === selectable.parameter
    );
  }

  nodes() {
    return [];
  }
  allNodes() {
    return [];
  }
  allInstances() {
    return [];
  }

  worldBoundingBox() {
    const info = handlePositionInfoForParameter(this);
    if (!info) return;
    return new BoundingBox(info.handlePosition.clone(), info.handlePosition.clone());
  }

  expression() {
    return this.parameter.expression;
  }
  ensureEditableExpression() {
    return this.expression();
  }
  instanceTrace() {
    if (this.component instanceof Project) {
      return globalState.projectTrace?.base;
    }
    return globalState.traceForComponent(this.component);
  }
  expressionTrace() {
    return this.instanceTrace()?.args[this.parameter.name];
  }
  definition() {
    return this.component;
  }

  isTransformable() {
    return this.expression().isLiteral();
  }

  isOverridden() {
    return this.parameter.isOverridden();
  }
  revertToDefault() {
    this.parameter.revertToDefault();
  }

  contextMatrix() {
    return new AffineMatrix();
  }
}
registerClass("SelectableComponentParameter", SelectableComponentParameter);

export class SelectableSegment implements Selectable {
  node: Node;
  nextNode: Node;

  constructor(node: Node, nextNode: Node) {
    this.node = node;
    this.nextNode = nextNode;
  }

  isValid() {
    return (
      this.node.parent !== null &&
      this.nextNode.parent !== null &&
      this.node.parent.equalsNode(this.nextNode.parent)
    );
  }
  isImmutable() {
    return this.node.isImmutable() || this.nextNode.isImmutable();
  }
  equals(selectable: Selectable): boolean {
    return (
      selectable instanceof SelectableSegment &&
      this.node.equalsNode(selectable.node) &&
      this.nextNode.equalsNode(selectable.nextNode)
    );
  }

  nodes() {
    return [this.node, this.nextNode];
  }
  allNodes() {
    return [new SelectableNode(this.node), new SelectableNode(this.nextNode)];
  }
  allInstances() {
    return [];
  }

  worldBoundingBox() {
    const anchor1 = transformedGraphicForNode(this.node);
    const anchor2 = transformedGraphicForNode(this.node);
    if (anchor1 instanceof Anchor && anchor2 instanceof Anchor) {
      return new Path([anchor1, anchor2]).boundingBox();
    }
    return undefined;
  }

  isBendLocked() {
    return (
      isAnchorHandleLocked(this.node, "handleOut") ||
      isAnchorHandleLocked(this.nextNode, "handleIn")
    );
  }
  isMoveLocked() {
    return this.nodes().some((node) => node.source.isPositionLocked());
  }
}
registerClass("SelectableSegment", SelectableSegment);

const isAnchorHandleLocked = (node: Node, handleName: "handleIn" | "handleOut") => {
  if (node.source.base.args[handleName]?.isComputed()) return true;

  // If either handle is mirrored, we also need to check the opposite handle.
  const trace = globalState.traceForNode(node);
  if (!trace?.base.isSuccess()) return true;
  const constraint = trace.base.resultForArgName("handleConstraint");
  const oppositeHandleName = handleName === "handleIn" ? "handleOut" : "handleIn";
  if (constraint === "mirror" || constraint === "tangent") {
    if (node.source.base.args[oppositeHandleName]?.isComputed()) return true;
  }

  return false;
};
