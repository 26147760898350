const defaultFontFace =
  "-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol";

export default {
  black: "hsl(0, 0%, 0%)",
  white: "hsl(0, 0%, 100%)",

  gray96: "hsl(0, 0%, 96%)",
  gray94: "hsl(0, 0%, 94%)",
  gray91: "hsl(0, 0%, 91%)",
  gray80: "hsl(0, 0%, 80%)",
  gray70: "hsl(0, 0%, 70%)",
  gray50: "hsl(0, 0%, 50%)",
  gray24: "hsl(0, 0%, 24%)",

  whiteAlpha65: "rgba(255, 255, 255, 0.65)",
  whiteAlpha85: "rgba(255, 255, 255, 0.85)",
  whiteAlpha95: "rgba(255, 255, 255, 0.95)",

  blackAlpha03: "rgba(0, 0, 0, 0.03)",
  blackAlpha05: "rgba(0, 0, 0, 0.05)",
  blackAlpha10: "rgba(0, 0, 0, 0.1)",
  blackAlpha20: "rgba(0, 0, 0, 0.2)",
  blackAlpha42: "rgba(0, 0, 0, 0.42)", // Light Text, gets contrast ratio just above 3.0
  blackAlpha50: "rgba(0, 0, 0, 0.5)", // Icon Color
  blackAlpha60: "rgba(0, 0, 0, 0.6)", // Medium Text
  blackAlpha85: "rgba(0, 0, 0, 0.85)", // Default Text

  // Snapping, Guides
  red47: "hsl(12, 68%, 47%)",
  red47Alpha20: "hsla(12, 68%, 47%, 0.2)",
  red47Alpha40: "hsla(12, 68%, 47%, 0.4)",
  red47Alpha60: "hsla(12, 68%, 47%, 0.6)",
  red85: "hsl(0, 55%, 85%)",

  // Code warnings
  yellow85: "hsl(60, 60%, 85%)",

  // Selection, Buttons
  blue83: "hsl(200, 100%, 83%)",
  blue63: "hsl(200, 100%, 63%)",
  blue63Alpha10: "hsla(200, 100%, 63%, 0.1)",
  blue63Alpha20: "hsla(200, 100%, 63%, 0.2)",
  blue63Alpha80: "hsla(200, 100%, 63%, 0.8)",
  blue53: "hsl(200, 100%, 53%)",
  blue53Alpha20: "hsl(200, 100%, 53%, 0.2)",
  blue43: "hsl(200, 100%, 43%)",

  // Modifiers
  green85: "hsl(150, 61%, 85%)", // Overridden parameters
  green75: "hsl(150, 72%, 75%)",
  green60: "hsl(150, 73%, 60%)",
  green45: "hsl(150, 72%, 45%)",

  defaultFont12: `400 12px/16px ${defaultFontFace}`,
  defaultFont14: `400 14px/18px ${defaultFontFace}`,

  parameterNameWidth: 120,
};
