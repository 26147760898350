import { parse } from "acorn";
import type * as ESTree from "estree";

export const transformAst: (node: ESTree.Node) => ESTree.Node = (node) => {
  return walk(node) as ESTree.Node;
};

const parseKnownCodeToExpressionStatement: (input: string) => ESTree.ExpressionStatement = (
  input
) => {
  const program = parse(input, {
    // Since we're parsing known code here without anything fancy, this
    // ecmaVersion might make the parse simpler
    ecmaVersion: 3,
  });
  if (program.type !== "Program") {
    throw new Error("unexpected parse output");
  }
  const expressionStatement = (program as unknown as ESTree.Program).body[0];
  if (!expressionStatement || expressionStatement.type !== "ExpressionStatement") {
    throw new Error("unexpected parse output");
  }
  return expressionStatement;
};

const parseCallExpression: (input: string) => ESTree.CallExpression = (input) => {
  const expressionStatement = parseKnownCodeToExpressionStatement(input);
  const callExpression = expressionStatement.expression;
  if (!callExpression || callExpression.type !== "CallExpression") {
    throw new Error("unexpected parse output");
  }
  return callExpression;
};

const unaryOperatorSubs: { [operator: string]: string } = {
  "-": "_ENV.operatorOverloads.negate()",
};

const binaryOperatorSubs: { [operator: string]: string } = {
  "+": "_ENV.operatorOverloads.add()",
  "-": "_ENV.operatorOverloads.sub()",
  "*": "_ENV.operatorOverloads.mul()",
  "/": "_ENV.operatorOverloads.div()",
  "%": "_ENV.operatorOverloads.mod()",
  "==": "_ENV.operatorOverloads.doubleEquals()",
  "===": "_ENV.operatorOverloads.tripleEquals()",
  "!=": "_ENV.operatorOverloads.doubleNotEquals()",
  "!==": "_ENV.operatorOverloads.tripleNotEquals()",
};

const assignmentOperatorSubs: { [operator: string]: string } = {
  "+=": "_ENV.operatorOverloads.add()",
  "-=": "_ENV.operatorOverloads.sub()",
  "*=": "_ENV.operatorOverloads.mul()",
  "/=": "_ENV.operatorOverloads.div()",
  "%=": "_ENV.operatorOverloads.mod()",
};

const makeLineNumExpressionStatement: (num: number) => ESTree.ExpressionStatement = (num) => {
  const jsCode = `_ENV.lineNum(${num});`;
  return parseKnownCodeToExpressionStatement(jsCode);
};

const walk = (node: any): unknown => {
  if (!node) return node;

  // Nodes
  if (node.type === "UnaryExpression") {
    const operatorSub = unaryOperatorSubs[node.operator as string];
    if (operatorSub) {
      const replacement = parseCallExpression(operatorSub);
      replacement.arguments = [node.argument];
      return walk(replacement);
    }
  }

  if (node.type === "BinaryExpression") {
    const operatorSub = binaryOperatorSubs[node.operator];
    if (operatorSub) {
      const replacement = parseCallExpression(operatorSub);
      replacement.arguments = [node.left, node.right];
      return walk(replacement);
    }
  }

  if (node.type === "AssignmentExpression") {
    const operatorSub = assignmentOperatorSubs[node.operator];
    if (operatorSub) {
      const callExpression = parseCallExpression(operatorSub);
      callExpression.arguments = [node.left, node.right];
      const replacement = {
        type: "AssignmentExpression",
        operator: "=",
        left: node.left,
        right: callExpression,
      };
      return walk(replacement);
    }
  }

  // Interspersing _ENV.lineNum() calls
  if (node.body && Array.isArray(node.body) && node.type !== "ClassBody") {
    node = { ...node, body: enhanceWithLineNumbers(node.body) };
  } else if (node.consequent && Array.isArray(node.consequent)) {
    node = { ...node, consequent: enhanceWithLineNumbers(node.consequent) };
  }

  // Properties of nodes
  if (Array.isArray(node)) {
    // console.log(depth, item, parent);
    const result: unknown[] = [];
    for (let child of node) {
      result.push(walk(child));
    }
    return result;
  } else if (node && typeof node === "object") {
    const result: { [key: string]: unknown } = {};
    for (let key of Object.keys(node)) {
      // Added by acorn-globals. Don't walk parents, b/c it will break recursion
      if (key === "parents") continue;
      result[key] = walk(node[key]);
    }
    return result;
  } else {
    return node;
  }
};

const enhanceWithLineNumbers = (body: ESTree.Node[]) => {
  // TODO: node.body of `while(true);` is type EmptyStatement... look into
  // converting to BlockStatement and preventing runaway loops
  const enhancedBody: ESTree.Node[] = [];
  for (const childNode of body) {
    if (childNode) {
      if (childNode.loc) {
        // Using end line, b/c we want console and errors to show after the
        // source code location
        enhancedBody.push(makeLineNumExpressionStatement(childNode.loc.end.line));
      }
      enhancedBody.push(childNode);
    }
  }
  return enhancedBody;
};
