import { Extension } from "@tiptap/core";

// Hide bubble menu during selection
let selectionChanging = false;
const suppressSelectionMenu = (parentEl: HTMLElement) => {
  parentEl.setAttribute("data-selection-changing", "true");
};
const allowSelectionMenu = (parentEl: HTMLElement) => {
  parentEl.setAttribute("data-selection-changing", "false");
};
const selectionChanged = (parentEl: HTMLElement) => {
  if (selectionChanging) return;
  selectionChanging = true;
  suppressSelectionMenu(parentEl);
  const selectionEndHandler = () => {
    selectionChanging = false;
    allowSelectionMenu(parentEl);
    window.removeEventListener("pointerup", selectionEndHandler);
    window.removeEventListener("keyup", selectionEndHandler);
  };
  window.addEventListener("pointerup", selectionEndHandler);
  window.addEventListener("keyup", selectionEndHandler);
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    selectionChanging: {
      /**
       * Hmm.
       */
      cuttleAllowSelectionMenu: () => ReturnType;
    };
  }
}

export const SelectionChanging = Extension.create({
  name: "selectionChanging",
  addCommands() {
    return {
      cuttleAllowSelectionMenu:
        () =>
        ({ editor }) => {
          setTimeout(() => {
            const parentEl = editor.view.dom.parentElement;
            if (parentEl) allowSelectionMenu(parentEl);
          });
          return true;
        },
    };
  },
  onSelectionUpdate() {
    // parentEl has class .doc-editor-wrap, used in doc-editor.less to hide the
    // bubble selection menu
    const parentEl = this.editor.view.dom.parentElement;
    if (parentEl) selectionChanged(parentEl);

    // The selection has changed.
  },
});
