import PathKitInit from "../../3rd-party/pathkit-wasm";

import {
  AffineMatrix,
  Anchor,
  BoundingBox,
  Color,
  CompoundPath,
  Fill,
  Geometry,
  Graphic,
  Group,
  ImageFill,
  looseBoundingBoxForGeometries,
  Path,
  Vec,
} from "..";

// PathKit is made to work with geometry in pixels, but most of our geometry
// (e.g. in inches) is smaller. We define a "happy" scale for PathKit and try to
// scale geometry so that the size of its bounding box lies within that order of
// magnitude. The inverse scaling is applied when converting back to Geometry.
// If we don't do this, we start seeing glitches at smaller scales.
const pathKitHappyScale = 1000;
const pathKitScaleFactorForBoundingBox = (box: BoundingBox) => {
  let s = Math.max(box.max.x - box.min.x, box.max.y - box.min.y);
  s = pathKitHappyScale / s;
  return s;
};

export let PathKit: any = null;

// This function must be awaited before calling anything that uses PathKit.
export async function _initPathKit() {
  console.assert(!PathKit);
  PathKit = await PathKitInit({ locateFile: (file: string) => "/editor/" + file });
}

type PkPath = any;
type PkCommand = number[];

//
// Converting to PkCommands
//

const pkCommandForSegment = (a1: Anchor, a2: Anchor): PkCommand => {
  if (a1.handleOut.x !== 0 || a1.handleOut.y !== 0 || a2.handleIn.x !== 0 || a2.handleIn.y !== 0) {
    return [
      PathKit.CUBIC_VERB,
      a1.position.x + a1.handleOut.x,
      a1.position.y + a1.handleOut.y,
      a2.position.x + a2.handleIn.x,
      a2.position.y + a2.handleIn.y,
      a2.position.x,
      a2.position.y,
    ];
  } else {
    return [PathKit.LINE_VERB, a2.position.x, a2.position.y];
  }
};
export const toPkCommands = (item: Geometry): PkCommand[] => {
  const pkCommands: PkCommand[] = [];
  const recurse = (item: Geometry) => {
    if (item instanceof Path) {
      const path = item;
      if (path.anchors.length === 0) return;
      let a1 = path.anchors[0];
      pkCommands.push([PathKit.MOVE_VERB, a1.position.x, a1.position.y]);
      for (let i = 1, n = path.anchors.length; i < n; ++i) {
        let a2 = path.anchors[i];
        pkCommands.push(pkCommandForSegment(a1, a2));
        a1 = a2;
      }
      if (path.closed) {
        pkCommands.push(pkCommandForSegment(a1, path.anchors[0]));
        pkCommands.push([PathKit.CLOSE_VERB]);
      }
    } else if (item instanceof CompoundPath) {
      for (let path of item.paths) {
        recurse(path);
      }
    } else if (item instanceof Group) {
      for (let child of item.items) {
        recurse(child);
      }
    }
  };
  recurse(item);
  return pkCommands;
};

//
// Converting from PkCommands
//

class Conic {
  p0: Vec;
  p1: Vec;
  p2: Vec;
  w: number;

  constructor(p0: Vec, p1: Vec, p2: Vec, w: number) {
    this.p0 = p0;
    this.p1 = p1;
    this.p2 = p2;
    this.w = w;
  }

  subdivide() {
    // http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.131.4487&rep=rep1&type=pdf
    // Page 6
    const { p0, p1, p2, w } = this;
    const q0 = p0;
    const q1 = p0
      .clone()
      .add(p1.clone().mulScalar(w))
      .mulScalar(1 / (1 + w));
    const q2 = p0
      .clone()
      .add(p1.clone().mulScalar(2 * w))
      .add(p2)
      .mulScalar(1 / (2 + 2 * w));
    const q3 = p1
      .clone()
      .mulScalar(w)
      .add(p2)
      .mulScalar(1 / (1 + w));
    const q4 = p2;
    const qw = Math.sqrt((1 + w) / 2);
    return [new Conic(q0, q1, q2, qw), new Conic(q2, q3, q4, qw)];
  }

  approximateCubic(): Anchor[] {
    const { p0, p1, p2, w } = this;
    const lambda = ((4 / 3) * w) / (1 + w);
    const handleOut = p1.clone().sub(p0).mulScalar(lambda);
    const handleIn = p1.clone().sub(p2).mulScalar(lambda);
    return [new Anchor(p0, new Vec(), handleOut), new Anchor(p2, handleIn, new Vec())];
  }

  approximateCubicPieces(): Anchor[] {
    if (Math.abs(this.w - 1) < 0.01) {
      return this.approximateCubic();
    }
    const [c1, c2] = this.subdivide();
    const anchors1 = c1.approximateCubicPieces();
    const anchors2 = c2.approximateCubicPieces();
    anchors2[0].handleIn = anchors1[anchors1.length - 1].handleIn;
    anchors1.pop();
    return [...anchors1, ...anchors2];
  }
}

export const fromPkCommands = (pkCommands: PkCommand[]): CompoundPath => {
  const paths: Path[] = [];
  let currentPath: Path | null = null;

  for (let command of pkCommands) {
    const verb = command[0];
    if (verb === PathKit.MOVE_VERB) {
      const position = new Vec(command[1], command[2]);
      const anchor = new Anchor(position);
      currentPath = new Path([anchor]);
      paths.push(currentPath);
    } else if (verb === PathKit.LINE_VERB && currentPath) {
      const position = new Vec(command[1], command[2]);
      const anchor = new Anchor(position);
      currentPath.anchors.push(anchor);
    } else if (verb === PathKit.CUBIC_VERB && currentPath) {
      const lastAnchor = currentPath.anchors[currentPath.anchors.length - 1];
      lastAnchor.handleOut = new Vec(
        command[1] - lastAnchor.position.x,
        command[2] - lastAnchor.position.y
      );
      const position = new Vec(command[5], command[6]);
      const handleIn = new Vec(command[3] - position.x, command[4] - position.y);
      const anchor = new Anchor(position, handleIn);
      currentPath.anchors.push(anchor);
    } else if ((verb === PathKit.QUAD_VERB || verb === PathKit.CONIC_VERB) && currentPath) {
      // We don't make Conics or Quads but sometimes PathKit makes them when performing Strokes.
      // Conic Approximation via http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.131.4487&rep=rep1&type=pdf
      // "An analysis of cubic approximation schemes for conic sections" - M Floater

      const lastAnchor = currentPath.anchors[currentPath.anchors.length - 1];
      const weight = verb === PathKit.CONIC_VERB ? command[5] : 1;

      const p0 = lastAnchor.position;
      const p1 = new Vec(command[1], command[2]);
      const p2 = new Vec(command[3], command[4]);

      const conic = new Conic(p0, p1, p2, weight);
      // TODO: Should this be conic.approximateCubicPieces()? Need to see where
      // Conics get used and what kind of approximation is acceptable.
      const anchors = conic.approximateCubic();

      for (let i = 0; i < anchors.length; i++) {
        const anchor = anchors[i];
        if (i === 0) {
          lastAnchor.handleOut = anchor.handleOut;
        } else {
          currentPath.anchors.push(anchor);
        }
      }
    } else if (verb === PathKit.CLOSE_VERB && currentPath) {
      if (currentPath.anchors.length > 1) {
        const firstAnchor = currentPath.anchors[0];
        const lastAnchor = currentPath.anchors[currentPath.anchors.length - 1];
        if (firstAnchor.position._almostEquals(lastAnchor.position)) {
          // Until now the last Anchor has been speculative. Once the path is
          // closed we need to remove the last anchor and copy its handleIn to the
          // first anchor, otherwise the resulting path will have a duplicate
          // anchor at the loop point.
          currentPath.anchors.pop();
          firstAnchor.handleIn = lastAnchor.handleIn;
        }
      }
      currentPath.closed = true;
    }
  }

  return new CompoundPath(paths);
};

//
// Creating, copying, and deleting PkPaths
//

// We keep track of the number of pkPaths created to ensure we don't forget to
// delete one (a memory leak).
let numPkObjects = 0;
setInterval(() => {
  if (numPkObjects !== 0) {
    console.warn("PathKit memory leak", numPkObjects);
  }
}, 1000);

export const toPkPath = (item: Geometry, fillType = PathKit.FillType.EVENODD): PkPath => {
  numPkObjects++;
  const pkCommands = toPkCommands(item);
  const pkPath = PathKit.FromCmds(pkCommands);
  pkPath.setFillType(fillType);
  return pkPath;
};

export const fromPkPath = (pkPath: PkPath, andDelete = false): CompoundPath => {
  // Ensure even-odd fill type
  if (pkPath.getFillType() !== PathKit.FillType.EVENODD) {
    pkPath.setFillType(PathKit.FillType.EVENODD);
  }
  const pkCommands = pkPath.toCmds();
  const shape = fromPkCommands(pkCommands);
  if (andDelete) {
    deletePkPath(pkPath);
  }
  return shape;
};

export const emptyPkPath = (): PkPath => {
  numPkObjects++;
  return PathKit.NewPath();
};

export const copyPkPath = (pkPath: PkPath): PkPath => {
  numPkObjects++;
  return pkPath.copy();
};

export const deletePkPath = (pkPath: PkPath) => {
  numPkObjects--;
  pkPath.delete();
};

export const pkPathFromSVGPathString = (svgPathString: string): PkPath => {
  numPkObjects++;
  return PathKit.FromSVGString(svgPathString);
};

//
// Stroke
//

export const performStroke = (
  pkPath: PkPath,
  width: number,
  cap: string,
  join: string,
  miterLimit: number
) => {
  pkPath.stroke({
    width: width,
    miter_limit: miterLimit,
    join: PathKit.StrokeJoin[join.toUpperCase()],
    cap: PathKit.StrokeCap[cap.toUpperCase()],
    res_scale: 2,
  });
  pkPath.simplify();
};

//
// Flatten
//

export const performFlatten = (
  graphic: Graphic,
  backgroundColor?: Color,
  layered: boolean = false
) => {
  const box = graphic.looseBoundingBox();
  if (!box) return new Group();

  const scaleFactor = pathKitScaleFactorForBoundingBox(box);
  const transform = pathKitQATransform(box, scaleFactor);

  const colorPkPaths: { color: Color | ImageFill; pkPath: any }[] = [];
  const addPkPath = (pkPath2: any, color2: Color | ImageFill) => {
    const isBackgroundColor = backgroundColor && colorOrFillEqual(color2, backgroundColor);
    let found = false;
    for (let { color, pkPath } of colorPkPaths) {
      if (colorOrFillEqual(color, color2)) {
        found = true;
        pkPath.op(pkPath2, PathKit.PathOp.UNION);
      } else {
        if (layered && !found && !isBackgroundColor) {
          // Note: we have the !found in the condition because any layers
          // *above* the found color should be subtracted, not unioned. Also
          // the background color should always be subtracted.
          pkPath.op(pkPath2, PathKit.PathOp.UNION);
        } else {
          pkPath.op(pkPath2, PathKit.PathOp.DIFFERENCE);
        }
      }
    }
    if (!found && !isBackgroundColor) {
      colorPkPaths.push({ color: color2, pkPath: pkPath2 });
    } else {
      deletePkPath(pkPath2);
    }
  };

  for (let item of graphic.allPathsAndCompoundPaths()) {
    // TODO: We currently discard paths with image fills, but that's probably
    // not the ideal thing to do. Can we round trip images through PathKit?
    if (item.fill instanceof Fill) {
      const pkPath = toPkPath(item);
      affineTransformPkPath(pkPath, transform);
      addPkPath(pkPath, item.fill.color);
    } else if (item.fill instanceof ImageFill) {
      const pkPath = toPkPath(item);
      affineTransformPkPath(pkPath, transform);
      addPkPath(pkPath, item.fill);
    }
    if (item.stroke && !item.stroke.hairline) {
      const pkPath = toPkPath(item);
      affineTransformPkPath(pkPath, transform);
      const hasNonStandardAlignment = item.hasStrokeWithNonStandardAlignment();
      if (hasNonStandardAlignment) {
        const pkPathStroked = copyPkPath(pkPath);
        performStroke(
          pkPathStroked,
          item.stroke.width * 2 * scaleFactor,
          item.stroke.cap,
          item.stroke.join,
          item.stroke.miterLimit
        );
        if (item.stroke.alignment === "outer") {
          pkPath.op(pkPathStroked, PathKit.PathOp.REVERSE_DIFFERENCE);
        } else if (item.stroke.alignment === "inner") {
          pkPath.op(pkPathStroked, PathKit.PathOp.INTERSECT);
        }
        deletePkPath(pkPathStroked);
      } else {
        performStroke(
          pkPath,
          item.stroke.width * scaleFactor,
          item.stroke.cap,
          item.stroke.join,
          item.stroke.miterLimit
        );
      }
      addPkPath(pkPath, item.stroke.color);
    }
  }

  // Invert the transform to bring the PkPaths back into project coordinates.
  transform.invert();

  const result: CompoundPath[] = [];
  for (let { color, pkPath } of colorPkPaths) {
    affineTransformPkPath(pkPath, transform);
    const shape = fromPkPath(pkPath, true);
    if (color instanceof ImageFill) {
      shape.assignFill(color);
    } else {
      shape.assignFill(new Fill(color.clone()));
    }
    result.push(shape);
  }

  return new Group(result);
};

const colorOrFillEqual = (a: Color | ImageFill, b: Color | ImageFill) => {
  if (a instanceof Color && b instanceof Color) return a.equals(b);
  else if (a instanceof ImageFill && b instanceof ImageFill) return a.equals(b);
  return false;
};

//
// Ops (boolean, stroke, etc)
//

// Scale constants were made with the following expression: `1.618 + 0.001 *
// Math.random()`.
const qaScaleConstant1 = 1.6186993128582432;
const qaScaleConstant2 = 1.6183825806646275;
const qaTolerance = 0.0001; // As a fraction of the total combined lengths.

/**
 * Performs a quality-assured Pathkit operation.
 *
 * QA happens by performing the op multiple times and comparing the results. We
 * first perform the boolean operation at two different scales. If the results
 * disagree (after normalizing their scales) then we try again at a 3rd scale.
 * Currently we use the total path length to determine similarity.
 */
export const performQualityAssuredOp = (
  graphics: Graphic[],
  fillRule: "evenodd" | "winding" | undefined,
  preUnionGroups: boolean,
  opFn: (pkPaths: PkPath[], scaleFactor: number) => PkPath | undefined
): CompoundPath => {
  const box = looseBoundingBoxForGeometries(graphics);
  if (!box) return new CompoundPath();

  const pkFillType = fillRule === "winding" ? PathKit.FillType.WINDING : PathKit.FillType.EVENODD;

  const resultWithScaleFactor = (scaleFactor: number) => {
    const pkOpPaths = preUnionGroups
      ? preUnionedPkPathsFromGraphics(graphics, pkFillType)
      : pkPathsFromGraphics(graphics, pkFillType);

    const transform = pathKitQATransform(box, scaleFactor);
    for (const pkPath of pkOpPaths) affineTransformPkPath(pkPath, transform);

    const pkOpResult = opFn(pkOpPaths, scaleFactor);

    affineTransformPkPath(pkOpResult, transform.invert());

    for (const pkPath of pkOpPaths) deletePkPath(pkPath);
    return fromPkPath(pkOpResult, true);
  };

  interface Trial {
    result: CompoundPath;
    length: number;
  }

  const scaleFactors = new Array<number>(5);
  scaleFactors[0] = pathKitScaleFactorForBoundingBox(box);
  scaleFactors[1] = scaleFactors[0] * qaScaleConstant1;
  scaleFactors[2] = scaleFactors[1] * qaScaleConstant2;
  scaleFactors[3] = scaleFactors[2] * qaScaleConstant1;
  scaleFactors[4] = scaleFactors[3] * qaScaleConstant2;

  const computeTrial = (trialNumber: number): Trial => {
    const result = resultWithScaleFactor(scaleFactors[trialNumber]);
    return {
      result,
      length: totalLengthOfCompoundPath(result),
    };
  };

  const areTrialsClose = (a: Trial, b: Trial) => {
    const length1 = a.length;
    const length2 = b.length;
    const ratio = Math.abs(length1 - length2) / (length1 + length2);
    return ratio <= qaTolerance;
  };

  const trials: Trial[] = [];
  for (let i = 0; i < 5; i++) {
    const trial = computeTrial(i);
    // logManager.consoleGeometry(
    //   trial.result.clone().transform({ position: new Vec(5 * (i + 1), 0) })
    // );
    for (let prevTrial of trials) {
      if (areTrialsClose(prevTrial, trial)) {
        return prevTrial.result;
      }
    }
    trials.push(trial);
  }
  return trials[0].result;
};

const pathKitQATransform = (box: BoundingBox, scaleFactor: number) => {
  return new AffineMatrix()
    .scaleScalar(scaleFactor)
    .translate(box.size().mulScalar(2))
    .rotate(scaleFactor)
    .translate(box.center().negate());
};

const totalLengthOfCompoundPath = (compoundPath: CompoundPath) => {
  let totalLength = 0;
  for (const path of compoundPath.paths) {
    totalLength += path.length();
  }
  return totalLength;
};

const preUnionedPkPathsFromGraphics = (graphics: Graphic[], fillType: number) => {
  return graphics.map((graphic) => {
    if (graphic instanceof Group) {
      // Groups must be pre-unioned so overlapping geometry doesn't become holes
      // due to the winding order.
      let resultPkPath = emptyPkPath();
      for (let groupItem of graphic.items) {
        const pkPath = toPkPath(groupItem, fillType);
        resultPkPath.op(pkPath, PathKit.PathOp.UNION);
        deletePkPath(pkPath);
      }
      return resultPkPath;
    } else {
      return toPkPath(graphic, fillType);
    }
  });
};

const pkPathsFromGraphics = (graphics: Graphic[], fillType: number): PkPath[] => {
  return graphics.flatMap((graphic) => {
    if (graphic instanceof Group) {
      return pkPathsFromGraphics(graphic.items, fillType);
    } else {
      return toPkPath(graphic, fillType);
    }
  });
};

const affineTransformPkPath = (pkPath: PkPath, matrix: AffineMatrix) => {
  const { a, b, c, d, tx, ty } = matrix;
  pkPath.transform(a, c, tx, b, d, ty, 0, 0, 1);
};
