/**

Ref: https://github.com/meixler/web-browser-based-file-encryption-decryption

## Compatibility with openssl

The encryption used by the page is compatible with [openssl](https://www.openssl.org/docs/man1.1.1/man1/openssl-enc.html).

Files encrypted using the page can be decrypted using openssl using the following command:
    
    openssl aes-256-cbc -d -salt -pbkdf2 -iter 10000 -in encryptedfilename -out plaintextfilename

Files encrypted using the following openssl command can be decrypted using the page:

    openssl aes-256-cbc -e -salt -pbkdf2 -iter 10000 -in plaintextfilename -out encryptedfilename

*/

import { assert } from "../geom";

export async function decryptFile(cipherBuffer: ArrayBuffer, passphrase: string) {
  let cipherBytes = new Uint8Array(cipherBuffer);

  const iterations = 10000;
  const passphraseBytes = new TextEncoder().encode(passphrase);
  const salt = cipherBytes.slice(8, 16);

  const passphraseKey = await window.crypto.subtle
    .importKey("raw", passphraseBytes, { name: "PBKDF2" }, false, ["deriveBits"])
    .catch(function (err) {
      console.error(err);
    });
  assert(passphraseKey);

  const derivedBuffer = await window.crypto.subtle
    .deriveBits({ name: "PBKDF2", salt, iterations, hash: "SHA-256" }, passphraseKey, 384)
    .catch(function (err) {
      console.error(err);
    });
  assert(derivedBuffer);
  const derivedBytes = new Uint8Array(derivedBuffer);

  const keyBytes = derivedBytes.slice(0, 32);
  const initializationVectorBytes = derivedBytes.slice(32);
  cipherBytes = cipherBytes.slice(16);

  const decryptionKey = await window.crypto.subtle
    .importKey("raw", keyBytes, { name: "AES-CBC", length: 256 }, false, ["decrypt"])
    .catch(function (err) {
      console.error(err);
    });
  assert(decryptionKey);

  const decryptedBytesBuffer = await window.crypto.subtle
    .decrypt({ name: "AES-CBC", iv: initializationVectorBytes }, decryptionKey, cipherBytes)
    .catch(function (err) {
      console.error(err);
    });
  assert(decryptedBytesBuffer);

  return decryptedBytesBuffer;
}
