import { StorageBackend } from "./storage-backend";
import { StorageLocal } from "./storage-local";

// Live or local cuttle-dashboard dev
const useBackend = [
  "cuttle.xyz",
  "cuttlexyz.com",

  // Local cuttle-dashboard dev (cuttle-editor is :8000)
  "localhost.cuttle.xyz:8001",
  "localhost.cuttlexyz.com:8001",

  // Staging for main branch
  "staging.cuttle.xyz",
  "staging.cuttlexyz.com",

  // Staging for mobile branch
  "staging-mobile.cuttle.xyz",
  "staging-mobile.cuttlexyz.com",
].includes(window.location.host);

export const storage = useBackend ? new StorageBackend() : new StorageLocal();

window.addEventListener("beforeunload", (e) => {
  if (!storage.isSynchronized()) {
    storage.forceSave();
    e.preventDefault();
    e.returnValue = "Auto-save in progress...";
  }
});
