import FileSaver from "file-saver";
import JSZip from "jszip";

import { scaleFactorForUnitConversion } from "../geom";
import { globalState } from "../global-state";
import { CodeComponent, Component } from "../model/component";
import { ExportFormatSVG, PostprocessSVG } from "../model/export-format";
import { logEvent } from "../shared/log-event";
import { escapeURLForXMLComment, sanitizeFilename } from "../util";
import { DXFStringFromFramedGraphic } from "./export-dxf";
import { ExportOptions, exportSVGOptionsForPrint } from "./export-options";
import { PDFBlobFromSVGString } from "./export-pdf";
import { ExportPNGOptions, PNGBlobFromFramedGraphic } from "./export-png";
import { prepareGraphicForCricut, prepareGraphicForGlowforge } from "./export-preprocess";
import { SVGStringFromFramedGraphic, bareSVGStringFromFramedGraphic } from "./export-svg";
import {
  normalizedFramedGraphicForExport,
  tightFramedGraphic,
  usLetterFramedGraphic,
} from "./framed-graphic";

/**
 * All component export flows should ultimately land in this function. All
 * decisions about export formats should be made at this point.
 *
 * This function can throw, and should be surrounded in a try/catch if error
 * handling is desired.
 *
 * @param exportOptions Finalized export options
 */
export const downloadFileWithExportOptions = async (exportOptions: ExportOptions[]) => {
  let files: File[] = [];
  for (const options of exportOptions) {
    const { component, format, filename } = options;
    let file: File | undefined;
    if (format === "svg" || format === "cuttle.svg") {
      const svgPostprocess = globalState.deviceStorage.svgPostprocess;
      file = exportComponentSVG(component, format, filename, svgPostprocess);
    } else if (format === "pdf") {
      file = await exportComponentPDF(component, filename);
    } else if (format === "png") {
      file = await exportComponentPNG(component, filename);
    } else if (format === "dxf") {
      file = exportComponentDXF(component, filename);
    }
    if (file) files.push(file);
  }

  if (files.length === 0) return;
  if (files.length === 1) {
    FileSaver.saveAs(files[0]);
    logEvent("project downloaded", { component: exportOptions[0].component.name });
    return;
  }

  const zip = new JSZip();
  for (const file of files) {
    zip.file(file.name, file);
  }

  const blob = await zip.generateAsync({ type: "blob" });

  const filename = globalState.storage.getProjectName() + ".zip";
  FileSaver.saveAs(blob, filename);
  logEvent("project downloaded zip");
};

const exportComponentSVG = (
  component: Component | CodeComponent,
  format: ExportFormatSVG,
  filename: string,
  postprocess?: PostprocessSVG
) => {
  const framedGraphic = tightFramedGraphicForComponent(component);
  if (!framedGraphic) return;

  if (postprocess === "cricut") {
    framedGraphic.graphic = prepareGraphicForCricut(framedGraphic.graphic);
  } else if (postprocess === "glowforge") {
    framedGraphic.graphic = prepareGraphicForGlowforge(framedGraphic.graphic);
  }

  const options = exportSVGOptionsForPrint(globalState.project.settings);
  options.title = `${globalState.storage.getProjectName()} - ${component.name}`;

  if (format === "cuttle.svg") {
    options.comment = globalState.getProjectSnapshot().toString();
  } else {
    options.comment = "Generator: Cuttle.xyz";
    if (globalState.storage.getShareStatus() === "public") {
      const projectURL = escapeURLForXMLComment(globalState.storage.getProjectUrl());
      options.comment += "\n" + "Project: " + projectURL;
    }
  }

  const framedGraphicPoints = normalizedFramedGraphicForExport(framedGraphic, "pt");

  const svgString = SVGStringFromFramedGraphic(framedGraphicPoints, options);
  const svgBlob = new Blob([svgString], { type: "image/svg+xml" });

  filename = sanitizeFilename(filename, "." + format);
  const file = new File([svgBlob], filename, { type: "image/svg+xml" });
  return file;
};

const exportComponentPDF = async (component: Component | CodeComponent, filename: string) => {
  const trace = globalState.traceForComponent(component);
  if (!trace?.isSuccess()) return;

  const { settings } = globalState.project;
  const framedGraphic = usLetterFramedGraphic(trace.result, settings.units);
  if (!framedGraphic) return;

  const framedGraphicPoints = normalizedFramedGraphicForExport(framedGraphic, "pt");

  // NOTE: We need the bare SVG string without the wrapping `<svg>...</svg>`.
  const options = exportSVGOptionsForPrint(settings);

  // Force image rasterization since PDF (or at least PDFKit) doesn't support pattern defs.
  options.rasterizeImages = true;

  const svgString = bareSVGStringFromFramedGraphic(framedGraphicPoints, options);
  const pdfBlob = await PDFBlobFromSVGString(svgString, framedGraphicPoints.artboard.size());

  filename = sanitizeFilename(filename, ".pdf");
  const file = new File([pdfBlob], filename, { type: "application/pdf" });
  return file;
};

const exportComponentPNG = async (component: Component | CodeComponent, filename: string) => {
  const framedGraphic = tightFramedGraphicForComponent(component);
  if (!framedGraphic) return;

  const pixelsPerUnit = scaleFactorForUnitConversion(framedGraphic.units, "px");
  const options: ExportPNGOptions = {
    hairlineStrokeWidthPixels: globalState.project.settings.hairlineStrokeWidth * pixelsPerUnit,
  };

  const framedGraphicPixels = normalizedFramedGraphicForExport(framedGraphic, "px");

  const pngBlob = await PNGBlobFromFramedGraphic(framedGraphicPixels, options);

  filename = sanitizeFilename(filename, ".png");
  const file = new File([pngBlob], filename, { type: "image/png" });
  return file;
};

const exportComponentDXF = (component: Component | CodeComponent, filename: string) => {
  const framedGraphic = tightFramedGraphicForComponent(component);
  if (!framedGraphic) return;

  const dxfString = DXFStringFromFramedGraphic(framedGraphic);
  const dxfBlob = new Blob([dxfString], { type: "image/x-dxf" });

  filename = sanitizeFilename(filename, ".dxf");
  const file = new File([dxfBlob], filename, { type: "image/x-dxf" });
  return file;
};

const tightFramedGraphicForComponent = (component: Component | CodeComponent) => {
  const trace = globalState.traceForComponent(component);
  if (!trace?.isSuccess()) return;

  const { units } = globalState.project.settings;
  return tightFramedGraphic(trace.result, units);
};
