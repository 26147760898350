import { assert } from "../geom";
import { sanitizeName } from "../util";
import { CodeComponent, Component } from "./component";
import { Element } from "./element";
import { Expression } from "./expression";
import { Instance } from "./instance";
import { Modifier } from "./modifier";
import {
  DefinitionNameGenerator,
  ElementNameGenerator,
  NameHash,
  ParameterNameGenerator,
} from "./name-generator";
import { Parameter } from "./parameter";
import { Project } from "./project";
import { isBuiltin } from "./registry";
import { Selection } from "./selection";
import { PortableProjectData } from "./snapshot";

type Source =
  | Component
  | CodeComponent
  | Modifier
  | Element
  | Instance
  | Parameter
  | Expression
  | Project;
type Env = Record<string, DependencyNode>;

class DependencyNode {
  source: Source;
  parent: DependencyNode | null;

  env: Env;

  children: DependencyNode[];

  dependencies: Set<DependencyNode>;
  dependents: Set<DependencyNode>;

  constructor(source: Source) {
    this.source = source;
    this.parent = null;
    this.env = {};
    this.children = [];
    this.dependencies = new Set();
    this.dependents = new Set();
  }

  addChild(child: DependencyNode) {
    this.children.push(child);
    child.parent = this;
  }
  addDependency(dependency: DependencyNode) {
    if (this.dependencies.has(dependency)) return;
    this.dependencies.add(dependency);
    dependency.dependents.add(this);
  }

  namedParentPath() {
    const names: string[] = [];
    const addNode = ({ source, parent }: DependencyNode) => {
      if (
        source instanceof Component ||
        source instanceof CodeComponent ||
        source instanceof Modifier ||
        source instanceof Parameter
      ) {
        names.unshift(source.name);
      }
      if (parent) addNode(parent);
    };
    addNode(this);
    return names;
  }
}

/**
 * Contains a canonical representation of the dependency structure of the model
 * evaluation. The specific way depenencies are computed here mirrors project
 * evaluation and must be kept in sync with that implementation.
 *
 * @internal
 */
export class DependencyGraph {
  project: Project;
  nodesBySource: Map<Source, DependencyNode>;

  constructor(project: Project) {
    this.project = project;
    this.nodesBySource = new Map();

    const env: Env = Object.create(null);
    const projectNode = this.ensureNodeForSource(project);
    const projectParameters = project.allParameters();

    // All components and modifiers are defined in the global env, so we need to
    // add them here before getting started.
    for (let component of project.components) {
      this.addDefinitionToEnv(env, component);
    }
    for (let modifier of project.modifiers) {
      this.addDefinitionToEnv(env, modifier);
    }
    for (const parameter of projectParameters) {
      this.addDefinitionToEnv(env, parameter);
    }

    for (const parameter of projectParameters) {
      this.addParameter(env, parameter, projectNode);
    }
    for (let component of project.components) {
      this.addDefinition(env, component, projectNode);
    }
    for (let modifier of project.modifiers) {
      this.addDefinition(env, modifier, projectNode);
    }
  }

  nodeForSource(source: Source) {
    return this.nodesBySource.get(source);
  }
  assertNodeForSource(source: Source) {
    const node = this.nodeForSource(source);
    assert(node, "Dependency Graph: Node expected for source, but not found.");
    return node;
  }

  private ensureNodeForSource(source: Source) {
    let node = this.nodeForSource(source);
    if (node === undefined) {
      node = new DependencyNode(source);
      this.nodesBySource.set(source, node);
    }
    return node;
  }

  private addDefinitionToEnv(
    env: Env,
    definition: Component | CodeComponent | Modifier | Parameter
  ) {
    const node = this.ensureNodeForSource(definition);
    const name = sanitizeName(definition.name);
    env[name] = node;
  }

  private addRepeatIndexVariableToEnv(env: Env, instance: Instance, parent: DependencyNode | null) {
    if (instance.isRepeatModifier() && instance.repeatIndexVariableName) {
      const node = this.ensureNodeForSource(instance);
      parent?.addChild(node);
      env[instance.repeatIndexVariableName] = node;
    }
  }

  private addDefinition(
    parentEnv: Env,
    definition: Component | CodeComponent | Modifier,
    parent: DependencyNode | null
  ) {
    const node = this.ensureNodeForSource(definition);
    parent?.addChild(node);

    const env = (node.env = Object.create(parentEnv));
    const parameters = definition.allParameters();

    for (const param1 of parameters) {
      // Since a parameter can't reference itself, but can reference any other
      // definition parameter we must build a new env for each that doesn't
      // shadow project parameters of the same name.
      const paramEnv = Object.create(env);
      for (const param2 of parameters) {
        if (param1 !== param2) {
          this.addDefinitionToEnv(paramEnv, param2);
        }
      }
      this.addParameter(paramEnv, param1, node);
    }

    // Add all definition parameters to the env.
    for (const parameter of parameters) {
      this.addDefinitionToEnv(env, parameter);
    }
    if (definition instanceof CodeComponent || definition instanceof Modifier) {
      this.addExpression(env, definition.code, node);
    }
    if (definition instanceof Component) {
      this.addElement(env, definition.element, node);
    }
  }

  private addElement(env: Env, element: Element, parent: DependencyNode) {
    const node = this.ensureNodeForSource(element);
    node.env = env;
    parent.addChild(node);

    let instanceEnv = env;
    for (const instance of element.allInstances().reverse()) {
      this.addInstance(instanceEnv, instance, node);
      if (instance.isRepeatModifier()) {
        if (instance.repeatIndexVariableName) {
          instanceEnv = Object.create(instanceEnv);
          this.addRepeatIndexVariableToEnv(instanceEnv, instance, node);
        }
      }
    }
    for (const childElement of element.children) {
      this.addElement(instanceEnv, childElement, node);
    }
  }

  private addInstance(env: Env, instance: Instance, parent: DependencyNode) {
    const node = this.ensureNodeForSource(instance);
    node.env = env;
    parent.addChild(node);

    for (const name in instance.args) {
      const expression = instance.args[name];
      this.addExpression(env, expression, parent);
    }

    if (!isBuiltin(instance.definition)) {
      if (
        instance.definition instanceof Component ||
        instance.definition instanceof CodeComponent ||
        instance.definition instanceof Modifier
      ) {
        const dep = this.nodeForSource(instance.definition);
        // All definitions should already be added before we start adding
        // instances.
        assert(dep !== undefined);
        node.addDependency(dep);
      }
    }
  }

  // TODO: Allowing Parameters to be sources simplifies the model, but just add
  // a step of indirection.. Maybe skip them once everything is working.
  private addParameter(env: Env, parameter: Parameter, parent: DependencyNode) {
    const node = this.ensureNodeForSource(parameter);
    node.env = env;
    parent.addChild(node);

    this.addExpression(env, parameter.expression, node);
  }

  private addExpression(env: Env, expression: Expression, parent: DependencyNode) {
    const node = this.ensureNodeForSource(expression);
    node.env = env;
    parent.addChild(node);

    for (const reference of expression.compiled().references) {
      const name = sanitizeName(reference.name);
      const dep = env[name];
      if (dep !== undefined) {
        node.addDependency(dep);
      }
    }
  }

  renameParameter(
    parameter: Parameter,
    newName: string,
    parameterNameGenerator: ParameterNameGenerator
  ) {
    newName = sanitizeName(newName);
    if (newName.length === 0) return;

    const oldName = sanitizeName(parameter.name);
    if (oldName === newName) return;

    // Ensure newName is unique.
    newName = parameterNameGenerator.generate(newName);

    // Perform the rename.
    parameter.name = newName;

    // Rename any references or args.
    const paramNode = this.nodeForSource(parameter);
    if (!paramNode) return;
    for (const { source } of paramNode.dependents) {
      if (source instanceof Expression) {
        source.replaceReferences(oldName, newName);
      }
      if (source instanceof Instance) {
        source.args[newName] = source.args[oldName];
        delete source.args[oldName];
      }
    }
  }

  renameDefinition(
    definition: Component | CodeComponent | Modifier,
    newName: string,
    definitionNameGenerator: DefinitionNameGenerator,
    elementNameGenerator: ElementNameGenerator
  ) {
    newName = newName.trim();
    if (newName.length === 0) return;

    const oldName = definition.name;
    if (oldName === newName) return;

    // Ensure newName is unique.
    newName = definitionNameGenerator.generate(newName);

    // Perform the rename.
    definition.name = newName;

    const sanitizedOldName = sanitizeName(oldName);
    const sanitizedNewName = sanitizeName(newName);

    const defNode = this.nodeForSource(definition);
    if (!defNode) return;

    // Rename any references.
    for (const dependent of defNode.dependents) {
      if (dependent.source instanceof Expression) {
        dependent.source.replaceReferences(sanitizedOldName, sanitizedNewName);
      }
    }

    // Elements are named according to their definition by default. Also attempt
    // to rename these to maintain semantic consistency.
    for (const node of this.nodesBySource.values()) {
      if (node.source instanceof Element) {
        const nameWithoutNumberSuffix = node.source.name.replace(/ \d+$/, "");
        if (nameWithoutNumberSuffix === oldName) {
          let newElementName = node.source.name.replace(oldName, newName);
          this.renameElement(node.source, newElementName, elementNameGenerator);
        }
      }
    }
  }

  renameConflictingDefinitionsBeforeImportToProject(importingProject: Project) {
    const definitionNameGenerator = importingProject.makeDefinitionNameGenerator();
    const elementNameGenerator = importingProject.makeElementNameGenerator();
    for (const component of this.project.components) {
      if (this.project.components.some((c) => c.name === component.name)) {
        const [baseName, number] = definitionNameGenerator.decompose(component.name);
        this.renameDefinition(
          component,
          definitionNameGenerator.compose(baseName, number + 1),
          definitionNameGenerator,
          elementNameGenerator
        );
      }
    }
  }

  renameElement(element: Element, newName: string, elementNameGenerator: ElementNameGenerator) {
    newName = newName.trim();
    if (newName.length === 0) return;

    const oldName = element.name;
    if (oldName === newName) return;

    // Ensure newName is unique.
    newName = elementNameGenerator.generate(newName);

    element.name = newName;
  }

  renameRepeatIndexVariable(instance: Instance, newName: string) {
    newName = sanitizeName(newName);
    if (newName.length === 0) return;

    const oldName = instance.repeatIndexVariableName;
    if (oldName === newName) return;

    const instanceNode = this.nodeForSource(instance);
    if (!instanceNode) return;

    // Ensure newName is unique.
    if (instanceNode.parent && instanceNode.parent.source instanceof Element) {
      // Use the base instance's env since it will have all repeat variable
      // names in the element. This avoids shadowing earlier repeat variable
      // names when applying a new repeat modifier.
      const baseNode = this.nodeForSource(instanceNode.parent.source.base);
      if (baseNode) {
        const existingNames: NameHash = {};
        for (const name in baseNode.env) {
          existingNames[name] = true;
        }
        const nameGenerator = new ParameterNameGenerator(existingNames);
        newName = nameGenerator.generate(newName);
      }
    }

    // Preform the rename.
    instance.repeatIndexVariableName = newName;

    // Rename any references.
    if (oldName !== null) {
      for (const dependent of instanceNode.dependents) {
        if (dependent.source instanceof Expression) {
          dependent.source.replaceReferences(oldName, newName);
        }
      }
    }
  }

  /**
   * Immutable components can be added to the project via Creation Panel
   * examples. This checks if there are any unused ones and removes them from
   * the project.
   *
   * NOTE: There should be no need to call `project.removeComponent` here since
   * immutable components cannot be focused.
   */
  removeUnusedImmutableComponents() {
    this.project.components = this.project.components.filter((component) => {
      if (!component.isImmutable) return true;
      const node = this.assertNodeForSource(component);
      return node.dependents.size > 0;
    });
  }

  private portableProjectDataForNodes(
    nodes: DependencyNode[],
    contextComponent: Component | CodeComponent | null
  ) {
    const visitedNodes: Set<DependencyNode> = new Set();
    const dependencies: Set<DependencyNode> = new Set();

    const addDependenciesForNode = (node: DependencyNode) => {
      if (visitedNodes.has(node)) return;
      visitedNodes.add(node);
      for (const dep of node.dependencies) {
        // Omit repeat index variable dependencies for now to maintain the same
        // copy-paste behavior. In future we should store the referenced
        // variable name, and check to see if it's already available in the
        // pasted expression's scope before pasting a new instance of the repeat
        // modifier.
        const isRepeatIndexVariable = dep.source instanceof Instance;
        if (isRepeatIndexVariable) continue;

        dependencies.add(dep);
        addDependenciesForNode(dep);
      }
      for (const child of node.children) {
        addDependenciesForNode(child);
      }
    };

    for (const node of nodes) {
      dependencies.add(node);
      addDependenciesForNode(node);
    }

    // Categorize dependencies.
    const projectData = new PortableProjectData();
    for (const { source, parent } of dependencies) {
      if (source instanceof Element) {
        projectData.elements.push(source);
      } else if (source instanceof Instance) {
        projectData.instances.push(source);
      } else if (source instanceof Component || source instanceof CodeComponent) {
        projectData.components.push(source);
      } else if (source instanceof Modifier) {
        projectData.modifiers.push(source);
      } else if (source instanceof Parameter) {
        if (parent !== null) {
          if (parent.source instanceof Project) {
            projectData.projectParameters.push(source);
          } else if (contextComponent && parent.source === contextComponent) {
            projectData.componentParameters.push(source);
          }
        }
      }
    }

    // Sort dependencies in project order.
    projectData.projectParameters.sort(compareExternalIndex(this.project.allParameters()));
    if (contextComponent) {
      projectData.componentParameters.sort(compareExternalIndex(contextComponent.allParameters()));
    }
    projectData.components.sort(compareExternalIndex(this.project.components));
    projectData.modifiers.sort(compareExternalIndex(this.project.modifiers));

    return projectData;
  }

  portableProjectDataForSelection(selection: Selection) {
    const elements = selection
      .allNodes()
      .sortInDocumentOrder()
      .items.map((item) => this.assertNodeForSource(item.node.source));
    const instances = selection
      .allInstances()
      .sortInDocumentOrder()
      .items.map((item) => this.assertNodeForSource(item.instance));
    const nodes = [...elements, ...instances];
    return this.portableProjectDataForNodes(nodes, this.project.exportComponent());
  }

  portableProjectDataForComponent(component: Component | CodeComponent) {
    const node = this.assertNodeForSource(component);
    return this.portableProjectDataForNodes([node], null);
  }

  unusedModifiers() {
    return this.project.modifiers.filter((modifier) => {
      const node = this.assertNodeForSource(modifier);
      return node.dependents.size === 0;
    });
  }

  dependentPathsForSource(source: Source) {
    const node = this.nodeForSource(source);
    if (!node) return [];
    const paths: string[][] = [];
    for (const dep of node.dependents) {
      paths.push(dep.namedParentPath());
    }
    return paths;
  }

  categorizedNamesInExpressionScope(expression: Expression) {
    const components: string[] = [];
    const modifiers: string[] = [];
    const parameters: string[] = [];
    const repeats: string[] = [];

    const expressionNode = this.nodeForSource(expression);
    if (expressionNode) {
      for (const name in expressionNode.env) {
        const node = expressionNode.env[name];
        if (node.source instanceof Component || node.source instanceof CodeComponent) {
          components.push(name);
        } else if (node.source instanceof Modifier) {
          modifiers.push(name);
        } else if (node.source instanceof Instance) {
          repeats.push(name);
        } else if (node.source instanceof Parameter) {
          parameters.push(name);
        }
      }
    }

    return {
      components,
      modifiers,
      parameters,
      repeats,
    };
  }
}

const compareExternalIndex = <T>(array: T[]) => {
  return (a: T, b: T) => {
    return array.indexOf(a) - array.indexOf(b);
  };
};
