import { AffineMatrix, BoundingBox, Geometry, Graphic, Path, Vec } from "../geom";
import { LogLevel, LogMessage } from "../log-manager";
import { Component } from "./component";
import { Element } from "./element";
import { Instance } from "./instance";
import { ReducerCache } from "./reducer-cache";

export abstract class Trace implements ReducerCache {
  _reducerCache?: Record<string, unknown>;

  abstract childTraces(): Trace[];
}

export class ProjectTrace extends Trace {
  base: InstanceTrace;
  definitions: InstanceTrace[];

  constructor(base: InstanceTrace, definitions: InstanceTrace[]) {
    super();
    this.base = base;
    this.definitions = definitions;
  }

  childTraces(): Trace[] {
    return [this.base, ...this.definitions];
  }
}

export class ElementTrace extends Trace {
  _isSuccess: boolean;
  source: Element;

  children: ElementTrace[];

  base: InstanceTrace;

  transform: InstanceTrace | undefined;
  fill: InstanceTrace | undefined;
  stroke: InstanceTrace | undefined;

  modifiers: InstanceTrace[];

  result?: Graphic;

  constructor(source: Element) {
    super();

    this._isSuccess = false;
    this.source = source;

    this.children = [];

    this.base = new InstanceTrace(source.base);

    this.transform = undefined;
    this.fill = undefined;
    this.stroke = undefined;

    this.modifiers = [];
  }

  isSuccess(): this is SuccessfulElementTrace {
    return this._isSuccess;
  }
  isAllSuccess(): this is SuccessfulElementTrace {
    return this._isSuccess && this.children.every((childTrace) => childTrace.isAllSuccess());
  }

  childTraces(): Trace[] {
    return [...this.instanceTraces(), ...this.children];
  }

  /**
   * @returns element traces for children of this element, including children of
   * component instance definitions — as they appear in the outline.
   */
  childElementTraces() {
    if (this.source.base.definition instanceof Component) {
      return this.base.element?.children ?? [];
    }
    return this.children;
  }

  instanceTraces() {
    const instances = [this.base];
    if (this.transform) instances.push(this.transform);
    instances.push(...this.modifiers);
    if (this.fill) instances.push(this.fill);
    if (this.stroke) instances.push(this.stroke);
    return instances;
  }

  instanceExpressionTraces() {
    return this.instanceTraces().flatMap((instance) => instance.expressionTraces());
  }

  traceForInstance(instance: Instance) {
    return this.instanceTraces().find((trace) => trace.source === instance);
  }

  loggedGeometry() {
    return this.instanceTraces().flatMap((instance) => instance.loggedGeometry());
  }

  transformMatrix(): Readonly<AffineMatrix> {
    if (this.transform?.isSuccess()) {
      // We know the result of the Transform modifier will be an AffineMatrix
      // because Transform is a builtin.
      return this.transform.code!.evaluationResult as AffineMatrix;
    }
    return AffineMatrix.indentity;
  }
}

interface SuccessfulElementTrace extends ElementTrace {
  _isSuccess: true;

  base: SuccessfulInstanceTrace;
  transform: SuccessfulInstanceTrace | undefined;
  fill: SuccessfulInstanceTrace | undefined;
  stroke: SuccessfulInstanceTrace | undefined;
  modifiers: SuccessfulInstanceTrace[];

  result: Graphic;

  instanceTraces(): SuccessfulInstanceTrace[];
  traceForInstance(instance: Instance): SuccessfulInstanceTrace | undefined;
}

export class InstanceTrace extends Trace {
  _isSuccess: boolean;
  source: Instance;

  args: { [name: string]: ExpressionTrace | undefined };

  // One of `code` or `element` will be present depending on the source definition type
  code: ExpressionTrace | undefined; // Modifier
  element: ElementTrace | undefined; // Component

  result?: Graphic;

  constructor(source: Instance) {
    super();

    this._isSuccess = false;
    this.source = source;

    this.args = {};
    this.code = undefined;
    this.element = undefined;
  }

  isSuccess(): this is SuccessfulInstanceTrace {
    return this._isSuccess;
  }

  resultForArgName(name: string): unknown {
    return this.args[name]?.evaluationResult;
  }

  childTraces(): Trace[] {
    const traces: Trace[] = this.expressionTraces();
    if (this.element) traces.push(this.element);
    return traces;
  }

  expressionTraces() {
    // It's safe to cast away undefined in the args type here because we're
    // using keys that are known to exist, and any key that exists will have a
    // value that is an ExpressionTrace.
    const expressions = Object.values(this.args) as ExpressionTrace[];
    if (this.code) expressions.push(this.code);
    return expressions;
  }

  loggedGeometry() {
    const logged: LoggedGeometry[] = [];
    for (let expression of this.expressionTraces()) {
      for (let { message, logLevel } of expression.messages) {
        if (logLevel === "geometry" || logLevel === "guide") {
          const addSingleGeometry = (geometry: unknown) => {
            if (Geometry.isValid(geometry) || Vec.isValid(geometry)) {
              logged.push({ geometry, logLevel });
            } else if (BoundingBox.isValid(geometry)) {
              // Convert bounding boxes so they can be affuin transformed later.
              logged.push({ geometry: Path.fromBoundingBox(geometry), logLevel });
            }
          };
          if (Array.isArray(message)) {
            for (let item of message) {
              addSingleGeometry(item);
            }
          } else {
            addSingleGeometry(message);
          }
        }
      }
    }
    return logged;
  }
}

interface SuccessfulInstanceTrace extends InstanceTrace {
  _isSuccess: true;
  result: Graphic;
}

export class ExpressionTrace extends Trace {
  readonly evaluationResult: unknown;
  readonly messages: LogMessage[];

  readonly isSuccess: boolean;

  constructor(evaluationResult: unknown, messages: LogMessage[] = []) {
    super();
    this.evaluationResult = evaluationResult;
    this.messages = messages;
    this.isSuccess = messages.every((message) => message.logLevel !== "error");
  }

  childTraces(): Trace[] {
    return [];
  }

  traceByAppendingMessages(messages: LogMessage[]) {
    return new ExpressionTrace(this.evaluationResult, [...this.messages, ...messages]);
  }
}

export interface LoggedGeometry {
  geometry: Geometry | Vec;
  logLevel: LogLevel;
}

export const traceResultOrError = (trace: InstanceTrace) => {
  if (trace.isSuccess()) {
    return trace.result.clone();
  } else {
    throw new Error(`Error evaluating ${trace.source.definition.name}`);
  }
};
