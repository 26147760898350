import {
  APPROXIMATE_EPSILON,
  BoundingBox,
  DEFAULT_EPSILON,
  DEFAULT_TOLERANCE,
  GEOMETRIC_TOLERANCE,
  Geometry,
  Vec,
} from ".";

// Built-in Array Utility

/**
 * @param start The first number in the array
 * @param stop The number to stop at. Note that this number will not be included
 * in the array.
 * @param step The increment. Keep this as 1 for integer ranges.
 * @returns An array of numbers from `start` to `stop`, in increments of `step`.
 * The array is not inclusive of `end`.
 */
export const range = (start: number, stop?: number, step = 1) => {
  if (stop === undefined) {
    stop = start;
    start = 0;
  }

  // via https://github.com/d3/d3-array/blob/master/src/range.js
  const n = Math.max(0, Math.ceil((stop - start) / step)) | 0;
  const range: number[] = new Array(n);
  for (let i = 0; i < n; ++i) {
    range[i] = start + i * step;
  }

  return range;
};

/**
 * Pairs-up elements in an array.
 *
 * ```
 * let a = [1, 2, 3];
 * pairs(a); // [[1, 2], [2, 3]]
 * ```
 *
 * If `loop` is set `true` the array of pairs will include a pair of the last
 * and first elements, completing the cycle.
 *
 * ```
 * let a = [1, 2, 3];
 * pairs(a, true); // [[1, 2], [2, 3], [3, 1]]
 * ```
 */
export function pairs<T>(array: T[], loop = false): [T, T][] {
  if (array.length < 2) return [];
  const pairs: [T, T][] = new Array(loop ? array.length : array.length - 1);
  let prev = array[0];
  let current: T;
  for (let i = 1, n = array.length; i < n; ++i) {
    current = array[i];
    pairs[i - 1] = [prev, current];
    prev = current;
  }
  if (loop) {
    pairs[pairs.length - 1] = [prev, array[0]];
  }
  return pairs;
}

export const pairwiseSplit = <T>(array: T[], shouldSplit: (a: T, b: T) => boolean): T[][] => {
  if (array.length < 1) return [];
  let prevItem = array[0];
  let group: T[] = [prevItem];
  const groups: T[][] = [group];
  for (let i = 1; i < array.length; ++i) {
    const item = array[i];
    if (shouldSplit(prevItem, item)) {
      group = [];
      groups.push(group);
    }
    group.push(item);
    prevItem = item;
  }
  return groups;
};

export const pairwiseGroup = <T>(array: T[], shouldGroup: (a: T, b: T) => boolean): T[][] => {
  if (array.length < 1) return [];
  const isGrouped = new Array<boolean>(array.length).fill(false);
  const groups: T[][] = [];
  const n = array.length;
  for (let i1 = 0; i1 < n; ++i1) {
    if (isGrouped[i1]) continue;
    const elem1 = array[i1];
    const group = [elem1];
    for (let i2 = i1 + 1; i2 < n; ++i2) {
      const elem2 = array[i2];
      if (shouldGroup(elem1, elem2)) {
        group.push(elem2);
        isGrouped[i2] = true;
      }
    }
    groups.push(group);
  }
  return groups;
};

export const groupBy = <K, T>(items: Iterable<T>, keyFn: (item: T) => K): Map<K, T[]> => {
  const groups = new Map<K, T[]>();
  for (const item of items) {
    const key = keyFn(item);
    let group = groups.get(key);
    if (group === undefined) {
      group = [];
      groups.set(key, group);
    }
    group.push(item);
  }
  return groups;
};

export const rotateArray = <T>(array: T[], zeroIndex: number) => {
  if (zeroIndex === 0) return;
  array.push(...array.splice(0, zeroIndex));
};

/**
 * @returns an array with `null` and `undefined` entries removed.
 *
 * @internal
 */
export const nonNull = <T>(array: (T | null | undefined)[]): T[] => {
  return array.filter((e) => e !== null && e !== undefined) as T[];
};

export const boundingBoxForGeometries = (geometries: Geometry[]): BoundingBox | undefined => {
  let box: BoundingBox | undefined;
  for (let i = 0, n = geometries.length; i < n; ++i) {
    const geomBox = geometries[i].boundingBox();
    if (geomBox) {
      if (box) {
        // We assume that geomBox will be canonically oriented here, so we can
        // just compare min vs min and max vs max.
        box.min.min(geomBox.min);
        box.max.max(geomBox.max);
      } else {
        box = geomBox;
      }
    }
  }
  return box;
};

export const looseBoundingBoxForGeometries = (geometries: Geometry[]): BoundingBox | undefined => {
  let box: BoundingBox | undefined;
  for (let i = 0, n = geometries.length; i < n; ++i) {
    const geomBox = geometries[i].looseBoundingBox();
    if (geomBox) {
      if (box) {
        // We assume that geomBox will be canonically oriented here, so we can
        // just compare min vs min and max vs max.
        box.min.min(geomBox.min);
        box.max.max(geomBox.max);
      } else {
        box = geomBox;
      }
    }
  }
  return box;
};

export const areaOfInterestForPartitionedGeometries = (
  geometries1: Geometry[],
  geometries2: Geometry[],
  areaOfInterest?: BoundingBox,
  tolerance = GEOMETRIC_TOLERANCE
) => {
  // Optimization: Check the bounding boxes of the input geometries first. If
  // they don't overlap, then we can return early. If they do overlap, we can
  // limit our search area (areaOfInterest) to the overlapping area.
  const looseBox1 = looseBoundingBoxForGeometries(geometries1);
  const looseBox2 = looseBoundingBoxForGeometries(geometries2);
  if (looseBox1 && looseBox2) {
    const boxes = [looseBox1, looseBox2];
    if (areaOfInterest) boxes.push(areaOfInterest);
    // Expand the bounding box by some tolerance so that we find intersections
    // and overlaps that lie exactly on the edge.
    return BoundingBox.booleanIntersect(boxes)?.expandScalar(tolerance * 2);
  }
  return areaOfInterest;
};

export const pointsAreCollinear = (p1: Vec, p2: Vec, p3: Vec, tolerance = DEFAULT_TOLERANCE) => {
  const m1 = 1 / p1.distance(p2);
  const m2 = 1 / p2.distance(p3);
  const d1x = m1 * (p2.x - p1.x);
  const d1y = m1 * (p2.y - p1.y);
  const d2x = m2 * (p3.x - p2.x);
  const d2y = m2 * (p3.y - p2.y);
  const dot = d1x * d2x + d1y * d2y;
  return dot >= 1 - tolerance;
};

/**
 * Equivalent of number.toFixed() that outputs a number instead of a string.
 *
 * @internal
 */
export const roundToFixed = (x: number, fractionDigits: number) => {
  const scale = Math.pow(10, fractionDigits);
  return Math.round(x * scale) / scale;
};

/**
 * Comparisons using this function may need to be more rigorous. We need to look
 * at each use case and decide what specific level of precision is required.
 * Good reference here:
 * https://randomascii.wordpress.com/2012/02/25/comparing-floating-point-numbers-2012-edition/\
 *
 * @internal
 */
export const almostEquals = (
  a: number,
  b: number,
  maxAbsoluteDiff = DEFAULT_EPSILON,
  maxRelativeDiff = DEFAULT_EPSILON
) => {
  const d = Math.abs(b - a);

  // Check if difference is less than our absolute minimum. This avoids problems
  // when comparing values near zero.
  if (d <= maxAbsoluteDiff) return true;

  // Compare relative to the larger of the two values.
  a = Math.abs(a);
  b = Math.abs(b);
  return d <= Math.max(a, b) * maxRelativeDiff;
};

/**
 * Performs a "fuzzy" equality check between `x` and zero.
 *
 * @param x The number to compare
 * @param maxAbsoluteDiff The maximum distance between `x` and zero.
 *
 * @internal
 */
export const almostZero = (x: number, maxAbsoluteDiff = DEFAULT_EPSILON) => {
  return Math.abs(x) <= maxAbsoluteDiff;
};

/**
 * Performs a "fuzzy" greater than or equal comparison (`a >= b`). This is
 * useful when comparing numbers that may have some accumulated floating point
 * error.
 *
 * @internal
 */
export const almostGreaterThanOrEqual = (
  a: number,
  b: number,
  maxAbsoluteDiff = DEFAULT_EPSILON,
  maxRelativeDiff = DEFAULT_EPSILON
) => {
  if (a > b) return true;
  return almostEquals(a, b, maxAbsoluteDiff, maxRelativeDiff);
};

/**
 * Performs a "fuzzy" less than or equal comparison (`a <= b`). This is useful
 * when comparing numbers that may have some accumulated floating point error.
 *
 * @internal
 */
export const almostLessThanOrEqual = (
  a: number,
  b: number,
  maxAbsoluteDiff = DEFAULT_EPSILON,
  maxRelativeDiff = DEFAULT_EPSILON
) => {
  if (a < b) return true;
  return almostEquals(a, b, maxAbsoluteDiff, maxRelativeDiff);
};

/**
 * Approximate equality functions are intended for comparing times in the 0-1
 * range. For larger values use `almostEqual`.
 *
 * @internal
 */

export const approximatelyEqual = (x: number, y: number) => {
  return approximatelyZero(x - y);
};

export const approximatelyZero = (x: number) => {
  return Math.abs(x) < APPROXIMATE_EPSILON;
};

export const approximatelyOne = (x: number) => {
  return Math.abs(x - 1) < APPROXIMATE_EPSILON;
};

export const approximatelyZeroOrMore = (x: number) => {
  return x > -APPROXIMATE_EPSILON;
};

export const approximatelyOneOrLess = (x: number) => {
  return x < 1 + APPROXIMATE_EPSILON;
};

export const approximatelyZeroToOne = (x: number) => {
  return x > -APPROXIMATE_EPSILON && x < 1 + APPROXIMATE_EPSILON;
};

/**
 * Type checking functions. JavaScript type checking is weird and non-uniform.
 * These bake in the quirks and tell you what you really want to know.
 *
 * @internal
 */

export const isNumber = (value: unknown): value is number => {
  return typeof value === "number" && !isNaN(value);
};

export const isString = (value: unknown): value is string => {
  return typeof value === "string";
};

export const isArray = (value: unknown): value is unknown[] => {
  return Array.isArray(value);
};

export const isObject = (obj: unknown): obj is Record<string, unknown> => {
  return typeof obj === "object" && obj !== null;
};

export const isBoolean = (value: unknown): value is boolean => {
  return typeof value === "boolean";
};

export function assert(condition: any, message?: string): asserts condition {
  if (!condition) throw new Error(message ?? "Assertion failed");
}
