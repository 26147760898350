import m from "mithril";

import { SiteLayout } from "../shared/site-layout/site-layout";

export const ReadOnly: m.Component<{}> = {
  view: function ({ children }) {
    return m(SiteLayout, { className: "read-only" }, children);
  },
  oncreate: function () {
    // HACK: For now a session will be read-only or not. If we change to dynamic
    // routing we'll need to consider how to change the scroll behavior of the
    // page.
    document.querySelector("html")?.classList.add("read-only");
    document.querySelector("body")?.classList.add("read-only");
  },
  onremove: function () {
    document.querySelector("html")?.classList.remove("read-only");
    document.querySelector("body")?.classList.remove("read-only");
  },
};
