import SuperExpressive from "super-expressive";
import { LiteralNumber, seNumber } from "./parse-literal";

export interface CharacterRange {
  begin: number;
  end: number;
}

export interface LiteralNumberRange {
  begin: number;
  end: number;
  literal: LiteralNumber;
}

// prettier-ignore
const seAnyNumbers = SuperExpressive()
  .allowMultipleMatches
  .subexpression(seNumber);

const reAnyNumbers = seAnyNumbers.toRegex();

export const numberRangesForString = (str: string) => {
  const ranges: LiteralNumberRange[] = [];
  let match: RegExpExecArray | null;
  let string: string | undefined;
  while ((match = reAnyNumbers.exec(str))) {
    if ((string = match.groups?.Number)) {
      const value = +string;
      const literal: LiteralNumber = { type: "Number", value, string };
      const range: LiteralNumberRange = {
        begin: match.index,
        end: match.index + string.length,
        literal,
      };
      ranges.push(range);
    }
  }
  return ranges;
};
