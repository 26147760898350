import m from "mithril";

import { Vec, assert } from "../geom";
import { domForVnode } from "../shared/util";

interface CustomCanvasAttrs {
  viewportSize: Vec;
  render(viewportSize: Vec, ctx: CanvasRenderingContext2D): void;
}
interface CustomCanvasState {
  context: CanvasRenderingContext2D | null;
}
export const CustomCanvas: m.Component<CustomCanvasAttrs, CustomCanvasState> = {
  oncreate(vnode) {
    const canvasElem = domForVnode(vnode);

    assert(canvasElem instanceof HTMLCanvasElement);
    this.context = canvasElem.getContext("2d");
    assert(this.context);

    beginRender(this.context);
    vnode.attrs.render(vnode.attrs.viewportSize, this.context);
    endRender(this.context);
  },
  onupdate(vnode) {
    assert(this.context);

    beginRender(this.context);
    vnode.attrs.render(vnode.attrs.viewportSize, this.context);
    endRender(this.context);
  },
  view({ attrs: { viewportSize } }) {
    const pixelRatio = window.devicePixelRatio || 1;
    return m("canvas", {
      width: viewportSize.x * pixelRatio,
      height: viewportSize.y * pixelRatio,
      style: `width:${viewportSize.x}px;height:${viewportSize.y}px`,
    });
  },
};

const beginRender = (ctx: CanvasRenderingContext2D) => {
  const pixelRatio = window.devicePixelRatio || 1;
  ctx.save();
  ctx.scale(pixelRatio, pixelRatio);
};
const endRender = (ctx: CanvasRenderingContext2D) => {
  ctx.restore();
};
