import {
  AffineMatrix,
  BoundingBox,
  ClosestPointResult,
  Color,
  Fill,
  Graphic,
  ImageFill,
  Path,
  RasterizeOptions,
  Stroke,
  Vec,
  boundingBoxForGeometries,
  looseBoundingBoxForGeometries,
  rasterizeGraphic,
} from "..";
import { performFlatten } from "../misc/pathkit";

/**
 * A collection of graphics.
 */
export class Group extends Graphic {
  static readonly displayName = "Group";

  /** An array of graphics contained within this group. */
  items: Graphic[];

  /**
   * Constructs a group from an array of graphics.
   *
   * @param items
   */
  constructor(items: Graphic[] = []) {
    super();
    this.items = items;
  }

  clone() {
    return new Group(this.items.map((item) => item.clone()));
  }

  isValid() {
    return Array.isArray(this.items) && this.items.every(Graphic.isValid);
  }

  affineTransform(affineMatrix: AffineMatrix) {
    for (let item of this.items) item.affineTransform(affineMatrix);
    return this;
  }

  affineTransformWithoutTranslation(affineMatrix: AffineMatrix) {
    for (let item of this.items) item.affineTransformWithoutTranslation(affineMatrix);
    return this;
  }

  allCompoundPaths() {
    return this.items.flatMap((item) => item.allCompoundPaths());
  }

  allPaths() {
    return this.items.flatMap((item) => item.allPaths());
  }

  allAnchors() {
    return this.items.flatMap((item) => item.allAnchors());
  }

  allPathsAndCompoundPaths() {
    return this.items.flatMap((item) => item.allPathsAndCompoundPaths());
  }

  hasStyle() {
    for (let item of this.items) {
      if (item.hasStyle()) return true;
    }
    return false;
  }

  assignFill(fill: Fill | ImageFill) {
    for (let item of this.items) item.assignFill(fill);
    return this;
  }
  removeFill() {
    for (let item of this.items) item.removeFill();
    return this;
  }

  assignStroke(stroke: Stroke) {
    for (let item of this.items) item.assignStroke(stroke);
    return this;
  }
  removeStroke() {
    for (let item of this.items) item.removeStroke();
    return this;
  }

  assignStyle(fill: Fill | ImageFill, stroke: Stroke) {
    for (let item of this.items) item.assignStyle(fill, stroke);
    return this;
  }

  copyStyle(graphic: Graphic) {
    for (let item of this.items) item.copyStyle(graphic);
    return this;
  }

  scaleStroke(scaleFactor: number) {
    for (let item of this.items) item.scaleStroke(scaleFactor);
    return this;
  }

  firstStyled() {
    for (const item of this.items) {
      const styledGraphic = item.firstStyled();
      if (styledGraphic) return styledGraphic;
    }
    return undefined;
  }

  looseBoundingBox() {
    return looseBoundingBoxForGeometries(this.items);
  }
  boundingBox() {
    return boundingBoxForGeometries(this.items);
  }

  isContainedByBoundingBox(box: BoundingBox) {
    if (this.items.length === 0) return false; // Array.every() returns true on an empty array so we need a special case.
    return this.items.every((item) => item.isContainedByBoundingBox(box));
  }

  isIntersectedByBoundingBox(box: BoundingBox) {
    return this.items.some((item) => item.isIntersectedByBoundingBox(box));
  }

  isOverlappedByBoundingBox(box: BoundingBox) {
    return this.items.some((item) => item.isOverlappedByBoundingBox(box));
  }

  closestPoint(point: Vec, areaOfInterest?: BoundingBox): ClosestPointResult | undefined {
    const { items } = this;

    if (items.length === 0) return undefined;
    if (items.length === 1) return items[0].closestPoint(point, areaOfInterest);

    let closestResult: ClosestPointResult | undefined;
    let closestDistanceSq = Infinity;

    for (let item of items) {
      const result = item.closestPoint(point, areaOfInterest);
      if (!result) continue;

      const distanceSq = point.distanceSquared(result.position);
      if (distanceSq < closestDistanceSq) {
        closestResult = result;
        closestDistanceSq = distanceSq;
      }
    }

    return closestResult;
  }

  _primitives() {
    return this.items.flatMap((item) => item._primitives());
  }

  containsPoint(point: Vec) {
    return this.items.some((item) => item.containsPoint(point));
  }

  styleContainsPoint(point: Vec) {
    return this.items.some((item) => item.styleContainsPoint(point));
  }

  reverse() {
    for (let item of this.items) item.reverse();
    this.items.reverse();
    return this;
  }

  rasterize(options?: RasterizeOptions): Path | undefined {
    const res = rasterizeGraphic(this, options);
    if (!res) return;
    const imagePath = Path.fromBoundingBox(res.boundingBox);
    imagePath.fill = res.fill;
    return imagePath;
  }

  static isValid(a: unknown): a is Group {
    return a instanceof Group && a.isValid();
  }

  static flatten(graphic: Graphic, backgroundColor?: Color, layered?: boolean) {
    return performFlatten(graphic, backgroundColor, layered);
  }
}
