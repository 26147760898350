import { IconName } from "../shared/icon";
import { Expression } from "./expression";
import { InstanceDefinition } from "./instance-definition";
import { registerClass } from "./registry";

export class Modifier extends InstanceDefinition {
  projectId = "";

  code = new Expression();
  isShowGuides = true;

  icon?: IconName;

  // Flags this modifier as a repeat modifier, which means it will be required
  // to have a `repetitions` parameter.
  isRepeat = false;

  materialKeys() {
    return [...super.materialKeys(), "projectId", "code", "icon", "isRepeat"];
  }

  clone() {
    const modifier = new Modifier();
    modifier.code = this.code.clone();

    modifier.name = this.name;
    modifier.parameters = this.parameters.map((parameter) => parameter.clone());
    modifier.parameterFolders = this.parameterFolders.map((folder) => folder.clone());

    modifier.isRepeat = this.isRepeat;
    modifier.isPassThrough = this.isPassThrough;
    modifier.isShowGuides = this.isShowGuides;
    modifier.isImmutable = this.isImmutable;
    modifier.isDefaultUniformScale = this.isDefaultUniformScale;

    modifier.comment = this.comment;

    return modifier;
  }
}
registerClass("Modifier", Modifier);
