import { BoundingBox, Geometry, Vec } from "..";

/**
 * Geometry types that can be intersected implement this interface.
 */
export interface GeometryPrimitive extends Geometry {
  /**
   * @returns the position on the primitive at `time`.
   * @param time
   */
  positionAtTime(time: number): Vec;

  _intersectionsWithPrimitive(primitive: GeometryPrimitive): PrimitiveIntersectionResult[];
  _overlapWithPrimitive(
    primitive: GeometryPrimitive,
    tolerance: number
  ): PrimitiveOverlapResult | undefined;

  _looseOverlapsBoundingBox(box: BoundingBox): boolean;
}

export interface PrimitiveIntersectionResult {
  time1: number;
  time2: number;
}

export interface PrimitiveSpan {
  primitive: GeometryPrimitive;
  time1: number;
  time2: number;
}

export interface PrimitiveOverlapResult {
  span1: PrimitiveSpan;
  span2: PrimitiveSpan;
}

export const primitivesFromGeometry = (geometry: Geometry[], areaOfInterest?: BoundingBox) => {
  let prims = geometry.flatMap((geom) => geom._primitives());
  if (areaOfInterest) {
    return prims.filter((prim) => prim._looseOverlapsBoundingBox(areaOfInterest));
  }
  return prims;
};
