import { Node } from "@tiptap/core";
import { NodeSelection } from "@tiptap/pm/state";

import { CodeComponent, Component } from "../../model/component";
import { mountComponentEmbed, unmountComponentEmbed } from "./component-embed";

import { isArray } from "../../shared/util";
import { initDragHandle } from "./prosemirror-drag-handle";

const cuttleComponentNodeName = "cuttleComponent";

export interface CuttleComponentOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    cuttleComponent: {
      /**
       * Add a Cuttle component embed
       */
      insertCuttleComponent: (options: { component: Component | CodeComponent }) => ReturnType;
      /**
       * Convert a component embed to a component gallery, or add the component
       * id to the existing selected gallery.
       */
      makeOrAddToCuttleGallery: (options: {
        currentIds: string[];
        componentId: string;
        pos: number;
      }) => ReturnType;
      /**
       * Remove a component id from a component gallery.
       */
      removeFromCuttleGallery: (options: {
        currentIds: string[];
        componentId: string;
        pos: number;
      }) => ReturnType;
    };
  }
}

export const CuttleComponentNode = Node.create<CuttleComponentOptions>({
  name: cuttleComponentNodeName,
  content: "",
  marks: "",
  group: "block",

  // Set with `extend` in DocEditor, if it is editable
  selectable: false,
  draggable: false,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  addAttributes() {
    return {
      componentid: {
        default: null,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "figure",
        getAttrs: (el: HTMLElement | string) => {
          if (typeof el === "string") {
            return false;
          }
          if (!(el instanceof HTMLElement)) {
            return false;
          }
          let componentid: string | null | string[] = el.getAttribute("data-cuttle-component-id");
          if (!componentid) return false;
          componentid = componentid.split(",");
          return {
            componentid,
          };
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    const idAttr = isArray(HTMLAttributes.componentid)
      ? HTMLAttributes.componentid.join(",")
      : HTMLAttributes.componentid;
    return ["figure", { ["data-cuttle-component-id"]: idAttr }];
  },
  renderText({ node }) {
    const ids = isArray(node.attrs.componentid)
      ? node.attrs.componentid.join(", ")
      : node.attrs.componentid;
    return `[Cuttle component embed: ${ids}]\n\n`;
  },
  addNodeView() {
    // NodeViewRenderer returns a ProseMirror NodeView. Reference:
    // https://prosemirror.net/docs/ref/#view.NodeView
    return (nodeViewRendererProps) => {
      const { node } = nodeViewRendererProps;

      const dom = document.createElement("figure");
      const idAttr = isArray(node.attrs.componentid)
        ? node.attrs.componentid.join(",")
        : node.attrs.componentid;
      dom.setAttribute("data-cuttle-component-id", idAttr);

      mountComponentEmbed(dom, node.attrs.componentid, nodeViewRendererProps);

      const dragHandleNodeViewOptions = initDragHandle(dom, nodeViewRendererProps);

      return {
        dom,
        ...dragHandleNodeViewOptions,
        destroy() {
          // Call the drag handle destroy function since we are also overriding.
          dragHandleNodeViewOptions.destroy();
          unmountComponentEmbed(dom);
        },
      };
    };
  },
  addCommands() {
    return {
      insertCuttleComponent:
        (options) =>
        ({ chain, editor }) => {
          const { component } = options;
          const { selection } = editor.state;
          const pos = selection.$head;

          return chain()
            .insertContentAt(pos.before(), [
              {
                type: this.name,
                attrs: { componentid: [component.id] },
              },
            ])
            .run();
        },
      makeOrAddToCuttleGallery:
        (options) =>
        ({ tr, dispatch }) => {
          const { currentIds, componentId, pos } = options;

          // Ensure we're operating on a cuttleComponent node.
          const nodeAtPos = tr.doc.nodeAt(pos);
          if (nodeAtPos?.type.name !== cuttleComponentNodeName) return false;

          const componentids = isArray(currentIds)
            ? [...currentIds, componentId]
            : [currentIds, componentId];
          dispatch?.(
            tr
              .setSelection(NodeSelection.create(tr.doc, pos))
              .setNodeAttribute(pos, "componentid", componentids)
          );
          return true;
        },
      removeFromCuttleGallery:
        (options) =>
        ({ tr, dispatch }) => {
          const { currentIds, componentId, pos } = options;

          // Ensure we're operating on a cuttleComponent node.
          const nodeAtPos = tr.doc.nodeAt(pos);
          if (nodeAtPos?.type.name !== cuttleComponentNodeName) return false;

          const componentids = currentIds.filter((id) => id !== componentId);
          dispatch?.(
            tr
              .setSelection(NodeSelection.create(tr.doc, pos))
              .setNodeAttribute(pos, "componentid", componentids)
          );
          return true;
        },
    };
  },
});
