import { addExampleShapes } from "../examples-config";
import { EmojiData } from "../examples-types";
import { assetsOrigin } from "../shared/config";

const dataSources = {
  Emoji: `${assetsOrigin}/noto-emoji-600/data-15-1.json`,
};

class ShapeIndex {
  data: Map<string, EmojiData[]> = new Map();

  constructor() {
    this.load();
  }

  async load() {
    for (const [name, url] of Object.entries(dataSources)) {
      const response = await fetch(url);
      const data = (await response.json()) as EmojiData[];
      this.data.set(name, data);

      if (name === "Emoji") {
        // Add all emoji to the example shapes.
        addExampleShapes(name, data);
      }
    }
  }

  // TODO: search by future category or "All"
  search(query: string): EmojiData[] {
    if (query === "") return this.data.get("Emoji") || [];

    const querySplit = query
      .toLowerCase()
      .replace(/[\-]/g, " ")
      .split(/[\s|,]+/)
      .filter((word, i, words) => {
        return word.trim() && words.indexOf(word) == i;
      });

    // The "score" record allows us to sort the results by the order of the
    // keyword match. For example "fruit" sorts "kiwi fruit" before the other
    // emoji that have "fruit" in their keywords, but not names.
    const results: EmojiData[] = [];
    const scores: Record<string, number> = {};
    for (const [category, data] of this.data) {
      for (const shape of data) {
        for (const queryWord of querySplit) {
          const { unified, keywords } = shape;
          const matchIndex = keywords.indexOf(queryWord);
          if (matchIndex >= 0) {
            const existingScore = scores[unified];
            const thisScore = matchIndex / keywords.length;
            if (existingScore !== undefined) {
              scores[unified] = Math.max(existingScore, thisScore);
            } else {
              results.push(shape);
              scores[unified] = thisScore;
            }
          }
        }
      }
    }

    if (results.length < 2) return results;
    results.sort((a, b) => {
      const aScore = scores[a.unified];
      const bScore = scores[b.unified];
      return aScore - bScore;
    });
    return results;
  }
}

export const shapeIndex = new ShapeIndex();
